import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ThemeService } from "../../../common/services/theme.service";
import { Theme } from "../../theme.model";
import { ToastrService } from 'ngx-toastr';
import { UserService } from "src/app/common/services/user.service";
import adminLabels from "../../../constants/ApplicationStrings/trans_utils";
declare const $: any;

@Component({
  selector: "app-theme-first-page",
  templateUrl: "./theme-first-page.component.html",
  styleUrls: ["./theme-first-page.component.css"],
})
export class ThemeFirstPageComponent implements OnInit {
  @Input() theme: Theme;
  @Input() cdn_sign: string;
  @Input() org_id: number;
  @Output("updateTheme") updateTheme: EventEmitter<any> = new EventEmitter();
  theme_id: string;
  theme_color: string;
  logo_id: number;
  logo_url: string;
  footer_logo_id: number;
  footer_logo_url: string;
  logo_options: boolean;
  previous_logo_url: string;
  previous_footer_logo_url: string;
  footer_logo_options: boolean;
  selected_logo: File;
  edit_domain_name: boolean;
  previous_domain_name: string;
  show_color_box: boolean;
  show_title_strip: boolean;
  header_border: boolean;
  header_img: boolean;
  show_domain_name: boolean;
  show_footer_strip: boolean;
  show_footer_logo: boolean;
  theme_settings: object;
  binary_image= false;
  file_1;
  file_2;

  admin_data = adminLabels ;
  constructor(
    private route: ActivatedRoute,
    public themeService: ThemeService,
    private toastr: ToastrService,
    public userService:UserService
  ) { 
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.theme_id = params["params"]["theme_id"];
    });

    this.show_color_box =
      this.show_title_strip =
      this.show_domain_name = (
        this.theme_id == 'theme-1'
        || this.theme_id == 'theme-5'
      ) ? true : false;

    this.header_border =
      this.show_footer_strip =
      (this.theme_id == 'theme-2') ? true : false;

    this.header_img = (this.theme_id == 'theme-3') ? true : false;
    this.show_footer_logo = (this.theme_id == 'theme-2' || this.theme_id == 'theme-3') ? true : false;

    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

  }

  onSelectLogo(event, type: string) {
    if (event.target.files && event.target.files[0]) {
      this.selected_logo = event.target.files[0];

      // File Name and Extension.
      let file_name: string = this.selected_logo.name;
      let file_extension: string = file_name.replace(/^.*\./, '');
      let file_size: number = this.selected_logo.size;

      // Check if it is an image file.
      if ((file_extension == "png"
        || file_extension == "jpg"
        || file_extension == "jpeg")
        && file_size <= 2097152) {

        let reader = new FileReader();

        // read file as data url
        reader.readAsDataURL(event.target.files[0]);

        reader.onload = () => {
          // called once readAsDataURL is completed
          this.binary_image = true;
          if (type === "footer_logo") {
            this.previous_footer_logo_url = this.theme.first_page.footer_logo_url;
            this.theme.first_page.footer_logo_url = reader.result;
            this.footer_logo_options = true;
          } else {
            this.previous_logo_url = this.theme.first_page.logo_url;
            this.theme.first_page.logo_url = reader.result;
            this.logo_options = true;
          }
        };
      } else {
        this.onCancelLogo(type);
        this.toastr.error(this.admin_data.invalidExtension, this.admin_data.failed, {
          timeOut: 3000
        });
      }
    }
  }

  onEditDomainName() {
    this.previous_domain_name = this.theme.domain_name;
    this.edit_domain_name = true;
  }

  onCancelDomainName() {
    this.theme.domain_name = this.previous_domain_name;
    this.edit_domain_name = false;
  }

  onSaveDomainName() {
    this.edit_domain_name = false;
    this.updateTheme.emit();
  }

  onSaveThemeColor() {
    this.updateTheme.emit();
  }

  onCancelLogo(type: string) {
    if (type == 'footer_logo') {
      if (this.previous_footer_logo_url != '' && this.previous_footer_logo_url != undefined) {
        this.theme.first_page.footer_logo_url = this.previous_footer_logo_url;
      } else {
        this.theme.first_page.footer_logo_url = '';
      }
      this.footer_logo_options = false;
    } else 
    {
      if (this.previous_logo_url != '' && this.previous_logo_url != undefined){
        this.theme.first_page.logo_url = this.previous_logo_url + this.cdn_sign;
      } else {
        this.theme.first_page.logo_url = '';
      }
      this.logo_options = false;
    }
    this.file_1=null;
    this.file_2=null;
    this.binary_image = false;
  }

  onSaveLogo(org_id: number, type: string) {
    let formData = new FormData();
    formData.append(
      "image",
      this.selected_logo
    );

    formData.append(
      "organization_id",
      org_id.toString()
    );

    this.themeService.getImageId(formData).subscribe(
      (response) => {
        let data = response["data"];
        if (type == 'footer_logo') {
          this.footer_logo_options = false;
          this.theme.first_page.footer_logo_id = parseInt(data["image_id"]);
          this.theme.first_page.footer_logo_url = data["image_path"];
        } else {
          this.logo_options = false;
          this.theme.first_page.logo_id = parseInt(data["image_id"]);
          this.theme.first_page.logo_url = data["image_path"];
        }

        this.updateTheme.emit();
        this.binary_image = false;
      },
      (error) => {
        this.toastr.error(this.admin_data.unableUpdateLogo, this.admin_data.failed, {
          timeOut: 2000
        });
      }
    );
  }

  getLogoStyle() {
    let css_class: string = 'logo-wrapper';
    if (this.theme_id == 'theme-2' || this.theme_id == 'theme-3') {
      css_class = 'logo-wrapper-center';
    }
    return css_class;
  }

  getTitleStyle() {
    let css_class: string = 'guide-title';
    if (this.theme_id == 'theme-2') {
      css_class = 'guide-title-2';
    }

    if (this.theme_id == 'theme-3') {
      css_class = 'guide-title-3';
    }

    if (this.theme_id == 'theme-5') {
      css_class = 'guide-title-5';
    }

    return css_class;
  }

  getDomainNameStyle() {
    let css_class: string = 'domain-name';

    if (this.theme_id == 'theme-5') {
      css_class = 'domain-name-5';
    }

    return css_class;
  }

  showToolTip(){
    $('[data-toggle="tooltip"]').tooltip();
  }

  removeToolTip(){
    $('.tooltip').remove();
  }

}
