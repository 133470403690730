import { Component } from '@angular/core';
import { UserService } from '../common/services/user.service';
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { ErrorResponseService } from '../common/services/error-response.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
declare var $;
@Component({
  selector: 'app-maintenance-mode',
  templateUrl: './maintenance-mode.component.html',
  styleUrls: ['./maintenance-mode.component.scss']
})
export class MaintenanceModeComponent {

    admin_data = adminLabels;
    urlData = [];
    constructor(
        public userService: UserService,
        private errorResponseService: ErrorResponseService,
        private router: Router,) {
        this.userService.getUserData();
    
    }

    ngOnInit() {
        console.log(1);
        this.userService.sidebarOpen = false
        const query = new URLSearchParams(window.location.search);
        query.forEach((v, k) => {
            this.urlData[k] = v;
        });
        this.userService.hide();

        const urlVal = atob(this.urlData['redirectfrommaintenance'])
        let user = JSON.parse(localStorage.getItem('user'));
        this.userService.getUser(user['user_id']).subscribe((response) => {
            if (response['error'] == true) {
            } else {
                
                if (this.isInsightsURL(urlVal)) {
                    this.redirectToInsights(urlVal);
                } else {
                    this.redirectAdminPortal(urlVal);
                }
            }
        })
    }

    refresh() {
        console.log(2);

        const urlVal = atob(this.urlData['redirectfrommaintenance'])
        let user = JSON.parse(localStorage.getItem('user'));
        this.userService.getUser(user['user_id']).subscribe((response) => {
            if (response['error'] == true) {
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            } else {
                if (this.isInsightsURL(urlVal)) {
                    this.redirectToInsights(urlVal);
                } else {
                    this.redirectAdminPortal(urlVal);
                }
            }
        })
    }

    
    isInsightsURL(url: string) {
        if (url.startsWith(environment.INSIGHTS_URL)) return true;
        return false;
    }

    redirectToInsights(url: string) {
        let token = localStorage.getItem('token');
        token = JSON.parse(token);

        const accessToken = token['accesstoken'];
        const refreshToken = token['refreshtoken'];
        this.userService.generateHandoffToken(accessToken, refreshToken).subscribe(
            (response) => {
                if (response['error']) {
                    this.router.navigate(["/login"])
                } else {
                    window.open(url + '&handoff-token=' + response['data']['handoff-token'], '_self')
                }
            });
    }

    redirectAdminPortal(url: string) {
        window.open(url, '_self');
    }

    ngOnDestroy() {
        this.userService.sidebarOpen = true;
    }
}
