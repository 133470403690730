import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_END_POINTS } from 'src/app/constants/api-end-points';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

export interface SSO{
  id: string,
  organization_id: string,
  issuer_url: string,
  sso_url: string,
  x509_certificate: string,
  api_key: string,
  api_secret: string,
  creation_date: string,
  modification_date: string,
  want_response_signed: string,
  want_assertion_signed: string,
  want_metadata_signed: string,
  want_message_signed: string,
  want_nameId_encrypted: string,
  want_slo_enabled: string,
  is_sync_complete: string,
  want_same_cert_for_encryption: string,
  slo_url: string,
  sso_btn_message: string,
  sso_confirmation_message: string,
  signature_algorithm: string,
  digest_algorithm: string,
  encryption_x509_certificate: string,
  attribute_map: string,
  domain: any,
  want_authn_request_signed: string,
  is_active: string,
  name: string,
  description: string
}

@Injectable({
  providedIn: 'root'
})

export class SsoListService {

  appTitles=[adminLabels.ssoListTableHeaderSSOName,adminLabels.tableHeaderDescription,adminLabels.tableHeaderModificationDate,adminLabels.tableHeaderActions];
  appDataKeys=['','name','description','modification_date','sso_action'];


  col_widths=['20%','30%','30%','20%'];
  isEdit=false;
  selectedSSO;

  constructor(private userService:UserService,private apiService:ApiService) { }

  getHeader(){
    return {
       headers: new HttpHeaders({
        //  'AppKey': environment.authKey,
         'AccessToken':this.userService.user.token
     })
     }
    }

  geSSOList(limit,offset){
    let urlPath=environment.baseUrl+API_END_POINTS.GET_SSO_LIST+'?organization_id='+this.userService.organization_id+'&limit='+limit+'&offset='+offset;
    return this.apiService.get(urlPath);
  }

  addSSO(request){
    let urlPath=environment.baseUrl+API_END_POINTS.ADD_SSO;
    return this.apiService.post(urlPath,request);
  }

  deleteSSO(id){
    let request = {
      sso_id:id
    }

    let urlPath = environment.baseUrl+API_END_POINTS.DELETE_SSO;
    return this.apiService.deleteWithBody(urlPath,request);
  }
}
