import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { API_END_POINTS } from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  appTitles=[ adminLabels.domainsAddDomainDomainName , adminLabels.domainsAddDomainSSOIDP , adminLabels.tableHeaderCreationDate , adminLabels.tableHeaderActions ];
  appDataKeys=['','domain','sso_name','creation_date','domain_action'];

  col_widths=['25%','25%','30%','20%'];
  isEdit=false;
  selectedDomain:any;

  add_button={
    type:'add_domain',
    class:'btn btn-primary minWidth-120',
    disable:false,
    text: adminLabels.domainsAddDomainButtonText
  }

  update_button={
    type:'update_domain',
    class:'btn btn-primary minWidth-120',
    disable:false,
    text:adminLabels.updateButtonText
  }

  cancel_button={
    type:'cancel',
    class:'btn btn-secondary minWidth-120',
    disable:false,
    text:adminLabels.cancelButtonText
  }

  constructor(private userService:UserService,private apiService:ApiService) { }

  getDomainList(limit,offset,search=''){
    let urlPath=environment.baseUrl+API_END_POINTS.GET_DOMAIN_LIST+'?organization_id='+this.userService.organization_id+'&limit='+limit+'&offset='+offset;
    if(search!=''){
      urlPath+='&search_text='+search;
    }
    return this.apiService.get(urlPath);
  }

  addDomain(request){
    let urlPath=environment.baseUrl+API_END_POINTS.ADD_DOMAIN;
    return this.apiService.post(urlPath,request);
  }

  ssomapping(request){
    let urlPath=environment.baseUrl+API_END_POINTS.DOMAIN_SSO_MAP;
    return this.apiService.put(urlPath,request);
  }

  deleteDomain(id){
    let httpParams = new HttpParams().set('domain_id', id);
    let urlPath=environment.baseUrl+API_END_POINTS.DELETE_DOMAIN;
    return this.apiService.delete(urlPath,httpParams);
  }
}
