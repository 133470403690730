import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import {API_END_POINTS} from '../../constants/api-end-points';

export interface Provision{
  "provision_method": string,
    "email_id": string,
    "provision_token": string,
    "organization_id": string,
    "api_key": string,
    "api_secret": string
}

@Injectable({
  providedIn: 'root'
})
export class UserProvisioningService {

  constructor(private apiService:ApiService,private userService:UserService) { }

  

  getProvisioningToken(){
    let urlPath = environment.baseUrl + API_END_POINTS.GENERATE_PROVISION_TOKEN+'?organization_id='+this.userService.organization_id;
    return this.apiService.get(urlPath);
  }

  generateProvisionToken(request){
    let urlPath= environment.baseUrl+API_END_POINTS.GENERATE_PROVISION_TOKEN;
    return this.apiService.put(urlPath,request);
  }
}
