import adminLabels from "../ApplicationStrings/trans_utils";

export const SSO_FORMDATA = [
  {
    type: "text",
    name: "name",
    value: "",
    class: "col-12 col-sm-12 col-md-6 col-lg-12 col-xl-12 my-2",
    validators: [
      {
        required: true,
        maxLength: 120,
      },
    ],
    ui: {
      label: adminLabels.ssoListTableHeaderSSOName,
      class: "font-weight-bold label-asterisk font14",
      inputclass: "form-control col-lg-12",
    },
    input: {
      id: "name",
      class: "form-control col-lg-12",
      type: "text",
      name: "name",
      placeholder: adminLabels.ssoListTableHeaderSSOName,
    },
    label: {
      title: adminLabels.ssoListTableHeaderSSOName,
      class: "col-lg-4",
      for: "name",
    },
  },
{
    type: "textarea",
    name: "description",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2 font14",
    value: "",
    validators: [
        {
            required:true,
            maxLength:3000,
        },
    ],
    ui: {
        label: adminLabels.ssoDescription,
        class: "font-weight-bold font14 label-asterisk",
      inputclass: "form-control",
    },
},
  {
    type: "text",
    name: "issuer_url",
    value: "",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2",
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoIssuerURL,
      class: "font-weight-bold label-asterisk font14",
      inputclass: "form-control",
    },
    input: {
      id: "issuer_url",
      class: "form-control",
      type: "text",
      name: "issuer_url",
      placeholder: adminLabels.ssoIssuerURL,
    },
    label: {
      title: adminLabels.ssoIssuerURL,
      class: "col-lg-4",
      for: "issuer_url",
    },
  },
  {
    type: "text",
    name: "sso_url",
    value: "",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoUrl,
      class: "font-weight-bold label-asterisk font14",
      inputclass: "form-control",
    },
    input: {
      id: "sso_url",
      class: "col-lg-8",
      type: "text",
      name: "sso_url",
      placeholder: adminLabels.ssoIssuerURL,
    },
    label: {
      title: adminLabels.ssoIssuerURL,
      class: "col-lg-4 label-asterisk font14",
      for: "pwd",
    },
  },
  {
    type: "textarea",
    name: "x509_certificate",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2 font14",
    value: "",
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoCertificate,
      class: "font-weight-bold label-asterisk font14",
      inputclass: "form-control",
    },
  },
  {
    type: "text",
    name: "api_key",
    value: "",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoAPIKey,
      class: "font-weight-bold label-asterisk font14",
      inputclass: "form-control",
    },
    input: {
      id: "api_key",
      class: "col-lg-8",
      type: "text",
      name: "api_key",
      placeholder: adminLabels.ssoAPIKey,
    },
    label: {
      title: adminLabels.ssoAPIKey,
      class: "col-lg-4 label-asterisk font14",
      for: "api_key",
    },
  },
  {
    type: "text",
    name: "api_secret",
    value: "",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoAPISecret,
      class: "font-weight-bold label-asterisk font14",
      inputclass: "form-control",
    },
    input: {
      id: "api_secret",
      class: "col-lg-8",
      type: "text",
      name: "api_secret",
      placeholder: adminLabels.ssoAPISecret,
    },
    label: {
      title: adminLabels.ssoAPISecret,
      class: "col-lg-4 label-asterisk font14",
      for: "api_secret",
    },
  },
];

export const SSO_ADVANCED_FORMDATA = [
  {
    type: "radio",
    name: "want_response_signed",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
    value: ["yes", "no"],
    value_labels: [adminLabels.yes, adminLabels.no],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoResponseSigned,
      class: "font-weight-bold font14 col-lg-12 padding0  ",
    },
  },
  {
    type: "radio",
    name: "want_assertion_signed",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
    value: ["yes", "no"],
    value_labels: [adminLabels.yes, adminLabels.no],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoAssertionSigned,
      class: "font-weight-bold font14 col-lg-12 padding0 ",
    },
  },
  {
    type: "radio",
    name: "want_metadata_signed",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
    value: ["yes", "no"],
    value_labels: [adminLabels.yes, adminLabels.no],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoMetaDataSigned,
      class: "font-weight-bold font14 col-lg-12 padding0 ",
    },
  },
  {
    type: "select",
    name: "signature_algorithm",
    value: "rsa-sha256",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 hidden font14",
    options: [
      {
        value: "rsa-sha256",
        name: "RSA-SHA256",
      },
      {
        value: "rsa-sha384",
        name: "RSA-SHA384",
      },
      {
        value: "rsa-sha512",
        name: "RSA-SHA512",
      },
    ],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoSignatureAlgorithm,
      class: "font-weight-bold font14 col-lg-12 padding0 ",
      inputclass: "form-control font14",
    },
  },
  {
    type: "select",
    name: "digest_algorithm",
    value: "sha256",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 hidden font14",
    options: [
      {
        value: "sha256",
        name: "SHA256",
      },
      {
        value: "sha384",
        name: "SHA384",
      },
      {
        value: "sha512",
        name: "SHA512",
      },
    ],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoDigestAlgorithm,
      class: "font-weight-bold font14 col-lg-12 padding0 ",
      inputclass: "form-control",
    },
  },
  {
    type: "radio",
    name: "want_nameId_encrypted",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
    value: ["yes", "no"],
    value_labels: [adminLabels.yes, adminLabels.no],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoNameIdEncrypted,
      class: "font-weight-bold font14 col-lg-12 padding0 ",
    },
  },
  {
    type: "radio",
    name: "want_message_signed",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
    value: ["yes", "no"],
    value_labels: [adminLabels.yes, adminLabels.no],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoMessageSigned,
      class: "font-weight-bold font14 col-lg-12 padding0 ",
    },
  },
  {
    type: "radio",
    name: "want_same_cert_for_encryption",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
    value: ["yes", "no"],
    value_labels: [adminLabels.yes, adminLabels.no],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoWish,
      class: "font-weight-bold font14 col-lg-12 padding0 ",
    },
  },
  {
    type: "textarea",
    name: "encryption_x509_certificate",
    class: "col-lg-12 my-2 font14",
    value: "",
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoEncryptCertificate,
      class: "font-weight-bold font14 col-lg-12 padding0 ",
      inputclass: "form-control",
    },
  },
  {
    type: "radio",
    name: "want_slo_enabled",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
    value: ["yes", "no"],
    value_labels: [adminLabels.yes, adminLabels.no],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoAllowAplicationSingleLayout,
      class: "font-weight-bold font14 col-lg-12 padding0 ",
    },
  },
  {
    type: "radio",
    name: "want_authn_request_signed",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
    value: ["yes", "no"],
    value_labels: [adminLabels.yes, adminLabels.no],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoAuthRequestSigned,
      class: "font-weight-bold font14 col-lg-12 padding0 ",
    },
  },
  {
    type: "text",
    name: "slo_url",
    value: "",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.ssoSingleLogoutURL,
      class: "font-weight-bold label-asterisk font14",
      inputclass: "form-control",
    },
    input: {
      id: "slo_url",
      class: "col-lg-8 font14",
      type: "text",
      name: "slo_url",
      placeholder: adminLabels.ssoSingleLogoutURL,
    },
    label: {
      title: adminLabels.ssoSingleLogoutURL,
      class: "col-lg-4 font14",
      for: "slo_url",
    },
  },
  {
    type: "text",
    name: "sso_btn_message",
    value: "",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
    validators: [
      {
        required: true,
        maxLength:250,
      },
    ],
    ui: {
      label: adminLabels.ssoButtonMessage,
      class: "font-weight-bold font14 col-lg-12 padding0 ",
      inputclass: "form-control",
    },
    input: {
      id: "app_elem",
      class: "col-lg-8 font14",
      type: "text",
      name: "app_elem",
      placeholder: adminLabels.ssoButtonMessage,
    },
    label: {
      title: adminLabels.ssoButtonMessage,
      class: "col-lg-4 font14",
      for: "app_elem",
    },
  },
  {
    type: "text",
    name: "sso_confirmation_message",
    value: "",
    class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
    validators: [
      {
        required: true,
        maxLength:250,
      },
    ],
    ui: {
      label: adminLabels.ssoConfirmationMessage,
      class: "font-weight-bold font14 col-lg-12 padding0 ",
      inputclass: "form-control",
    },
    input: {
      id: "sso_confirmation_message",
      class: "col-lg-8 font14",
      type: "password",
      name: "sso_confirmation_message",
      placeholder: adminLabels.ssoConfirmationMessage,
    },
    label: {
      title: adminLabels.ssoConfirmationMessage,
      class: "col-lg-4 font14",
      for: "sso_confirmation_message",
    },
  },
];
