import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { UserService } from "../../common/services/user.service";
import { UUID } from "../../constants/FormData/add_user";
import { SegmentationService } from "../../common/services/segmentation.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { ApplicationService } from "../../common/services/application.service";
import { OrganisationService } from "../../common/services/organisation.service";
import { filter } from 'rxjs/operators';
import { RoleService } from '../../common/services/role.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "../../common/services/toast-modal.service";
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

import { ADD_INTERNAL_USER_FORMDATA } from "../../constants/FormData/add_internal_user";
import { PartnerService } from 'src/app/common/services/partner.service';
declare var $: any;

@Component({
    selector: 'app-create-internal-user',
    templateUrl: './create-internal-user.component.html',
    styleUrls: ['./create-internal-user.component.css']
})
export class CreateInternalUserComponent implements OnInit {

    partner_id = "0";
    admin_data = adminLabels;
    breadcrumb;
    add_internal_user_formdata = JSON.parse(JSON.stringify(ADD_INTERNAL_USER_FORMDATA));
    formGroup: FormGroup;
    constructor(
        public userService: UserService,
        private segmentation: SegmentationService,
        private router: Router,
        private application: ApplicationService,
        private route: ActivatedRoute,
        private organizationService: OrganisationService,
        private roleService: RoleService,
        private toastr: ToastrService,
        private titleService: Title,
        private errorResponseService: ErrorResponseService,
        private toastService: ToastModalService,
        private partnerService: PartnerService) {
        this.userService.getUserData();
        if (this.userService.partner_id) {
            this.partner_id = userService.partner_id
        }

        // this.paginationService.initializeValues();
        // this.organisationService.getCDNData()
        this.titleService.setTitle(
            this.admin_data.addInternalUser + " | Cornerstone Guide"
        );
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                let breadCrumb = [
                    {
                        name: adminLabels.usersBreadCrumbTitle,
                        route: ['/users']
                    },
                    {
                        name: adminLabels.addInternalUser,
                        route: ['/users/add/internal']
                    },
                ];
                if (this.userService.organization_id === "000") {
                    let tempDashboardBreadCrumb = this.userService.getDashboardBreadCrumb();
                    breadCrumb.unshift(tempDashboardBreadCrumb);
                }
                this.userService.setBreadcrumb(breadCrumb);
                this.breadcrumb = this.userService.breadCrumbs;
            });
        // this.userService.partner_id = "123"
        if (this.partner_id === "0") {
            this.partnerService.getPartnerList(200, 0).subscribe((response) => {
                for (let index = 0; index < response["data"]["partners"].length; index++) {
                    let partnerSelect = {
                        value: response["data"]["partners"][index]["id"],
                        name: response["data"]["partners"][index]["title"],
                    }

                    this.add_internal_user_formdata[4]["options"].push(partnerSelect)
                }

            })
            this.initialize();

        } else {
            this.add_internal_user_formdata.splice(4, 1);
            this.initialize()
        }
        // this.add_internal_user_formdata[4]["options"] = [{
        //     value: "a",
        //     name: "d",
        // },
        // {
        //     value: "b",
        //     name: "d",
        // },
        // {
        //     value: "c",
        //     name: "d",
        // },
        // {
        //     value: "d",
        //     name :  "d",
        // }]
    }


    ngOnInit() {

    }


    initialize() {
        let form = {};
        for (let i = 0; i < this.add_internal_user_formdata.length; i++) {
            form[this.add_internal_user_formdata[i].name] = new FormControl("");
            let validations = [];
            for (let v of this.add_internal_user_formdata[i].validators) {
                for (let key in v) {
                    validations.push(key);
                }
            }
            let validators = [];
            for (let j = 0; j < validations.length; j++) {
                switch (validations[j]) {
                    case "required":
                        if (validations[j]['required']) {
                            validators.push(Validators.required);
                        }
                        break;

                    case "email":
                        validators.push(Validators.email);
                        break;

                    case "regex":
                        validators.push(
                            Validators.pattern(
                                this.add_internal_user_formdata[i].validators[j]["regex"]
                            )
                        );
                }
            }
            form[this.add_internal_user_formdata[i].name].setValidators(validators);
        }
        this.formGroup = new FormGroup(form);
        let inputValidateField = ["first_name", "last_name"];
        for (let ind = 0; ind < inputValidateField.length; ind++) {
            this.formGroup.get(inputValidateField[ind]).valueChanges.subscribe(x => {
                if (x && !this.userService.pattern.test(x)) {
                    this.formGroup.get(inputValidateField[ind]).setValue(x.replace(this.userService.replacePattern, ""), { emitEvent: false });
                }
            });
        }
    }

    addUser() {
        let partnerId;
        if (this.userService.user.role == "partner_admin") {
            partnerId = this.userService.partner_id;
        } else {
            partnerId = this.formGroup.get("partners") ? this.formGroup.get("partners").value : ""
        }
        let request = {
            first_name: this.formGroup.get("first_name").value.trim(),
            last_name: this.formGroup.get("last_name").value.trim(),
            email_id: this.formGroup.get("user_email").value,
            role: this.formGroup.get("role").value,
            partner_id: partnerId
        };
        // console.log("add user called ", request);
        this.userService.addInternaluser(request).subscribe((response) => {
            if(response['error'] == false)
            {
                this.unassign();
            } else {
                let errorResponse = this.errorResponseService.getErrorResponse(response);
                this.userService.errorMessage = errorResponse;
                //console.log(errorResponse);                
                $("#errorModal").modal("show");
            }
        })

    }
    unassign() {
        this.router.navigate([
            "users"
        ]);
    }

    onChangForm() {
        if (this.userService.user.role === 'superadmin') {
            if (this.formGroup.get('role').value == "mg_admin") {
                this.formGroup.controls['partners'] && this.formGroup.controls['partners'].clearValidators();
                this.add_internal_user_formdata[4]["ui"]["class"] = "font-weight-bold"
            } else if (this.formGroup.get('role').value == "partner_admin") {
                this.formGroup.controls['partners'] && this.formGroup.controls['partners'].setValidators([Validators.required]);
                this.add_internal_user_formdata[4]["ui"]["class"] = "font-weight-bold label-asterisk"
            }
            this.formGroup.controls['partners'] && this.formGroup.controls['partners'].updateValueAndValidity();
        }
    }
    

}
