<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">
            <div class="sectionBody">
                <ng-container >
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 section-header mb-2 p-0">
                        <span class="section-title">{{this.admin_data.orgAssign}}</span>
                    </div>
                    <div class="d-flex justify-content-end my-3">
                        <div id="filter" class="ml-2 filter-dropdown">
                            <button class="btn-primary-borderless-medium" id="filter-menu"
                                (click)="showFilterMenu = !showFilterMenu" data-toggle="dropdown">
                                <span class="d-flex align-items-center">
                                    <span class="mr-2">
                                        <img src="../../../../assets/svgs/filter_new.svg" />
                                    </span>
                                    <span>{{admin_data.filter}}</span>
                                </span>
                            </button>
                            <div *ngIf="showFilterMenu" class="dropdown-menu pb-0 dropdown-menu-app" role="menu"
                                [ngClass]="{'show': showFilterMenu}" id="filter-dropdown-menu">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left px-4 pt-3 mb-4">
                                    <div class="d-flex justify-content-between align-items-baseline">
                                        <span
                                            class="fontWeight-900 font16 text-color-dark">{{this.admin_data.filters}}</span>
                                        <div class="d-flex justify-content-end align-items-center">
                                            <span type="button"
                                                class="primaryFont-color mx-2 px-2 font13 fontWeight-700"
                                                (click)="clear()">
                                                {{this.admin_data.clear}}</span>
                                            <button type="button" class="btn btn-primary-small"
                                                (click)="applyFilter(status.value);$event.stopPropagation()">
                                                {{this.admin_data.apply}}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <div class=" form-group px-4 mb-4 width100">
                                        <label class="fontWeight-900 font14 text-color-dark-grey"
                                            for="status">{{this.admin_data.usersVariable2}}</label>

                                        <app-custom-select #status [dataList]="customSelectObj"
                                            (updateDataList)="status.value = $event;" [selected]="curr_status"
                                            componentName="default" dropdownPosition="inherit"
                                            [ngStyle]="{ 'position': 'relative' }"></app-custom-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="input-group w-50 ml-2 d-inline-flex width-300">
                            <input class="form-control py-2 search" type="search"
                                placeholder={{this.admin_data.searchPlaceholder}} autocomplete="off"
                                (input)="searchOrgList()" (search)="searchOrgList()" aria-label="Search"
                                [(ngModel)]="search_text">
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="orgList!=undefined">
                    <span *ngIf="orgList.length==0" style="margin-left: 45%;" class="padding50">{{admin_data.noDataFoundText}}</span>
                </ng-container>
                <div style="display: flex; flex-direction: column;">
                    <div class="d-flex scroll-x w-1240 table-div">
                        <table class="table  table-bordered break-w">
                            <thead *ngIf="orgList.length> 0">
                                <tr>
                                    <th *ngFor="let title of table_titles">
                                        <span class="thead-title-wrap">{{ title }}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-body-cust">
                                <tr *ngFor="let org of orgList; index as i">
                                    <td>{{org.title}}</td>
                                    <td><button class="btn btn-primary"
                                            (click)="changeMappingStatus(i, org.id, org.mappingStatus === 'assign'? 'unassign': 'assign')">
                                            {{org.mappingStatus === 'assign' ? admin_data.unassign : admin_data.assign
                                            }}
                                        </button></td>
                                    <!-- <td >{{org.mappingStatus}}</td> -->
                                    <!-- <td>
                            <svg-icon (click) = "performEdit(partner.id, $event)" src="../../../assets/svgs/emit_edit.svg"></svg-icon>
                            <svg-icon (click) = "showModal(partner.id, $event)" src="../../../assets/svgs/emit_delete.svg"></svg-icon>
                        </td> -->
                                </tr>
                        </table>
                    </div>
                </div>
                <app-pagination [offset]="offset" [totalRecords]="paginationService.total"
                    [currentPage]="paginationService.currentPage" [limit]="limit" (gotoFirstPage)="gotoFirstPage()"
                    (gotoLastPage)="gotoLastPage($event)" (gotoPreviousPage)="prependOffsetAndGetUsers()"
                    (gotoNextPage)="appendOffsetAndGetUsers()" (changeLimit)="changeLimit($event)"
                    (gotoAction)="gotoPage($event)"></app-pagination>

            </div>
        </section>
    </div>
</div>