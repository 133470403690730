import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

@Component({
    selector: 'app-multi-checkbox-dropdown',
    templateUrl: './multi-checkbox-dropdown.component.html',
    styleUrls: ['./multi-checkbox-dropdown.component.scss']
})
export class MultiCheckboxDropdownComponent implements OnInit, OnChanges {
    admin_data = adminLabels;
    showDropDown = false;
    attributeMapper = new Map();

    @Input() appList: any[];
    @Input() componentName: string = "customization";
    @Input() selectAll: boolean = false;
    @Input() className: string = "";
    @Input() className2: string = "";
    @Input() placeholder: string = "";
    @Input() searchAllowed: boolean = false;
    @Input() isDisabled: boolean = false;
    @Input() isSingleSelect: boolean = false;
    @Input() position: string = '';
    @Input() preSelection: string[] = [];
    @Output() updateAppList = new EventEmitter<any>();

    searchQuery: string = '';

    constructor(private elementRef: ElementRef) {
        this.attributeMapper.set("customization", ['app_id', 'app_name']);
        this.attributeMapper.set("guideInventory", ['item_id', 'item_text']);
        this.attributeMapper.set("exportGuides", ['item_id', 'item_text']);
        this.attributeMapper.set("generateCredentials", ['item_id', 'item_text'])
        this.attributeMapper.set("productUsage", ['item_id', 'item_text']);
        this.attributeMapper.set("tasklist", ["group_id", "title"])
    }

    ngOnInit() {
        this.updateSelection();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.preSelection && !changes.preSelection.isFirstChange()) {
            this.updateSelection();
        }
    }

    private updateSelection() {
        if (!this.isSingleSelect) {
            if (this.preSelection.length > 0) {
                this.appList = this.appList.map(app => {
                    app.checked = this.preSelection.includes(this.getId(app));
                    return app;
                });
            }
        }
    }

    openCloseDropdown = () => {
        this.showDropDown = !this.showDropDown;
        if (!this.showDropDown) {
            this.searchQuery = "";
        }
    }

    onSelect = (app_id: number) => {
        let index = this.appList.findIndex(app => {
            return this.getId(app) == app_id
        });
        if (this.isSingleSelect) {
            this.appList.forEach(app => app.checked = false);
        }
        if (index != -1) {
            let checkVal = this.appList[index].checked ? false : true;
            this.appList[index].checked = checkVal;
            if (this.componentName === "customization") {
                this.updateAppList.emit({ "checked": checkVal, "app_id": app_id });
            } else {
                let updateData = {
                    "type": checkVal ? "select" : "deselect",
                }
                updateData[this.attributeMapper.get(this.componentName)[0]] = this.getId(this.appList[index]);
                updateData[this.attributeMapper.get(this.componentName)[1]] = this.getName(this.appList[index])
                this.updateAppList.emit(updateData);
            }
        }
    }

    onSelectAll = () => {
        let checkVal = !this.getSelectAllStatus();
        this.appList.forEach(app => app.checked = checkVal);
        this.updateAppList.emit({
            "type": checkVal ? "selectAll" : "deselectAll"
        });
    }

    getSelectAllStatus = () => this.appList.every(app => app.checked);

    getId = (app) => app[this.attributeMapper.get(this.componentName)[0]];
    getName = (app) => app[this.attributeMapper.get(this.componentName)[1]];

    filteredAppList = () => {
        if (!this.searchQuery) {
            return this.appList;
        }
        return this.appList.filter(app =>
            this.getName(app).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event) {
        const targetElement = event.target as HTMLElement;
        if (this.showDropDown && !this.elementRef.nativeElement.contains(targetElement)) {
            this.showDropDown = false;
            this.searchQuery = "";
        }
    }

    @HostListener('document:scroll', ['$event'])
    onScrollOutside(event: Event) {
        if (this.showDropDown) {
            this.showDropDown = false;
            this.searchQuery = "";
        }
    }
}
