import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import adminLabels from '../../constants/ApplicationStrings/trans_utils.js'
import { BehaviorSubject, debounceTime } from 'rxjs';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

    @Input() totalRecords
    @Input() currentPage
    @Input() limit: number
    @Input() offset

    @Output() gotoFirstPage = new EventEmitter<void>();
    @Output() gotoPreviousPage = new EventEmitter<void>();
    @Output() gotoNextPage = new EventEmitter<void>();
    @Output() gotoLastPage = new EventEmitter<void>();
    @Output() gotoAction = new EventEmitter<number>();

    @Output() changeLimit = new EventEmitter<void>();

    totalPage;
    // noOfRows = [5, 10, 20, 50]5
    noOfRows = [{value: 5, name: "5"},{value: 10, name: "10"},{value: 20, name: "20"},{value: 50, name: "50"}]
    invalidGotoPage = false;
    private gotoPageSubject = new BehaviorSubject<number>(1);

    constructor() {

    }
    ngOnInit(): void {
        this.totalPage = Math.ceil(this.totalRecords / this.limit);
        this.gotoPageSubject.pipe(
            debounceTime(800))
            .subscribe((page: number) => {

                if (page != this.currentPage && page > 0 ) {
                    console.log(page, this.currentPage);
                    this.gotoAction.emit(page)
                }
            });
    }

    ngAfterViewChecked() {
        this.totalPage = Math.ceil(this.totalRecords / this.limit);

    }

    emitLimitChanged(event) {
        this.changeLimit.emit(event)
        this.totalPage = Math.ceil(this.totalRecords / this.limit);
    }

    gotoSubjectCall(event) {
        if (event.target.value === '') {
            this.invalidGotoPage = false;
        }
        else if (event.target.value > this.totalPage || event.target.value < 1) {
            this.invalidGotoPage = true;
        } else {
            this.gotoPageSubject.next(Number(event.target.value));
            this.invalidGotoPage = false;
        }
    }

    // ngAfterViewChecked() {
    //     console.log(this.currentPage + " Current Page " + this.totalPage + " Total Page");
    //     console.log(this.currentPage < this.totalPage);

    // }
    admin_data = adminLabels;
}
