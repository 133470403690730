import { Component } from '@angular/core';
import { CustomizationCentre, CustomizationCentreService } from 'src/app/common/services/customization.service';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import organization from '../organizationDefault';

declare var $: any;
declare var jquery: any;
@Component({
    selector: 'app-modal-notification-theme',
    templateUrl: './modal-notification-theme.component.html',
    styleUrl: './modal-notification-theme.component.scss'
})
export class ModalNotificationThemeComponent {

    admin_data = adminLabels;
    appId = '';
    app: CustomizationCentre = {};
    currentTab = "generalSettings";
    fontSizeArray = [
        { value: "xx-small", name: "xx-small", styles: { 'font-size': "xx-small" } },
        { value: "x-small", name: "x-small", styles: { 'font-size': "x-small" } },
        { value: "small", name: "small", styles: { 'font-size': "small" } },
        { value: "medium", name: "medium", styles: { 'font-size': "medium" } },
        { value: "large", name: "large", styles: { 'font-size': "large" } },
        { value: "x-large", name: "x-large", styles: { 'font-size': "x-large" } },
        { value: "xx-large", name: "xx-large", styles: { 'font-size': "xx-large" } },
    ]
    fontFamiliesArray = [
        { "value": "Lato", "name": "Lato", "styles": { "font-family": "Lato" } },
        { "value": "Arial", "name": "Arial", "styles": { "font-family": "Arial" } },
        { "value": "Comic Sans MS", "name": "Comic Sans MS", "styles": { "font-family": "Comic Sans MS" } },
        { "value": "Courier New", "name": "Courier New", "styles": { "font-family": "Courier New" } },
        { "value": "Cursive", "name": "Cursive", "styles": { "font-family": "Cursive" } },
        { "value": "Fantasy", "name": "Fantasy", "styles": { "font-family": "Fantasy" } },
        { "value": "Garamond", "name": "Garamond", "styles": { "font-family": "Garamond" } },
        { "value": "Georgia", "name": "Georgia", "styles": { "font-family": "Georgia" } },
        { "value": "Helvetica", "name": "Helvetica", "styles": { "font-family": "Helvetica" } },
        { "value": "Monospace", "name": "Monospace", "styles": { "font-family": "Monospace" } },
        { "value": "Narrow", "name": "Narrow", "styles": { "font-family": "Narrow" } },
        { "value": "Proxima Nova", "name": "Proxima Nova", "styles": { "font-family": "Proxima Nova" } },
        { "value": "Sans-Serif", "name": "Sans-Serif", "styles": { "font-family": "Sans-Serif" } },
        { "value": "Serif", "name": "Serif", "styles": { "font-family": "Serif" } },
        { "value": "Tahoma", "name": "Tahoma", "styles": { "font-family": "Tahoma" } },
        { "value": "Trebuchet MS", "name": "Trebuchet MS", "styles": { "font-family": "Trebuchet MS" } },
        { "value": "Verdana", "name": "Verdana", "styles": { "font-family": "Verdana" } },
        { "value": "Wide", "name": "Wide", "styles": { "font-family": "Wide" } }
    ]
    showAccesibility = true;

    constructor(private customizationService: CustomizationCentreService) { }
    openNotificationThemeModal = (app_id) => {
        $("#notifTheme").modal("show");
        this.appId = app_id;
        this.app = JSON.parse(
            JSON.stringify(
                this.customizationService.apps[this.customizationService.apps.findIndex(
                    (app) => { return app.appId === app_id }
                )]))
        this.currentTab = "generalSettings";
    }

    closePopup($event) {
        $event.stopPropagation();
        $("#notifTheme").modal("hide");
        delete this.app;
    }

    addAppToChanged(app) {
        if (this.customizationService.changedApps.length >= 1) {
            const pos = this.customizationService.changedApps.findIndex((app) => {
                return app.appId === this.appId
            })
            if (pos > -1) {
                this.customizationService.changedApps.splice(pos, 1)
                this.customizationService.changedApps.push(app)

            } else {
                this.customizationService.changedApps.push(app)
            }
        }
        else {
            this.customizationService.changedApps.push(app)
        }
    }

    getPropValue = (prop: string) => {
        // console.log(this.app.settings);

        let propValue = this.getPropertyValue(this.app.settings, "notificationTheme." + prop);
        if (JSON.stringify(propValue) != JSON.stringify("")) {
            return propValue;
        }
        return this.getPropertyValue(this.customizationService.apps[0].settings.notificationTheme, prop);
    }

    getPropertyValue = (o, prop) => {
        const [head, ...rest] = prop.split(".");
        if (rest.length && o.hasOwnProperty(head)) {
            return this.getPropertyValue(o[head], rest.join("."));
        } else {
            return o.hasOwnProperty(head) ? o[head] : "";
        }
    }

    getPopupVisibility = () => {
        if (this.app === undefined || this.app.appId === undefined)
            return false;
        return true;
    }

    getStyleForNotif = (prop) => {
        let prefixConst = "settings.notificationTheme.";
        if (prop != "shadow") {
            return this.getPropValue(prop);
        }
    }
    hasOwnProperty = (o: object, prop: string) => {
        let tempObj = { ...o };
        for (let level of prop.split(".")) {
            if (!tempObj.hasOwnProperty(level)) {
                return false;
            };
            tempObj = { ...tempObj[level] };
        }
        return true;
    }

    changeTab = (currentTab: string) => {
        this.currentTab = currentTab;
    }


    changeProp = (prop: String, val) => {
        this.changePropRecursive(this.app.settings, "notificationTheme." + prop, val);
    }

    changePropRecursive = (tempApp, prop, val) => {
        const [head, ...rest] = prop.split(".");
        if (rest.length) {
            if (!this.hasOwnProperty(tempApp, head)) {
                tempApp[head] = {};
            }
            this.changePropRecursive(tempApp[head], rest.join("."), val);
        } else {
            tempApp[head] = val;
        }
    }

    enforceMinMax = (event) => {
        let el = (event.target as HTMLInputElement);
        if (el.value != "") {
            if (parseInt(el.value) < parseInt(el.min)) {
                el.value = el.min;
            }
            if (parseInt(el.value) > parseInt(el.max)) {
                el.value = el.max;
            }
        }
    }

    preventInput = (event: Event) => {
        event.preventDefault();
    }

    save = ($event) => {
        this.customizationService.apps[
            this.customizationService.apps.findIndex(app => { return this.appId == app.appId })].settings = JSON.parse(JSON.stringify(this.app.settings))
        this.addAppToChanged(this.app);
        this.closePopup($event);
    }

    reset = () => {
        if (this.appId === '0') {
            this.app.settings.notificationTheme = JSON.parse(JSON.stringify(organization.settings.notificationTheme));
        } else {
            this.app.settings.notificationTheme = JSON.parse(JSON.stringify(this.customizationService.apps[0].settings.notificationTheme));
        }
    }

    hideAccessibility = () => {
        this.showAccesibility = false;
    }
}
