import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { API_END_POINTS } from 'src/app/constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { ApiService } from './api.service';
import { TasksService } from './tasks.service';

export interface LeaderBoardData{
  "image_url": string
  "full_name": string,
  "email": string,
  "progress": number
}

@Injectable({
  providedIn: 'root'
})
export class LeaderBoardService {

  constructor(
    private apiService: ApiService,
    private taskListService: TasksService
  ) { }

  titles=[adminLabels.addUserAvtar, adminLabels.user, adminLabels.addUserEmailId,adminLabels.tableHeaderProgress];
  DataKeys = ['image_url', 'full_name', 'email', 'progress'];

  col_widths = ['6%','8%','10%','12%'];


  getUsersList(limit,offset,search=''){
    let url = environment.baseUrl+API_END_POINTS.GET_USER_LIST;
    url+="?limit="+limit+"&offset="+offset+"&task_list_id="+this.taskListService.selectedTask.id;
    if(search!=''){
      url+='&search='+encodeURIComponent(search);
    }
    return this.apiService.get(url)
  }
}
