<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view" [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>

        <section class="component-section">
            <div class="sectionBody">
                <div class="d-flex mb-3">
					<div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
						{{this.admin_data.labelAPIConnector}}
					</div>
				</div>

                <div class="">
                    <app-form [formData]="api_connector_formData" [formGroup]="formGroup" (api_name)="setApiType($event)">
                    </app-form>
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center py-4">
                        <!-- <button type="button" class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
                            (click)="unassign()">{{this.admin_data.cancelButtonText}}</button> -->
                        <app-button [buttonData]="apiConnectorService.cancel_button" (clickhandler)="unassign()"></app-button>
                        <!-- <button type="button" class="btn btn-primary minWidth-120 mx-2" (click)="addConnector()"
                            [disabled]="!formGroup.valid">Save</button> -->
                        <app-button [disabled]="!formGroup.valid" [buttonData]="apiConnectorService.add_button" (clickhandler)="addConnector()"></app-button>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
</div>