<div [class]="checked? 'toggle-switch on': 'toggle-switch off'">
    <input (click)="toggle($event)" type="checkbox" [formControl]="control" [value]="value" [checked]="checked"
        class="{{inputClass}}" [disabled]="disabled">
    <div class="toggle-thumb" [class]="checked?'on':'off'"></div>
    <!-- <div class="toggle-icon" [class]="checked ? 'on' : 'off'">
        <ng-container *ngIf="checked else notChecked">
            <svg-icon src="../../../assets/svgs/tick.svg"></svg-icon>
        </ng-container>
        <ng-template #notChecked>
            {{'✖'}}
        </ng-template>
    </div> -->
</div>