import { Component, OnInit } from '@angular/core';
import { UserProvisioningService, Provision } from '../common/services/user-provisioning.service';
import { UserService } from '../common/services/user.service';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { OrganisationService } from '../common/services/organisation.service';
import{ USER_PROVISIONING_FORMDATA } from '../constants/FormData/user_provisioning';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import adminLabels from "../constants/ApplicationStrings/trans_utils";
declare var $:any;

@Component({
  selector: 'app-user-provisioning',
  templateUrl: './user-provisioning.component.html',
  styleUrls: ['./user-provisioning.component.css']
})
export class UserProvisioningComponent implements OnInit {

  org_id;
  selectedOrg;
  formGroup;
  form;
  provision_data:Provision;
  email_data;
  emailControl;
  show_api_key=false;
  api_key;
  show_api_secret=false;
  api_secret;
  show_api_token=false;
  api_token;
  breadcrumb;
  user_provisioning_formData=JSON.parse(JSON.stringify(USER_PROVISIONING_FORMDATA));

  copy1 = false;
  copy2 = false;
  copy3 = false;
  admin_data = adminLabels ;

  constructor(private userProvision:UserProvisioningService,public userService:UserService,private route:ActivatedRoute,private organisationService:OrganisationService,private errorResponseService : ErrorResponseService,private router:Router,private toastr:ToastrService,private titleService:Title) { 
    this.titleService.setTitle(this.admin_data.userProvisioningTitle+"Cornerstone Guide");
    this.userService.activeTab = 'user-provisioning';
    this.userService.getUserData();
    this.organisationService.getCDNData();
    this.email_data=this.user_provisioning_formData[1];
    this.route.paramMap.subscribe((params) => {
        this.org_id = params["params"]["org_id"];
        this.userService.organization_id = this.org_id;
      if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" )) {
        this.getOrganization();
    }
    });

    this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs(
                //     "User Provisioning",
                //     this.route.url["_value"]
                // );
                this.userService.setBreadcrumb([
                  {
                    name:this.admin_data.userProvisioningBreadCrumbTitle,
                    route:null
                  }
                ])
                this.breadcrumb=this.userService.breadCrumbs;
            });
  }

  getOrganization() {
    this.organisationService.getOrganization(this.org_id).subscribe(
        (response) => {
            let organization = response["data"]["organization"];

            this.userService.organization = organization["name"];
            this.userService.organization_id = organization["organization_id"];
            this.selectedOrg = this.userService.organization_id;
            // this.userService.user.cdn_signature = organization["cdn_signature"];
            this.userService.organization = organization;
            let admin_settings = JSON.parse(
                this.userService.organization.admin_settings
            );
            this.userService.enableSegments =
                admin_settings["guide_segmentation"] == "1" ? true : false;
            this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
            this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
            this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;

            this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
            let org = organization;
            let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

            cdn_sign["cdn_signature"] = organization["cdn_signature"];
            cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

            this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
            this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

            localStorage.setItem("organization", JSON.stringify(org));
            localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
            // this.ngOnInit();
        }
    );
}

  ngOnInit() {
    if (this.userService.user.role != "superadmin") {
      this.getProvisioningToken();
  } else if (
      this.userService.user.role == "superadmin" &&
      this.org_id == this.userService.organization_id
  ) {
      this.getProvisioningToken();
  }

  this.setForm();
  }


  setForm(){
    let form = {};
        for (let i = 0; i < this.user_provisioning_formData.length; i++) {
            form[this.user_provisioning_formData[i].name] = new FormControl("");
            let validations = [];
            for (let v of this.user_provisioning_formData[i].validators) {
                for (let key in v) {
                    validations.push(key);
                }
            }
            let validators = [];
            for (let j = 0; j < validations.length; j++) {
                switch (validations[j]) {
                    case "required":
                        validators.push(Validators.required);
                        break;

                    case "email":
                        validators.push(Validators.email);
                        break;
                }
            }
            form[this.user_provisioning_formData[i].name].setValidators(validators);
        }
        this.form = form;
        this.formGroup = new FormGroup(form);
        this.emailControl = this.formGroup.get("email_id");

  }
  getProvisioningToken(){
    this.userProvision.getProvisioningToken().subscribe((response)=>{
      if (response.hasOwnProperty('data')) {
        this.provision_data=response['data'];
        if(this.provision_data['provision_method']!='basic_token'){
          this.formGroup.removeControl("email_id");
          this.user_provisioning_formData.splice(1,1);
        }else{
          if(this.user_provisioning_formData.length==1){
            this.formGroup.addControl('email_id',this.emailControl);
            this.user_provisioning_formData.push(this.email_data);
          }
        }
        for(let i=0;i<this.user_provisioning_formData.length;i++){
          this.formGroup.patchValue({
            [this.user_provisioning_formData[i].name]:this.provision_data[this.user_provisioning_formData[i].name]
          })
        }
      }


    })
  }

  generateProvisionToken(){

    this.closeConfirmModal();
    this.show_api_key=false;
    this.show_api_secret=false;
    this.show_api_token=false;
    let request={
      'organization_id':this.org_id,
      'provision_token_type':this.formGroup.get("provision_method").value
    }

    if(this.formGroup.get("provision_method").value=='basic_token'){
      request['email_id']=this.formGroup.get("email_id").value;
    }

    this.userProvision.generateProvisionToken(request).subscribe((response)=>{
      if (!response['error']) {
        // this.userProvision.getProvisioningToken().subscribe((response)=>{

          this.provision_data=response['data'];

        if(this.provision_data['provision_method']!='basic_token'){
          if(this.provision_data['api_key']!=""){
            this.show_api_key=true;
            this.api_key=this.provision_data['api_key']
          }
  
          if(this.provision_data['api_secret']!=""){
            this.show_api_secret=true;
            this.api_secret=this.provision_data['api_secret'];
          }
  
          if(this.provision_data['provision_token']!=""){
            this.show_api_token=true;
            this.api_token=this.provision_data['provision_token'];
          }
          if(this.show_api_key || this.show_api_secret || this.show_api_token){
            this.showUserProvisionModal();
          }
        }
  
          // if(this.provision_data['provision_method']!='basic_token'){
          //   this.formGroup.removeControl(this.emailControl);
          //   // this.user_provisioning_formData.splice(1,1);
          // }else{
          //   if(this.user_provisioning_formData.length==1){
          //     this.formGroup.addControl(this.emailControl);
          //     this.user_provisioning_formData.push(this.email_data);
          //   }
          // }
          // console.log(this.user_provisioning_formData);
          for(let i=0;i<this.user_provisioning_formData.length;i++){
            this.formGroup.patchValue({
              [this.user_provisioning_formData[i].name]:this.provision_data[this.user_provisioning_formData[i].name]
            })
          }
          this.toastr.success(this.admin_data.tokenGenerated, this.admin_data.successfullyText, {
            timeOut: 2000
          });
        // })  
      } else {
        // this.toastr.error(response['message'][0], this.admin_data.failed, {
        //   timeOut: 2000
        // });
        let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
        this.userService.errorMessage=errorResponse;
        $("#errorModal").modal("show");
      }
    });

  }

  processMethod(obj){
    if(obj['value']!='basic_token'){
      this.formGroup.removeControl('email_id');
      this.user_provisioning_formData.splice(1,1);
    }else{
      if(this.user_provisioning_formData.length==1){
        this.formGroup.addControl('email_id',this.emailControl)
        this.user_provisioning_formData.push(this.email_data);
        // this.formGroup.patchValue({
        //   'email_id':''
        // })
      }
    }
    
  }

  showUserProvisionModal() {
    $("#provision_key_Modal").modal("show");
}

closeUserProvisionModal() {
    $("#provision_key_Modal").modal("hide");
}

showConfirmModal() {
  $("#confirmModal").modal("show");
}

closeConfirmModal() {
  $("#confirmModal").modal("hide");
}


copySuccess(key){
}


unassign() {
  this.router.navigate([
      "organization",
      this.userService.organization_id,
  ]);
}

  showCopiedText(optn){
    if(optn == 1){
      this.copy1 = true;
    } else if(optn == 2){
      this.copy2 = true;
    } else{
      this.copy3 = true;
    }


    setTimeout(() => {
      this.copy1 = false;
      this.copy2 = false;
      this.copy3 = false;

    }, 3000);
  }

}
