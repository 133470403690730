import { Component, OnInit } from '@angular/core';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { CustomizationCentreService } from 'src/app/common/services/customization.service';

declare var $: any;
declare var jquery: any;

@Component({
  selector: 'app-modal-tutorial',
  templateUrl: './modal-tutorial.component.html',
  styleUrls: ['./modal-tutorial.component.css']
})

export class ModalTutorialComponent implements OnInit {
  admin_data = adminLabels;
  appId;
  app;

  fontSizeArray = [
    {value: "xx-small", name: "xx-small", styles: {'font-size': "xx-small"}},
    {value: "x-small", name: "x-small", styles: {'font-size': "x-small"}},
    {value: "small", name: "small", styles: {'font-size': "small"}},
    {value: "medium", name: "medium", styles: {'font-size': "medium"}},
    {value: "large", name: "large", styles: {'font-size': "large"}},
    {value: "x-large", name: "x-large", styles: {'font-size': "x-large"}},
    {value: "xx-large", name: "xx-large", styles: {'font-size': "xx-large"}},
]

  constructor(private customizationService: CustomizationCentreService) { }

  ngOnInit() {
  }

  openTutorialModal = (app, appId) => {
    $("#tutorialStep").modal("show");
    this.app = JSON.parse(JSON.stringify(app));
    this.appId = appId;
  }

  getModalVisibility = () => {
    if (this.app === undefined || this.app.appId === undefined)
      return false;
    return true;
  }

  closePopup($event) {
    $event.stopPropagation();
    $("#tutorialStep").modal("hide");
    delete this.app;
  }

  reset = () => {
    this.app.settings.tutorial_guide = JSON.parse(JSON.stringify(this.customizationService.apps[0].settings.tutorial_guide));
  }

  changeProp = (prop: String, val) => {
    this.changePropRecursive(this.app.settings, "tutorial_guide." + prop, val);
  }

  changePropRecursive = (tempApp, prop, val) => {
    const [head, ...rest] = prop.split(".");
    if (rest.length) {
      if (!this.hasOwnProperty(tempApp, head)) {
        tempApp[head] = {};
      }
      this.changePropRecursive(tempApp[head], rest.join("."), val);
    } else {
      tempApp[head] = val;
    }
  }

  hasOwnProperty = (o: object, prop: string) => {
    let tempObj = { ...o };
    for (let level of prop.split(".")) {
      if (!tempObj.hasOwnProperty(level)) {
        return false;
      };
      tempObj = { ...tempObj[level] };
    }
    return true;
  }

  preventInput = (event: Event) => {
    event.preventDefault();
  }

  addAppToChanged(app) {
    if (this.customizationService.changedApps.length >= 1) {
      const pos = this.customizationService.changedApps.findIndex((app) => {
        return app.appId === this.appId
      })
      if (pos > -1) {
        this.customizationService.changedApps.splice(pos, 1)
        this.customizationService.changedApps.push(app)

      } else {
        this.customizationService.changedApps.push(app)
      }
    }
    else {
      this.customizationService.changedApps.push(app)
    }
  }

  getPropertyValue = (o, prop) => {
    const [head, ...rest] = prop.split(".");
    if (rest.length && o.hasOwnProperty(head)) {
      return this.getPropertyValue(o[head], rest.join("."));
    } else {
      return o.hasOwnProperty(head) ? o[head] : "";
    }
  }

  getPropValue = (prop: string) => {
    let propValue = this.getPropertyValue(this.app.settings, "tutorial_guide." + prop);
    if (JSON.stringify(propValue) != JSON.stringify("")) {
      return propValue;
    }
    return this.getPropertyValue(this.customizationService.apps[0].settings.tutorial_guide, prop);
  }

  getStyle = (prop) => {
    if(prop!="shadow"){
      return this.getPropValue(prop);
    }else{
      return this.getPropValue('outerBox.shadow.horizontal')+'px '+this.getPropValue('outerBox.shadow.vertical')+'px '
      +this.getPropValue('outerBox.shadow.blur')+'px '+this.getPropValue('outerBox.shadow.spreadRadius')+'px '+this.getPropValue('outerBox.shadow.color')
      +(this.getPropValue('outerBox.shadow.inset')==true?' inset':'');
    }
  }

  save = ($event) => {
    this.customizationService.apps[
        this.customizationService.apps.findIndex(app => { return this.appId == app.appId })].settings = JSON.parse(JSON.stringify(this.app.settings))
    this.addAppToChanged(this.app);
    this.closePopup($event);
  }

}
