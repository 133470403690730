import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { UserService } from "../../common/services/user.service";
import { ADD_USER_FORMDATA } from "../../constants/FormData/add_user";
import { UUID } from "../../constants/FormData/add_user";
import { SegmentationService } from "../../common/services/segmentation.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { ApplicationService } from "../../common/services/application.service";
import { OrganisationService } from "../../common/services/organisation.service";
import { filter } from 'rxjs/operators';
import { RoleService } from '../../common/services/role.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "../../common/services/toast-modal.service";
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { ADD_PARTNER_FORMDATA } from 'src/app/constants/FormData/add_partner';
import { PartnerService } from 'src/app/common/services/partner.service';
declare var $: any;
@Component({
    selector: 'app-create-partner',
    templateUrl: './create-partner.component.html',
    styleUrls: ['./create-partner.component.css']
})
export class CreatePartnerComponent implements OnInit {

    partner_id;
    allRoles = [];
    fetch_roles = false;
    fetch_segments = false;
    fetch_user = false;
    decline_message = '';
    formGroup: FormGroup;
    org_admin_settings;
    breadcrumb;
    isLXPUser = false;
    add_partner_formdata = JSON.parse(JSON.stringify(ADD_PARTNER_FORMDATA));
    uuid = JSON.parse(JSON.stringify(UUID));
    admin_data = adminLabels;
    descriptionValue = "";
    constructor(
        public userService: UserService,
        private segmentation: SegmentationService,
        private router: Router,
        private application: ApplicationService,
        private route: ActivatedRoute,
        private organizationService: OrganisationService,
        private roleService: RoleService,
        private toastr: ToastrService,
        private titleService: Title,
        private errorResponseService: ErrorResponseService,
        private toastService: ToastModalService,
        public partnerService: PartnerService) {
        this.userService.getUserData();

        this.route.paramMap.subscribe((params) => {
            if (params['params']['partner_id']) {
                this.partner_id = params['params']['partner_id'];
                console.log(this.partner_id);
                this.add_partner_formdata[2]['validators'].shift();
                this.router.events
                    .pipe(filter((event) => event instanceof NavigationEnd))
                    .subscribe(() => {
                        let breadCrumb = [
                            {
                                name: this.admin_data.partners,
                                route: ['/partners'],
                            }, {
                                name: this.admin_data.tableTitle2,
                                route: ['/partners', 'edit', this.partner_id]
                            }
                        ]
                        if (this.userService.organization_id === "000") {
                            let tempDashboardBreadCrumb = this.userService.getDashboardBreadCrumb();
                            breadCrumb.unshift(tempDashboardBreadCrumb);
                        }
                        this.userService.setBreadcrumb(breadCrumb)
                        this.breadcrumb = this.userService.breadCrumbs;
                    });
                this.add_partner_formdata[2]['label']['class'] = "font-weight-bold"
                this.titleService.setTitle(
                    this.admin_data.editPartner + " | Cornerstone Guide"
                );
                this.partnerService.isEdit = true;
                this.formGroup && this.setFormData();
            } else {
                this.router.events
                    .pipe(filter((event) => event instanceof NavigationEnd))
                    .subscribe(() => {
                        // this.userService.setBreadcrumbs(this.admin_data.themes, this.route.url["_value"]);
                        let breadCrumb = [
                            {
                                name: this.admin_data.partners,
                                route: ['/partners'],
                            }, {
                                name: this.admin_data.addButtonText,
                                route: ['/partners/add']
                            }
                        ];
                        if (this.userService.organization_id === "000") {
                            let tempDashboardBreadCrumb = this.userService.getDashboardBreadCrumb();
                            breadCrumb.unshift(tempDashboardBreadCrumb);
                        }
                        this.userService.setBreadcrumb(breadCrumb)
                        this.breadcrumb = this.userService.breadCrumbs;
                    });
                this.partnerService.isEdit = false;
                this.titleService.setTitle(
                    this.admin_data.addPartner + " | Cornerstone Guide"
                );
            }

        });
    }


    ngOnInit() {

        this.initialize()
    }
    initialize() {
        let form = {};
        for (let i = 0; i < this.add_partner_formdata.length; i++) {
            form[this.add_partner_formdata[i].name] = new FormControl("");
            let validations = [];
            for (let v of this.add_partner_formdata[i].validators) {
                for (let key in v) {
                    validations.push(key);
                }
            }
            let validators = [];
            for (let j = 0; j < validations.length; j++) {
                switch (validations[j]) {
                    case "required": {
                        validators.push(Validators.required);
                        break;
                    }
                    case "regex": {

                        validators.push(Validators.pattern(this.add_partner_formdata[i].validators[0]["regex"]));
                        break;
                    }
                    case "minvalue": {
                        validators.push(Validators.min(this.add_partner_formdata[i].validators[0]["minvalue"]));
                    }
                }
            }
            form[this.add_partner_formdata[i].name].setValidators(validators)  
        }
        this.formGroup = new FormGroup(form);
        if (this.partnerService.isEdit) this.setFormData();
    }

    editPartner() {
        let request = {
            id: this.partner_id,
            title: this.formGroup.get("title").value.trim(),
            description: this.formGroup.get("description").value.trim(),
             
        };
        if (this.formGroup.get('partner_admin_max_limit').value && this.formGroup.get('partner_admin_max_limit').value > 0) request['partner_admin_max_limit'] = Number( this.formGroup.get('partner_admin_max_limit').value)
        this.partnerService.editPartner(request).subscribe((response) => {
            if (response['error'] == false) {
                this.unassign()
            }
            else {
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                //console.log(errorResponse);                
                $("#errorModal").modal("show");
            }
        })

    }

    addPartner() {
        let request = {
            title: this.formGroup.get("title").value.trim(),
            description: this.formGroup.get("description").value.trim(),
            partner_admin_max_limit: Number(this.formGroup.get('partner_admin_max_limit').value )
        };
        this.partnerService.addPartner(request).subscribe((response) => {
            if (response['error'] == false) {
                this.unassign()
            } else {
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            }
        })

    }
    unassign() {
        this.router.navigate([
            "partners"
        ]);
    }

    setFormData() {
        for (let i = 0; i < this.add_partner_formdata.length; i++) {
            this.formGroup.patchValue({
                [this.add_partner_formdata[i].name]: this.partnerService.currPartner[
                    this.add_partner_formdata[i].name
                ],
            })
        }
    }

}
