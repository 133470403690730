import { Component, OnInit } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import {
    SegmentationService,
} from "../../../common/services/segmentation.service";
import { UserService } from "../../../common/services/user.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';
import { OrganisationService } from '../../../common/services/organisation.service';
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "src/app/common/services/toast-modal.service";
import adminLabels from "../../../constants/ApplicationStrings/trans_utils";
import { environment } from "src/environments/environment";

declare var $: any;
declare var AzureStorage: any;
@Component({
    selector: 'app-import-segment-value',
    templateUrl: './import-segment-value.component.html',
    styleUrls: ['./import-segment-value.component.scss']
})
export class ImportSegmentValueComponent {

    admin_data = adminLabels;
    selected_file;
    showFileError = false;
    file;
    csv = '';
    org_id;
    segment_id;
    breadcrumb;
    tasks; limit = 10;
    offset = 0;
    pages = [1, 2];
    currentPage = 1;
    prevIndex = 0;
    currentIndex = 1;
    total = 0;
    pageLimit: number = 0;
    disableNext = false;
    disablePrev = false;
    showPagination = false;
    taskType: string = "";
    importLabel: string = "";
    importHistoryLabel: string = "";
    constructor(
        public segmentation: SegmentationService,
        public userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private organisationService: OrganisationService,
        private titleService: Title,
        private errorResponseService: ErrorResponseService,
        private toastService: ToastModalService
    ) {
        this.userService.getUserData();
        this.titleService.setTitle(this.admin_data.userAttributes + " | " + "Cornerstone Guide");
        this.organisationService.getCDNData();
        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];
            this.segment_id = params["params"]["segment_id"];
        });
        if (this.segment_id) {
            this.initializeForSegmentValues();
        } else {
            this.initializeForSegments()
        }
    }

    ngOnInit() {
        this.getTaskList();
    }

    initializeForSegmentValues() {
        this.csv = '"VALUE"';
        this.importHistoryLabel = this.admin_data.importUserAttributeValuesHistory;
        this.importLabel = this.admin_data.importUserAttributeValues
        this.taskType = "segment_values_import";
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.userService.setBreadcrumb([
                    {
                        name: this.admin_data.userAttributes,
                        route: ['/organization', this.org_id, 'segmentation']
                    },
                    {
                        name: this.admin_data.userAttributeValues,
                        route: ['/segment-list', this.org_id, this.segment_id]
                    },
                    {
                        name: this.admin_data.importUserAttributeValues,
                        route: null
                    }
                ])
                this.breadcrumb = this.userService.breadCrumbs;
            });
    }

    initializeForSegments() {
        this.csv = '"KEY NAME","IS REQUIRED","VALUE"'
        this.importHistoryLabel = this.admin_data.importUserAttributesHistory;
        this.importLabel = this.admin_data.importUserAttributes
        this.taskType = "segments_import";
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.userService.setBreadcrumb([
                    {
                        name: this.admin_data.userAttributes,
                        route: ['/organization', this.org_id, 'segmentation']
                    },
                    {
                        name: this.admin_data.importUserAttributes,
                        route: null
                    }
                ])
                this.breadcrumb = this.userService.breadCrumbs;
            });
    }

    downloadSampleCSV() {
        let blob = new Blob([this.csv], { type: "application/csv;charset=utf-8;" });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser =
            navigator.userAgent.indexOf("Safari") != -1 &&
            navigator.userAgent.indexOf("Chrome") == -1;
        if (isSafariBrowser) {
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        let name = "sample_" + Math.floor(Date.now() / 1000).toString() + ".csv";
        dwldLink.setAttribute("download", name);
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    saveFile(file) {
        if (environment.isAzure) {
            this.selected_file = file;
            let names = file[0].name.split(".");
            if (file[0].name.split(".")[names.length - 1] != 'csv') {
                this.showFileError = true;
                return;
            } else {
                this.showFileError = false;
            }
            this.file = file[0];
        } else {
            if (file && file.length > 0) {
                let file_save: File = file.item(0);
                let fileReader: FileReader = new FileReader();
                fileReader.readAsText(file_save);
                fileReader.onload = (ev) => {
                    this.file = fileReader.result.toString();
                };
            }
        }
    }

    clickFileInput() {
        document.getElementById("validatedCustomFile").click();
    }

    upload() {
        let file_elem = document.getElementById("validatedCustomFile");
        this.userService.import_button.disable = true;
        this.userService.import_button.text = this.admin_data.uploadingButtonText;
        this.userService.getUploadedFile().subscribe((response) => {
            let uploadData = response["data"];
            let self = this;

            const blobService = AzureStorage.Blob.createBlobServiceWithSas(
                uploadData["upload_url"]["blob_uri"],
                uploadData["upload_url"]["signature"]
            );
            let customBlockSize = 0;

            if (this.file) {
                customBlockSize =
                    this.file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
                blobService.singleBlobPutThresholdInBytes = customBlockSize;
            }
            blobService.createBlockBlobFromBrowserFile(
                "csv",
                uploadData["upload_url"]["file_name"].split("/")[1],
                this.file,
                {
                    blockSize: customBlockSize,
                },
                function (error, result, response) {
                    if (error) {
                        alert("Your CSV is not uploaded, Please try again");
                    } else {
                        if (self.segment_id) {
                            let request = {
                                file_id: uploadData["upload_url"]["file_id"],
                                segment_id: self.segment_id
                            };
                            self.segmentation.importSegmentsValues(request).subscribe((response) => {
                                self.userService.import_button.disable = false;
                                self.userService.import_button.text = self.admin_data.uploadButtonText;
                                self.toastService.showToast = true;
                                self.toastService.toastType = "import_user";
                                self.file = "";
                                file_elem['value'] = null;
                                self.selected_file = null;
                                self.getTaskList();
                            },
                                (error) => {
                                    alert("Your CSV is not uploaded, Please try again");
                                }
                            );
                        } else {
                            let request = {
                                file_id: uploadData["upload_url"]["file_id"],
                            };
                            self.segmentation.importSegment(request).subscribe((response) => {
                                self.userService.import_button.disable = false;
                                self.userService.import_button.text = self.admin_data.uploadButtonText;
                                self.toastService.showToast = true;
                                self.toastService.toastType = "import_user";
                                self.file = "";
                                file_elem['value'] = null;
                                self.selected_file = null;
                                self.getTaskList();
                            },
                                (error) => {
                                    alert("Your CSV is not uploaded, Please try again");
                                }
                            );

                        }
                    }
                }
            );
        });
    }

    getTaskList() {
        this.segmentation
            .getTaskStatus(this.limit, this.offset, this.org_id, this.taskType)
            .subscribe((response) => {
                this.processResponse(response);
                if (this.toastService.showToast) {
                    this.toastService.showToastMessage(this.toastService.toastType);
                }
            });
    }

    processResponse(response) {

        this.tasks = response["data"]["tasks"];
        for (let i = 0; i < this.tasks.length; i++) {
            if (this.tasks[i].status == "DONE") {
                this.tasks[i].status = this.admin_data.done
            }
            else if (this.tasks[i].status == "STARTED") {
                this.tasks[i].status = this.admin_data.started
            }
            else if (this.tasks[i].status == "PENDING") {
                this.tasks[i].status = this.admin_data.pending
            }
            else if (this.tasks[i].status == "FAILED") {
                this.tasks[i].status = this.admin_data.failed
            }
        }
        if (this.currentPage > 1 && this.tasks.length < 1) {
            this.prependOffsetAndGetTasks();
        }
        this.segmentation
            .getTaskStatus(this.limit + 1, this.offset, this.org_id, this.taskType)
            .subscribe((res) => {
                this.userService.showLoader = false;
                if (this.tasks.length < this.limit) {
                    this.disableNext = true;
                } else {
                    this.disableNext = false;
                    this.showPagination = true;
                }

                if (this.currentPage == 1) {
                    this.disablePrev = true;
                } else {
                    this.disablePrev = false;
                    this.showPagination = true;
                }
                if (this.tasks.length == this.limit) {
                    if (res["data"]["tasks"].length > this.tasks.length) {
                        this.showPagination = true;
                    } else {
                        if (this.currentPage == 1) {
                            this.showPagination = false;
                        } else {
                            this.disableNext = true;
                            this.showPagination = true;
                        }
                    }
                }
            });

    }

    appendOffsetAndGetTasks() {
        this.disableNext = true;
        this.currentPage++;
        this.currentIndex = this.currentPage;
        if (this.currentPage > 0) {
            if (this.currentPage > this.pages[this.pages.length - 1]) {
                this.pages.shift();
                this.pages.push(this.currentPage);
            }
            this.offset += 10;
            this.getTaskList();
        }
    }

    prependOffsetAndGetTasks() {
        this.disablePrev = true;
        this.currentPage--;
        this.currentIndex = this.currentPage;
        if (this.currentPage > 0) {
            if (this.currentPage < this.pages[0]) {
                this.pages.pop();
                this.pages.unshift(this.currentPage);
            }
            this.offset -= 10;
            this.getTaskList();
        }

    }

    setIndex(ind, event) {
        if (this.disablePrev && this.disableNext) {
            event.preventDefault();
            return;
        }
        if (ind != this.currentIndex) {
            this.prevIndex = this.currentIndex;
            this.currentIndex = ind;
            this.currentPage = this.currentIndex;
            this.calculateOffsetAndGetTasks(this.prevIndex, this.currentIndex);
        }
    }

    calculateOffsetAndGetTasks(prev, current) {
        if (current > prev) {
            this.offset = (current - prev) * this.limit + this.offset;
        } else {
            this.offset = this.offset - (prev - current) * this.limit;
        }
        this.getTaskList();
    }

}
