import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, GuardsCheckEnd, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { ExportService, Task } from '../../common/services/export.service';
import { OrganisationService } from '../../common/services/organisation.service';
import { UserService } from '../../common/services/user.service';
import { ApplicationService } from '../../common/services/application.service';
import { ErrorResponseService } from "src/app/common/services/error-response.service";
import adminLabels from '../../constants/ApplicationStrings/trans_utils';

@Component({
  selector: 'app-export-guides',
  templateUrl: './export-guides.component.html',
  styleUrls: ['./export-guides.component.scss']
})
export class ExportGuidesComponent {

    org_id;
    selectedOrg;
    export_reports: Task[] = [];
    breadcrumb;

    current_tab = 1;
    dropdownList = [];
    selectedItems = [];
    allApplications = [];
    appListFlag: boolean = false;
    guideExportError = false;
    guideTasks;
    exportTasks;
    appsText = 'Applications'

    admin_data = adminLabels;
    selectedLang = {
        name: "English (United States)",
        language: "en-US",
        voice: "Jessa"
    };
    translatedLanguage = [];
    initalSelectedApps = [];

    constructor(public userService: UserService,
        public exportService: ExportService,
        private router: Router,
        public organisationService:
            OrganisationService,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private titleService: Title,
        private errorResponseService: ErrorResponseService,
        public applicationService: ApplicationService,
    ) {
        this.userService.activeTab = 'export_guides';
        this.titleService.setTitle(this.admin_data.exportData + " | " + "Cornerstone Guide")
        this.userService.getUserData();
        this.organisationService.getCDNData();

        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];
            this.userService.organization_id = this.org_id;
            this.organisationService.getOrganization(this.org_id).subscribe(
                (response) => {
                    let organization = response["data"]["organization"];

                    this.userService.organization = organization["name"];
                    this.userService.organization_id = organization["organization_id"];
                    this.selectedOrg = this.userService.organization_id;
                    // this.userService.user.cdn_signature = organization["cdn_signature"];
                    this.userService.organization = organization;
                    let admin_settings = JSON.parse(
                        this.userService.organization.admin_settings
                    );
                    this.translatedLanguage = admin_settings["language_settings"] && admin_settings["language_settings"]["translations"] ? admin_settings["language_settings"]["translations"] : [];
                    (admin_settings["language_settings"] && admin_settings["language_settings"]["default"]) && (this.selectedLang = admin_settings["language_settings"]["default"])
                    this.translatedLanguage.push(this.selectedLang);
                    this.userService.enableSegments =
                        admin_settings["guide_segmentation"] == "1" ? true : false;
                    this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
                    this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                    this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
                    this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
                    this.userService.enableMediaThemes = admin_settings['media_files_themes'] == 0 ? false : true;

                    let org = organization;
                    let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

                    cdn_sign["cdn_signature"] = organization["cdn_signature"];
                    cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

                    this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                    this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                    localStorage.setItem("organization", JSON.stringify(org));
                    localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
                    // this.ngOnInit();
                })
        })

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs("Users", this.route.url["_value"]);

                this.userService.setBreadcrumb([
                    {
                        name: this.admin_data.exportDataExportGuideButtonText,
                        route: null
                    }
                ])
                this.breadcrumb = this.userService.breadCrumbs;
            });
    }

    ngOnInit() {
        if (this.userService.user.role != "superadmin") {
            this.getGuideExportTaskStatus();
        } else if (
            this.userService.user.role == "superadmin" &&
            this.selectedOrg == this.userService.organization_id
        ) {
            this.getGuideExportTaskStatus();
        }

        // this.dropdownSettings = {
        //     singleSelection: false,
        //     enableCheckAll: true,
        //     idField: "item_id",
        //     textField: "item_text",
        //     selectAllText: "Select All",
        //     unSelectAllText: "Select All",
        //     itemsShowLimit: 3,
        //     allowSearchFilter: false,
        // };

        this.getApplications();

    }

    getApplications() {
        this.appListFlag = false;
        this.applicationService.getApplications(100, 0, '', '').subscribe(
            (response) => {
                let data = response["data"]['applications'];
                this.selectedItems = [];
                this.dropdownList = [];
                for (let itr0 = 0; itr0 < data.length; itr0++) {
                    this.dropdownList[itr0] = { item_id: data[itr0]['application_id'], item_text: data[itr0]['title'], isDisabled: undefined };
                    this.selectedItems.push({ item_id: data[itr0]['application_id'], item_text: data[itr0]['title'], isDisabled: undefined })
                    this.initalSelectedApps.push(data[itr0]['application_id']);
                }
                this.appListFlag = true;
            },
            (error) => {
            }
        );

    }

    updateAppList = (event) => {
        switch(event.type) {
            case "select": this.onItemSelect(event); break;
            case "deselect": this.onDeSelect(event); break;
            case "selectAll": this.onSelectAll(); break;
            case "deselectAll": this.onDeSelectAll(); break;
            default: return;
        }
    }

    onItemSelect(item: any) {
        this.guideExportError = false;
        if (!this.selectedItems.some(selectedItem => selectedItem.item_id === item.item_id)) {
            this.selectedItems.push({ item_id: item.item_id, item_text: item.item_text });
        }
    }
    onSelectAll() {
        this.guideExportError = false;
        this.selectedItems = this.dropdownList.map(item => ({ item_id: item.item_id, item_text: item.item_text }));
    }

    onDeSelect = (items: any) => this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.item_id !== items.item_id);
    
    onDeSelectAll = () => this.selectedItems = [];

    exportGuideList() {
        let appIds = [];
        console.log(this.selectedItems);
        for (let i = 0; i < this.selectedItems.length; i++) {
            appIds.push(this.selectedItems[i]['item_id'])
        }
        // let cnt = 0
        // for (let i = 0; i < document.querySelectorAll(".dropdown-list .multiselect-item-checkbox input").length; i++) {
        //     const element = (<HTMLInputElement>document.getElementsByTagName("input")[i])
        //     if (element.checked) {
        //         cnt++;
        //     }
        // }

        // if (cnt === 0) {
        //     appIds = [];
        // }

        if (this.userService.user.role == 'app_admin' && appIds.length == 0) {
            this.guideExportError = true;
        } else {
            let params = {
                organization_id: this.org_id,
                app_ids: appIds,
                language: this.translatedLanguage.find(el => el['name'] == this.selectedLang['name'])['language']
            }
            this.exportService.exportGuideList(params).subscribe((response) => {
                if (!response['error']) {
                    this.toastr.success(this.admin_data.availableSoon, this.admin_data.exportInProgress, {
                        timeOut: 2000
                    });
                } else {
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message']);
                    this.userService.errorMessage = errorResponse;
                    this.toastr.error(errorResponse, this.admin_data.error, {
                        timeOut: 2000
                    });
                }
                this.getGuideExportTaskStatus();
            })
        }
    }

    getGuideExportTaskStatus() {
        this.exportService.getGuideExportTaskStatus(this.org_id).subscribe((res) => {

            this.guideTasks = res["data"]["tasks"];
            for (let i = 0; i < this.guideTasks.length; i++) {
                if (this.guideTasks[i].status == "DONE") {
                    this.guideTasks[i].status = this.admin_data.done
                }
                else if (this.guideTasks[i].status == "STARTED") {
                    this.guideTasks[i].status = this.admin_data.started
                }
                else if (this.guideTasks[i].status == "PENDING") {
                    this.guideTasks[i].status = this.admin_data.pending
                }
                else if (this.guideTasks[i].status == "FAILED") {
                    this.guideTasks[i].status = this.admin_data.failed
                }
            }

            let report = [];

            if (!res['error']) {
                if (res['data']['tasks'].length > 0) {
                    for (let i = 0; i < res['data']['tasks'].length; i++) {
                        let task = res['data']['tasks'][i];

                        let data = {
                            ...res['data']['tasks'][i],
                            type: "Guide List Report",
                            export_guide_action: task['progress'] == '100' ? res['data']['tasks'][i]['task_url'] + this.organisationService.cdn_signature : '',
                            export_download_disabled: task['task_url'].length > 0 ? false : true
                        };
                        report.push(data);
                    }
                    this.export_reports = JSON.parse(JSON.stringify(report));
                }

            }
        })
    }

    changeLanguage(event) {
        console.log(event);
        this.selectedLang = this.translatedLanguage[event.target.selectedIndex];
        console.log(this.selectedLang);
    }

    isSelectedLanguage(language) {
        return this.selectedLang['name'] === language['name'];
    }
}
