<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModal"
aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="deleteSegModalLabel">{{this.admin_data.doYouWantToDelete}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <ng-container>
            <div class="modal-body">
                <div class="alert alert-danger m-0" role="alert">
                    <div><b class="text-dark overflow-wrap">{{elem}}</b></div>
                   <div *ngIf="show_email"><b class="text-dark overflow-wrap" >{{show_email}}</b></div>
                </div>
            </div>
        </ng-container>
        <div class="modal-footer justify-content-center">
            <button type="button" class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
                (click)="closeModal()">{{this.admin_data.cancelButtonText}}</button>
            <!-- <button type="button" class="btn btn-danger minWidth-120 mx-2" (click)="delete()">{{this.admin_data.deleteButtonText}}</button> -->
            <app-button [buttonData]="userService.delete_button" (clickhandler)="delete()"></app-button>

        </div>
    </div>
</div>
</div>
