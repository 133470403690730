import { Component, OnInit, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { OrganisationService } from '../common/services/organisation.service';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from '../common/services/toast-modal.service';
import { UserService } from '../common/services/user.service';
import { CHANGE_PASSWORD_FORMDATA, CURRENT_PASSWORD_FORMDATA } from '../constants/FormData/change_password';
import adminLabels from '../constants/ApplicationStrings/trans_utils';
declare var $: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  admin_data = adminLabels;
  constructor(
    public userService: UserService,
    private route: ActivatedRoute,
    public router: Router,
    private organisationService: OrganisationService,
    private toastr: ToastrService,
    private errorResponseService: ErrorResponseService,
    private toastService: ToastModalService,
    private _location: Location
  ) {

    this.route.queryParams.subscribe((params) => {
      if (params['user']) {
        this.user_email = decodeURIComponent(params['user']);
      } else {
        this.user_email = "";
      }
    })


    this.userService.getUserData();
    this.organisationService.getCDNData();
    this.route.paramMap.subscribe((params) => {

      this.org_id = params["params"]["org_id"];

      if (params['params']['user_id']) {
        this.user_id = params['params']['user_id'];
      }

      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          // this.userService.setBreadcrumbs("change_password",this.route.url["_value"]);
          if (params['params']['org_id']) {
            if (this.route.url["_value"][2]['path'] == 'change_password') {
              this.userService.setBreadcrumb([
                {
                  name: this.admin_data.headerVariable3,
                  route: null
                }
              ])
            } else {
              let breadCrumb = [
                {
                  name: adminLabels.usersBreadCrumbTitle,
                  route: ['/users']
                },
                {
                  name: adminLabels.changeUserPassword,
                  route: null
                }
              ];
              if (this.userService.organization_id === "000") {
                let tempDashboardBreadCrumb = this.userService.getDashboardBreadCrumb();
                breadCrumb.unshift(tempDashboardBreadCrumb);
              }
              this.userService.setBreadcrumb(breadCrumb)
            }
          }
          this.breadcrumb = this.userService.breadCrumbs;
        });
    });



  }

  //   getOrganization() {
  //     this.organisationService
  //         .getOrganization(this.org_id)
  //         .subscribe((response) => {
  //             let organization = response["data"]["organization"];
  //             this.userService.organization = organization["name"];
  //             this.userService.organization_id = organization["organization_id"];
  //             // this.userService.user.cdn_signature = organization["cdn_signature"];
  //             this.userService.organization = organization;
  //             let admin_settings = JSON.parse(
  //                 this.userService.organization.admin_settings
  //             );
  //             this.userService.enableSegments =
  //                 admin_settings["guide_segmentation"] == "1" ? true : false;
  //             this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
  //             this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
  //             this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;

  //             let org = organization;
  //             let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

  //             cdn_sign["cdn_signature"] = organization["cdn_signature"];
  //             cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

  //             this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
  //             this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

  //             localStorage.setItem("organization", JSON.stringify(org));
  //             localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
  //             this.ngOnInit();
  //         });
  // }


  change_password_formdata = JSON.parse(JSON.stringify(CHANGE_PASSWORD_FORMDATA));
  current_password_formdata = JSON.parse(JSON.stringify(CURRENT_PASSWORD_FORMDATA));
  password_formdata;
  form;
  formGroup;
  formModal;
  formGroupModal;
  org_id;
  user_id;
  user_email;
  breadcrumb;

  setPasswordValidator = false;
  length_check = false;
  digit_check = false;
  capital_check = false;
  small_letter_check = false; 
  specialchar_check = false;
  equal_check = false;
  showRules = false;

  ngOnInit() {
    if (this.router.url.includes('change_user_password')) {
      this.password_formdata = this.change_password_formdata;

      let formModal = this.createFormGroupInstance(this.current_password_formdata);
      this.formModal = formModal;
      this.formGroupModal = new FormGroup(formModal);
      // this.formGroupModal.setValidators(
      //   this.password.bind(this, formModal["new_password"], formModal["new_password_confirm"])
      // );

    } else {
      this.password_formdata = this.current_password_formdata.concat(this.change_password_formdata);
    }

    let form = this.createFormGroupInstance(this.password_formdata);
    this.form = form;
    this.formGroup = new FormGroup(form);

    this.formGroup.setValidators(
      this.password.bind(this, form["new_password"], form["new_password_confirm"])
    );
  }

  createFormGroupInstance(formData) {

    let form = {};
    for (let i = 0; i < formData.length; i++) {
      form[formData[i].name] = new FormControl("");
      let validations = [];
      for (let v of formData[i].validators) {
        for (let key in v) {
          validations.push(key);
        }
      }
      let validators = [];
      for (let j = 0; j < validations.length; j++) {
        switch (validations[j]) {
          case "required":
            validators.push(Validators.required);
            break;

          case "regex":
            validators.push(
              Validators.pattern(
                formData[i].validators[j]["regex"]
              )
            );

          case "maxLength":
            validators.push(Validators.maxLength);
            break;

          case "email":
            validators.push(Validators.email);
            break;

          case "custom":
            if (
              formData[i].validators[j]["custom"] ==
              "password()"
            ) {
              this.setPasswordValidator = true;
              validators.push(
                this.password.bind(
                  this,
                  form["new_password"],
                  form["new_password_confirm"]
                )
              );
            }
            break;
        }
      }
      form[formData[i].name].setValidators(validators);
    }

    return form;

  }

  password(password, confirmPassword) {
    return password.value === confirmPassword.value
      ? null
      : { passwordDoNotMatch: true };
  }

  ngAfterViewInit() {

    let form = this.formGroup;
    let self = this;
    document.getElementById("new_pwd").addEventListener("keyup", function () {
      self.showRules = true;
      if (form.get("new_password").value.length >= 8 && form.get("new_password").value.length <= 20) {
        self.length_check = true;
      } else {
        self.length_check = false;
      }
      if (form.get("new_password").value.match('[A-Z]')) {
        self.capital_check = true;
      } else {
        self.capital_check = false;
      }
        if (form.get('new_password').value.match('[a-z]')) {
          self.small_letter_check = true;
      } else {
          self.small_letter_check = false;
      }
      if (form.get("new_password").value.match('[0-9]')) {
        self.digit_check = true;
      } else {
        self.digit_check = false;
      }
      if (form.get("new_password").value.match("[$@$!#^~%*?&,.<>\"'\\;:{\\}\\[\\]\\|\\+\\-\\=\\_\\)\\(\\)\\`\\/\\\\\\]]")) {
        self.specialchar_check = true;
      } else {
        self.specialchar_check = false;
      }
      if (form.get("new_password").value == form.get("new_password_confirm").value) {
          self.equal_check = true;
      } else {
          self.equal_check = false;
      }
    })
    document.getElementById("confirm_new_pwd").addEventListener("keyup", function () {
      if (form.get("new_password").value == form.get("new_password_confirm").value) {
        self.equal_check = true;
      } else {
        self.equal_check = false;
      }
    })

  }

  changePassword() {
    if (this.router.url.includes('change_user_password')) {
      this.showModal();
    } else {
      this.savePassword();
    }
  }

  savePassword() {
    let request;
    let is_change_user_password = false;
    if (this.router.url.includes('change_user_password')) {
      is_change_user_password = true;
      request = {
        "user_id": this.user_id,
        "new_password": this.formGroup.get("new_password_confirm").value,
        "existing_password": this.formGroupModal.get("current_password").value
      }
    } else {
      request = {
        "user_id": this.user_id,
        "new_password": this.formGroup.get("new_password_confirm").value,
        "existing_password": this.formGroup.get("current_password").value
      }
    }
    this.userService.changePassword(request).subscribe((response) => {
      if (response['error'] == false) {
        this.toastService.showToast = true;
        this.toastService.toastType = "change_password";
        if (is_change_user_password) {
          //     this.router.navigate([
          //       "/organization",
          //       this.org_id,'users'
          //   ]);
          this._location.back()

        } else {
          this.router.navigate([
            "/organization",
            this.userService.organization_id,
          ]);
        }
      } else {
        this.userService.showModal.next(false);
        let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
        this.userService.errorMessage = errorResponse;
        $("#errorModal").modal("show");
      }
    })
  }


  unassign() {
    this._location.back()
    // if(this.router.url.includes('change_user_password')){
    //   this.router.navigate([
    //     "/organization",
    //     this.userService.organization_id,
    //     'users'
    // ])
    // }else{
    //   this.router.navigate([
    //     "/organization",
    //     this.userService.organization_id,
    // ])
    // }
  }

  showModal() {
    this.formGroupModal.patchValue({
      current_password: '',
    });
    $("#currentPasswordModal").modal("show");
  }

  closeModal() {
    $("#currentPasswordModal").modal("hide");
  }

  @HostListener('keyup', ['$event']) onKeyUp(e) {
    if (e.target.id == 'new_pwd') {
      this.showRules = true;
    }
  }

}
