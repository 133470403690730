import { Component, OnInit, ViewChild } from "@angular/core";
import { UserService } from "../common/services/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { OrganisationService } from '../common/services/organisation.service';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { environment } from "src/environments/environment";
import adminLabels from "../constants/ApplicationStrings/trans_utils";


declare var $: any;

@Component({
  selector: "app-otp",
  templateUrl: "./otp.component.html",
  styleUrls: ["./otp.component.scss"],
})
export class OtpComponent implements OnInit {
  user_unlock = false;
  user_email;
  loaderFlag=false;
  admin_data = adminLabels ;
  constructor(
    private fb: FormBuilder,
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private organisationService: OrganisationService,
    private errorResponseService : ErrorResponseService,
  ) {
    this.userService.getUserData();
    if (JSON.parse(localStorage.getItem("token"))) {
      this.user_email = JSON.parse(localStorage.getItem("token"))['email'];
    }
    this.route.paramMap.subscribe((params) => {
      let token = (params["params"]["token"]) ? params["params"]["token"] : false;
      if (token) {
        this.userService.token = token;
        this.user_unlock = true;
        this.ngOnInit();
      }
    });
  }

  // otpForm = this.fb.group({
  //   'otp':[null,[Validators.required,Validators.max(999999),Validators.min(100000)]]
  // })
  @ViewChild("ngOtpInput", {static: false}) ngOtpInputRef: any;
  otp = "";
  showError = false;
  count = 60;
  resend_active = false;
  recaptcha = '';
  unlocked = false;

  ngOnInit() {
    if (this.userService.isLoggedIn()) {

      const query = new URLSearchParams(window.location.search);
      const urlData = [];
      query.forEach((v, k) => {
        urlData[k] = v;
      });

      let urlString = '';
      for (let urlParam in urlData) {
        if (urlString.length === 0) {
          urlString += '?';
        } else {
          urlString += '%';
        }
        urlString += (urlParam + '=' + urlData[urlParam]);
      }

      if (urlString.includes('redirectURL')) {
        // this.router.navigate(['/loader'], { queryParams: urlData });
        this.loaderFlag = true;
      }

      if (urlString.includes('redirectURL')) {
        const urlLink = atob(urlData['redirectURL']);

        let token = localStorage.getItem('token');
        token = JSON.parse(token);

        const accessToken = token['accesstoken'];
        const refreshToken = token['refreshtoken'];


        if (urlLink.includes('logout')) {
          const data = {
            redirectURL: btoa('/dashboard')
          }

          this.router.navigate(['/login'], { queryParams: data });
          setTimeout(() => {
            localStorage.clear();
          }, 0)
        } else {
          if (accessToken && refreshToken) {
            this.userService.generateHandoffToken(accessToken, refreshToken).subscribe(
              (response1) => {
                if (response1['error']) {
                } else if (response1['data']["handoff-token"]) {
                  if (this.userService.user.role !== 'creator') {
                    const appendSign = urlLink.includes('dashboard') ? '?' : '&';
                    window.open(environment.INSIGHTS_URL + '/#' + urlLink + appendSign + 'handoff-token=' + response1["data"]["handoff-token"], "_self");
                  } else {
                    const appendSign = urlLink.includes('dashboard') ? '?' : '&';
                    window.open(environment.INSIGHTS_URL + '/#/survey/manage-survey/' + appendSign + 'handoff-token=' + response1["data"]["handoff-token"], "_self");
                  }
                } else {
                }
              },
              (error) => {

              }
            );
          } else {
            const data = {
              redirectURL: btoa('/dashboard')
            }
            this.router.navigate(['/login'], { queryParams: data });
          }
        }
      } 
      else {
        this.router.navigate([""]);
      }

      // uncomment else block if it causes issues; change "/loader" to "" if issue still persists 

    } else if (!this.userService.token) {
      this.router.navigate(["/login"]);
    }
    let self = this;
    this.runCounter();
    this.userService.user.token = '';
  }

  runCounter() {
    this.count = 60;
    let self = this;
    let t = setInterval(function () {
      self.count -= 1;
      if (self.count == 0) {
        self.resend_active = true;
        clearInterval(t);
      }
    }, 1000);
  }

  ngAfterViewInit() {
    let self = this;
    $(".otp-input").on("focus", function () {
      $(".otp-input").addClass("otp-input-focused");
      if (self.showError) {
        self.ngOtpInputRef.setValue("");
      }
      self.showError = false;
    });

    $(document).on("click", function (event) {
      if (!event.target.classList.contains("otp-input")) {
        $(".otp-input").removeClass("otp-input-focused");
      }
    });
  }

  submitOtp() {
    let req = {
      code: this.otp,
      token: this.userService.token,
      captcha: this.recaptcha
    };

    if (this.user_unlock) {
      this.unlockWithOtp(req);
    } else {
      this.loginWithOtp(req);
    }
  }

  loginWithOtp(req) {
    this.userService.loginWithOtp(req).subscribe(
      (response) => {

        let token = {};
        let organization = {};
        let user = {};

        // let appData = {};
        if (!response['error']) {
          if (this.user_unlock) {
            this.unlocked = true;
          } else {
            this.userService.user = response["data"]["user"];
            this.userService.user.token = response["data"]["user"]["accesstoken"];
            this.userService.user.organization_id = response["data"]["user"]["org_ids"][0];
            this.userService.user.role = response["data"]["user"]["role"];

            token["accesstoken"] = response["data"]["user"]["accesstoken"];
            token["refreshtoken"] = response["data"]["user"]["refreshtoken"];
            user = response["data"]["user"];

            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("token", JSON.stringify(token));

            if ((this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin"  )) {
              this.userService.organization.name = " ";
              this.userService.organization_id = "000";
              if(this.userService.user.role == "partner_admin")
              {
                this.userService.partner_id = response["data"]["user"]["partner_id"]
              }
            } 
            else {
                this.userService.organization_id = response["data"]["user"]["org_ids"][0];							
                this.organisationService.getOrganization(response["data"]["user"]["org_ids"][0])
                .subscribe((responseOrg) => {
                  organization = responseOrg["data"]["organization"];
                  this.userService.organization.name = responseOrg["data"]["organization"]["name"];
                  localStorage.setItem("organization", JSON.stringify(organization));
                });
            }

            // this.organisationService.cdn_signature = this.userService.user.cdn_signature;
            // this.organisationService.cdn_signature_expiry = this.userService.user.cdn_signature_expiry;
            // let cdn_sign = {};
            // organization = response["data"]["user"]["organization"];
            // cdn_sign["cdn_signature"] = this.organisationService.cdn_signature;
            // cdn_sign["cdn_signature_expiry"] = this.organisationService.cdn_signature_expiry;
            // appData["token"] = response["data"]["user"]["accesstoken"];
            // appData["organization_id"] = this.userService.user.organization_id;
            // appData["role"] = response["data"]["user"]["role"];
            // appData["organization"] = this.userService.user.organization.name;
            // appData["org"] = this.userService.user.organization;
            // appData["user"] = this.userService.user;
            // localStorage.setItem("appData", JSON.stringify(appData));
            // if ((this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin"  )) {
            //   localStorage.setItem("organization", JSON.stringify(organization));
            // }
            // localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));


            
            let login = localStorage.getItem("users")
              ? JSON.parse(localStorage.getItem("users"))
              : {};
            // if (
            //   !login[this.userService.user.user_email] ||
            //   login[this.userService.user.user_email]["expiry"] < Date.now()
            // ) {
            login[this.userService.user.user_email] = {};
            login[this.userService.user.user_email][
              "expiry"
            ] = this.userService.token_expiry;
            login[this.userService.user.user_email]["token"] = req["token"];
            localStorage.setItem("users", JSON.stringify(login));
            this.router.navigate([""]);
          }

        } else {
          this.showError = true;
          this.loaderFlag = false;
        }
      },
      (error) => {
      }
    );
  }

  unlockWithOtp(req) {
    this.userService.unlockWithOtp(req).subscribe(
      (response) => {
        if (!response['error']) {
          if (this.user_unlock) {
            this.unlocked = true;
          } else {

            const query = new URLSearchParams(window.location.search);
            const urlData = [];
            query.forEach((v, k) => {
              urlData[k] = v;
            });

            let urlString = '';
            let organization = {};
            for (let urlParam in urlData) {
              if (urlString.length === 0) {
                urlString += '?';
              } else {
                urlString += '%';
              }
              urlString += (urlParam + '=' + urlData[urlParam]);
            }

            if (urlString.includes('redirectURL')) {
                this.loaderFlag = true;
              // this.router.navigate(['/loader']);
            }

            this.userService.user = response["data"]["user"];
            this.userService.user.token = response["data"]["user"]["accesstoken"];
            // this.userService.user.organization_id = response["data"]["user"]["org_ids"][0];
            this.userService.user.role = response["data"]["user"]["role"];


            if ((this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin"  )) {
              this.userService.organization.name = " ";
              this.userService.organization_id = "000";
              if(this.userService.user.role == "partner_admin")
              {
                this.userService.partner_id = response["data"]["user"]["partner_id"]
              }
            } else {
                this.userService.organization_id = response["data"]["user"]["org_ids"][0];
                
                this.organisationService.getOrganization(response["data"]["user"]["org_ids"][0])
                .subscribe((responseOrg) => {
                  console.log(responseOrg)
                  organization = responseOrg["data"]["organization"];
 this.userService.organization.name = responseOrg["data"]["organization"]["name"];
  
                });
  
            }

            // this.organisationService.cdn_signature = this.userService.user.cdn_signature;
            // this.organisationService.cdn_signature_expiry = this.userService.user.cdn_signature_expiry;

            let token = {};

            let user = {};
            let cdn_sign = {};
            token["accesstoken"] = response["data"]["user"]["accesstoken"];
            token["refreshtoken"] = response["data"]["user"]["refreshtoken"];
            // organization = response["data"]["user"]["organization"];
            user = response["data"]["user"];
            // cdn_sign["cdn_signature"] = this.organisationService.cdn_signature;
            // cdn_sign["cdn_signature_expiry"] = this.organisationService.cdn_signature_expiry;

            localStorage.setItem("token", JSON.stringify(token));
            if ((this.userService.user.role != "superadmin" && this.userService.user.role != "mg_admin" && this.userService.user.role != "partner_admin")) {
              localStorage.setItem("organization", JSON.stringify(organization));
            }
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));

            let login = localStorage.getItem("users")
              ? JSON.parse(localStorage.getItem("users"))
              : {};

            login[this.userService.user.user_email] = {};
            login[this.userService.user.user_email][
              "expiry"
            ] = this.userService.token_expiry;
            login[this.userService.user.user_email]["token"] = req["token"];
            localStorage.setItem("users", JSON.stringify(login));
            if (urlString.includes('redirectURL')) {
              const accessToken = response["data"]["user"]["accesstoken"];
              const refreshToken = response["data"]["user"]["refreshtoken"];

              this.userService.generateHandoffToken(accessToken, refreshToken).subscribe(
                (response1) => {
                  if (response1["error"]) {
                  } else if (response1["data"]["handoff-token"]) {
                    const urlLink = atob(urlData['redirectURL']);
                    if (response["data"]["user"]["role"] !== 'creator') {
                      const appendSign = urlLink.includes('dashboard') ? '?' : '&';
                      window.open(environment.INSIGHTS_URL + '/#' + urlLink + appendSign + 'handoff-token=' + response1["data"]["handoff-token"], "_self");
                    } else {
                      const appendSign = urlLink.includes('dashboard') ? '?' : '&';
                      window.open(environment.INSIGHTS_URL + '/#/survey/manage-survey/' + appendSign + 'handoff-token=' + response1["data"]["handoff-token"], "_self");
                    }
                  } else {
                  }
                },
                (error) => {

                }
              );
            } else {
              this.router.navigate([""]);
            }
          }

        } else {
          if (response['code'] == 3046) {
            this.userService.showModal.next(false);
            let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
            this.userService.errorMessage=errorResponse;
            $("#errorModal").modal("show");
          } else if (response['code'] == 2002) {
            this.userService.showModal.next(false);
            let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
            this.userService.errorMessage=errorResponse;
            $("#errorModal").modal("show");
          }
          else {
            this.showError = true;
            this.loaderFlag = false;
            if (this.user_unlock) {
              grecaptcha.reset();
              this.recaptcha = '';
            }
          }
        }
      },
      (error) => {
      }
    );
  }

  resendOtp() {
    let token = JSON.parse(localStorage.getItem("token"));
    let req = {
      email: token["email"],
      token: token["token"],
    };
    this.userService.resendOtp(req).subscribe((response) => {
      this.resend_active = false;
      this.runCounter();
    });
  }

  onOtpChange(event) {
    this.otp = event;
    if (this.otp.length < 1) {
      this.showError = false;
    }
  }
}
