<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <ng-container>
            <section class="component-section">
                <div class="sectionBody">
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 section-header mb-2 p-0">
                        <span class="section-title">{{this.admin_data.partners}}</span>
                    </div>
                    <div class="d-flex justify-content-end my-3 align-items-center">
                        <div class="input-group w-50 ml-2 d-inline-flex width-300">
                            <input class="form-control py-2 search" type="search"
                                placeholder={{this.admin_data.searchPlaceholder}} autocomplete="off"
                                (input)="searchPartnerList()" (search)="searchPartnerList()" aria-label="Search"
                                [(ngModel)]="search_text">
                        </div>
                        <button type="button" class="btn btn-primary ml-2"
                            [routerLink]="['/partners/add']">{{this.admin_data.addPartner}}</button>

                    </div>
                </div>
            </section>
        </ng-container>
        <div style="display: flex; flex-direction: column;">
            <div class="d-flex scroll-x w-1240 table-div">

                <table class="table  table-bordered break-w">
                    <thead *ngIf="partnerService.partners.length> 0">
                        <tr>
                            <th *ngFor="let title of table_titles">
                                <span class="thead-title-wrap">{{ title }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="table-body-cust">
                        <tr *ngFor="let partner of partnerService.partners">
                            <td>{{partner.title}}</td>
                            <td>{{partner.description}}</td>
                            <td>
                                <span role="button"><svg-icon (click)="performEdit(partner.id, $event)"
                                        src="../../../assets/svgs/emit_edit.svg"></svg-icon></span>
                                <span role="button"><svg-icon (click)="showModal(partner.id, $event)"
                                        src="../../../assets/svgs/emit_delete.svg"></svg-icon></span>
                            </td>
                        </tr>
                </table>
            </div>
        </div>

    </div>
    <ng-container *ngIf="partnerService.currPartner">
        <app-modal-confirmation [header_value]="modalHeader" [content_value]="modalContent"
            [modal_ok_value]="this.userService.delete_button.text" [org]=""
            [disabled]="this.userService.delete_button.disable"
            (onDelete)="deletePartner($event)"></app-modal-confirmation>
    </ng-container>
</div>