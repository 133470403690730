import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, GuardsCheckEnd, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { ExportService, Task } from '../../common/services/export.service';
import { OrganisationService } from '../../common/services/organisation.service';
import { UserService } from '../../common/services/user.service';
import { ApplicationService } from '../../common/services/application.service';
import { ErrorResponseService } from "src/app/common/services/error-response.service";
import adminLabels from '../../constants/ApplicationStrings/trans_utils';

@Component({
    selector: 'app-export-users',
    templateUrl: './export-users.component.html',
    styleUrls: ['./export-users.component.scss']
})
export class ExportUsersComponent {
    org_id;
    selectedOrg;
    export_reports: Task[] = [];
    breadcrumb;

    current_tab = 1;
    dropdownList = [];
    selectedItems = [];
    allApplications = [];
    appListFlag: boolean = false;
    guideExportError = false;
    guideTasks;
    exportTasks;
    appsText = 'Applications'

    admin_data = adminLabels;
    translatedLanguage = [];

    constructor(public userService: UserService,
        public exportService: ExportService,
        private router: Router,
        public organisationService:
            OrganisationService,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private titleService: Title,
        private errorResponseService: ErrorResponseService,
        public applicationService: ApplicationService,
    ) {
        this.userService.activeTab = 'export_users';
        this.titleService.setTitle(this.admin_data.exportData + " | " + "Cornerstone Guide")
        this.userService.getUserData();
        this.organisationService.getCDNData();

        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];
            this.userService.organization_id = this.org_id;
            this.organisationService.getOrganization(this.org_id).subscribe(
                (response) => {
                    let organization = response["data"]["organization"];

                    this.userService.organization = organization["name"];
                    this.userService.organization_id = organization["organization_id"];
                    this.selectedOrg = this.userService.organization_id;
                    // this.userService.user.cdn_signature = organization["cdn_signature"];
                    this.userService.organization = organization;
                    let admin_settings = JSON.parse(
                        this.userService.organization.admin_settings
                    );
                    this.translatedLanguage = admin_settings["language_settings"] && admin_settings["language_settings"]["translations"] ? admin_settings["language_settings"]["translations"] : [];
                    this.userService.enableSegments =
                        admin_settings["guide_segmentation"] == "1" ? true : false;
                    this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
                    this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                    this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
                    this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
                    this.userService.enableMediaThemes = admin_settings['media_files_themes'] == 0 ? false : true;

                    let org = organization;
                    let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

                    cdn_sign["cdn_signature"] = organization["cdn_signature"];
                    cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

                    this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                    this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                    localStorage.setItem("organization", JSON.stringify(org));
                    localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
                    // this.ngOnInit();
                })
        })

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs("Users", this.route.url["_value"]);

                this.userService.setBreadcrumb([
                    {
                        name: this.admin_data.usersVariable14,
                        route: null
                    }
                ])
                this.breadcrumb = this.userService.breadCrumbs;
            });
    }

    ngOnInit() {
        if (this.userService.user.role != "superadmin") {
            this.getUserExportTaskStatus();
        } else if (
            this.userService.user.role == "superadmin" &&
            this.org_id == this.userService.organization_id
        ) {
            this.getUserExportTaskStatus();
        }
    }

    getUserExportTaskStatus() {
        let report1 = [];
        this.exportService.getUserExportData(this.org_id).subscribe((res) => {

            this.exportTasks = res["data"]["tasks"];
            for (let i = 0; i < this.exportTasks.length; i++) {
                if (this.exportTasks[i].status == "DONE") {
                    this.exportTasks[i].status = this.admin_data.done
                }
                else if (this.exportTasks[i].status == "STARTED") {
                    this.exportTasks[i].status = this.admin_data.started
                }
                else if (this.exportTasks[i].status == "PENDING") {
                    this.exportTasks[i].status = this.admin_data.pending
                }
                else if (this.exportTasks[i].status == "FAILED") {
                    this.exportTasks[i].status = this.admin_data.failed
                }
            }

            if (!res['error']) {
                if (res['data']['tasks'].length > 0) {
                    for (let i = 0; i < res['data']['tasks'].length; i++) {
                        let task = res['data']['tasks'][i];
                        if (!(task['progress'] === '100' && task['task_url'].length === 0)) {
                            let data = {
                                ...res['data']['tasks'][i],
                                type: "User List Report",
                                export_guide_action: task['progress'] == '100' ? res['data']['tasks'][i]['task_url'] + this.organisationService.cdn_signature : '',
                                export_download_disabled: task['task_url'].length > 0 ? false : true
                            };
                            report1.push(data);
                        }
                    }
                    this.export_reports = JSON.parse(JSON.stringify(report1));
                }
            }
        })
    }

    exportUserList() {
        let params = {
            organization_id: this.org_id
        }


        this.exportService.exportUserList(params).subscribe((response) => {
            if (!response['error']) {
                this.toastr.success(this.admin_data.availableSoon, this.admin_data.exportInProgress, {
                    timeOut: 2000
                });
                this.getUserExportTaskStatus();

            } else {
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message']);
                this.userService.errorMessage = errorResponse;
                this.toastr.error(errorResponse, this.admin_data.error, {
                    timeOut: 2000
                });
                this.getUserExportTaskStatus();

            }
        })
    }
}
