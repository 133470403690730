import { Component, OnInit } from "@angular/core";
import {
    ApiandservicesService,
    SecretKeyObject,
} from "../../common/services/apiandservices.service";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { UserService } from "../../common/services/user.service";
import { OrganisationService } from "../../common/services/organisation.service";
import { ApplicationService } from "../../common/services/application.service";
import { filter } from "rxjs/operators";
import { DashboardService } from "../../common/services/dashboard.service";
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "src/app/common/services/toast-modal.service";
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
declare var $: any;

@Component({
    selector: "app-apiandservices",
    templateUrl: "./apiandservices.component.html",
    styleUrls: ["./apiandservices.component.css"],
})
export class ApiandservicesComponent implements OnInit {
    secretKeyList: SecretKeyObject[];
    org_id;
    selectedOrg;
    deleteIndex = 0;
    delete_key_id;
    breadcrumb;

    admin_data = adminLabels ;
    modalHeader: any;
    modalContent: any;

    constructor(
        public apiAndServices: ApiandservicesService,
        private route: ActivatedRoute,
        public userService: UserService,
        private organisationService: OrganisationService,
        private applicationService: ApplicationService,
        private router: Router,
        private dbService: DashboardService,
        private toastr: ToastrService,
        private titleService: Title,
		private errorResponseService : ErrorResponseService,
        private toastService:ToastModalService
    ) {
        this.userService.getUserData();
        this.titleService.setTitle("API & Services" + " | " + "Cornerstone Guide");
        this.organisationService.getCDNData()
        this.userService.activeTab = 'api & services';
        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];
            this.userService.organization_id = this.org_id;
            if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" )) {
                this.getOrganization();
            }
        });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs(
                //     "API & Services",
                //     this.route.url["_value"]
                // );

                this.userService.setBreadcrumb([
                    {
                        name: "API & Services", 
                        route:null
                    }
                ])
                this.breadcrumb=this.userService.breadCrumbs;
                
            });
    }

    getOrganization() {
        this.organisationService.getOrganization(this.org_id).subscribe(
            (response) => {
                let organization = response["data"]["organization"];

                this.userService.organization = organization["name"];
                this.userService.organization_id = organization["organization_id"];
                // this.userService.user.cdn_signature = organization["cdn_signature"];
                this.userService.organization = organization;
                let admin_settings = JSON.parse(
                    this.userService.organization.admin_settings
                );
                this.userService.enableSegments =
                    admin_settings["guide_segmentation"] == "1" ? true : false;
                this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
                this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;                    
                this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
                this.userService.enableMediaThemes=admin_settings['media_files_themes'] == 0 ? false : true;

                let org = organization;
                let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

                cdn_sign["cdn_signature"] = organization["cdn_signature"];
                cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

                this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                localStorage.setItem("organization", JSON.stringify(org));
                localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));

                this.selectedOrg = this.userService.organization_id;
                // this.ngOnInit();
            }
        );
    }

    ngOnInit() {
        if (
            this.userService.user.role != "superadmin" ||
            (this.userService.user.role == "superadmin" &&
                this.selectedOrg == this.userService.organization_id)
        ) {
            this.getkeyList();
        }
    }

    getkeyList() {
        this.apiAndServices
            .getKeyList(this.userService.organization_id)
            .subscribe(
                (response) => {
                    this.secretKeyList = response["data"]["key_list"];
                    this.apiAndServices.keyApplications = [];
                    this.apiAndServices.assignApplications = [];
                    if(this.toastService.showToast){
                        this.toastService.showToastMessage(this.toastService.toastType);
                    }
                }
            );
    }

    showModal(key_id) {
        this.userService.showModal.next(true);
        this.secretKeyList.forEach((elem, i) => {
            if (elem.key_id == key_id) {
                this.deleteIndex = i;
                this.delete_key_id = elem.key_id;
            }
        });
        this.modalHeader = this.admin_data.deleteKey;
        let key = this.secretKeyList[this.deleteIndex]['key_name']
        let str = this.admin_data.deleteKeyContent
        str = str.split(' ')
        str.splice(str.length-1, 0,"'" + key + "'")
        this.modalContent = str.join(' ');
        this.organisationService.showConfirmationModal.next(true);
    }

    closeModal() {
        $("#deleteKeyModal").modal("hide");
    }

    deleteSecretKey(key) {
        this.userService.delete_button.disable=true;
        this.userService.delete_button.text=this.admin_data.deletingButtonText;
        this.apiAndServices.deleteSecretKey(this.delete_key_id).subscribe(
            (response) => {
                this.organisationService.showConfirmationModal.next(false);
                this.userService.delete_button.disable=false;
                this.userService.delete_button.text=this.admin_data.deleteButtonText;
                this.toastService.showToast=true;
                this.toastService.toastType="delete_secret_key";
                
                this.userService.showModal.next(false);
                this.getkeyList();
                this.deleteIndex = 0;
                
            }, error=>{
                this.organisationService.showConfirmationModal.next(false);
                this.userService.delete_button.disable=false;
                this.userService.delete_button.text=this.admin_data.deleteButtonText;
            }
        );
    }

    showRoles(secretKey) {
        this.applicationService.assignApp = true;
        this.router.navigate([
            "/organization",
            this.userService.organization_id,
            "application",
            "assign_app",
            secretKey['key_id'],
        ]);
    }

    editApiKey(key) {
        this.apiAndServices.secretKeyToEdit = key;
        this.router.navigate([
            "/organization",
            this.userService.organization_id,
            "edit_secret_key",
            key['key_id']
        ]);
        
    }
}
