import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Theme } from '../../theme.model';
import { ActivatedRoute } from '@angular/router';
import { ThemeService } from '../../../common/services/theme.service';
import { ToastrService } from 'ngx-toastr';
import adminLabels from "../../../constants/ApplicationStrings/trans_utils";

declare var $:any

@Component({
    selector: 'app-theme-middle-page',
    templateUrl: './theme-middle-page.component.html',
    styleUrls: ['./theme-middle-page.component.css']
})

export class ThemeMiddlePageComponent implements OnInit {
    @Input() theme: Theme;
    @Input() cdn_sign: string;
    @Input() org_id: number;
    @Output("updateTheme") updateTheme: EventEmitter<any> = new EventEmitter();
    theme_id: string;
    footer_logo_options: boolean;
    previous_footer_logo_url: string;
    selected_logo: File;
    header_img: boolean;
    header_border: boolean;
    binary_image: boolean;
    file_1;

    admin_data = adminLabels ;

    constructor(
        private route: ActivatedRoute,
        private themeService: ThemeService,
        private toastr: ToastrService
    ) { 
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.theme_id = params["params"]["theme_id"];
            this.header_img = (this.theme_id == 'theme-3') ? true : false;
            this.header_border = (this.theme_id == 'theme-1' || this.theme_id == 'theme-2') ? true : false;
        });
       
    }

    onSelectLogo(event) {
        if (event.target.files && event.target.files[0]) {
            this.selected_logo = event.target.files[0];

            // File Name and Extension.
            let file_name: string = this.selected_logo.name;
            let file_extension: string = file_name.replace(/^.*\./, '');
            let file_size: number = this.selected_logo.size;

            // Check if it is an image file.
            if ((file_extension == "png"
                || file_extension == "jpg"
                || file_extension == "jpeg")
                && file_size <= 2097152) {

                var reader = new FileReader();

                // read file as data url
                reader.readAsDataURL(event.target.files[0]);

                reader.onload = () => {
                    // called once readAsDataURL is completed
                    this.binary_image = true;
                    this.previous_footer_logo_url = this.theme.middle_page.footer_logo_url;
                    this.theme.middle_page.footer_logo_url = reader.result;
                    this.footer_logo_options = true;
                };
            } else {
                this.onCancelLogo();
                this.toastr.error(this.admin_data.invalidExtension, this.admin_data.failed, {
                    timeOut: 3000
                });
            }
        }
    }

    onCancelLogo() {
        if (this.previous_footer_logo_url != '' && this.previous_footer_logo_url != undefined){
            this.theme.middle_page.footer_logo_url = this.previous_footer_logo_url + this.cdn_sign;
        } else {
            this.theme.middle_page.footer_logo_url = '';
        }
        this.footer_logo_options = false;
        this.file_1=null;
        this.binary_image = false;
    }

    onSaveLogo(org_id: number) {
        let formData = new FormData();
        formData.append(
            "image",
            this.selected_logo
        );

        formData.append(
            "organization_id",
            org_id.toString()
        );

        this.themeService.getImageId(formData).subscribe(
            (response) => {
                let data = response["data"];
                this.footer_logo_options = false;
                this.theme.middle_page.footer_logo_id = parseInt(data["image_id"]);
                this.theme.middle_page.footer_logo_url = data["image_path"];
                this.updateTheme.emit();
                this.binary_image = false;
            },
            (error) => {
                this.toastr.error(this.admin_data.unableUpdateLogo, this.admin_data.failed, {
                    timeOut: 2000
                });
            }
        );
    }

    onSaveThemeColor() {
        this.updateTheme.emit();
    }

    getStepText() {
        if (this.theme_id == "theme-3" || this.theme_id == "theme-5") {
            return 'STEP ##';
        }
        return 'Step: ##';
    }

    addFooterStrip() {
        return (this.theme_id == "theme-3" || this.theme_id == "theme-5") ? false : true;
    }

    addHeaderStrip() {
        return (this.theme_id == "theme-3" || this.theme_id == "theme-5") ? false : true;
    }

    getStepNumStyle() {
        let css_class: string = "step-number";
        if (this.theme_id == 'theme-3') {
            css_class = "step-number-3";
        }
        if (this.theme_id == 'theme-5') {
            css_class = "step-number-5";
        }
        return css_class;
    }

    getStepTitleStyle() {
        let css_class: string = "step-title";
        if (this.theme_id == 'theme-3') {
            css_class = "step-title-3";
        }
        if (this.theme_id == 'theme-5') {
            css_class = "step-title-5";
        }
        return css_class;
    }

    showToolTip(){
        $('[data-toggle="tooltip"]').tooltip();
      }
    
      removeToolTip(){
        $('.tooltip').remove();
      }
}
