import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
    private appLoader: Subject<boolean> = new Subject<boolean>()
    private languageLoader: Subject<string> = new Subject<string>()
  constructor() { }



    setVariable(value: boolean) {
        this.appLoader.next(value);
    }
  
    getVariable() {
        return this.appLoader.asObservable();
    }

    setLanguage(value: string) {
        this.languageLoader.next(value);
    }

    getLanguage() {
        return this.languageLoader.asObservable();
    }



}
