<div class="d-flex">
  <div class="container-fluid content-container p-3 toggle-view" [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
      <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
      <section class="component-section">

        <div class="sectionBody">
            <div class="d-flex mb-3">
                <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                    {{this.admin_data.headerVariable3}}
                </div>
            </div>

            <div class="">
                <ng-container *ngIf="user_email">
                    <div class="displayFlex task-contentDiv innerDiv my-2" >
                    <div class="displayFlex partitionDiv">
                        <span class="title userTitle">{{this.admin_data.user}} : {{user_email}}</span>
                      </div>
                    </div>
                  </ng-container>
                  <div class="bg-light-grey p-3 mb-3" *ngIf="showRules">
                    <p class="mb-1"><b>{{this.admin_data.resetPassword}}</b></p>
                    <div [ngClass]="{'error color': !length_check}" class="text-color-dark d-flex font12">
                        {{this.admin_data.generatePasswordCharacterLimit}}
                    </div>
                    <div [ngClass]="{'error color': !digit_check}" class="text-color-dark d-flex font12">
                        {{this.admin_data.generatePasswordOneNumber}} <b>&nbsp;(0-9)</b>
                    </div>
                    <div [ngClass]="{'error color': !capital_check}" class="text-color-dark d-flex font12">
                        {{this.admin_data.generatePasswordOneUpperCase}}<b>&nbsp;(A-Z)</b>
                    </div>
                    <div [ngClass]="{'error color': !small_letter_check}" class="text-color-dark d-flex font12">
                        {{this.admin_data.generatePasswordOneLowerCase}}<b>&nbsp;(a-z)</b>
                    </div>
                    <div [ngClass]="{'error color': !specialchar_check}" class="text-color-dark d-flex font12">
                        {{this.admin_data.generatePasswordOneSpecialCharacter}}
                    </div>
                    <div [ngClass]="{'error color': !equal_check}" class="text-color-dark d-flex font12">
                        {{this.admin_data.generatePasswordShouldMatch}}
                    </div>
                </div>
                  <app-form [formData]="password_formdata" [formGroup]="formGroup">
                  </app-form>
                  <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center py-4">
                      <button type="button" class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
                          (click)="unassign()">{{this.admin_data.cancelButtonText}}</button>
                      <button type="button" class="btn btn-primary minWidth-120 mx-2" (click)="changePassword()"
                          [disabled]="!formGroup.valid">{{this.admin_data.saveButtonText}}</button>
                  </div>
              </div>
        </div>
    </section>
      
  </div>
</div>
<div class="modal fade" id="currentPasswordModal" tabindex="-1" role="dialog" aria-labelledby="currentPasswordModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="currentPasswordModal">{{this.admin_data.currentAdminPassword}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="router.url.includes('change_user_password')">
                    <app-form [formData]="current_password_formdata" [formGroup]="formGroupModal">
                    </app-form>
                </ng-container>
            </div>
            <div *ngIf="formGroupModal" class="modal-footer justify-content-center">
                <button type="button" class="btn btn-primary minWidth-120 mx-2" data-dismiss="modal"
                [disabled]="!formGroupModal.valid" (click)="savePassword()">{{this.admin_data.saveButtonText}}</button>
            </div>
        </div>
    </div>
</div>