import { Injectable } from "@angular/core";
import { Theme, lastPage, middlePage, firstPage } from "../../themes/theme.model";
import { environment } from "src/environments/environment";
import { ApiService } from "../services/api.service";
import { API_END_POINTS} from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

@Injectable({
  providedIn: "root",
})
export class ThemeService {

  current_date = new Date(Date.now());

  constructor(private apiService: ApiService) { }

  public getDefaultTheme(theme_id: string) {
    
    let theme_name = this.getThemeName(theme_id);
    let theme_color = this.getThemeColor(theme_id);
    let first_page: firstPage = {
      logo_id: 0,
      logo_url: false,
      footer_logo_id: 0,
      footer_logo_url: false,
    };
    let middle_page: middlePage = { footer_logo_id: 0, footer_logo_url: false };
    let last_page: lastPage;
    if (theme_id == "theme-3") {
      last_page = {
        title: "Brought to You by",
        lp_subtitle_1: "An in-support system tool where users find",
        lp_subtitle_2: "what they need to know when they need to know it",
        logo_id: 0,
        logo_url: false,
        footer_logo_id: 0,
        footer_logo_url: false,
      };
    } else {
      last_page = {
        title: "Thank You",
        lp_subtitle_1: false,
        lp_subtitle_2: false,
        logo_id: 0,
        logo_url: false,
        footer_logo_id: 0,
        footer_logo_url: false,
      };
    }

    return new Theme(
      theme_id,
      theme_name,
      false,
      theme_color,
      "myguide.org",
      first_page,
      middle_page,
      last_page
    );
  }

  public getThemeColor(theme_id: string) {
    let theme_color: string;
    switch (theme_id) {
      case "theme-2": {
        theme_color = "#0DAB7F";
        break;
      }
      case "theme-3": {
        theme_color = "#00AEEF";
        break;
      }
      case "theme-5": {
        theme_color = "#007DB9";
        break;
      }
      default: {
        theme_color = "#56E7CE";
        break;
      }
    }
    return theme_color;
  }

  public getThemeName(theme_id: string) {
    let theme_name: string;
    switch (theme_id) {
      case "theme-1": {
        theme_name = "Turquoise";
        break;
      }
      case "theme-2": {
        theme_name = "Niagara";
        break;
      }
      case "theme-3": {
        theme_name = "Cerulean";
        break;
      }
      case "theme-5": {
        theme_name = "Lochmara";
        break;
      }
      default: {
        theme_name = "New Theme";
        break;
      }
    }
    return theme_name;
  }

  public getImageId(request) {
    let urlPath = environment.baseUrl +API_END_POINTS.GET_IMAGE_ID;
    return this.apiService.post(urlPath, request);
  }
}
