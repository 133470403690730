<div class="d-flex">
	<div class="container-fluid content-container p-3 toggle-view"
		[ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
		<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
		<section class="component-section">
			<div class="sectionBody">

				<div class="d-flex mb-3">
					<div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
						{{this.admin_data.userProvisioningBreadCrumbTitle}}
					</div>
				</div>


				<div class="font14">
					<div class="font14">
						<b>{{this.admin_data.note}}</b>
						<br>
						<b>{{this.admin_data.usersProvisioningVariable30}}</b>
						<br>
						<b>{{this.admin_data.usersProvisioningVariable31}}</b>
						<br>
						<br>
					</div>
					<app-form [formData]="user_provisioning_formData" [formGroup]="formGroup"
						(provision_method)="processMethod($event)">
					</app-form>
					<div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center py-4">
						<button type="button" class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
							(click)="unassign()">{{this.admin_data.cancelButtonText}}</button>
						<button type="button" class="btn btn-primary minWidth-120 mx-2" (click)="showConfirmModal()"
							[disabled]="!formGroup.valid">{{this.admin_data.generateButtonText}}</button>
					</div>
		
					<div class="modal fade" id="provision_key_Modal" tabindex="-1" role="dialog"
						aria-labelledby="provision_key_Modal" aria-hidden="true">
						<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
							<div class="modal-content">
								<div class="modal-header">
									<h5 class="modal-title" id="provision_key_Modal">
										{{this.admin_data.userProvisioningBreadCrumbTitle}}</h5>
									<button type="button" class="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div class="modal-body">
									<div class="row w-80 m-auto">
										<div *ngIf="show_api_key"
											class="positionRelative ngxclipboard d-inline-flex col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14">
											<label
												class="font-weight-bold col-lg-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1">{{this.admin_data.ssoAPIKey}}</label>
											<input class="form-control col-lg-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 p-1" #clip1
												[value]="api_key" readonly>
											<button class="code-copy-btn d-block" type="button" [ngxClipboard]="clip1"
												[cbContent]="clip1.value" (cbOnSuccess)="copySuccess(clip1.value)"
												(click)="showCopiedText(1)">
												{{this.admin_data.copy}}
											</button>
											<span class="text-copy" *ngIf="copy1">{{this.admin_data.copied}}!</span>
										</div>
										<div *ngIf="show_api_secret"
											class="positionRelative ngxclipboard d-inline-flex col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14">
											<label class="font-weight-bold col-lg-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1">API
												Secret</label>
											<input class="form-control col-lg-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 p-1" #clip2
												[value]="api_secret" readonly>
											<button class="code-copy-btn d-block" type="button" [ngxClipboard]="clip2"
												[cbContent]="clip2.value" (cbOnSuccess)="copySuccess(clip2.value)"
												(click)="showCopiedText(2)">
												{{this.admin_data.copy}}
											</button>
											<span class="text-copy" *ngIf="copy2">{{this.admin_data.copied}}</span>
										</div>
										<div *ngIf="show_api_token"
											class="positionRelative ngxclipboard d-inline-flex col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14">
											<label
												class="font-weight-bold col-lg-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1">{{this.admin_data.usersProvisioningVariable2}}</label>
											<input class="form-control col-lg-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 p-1" #clip3
												[value]="api_token" readonly>
											<button class="code-copy-btn d-block" type="button" [ngxClipboard]="clip3"
												[cbContent]="clip3.value" (cbOnSuccess)="copySuccess(clip3.value)"
												(click)="showCopiedText(3)">
												{{this.admin_data.copy}}
											</button>
											<span class="text-copy" *ngIf="copy3">{{this.admin_data.copied}}</span>
										</div>
									</div>
		
									<div class="w-80 m-auto font14">
										<b>{{this.admin_data.note}}</b><br>
										{{this.admin_data.usersProvisioningVariable1}}<br>
									</div>
								</div>
								<div class="modal-footer justify-content-center">
									<button type="button" class="btn btn-secondary minWidth-120 mx-2"
										data-dismiss="modal"
										(click)="closeUserProvisionModal()">{{this.admin_data.okButtonText}}</button>
								</div>
							</div>
						</div>
					</div>
		
					<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="confirmModal"
						aria-hidden="true">
						<div class="modal-dialog modal-dialog-centered" role="document">
							<div class="modal-content">
								<div class="modal-header">
									<h5 class="modal-title" id="confirmModal">
										{{this.admin_data.userProvisioningBreadCrumbTitle}}</h5>
									<button type="button" class="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<ng-container>
									<div class="modal-body">
										<div class="alert m-0" role="alert">
											<b>{{this.admin_data.usersProvisioningVariable3}}</b>
										</div>
									</div>
								</ng-container>
								<div class="modal-footer justify-content-center">
									<button type="button" class="btn btn-secondary minWidth-120" data-dismiss="modal"
										(click)="closeConfirmModal()">{{this.admin_data.cancelButtonText}}</button>
									<button type="button" class="btn btn-primary minWidth-120"
										(click)="generateProvisionToken()">{{this.admin_data.yes}}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
	</div>
</div>