<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view" [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <div class="d-flex justify-content-end mb-3">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9 p-0 text-right ">
                <button  type="button" class="btn btn-primary ml-2" [disabled]="disabled" (click)="generatePlayerPackage()">{{this.admin_data.createPlayerPackage}}</button>
            </div>
        </div>
        <section class="component-section">

			<div class="sectionBody">
                <div class="d-flex mb-3">
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                        {{this.admin_data.playerPackages}}
                    </div>
                </div>
                <div class="">
                    
                <table class="table  table-bordered break-w">
                    <colgroup>
                        <col style="width: 20%;">
                        <col style="width: 20%;">
                        <col style="width: 20%;">
                        <col style="width: 20%;">
                        <col style="width: 20%;">
                    </colgroup>
                    <thead>
                        <tr>
                            <th *ngFor="let header of tableHeading; index as i">
                                {{header}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let package of packageList; index as i">
                            <td>{{i+1}}</td> 
                            <td>{{package['version']}}</td>                        
                            <td>{{package['status']}}</td>
                            <td>{{package['creation_date'] * 1000 | date: "EEE, MMM d, y" }}</td>
                            <td>
                                <a (click)="downloadFile(package['package']+cdn_signature, package['package'])" [ngStyle]="{
                                                        'pointer-events': package['package'] && package['package'].length > 0 ? 'visible' : 'none',
                                                        'color': package['package'] && package['package'].length > 0  ? 'blue' : '#cfd0dd' 
                                                        }" target="_blank" [download]=" package['package'] && package['package'].length > 0 ? package['package'].split('/')[-1] : ''">
                                    <h6  class="d-inline-block bottom-0 m-0 font13 fontWeight-700">
                                        {{this.admin_data.download}}
                                    </h6>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
        </section>
        
    </div>
</div>
