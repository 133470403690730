import { Injectable, NgModule } from '@angular/core';
import { HttpClientModule, HttpHeaders, HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import { API_END_POINTS } from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";


export interface Application {
  application_id: string,
  organization_id: string,
  title: string,
  description: string,
  mobile_app_id: string,
  image_id: string,
  settings: string,
  is_active: string,
  creation_date: string,
  modification_date: string,
  is_default: string,
  type: string,
  myintell_settings: {
    is_app_verified: boolean,
    is_tracking_enabled: boolean,
    is_authorized: boolean,
    allow_external_host: boolean
  },
  external_id: string,
  image_path: string,
  name: string,
  image_url: string,
}
@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  appTitles = [adminLabels.applicationsTableHeaderThumbnail, adminLabels.applicationsTableHeaderApplicationName, adminLabels.tableHeaderType, adminLabels.tableHeaderDescription, adminLabels.applicationsTableHeaderApplicationId, adminLabels.applicationsTableHeaderAutomationStatus, adminLabels.tableHeaderCreationDate, adminLabels.tableHeaderModificationDate, adminLabels.tableHeaderActions];

  appDataKeys = ['image_url', 'title', 'type', 'description', 'mobile_app_id', 'isAutomationEnabled', 'creation_date', 'modification_date', 'app_action'];

  col_widths = ['10%', '12%', '10%', '18%', '9%', '8%', '11%', '11%', '11%'];

  add_button = {
    type: 'add_application',
    class: 'btn btn-primary minWidth-120 mx-2',
    disable: false,
    text: adminLabels.addButtonText
  }

  update_button = {
    type: 'update_application',
    class: 'btn btn-primary minWidth-120 mx-2',
    disable: false,
    text: adminLabels.updateButtonText
  }

  cancel_button = {
    type: 'cancel',
    class: 'btn btn-secondary minWidth-120',
    disable: false,
    text: adminLabels.cancelButtonText
  }

  selectedApplication: Application;
  isEdit = false;

  assignApp = false;
  public isReloadedSub: Subject<HttpResponse<any>> = new Subject<HttpResponse<any>>();
  public simplemessageSub: Subject<string> = new Subject<string>();

  eventEmitted$ = this.isReloadedSub.asObservable();
  simplemessage$ = this.simplemessageSub.asObservable();

  constructor(private userService: UserService, private http: HttpClient, private apiService: ApiService) { }

  //avoid duplicates
  getApplications(limit, offset, search = '', type = '') {
    let urlPath;
    if (this.userService.organization_id == '000') {
      urlPath = environment.baseUrl + API_END_POINTS.GET_APPLICATIONS + "?limit=" + limit + "&offset=" + offset;
    } else {
      if (search == '') {
        urlPath = environment.baseUrl + "/application/list?limit=" + limit + "&offset=" + offset + "&organization_id=" + this.userService.organization_id;
      }
      else {
        urlPath = environment.baseUrl + "/application/list?limit=" + limit + "&offset=" + offset + "&organization_id=" + this.userService.organization_id + '&search_text=' + search;
      }
    }
    if (type != '') {
      urlPath += '&type=' + type;
    }
    return this.apiService.get(urlPath);
  }

  getApplication(app_id) {
    let urlPath = environment.baseUrl + API_END_POINTS.GET_APPLICATION + "?application_id=" + app_id;
    return this.apiService.get(urlPath);
  }

  getImageId(request) {
    let urlPath = environment.baseUrl + API_END_POINTS.GET_IMAGE_ID;
    return this.apiService.post(urlPath, request);
  }

  addApplication(request) {
    let urlPath = environment.baseUrl + API_END_POINTS.ADD_APPLICATION;
    return this.apiService.post(urlPath, request);
  }

  editApplication(request) {
    let urlPath = environment.baseUrl + API_END_POINTS.EDIT_APPLICATION;
    return this.apiService.put(urlPath, request);
  }

  deleteApplication(id) {
    let httpParams = new HttpParams().set('application_id', id);
    let urlPath = environment.baseUrl + API_END_POINTS.DELETE_APPLICATION;
    return this.apiService.delete(urlPath, httpParams);
  }

  returnEvent(input) {
    this.isReloadedSub.next(input);
  }

  sendMessage(input) {
    this.simplemessageSub.next(input);
  }

  loadTours(application_id, limit, offset) {
    let urlPath = environment.baseUrl + API_END_POINTS.GET_TOUR_LIST + '?limit=' + limit + '&offset=' + offset + '&organization_id=' + this.userService.organization_id +"&application_id="+application_id;
    return this.apiService.get(urlPath);
  }

  searchTours(application_id, limit, offset, search_text) {
    let urlPath = environment.baseUrl + API_END_POINTS.SEARCH_TOUR_LIST;
    urlPath+='?limit=' + limit + '&offset=' + offset + '&organization_id=' + this.userService.organization_id +"&application_id="+application_id +'&search_text=' + search_text;
    return this.apiService.get(urlPath);
  }

  updateTours(request) {
    let urlPath = environment.baseUrl + API_END_POINTS.UPDATE_TOUR;
    return this.apiService.put(urlPath, request);
  }
}
