import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { HostListener } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { Subject } from "rxjs";
import {
    Component,
    ViewChild,
    OnInit,
    ElementRef,
    AfterViewInit,
    Input,
    ChangeDetectorRef,
    HostBinding,
} from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { UserService } from "../services/user.service";
import { DashboardService } from "../services/dashboard.service";
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { environment } from "src/environments/environment";
import { OrganisationService } from "../services/organisation.service";
import { CustomizationCentreService } from "../services/customization.service";

declare var moment: any;

const equals = (one: any, two: any) =>
    one &&
    two &&
    two.year === one.year &&
    two.month === one.month &&
    two.day === one.day;

const before = (one: any, two: any) =>
    !one || !two
        ? false
        : one.year === two.year
            ? one.month === two.month
                ? one.day === two.day
                    ? false
                    : one.day < two.day
                : one.month < two.month
            : one.year < two.year;

const after = (one: any, two: any) =>
    !one || !two
        ? false
        : one.year === two.year
            ? one.month === two.month
                ? one.day === two.day
                    ? false
                    : one.day > two.day
                : one.month > two.month
            : one.year > two.year;

@Component({
    selector: "app-left-panel",
    templateUrl: "./left-panel.component.html",
    styleUrls: ["./left-panel.component.scss"],
})
export class LeftPanelComponent {
    close = false;
    scrolledAFterViewChecked = false;
    isSubmenu = false;

    @Input("closeNav") set closeNav(value: boolean) {
        this.close = value;
    }

    svgIcons = [
        "assets/img/ic_dashboard.svg",
        "assets/img/guideme_white.svg",
        "assets/img/showme_white.svg",
        "assets/img/testme_white.svg",
        "assets/img/survey_white_icon.svg",
        "assets/img/ic_survey_blueicon.svg",
    ];
    currentRoute: string;

    panelData;
    westpac_panelData;
    qaAutomator_panelData;

    showSubPanel = false;
    isCollapsed1 = false;
    counter = 3;

    hoveredDateR;
    yesterday;
    fromDateR;
    toDateR;
    defaultfromDate;
    defaulttoDate;
    fdateR;
    model2R;
    tdateR;
    SURVEY_QUESTION_DETAIL;
    d;
    d1;
    todayR: any;
    dp: any;
    _calendar;
    _showR = false;
    invalid_form = false;

    myGuide_val = 1;
    myShow_val = 1;
    myTest_val = 1;
    status;

    message;
    selectedVal;

    dateResult;
    weeks;
    singleDay;
    switchAppId;
    adminLink;
    serverEnv = environment['SERVER_ENV'] && environment['SERVER_ENV'] == 'WestPac' ? "WestPac" : '';
    hideTabs = true;
    sideNavOptns = [
        {
            title: "Dashboard",
            routerLink: "''",
        },
        {
            title: "API & Services",
            routerLink: "'#'",
        },
        {
            title: "Applications",
            routerLink: "'#'",
        },
    ];
    subOptions;
    org_id;
    // event_source = "Player";
    detail_flag = false;
    tickOptn;

    userActivity;
    userInactive: Subject<any> = new Subject();

    // @ViewChild(CalendarComponent) child: CalendarComponent;
    admin_data = adminLabels;

    constructor(
        private http: HttpClientModule,
        public router: Router,
        private titleService: Title,
        public userService: UserService,
        private cdRef: ChangeDetectorRef,
        private dbService: DashboardService,
        public organisationService: OrganisationService,
        private route: ActivatedRoute,
        private customizationService: CustomizationCentreService
    ) {
        router.events.subscribe((val) => {
            this.currentRoute = this.router.url;
        });

        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];
            if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin")) {
                this.getOrganization();
            }
        });
    }


    ngAfterViewChecked() {
        this.cdRef.detectChanges();
        if (this.userService.activeTab == 'customizationCentre') {
            this.isSubmenu = true;            
        } else {
            this.isSubmenu = false;
        }
    }

    selectSubmenu = (submenu) => { 
        if (this.userService.activeTab == 'customizationCentre') {
            this.customizationService.selectSubMenu(submenu)
        }
    }

    changeSubmenuState = () => {
        this.isSubmenu = !this.isSubmenu;
    }

    getOrganization() {
        this.organisationService
            .getOrganization(this.org_id)
            .subscribe((response) => {
                let organization = response["data"]["organization"];

                this.userService.organization = organization["name"];
                this.userService.organization_id = organization["organization_id"];
                // this.userService.user.cdn_signature = organization["cdn_signature"];
                this.userService.organization = organization;
                let admin_settings = JSON.parse(
                    this.userService.organization.admin_settings
                );
                this.userService.enableSegments =
                    admin_settings["guide_segmentation"] == "1" ? true : false;
                this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
                this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
                this.userService.enableMediaThemes = admin_settings['media_files_themes'] == 0 ? false : true;
                this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;

                let org = organization;
                let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));
                cdn_sign["cdn_signature"] = organization["cdn_signature"];
                cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

                this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                localStorage.setItem("organization", JSON.stringify(org));
                localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));

            });
    }

    inManageGuide() {        
        if (this.userService.activeTab == 'guides' || this.userService.activeTab == 'manage_keywords' || this.userService.activeTab == 'find_replace' || this.userService.activeTab == 'export_guides' || this.userService.activeTab == 'guide_inventory') {
            return true;
        }
        return false;
    }

    inSection(section: string) {
        switch (section) {
            case 'manage_guides': {
                if (this.userService.activeTab == 'guides' ||
                    this.userService.activeTab == 'manage_keywords' ||
                    this.userService.activeTab == 'find_replace' ||
                    this.userService.activeTab == 'export_guides' ||
                    this.userService.activeTab == 'guide_inventory') {
                    return true;
                }
                return false;
            }
            case 'segmentation': {
                if (this.userService.activeTab == 'segments' ||
                    this.userService.activeTab == 'manage_segments') {
                    return true;
                }
                return false;
            }

            case 'customization': {
                if (this.userService.activeTab == 'customizationCentre' || 
                this.userService.activeTab == 'themes') {
                    return true;
                }
                return false;
            }
            case 'administration': {
                if (this.userService.activeTab == 'admin settings' ||
                    this.userService.activeTab == 'applications' ||
                    this.userService.activeTab == 'api & services' ||
                    this.userService.activeTab == 'api connectors' ||
                    this.userService.activeTab == 'manage roles' ||
                    this.userService.activeTab == 'SSO' ||
                    this.userService.activeTab == 'users' ||
                    this.userService.activeTab == 'export_users' ||
                    this.userService.activeTab == 'user-provisioning') {
                    return true;
                }
                return false;
            }
            
        }
    }
}

// scroll(target) {
    //     switch (target) {
    //         case "branding": {
    //             this.scrollToRow(0);
    //             break;
    //         }
    //         case "widget": {
    //             this.scrollToRow(3);
    //             break;
    //         }
    //         case "videogif": {
    //             this.scrollToRow(9);
    //             break;
    //         }
    //         case "step": {
    //             this.scrollToRow(13);
    //             break;
    //         }
    //         case "notification": {
    //             this.scrollToRow(25);
    //             break;
    //         }
    //         case "chatbot": {
    //             this.scrollToRow(28);
    //             break;
    //         }
    //         case "features": {
    //             this.scrollToRow(35);
    //             break;
    //         }
    //         case "labels": {
    //             this.scrollToRow(49);
    //             break;
    //         }
    //     }
    // }

    // scrollToRow(rowNumber) {
    //     window.scroll(0, 0)
    //     let scrollableDiv = document.getElementById('tableContainer');
    //     if (scrollableDiv) {
    //         let tableRows = scrollableDiv.getElementsByTagName('tr');

    //         let scrollToPosition = 0;
    //         for (var i = 1; i < rowNumber; i++) {
    //             scrollToPosition += tableRows[i].offsetHeight;
    //         }
    //         scrollableDiv.scrollTop = scrollToPosition + 1;
    //     }
    // }