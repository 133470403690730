import adminLabels from "../ApplicationStrings/trans_utils";

export const CHANGE_PASSWORD_FORMDATA = [
  {
    type: "password",
    name: "new_password",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    validators: [
      {
        required: true,
      },
      {
        regex:
          "(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>\"'\\;:{\\}\\[\\]\\|\\+\\-\\=\\_\\)\\(\\)\\`\\/\\\\\\]]).{8,20}",
      },
    ],
    ui: {
      label: adminLabels.newPassword,
      class: "font-weight-bold",
      inputclass: "form-control",
    },
    input: {
      id: "new_pwd",
      class: "form-control",
      type: "password",
      name: "new_password",
      placeholder: adminLabels.newPassword,
      maxLength: 20,
      autocomplete: "off"
    },
    label: {
      title: adminLabels.newPassword,
      class: "font-weight-bold label-asterisk",
      for: "folder_name",
    },
  },
  {
    type: "password",
    name: "new_password_confirm",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    validators: [
      {
        required: true,
      },
      {
        regex:
          "(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>\"'\\;:{\\}\\[\\]\\|\\+\\-\\=\\_\\)\\(\\)\\`\\/\\\\\\]]).{8,20}",
      },
      {
        custom: "password()",
      },
    ],
    ui: {
      label: adminLabels.confirmNewPassword,
      class: "font-weight-bold",
      inputclass: "form-control",
    },
    input: {
      id: "confirm_new_pwd",
      class: "form-control",
      type: "password",
      name: "new_password_confirm",
      placeholder: adminLabels.confirmNewPassword,
      maxLength: 20,
      autocomplete: "off"
    },
    label: {
      title: adminLabels.confirmNewPassword,
      class: "font-weight-bold label-asterisk",
      for: "folder_name",
    },
  },
];

export const CURRENT_PASSWORD_FORMDATA = [
  {
    type: "password",
    name: "current_password",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    layoutModal: true,
    validators: [
      {
        required: true,
        maxLength: 1000,
      },
    ],
    ui: {
      label: adminLabels.currentPassword,
      class: "font-weight-bold col-lg-4",
      inputclass: "form-control col-lg-8",
    },
    input: {
      id: "current_password",
      class: "form-control",
      type: "password",
      name: "folder_name",
      placeholder: adminLabels.currentPassword,
      autocomplete: "off"
    },
    label: {
      title: adminLabels.currentPassword,
      class: "font-weight-bold label-asterisk",
      for: "folder_name",
    },
  },

];