<div class="modal fade" tabindex="-1" role="dialog" id="ruleModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{this.admin_data.createRules}}</h4>
            </div>
            <div class="modal-body">
                <div class="card">
                    <!-- <h5  class="card-title" style="margin-top: 10px; margin-left: 10px;">
                    {{this.admin_data.stepRules}}</h5> -->
                    <div class="card-body">
                        <div *ngFor="let section of parentRulesSections; index as i" class="group-div">
                            <div>
                                <div *ngIf="i>0" style="margin-bottom: 10px;">Or</div>
                                <div class="rule-div" *ngFor="let rule of section; index as j">
                                    <div *ngIf="j>0" style="margin-bottom: 10px;">And</div>
                                    <div class="display-flex align-items-center justify-content-start gap-10">
                                        <!-- <select class="form-control width-150" (change)="changeRuleType($event, j, i)">
                                            <option *ngFor="let item of rulesTypeLabel"
                                                [selected]="isSelectedRuleType(item, rule)">{{item}}</option>
                                        </select> -->
                                        <!-- <select class="form-control width-150"
                                            (change)="changeRuleCondition($event, j, i)">
                                            <option *ngFor="let item of rulesConditionLabel"
                                                [selected]="isSelectedRuleCondition(item, rule)">{{item}}</option>
                                        </select> -->
                                        <app-custom-select [dataList]="rulesTypeArray" preSelectOption="true"
                                            (updateDataList)="changeRuleType($event, j, i)"
                                            [ngStyle]="{'position': 'relative'}"
                                            dropdownPosition="inherit"
                                            [selected]="rule.type"
                                            componentName="default"></app-custom-select>

                                        <app-custom-select [dataList]="rulesConditionArray" preSelectOption="true"
                                            (updateDataList)="changeRuleCondition($event, j, i)"
                                            [ngStyle]="{'position': 'relative'}"
                                            dropdownPosition="inherit"
                                            [selected]="rule.condition"
                                            componentName="default"></app-custom-select>

                                        <input type="text" class="text-input" style="width: 200px !important;"
                                            (input)="changeRuleValue($event, j, i);validateRule(rule);"
                                            [value]="rule.value" [name]="'rule_value'+i">

                                        <div>
                                            <div style="width: 20px; height: 20px;color: green; display: contents;"
                                                *ngIf="validateRule(rule); else crossIcon"
                                                src="../../../assets/svgs/emit_delete.svg">&#10003;</div>
                                            <ng-template #crossIcon>
                                                <div style="width: 20px; height: 20px;color: red; display: contents;"
                                                    src="../../../assets/svgs/emit_delete.svg">&#10006;</div>
                                            </ng-template>
                                            <svg-icon style="margin-left: 10px;cursor: pointer;"
                                                src="../../../assets/svgs/emit_delete.svg"
                                                (click)="deleteRule($event, j, i)"></svg-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-outline-primary"
                                (click)="addRule(i)">{{this.admin_data.addRule}}</button>
                        </div>

                        <button class="btn btn-outline-primary"
                            (click)="addGroup()">{{this.admin_data.addGroup}}</button>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-discard btn-secondary" (click)="closePopup($event)">
                    {{this.admin_data.discardBtnTxt}}
                </button>
                <button type="button" class="btn btn-primary" [disabled]="!validateAllRules()" (click)="saveRules($event)">
                    {{this.admin_data.apply}}
                </button>
            </div>
        </div>
    </div>
</div>