import {
    Component,
    OnInit,
    ElementRef,
    ViewChildren,
    QueryList,
} from "@angular/core";
import { OrganisationService } from "../../common/services/organisation.service";
import { UserService } from "../../common/services/user.service";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { SSO_FORMDATA } from "../../constants/FormData/sso";
import { FormControl, Validators, FormGroup, FormArray } from "@angular/forms";
import { SSO_ADVANCED_FORMDATA } from "../../constants/FormData/sso";
import { SsoService } from "../../common/services/sso.service";
import { SegmentationService } from "../../common/services/segmentation.service";
import { SSOConfig } from "../../common/services/sso.service";
import { SSO_API_BASE_URL } from "../../common/services/sso.service";
import { filter, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "../../common/services/toast-modal.service";
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
declare var $: any;

@Component({
    selector: "app-sso",
    templateUrl: "./sso.component.html",
    styleUrls: ["./sso.component.css"],
})
export class SsoComponent implements OnInit {
    admin_data = adminLabels ;

    constructor(
        private organisationService: OrganisationService,
        public userService: UserService,
        private route: ActivatedRoute,
        private ssoService: SsoService,
        private segmentationService: SegmentationService,
        private router: Router,
        private toastr: ToastrService,
        private titleService: Title,
		private errorResponseService : ErrorResponseService,
        private toastService:ToastModalService
    ) {
        this.titleService.setTitle("SSO | " + "Cornerstone Guide");
        this.userService.getUserData();

        this.organisationService.getCDNData()
        this.userService.activeTab = 'SSO';
        this.route.paramMap.subscribe((params) => {
            if(params['params']['sso_id']){
                this.sso_id=params['params']['sso_id'];
            }
            this.org_id = params["params"]["org_id"];
            if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" )) {
                this.getOrganization();
            }
        });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs("SSO", this.route.url["_value"]);
                this.userService.setBreadcrumb([
                    {   
                       name:'SSO',
                       route:['/organization',this.org_id,'sso']
                    },
                    {
                        name:this.admin_data.tableTitle2,
                        route:null
                    }
                   ])
                this.breadcrumb=this.userService.breadCrumbs;
            });
    }

    sso_formData = JSON.parse(JSON.stringify(SSO_FORMDATA));
    SSO_API_BASE_URL = SSO_API_BASE_URL;
    sso_advanced_formData;
    show_Advanced = false;
    showAlgorithmSettigs = false;
    showX509Encryption = false;
    showSloUrl = false;
    attribute_list = ["first_name", "last_name", "email_id"].map(val => ({value: val, name: val}));
    sso_config: SSOConfig;
    alreadyPatchedX509Encryption = false;
    alreadyPatchedSloUrl = false;

    formGroup;
    org_id;
    sso_id;
    attribute_count = 1;
    breadcrumb;
    elem_map = [
        {
            text: "",
            select: "",
        },
    ];
    @ViewChildren("attributes") attributes: QueryList<ElementRef>;

    ngOnInit() {
        let form = {};
        for (let i = 0; i < this.sso_formData.length; i++) {
            form[this.sso_formData[i].name] = new FormControl("");
            let validations = [];
            for (let v of this.sso_formData[i].validators) {
                for (let key in v) {
                    validations.push(key);
                }
            }
            let validators = [];
            for (let j = 0; j < validations.length; j++) {
                switch (validations[j]) {
                    case "required":
                        validators.push(Validators.required);
                        break;

                    case "email":
                        validators.push(Validators.email);
                        break;
                }
            }
            form[this.sso_formData[i].name].setValidators(validators);
        }
        form["attributes"] = new FormArray([]);
        form["attributes"].push(
            new FormGroup({
                attr_name: new FormControl("first_name"),
                attr_segment: new FormControl("first_name"),
            })
        );
        this.formGroup = new FormGroup(form);
        this.addAdvanceFormControls();
        this.formGroup
            .get("issuer_url")
            .setValidators([
                Validators.required
                // ,
                // Validators.pattern(
                //     "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,})[/\\w .-]*/?"
                // ),
            ]);
        this.formGroup
            .get("sso_url")
            .setValidators([
                Validators.required,
                // Validators.pattern(
                //     "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,})[/\\w .-]*/?"
                // ),
            ]);
        this.segmentationService
            .getSegmentations(this.org_id)
            .subscribe((response) => {
                response["data"]["segments"].forEach((data) => {
                    this.attribute_list.push({value: data["key_name"], name: data["key_name"]});
                });
                this.getSsoConfig();
            });
    }

    getSsoConfig() {
        this.ssoService.getSsoConfig(this.sso_id).subscribe((response) => {
            this.sso_config = response["data"];
            for (let i = 0; i < this.sso_formData.length; i++) {
                if (this.formGroup.get(this.sso_formData[i].name)) {
                    this.formGroup.patchValue({
                        [this.sso_formData[i].name]: this.sso_config[
                            this.sso_formData[i].name
                        ],
                    });
                }
            }
            let attr_map = {};
            if(this.sso_config.attribute_map != "") {
                attr_map = JSON.parse(this.sso_config.attribute_map);
            }

            if (Object.keys(attr_map).length > 0) {
                Object.keys(attr_map).forEach((attr, i) => {
                    if (i == 0) {
                        this.formGroup.get("attributes")["controls"][0].patchValue({
                            attr_name: attr_map[attr],
                            attr_segment: attr,
                        });
                    } else {
                        this.addAttribute();
                        this.formGroup.get("attributes")["controls"][i].patchValue({
                            attr_name: attr_map[attr],
                            attr_segment: attr,
                        });
                    }
                });
            }
            let formData = JSON.parse(JSON.stringify(SSO_ADVANCED_FORMDATA));
            for (let i = 0; i < formData.length; i++) {
                if (
                    this.formGroup.get(formData[i].name) &&
                    (formData[i] != "signature_algorithm" ||
                        formData[i] != "digest_algorithm" ||
                        formData[i] != "sso_btn_message" ||
                        formData[i] != "sso_confirmation_message")
                ) {
                    this.formGroup.patchValue({
                        [formData[i].name]:
                            this.sso_config[formData[i].name] == "0" ? "no" : "yes",
                    });
                }
            }
            this.formGroup.patchValue({
                sso_btn_message: this.sso_config["sso_btn_message"],
                sso_confirmation_message: this.sso_config["sso_confirmation_message"],
            });
        });
    }

    getOrganization() {
        this.organisationService
            .getOrganization(this.org_id)
            .subscribe((response) => {
                let organization = response["data"]["organization"];

                this.userService.organization = organization["name"];
                this.userService.organization_id = organization["organization_id"];
                // this.userService.user.cdn_signature = organization["cdn_signature"];
                this.userService.organization = organization;
                let admin_settings = JSON.parse(
                    this.userService.organization.admin_settings
                );
                this.userService.enableSegments =
                    admin_settings["guide_segmentation"] == "1" ? true : false;
                this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
                this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;

                        this.userService.enableMediaThemes= admin_settings['media_files_themes'] == 0 ? false : true;


                        this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;

                if (!this.userService.enableSSO) {
                    this.router.navigate([
                        "/organization",
                        this.userService.organization_id
                    ]);
                }

                let org = organization;
                let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));
                cdn_sign["cdn_signature"] = organization["cdn_signature"];
                cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

                this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                localStorage.setItem("organization", JSON.stringify(org));
                localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));

            });
    }

    addAttribute() {
        if (this.attribute_count < this.attribute_list.length) {
            this.attribute_count += 1;
            this.elem_map = new Array(this.attribute_count);
            this.formGroup.get("attributes").push(
                new FormGroup({
                    attr_name: new FormControl(""),
                    attr_segment: new FormControl(""),
                })
            );
        }

    }

    remove(i) {
        this.elem_map.splice(i, 1);
        this.attribute_count -= 1;
        this.formGroup.get("attributes").removeAt(i);
    }

    showAdvanced() {
        this.show_Advanced = true;

        this.sso_advanced_formData = JSON.parse(
            JSON.stringify(SSO_ADVANCED_FORMDATA)
        );
        this.processAlgorithmControl();
    }

    addAdvanceFormControls() {
        let formData = JSON.parse(JSON.stringify(SSO_ADVANCED_FORMDATA));
        for (let i = 0; i < formData.length; i++) {
            let control;
            if (formData[i].type == "radio") {
                control = new FormControl("");
                this.formGroup.addControl(formData[i].name, control);
                this.formGroup.patchValue({
                    [formData[i].name]: "no",
                });
            } else {
                control = new FormControl("");
                this.formGroup.addControl(formData[i].name, control);
            }
        }
    }

    processAlgorithmControl() {
        if (
            this.formGroup.get("want_response_signed").value == "yes" ||
            this.formGroup.get("want_assertion_signed").value == "yes" ||
            this.formGroup.get("want_metadata_signed").value == "yes"
        ) {
            this.showAlgorithmSettigs = true;
            let control1 = new FormControl("rsa-sha256");
            let control2 = new FormControl("sha256");


            if (!this.formGroup.get("signature_algorithm") && !this.formGroup.get("digest_algorithm")) {
                this.formGroup.addControl("signature_algorithm", control1);
                this.formGroup.addControl("digest_algorithm", control2);

            }


            if (this.sso_config != undefined) {
                if ((this.sso_config["signature_algorithm"] != undefined) && (this.sso_config["signature_algorithm"].includes('#'))) {
                    this.formGroup.patchValue({
                        signature_algorithm: this.sso_config["signature_algorithm"].split(
                            "#"
                        )[1]
                    });
                }
            }


            if (this.sso_config != undefined) {
                if ((this.sso_config["digest_algorithm"] != undefined) && (this.sso_config["digest_algorithm"].includes('#'))) {
                    this.formGroup.patchValue({
                        digest_algorithm: this.sso_config["digest_algorithm"].split(
                            "#"
                        )[1]
                    });
                }
            }

        } else {

            if (
                this.formGroup.get("signature_algorithm") &&
                this.formGroup.get("digest_algorithm")
            ) {
                this.formGroup.removeControl("signature_algorithm");
                this.formGroup.removeControl("digest_algorithm");
            }
            this.showAlgorithmSettigs = false;
        }
        if (this.formGroup.get("want_same_cert_for_encryption").value == "yes") {
            this.showX509Encryption = true;
            let control = new FormControl("");
            if (!this.formGroup.get("encryption_x509_certificate")) {
                this.formGroup.addControl("encryption_x509_certificate", control);
                this.alreadyPatchedX509Encryption = false;
            }
            if (!this.alreadyPatchedX509Encryption) {
                this.formGroup.patchValue({
                    encryption_x509_certificate: this.sso_config[
                        "encryption_x509_certificate"
                    ],
                });
                this.alreadyPatchedX509Encryption = true;
            }
        } else {
            if (this.formGroup.get("encryption_x509_certificate")) {
                this.formGroup.removeControl("encryption_x509_certificate");
            }
            this.showX509Encryption = false;
        }

        if (this.formGroup.get("want_slo_enabled").value == "yes") {
            this.showSloUrl = true;
            let control = new FormControl("");
            if (!this.formGroup.get("slo_url")) {
                this.formGroup.addControl("slo_url", control);
                this.alreadyPatchedSloUrl = false;
            }
            if (!this.alreadyPatchedSloUrl) {
                this.formGroup.patchValue({
                    slo_url: this.sso_config["slo_url"],
                });
                this.alreadyPatchedSloUrl = true;
            }
        } else {
            if (this.formGroup.get("slo_url")) {
                this.formGroup.removeControl("slo_url");
            }
            this.showSloUrl = false;
        }
    }

    hideAdvanced() {
        this.show_Advanced = false;
        setTimeout(function () {
            this.sso_advanced_formData = [];
        }, 400);
    }

    unassign() {
        this.router.navigate([
            "organization",
            this.userService.organization_id,
        ]);
    }

    updateSsoConfig() {
        let attributes = {};
        let attr_formValues = this.formGroup.get("attributes").value;
        attr_formValues.forEach((attr) => {
            attributes[attr["attr_segment"]] = attr["attr_name"];
        });
        attributes = JSON.stringify(attributes);

        let digestUrl = '';
        if (this.formGroup.get("digest_algorithm") != null || this.formGroup.get("digest_algorithm") != undefined) {
            switch (this.formGroup.get("digest_algorithm").value) {
                case 'sha256':
                    digestUrl = 'http://www.w3.org/2001/04/xmlenc#sha256';
                    break;
    
                case 'sha384':
                    digestUrl = 'http://www.w3.org/2001/04/xmldsig-more#sha384';
                    break;
    
                case 'sha512':
                    digestUrl = 'http://www.w3.org/2001/04/xmlenc#sha512';
                    break;
            }
        }


        let sso_settings = {
            name:this.formGroup.get("name").value,
            description:this.formGroup.get("description").value,
            issuer_url: this.formGroup.get("issuer_url").value,
            sso_url: this.formGroup.get("sso_url").value,
            x509_certificate: this.formGroup.get("x509_certificate").value,
            attribute_map: attributes,
            want_response_signed:
                this.formGroup.get("want_response_signed").value == "yes" ? "1" : "0",
            want_assertion_signed:
                this.formGroup.get("want_assertion_signed").value == "yes" ? "1" : "0",
            want_metadata_signed:
                this.formGroup.get("want_metadata_signed").value == "yes" ? "1" : "0",
            want_message_signed:
                this.formGroup.get("want_message_signed").value == "yes" ? "1" : "0",
            signature_algorithm: this.showAlgorithmSettigs
                ? "http://www.w3.org/2001/04/xmldsig-more#" +
                this.formGroup.get("signature_algorithm").value
                : "",
            digest_algorithm: this.showAlgorithmSettigs
                ? digestUrl
                : "",
            want_nameId_encrypted:
                this.formGroup.get("want_nameId_encrypted").value == "yes" ? "1" : "0",
            want_same_cert_for_encryption:
                this.formGroup.get("want_same_cert_for_encryption").value == "yes"
                    ? "1"
                    : "0",
            encryption_x509_certificate:
                this.formGroup.get("want_same_cert_for_encryption").value == "yes"
                    ? this.formGroup.get("encryption_x509_certificate").value
                    : "",
            want_slo_enabled:
                this.formGroup.get("want_slo_enabled").value == "yes" ? "1" : "0",
            want_authn_request_signed:
                this.formGroup.get("want_authn_request_signed").value == "yes" ? "1" : "0",
            slo_url:
                this.formGroup.get("want_slo_enabled").value == "yes"
                    ? this.formGroup.get("slo_url").value
                    : "",
            sso_btn_message: this.formGroup.get("sso_btn_message").value,
            sso_confirmation_message: this.formGroup.get("sso_confirmation_message")
                .value,
            api_key: this.formGroup.get("api_key").value,
            api_secret: this.formGroup.get("api_secret").value,
        };

        let request = {
            sso_id: this.sso_id,
            sso_settings: JSON.stringify(sso_settings),
        };
        this.ssoService.updateSSOConfig(request)
            .subscribe((val) => {
                if (!val['error']) {
                    this.router.navigate([
                        "organization",
                        this.userService.organization_id,'sso'
                    ]);
                    this.toastService.showToast=true;
                    this.toastService.toastType="sso_config"
                } else{
                    this.userService.showModal.next(false);
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(val['message'][0]);
                    this.userService.errorMessage=errorResponse;
                    $("#errorModal").modal("show");
                }
            });
            
    }
}
