import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ErrorResponseService } from 'src/app/common/services/error-response.service';
import { OrganisationService } from 'src/app/common/services/organisation.service';
import { SegmentationService } from 'src/app/common/services/segmentation.service';
import { UserService } from 'src/app/common/services/user.service';
import { ManageSegmentsService } from 'src/app/manage-segments.service';
import adminLabels from "../../constants/ApplicationStrings/trans_utils.js";
import * as moment from 'moment';
import { ApplicationService } from 'src/app/common/services/application.service';
declare var $: any;

@Component({
    selector: 'app-create-segment',
    templateUrl: './create-segment.component.html',
    styleUrls: ['./create-segment.component.scss']
})
export class CreateSegmentComponent implements OnInit {

    constructor(
        public userService: UserService,
        public organizationService: OrganisationService,
        public manageSegmentsService: ManageSegmentsService,
        private segmentation: SegmentationService,
        private router: Router,
        private titleService: Title,
        private errorResponseService: ErrorResponseService,
        private route: ActivatedRoute,
        public applicationService: ApplicationService,
    ) {
        this.titleService.setTitle(
            this.admin_data.manageSegments + " | Cornerstone Guide"
        );
        this.userService.activeTab = 'manage_segments';
        this.userService.getUserData();
        this.route.paramMap.subscribe((params) => {
            if (!params['params']['segment_id']) {
                this.selectedSegments = [];
                this.parentRulesSections = this.getRulesInSections([]);
                this.isEdit = false
                this.userService.setBreadcrumb([
                    {
                        name: adminLabels.manageSegments,
                        route: ['/organization', this.userService.organization_id, 'manage_segments']
                    },
                    {
                        name: this.admin_data.addASegment,
                        route: ['/segments/add']
                    }
                ])
                this.initialize();
            } else {
                this.userService.setBreadcrumb([
                    {
                        name: adminLabels.manageSegments,
                        route: ['/organization', this.userService.organization_id, 'manage_segments']
                    },
                    {
                        name: this.admin_data.tableTitle2,
                        route: ['/segments/edit', params['params']['segment_id']]
                    }
                ])
                if (this.manageSegmentsService.group) {

                    this.selectedSegments = this.manageSegmentsService.group['segments'] ? JSON.parse(this.manageSegmentsService.group['segments']) : []
                    if (this.manageSegmentsService.group['settings']) {
                        this.advanceSettings = JSON.parse(this.manageSegmentsService.group['settings'])
                    }
                    // console.log(this.manageSegmentsService.group['rules']);
                    this.parentRulesSections = this.getRulesInSections(this.manageSegmentsService.group['rules'] ? JSON.parse(this.manageSegmentsService.group['rules']) : [])
                    // console.log(this.parentRulesSections);
                    // console.log(this.selectedSegments);
                    this.initialize();
                } else {
                    this.getSegments(params['params']['segment_id'])

                }
                this.isEdit = true
            }
            this.breadcrumb = this.userService.breadCrumbs;
        })
    }

    isEdit = false;
    segments = [];
    formData = new FormData();
    add_segment_formdata = [];
    formGroup;
    breadcrumb;
    admin_data = adminLabels;
    selectedSegments = [];
    rules = [];
    // rulesType = ["", "URL", "URL Hostname", "URL Path", "URL Parameters", "URL Hash", "Page Title", "Creation Date", "Date", "Time", "Display Frequency", "Variables"]
    // rulesURLCondition = ["", "Equals", "Not Equals", "Contains", "Does Not Contain", "Starts With", "Ends With"]
    // rulesDateCondition = ["", "Equals", "Greater Than", "Less Than"]
    // rulesDisplayFrequencyConditon = ["", "Day of the Week", "Fixed Time in the Day", "Date Range"]
    // rulesVariableCondition = ["", "Text Is", "Text Is Not", "Text Contains", "Does Not Contain", "Text Matches"]
    //rulesSections=[];

    rulesTypeArray = [
        { value: '', name: this.admin_data.select },
        { value: 'URL', name: this.admin_data.URL },
        { value: 'URL Hostname', name: this.admin_data.urlHostname },
        { value: 'URL Path', name: this.admin_data.urlPath },
        { value: 'URL Parameters', name: this.admin_data.urlParams },
        { value: 'URL Hash', name: this.admin_data.urlHash },
        { value: 'Creation Date', name: this.admin_data.userCreationDate },
        { value: 'Date', name: this.admin_data.date },
        { value: 'Time', name: this.admin_data.time },
        { value: 'Display Frequency', name: this.admin_data.displayFrequency },
        { value: 'Variables', name: this.admin_data.variables },
    ]

    rulesURLConditionArray = [
        { value: '', name: this.admin_data.select },
        { value: 'Equals', name: this.admin_data.equals },
        { value: 'Not Equals', name: this.admin_data.notEquals },
        { value: 'Contains', name: this.admin_data.contains },
        { value: 'Does Not Contain', name: this.admin_data.doesNotContain },
        { value: 'Starts With', name: this.admin_data.startsWith },
        { value: 'Ends With', name: this.admin_data.endsWith }
    ]

    rulesDateConditionArray = [
        { value: '', name: this.admin_data.select },
        { value: 'Equals', name: this.admin_data.equals },
        { value: "Greater Than", name: this.admin_data.greaterThan },
        { value: "Less Than", name: this.admin_data.lessThan }
    ]

    rulesDisplayFrequencyConditonArray = [
        { value: '', name: this.admin_data.select },
        { value: 'Day of the Week', name: this.admin_data.dayOfWeek },
        { value: "Fixed Time in the Day", name: this.admin_data.fixedTime },
        { value: "Date Range", name: this.admin_data.dateRange }
    ]

    rulesVariableConditionArray = [
        { value: '', name: this.admin_data.select },
        { value: "Text Is", name: this.admin_data.textIs },
        { value: "Text Is Not", name: this.admin_data.testIsNot },
        { value: "Text Contains", name: this.admin_data.textContains },
        { value: "Does Not Contain", name: this.admin_data.doesNotContain },
        { value: "Text Matches", name: this.admin_data.textMatches },
    ]

    parentRulesSections = [];
    // rulesTypeLabel = [this.admin_data.select, this.admin_data.URL, this.admin_data.urlHostname, this.admin_data.urlPath, this.admin_data.urlParams, this.admin_data.urlHash, this.admin_data.pageTitle, this.admin_data.userCreationDate, this.admin_data.date, this.admin_data.time, this.admin_data.displayFrequency, this.admin_data.variables]
    // rulesURLConditionLabel = [this.admin_data.select, this.admin_data.equals, this.admin_data.notEquals, this.admin_data.contains, this.admin_data.doesNotContain, this.admin_data.startsWith, this.admin_data.endsWith]
    // rulesDateConditionLabel = [this.admin_data.select, this.admin_data.equals, this.admin_data.greaterThan, this.admin_data.lessThan]
    // rulesDisplayFrequencyConditonLabel = [this.admin_data.select, this.admin_data.dayOfWeek, this.admin_data.fixedTime, this.admin_data.dateRange]
    // rulesVariableConditionLabel = [this.admin_data.select, this.admin_data.textIs, this.admin_data.testIsNot, this.admin_data.textContains, this.admin_data.doesNotContain, this.admin_data.textMatches]
    formIsValid = false;
    minDate = moment().toISOString().split("T")[0];
    variables = [];
    segmentOptionSelected = '';
    unselectedSegmentArray = [{id: "", key_name: this.admin_data.selectSegmentUserAttribute}];

    advanceSettings = {
        "show_guides_user_in_segment": 1,
        "hide_guides_user_in_segment": 0,
        "show_guides_user_not_in_segment": 0
    };
    ngOnInit() {
        // this.userService.getUserData()
        this.userService.getUserData();
        this.organizationService.getCDNData()
        // this.initialize()
    }

    initialize() {
        let form = {};
        this.getApplications();
        this.segmentation.getSegmentations(this.userService.organization_id).subscribe((response) => {
            // this.add_user_formdata = Object.assign([], formData);
            if (!response['error']) {
                if (response['data']['segments']) {
                    this.segments = response["data"]["segments"]
                };
                if (this.segments.length > 0) {
                    // console.log(this.manageSegmentsService.group['title']); 
                    this.add_segment_formdata = [];
                }
                this.segments.forEach((data) => {
                    this.unselectedSegmentArray.push(data);
                    if (this.selectedSegments && this.selectedSegments.length > 0) {
                        if (this.selectedSegments.find((seg => seg['id'] == data['id'] && seg['data'] != undefined && seg['data'].length > 0))) {
                            let seg = this.selectedSegments.find((seg => seg['id'] == data['id']))
                            if (this.add_segment_formdata.length > 0) {
                                let andHeading = {

                                    type: "heading",
                                    name: "AND",
                                    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 font14",
                                    value: "",
                                    checked: false,
                                    validators: [],
                                    text_class: "",
                                    ui: {
                                        label: "and",
                                        class: "",
                                        inputclass: "primary",
                                    }
                                }
                                this.add_segment_formdata.push(andHeading);
                            }
                            let field = {
                                type: "tag-input",
                                name: data["key_name"],
                                value: "",
                                selectedItems: [],
                                class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2 p-4 font14 tag-input-wrapper",
                                validators: [],
                                ui: {
                                    label: data["key_name"],
                                    class: data['is_required'] === "0" ? "tag-input-label" : "tag-input-label label-asterisk",
                                    inputclass: "col-lg-6",
                                }
                            };
                            field["items"] = [];
                            if (JSON.parse(data["data"]) != null) {
                                JSON.parse(data["data"]).forEach((value) => {
                                    field["items"].push({
                                        display: value,
                                        value: value,
                                    });
                                });
                                let dataValues = seg["data"];

                                if (typeof (dataValues) == 'string') {
                                    dataValues = JSON.parse(dataValues);
                                }
                                dataValues.forEach((val) => {
                                    field.selectedItems.push({
                                        display: val,
                                        value: val,
                                    });
                                });
                            }

                            form[data["key_name"]] = new FormControl("");
                            data['is_required'] == "1" && form[data["key_name"]].setValidators(Validators.required)
                            this.add_segment_formdata.push(field);
                            this.unselectedSegmentArray.pop();
                        }
                    }
                })

                this.formGroup = new FormGroup(form);
                if (!this.formGroup) {
                    form['segment_title'] = new FormControl('');
                    form['segment_title'].setValidators(Validators.required)
                    this.formGroup = new FormGroup(form);
                } else {

                    this.formGroup.addControl('segment_title', new FormControl('', Validators.required))
                }
                this.formGroup.patchValue({ 'segment_title': this.manageSegmentsService.group && this.manageSegmentsService.group['title'] ? this.manageSegmentsService.group['title'] : "" })
            }
            this.formGroup && this.formGroup.valueChanges.subscribe(selectedValue  => {
                this.formIsValid = this.isValidForm() || this.isValidRule();  
            })
        })
    }

    // getRulesConditionArray(type: string) {
    //     if (type === 'Creation Date' || type === 'Date' || type === 'Time') {
    //         return this.rulesDateCondition
    //     } else if (["URL", "URL Hostname", "URL Path", "URL Parameters", "URL Hash", "Page Title"].indexOf(type) !== -1) return this.rulesURLCondition;
    //     else if (type === 'Display Frequency') {
    //         return this.rulesDisplayFrequencyConditon
    //     }else if(type === 'Variables'){
    //         return this.rulesVariableCondition
    //     }
    //     else return [];
    // }
    // getRulesConditionLabelArray(type: string) {
    //     if (type === 'Creation Date' || type === 'Date' || type === 'Time') {
    //         return this.rulesDateConditionLabel
    //     } else if (["URL", "URL Hostname", "URL Path", "URL Parameters", "URL Hash", "Page Title"].indexOf(type) !== -1) return this.rulesURLConditionLabel;
    //     else if (type === 'Display Frequency') {
    //         return this.rulesDisplayFrequencyConditonLabel
    //     } else if(type === 'Variables'){
    //         return this.rulesVariableConditionLabel
    //     }
    //     else return []
    // }
    // isSelectedRuleType(item, rule) {
    //     return this.getRuleType(rule) === item;
    // }
    // isSelectedRuleCondition(item, rule) {
    //     return this.getRuleCondition(rule).toLowerCase() === item.toLowerCase();
    // }
    // getRuleType(rule) {
    //     return this.rulesTypeLabel[this.rulesType.findIndex(el => {
    //         return rule.type == el
    //     })]
    // }
    // getRuleCondition(rule) {
    //     let rulesConditionArray = this.getRulesConditionArray(rule.type)        
    //     let rulesURLConditionLabelArray = this.getRulesConditionLabelArray(rule.type)
    //     return rulesURLConditionLabelArray[rulesConditionArray.findIndex(el => {
    //         return rule.condition === el
    //     })]
    // }

    getRulesConditionArray(type: string) {
        if (type === 'Creation Date' || type === 'Date' || type === 'Time') {
            return this.rulesDateConditionArray;
        }
        else if (["URL", "URL Hostname", "URL Path", "URL Parameters", "URL Hash", "Page Title"].indexOf(type) !== -1) {
            return this.rulesURLConditionArray;
        }
        else if (type === 'Display Frequency') {
            return this.rulesDisplayFrequencyConditonArray
        }
        else if (type === 'Variables') {
            return this.rulesVariableConditionArray
        }
        else return []
    }

    changeRuleCondition(value, ruleNo, sectionNo) {
        // let rulesConditionArray = this.getRulesConditionArray(this.parentRulesSections[sectionNo][ruleNo].type);
        // this.parentRulesSections[sectionNo][ruleNo].condition = rulesConditionArray[$event.target.selectedIndex]
        console.log(value);
        this.parentRulesSections[sectionNo][ruleNo].condition = value;
        this.parentRulesSections[sectionNo][ruleNo].value = "";
        delete this.parentRulesSections[sectionNo][ruleNo].startDate
        delete this.parentRulesSections[sectionNo][ruleNo].endDate
        delete this.parentRulesSections[sectionNo][ruleNo].min
        this.formIsValid = this.isValidForm() || this.isValidRule();
    }

    changeRuleType(value, ruleNo, sectionNo) {
        this.parentRulesSections[sectionNo][ruleNo].condition = ''
        this.parentRulesSections[sectionNo][ruleNo].value = '';
        delete this.parentRulesSections[sectionNo][ruleNo].startDate
        delete this.parentRulesSections[sectionNo][ruleNo].endDate
        delete this.parentRulesSections[sectionNo][ruleNo].min;
        delete this.parentRulesSections[sectionNo][ruleNo].variableData;
        this.parentRulesSections[sectionNo][ruleNo].type = value;
        this.formIsValid = this.isValidForm() || this.isValidRule();
    }

    changeRuleValue($event, ruleNo, sectionNo) {
        if (this.parentRulesSections[sectionNo][ruleNo].type === 'Creation Date' || this.parentRulesSections[sectionNo][ruleNo].type === 'Date') {
            this.parentRulesSections[sectionNo][ruleNo].value = new Date($event.target.value).getTime();
        } else {
            this.parentRulesSections[sectionNo][ruleNo].value = $event.target.value
        }
        this.formIsValid = this.isValidForm() || this.isValidRule();
    }

    changeRuleDateRange($event, ruleNo, sectionNo, isStartDate) {
        if (isStartDate) {
            this.parentRulesSections[sectionNo][ruleNo].startDate = new Date($event.target.value).toISOString();
        } else this.parentRulesSections[sectionNo][ruleNo].endDate = new Date($event.target.value).toISOString();
        this.parentRulesSections[sectionNo][ruleNo].value = new Date(this.parentRulesSections[sectionNo][ruleNo].startDate).toDateString() + "-" + new Date(this.parentRulesSections[sectionNo][ruleNo].endDate).toDateString()
        this.parentRulesSections[sectionNo][ruleNo].min = new Date().toISOString().split('T')[0];
        this.formIsValid = this.isValidForm() || this.isValidRule();    
        
    }

    getDateValue(rule, date: string) {
        if (date) {
            return this.getDateInLocalValue(date);
        }
        return "";
    }

    getDateInLocalValue = (date) => {
        let tempDate = date ? new Date(date) : new Date();
        let val = [tempDate.getFullYear(), (tempDate.getMonth() + 1).toString().padStart(2, '0'), tempDate.getDate().toString().padStart(2, '0')]
        return val.join("-");
    }

    getCategoryType(rule) {
        if (["URL", "URL Hostname", "URL Path", "URL Parameters", "URL Hash", "Page Title", "Display Frequency", "Variables"].indexOf(rule.type) !== -1) {
            if (rule.condition === 'Date Range') {
                return 'daterange'
            }
            return "text"
        } else if (["Creation Date", "Date"].indexOf(rule.type) !== -1) {
            return "date"
        } else if (["Time"].indexOf(rule.type) !== -1) {
            return "time"
        }
    }

    showTooltip(rule) {
        if (rule.condition && (["Day of the Week", "Fixed Time in the Day", "Date Range"].includes(rule.condition))) {
            return true;
        } return false
    }

    getTooltipContent(rule) {
        if (rule.condition && rule.condition === "Day of the Week") {
            return this.admin_data.rule_dayOfWeek
        } else if (rule.condition && rule.condition === "Fixed Time in the Day") {
            return this.admin_data.rule_fixTime
        } else if (rule.condition && rule.condition === "Date Range") {
            return this.admin_data.rule_dateRange
        }
    }

    deleteRule($event, ruleNo, sectionNo) {

        this.parentRulesSections[sectionNo].splice(ruleNo, 1);
        if (this.parentRulesSections[sectionNo].length == 0) { this.parentRulesSections.splice(sectionNo, 1) }
        if (this.parentRulesSections.length == 0) {
            this.addGroup()
        }
    }

    isDefaultRule(i, j, rule) {
        if (i == 0 && j == 0 && JSON.stringify(rule) === JSON.stringify({
            "type": "",
            "name": "",
            "condition": "",
            "value": "",
            "logical_condition": "",
            "showValueField": true
        })) return false;

        return true;
    }
    addGroup() {
        let default_rule = {
            type: "",
            name: "",
            condition: "",
            value: "",
            logical_condition: "||",
            showValueField: true
        }
        let rules = [default_rule]
        this.parentRulesSections.push(rules)

    }

    addRule(sectionNo) {
        this.parentRulesSections[sectionNo].push({
            type: "",
            name: "",
            condition: "",
            value: "",
            logical_condition: "&&",
            showValueField: true
        })
    }

    validateRule(rule) {
        if (rule.type === "" || rule.condition === "" || rule.value === "") {
            // console.log(rule);
            return false;
        }
        switch (rule.type) {
            case "URL": {
                switch (rule.condition) {
                    case "Equals":
                    case "Not Equals": {
                        return this.isValidUrl(rule.value);
                    }
                    case "Contains":
                    case "Does Not Contain": {
                        return this.isStringValidURLComponent(rule.value)
                    }
                    case "Starts With": {
                        return this.isValidURLStartingString(rule.value)
                    }
                    case "Ends With": {
                        return this.isStringValidURLEnding(rule.value)
                    }
                }
            }
            case "URL Hostname": {
                switch (rule.condition) {
                    case "Equals":
                    case "Not Equals": {
                        return this.isStringValidURLHostname(rule.value);
                    }
                    case "Contains":
                    case "Does Not Contain": {
                        return this.isStringValidHostnamePart(rule.value)
                    }
                    case "Starts With": {
                        return this.isStringValidURLHostnameStart(rule.value)
                    }
                    case "Ends With": {
                        return this.isValidHostnameEnding(rule.value)
                    }
                }
            }

            case "URL Path": {
                switch (rule.condition) {
                    case "Equals":
                    case "Not Equals": {
                        return this.isValidUrlPath(rule.value);
                    }
                    case "Contains":
                    case "Does Not Contain": {
                        return this.isUrlPath(rule.value)
                    }
                    case "Starts With": {
                        return this.isUrlPathStart(rule.value)
                    }
                    case "Ends With": {
                        return this.isUrlPathEnd(rule.value)
                    }
                }
            }
            case "URL Parameters": {
                switch (rule.condition) {
                    case "Equals":
                    case "Not Equals": {
                        return this.isValidUrlParameters(rule.value);
                    }
                    case "Contains":
                    case "Does Not Contain": {
                        return this.isUrlParameter(rule.value)
                    }
                    case "Starts With": {
                        return this.isUrlParameterStart(rule.value)
                    }
                    case "Ends With": {
                        return this.isUrlParameterEnd(rule.value)
                    }
                }
            }
            case "URL Hash": {
                switch (rule.condition) {
                    case "Equals":
                    case "Not Equals": {
                        return this.isUrlHash(rule.value);
                    }
                    case "Contains":
                    case "Does Not Contain": {
                        return this.isUrlHashPart(rule.value)
                    }
                    case "Starts With": {
                        return this.isUrlHashStart(rule.value)
                    }
                    case "Ends With": {
                        return this.isUrlHashEnd(rule.value)
                    }
                }
            }
            case "Page Title": {
                return this.isValidPageTitle(rule.value)
            }
            case "Creation Date": return true
            case "Date": return true
            case "Time": return true;
            case "Display Frequency": {
                switch (rule.condition) {
                    case "Day of the Week": {
                        let retVal = false;
                        let date = new Date();
                        let day = date.getDay();

                        let dayOfWeekMap = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

                        let valArr = rule.value.replace(/\s/g, '').toLowerCase().split('|');
                        for (let i = 0; i < valArr.length; i++) {
                            let range = valArr[i].split('-');
                            if (range.length === 1) {
                                let val = range[0].slice(0, 3);
                                if (dayOfWeekMap.indexOf(val) === day) {
                                    retVal = true;
                                    break;
                                }
                            } else if (range.length === 2) {
                                let lower = range[0].slice(0, 3);
                                let upper = range[1].slice(0, 3);
                                if (dayOfWeekMap.indexOf(lower) <= day && day <= dayOfWeekMap.indexOf(upper)) {
                                    retVal = true;
                                    break;
                                }
                            }
                        }
                        return retVal;
                    }
                    case "Fixed Time in the Day": {
                        let valArr = rule.value.replace(/\s/g, '').split('|');
                        let retVal = false;
                        let date = new Date();
                        let time = parseInt(date.toTimeString().slice(0, 5).replace(':', ''));

                        for (let i = 0; i < valArr.length; i++) {
                            let range = valArr[i].split('-');
                            let lower = range[0];
                            if (lower.length === 5) { // 24-hour format time; eg: '11:30'
                                lower = parseInt(lower.slice(0, 5).replace(':', ''));

                                let upper = range[1];
                                if (upper && upper.length === 5) {
                                    upper = parseInt(upper.slice(0, 5).replace(':', ''));
                                } else {
                                    upper = 2359; // 23:59 - End of day
                                }

                                if ((lower <= time) && (time <= upper)) {
                                    retVal = true;
                                    break;
                                }
                            }
                        }
                        return retVal;
                    }
                    case "Date Range": {
                        if (rule.startDate && rule.endDate) {
                            if (new Date(rule.endDate) >= new Date(rule.startDate))
                                return true
                        }
                        return false
                    }
                }
            }
            case "Variables": {
                if(rule.variableData === undefined){
                    rule.variableData = '';
                }
                if(rule.variableData != undefined && rule.variableData !== '' && rule.value !== ''){
                    return true;
                }
            }
        }
        return false;
    }
    isValidPageTitle(string) {
        // Regular expression to validate page title
        var pageTitleRegex = /^[\w\s\-.,!?:;'"()|]+$/;

        // Test if the provided string matches the regex pattern
        return pageTitleRegex.test(string);
    }

    isUrlHashEnd(string) {
        // Regular expression to validate the end of a URL hash
        var hashEndRegex = /^[a-zA-Z0-9_-]+$/;

        // Test if the provided string matches the regex pattern
        return string !== '' && hashEndRegex.test(string);
    }


    isUrlHashStart(string) {
        // Regular expression to validate the start of a URL hash
        var hashStartRegex = /^#[a-zA-Z0-9_-]*$/;

        // Test if the provided string matches the regex pattern
        return hashStartRegex.test(string);
    }

    isUrlHashPart(string) {
        // Regular expression to validate URL hash part
        var hashPartRegex = /^[a-zA-Z0-9_-]+$/;

        // Test if the provided string matches the regex pattern
        return hashPartRegex.test(string);
    }



    isUrlHash(string) {
        // Regular expression to validate URL hash
        var hashRegex = /^#[a-zA-Z0-9_-]+$/;

        // Test if the provided string matches the regex pattern
        return hashRegex.test(string);
    }


    isUrlParameterEnd(string) {
        // Regular expression to validate the end of a URL parameter
        var parameterEndRegex = /^[a-zA-Z0-9%+-_]*$/;

        // Test if the provided string matches the regex pattern
        return parameterEndRegex.test(string);
    }


    isUrlParameterStart(string) {
        // Regular expression to validate the start of a URL parameter
        var parameterStartRegex = /^[a-zA-Z0-9]+=$/;

        // Test if the provided string matches the regex pattern
        return parameterStartRegex.test(string);
    }

    isUrlParameter(string) {
        // Regular expression to validate URL parameter segment
        var parameterRegex = /^[a-zA-Z0-9%+-_]+=[a-zA-Z0-9%+-_]*$/;

        // Test if the provided string matches the regex pattern
        return parameterRegex.test(string);
    }

    isValidUrlParameters(parameters) {
        // Regular expression to validate URL parameters
        var parametersRegex = /^([a-zA-Z0-9_-]+=[a-zA-Z0-9%+-_]*(&[a-zA-Z0-9_-]+=[a-zA-Z0-9%+-_]*)*)?$/;

        // Test if the provided parameters match the regex pattern
        return parametersRegex.test(parameters);
    }

    isUrlPathEnd(string) {
        // Regular expression to validate the end of a URL path
        var pathEndRegex = /^([^\s/]*\/?)?$/;

        // Test if the provided string matches the regex pattern
        return pathEndRegex.test(string);
    }

    isUrlPathStart(string) {
        // Regular expression to validate the start of a URL path
        var pathStartRegex = /^(\/[^\s/]*)?$/;

        // Test if the provided string matches the regex pattern
        return pathStartRegex.test(string);
    }

    isUrlPath(string) {
        // Regular expression to validate URL path segment
        var pathRegex = /^[a-zA-Z0-9~!*()@\-._]+$/;
        if (this.isValidUrlPath(string)) return true;

        // Test if the provided string matches the regex pattern
        return pathRegex.test(string);
    }

    isValidUrlPath(path) {
        // Regular expression to validate URL path
        var pathRegex = /^\/[^\s/]*$/;

        // Test if the provided path matches the regex pattern
        return pathRegex.test(path);
    }

    isValidHostnameEnding(string) {
        // Check if the string is empty
        if (string.length === 0) {
            return false;
        }

        // Check if the string contains any invalid characters
        var invalidChars = /[^\w-.]/g;
        if (invalidChars.test(string)) {
            return false;
        }

        // Check if the string starts or ends with a hyphen
        if (string.startsWith("-") || string.endsWith("-")) {
            return false;
        }

        // Check if the string is a valid IP address
        var ipRegex = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
        if (ipRegex.test(string)) {
            return false;
        }

        // Check if the string is a valid hostname
        var hostnameRegex = /^[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/;
        if (!hostnameRegex.test(string)) {
            return false;
        }

        return true;
    }

    isStringValidURLHostnameStart(str) {
        // Regular expression pattern for a valid URL hostname start
        var hostnameStartPattern = /^[a-zA-Z0-9][a-zA-Z0-9-]*$/;

        // Check if the string matches the pattern
        return hostnameStartPattern.test(str);
    }
    isStringValidHostnamePart(str) {
        // Regular expression pattern for a valid hostname part
        var partPattern = /^[a-zA-Z0-9-_.]+$/;

        // Check if the string matches the pattern
        return partPattern.test(str);
    }

    isStringValidURLHostname(str) {
        // Regular expression pattern for a valid URL hostname
        var hostnamePattern = /^([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

        // Check if the string matches the pattern
        return hostnamePattern.test(str);
    }

    isStringValidURLComponent(str) {// Regular expression pattern for a valid URL component
        var componentPattern = /^[a-zA-Z0-9-_.~!*'();:@&=+$,/?#[\]]*$/;

        // Check if the string matches the pattern
        return componentPattern.test(str);
    }

    // isValidURL(url) {
    //     console.log(url);
    //     // Regular expression pattern for a valid URL
    //     var urlPattern = /^(https?:\/\/)?((([a-zA-Z0-9]+|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([a-zA-Z]+|[a-zA-Z][a-zA-Z0-9-]*[a-zA-Z0-9]))(\.[a-zA-Z]{2,}){1,2}(:[0-9]{1,5})?((\/[a-zA-Z0-9]*)*|\.[a-zA-Z]{2,})?(\?[a-zA-Z0-9=&]*)?(#[a-zA-Z0-9]*)?$/;
    //     console.log(urlPattern.test(url));

    //     // Check if the URL matches the pattern
    //     return urlPattern.test(url);
    //   }


    isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    }
    isValidURLStartingString(str) {
        // Regular expression pattern for a valid URL starting string
        var startingPattern = /^(https?:\/\/)?[a-zA-Z0-9-_.~!*'();:@&=+$,/?#[\]]*$/;

        // Check if the string matches the pattern
        return startingPattern.test(str);
    }

    isStringValidURLEnding(str) {
        // Regular expression pattern for a valid URL ending
        var endingPattern = /^[a-zA-Z0-9-_.~!*'();:@&=+$,/?#[\]]*$/;

        // Check if the string matches the pattern
        return endingPattern.test(str);
    }

    getRulesInSections(rules) {
        let andRules = []
        let sectionIndex = 0;
        let rulesSections = [];
        if (rules.length == 0) {
            andRules.push({
                type: "",
                name: "",
                condition: "",
                value: "",
                logical_condition: "",
                showValueField: true
            })
            rulesSections.push(andRules);
            return rulesSections;
        }

        for (let i = 0; i < rules.length; i++) {
            if (rules[i].logical_condition === '||') {
                if (i > 0) { rulesSections.push(JSON.parse(JSON.stringify(andRules))) }
                andRules = [];
                andRules.push(JSON.parse(JSON.stringify(rules[i])))
            } else {
                andRules.push(JSON.parse(JSON.stringify(rules[i])))
            }
        }

        if (andRules.length > 0) {
            rulesSections.push(JSON.parse(JSON.stringify(andRules)))
        }
        return rulesSections;
    }


    saveRules() {
        this.rules = []
        this.parentRulesSections.forEach(section => {
            section.forEach(rule => {
                // if (this.validateRule(rule)) {
                if (rule.condition != '' && rule.type !== '') {
                    this.rules.push(JSON.parse(JSON.stringify(rule)))
                }
            });
        })
        return (this.rules);
    }

    saveGroup() {
        let group = [];
        this.segments.forEach((data) => {
            let obj = {};
            if (this.formGroup.get(data["key_name"])) {
                obj["id"] = data["id"];
                obj["data"] = [];

                this.formGroup.get(data["key_name"]).value.forEach((val) => {
                    obj["data"].push(val["value"]);
                });
                // obj["data"] = JSON.stringify(obj["data"]);
                group.push(obj);
            }
        });
        return group;
    }

    editSegment() {

        let request = {
            organization_id: this.userService.organization_id,
            rule_check: false,
            rules: JSON.stringify(this.saveRules()),
            segments: JSON.stringify(this.saveGroup()),
            title: this.formGroup.get("segment_title").value,
            group_id: this.manageSegmentsService.group['group_id'],
            settings: JSON.stringify(this.advanceSettings)
        }

        this.manageSegmentsService.updateGroup(request).subscribe((response) => {
            if (response['error'] == false) {
                this.manageSegmentsService.group = {};
                this.manageSegmentsService.segment = {};
                this.redirectToManageSegments()
            } else {
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            }
        })
    }

    addSegment() {
        let request = {
            organization_id: this.userService.organization_id,
            rule_check: false,
            rules: JSON.stringify(this.saveRules()),
            segments: JSON.stringify(this.saveGroup()),
            title: this.formGroup.get("segment_title").value,
            settings: JSON.stringify(this.advanceSettings)
        }
        this.manageSegmentsService.addGroup(request).subscribe((response) => {
            if (response['error'] == false) {
                this.manageSegmentsService.group = {};
                this.manageSegmentsService.segment = {};
                this.redirectToManageSegments()
            } else {
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            }
        })
    }
    redirectToManageSegments() {
        this.manageSegmentsService.group = {};
        this.manageSegmentsService.tab = 'segments'
        this.router.navigate(['/organization', this.userService.organization_id, 'manage_segments']);
    }

    isValidForm() {
        for (let data of this.segments) {
            if (this.formGroup.get(data["key_name"])) {
                if (this.formGroup.get(data["key_name"]).value.length > 0) {
                    return true;
                }
            }
        }
        return false;
    }

    isValidRule() {
        for (let sections of this.parentRulesSections) {
            for (let rule of sections) {
                if (this.validateRule(rule)) {
                    return true;
                }
            }
        }
        return false;
    }

    addSegmentField(event) {
        if (this.segmentOptionSelected === '') return;
        let form = {};
        let index = this.unselectedSegmentArray.findIndex(elem => elem.id == this.segmentOptionSelected);
        let data = this.unselectedSegmentArray[index];
        this.unselectedSegmentArray.splice(index, 1)
        if (this.add_segment_formdata.length > 0) {
            let andHeading = {

                type: "heading",
                name: "AND",
                class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 font14",
                value: "",
                checked: false,
                validators: [],
                text_class: "",
                ui: {
                    label: "and",
                    class: "",
                    inputclass: "primary",
                }
            }
            this.add_segment_formdata.push(andHeading);
        }
        let field = {
            type: "tag-input",
            name: data["key_name"],
            value: "",
            selectedItems: [],
            class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2 p-4 font14 tag-input-wrapper",
            validators: [],
            ui: {
                label: data["key_name"],
                class: data['is_required'] === "0" ? "tag-input-label" : "tag-input-label label-asterisk",
                inputclass: "col-lg-6",
            }
        };
        field["items"] = [];
        if (JSON.parse(data["data"]) != null) {
            JSON.parse(data["data"]).forEach((value) => {
                field["items"].push({
                    display: value,
                    value: value,
                });
            });
        }
        this.formGroup && this.formGroup.addControl(data["key_name"], new FormControl(''))
        data['is_required'] == "1" && this.formGroup && this.formGroup.controls[data["key_name"]].setValidators(Validators.required)
        this.add_segment_formdata.push(field);
        this.segmentOptionSelected = '';

    }

    getChangedValue = (value) => {
        this.segmentOptionSelected = value;
    }

    getSegments = (group_id) => {
        this.manageSegmentsService.getAllSegments(this.userService.organization_id).subscribe((response) => {
            let groups = []
            groups = response['data'] && response['data']['groups'] ? response['data']['groups'] : [];
            let self = this;
            this.manageSegmentsService.allSegments = groups
            this.manageSegmentsService.group = groups.find((element) => element["group_id"] == group_id)
            this.selectedSegments = this.manageSegmentsService.group['segments'] ?
                JSON.parse(this.manageSegmentsService.group['segments']) : []
            if (this.manageSegmentsService.group['settings']) {
                this.advanceSettings = JSON.parse(this.manageSegmentsService.group['settings'])
            }
            this.parentRulesSections = this.getRulesInSections(this.manageSegmentsService.group['rules'] ? JSON.parse(this.manageSegmentsService.group['rules']) : [])
            // console.log(this.selectedSegments);

            this.initialize();
        });
    }

    changeAdvanceSettings(event) {
        let val = event.target.value;
        for (let key in this.advanceSettings) {
            if (key === val) {
                this.advanceSettings[key] = 1
            } else {
                this.advanceSettings[key] = 0;
            }
        }
    }

    getApplications() {
        this.variables = [];
        this.applicationService.getApplications(100, 0, '', '').subscribe(
            (response) => {
                if (!response['error']) {
                    if (response["data"]['applications']) {
                        let data = response["data"]['applications'];
                        data.forEach(app => {
                            let tempSettings = app['settings'] ? JSON.parse(app['settings']) : {}
                            const varArray = tempSettings['variables'];
                            if (varArray && varArray.length > 0) {
                                this.variables.push({ value: '', name: this.admin_data.select })
                                varArray.forEach((variable) => {
                                    this.variables.push({ value: variable['name'], name: variable['name'] })
                                })
                            };
                        });
                    }
                } else {
                    let errorResponse = this.errorResponseService.getErrorResponse(response);
                    this.userService.errorMessage = errorResponse;
                    $("#errorModal").modal("show");
                }
            }
        );
    }

    changeRuleVariableData(value, ruleNo, sectionNo) {
        this.parentRulesSections[sectionNo][ruleNo].variableData = value;
        this.formIsValid = this.isValidForm() || this.isValidRule();    
    }

}

