import { Component, Input, OnInit, Output,EventEmitter} from '@angular/core';
declare var $;

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {



@Input('buttonData') set buttonData(value:any){
  if(value){
    this.button=value;
  }
  if(value.tooltipTemplate && value.tooltipTemplate){
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip({template:value.tooltipTemplate});
    });
  }else{
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }
};
@Input('disabled') disabled;
@Output() clickhandler = new EventEmitter();
button:any;



  constructor(
  ) { 
    // $(document).ready(function () {
    //   $('[data-toggle="tooltip"]').tooltip();
    // });
  }

  ngOnInit() {
  }

  handleClick(){
    this.clickhandler.emit('');
    $('[data-toggle="tooltip"]').tooltip('hide');
    document.getElementById("button-elem").blur();
  }




}


