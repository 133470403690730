import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { API_END_POINTS } from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

export const SSO_API_BASE_URL=environment.SSO_API_BASE_URL;

export interface SSOConfig{
  id: string,
  organization_id: string,
  issuer_url: string,
  sso_url: string,
  x509_certificate: string,
  api_key: string,
  api_secret: string,
  creation_date: string,
  modification_date: string,
  want_response_signed: string,
  want_assertion_signed: string,
  want_metadata_signed: string,
  want_message_signed: string,
  want_nameId_encrypted: string,
  want_slo_enabled: string,
  is_sync_complete: string,
  want_same_cert_for_encryption: string,
  slo_url: string,
  sso_btn_message: string,
  sso_confirmation_message: string,
  signature_algorithm: string,
  digest_algorithm: string,
  encryption_x509_certificate: string,
  attribute_map: string,
  domain: any,
  want_authn_request_signed: string,
  is_active: string,
  name: string,
  description: string
}



@Injectable({
  providedIn: 'root'
})
export class SsoService {

  selectedSSO;

  constructor(private apiService:ApiService) { }

  

  getSsoConfig(sso_id){
    let urlPath=environment.baseUrl+API_END_POINTS.GET_SSO+"?sso_id="+sso_id;
    return this.apiService.get(urlPath);
  }

  updateSSOConfig(req){
    let urlPath=environment.baseUrl+API_END_POINTS.UPDATE_SSO;
    return this.apiService.put(urlPath,req);
  }
}
