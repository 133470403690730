import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { API_END_POINTS } from 'src/app/constants/api-end-points';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ProductUsageService {

    constructor(
        private userService: UserService,
        private apiService: ApiService,
        private http: HttpClient
    ) { }

    getHeader() {
        return {
            headers: new HttpHeaders({
                'AppKey': environment.authKey,
                'AccessToken': this.userService.user.token
            })
        }
    }
    
    getInsightsData(start_date, end_date, time_zone, org_id) {
        let urlPath = environment.INSIGHTS_HOST + API_END_POINTS.GET_INSIGHT_PRODUCT_USAGE_DATA +
            "?start_date=" + start_date +
            "&end_date=" + end_date +
            "&time_zone=" + time_zone +
            "&org_id=" + org_id;
        const headers = this.getHeader();
        return this.http.get(urlPath, headers);
    }

    getServerStats(organization_id, month) {
        let urlPath = environment.baseUrl + API_END_POINTS.GET_SERVER_PRODUCT_USAGE_DATA +
            "?organization_id=" + organization_id +
            "&month=" + month;
        return this.apiService.get(urlPath)
    }
}
