import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})

export class LoaderComponent implements OnInit {

    constructor(private location: Location,
        private router: Router,) { }


    private destroyed: boolean = false;
    ngOnInit(): void {
        setTimeout(() => {
            if (!this.destroyed) {
                console.log("Redirecting to login after waiting 10 secs");
                const query = new URLSearchParams(window.location.search);
                const urlData = [];
                query.forEach((v, k) => {
                    urlData[k] = v;
                });
                this.router.navigate(['/login'], { queryParams: urlData })
            }
        }, 10000);
    }

    ngOnDestroy(): void {
        this.destroyed = true;
    }

}
