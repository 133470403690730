import adminLabels from "../ApplicationStrings/trans_utils";

export const GENERATE_CREDENTIALS_FORMADATA = [
  {
    type: "radio",
    name: "provisioning",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    value: ["anonymous", "jit"],
    value_labels: [adminLabels.generateCredentialsVariable2, "JIT"],
    validators: [
    ],
    ui: {
      label: adminLabels.userProvisioningBreadCrumbTitle,
      class: "font-weight-bold font14",
    },
  },
  {
    type: "text",
    name: "key_name",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    validators: [
      {
        required: true,
        maxLength: 80,
      },
    ],
    ui: {
      label: adminLabels.userProvisioningProvideKey,
      class: "font-weight-bold font14 label-asterisk",
      inputclass: "form-control",
    },
    input: {
      id: "key_name",
      class: "form-control font14",
      type: "password",
      name: "key_name",
      placeholder: adminLabels.userProvisioningProvideKey,
    },
    label: {
      title: adminLabels.userProvisioningProvideKey,
      class: "col-lg-2 font14",
      for: "key_name",
    },
  },
];
