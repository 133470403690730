import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment';
import { UserService } from '../services/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CustomErrorService {

    constructor(private userService: UserService,
        private http: HttpClient
    ) { }

    getHeader() {
        return {
            headers: new HttpHeaders({
                'AppKey': environment.authKey,
                'AccessToken': this.userService.user.token
            })
        }
    }
    persistErrorDetails = (reqObj) => {
        let url = environment.INSIGHTS_HOST + "/services/v1/log/error/frontend/";
        let headers = this.getHeader();
        return this.http.post(url, reqObj, headers);
    }
}
