<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">
          
            <div class="sectionBody">

                <div class="d-flex mb-3">
					<div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
						{{this.admin_data.userAttributeValues}}
					</div>
					<div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right">
						<button type="button" class="btn btn-primary ml-2" (click)="showAddSegmentListModal()">
                            {{this.admin_data.addButtonText}}
                        </button>
                        <button type="button" class="btn btn-primary ml-2"
                            (click)="navigateToImport()">{{this.admin_data.import}}</button>
					</div>
				</div>


             
                <app-table [data]="passSegmentationList" [titles]="segmentation.list_appTitles"
                    [widths]="segmentation.col_list_widths" [dataKeys]="segmentation.list_appDataKeys"
                    (delete)="showDeleteModal($event)" (roles)="showAddSegmentListModal()"></app-table>
                <div class="modal fade" id="addSegmentListModal" tabindex="-1" role="dialog"
                    aria-labelledby="addSegmentListModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="addRoleModalLabel">{{this.admin_data.addUserAttributeValues}}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <app-form [formData]="segment_list_formData" [formGroup]="formGroup">
                                </app-form>
                            </div>
                            <div class="modal-footer justify-content-center">
                                <!-- <button type="button" class="btn btn-secondary minWidth-120" data-dismiss="modal"
                                    (click)="closeAddSegmentModal()">{{this.admin_data.cancelButtonText}}</button> -->
                                <app-button [buttonData]="segmentation.cancel_button"
                                    (clickhandler)="closeAddSegmentModal()"></app-button>
                                <!-- <button type="button" class="btn btn-primary" (click)="addSegmentList()"
                                    [disabled]="!formGroup.valid">Add Value</button> -->
                                <app-button [disabled]="!formGroup.valid" [buttonData]="segmentation.add_value_button"
                                    (clickhandler)="addSegmentList()"></app-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <ng-container>
            <app-modal-confirmation [header_value]="modalHeader" [content_value]="modalContent"
            [modal_ok_value]="this.userService.delete_button.text" [org]=""
            [disabled]="this.userService.delete_button.disable"
            (onDelete)="delete_Segment()"></app-modal-confirmation>
        </ng-container>
    </div>
</div>