<div class="modal fade" tabindex="-1" role="dialog" id="library" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{this.admin_data.imageLibrary}}</h4>
            </div>
            <div class="modal-body">
                <div class="sidebar">
                    <ul class="nav nav-pills flex-column mb-auto">
                        <li class="nav-item" *ngFor="let item of currLabels; index as i">
                            <a class="nav-link" [class.active]="i===activeLabel" aria-current="page"
                                (click)="setImages(currSection, i, $event)">{{item}}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="right-section">
                    <div class="tabs">
                        <ul class="nav nav-tabs">
                            <li class="nav-item" *ngFor="let item of sections; index as i">
                                <a class="nav-link" [class.active]="i===currSection" aria-current="page"
                                    (click)="setImages(i, 0, $event)">{{item}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="images">
                        <div *ngFor="let img of images" class="library-images">
                            <div class="tick-img-wrapper">
                                <img *ngIf="libImageSelected === img" src="../../../assets/svgs/img_tick.svg"
                                    class="tick-img" />
                            </div>
                            <img [src]="img" class="lib-image" width="100px" height="100px" [ngStyle]="{
                      'border': libImageSelected === img ? '2px solid #454560' : 'none'
                    }" (click)="onImageClicked($event)" (dblclick)="selectImage($event)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-discard" (click)="closePopup($event)">
                    {{this.admin_data.discardBtnTxt}}
                </button>
                <button type="button" class="btn btn-save" (click)="onImageSelected($event)">
                    {{this.admin_data.apply}}
                </button>
            </div>
        </div>
    </div>
</div>