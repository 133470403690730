import adminLabels from "../ApplicationStrings/trans_utils";

export const SEGMENT_LIST_FORMADATA = [
  {
    type: "text",
    name: "key_name",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    layoutModal: true,
    validators: [
      {
        required: true,
        maxLength: 80,
      },
    ],
    ui: {
      label: adminLabels.segmentKeyName,
      class: "col-lg-4",
      inputclass: "col-lg-8",
    },
    input: {
      id: "key_name",
      class: "form-control",
      type: "text",
      name: "key_name",
      placeholder: adminLabels.segmentKeyName,
    },
    label: {
      title: adminLabels.segmentKeyName,
      class: "font-weight-bold label-asterisk",
      for: "key_name",
    },
  },
  {
    type: "checkbox",
    name: 'is_required',
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 d-flex align-items-center font14",
    value: "",
    checked: false,
    validators: [],
    ui: {
      label: adminLabels.segmentsTableHeaderIsRequired,
      class: "font-weight-bold mb-0",
      inputclass: "primary",
    },
  },
  // {
  //   type: "checkbox",
  //   name: "is_required",
  //   value: "",
  //   class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 d-flex align-items-center",
  //   layoutModal: true,
  //   validators: [],
  //   ui: {
  //     label: "Is Required?",
  //     class: "font-weight-bold mb-0",
  //     inputclass: "col-lg-4",
  //   },
  //   input: {
  //     id: "is_required",
  //     class: "form-control",
  //     type: "checkbox",
  //     name: "is_required",
  //     placeholder: "Is Required?",
  //   },
  //   label: {
  //     title: "Is Required?",
  //     class: "font-weight-bold",
  //     for: "is_required",
  //   },
  // },
];
