<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>

        <section class="component-section">

            <div class="sectionBody">
                <div class="d-flex justify-content-end">
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                        {{this.admin_data.guides}}
                    </div>

                    <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right" style="min-height: 40px;">
                        <button *ngIf="curr_tab !== 'transfer_guides'" type="button" class="btn btn-secondary ml-2"
                            (click)="refresh()">{{this.admin_data.refresh}}</button>
                    </div>
                </div>


                <ul class="nav nav-tabs" id="segmentsTab" role="userTabsList">
                    <li class="nav-item" (click)="changeTab('transfer_guides')">
                        <a class="nav-link custom-nav-link active" id="guides-tab" data-toggle="tab" role="tab"
                            aria-controls="guidesTab" aria-selected="true">{{this.admin_data.transferGuides}}</a>
                    </li>
                    <li class="nav-item" (click)="changeTab('import_guides')">
                        <a class="nav-link custom-nav-link" id="segments-tab" data-toggle="tab" role="tab"
                            aria-controls="segments" aria-selected="true">{{this.admin_data.importedGuides}}</a>
                    </li>
                    <li class="nav-item" (click)="changeTab('export_guides')">
                        <a class="nav-link custom-nav-link" id="segments-tab" data-toggle="tab" role="tab"
                            aria-controls="segments" aria-selected="true">{{this.admin_data.exportedGuides}}</a>
                    </li>
                </ul>
                <div *ngIf="curr_tab === 'transfer_guides'" class="bg-white"
                    style="display: flex; flex-direction: column;">
                    <div class="d-flex justify-content-end my-3">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 text-right filterAlignment">
                            <div *ngIf="navArray.length > 1" class="float-left  px-2 mt-2 mx-2">
                                <span>
                                    <svg-icon class="mr-2" src="../assets/svgs/back-icon.svg" (click)="goBack()"
                                        role="button"></svg-icon>
                                    <span class="font14" *ngFor="let item of navArray; index as i">
                                        <span class="font14" *ngIf="i>0">/</span>{{item}}
                                    </span>
                                </span>
                            </div>
                            <div *ngIf="navArray.length == 1" class="col-md-3 text-left d-inline-block">
                                <div class="d-flex flex-row align-items-end" [ngStyle]="{ 'min-width': '200px' }">
                                    <!-- <label class="font14 font-weight-bold mr-1"
                                        for="app">{{this.admin_data.applicationsBreadCrumbTitle}}</label> -->

                                    <app-custom-select [dataList]="apps" componentName="apps"
                                        [selected]="this.curr_app_id"
                                        (updateDataList)="appChanged($event)" [ngStyle]="{ 'width': '100%' }"></app-custom-select>

                                    <!-- <select #app class="form-control form-control-sm" id="app"
                                        (change)="appChanged($event.target.value)">
                                        <option *ngFor="let app of apps" [value]="app['application_id']"
                                            [selected]="this.curr_app_id===app['application_id']">
                                            {{app['name']}}
                                        </option>
                                    </select> -->
                                </div>
                            </div>
                            <div *ngIf="navArray.length == 1" class="input-group w-50 ml-2 d-inline-flex width-300">
                                <input class="form-control py-2 search" type="search"
                                    placeholder={{this.admin_data.searchPlaceholder}} autocomplete="off"
                                    (input)="search()" (search)="search()" aria-label="Search"
                                    [(ngModel)]="search_text">
                                <!-- <span class="input-group-append">
                                    <div class="input-group-text bg-white">
                                        <svg-icon src="../assets/svgs/search-icon.svg"></svg-icon>
                                    </div>
                                </span> -->
                            </div>
                        </div>
                    </div>
                    <div *ngIf="curr_view == 'folder_view'" class="d-flex scroll-x w-1240 table-container">

                        <div *ngIf="tableData !== undefined && tableData.length == 0" class="no-guide">
                            <img src="./../../assets/svgs/no_guides_image.svg">
                            <div class="no-guide-label-1">
                                <p>{{this.admin_data.noGuideFound}}</p>
                            </div>
                            <div class="no-guide-label-2">
                                <p>{{this.admin_data.modifySearchCriteria}}</p>
                            </div>
                        </div>
                        <table *ngIf="tableData !== undefined  && tableData.length > 0"
                            class="table  table-bordered break-w">
                            <colgroup>
                                <col style="width: 80%;">
                                <col style="width: 20%;">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th *ngFor="let header of tableHeading; index as i">
                                        {{header}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let folder of tableData">
                                    <td (click)="(folder['has_child'] !== '0' && populateSubFolder(folder, tableData)) || folder['has_child'] === '0' && populateGuideView(folder['category_id'])"
                                        [ngStyle]="{'cursor': folder['has_child'] !== '0' || folder['has_child'] == '0'? 'pointer' : 'default'}">
                                        <span>{{folder['category_title']}}</span>
                                    </td>
                                    <td>
                                        <div data-bs-toggle="tooltip" data-placement="right"
                                            title="{{folder['has_child'] === '0' && folder['has_tour'] !== '0'? this.admin_data.transferGuides : ''}}">
                                            <svg-icon src="../../../assets/svgs/share.svg" role="button"
                                                [ngStyle]="{'cursor': folder['has_child'] === '0' && folder['has_tour'] !== '0'? 'pointer' : 'default'}"
                                                (click)="folder['has_child'] === '0' && folder['has_tour'] !== '0' && exportGuides(folder['category_id'], folder['category_title'])"></svg-icon>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <ng-container *ngIf="curr_view === 'guides_view'">
                        <div class="d-flex scroll-x w-1240">
                            <!-- <div *ngIf="navArray.length > 1"  class="d-flex w-1240 p-2 m-2">
                                <span>
                                    <svg-icon class="mr-2" src="../assets/svgs/back-icon.svg" (click) = "goBack()" role="button"></svg-icon>
                                    <span class="font14" *ngFor="let item of navArray; index as i">
                                        <span class="font14" *ngIf="i>0">/</span>{{item}}
                                    </span>
                                </span>
                            </div> -->
                            <div *ngIf="tours.length>0" class="w-100">
                                <div class="pl-2 mt-2 folder-heading">
                                    <span class="font16">
                                        <svg-icon class="mr-2 ml-2" src="../assets/svgs/folder-icon.svg"></svg-icon>
                                        {{navArray[navArray.length-1]}}
                                    </span>
                                </div>
                                <div>
                                    <div class="d-flex flex-row justify-content-between pt-4">
                                        <div class="d-flex flex-row w-25 pl-2  align-items-baseline ml-4">


                                            <label class="custom-check mr-3">
                                                <input type="checkbox" name="" id='tour'
                                                    (change)="onGlobalChecked($event.target.checked)"
                                                    [checked]="globalChecked">
                                                <span class="checkmark mr-3 marginTop-6px"></span>
                                            </label>

                                            <!-- <select class="form-control mb-3" (change)="selectCategoryChanged($event)"
                                                id="dropdownFilter">
                                                <option value="all">{{selectCategories[0]}}</option>
                                                <option value="published">{{selectCategories[1]}}</option>
                                                <option value="unpublished">{{selectCategories[2]}}</option>
                                                <option value="clear">{{selectCategories[3]}}</option>
                                            </select> -->

                                            <app-custom-select [dataList]="customSelectObj"
                                                (updateDataList)="selectCategoryChanged($event)"
                                                componentName="guides" [ngStyle]="{ 'min-width': '200px' }"></app-custom-select>
                                        </div>
                                        <span class="float-right m-1">
                                            <span (click)="enableExportCopy && exportGuides()" class="py-4 pl-4 pr-2"
                                                [ngStyle]="{'cursor': enableExportCopy ? 'pointer' : 'not-allowed' }"
                                                data-bs-toggle="tooltip" data-placement="left"
                                                title="{{this.admin_data.export}}">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                    class="ng-scope">
                                                    <path
                                                        d="M10.7072 8.26949L10.7072 8.26949L16.8476 2.12903C16.8476 2.12903 16.8476 2.12902 16.8476 2.12902C17.1175 1.8594 17.1175 1.42197 16.8476 1.15236C16.5778 0.882539 16.1408 0.882546 15.871 1.15238L9.73052 7.29284C9.73052 7.29284 9.73051 7.29285 9.7305 7.29285C9.46066 7.56246 9.46067 7.9999 9.73052 8.2695C9.86522 8.4044 10.0421 8.47186 10.2188 8.47186C10.3954 8.47186 10.5723 8.40437 10.7072 8.26949Z"
                                                        fill="#6F708B" stroke="#6F708B" stroke-width="0.1"></path>
                                                    <path
                                                        d="M16.3599 0.95L11.7256 0.95C11.3441 0.95 11.035 1.25919 11.035 1.64071C11.035 2.02219 11.3441 2.33138 11.7256 2.33138H15.6692V6.27499C15.6692 6.65647 15.9784 6.96566 16.3599 6.96566C16.7414 6.96566 17.0506 6.65648 17.0506 6.27499V1.64071C17.0506 1.25919 16.7414 0.95 16.3599 0.95Z"
                                                        fill="#6F708B" stroke="#6F708B" stroke-width="0.1"></path>
                                                    <path
                                                        d="M15.1806 8.9153C14.7991 8.9153 14.4899 9.22449 14.4899 9.60598V15.6676H2.33138V3.50911H8.39302C8.77451 3.50911 9.0837 3.19992 9.0837 2.81844C9.0837 2.43696 8.77451 2.12773 8.39302 2.12773H1.64071C1.25919 2.12773 0.95 2.43692 0.95 2.81844V16.3583C0.95 16.7398 1.25919 17.049 1.64071 17.049H15.1806C15.5623 17.049 15.8713 16.7398 15.8713 16.3583V9.60598C15.8713 9.22449 15.5621 8.9153 15.1806 8.9153Z"
                                                        fill="#6F708B" stroke="#6F708B" stroke-width="0.1"></path>
                                                </svg>
                                            </span>
                                            <span (click)="enableExportCopy && copyGuides()"
                                                [ngStyle]="{'cursor': enableExportCopy ? 'pointer' : 'not-allowed' }"
                                                class=" py-4 pl-2 pr-4" data-bs-toggle="tooltip" data-placement="left"
                                                title="{{this.admin_data.copy}}">
                                                <svg version="1.2" overflow="visible" preserveAspectRatio="none"
                                                    viewBox="0 0 450 514" width="20" height="20" fill="#6F708B"
                                                    class="ng-scope">
                                                    <g transform="translate(1, 1)">
                                                        <path
                                                            d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM352 32.491a15.88 15.88 0 0 1 7.431 4.195l51.882 51.883A15.885 15.885 0 0 1 415.508 96H352V32.491zM288 464c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V144c0-8.822 7.178-16 16-16h80v240c0 26.51 21.49 48 48 48h112v48zm128-96c0 8.822-7.178 16-16 16H176c-8.822 0-16-7.178-16-16V48c0-8.822 7.178-16 16-16h144v72c0 13.2 10.8 24 24 24h72v240z"
                                                            vector-effect="non-scaling-stroke"></path>
                                                    </g>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <ul>
                                    <div *ngFor="let tour of tours; index as i">
                                        <div class="data-wrapper height35">
                                            <label class="custom-check mr-3">
                                                <input type="checkbox" name="" id='tour'
                                                    (change)="onCheckboxClick($event.target.checked,i)"
                                                    [checked]="getCheckedStatus(i)">
                                                <span class="checkmark mr-3"></span>
                                            </label>
                                            <span class="font14">{{tour['tour_title']}}</span>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <div *ngIf="tours.length == 0" class="no-guide">
                                <img src="./../../assets/svgs/no_guides_image.svg">
                                <div class="no-guide-label-1">
                                    <p>{{this.admin_data.noGuideFound}}</p>
                                </div>
                                <div class="no-guide-label-2">
                                    <p>{{this.admin_data.modifySearchCriteria}}</p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="round-button" #roundbutton>
                        <button class="btn btn-drop btn-primary " (click)="toggleDropdown()"> + </button>
                        <div class="dropdown" *ngIf="isDropdownOpen"
                            [ngStyle]="{'top':curr_view === 'guides_view' && copiedGuides !== undefined  ? '-100px' : '-50px'}">
                            <ul class="dropdown-list">
                                <li *ngIf="copiedGuides !== undefined && curr_view === 'guides_view' "
                                    class="dropdown-item font14" (click)="pasteGuide('paste')">
                                    <span class="mr-2">
                                        <svg width="16" height="16" viewBox="0 0 50 50">
                                            <g fill="#6F708B" stroke="#6F708B">
                                                <path
                                                    d="M34.2 48.5H9c-1.9 0-3.4-1.5-3.4-3.4V12.2c0-1.9 1.5-3.4 3.4-3.4h3.2c.4 0 .7.3.7.7s-.3.7-.7.7H9c-1.1 0-2 .9-2 2v32.9c0 1.1.9 2 2 2h25.3c1.1 0 2-.9 2-2v-3.2c0-.4.3-.7.7-.7s.7.3.7.7v3.2c-.1 1.9-1.6 3.4-3.5 3.4z" />
                                                <path
                                                    d="M34.2 48.9H9c-2.1 0-3.8-1.7-3.8-3.8V12.2c0-2.1 1.7-3.8 3.8-3.8h3.2c.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1H9c-.9 0-1.7.7-1.7 1.7v32.9c0 .9.7 1.7 1.7 1.7h25.3c.9 0 1.7-.7 1.7-1.7V42c0-.6.5-1.1 1.1-1.1s1.1.5 1.1 1.1v3.2c-.2 2-1.9 3.7-4 3.7zM9 9.2c-1.7 0-3 1.4-3 3v32.9c0 1.7 1.4 3 3 3h25.3c1.7 0 3-1.4 3-3v-3.2c0-.2-.1-.3-.3-.3-.2 0-.3.1-.3.3v3.2c0 1.3-1.1 2.4-2.4 2.4H9c-1.3 0-2.4-1.1-2.4-2.4V12.2c0-1.3 1.1-2.4 2.4-2.4h3.2c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.3H9z" />
                                            </g>
                                            <g fill="#6F708B" stroke="#6F708B">
                                                <path
                                                    d="M41 41.2H15.8c-1.9 0-3.4-1.5-3.4-3.4V4.9c0-1.9 1.5-3.4 3.4-3.4h18.5c.4 0 .7.3.7.7s-.3.7-.7.7H15.8c-1.1 0-2 .9-2 2v32.9c0 1.1.9 2 2 2H41c1.1 0 2-.9 2-2V11.9c0-.4.3-.7.7-.7s.7.3.7.7v25.9c0 1.9-1.5 3.4-3.4 3.4z" />
                                                <path
                                                    d="M41 41.6H15.8c-2.1 0-3.8-1.7-3.8-3.8V4.9c0-2.1 1.7-3.8 3.8-3.8h18.5c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1H15.8c-.9 0-1.7.7-1.7 1.7v32.9c0 .9.7 1.7 1.7 1.7H41c.9 0 1.7-.7 1.7-1.7v-26c0-.6.5-1.1 1.1-1.1s1.1.5 1.1 1.1v25.9c-.1 2.1-1.8 3.8-3.9 3.8zM15.8 1.9c-1.7 0-3 1.4-3 3v32.9c0 1.7 1.4 3 3 3H41c1.7 0 3-1.4 3-3V11.9c0-.2-.1-.3-.3-.3s-.3.1-.3.3v25.9c0 1.3-1.1 2.4-2.4 2.4H15.8c-1.3 0-2.4-1.1-2.4-2.4V4.9c0-1.3 1.1-2.4 2.4-2.4h18.5c.2 0 .3-.1.3-.3s-.1-.3-.3-.3H15.8z" />
                                                <path
                                                    d="M43.7 12.6h-9.5c-.4 0-.7-.3-.7-.7V2.4c0-.4.3-.7.7-.7s.7.3.7.7v8.8h8.8c.4 0 .7.3.7.7 0 .4-.3.7-.7.7z" />
                                                <path
                                                    d="M43.7 13h-9.5c-.6 0-1.1-.5-1.1-1.1V2.4c0-.6.5-1.1 1.1-1.1s1.1.5 1.1 1.1v8.4h8.4c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1zM34.3 2.1c-.2 0-.3.1-.3.3v9.5c0 .2.1.3.3.3h9.5c.2 0 .3-.1.3-.3s-.1-.3-.3-.3h-9.2V2.4c0-.1-.2-.3-.3-.3z" />
                                                <g>
                                                    <path
                                                        d="M43.7 12.6c-.2 0-.4-.1-.5-.2l-9.5-9.7c-.3-.3-.3-.7 0-1 .3-.3.7-.3 1 0l9.5 9.7c.3.3.3.7 0 1-.1.1-.3.2-.5.2z" />
                                                    <path
                                                        d="M43.7 13c-.3 0-.6-.1-.8-.3L33.4 3c-.4-.4-.4-1.1 0-1.5.6-.5 1.2-.5 1.6 0l9.5 9.7c.4.4.4 1.1 0 1.5-.2.2-.5.3-.8.3zM34.3 1.9c-.1 0-.2 0-.2.1-.1.1-.1.3 0 .4l9.5 9.7c.1.1.3.1.4 0 .1-.1.1-.3 0-.4L34.5 2c-.1-.1-.1-.1-.2-.1z" />
                                                </g>
                                            </g>
                                        </svg></span>{{admin_data.paste}}
                                </li>
                                <li class="dropdown-item font14" (click)="openFileInput()">
                                    <span class="mr-2"><svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path
                                                d="M6.73098 9.26951C7.00079 9.53934 7.43781 9.53933 7.70761 9.26949L14.8481 2.12904C14.8481 2.12903 14.8481 2.12903 14.8481 2.12902C15.118 1.85941 15.1179 1.42198 14.8481 1.15237C14.7134 1.01748 14.5365 0.950015 14.3598 0.950015C14.1833 0.950015 14.0064 1.0175 13.8714 1.15238L13.9068 1.18774L13.8714 1.15239L6.73098 8.29284C6.46112 8.56245 6.46112 8.9999 6.73098 9.26951Z"
                                                fill="#6F708B" stroke="#6F708B" stroke-width="0.1"></path>
                                            <path
                                                d="M7.2187 9.47188L11.853 9.47188C12.2345 9.47188 12.5437 9.16269 12.5437 8.78117C12.5437 8.39968 12.2345 8.0905 11.853 8.0905H7.90937L7.90937 4.14689C7.90937 3.7654 7.60018 3.45621 7.2187 3.45621C6.83721 3.45621 6.52799 3.7654 6.52799 4.14689L6.52799 8.78117C6.52799 9.16269 6.83718 9.47188 7.2187 9.47188Z"
                                                fill="#6F708B" stroke="#6F708B" stroke-width="0.1"></path>
                                            <path
                                                d="M15.1806 6.9153C14.7991 6.9153 14.4899 7.22449 14.4899 7.60598V13.6676H2.33138V1.50911H8.39302C8.77451 1.50911 9.0837 1.19992 9.0837 0.81844C9.0837 0.436958 8.77451 0.127734 8.39302 0.127734H1.64071C1.25919 0.127734 0.95 0.436922 0.95 0.81844V14.3583C0.95 14.7398 1.25919 15.049 1.64071 15.049H15.1806C15.5623 15.049 15.8713 14.7398 15.8713 14.3583V7.60598C15.8713 7.22449 15.5621 6.9153 15.1806 6.9153Z"
                                                fill="#6F708B" stroke="#6F708B" stroke-width="0.1"></path>
                                        </svg></span>{{admin_data.import}}
                                </li>
                            </ul>
                        </div>
                        <input type="file" accept=".tours, .json" (change)="handleFileChange($event)"
                            style="display: none;" #fileInput />
                    </div>

                </div>
                <div class="bg-white pt-4" *ngIf="curr_tab === 'import_guides'">
                    <!-- <div class="d-flex justify-content-end my-3">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 text-right mr-2">
                            <button class="btn btn-primary rounded" (click)="refresh()">{{admin_data.importUserVariable11}}</button>
                        </div>
                    </div> -->
                    <table class="table  table-bordered break-w">
                        <colgroup>
                            <col style="width: 25%;">
                            <col style="width: 20%;">
                            <col style="width: 15%;">
                            <col style="width: 20%;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th *ngFor="let header of importTableHeading; index as i">
                                    {{header}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let task of importedTasks">
                                <td>{{task['description']}}</td>
                                <td>
                                    <div class="progress">
                                        <div class="progress-bar text-overflow" role="progressbar"
                                            [attr.aria-valuenow]="task['progress']" [style.width]="task['progress']+'%'"
                                            aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                    </div>
                                    <div class="ml-auto">{{ task['progress'] }} %</div>
                                </td>
                                <td>{{task['status']}}</td>
                                <td>{{task['creation_date'] * 1000 | date: "EEE, MMM d, y" }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="bg-white pt-4" *ngIf="curr_tab === 'export_guides'">
                    <!-- <div class="d-flex justify-content-end my-3">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 text-right mr-2">
                            <button class="btn btn-primary rounded" (click)="refresh()">{{admin_data.importUserVariable11}}</button>
                        </div>
                    </div> -->
                    <table class="table  table-bordered break-w">
                        <colgroup>
                            <col style="width: 25%;">
                            <col style="width: 25%;">
                            <col style="width: 10%;">
                            <col style="width: 20%;">
                            <col style="width: 20%;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th *ngFor="let header of exportTableHeading; index as i">
                                    {{header}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let task of exportedTasks">
                                <td>{{task['description']}}</td>
                                <td>
                                    <div class="progress">
                                        <div class="progress-bar text-overflow" role="progressbar"
                                            [attr.aria-valuenow]="task['progress']" [style.width]="task['progress']+'%'"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div class="ml-auto">{{ task['progress'] }} %</div>
                                </td>
                                <td>{{task['status']}}</td>
                                <td>{{task['creation_date'] * 1000 | date: "EEE, MMM d, y" }}</td>
                                <td>
                                    <a (click)="downloadFile(task['task_url']+cdn_signature, task['task_url'])"
                                        [ngStyle]="{
                                    'pointer-events':task['task_url'] && task['task_url'].length> 0 ? 'visible':'none',
                                    'color':task['task_url'] && task['task_url'].length> 0 ? 'blue':'#cfd0dd'
                                    }" target="_blank"
                                        [download]="task['task_url'] && task['task_url'].length > 0 ? task['task_url'].split('/')[-1] : ''">
                                        <svg-icon *ngIf="task['progress']=='100'" class="mr-2 abcde"
                                            [src]="task['task_url'].length == 0 ? '../../assets/svgs/disable_download_icon.svg' :'../../assets/svgs/download_icon.svg'">
                                        </svg-icon>
                                        <h6 *ngIf="task['progress']=='100'"
                                            class="d-inline-block bottom-0 m-0 font14 font-56">
                                            {{this.admin_data.download}}
                                        </h6>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


        </section>

    </div>
</div>