import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../common/services/application.service';
import { OrganisationService } from '../../common/services/organisation.service';
import { UserService } from '../../common/services/user.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { API_CONNECTOR_FORMDATA, api_connector_types } from '../../constants/FormData/api_connector';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Renderer2 } from '@angular/core';
import { ApiConnectorService } from '../../common/services/api-connector.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from 'src/app/common/services/toast-modal.service';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

@Component({
    selector: 'app-create-api-connector',
    templateUrl: './create-api-connector.component.html',
    styleUrls: ['./create-api-connector.component.css']
})
export class CreateApiConnectorComponent implements OnInit {

    org_id;
    api_name;

    text_field = {
        type: "text",
        name: "folder_name",
        value: "",
        class: "col-lg-5 my-2",
        validators: [
            {
                required: true,
            },
        ],
        ui: {
            label: "Folder",
            class: "font-weight-bold",
            inputclass: "form-control",
        },
        input: {
            id: "first_name",
            class: "form-control",
            type: "text",
            name: "folder_name",
            placeholder: "Folder",
        },
        label: {
            title: "Folder",
            class: "font-weight-bold",
            for: "folder_name",
        },
    };

    form;
    allApplications;
    dropdownList = [];
    breadcrumb;
    connector_type;
    selectedApplication;
    category;

    api_connector_formData = JSON.parse(
        JSON.stringify(API_CONNECTOR_FORMDATA)
    );
    formGroup;
    admin_data = adminLabels ;

    constructor(
        private applicationService: ApplicationService,
        private organisationService: OrganisationService,
        public userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        public apiConnectorService: ApiConnectorService,
        private toastr: ToastrService,
		private errorResponseService : ErrorResponseService,
        private toastService:ToastModalService
    ) {
        this.userService.getUserData();
        this.organisationService.getCDNData()
        this.apiConnectorService.isEdit = false;
        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];
            if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" )) {
                this.getOrganization();
                if (params["params"]["connector_id"]) {
                    let api_name = params["params"]["connector_id"];
                    this.api_name = params["params"]["connector_id"];
                    this.apiConnectorService.getApiConnectors(api_name).subscribe((res) => {
                        this.apiConnectorService.isEdit = true;
                        this.apiConnectorService.selectedConnector = res['data']['setting'][0];
                        this.ngOnInit();
                    })
                }
            }

            if (params["params"]["connector_id"] && this.userService.user.role != 'superadmin') {
                let api_name = params["params"]["connector_id"];
                this.api_name = params["params"]["connector_id"];
                this.apiConnectorService.getApiConnectors(api_name).subscribe((res) => {
                    this.apiConnectorService.isEdit = true;
                    this.apiConnectorService.selectedConnector = res['data']['setting'][0];
                    this.getApplications();
                    this.ngOnInit();
                })
            }
            this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                if(params['params']['connector_id']){
                    this.userService.setBreadcrumb([
                        {
                            name:"API Connectors",
                            route:['/organization',this.org_id,'api_connector']
                        },
                        {
                            name:this.admin_data.tableTitle2,
                            route:null
                        }
                    ])
                }else{
                    this.userService.setBreadcrumb([
                        {
                            name:"API Connectors",
                            route:['/organization',this.org_id,'api_connector']
                        },
                        {
                            name:this.admin_data.addButtonText,
                            route:null
                        }
                    ])
                }
                this.breadcrumb=this.userService.breadCrumbs;
            });
        });

        if (this.userService.user.role != 'superadmin' && !this.api_name) {
            this.getApplications();
        }

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // if (!this.applicationService.isEdit) {
                //     this.userService.setBreadcrumbs(
                //         "api-connector-add:",
                //         this.route.url["_value"]
                //     );
                // }
            });

    }

    // setBreadCrumb() {
    //     this.userService.setBreadcrumbs(
    //         "api-connector-edit",
    //         this.route.url["_value"]
    //     );
    // }

    getOrganization() {
        this.organisationService
            .getOrganization(this.org_id)
            .subscribe((response) => {
                let organization = response["data"]["organization"];
                this.userService.organization = organization["name"];
                this.userService.organization_id = organization["organization_id"];
                // this.userService.user.cdn_signature = organization["cdn_signature"];
                this.userService.organization = organization;
                let admin_settings = JSON.parse(
                    this.userService.organization.admin_settings
                );
                this.userService.enableSegments =
                    admin_settings["guide_segmentation"] == "1" ? true : false;
                this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
                this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
                this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
                this.userService.enableMediaThemes=admin_settings['media_files_themes'] == 0 ? false : true;

                let org = organization;
                let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

                cdn_sign["cdn_signature"] = organization["cdn_signature"];
                cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

                this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                localStorage.setItem("organization", JSON.stringify(org));
                localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
                this.getApplications();
            });
    }

    getApplications() {
        var dropdownList = this.dropdownList;
        let self = this;
        var promise = new Promise((resolve, reject) => {
            let applicationService = this.applicationService;
            let limit = 100;
            let offset = 0;
            let cb = applicationService.getApplications(limit, offset);
            this.allApplications = [];
            let allApplications = this.allApplications;
            let self = this;
            function application() {
                cb.subscribe(
                    (data) => {
                        if (data["data"]["applications"].length > 0) {
                            Array.prototype.push.apply(
                                allApplications,
                                data["data"]["applications"]
                            );
                            offset += 100;
                            cb = applicationService.getApplications(limit, offset);
                            application();
                        } else {
                            resolve(allApplications);
                        }
                    },
                    (error) => {
                    }
                );
            }
            application();
        }).then(function (data: []) {
            dropdownList = data.map((d) => {
                return {
                    value: d["application_id"],
                    name: d["title"],
                };
            });
            self.dropdownList = dropdownList;
            self.api_connector_formData[1]['options'] = self.dropdownList;
            self.ngOnInit();
        });
    }

    ngOnInit() {
        let form = {};
        for (let i = 0; i < this.api_connector_formData.length; i++) {
            form[this.api_connector_formData[i].name] = new FormControl("");
            let validations = [];
            for (let v of this.api_connector_formData[i].validators) {
                for (let key in v) {
                    validations.push(key);
                }
            }
            let validators = [];
            for (let j = 0; j < validations.length; j++) {
                switch (validations[j]) {
                    case "required":
                        validators.push(Validators.required);
                        break;
                    
                    case "maxLength":
                        validators.push(Validators.maxLength);
                        break;

                    case "email":
                        validators.push(Validators.email);
                        break;
                    case "regex":
                        validators.push(
                            Validators.pattern(
                                this.api_connector_formData[i].validators[j]["regex"]
                            )
                        );
                        break;
                }
            }
            form[this.api_connector_formData[i].name].setValidators(validators);
        }
        this.form = form;
        this.formGroup = new FormGroup(form);
        let inputValidateField = ["folder"];
        for(let ind=0; ind<inputValidateField.length; ind++){
            this.formGroup.get(inputValidateField[ind]).valueChanges.subscribe(x => {
                if (x && !this.userService.pattern.test(x)) {
                this.formGroup.get(inputValidateField[ind]).setValue(x.replace(this.userService.replacePattern, ""),{ emitEvent: false });
                }
            });
        }

        if (this.apiConnectorService.isEdit && this.dropdownList.length > 0) {
            let setting = JSON.parse(this.apiConnectorService.selectedConnector.setting);
            this.apiConnectorService.getCategory(setting['category_id']).subscribe((res) => {
                let category = res['data']['category'];
                this.category = category;
                this.formGroup.patchValue({
                    api_name: this.apiConnectorService.selectedConnector['api_name']
                })
                let data = {
                    value: this.apiConnectorService.selectedConnector['api_name'],
                    formName: 'api_name'
                }
                this.setApiType(data);

                for (let i = 0; i < this.connector_type.length; i++) {
                    this.formGroup.patchValue({
                        [this.connector_type[i].key_name]: setting[this.connector_type[i].key_name]
                    })
                }
                this.formGroup.patchValue({
                    'application_id': setting['application_id'],
                    'folder': category['category_title']
                })
                this.selectedApplication = setting['application_id'];
                this.api_connector_formData[this.api_connector_formData.length - 1]['input']['disabled'] = true;
            })
        }
    }

    setApiType(data) {
        if (data['formName'] == 'application_id') {
            if (data['value'] != this.selectedApplication) {
                this.api_connector_formData[this.api_connector_formData.length - 1]['input']['disabled'] = null;
            } else {
                this.formGroup.patchValue({
                    folder: this.category['category_title']
                })
                this.api_connector_formData[this.api_connector_formData.length - 1]['input']['disabled'] = true;
            }
        }
        else {
            let api_name = data['value'];
            if (api_name == 'zendesk' && this.api_connector_formData.length==3) {
                this.connector_type = api_connector_types['zendesk_connector'];
                let i = 1;
                this.connector_type.forEach((type) => {
                    let obj = JSON.parse(JSON.stringify(this.text_field));
                    obj['name'] = type['key_name'];

                    if (obj['name'] == 'api_endpoint'){
                        obj['validators'] = [{ maxLength: 80 }];
                    }
                    if (obj['name'] == 'email_address'){
                        obj['validators'] = [{ email: true }];
                    }

                    obj['ui']['label'] = type['Label'];
                    obj['label']['title'] = type['Label'];
                    obj['input']['placeholder'] = type['Label'];
                    this.form[obj['name']] = new FormControl("");
                    let validations = [];
                    for (let v of obj.validators) {
                        for (let key in v) {
                            validations.push(key);
                        }
                    }
                    let validators = [];
                    for (let j = 0; j < validations.length; j++) {

                        switch (validations[j]) {
                            case "required":
                                validators.push(Validators.required);
                                break;
                                                    
                            case "maxLength":
                                validators.push(Validators.maxLength);
                                break;

                            case "email":
                                validators.push(Validators.email);
                                break;
                            
                            case "regex":
                                validators.push(Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,4}$/));
                                break;
                        }
                    }
                    this.form[obj.name].setValidators(validators);


                    this.api_connector_formData.splice(i, 0, obj);
                    i += 1;


                })
                this.formGroup = new FormGroup(this.form);
                let inputValidateField = ["api_endpoint","token"];
                for(let ind=0; ind<inputValidateField.length; ind++){
                    this.formGroup.get(inputValidateField[ind]).valueChanges.subscribe(x => {
                        if (x && !this.userService.pattern.test(x)) {
                        this.formGroup.get(inputValidateField[ind]).setValue(x.replace(this.userService.replacePattern, ""),{ emitEvent: false });
                        }
                    });
                }
            }
        }
    }

    addConnector() {
        this.apiConnectorService.add_button.disable=true;
        this.apiConnectorService.add_button.text=this.admin_data.savingButtonText;
        let value = this.formGroup.value;
        let request = {}
        if (value['api_name'] == 'zendesk') {
            let setting = {
                api_endpoint: this.formGroup.get('api_endpoint').value.trim(),
                email_address: this.formGroup.get('email_address').value,
                token: this.formGroup.get('token').value.trim(),
                application_id: this.formGroup.get('application_id').value,
                folder: this.formGroup.get('folder').value.trim()
            }
            request = {
                organization_id: this.org_id,
                api_name: this.formGroup.get('api_name').value,
                setting: JSON.stringify(setting)
            }
        }

        this.apiConnectorService.createConnector(request).subscribe((res) => {
            this.apiConnectorService.add_button.disable=false;
            this.apiConnectorService.add_button.text=this.admin_data.saveButtonText;
            this.apiConnectorService.isEdit = false;
            this.toastService.showToast=true;
            this.toastService.toastType="add_connector";
            this.router.navigate(['/organization',
                this.userService.organization_id,
                'api_connector']);
           
        })
    }

    unassign() {
        this.router.navigate(['/organization',
            this.userService.organization_id,
            'api_connector']);

        this.apiConnectorService.isEdit = false;

    }

}
