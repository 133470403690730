<div class="page-wrapper" [style.border-top]="header_border ? '2px solid ' + theme.theme_color : 'none'">
  <img *ngIf="theme && theme.theme_id == 'theme-3'" class="header-img" alt="Header Strip"
    src="../../../../assets/images/themes/theme-3/header-strip.png" />
  <div class="row mt-20pc">
    <div class="col">
      <div *ngIf="theme && show_color_box" class="color-box" [(colorPicker)]="theme.theme_color" [cpOKButton]="true"
        [cpSaveClickOutside]="false" [cpOKButtonClass]="'btn btn-primary btn-xs'" [style.background]="theme.theme_color"
        (colorPickerSelect)="onSaveThemeColor()" data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom" title="{{this.admin_data.tableTitle4}}"></div>
      <div [ngClass]="getLogoStyle()">
        <div class="save-options" *ngIf="logo_options">
           <button class="btn btn-primary btn-sm" type="button" (click)="onSaveLogo(org_id, 'logo')">
            {{this.admin_data.saveButtonText}}
          </button>
          <button class="btn btn-secondary btn-sm" type="button" (click)="onCancelLogo('logo')">
            {{this.admin_data.cancelButtonText}}
          </button>
        </div>
        <div *ngIf="theme.hasOwnProperty('first_page'); then showFirstPageImg; else defaultImg"></div>
        <ng-template #showFirstPageImg>
          <img *ngIf="theme && binary_image" class="logo" alt="Brand Logo" (click)="logo.click()" [src]="theme.first_page.logo_url
                                      ? theme.first_page.logo_url
                                      : '../../../../assets/images/themes/logo.png'" data-toggle="tooltip"
            (mouseover)="showToolTip()" (mouseout)="removeToolTip()" (mouseover)="showToolTip()" (mouseout)="removeToolTip()"
            data-placement="bottom" title="{{this.admin_data.tableTitle4}}"/>
          <img *ngIf="theme && !binary_image" class="logo" alt="Brand Logo" (click)="logo.click()" [src]="theme.first_page.logo_url
                                      ? theme.first_page.logo_url + cdn_sign
                                      : '../../../../assets/images/themes/logo.png'" data-toggle="tooltip"
            (mouseover)="showToolTip()" (mouseout)="removeToolTip()" (mouseover)="showToolTip()" (mouseout)="removeToolTip()"
            data-placement="bottom" title="{{this.admin_data.tableTitle4}}" />
        </ng-template>
        <ng-template #defaultImg>
          <img  class="logo" alt="Brand Logo" (click)="logo.click()" [src]="'../../../../assets/images/themes/logo.png'" data-toggle="tooltip"
            (mouseover)="showToolTip()" (mouseout)="removeToolTip()" (mouseover)="showToolTip()" (mouseout)="removeToolTip()"
            data-placement="bottom" title="{{this.admin_data.tableTitle4}}" />
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row mt-5pc">
    <div class="col">
      <div *ngIf="theme && show_title_strip" class="title-strip" [style.background-color]="theme.theme_color">
      </div>
      <div [ngClass]="getTitleStyle()">{{this.admin_data.guideTitle}}</div>
    </div>
  </div>
  <div *ngIf="theme && theme.theme_id == 'theme-5'" class="row mt-60pc">
    <div class="col">
      <img class="footer-strip" src="../../../../assets/images/themes/theme-5/footer-strip.png" alt="Footer Strip" />
    </div>
  </div>
  <div *ngIf="theme && show_domain_name" class="row"
    [style.margin-top]="theme && theme.theme_id == 'theme-5' ? '4%' : '80%'">
    <div class="col">
      <div class="edit-options" *ngIf="edit_domain_name">
        <input [(ngModel)]="theme.domain_name" value="{{ theme.domain_name }}" type="text" name="domain_name_input" autocomplete="off"
          id="domain_name_input" maxlength="50" />
          <button [disabled]="theme.domain_name.trim()==''" class="btn btn-primary btn-sm" type="button" (click)="onSaveDomainName()">            
              {{this.admin_data.saveButtonText}}
          </button>
          <button class="btn btn-secondary btn-sm" type="button" (click)="onCancelDomainName()">
              {{this.admin_data.cancelButtonText}}
          </button>
      </div>
      <div (click)="onEditDomainName()" [ngClass]="getDomainNameStyle()" data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom"
        title="{{this.admin_data.tableTitle4}}">
        <span>{{ theme.domain_name }}</span>
        <span *ngIf="userService.enableDisplayPublishDate" class="text-left font-published" (mouseover)="$event.stopPropagation()" (click)="$event.stopPropagation()">Published on {{themeService.current_date | date: 'mediumDate'}}</span>
      </div>
    </div>
  </div>
  <div [style.margin-top]="theme && theme.theme_id == 'theme-3' ? '450px' : theme && theme.theme_id == 'theme-1'?'60px':'487px'">
    <span *ngIf="theme && theme.theme_id == 'theme-2'" class="page-number">Page ##</span>
    <span *ngIf="userService.enableDisplayPublishDate && theme &&( theme.theme_id == 'theme-2'  || theme.theme_id == 'theme-3')" class="page-number" [style.margin-top]="theme && theme.theme_id == 'theme-3'?'-4px':''"><ng-container *ngIf="theme && theme.theme_id == 'theme-2'"> | </ng-container>Published on {{themeService.current_date | date: 'mediumDate'}}</span>
    <div class="save-options" *ngIf="footer_logo_options">
      <button class="btn btn-primary btn-sm" type="button" (click)="onSaveLogo(org_id, 'footer_logo')">
        {{this.admin_data.saveButtonText}}
      </button>
      <button class="btn btn-secondary btn-sm" type="button" (click)="onCancelLogo('footer_logo')">
        {{this.admin_data.cancelButtonText}}
      </button>
    </div>


    <div *ngIf="theme.hasOwnProperty('first_page'); then showFirstPageImg_footer; else defaultImg_footer"></div>
    <ng-template #showFirstPageImg_footer>
        <img *ngIf="show_footer_logo && binary_image" class="footer-logo footer-logo-binary" alt="Footer Logo" (click)="footer_logo.click()"
          [src]="
            theme.first_page.footer_logo_url
              ? theme.first_page.footer_logo_url
              : '../../../../assets/images/themes/logo.png'
          " data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom" title="{{this.admin_data.tableTitle4}}" />
        <img *ngIf="show_footer_logo && !binary_image" class="footer-logo" alt="Footer Logo" (click)="footer_logo.click()"
          [src]="
            theme.first_page.footer_logo_url
              ? theme.first_page.footer_logo_url + cdn_sign
              : '../../../../assets/images/themes/logo.png'
          " data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom" title="{{this.admin_data.tableTitle4}}" />
    </ng-template>
    <ng-template #defaultImg_footer>
        <img  class="footer-logo" alt="Footer Logo" (click)="footer_logo.click()" [src]="'../../../../assets/images/themes/logo.png'
                  " data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" (mouseover)="showToolTip()"
          (mouseout)="removeToolTip()" data-placement="bottom" title="{{this.admin_data.tableTitle4}}" />
    </ng-template>
    </div>
  <div *ngIf="show_footer_strip">
    <img class="footer-strip" src="../../../../assets/images/themes/footer-strip.png" alt="Footer Strip" />
  </div>
</div>
<input [(ngModel)]="file_1" class="d-none" type="file" accept=".jpg,.png" #logo (input)="onSelectLogo($event, 'logo')" />
<input [(ngModel)]="file_2" class="d-none" type="file" accept=".jpg,.png" #footer_logo
  (input)="onSelectLogo($event, 'footer_logo')" />
