<div class="modal fade" tabindex="-1" role="dialog" id="confirmModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{headerValue}} <small><b>{{orgValue}}</b></small></h5>
                <svg-icon class="ml-auto" role="button" style="fill:#555555" src="../../../assets/svgs/close-icon-large.svg" (click)="closeModal()"></svg-icon>
            </div>
            <div class="modal-body font14">{{contentValue}}</div>
            <div class="modal-footer justify-content-end">
                <button type="button" class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
                    (click)="closeModal()">{{this.admin_data.cancelButtonText}}</button>
                <!-- <button type="button" class="btn btn-danger minWidth-120 mx-2" (click)="delete()">{{this.admin_data.deleteButtonText}}</button> -->
                <!-- <app-button [buttonData]="userService.delete_button" (clickhandler)="delete()"></app-button> -->
                <button class="btn btn-danger" [disabled]="isDisabled" (click)="delete()">{{modalOkValue}}</button>
            </div>
            </div>
    </div>
</div>
