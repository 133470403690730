import { Injectable } from '@angular/core';
import { ApiService } from './common/services/api.service';
import { environment } from 'src/environments/environment';
import { API_END_POINTS } from './constants/api-end-points';
import adminLabels from "./constants/ApplicationStrings/trans_utils";
import { UserService } from './common/services/user.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';

export interface Folder {
  creation_date: "string",
  id: "string",
  is_active: "string",
  modification_date: "string",
  organization_id: "string",
  task_list_id: "string",
  title: string,
  contents: any[]
}

export interface Content {
  "tour_id": "string",
  "category_id": "string",
  "user_id": "string",
  "organization_id": "string",
  "tour_title": "string",
  "tour_description": "string",
  "tour_image": "string",
  "tour_thumbnail": "string",
  "tour_type": "onboarding_tour,overlay_tour",
  "tour_settings": "{\"beacon\":{},\"process_ppt\":{\"file_id\":0,\"url\":\"\"},\"slideshow_mode\":false,\"guide_tracking\":false,\"stepDelayTime\":\"Select\",\"rules\":[{\"type\":\"URL\",\"name\":\"\",\"condition\":\"Equals\",\"value\":\"www.google.com\",\"logical_condition\":\"\",\"showValueField\":true}],\"overlay_rules\":[],\"video_settings\":{\"first_slide_text\":\"Welcome\",\"first_slide_recorded_audio\":false,\"first_slide_recorded_audio_url\":\"\",\"first_slide_img\":\"https://stagecdn.guideme.io/organization-v3-777/image/_5e9094de4ee00_1586533598.png\",\"last_slide_text\":\"Thank you\",\"last_slide_recorded_audio\":false,\"last_slide_recorded_audio_url\":\"\",\"last_slide_img\":\"https://stagecdn.guideme.io/organization-v3-777/image/_5e9094de4ee04_1586533598.png\"},\"show_title_slide\":true,\"show_slide\":true,\"show_video_subtitle\":true,\"add_video_animation\":\"\",\"language_settings\":{\"language\":\"en-US\",\"name\":\"\",\"voice\":\"Jessa\"},\"step_settings\":{\"forceSlideshow\":false,\"hideStepIcon\":false,\"doNotShowPopup\":false,\"forceGuideMe\":false},\"play_structure\":[],\"downloadGuideFiles\":{\"video_file\":true,\"pdf_file\":true,\"pptx_file\":true,\"gif_file\":true,\"text_file\":true,\"word_file\":true,\"blog_file\":true},\"tour_type\":\"default\",\"version\":\"2020.6.30.1\",\"enableAudio\":false,\"disableAudio\":false,\"enableVideoDefaultShowme\":false,\"hidePrevBtn\":false,\"forwardMode\":false,\"watchRulesElement\":false,\"segments\":[],\"isAutolaunch\":false,\"videoNotification\":false,\"sentiment\":{}}",
  "tour_url": "www.google.com",
  "tour_order": "4",
  "is_private": "1",
  "is_published": "1",
  "is_active": "1",
  "creation_date": "1599217223",
  "modification_date": "1599639828",
  "application_id": "1214",
  "is_locked": "0",
  "creator_id": "2086",
  "sync_priority": "0",
  "is_sync_complete": "1",
  "language_data": null,
  "old_sha": null,
  "new_sha": null,
  "version": "1",
  "keywords": "tutorial guide",
  "associated_labels": null,
  "image_path": "organization-v3-777/image/_5e9097a593831_1586534309-default_icon.png",
  "thumbnail_path": "organization-v3-777/image/_5e9097a593831_1586534309-default_icon.png",
  "user_first_name": "richa",
  "user_last_name": "sharma",
  "user_email": "richa+777@edcast.com",
  "user_image_path": "organization-v3-777/image/_5e90956adbbfe_1586533738-default_icon.png",
  "category_title": "Guide Mixed",
  "step_count": "1",
  "image_url": "https://stagecdn.guideme.io/organization-v3-777/image/_5e9097a593831_1586534309-default_icon.png",
  "thumbnail_url": "https://stagecdn.guideme.io/organization-v3-777/image/_5e9097a593831_1586534309-default_icon.png",
  "user_image_url": "https://stagecdn.guideme.io/organization-v3-777/image/_5e90956adbbfe_1586533738-default_icon.png",
  "languages": []
}

export interface Task_List {
  application_id: "string"
  content: []
  creation_date: "string"
  id: "string"
  is_active: "string"
  is_publish: ""
  modification_date: ""
  organization_id: ""
  setting: ""
  title: ""
}

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  save_button={
    type:'save_folder',
    class:'btn btn-primary minWidth-120',
    disable:false,
    text:adminLabels.saveButtonText
  }

  update_button={
    type:'update_folder',
    class:'btn btn-primary minWidth-120',
    disable:false,
    text:adminLabels.updateButtonText
  }

  constructor(private apiService: ApiService, private userService: UserService, private http: HttpClient) { }
  getHeader() {
    return {
      headers: new HttpHeaders({
        // 'AppKey': environment.authKey,
        'AccessToken': this.userService.user.token
      })
    }
  }

  getTasks(id, language) {
    let urlPath = environment.baseUrl + API_END_POINTS.GET_TASKS + "?id=" + id;
    if(language != ''){
        urlPath += '&language=' + language;
    }
    return this.apiService.get(urlPath);
  }

  saveFolder(request) {
    let urlPath = environment.baseUrl + API_END_POINTS.ADD_FOLDER;
    return this.apiService.post(urlPath, request);
  }

  editFolder(request) {
    let urlPath = environment.baseUrl + API_END_POINTS.UPDATE_FOLDER;
    return this.apiService.put(urlPath, request);
  }

  updateFolderWithLanguageUpdate(request) {
    let urlPath = environment.baseUrl + API_END_POINTS.PUT_FOLDER_TASKLIST_LANGUAGE_UPDATE;
    return this.apiService.put(urlPath, request);
  }

  getAllGuides(limit, offset, search = '',application_id) {
    let urlPath;
    if(search!='' && search.trim()!=''){
       urlPath = environment.baseUrl + API_END_POINTS.SEARCH_TOUR_LIST;
       urlPath+='?limit=' + limit + '&offset=' + offset + '&organization_id=' + this.userService.organization_id +"&application_id="+application_id +'&is_published='+1+'&task_list='+1+'&search_text=' + search;
    }else{
      urlPath = environment.baseUrl + API_END_POINTS.GET_TOUR_LIST;
      urlPath += '?limit=' + limit + '&offset=' + offset + '&organization_id=' + this.userService.organization_id +"&application_id="+application_id +'&published_guides=' + 1+'&task_list='+1;
    }
    // if (search != '') {
    //   urlPath += '&search_text=' + search;
    // }
    return this.apiService.get(urlPath);
  }

  getContents(folder_id, language) {
    let urlPath = environment.baseUrl + API_END_POINTS.GET_CONTENT;
    urlPath += "?folder_id=" + folder_id;
    if(language != ''){
        urlPath += '&language=' + language;
    }
    return this.apiService.get(urlPath);
  }

  getGuide(id){
    let urlPath = environment.baseUrl+"/tour"+"?tour_id="+id;
    return this.apiService.get(urlPath);
  }

  updateContents(request) {
    let urlPath = environment.baseUrl + API_END_POINTS.UPDATE_CONTENT;
    return this.apiService.put(urlPath, request);
  }

  deleteFolder(req) {
    let urlPath = environment.baseUrl + API_END_POINTS.DELETE_FOLDER;
    let options = this.getHeader();
    options['body'] = req;
    return this.http.delete(urlPath, options);
  }

    updateOrder(req){
        let urlPath = environment.baseUrl +API_END_POINTS.UPDATE_CONTENT_ORDER;
        return this.apiService.post(urlPath, req);
    }
}
