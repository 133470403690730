export class CustomError extends Error {
    errorType;
    errorLog;
    severity;
    filePath;
    urlPath;
    constructor(error) {
        super(error);
        this.name = "CustomError";
        this.errorType = error.errorType;
        this.errorLog = error.errorLog;
        this.severity = error.severity;
        this.filePath = error.filePath;
        this.urlPath = error.urlPath;
    }
}