import adminLabels from "../ApplicationStrings/trans_utils";

export const ADD_TASK_LIST_FORMDATA = [
  {
    type: "text",
    name: "title",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    layoutModal: true,
    validators: [
      {
        required: true,
        maxLength: 80
      },
    ],
    ui: {
      label: adminLabels.tableHeaderTitle,
      class: "col-lg-4 font14",
      inputclass: "col-lg-8",
    },
    input: {
      id: "title",
      class: "form-control",
      type: "text",
      name: "title",
      placeholder: adminLabels.tableHeaderTitle,
    },
    label: {
      title: adminLabels.tableHeaderTitle,
      class: "font-weight-bold label-asterisk",
      for: "title",
    },
  },
  {
    type: "select",
    name: "application_id",
    value: "",
    disable:false,
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    layoutModal: true,
    options: [],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.taskListTableHeaderApplication,
      class: "font-weight-bold label-asterisk font14",
      inputclass: "form-control",
    },
  },
  {
    type: "checkbox",
    name: "translation_required",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
    value: "",
    checked: false,
    validators: [],
    ui: {
      label :  adminLabels.translation_required,
      class: "mb-0",
      inputclass: "primary",
    },
  },
];
