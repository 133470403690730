import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { UserService } from 'src/app/common/services/user.service';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import adminLabels from "../constants/ApplicationStrings/trans_utils";


@Component({
    selector: 'app-themes',
    templateUrl: './themes.component.html',
    styleUrls: ['./themes.component.css']
})
export class ThemesComponent implements OnInit {
    org_id: number;
    breadcrumb;
    admin_data = adminLabels ;

    constructor(
        private route: ActivatedRoute,
        public userService: UserService,
        private router: Router,
        private titleService:Title
    ) {
        this.userService.activeTab = 'themes';
        this.titleService.setTitle(this.admin_data.themeSettings + " | " + "Cornerstone Guide");
        this.userService.getUserData();
        
        if(this.userService.user.role != "superadmin" && !this.userService.enableMediaThemes){
            this.router.navigate([
              "/organization",
              this.userService.organization_id
          ]);
        }
        this.userService.activeTab = 'themes';
        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];
            if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" )) {
                this.ngOnInit();
            }
        });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs(this.admin_data.themes, this.route.url["_value"]);
                this.userService.setBreadcrumb([
                    {
                        name:this.admin_data.themes,
                        route:null
                    }
                ])
                this.breadcrumb=this.userService.breadCrumbs;
            });
    }

    ngOnInit() { }
}
