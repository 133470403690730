<div class="d-flex">
    <app-spinner *ngIf="!loader"></app-spinner>
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>


        <section class="component-section">
            <div class="sectionBody">
                <div style="display: flex; flex-direction: column;">
                    <div class="d-flex justify-content-end">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 displayBlock text-right section-header">
                            <!-- <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right marginTop--10"> -->

                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 sectionHeader section-title">
                                {{this.admin_data.manageKeywords}}
                            </div>
                            <div class="d-flex justify-content-between btns-filters-div">
                                <div
                                    class="col-sm-4 col-md-4 col-lg-4 col-xl-4 bulk-add-keyword d-flex justify-content-start">
                                    <button *ngIf="curr_tour_ids.size>0" class="btn btn-primary mr-2"
                                        (click)="openModalForBulkupdate()">{{admin_data.assignKeywords}}</button>
                                    <button *ngIf="curr_tour_ids.size>0" class="btn btn-secondary"
                                        (click)="onCheckboxClick(false, -1)">{{admin_data.clear}}</button>
                                </div>
                                <div
                                    class="d-inline-flex justify-content-end col-sm-8 col-md-8 col-lg-8 col-xl-8 align-items-center">
                                    <div class="form-group text-left d-inline-block mb-0 pr-0">
                                        <div class="d-flex flex-row align-items-end" [ngStyle]="{ 'min-width': '200px' }">
                                            <!-- <label class="font14 font-weight-bold mr-1"
                                                style="min-width: fit-content !important"
                                                for="app">{{this.admin_data.applicationsBreadCrumbTitle}}</label> -->
                                            <!-- <select #app class="form-control pr-0" id="app"
                                                (change)="appChanged($event.target.value)">
                                                <option *ngFor="let app of apps" [value]="app['application_id']"
                                                    [selected]="this.curr_app_id===app['application_id']">{{app['name']}}
                                                </option>
                                            </select> -->
                                            <app-custom-select #app [dataList]="apps"
                                                (updateDataList)="app.value=$event; appChanged($event);"
                                                [selected]="curr_app_id" componentName="apps" [ngStyle]="{ 'width': '100%' }"></app-custom-select>
                                        </div>
                                    </div>
                                    <div id="filter" class="ml-2 filter-dropdown">
                                        <button class="btn-primary-borderless-medium" id="filter-menu" (click)="showFilterMenu = !showFilterMenu"
                                            data-toggle="dropdown">
                                            <span class="d-flex align-items-center">
                                                <span class="mr-2">
                                                    <img src="../../../../assets/svgs/filter_new.svg" />
                                                </span>
                                                <span>{{admin_data.filter}}</span>
                                            </span>
                                        </button>
                                        <div class="dropdown-menu pb-0" role="menu" [ngClass]="{'show': showFilterMenu}"
                                            id="filter-dropdown-menu">
                                            <div
                                                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left px-4 pt-3 mb-4">
                                                <div class="d-flex justify-content-between align-items-baseline">
                                                    <span class="fontWeight-900 font16 text-color-dark">{{this.admin_data.filters}}</span>
                                                    <div class="d-flex justify-content-end align-items-center">
                                                        <span type="button" class="primaryFont-color mx-2 px-2 font13 fontWeight-700" (click)="clear()">
                                                            {{this.admin_data.clear}}</span>
                                                        <button type="button" class="btn btn-primary-small"
                                                            (click)="applyFilter(app.value,status.value,folder.value, userFilter.value);$event.stopPropagation()">
                                                            {{this.admin_data.apply}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" form-group px-4 mb-4">
                                                <label class="fontWeight-900 font14 text-color-dark-grey"
                                                    for="status">{{this.admin_data.usersVariable2}}</label>
                                                <!-- <select #status class="form-control form-control-sm" id="status">
                                                    <option value="" [selected]="curr_status===''">
                                                        {{this.admin_data.usersVariable3}}
                                                    </option>
                                                    <option value="1" [selected]="curr_status==='1'">
                                                        {{this.admin_data.published}}
                                                    </option>
                                                    <option value="0" [selected]="curr_status==='2'">
                                                        {{this.admin_data.unpublished}}
                                                    </option>
                                                </select> -->
                                                <app-custom-select #status [dataList]="customSelectObj"
                                                    (updateDataList)="status.value = $event" [selected]="curr_status"
                                                    componentName="guides" dropdownPosition="inherit" [ngStyle]="{ 'position': 'relative' }"></app-custom-select>

                                            </div>
                                            <div class=" form-group px-4 mb-4">
                                                <label class="fontWeight-900 font14 text-color-dark-grey"
                                                    for="folder">{{this.admin_data.apiConnectorFolder}}</label>
                                                <!-- <select #folder class="form-control form-control-sm" id="folder">
                                                    <option value="" [selected]="curr_folder===''">
                                                        {{this.admin_data.usersVariable3}}
                                                    </option>
                                                    <option *ngFor="let folder of folders" [value]="folder['category_id']"
                                                        [selected]="curr_folder===folder['category_id']">{{folder['name']}}
                                                    </option>
                                                </select> -->

                                                <app-custom-select #folder [dataList]="folders"
                                                    (updateDataList)="folder.value = $event" [selected]="curr_folder"
                                                    componentName="folder" dropdownPosition="inherit" [ngStyle]="{ 'position': 'relative' }"></app-custom-select>
                                            </div>
                                            <div class="form-group px-4 mb-4">
                                                <label class="fontWeight-900 font14 text-color-dark-grey"
                                                    for="userFilter">{{this.admin_data.usersVariable8}}</label>
                                                <!-- <select #userFilter class="form-control form-control-sm" id="userFilter">
                                                    <option value="" [selected]="curr_user===''">
                                                        {{this.admin_data.usersVariable3}}
                                                    </option>
                                                    <option *ngFor="let user of users" [value]="user['user_id']"
                                                        [selected]="curr_user===user['user_id']">{{user['name']}}
                                                    </option>
                                                </select> -->
                                                <app-custom-select #userFilter [dataList]="users"
                                                    (updateDataList)="userFilter.value = $event" [selected]="curr_user"
                                                    position="up" componentName="users" [ngStyle]="{ 'position': 'relative' }"></app-custom-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group w-50 ml-1 d-inline-flex width-300">
                                        <input class="form-control py-2 search" type="search"
                                            placeholder={{this.admin_data.searchPlaceholder}} autocomplete="off"
                                            (input)="search()" (search)="search()" aria-label="Search"
                                            [(ngModel)]="search_text">
                                        <!-- <span class="input-group-append">
                                            <div class="input-group-text bg-white">
                                                <svg-icon src="../assets/svgs/search-icon.svg"></svg-icon>
                                            </div>
                                        </span> -->
                                    </div>
                                </div>

                            </div>

                            <!-- </div> -->

                        </div>
                    </div>
                    <div class="d-flex scroll-x w-1240 table-container marginTop-30">
                        <table *ngIf="tours.length>0" class="table table-bordered break-w mg-table rounded-table">
                            <colgroup>
                                <col style="width: 5%;">
                                <col style="width: 20%;">
                                <col style="width: 10%;">
                                <col style="width: 10%;">
                                <col style="width: 30%;">
                                <col style="width: 5%;">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <label class="custom-check">
                                            <input type="checkbox" name="all" id="allCheck"
                                                (change)="onCheckboxClick($event.target.checked,-1)">
                                            <span class="checkmark mr-3"></span>
                                        </label>
                                    </th>
                                    <th *ngFor="let header of tableHeading; index as i">{{header}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let tour of tours; index as i">
                                    <td>
                                        <label class="custom-check">
                                            <input type="checkbox" name="" id='tour'
                                                (change)="onCheckboxClick($event.target.checked,i)"
                                                [checked]="getCheckedStatus(i)">
                                            <span class="checkmark mr-3"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <div class="data-wrapper">
                                            <img class="guide-logo" [src]="getIcon(tour)">
                                            <span>{{tour['tour_title']}}</span>
                                        </div>
                                    </td>
                                    <td>{{tour['category_title']}}</td>
                                    <td>{{getCreatorName(tour['creator_id'])}}</td>
                                    <td class="tags">
                                        <ng-container *ngFor="let item of tour['keywords']; index as i">
                                            <span *ngIf="i < 3" class="badge rounded-pill badge-secondary font14 h-3">
                                                <span class="badge-text">{{item}}</span>
                                                <span class="crossIcon" role="button"
                                                    (click)="deleteTag(tour['tour_id'],tour['keywords'][i])">
                                                    <svg-icon src="../../../assets/svgs/closeIcon.svg"
                                                        (click)="openModal(i)" role="button">
                                                    </svg-icon>
                                                </span>
                                            </span>

                                            <span *ngIf="i==3">...</span>
                                        </ng-container>
                                    <td>
                                        <svg-icon src="../../../assets/svgs/edit.svg" (click)="openModal(i)"
                                            role="button">
                                        </svg-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div *ngIf="tours.length == 0" class="no-guide">
                            <img src="./../../assets/svgs/no_guides_image.svg">
                            <div class="no-guide-label-1">
                                <p>{{this.admin_data.noGuideFound}}</p>
                            </div>
                            <div class="no-guide-label-2">
                                <p>{{this.admin_data.modifySearchCriteria}}</p>
                            </div>
                        </div>
                    </div>
                    <app-pagination [offset]="offset" [totalRecords]="paginationService.total"
                        [currentPage]="paginationService.currentPage" [limit]="limit" (gotoFirstPage)="gotoFirstPage()"
                        (gotoLastPage)="gotoLastPage($event)" (gotoPreviousPage)="prependOffset()"
                        (gotoNextPage)="appendOffset()" (changeLimit)="changeLimit($event)"
                        (gotoAction)="gotoPage($event)"></app-pagination>
                </div>
                <div *ngIf="this.taskSubscription && !this.taskSubscription.closed" class="loading-div w-1240">
                    <div class="loading">
                        <img class="spinner-img img-fluid loading-img" src="../../../assets/images/g_new_loader.gif" />
                    </div>
                    <div>
                        <span>{{admin_data.keywordLoading}}</span>
                    </div>
                </div>
            </div>
        </section>

        <div class="modal fade" tabindex="-1" role="dialog" id="assignKeywords" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">{{admin_data.addKeywords}}</h6>
                    </div>
                    <div class="modal-body font14 tags">
                        <div class="d-inline-flex w-100 justify-content-around align-items-center">
                            <!-- // <input type="text" class="form-control" [placeholder]="admin_data.addKeywords" (keydown)="keyPressed($event)" [(ngModel)]="keyword"> -->
                            <!-- <input type="text" value="Istanbul, Adana, Adiyaman, Afyon, Agri, Aksaray, Ankara" data-role="tagsinput" class="form-control" /> -->
                            <div style="width: 95%;">
                                <tag-input [(ngModel)]='curr_keywords' [editable]='false' [(inputText)]="blankString"
                                    [modelAsStrings]="true" [separatorKeys]="[',']" [theme]="'foundation-theme'"
                                    [placeholder]="admin_data.addKeywords"
                                    [secondaryPlaceholder]="admin_data.addKeywords" (onAdd)="updateDisabledProp(false)"
                                    (onRemove)="updateDisabledProp(false)"></tag-input>
                            </div>
                            <div class="marginLeft-10">
                                <div data-bs-toggle="tooltip" data-placement="right"
                                    title="{{this.admin_data.keywordTooltip}}">
                                    <!-- <span class="tooltiptext">{{this.admin_data.brandLogoTooltip}}</span> -->
                                    <svg-icon src="../../assets/svgs/icon-info.svg"></svg-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-end">
                        <button type="button" class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
                            (click)="closeModal()">{{this.admin_data.cancelButtonText}}</button>
                        <button type="button" [disabled]="disableUpdateTag" class="btn btn-primary minWidth-120 mx-2"
                            (click)="addKeywords()">{{this.admin_data.updateButtonText}}</button>
                        <!-- <app-button [buttonData]="userService.delete_button" (clickhandler)="delete()"></app-button>
                    <button class="btn btn-primary" (click)="delete()">{{modalOkValue}}</button>-->
                    </div>
                </div>
            </div>
        </div>
    </div>