<div *ngIf="theme" class="page-wrapper" [style.border-top]="header_border ? '2px solid ' + theme.theme_color : 'none'">
    <img class="header-img" alt="Header Strip" *ngIf="theme && header_img"
        src="../../../../assets/images/themes/theme-3/header-strip.png" />
    <div *ngIf="theme" [ngClass]="getStepNumStyle()" [style.background-color]="theme.theme_color"
        [(colorPicker)]="theme.theme_color" [cpOKButton]="true" [cpSaveClickOutside]="false"
        [cpOKButtonClass]="'btn btn-primary btn-xs'" (colorPickerSelect)="onSaveThemeColor()" data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()"
        data-placement="bottom" title="{{this.admin_data.tableTitle4}}">
        <p class="white-text">{{ getStepText() }}</p>
    </div>
    <div>
        <div [ngClass]="getStepTitleStyle()">{{this.admin_data.stepTitle}}</div>
        <div class="step-desc">{{this.admin_data.stepDescription}}</div>
        <div class="step-desc">{{this.admin_data.stepTooltips}}</div>
        <img class="step-image" [style.border]="'1px solid ' + theme.theme_color"
            src="../../../../assets/images/themes/step-image.png" alt="Step Image" />
    </div>
    <div [style.margin-top]="header_img ? '150px' : '171px'">
        <span class="page-number">{{this.admin_data.pageThemeNumber}}</span>
        <div class="save-options" *ngIf="footer_logo_options">
            <button class="btn btn-primary btn-sm" type="button" (click)="onSaveLogo(org_id)">
              {{this.admin_data.saveButtonText}}
            </button>
            <button class="btn btn-secondary btn-sm" type="button" (click)="onCancelLogo()">
              {{this.admin_data.cancelButtonText}}
            </button>
        </div>


        <div *ngIf="theme.hasOwnProperty('middle_page'); then middle_load_img1; else middle_default_img1"></div>
        <ng-template #middle_load_img1>
          <img *ngIf="theme && binary_image" class="footer-logo footer-logo-binary" alt="Footer Logo" (click)="footer_logo.click()" [src]="
          theme.middle_page.footer_logo_url
            ? theme.middle_page.footer_logo_url
            : '../../../../assets/images/themes/logo.png'
        " data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom" title="{{this.admin_data.tableTitle4}}" />
          <img *ngIf=" theme && !binary_image" class="footer-logo" alt="Footer Logo" (click)="footer_logo.click()" [src]="
          theme.middle_page.footer_logo_url
            ? theme.middle_page.footer_logo_url + cdn_sign
            : '../../../../assets/images/themes/logo.png'
        " data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom" title="{{this.admin_data.tableTitle4}}" />
      </ng-template>
      <ng-template #middle_default_img1>
        <img class="footer-logo" alt="Footer Logo" (click)="footer_logo.click()" [src]="'../../../../assets/images/themes/logo.png'
                " data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom"
          title="{{this.admin_data.tableTitle4}}" />

      </ng-template>

    </div>
    <div *ngIf="addFooterStrip()">
        <img class="footer-strip" src="../../../../assets/images/themes/footer-strip.png" alt="Footer Strip" />
    </div>
</div>
<input class="d-none" [(ngModel)]="file_1" type="file" accept=".jpg,.png" #footer_logo (input)="onSelectLogo($event)" />