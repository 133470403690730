<div class="d-flex">
    <div id="flexNav">
        <app-left-panel></app-left-panel>
    </div>
    <div id="cont" class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>

        <section class="component-section">
        <div class="sectionBody">
            <div class="d-flex mb-3">
                <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                    {{this.admin_data.organisationAddText}}
                </div>
            </div>
            <div class="">
                <app-form [formData]="add_org_formdata" [formGroup]="formGroup"></app-form>
                <div class="row m-0 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50">
                    <div
                        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-4 d-flex align-items-center justify-content-center">
                        <!-- <button type="button" class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
                            (click)="unassign()">{{this.admin_data.cancelButtonText}}</button> -->
                        <app-button [buttonData]="organisation.cancel_button" (clickhandler)="unassign()"></app-button>
                        <!-- <button type="button" class="btn btn-primary minWidth-120 mx-2" (click)="addOrganization()"
                            [disabled]="!formGroup.valid">Add</button> -->
                        <app-button [disabled]="!formGroup.valid" [buttonData]="organisation.add_button" (clickhandler)="addOrganization()"></app-button>
                    </div>
                </div>
            </div>
        </div>
        </section>
        
    </div>
</div>