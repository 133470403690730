import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../common/services/user.service';
import { ApiService } from '../common/services/api.service';

import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard  {


	constructor(public userService: UserService,
		private router: Router,
		private route: ActivatedRoute,
		public apiService: ApiService) {
	}
	isAppAdmin = false;
	hasRoleAppAdmin = false;

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		// if (JSON.parse(localStorage.getItem("user"))) {
		//   let role = JSON.parse(localStorage.getItem("user"))['role'];
		//   if (role) {
		//     if (role == 'app_admin') {
		//       let url: any = state.url;
		//       let currentUrl = this.route.url['_value'];
		//       let split = url.split("/");
		//       if (url == "/") {
		//         return true;
		//       }
		//       if (url.split("/").length == 3 && split[2] == "organization") {
		//         return true;
		//       }
		//       if (url.split("/").length == 4) {

		//         if (split[3] == 'application') {
		//           this.isAppAdmin = true;
		//         }
		//       }
		//       if (url.split("/").length == 6) {
		//         if (split[3] == 'application' && split[4] == 'edit') {
		//           this.isAppAdmin = true;
		//         }
		//       }
		//       if (this.isAppAdmin) {
		//         return true;
		//       } else {
		//         return false;
		//       }
		//     }
		//   }
		// }

		if (this.userService.isLoggedIn()) {
			if (JSON.parse(localStorage.getItem("user"))) {
				const query = new URLSearchParams(window.location.search);
				const urlData = [];
				query.forEach((v, k) => {
					urlData[k] = v;
				});

				let urlString = '';
				for (let urlParam in urlData) {
					if (urlString.length === 0) {
						urlString += '?';
					} else {
						urlString += '%';
					}
					urlString += (urlParam + '=' + urlData[urlParam]);
				}

				if (urlString.includes('redirectURL')) {
					const urlVal = atob(urlData['redirectURL'])
					localStorage.setItem('URLVAL', urlVal)

					if(urlVal.includes('logout')){
						this.router.navigate(['/login'], { queryParams: urlData });
					} else {
						this.router.navigate(['/loader'], { queryParams: urlData });
					}
				}

				let role = JSON.parse(localStorage.getItem("user"))['role'];
				if (route.data.roles.indexOf(role) != -1) {
					if (urlString.includes('redirectURL')) {
						const urlLink = atob(urlData['redirectURL']);

						let token = localStorage.getItem('token');
						token = JSON.parse(token);

						const accessToken = token['accesstoken'];
						const refreshToken = token['refreshtoken'];


						if (urlLink.includes('logout')) {
							const data = {
								redirectURL: btoa('/dashboard')
							}
							this.router.navigate(['/login'], { queryParams: data });
							setTimeout(() => {
								localStorage.clear();
								window.location.reload();
							}, 0)
						} else {
							if (accessToken && refreshToken) {
								this.userService.generateHandoffToken(accessToken, refreshToken).subscribe(
									(response1) => {
										if (response1['error']) {                                            
                                            this.router.navigate(["/login"])
										} else if (response1['data']["handoff-token"]) {
											if(this.userService.user.role == 'user' || this.userService.user.role == 'player' ){
												this.router.navigate(['/login'], { queryParams: urlData });
											} else {
												if(this.userService.user.role !== 'creator'){
													const appendSign = urlLink.includes('dashboard') ? '?' : '&';
													window.open(environment.INSIGHTS_URL + '/#' + urlLink + appendSign + 'handoff-token=' + response1["data"]["handoff-token"], "_self");
												} else {
													window.open(environment.INSIGHTS_URL + '/#/survey/manage-survey/?' + 'handoff-token=' + response1["data"]["handoff-token"], "_self");
												}
											}
										} else {
										}
									},
									(error) => {
                                        this.router.navigate(['/login']);
									}
								);
							} else {
								const data = {
									redirectURL: btoa('/dashboard')
								}
								this.router.navigate(['/login'], { queryParams: data });
							}
						}
					} else {
						return true;
					}
				} else {
					this.router.navigate(["**"]);
					return false;
				}
			}
			return true;
		} else {
			if (!window.location.search.includes('handoff-token') && !window.location.search.includes('redirectURL')){
				if(environment.production){
					
					window.open(environment.INSIGHTS_URL+'/?firstRedirect=admin', "_self");
				} else {
					// localhost 4200 login
					const query = new URLSearchParams(window.location.search);
					const urlData = [];
					query.forEach((v, k) => {
						urlData[k] = v;
					});
						
					this.router.navigate(['/login'], { queryParams: urlData });
					return false;
				}
			} else {
				const query = new URLSearchParams(window.location.search);
				const urlData = [];
				query.forEach((v, k) => {
					urlData[k] = v;
				});

				this.router.navigate(['/login'], { queryParams: urlData });
				return false;
			}
		
		}
	}
}
