import adminLabels from "../constants/ApplicationStrings/trans_utils";

let admin_data = adminLabels
let popupDesign_default = {
    bgColor: "#ffffff",
    stepCountColor: "#505050",
    borderColor: "#dfe0e7",
    borderWidth: 1,
    borderRadius: 3,
    nextBtnBackground: "#005BF0",
    nextBtnColor: "#ffffff",
    prevBtnBackground: "#ffffff",
    prevBtnColor: "#8e8fa6",
    stepTitleColor: "#26273b",
    stepDescColor: "#8e8fa6",
    audioIconColor: "#acadc1",
    closeIconColor: "#acadc1",
    stepDesFontSize: "medium",
    stepDesFontFamily: "arial, sans-serif",
    stepTitleFontSize: "medium",
    stepTitleFontFamily: "arial, sans-serif",
    stepTitleFontWeight: 600,
    padding: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
    }
};

let userLabels = {
    guideMe: admin_data.guideMe,
    showMe: admin_data.showMe,
    testMe: admin_data.testMe,
    vLab: admin_data.teachMe,
    doItForMe: admin_data.doItForMe,
    miniPlayerHelp: admin_data.miniPlayerHeader,
    userNotFollowingGuideMessage: admin_data.oopsMessageDefaulText,
    maintenanceMessage: admin_data.maintenanceDefaulText,
    serviceErrorMessage: admin_data.serviceErrorDefaulText,
    resumeGuideMessage: admin_data.resumeGuideDefaulText,
    next: admin_data.manageContentVariable29,
    btnPrev: admin_data.prev
};

let defaultColorArray = {
    0: "#FBEEB8",
    1: "#F8CAC6",
    2: "#ECCAFA",
    3: "#C2E0F4",
    4: "#2DC26B",
    5: "#F1C40F",
    6: "#E03E2D",
    7: "#B96AD9",
    8: "#3598DB",
    9: "#169179"
};

let popupDesign_classic = {
    bgColor: "#454560",
    stepCountColor: "#505050",
    borderColor: "#454560",
    borderWidth: 1,
    borderRadius: 0,
    nextBtnBackground: "#ffffff",
    nextBtnColor: "#26273b",
    prevBtnBackground: "#ffffff",
    prevBtnColor: "#26273b",
    stepTitleColor: "#ffffff",
    stepDescColor: "#ffffff",
    audioIconColor: "#cfd0dd",
    closeIconColor: "#cfd0dd",
    stepDesFontSize: "medium",
    stepDesFontFamily: "arial, sans-serif",
    stepTitleFontSize: "medium",
    stepTitleFontFamily: "arial, sans-serif",
    stepTitleFontWeight: 600,
    padding: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
    }
};

let organization = {
    appId: "",
    appTitle: "",
    description: "",
    orgName: "",
    image_id: "",
    admin_settings: {},
    settings: {
        popupDesign: {
            type: "default",
            default: popupDesign_default,
            classic: popupDesign_classic,
            current: popupDesign_default
        },
        tutorialBgColor: "#45465F",

        tutorial_guide: {
            titleBgColor: "#26273b",
            nextBtn: {
                color: "#ffffff",
                bgColor: "#005BF0",
                border_color: "#005BF0",
                border_width: 1,
                border_radius: 4,
                padding: {
                    top: 5,
                    left: 5,
                    bottom: 5,
                    right: 5
                },
                font_size: "medium"
            },
            prevBtn: {
                color: "#ffffff",
                bgColor: "#005BF0",
                border_color: "#005BF0",
                border_width: 1,
                border_radius: 4,
                padding: {
                    top: 5,
                    left: 5,
                    bottom: 5,
                    right: 5
                },
                font_size: "medium"
            },
            outerBox: {
                border_color: "#454560",
                border_width: 1,
                border_radius: 0,
                shadow: {
                    horizontal: 2,
                    vertical: 2,
                    blur: 5,
                    spreadRadius: 0,
                    color: "#00000080",
                    inset: false
                }
            }
        },
        notificationTheme:{
            width: '600',
            height: '300',
            header: {
                background: '#FF240A',
                color: '#FFFFFF',
                font: 'Lato'
            },
            body: {
                title_font_family: 'Lato',
                title_color: '#000000',
                title_font_size: 'medium',
                desc_font_family: 'Lato',
                desc_color: '#555555',
                desc_font_size: 'medium',
                padding_top: '20',
                padding_bottom: '20',
                padding_left: '20',
                padding_right: '20',
                background_color: "#FFFFFF",
                fill: "#707070",
                action_button_font: "Lato",
                action_button_text_color: "#FFFFFF",
                action_button_background: "#005BF0",
                action_button_border_color: "#FFFFFF",
                action_button_url: ""
            },
            footer: {
                primaryBtnTextColor: '#FFFFFF',
                primaryBtnTextSize: 'medium',
                primaryBtnFont: 'Lato',
                primaryBtnBackground : '#005BF0',
                primaryBtnBorderColor: '#005BF0',
                secondaryBtnTextColor: '#555555',
                secondaryBtnTextSize: 'medium',
                secondaryBtnFont: 'Lato',
                secondaryBtnBackground : '#E6E6E6',
                secondaryBtnBorderColor: '#555555',

            }
        },
        feedback_type: "email",
        feedback_email: "support@csod.com",
        feedback_url: "edcast.com",
        helpUrl: "https://help.csod.com/MyGuide/",
        showWidgetIcon: true,
        widgetIconPos: "bottom-right",
        showWidgetIconRight: true,
        showWidgetIconBottom: true,
        widget_icon_pos: {
            widget_icon_top_pos: 5,
            widget_icon_bottom_pos: 150,
            widget_icon_left_pos: 5,
            widget_icon_right_pos: 5,
            widget_icon_id: "",
            widget_icon_top_pos_unit: "px",
            widget_icon_bottom_pos_unit: "px",
            widget_icon_left_pos_unit: "px",
            widget_icon_right_pos_unit: "px"
        },
        logo: "myguide.png",
        watermark_video: "myguide.png",
        widget_icon_path: "myguide.png",
        first_slide: "default_first_slide.png",
        last_slide: "default_last_slide.png",
        chat_icon_path: "chat_bot_icon.png",
        hide_widget_if_noguide: false,
        widget_icon_zindex: "Default",
        notificationDelay: 0,
        guide_count_on_widget: true,
        keep_player_panel_open: false,
        guide_count_widget_color: "#FF0000",
        notificationsTime: "24 hours",
        playAudio: true,
        hideBrandLogo: false,
        first_slide_text: admin_data.firstSlideText,
        last_slide_text: admin_data.lastSlideText,
        userLabels: userLabels,
        selectorStyle: {
            borderColor: "#00a6d9",
            borderWidth: 2
        },
        tooltipColor: "#005BF0",
        stepPopupWidth: 300,
        logo_id: "",
        guideMeSetting: true,
        showMeSetting: true,
        testMeSetting: true,
        doitforme: false,
        vLab: false,
        giphy: true,
        video: true,
        slideshow: true,
        notifications: {
            notifThemeColor: "#26273b"
        },
        defaultPlayAction: "guideMe",
        isMiniPlayer: false,
        stepDefaultColorArray: defaultColorArray,
        tooltipTheme: false,
        widgetIconType: "circular",
        widgetIconSize: {
            widgetIconWidth: 50,
            widgetIconHeight: 50
        },
        chatIconType: "circular",
        chatIconSize: {
            chatIconWidth: 50,
            chatIconHeight: 50
        },
        all_guide: false,
        defaultGuideView: false,
        chatIconPos:"",
        enable_send_feedback: true,
        showChatbotIcon: true,
        autoProceedStep: false,
        autoStepDelay: 4
    },
}

export default organization;