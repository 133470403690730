import { Component, OnInit } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import {
    SegmentationService,
    Segmentation,
    Segment_List,
} from "../../common/services/segmentation.service";
import {
    FormGroup,
    FormControl,
    FormBuilder,
    Validators,
} from "@angular/forms";
import { UserService } from "../../common/services/user.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';
import { OrganisationService } from '../../common/services/organisation.service';
import { Title } from '@angular/platform-browser';
import { SEGMENT_FORMADATA } from "src/app/constants/FormData/segment";
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "src/app/common/services/toast-modal.service";
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
declare var $: any;

@Component({
    selector: "app-segment-list",
    templateUrl: "./segment-list.component.html",
    styleUrls: ["./segment-list.component.css"],
})
export class SegmentListComponent implements OnInit {
    admin_data = adminLabels ;
    modalHeader: any;
    modalContent: any;

    constructor(
        public segmentation: SegmentationService,
        public userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private organisationService: OrganisationService,
        private titleService: Title,
		private errorResponseService : ErrorResponseService,
        private toastService: ToastModalService
    ) {
        this.userService.getUserData();
        if (this.userService.include_default_segment) {
            this.segmentation.list_appTitles = [this.admin_data.segmentsListTableHeaderValue, this.admin_data.segmentsListTableHeaderIsDefault, this.admin_data.tableHeaderActions];
            this.segmentation.list_appDataKeys = ['', 'value', 'default_value', 'segment_list_action'];
        } else {
            this.segmentation.list_appTitles = [this.admin_data.segmentsListTableHeaderValue, this.admin_data.tableHeaderActions];
            this.segmentation.list_appDataKeys = ['', 'value', 'segment_list_action'];
        }

        if (this.userService.user.role != "superadmin" && !this.userService.enableSegments) {
            this.router.navigate([
                "/organization",
                this.userService.organization_id
            ]);
        }

        this.organisationService.getCDNData()
        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];
            this.segment_id = params["params"]["segment_id"];
        });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs(
                //     "Segment-List:",
                //     this.route.url["_value"]
                // );.
                this.userService.setBreadcrumb([
                    {
                        name: this.admin_data.userAttributes,
                        route: ['/organization', this.org_id, 'segmentation']
                    },
                    {
                        name: this.admin_data.userAttributeValues,
                        route: null
                    }
                ])
                this.breadcrumb=this.userService.breadCrumbs;
            });
    }

    limit = 10;
    offset = 0;
    segmentation_list = [];
    passSegmentationList = [];
    segment_list_formData = SEGMENT_FORMADATA;

    pages = [1, 2];
    currentPage = 1;
    prevIndex = 0;
    currentIndex = 1;
    total = 0;
    pageLimit: number = 0;
    disableNext = false;
    disablePrev = false;
    showPagination = false;
    images;
    formData;
    searchInputApp;
    deleteIndex = 0;
    delete_segment_id;
    formGroup;
    org_id;
    segment_id;
    segment_list_values = [];
    key_name;
    deleteSegment;
    include_default = false;
    breadcrumb;

    ngOnInit() {
        this.titleService.setTitle(this.admin_data.userAttributes + " | " + "Cornerstone Guide");
        this.userService.getUserData();
        this.organisationService.getCDNData()
        this.getSegmentationlist();
        this.initializeForm();
    }

    initializeForm() {
        let form = {};
        for (let i = 0; i < this.segment_list_formData.length; i++) {
            form[this.segment_list_formData[i].name] = new FormControl("");
            let validations = [];
            for (let v of this.segment_list_formData[i].validators) {
                for (let key in v) {
                    validations.push(key);
                }
            }
            let validators = [];
            for (let j = 0; j < validations.length; j++) {
                switch (validations[j]) {
                    case "required":
                        validators.push(Validators.required);
                        break;

                    case "email":
                        validators.push(Validators.email);
                        break;
                }
            }
            form[this.segment_list_formData[i].name].setValidators(validators);
        }
        this.formGroup = new FormGroup(form);
    }

    getSegmentationlist() {
        this.segmentation_list = [];
        this.segment_list_values = [];
        this.segmentation
            .getSegmentationList(this.segment_id)
            .subscribe((response) => {
                let d = JSON.parse(response["data"]["segment"]["data"]);
                this.key_name = response["data"]["segment"]["key_name"];
                if (d) {
                    d.forEach((value, i) => {
                        let elem = {
                            value: value,
                            default_value: response['data']['segment']['default_value'],
                            segment_list_action: 0,
                        };
                        this.segmentation_list.push(elem);
                    });
                }
                this.segmentation_list.forEach((value) => {
                    this.segment_list_values.push(value["value"]);
                });
                this.passSegmentationList = this.segmentation_list;
                if (response['data']['segment']['default_value'] != null && this.userService.include_default_segment) {
                    this.segment_list_formData.splice(1, 1);
                    this.include_default = false;
                } else {
                    if (this.userService.include_default_segment) {
                        this.segment_list_formData = JSON.parse(JSON.stringify(SEGMENT_FORMADATA));
                        this.include_default = true;
                    } else {
                        this.segment_list_formData.splice(1, 1);
                        this.include_default = false;
                    }
                }
                this.initializeForm();
                if (this.toastService.showToast) {
                    this.toastService.showToastMessage(this.toastService.toastType);
                }
            });
    }

    showAddSegmentListModal() {
        $("#addSegmentListModal").modal("show");
        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();
    }

    closeAddSegmentModal() {
        this.resetFormData();
        $("#addSegmentListModal").modal("hide");
    }

    resetFormData() {
        for (let i = 0; i < this.segment_list_formData.length; i++) {
            this.formGroup.patchValue({
                [this.segment_list_formData[i].name]: "",
            });
        }
    }

    addSegmentList() {
        this.segmentation.add_value_button.disable = true;
        this.segmentation.add_value_button.text = this.admin_data.segmentsAddingValueButtonText
        this.segment_list_values.push(this.formGroup.get("name").value.trim());
        let request = {
            key_name: this.key_name,
            data: JSON.stringify(this.segment_list_values),
            id: this.segment_id,
        };
        if (this.include_default) {
            if (this.formGroup.get('is_default').value == '1') {
                request['default_value'] = this.formGroup.get("name").value.trim();
            }
        }
        this.segmentation.addSegmentList(request).subscribe((response) => {
            this.segmentation.add_value_button.disable = false;
            this.segmentation.add_value_button.text = this.admin_data.segmentsAddValueButtonText
            if (!response['error']) {
                this.closeAddSegmentModal();
                this.toastService.showToast = true;
                this.toastService.toastType = "add_segment_value";
                this.getSegmentationlist();
            } else {
                this.segment_list_values.pop();
                this.closeAddSegmentModal();
                this.userService.showModal.next(false);
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            }
        }, error => {
            this.segmentation.add_value_button.disable = false;
            this.segmentation.add_value_button.text = this.admin_data.segmentsAddValueButtonText
        });
    }

    showDeleteModal(data) {
        this.deleteSegment = data;      
        this.modalHeader = this.admin_data.deleteUserAttributeValue;
        let str = this.admin_data.deleteUserAttributeValueContent
        str = str.split(' ')
        str.splice(str.length-3, 0,"'" + data + "'")
        this.modalContent = str.join(' ');
        this.organisationService.showConfirmationModal.next(true);
    }

    closeDeleteModal() {    
        this.organisationService.showConfirmationModal.next(false);
    }

    delete_Segment() {
        this.userService.delete_button.disable=true;
        this.userService.delete_button.text=this.admin_data.deletingButtonText;
        this.segment_list_values = this.segment_list_values.filter((data) => {
            return data != this.deleteSegment;
        });
        let request = {
            key_name: this.key_name,
            data: JSON.stringify(this.segment_list_values),
            id: this.segment_id,
        };
        this.segmentation.addSegmentList(request).subscribe((response) => {
            this.userService.delete_button.disable=false;
            this.userService.delete_button.text=this.admin_data.deleteButtonText;
            this.userService.showModal.next(false);
            this.toastService.showToast = true;
            this.toastService.toastType = "delete_segment_value";
            this.getSegmentationlist();
            this.closeDeleteModal();
        },error=>{
            this.userService.delete_button.disable=false;
            this.userService.delete_button.text=this.admin_data.deleteButtonText;
        });
    }
    navigateToImport(){
        this.router.navigate(['/segment-list', this.org_id, this.segment_id, 'import'])
    }    
}
