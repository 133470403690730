<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view" 
    [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" (click)="changeTab(1)">
              <a class="nav-link custom-nav-link active" id="sso-tab" data-toggle="tab" href="#identity_providers"
                  role="tab" aria-controls="sso" aria-selected="true">{{this.admin_data.ssoListIdentityProviders}}</a>
          </li>
          <li class="nav-item" (click)="changeTab(2)">
              <a class="nav-link custom-nav-link" id="domain-tab" data-toggle="tab" href="#domain_mappings" role="tab"
                  aria-controls="domains" aria-selected="true">{{this.admin_data.ssoListDomainMappings}}</a>
          </li>
          </ul>
      <div class="tab-container pt-4 pb-4 pl-4 pr-4 clearfix">
          <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="identity_providers" role="tabpanel" aria-labelledby="sso-tab" *ngIf="this.currentTab==1">
                  <ng-container>
                      <div class="d-flex justify-content-end my-3">
                          <div class="col-sm-8 col-md-6 col-lg-5 col-xl-5 p-0 text-right">
                              <button type="button" class="btn btn-primary ml-2" (click)="showAddSSOModal()">{{this.admin_data.addSSOIDP}}</button>
                          </div>
                      </div>
                      <app-table [titles]="ssolistService.appTitles" [dataKeys]="ssolistService.appDataKeys" [data]="sso_list" [widths]="ssolistService.col_widths"
                      (delete)="showDeleteModal($event)" (edit)="getSSOData($event)"></app-table>
  
                      <div class="modal fade" id="addSSOModal" tabindex="-1" role="dialog" aria-labelledby="addSSOModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title" id="addRoleModalLabel">{{this.admin_data.addSSOIDP}}</h5>
                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeAddSsoModal()">
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <div class="modal-body">
                                      <app-form [formData]="sso_list_formData" [formGroup]="formGroup"></app-form>
                                  </div>
                                  <div class="modal-footer justify-content-center">
                                      <button type="button" class="btn btn-secondary minWidth-120" data-dismiss="modal"
                                          (click)="closeAddSsoModal()">{{this.admin_data.cancelButtonText}}</button>
                                      <button type="button" class="btn btn-primary minWidth-120"
                                          (click)="ssolistService.isEdit?editSSO():addSSO()" [disabled]="!formGroup.valid">
                                          <ng-container *ngIf="ssolistService.isEdit">{{this.admin_data.updateSSO}}</ng-container>
                                          <ng-container *ngIf="!ssolistService.isEdit">{{this.admin_data.addSSO}}</ng-container>
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                    <app-pagination [offset]="offset" [totalRecords]="paginationService.total" [currentPage]="paginationService.currentPage"
                        [limit]="limit" (gotoFirstPage)="gotoFirstPage()" (gotoLastPage)="gotoLastPage($event)"
                        (gotoPreviousPage)="prependOffsetAndGetSSOList()" (gotoNextPage)="appendOffsetAndGetSSOList()" (changeLimit)="changeLimit($event)"
                        (gotoAction)="gotoPage($event)"></app-pagination>
                        <br>
  
                      <ng-container *ngIf="sso_list">
                          <ng-container *ngIf="sso_list.length>0">
                              <app-modal-confirmation [header_value]="modalHeader" [content_value]="modalContent"
                                  [modal_ok_value]="this.userService.delete_button.text" [org]=""
                                  [disabled]="this.userService.delete_button.disable"
                                  (onDelete)="deleteSSO($event)"></app-modal-confirmation>
                          </ng-container>
                      </ng-container>
                  </ng-container>
              </div>
              <div class="tab-pane fade show active" id="domain_mappings" role="tabpanel" aria-labelledby="domain-tab"  *ngIf="this.currentTab==2">  
                  <ng-container>    
                      <app-domain></app-domain>
                  </ng-container>
              </div>
          </div>
      </div>
  
      </div>
    </div>
