<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">
			
			<div class="sectionBody">
                <div class="d-flex mb-3">
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                        {{this.admin_data.createOrganization}}
                    </div>
                </div>
                <div class="">
                    <app-form [formData]="org_formData" [formGroup]="formGroup" (images)="setImages($event)"  (generic_button_clicked_event)="formButtonClicked($event)" (svg_button_clicked_event)="formButtonClicked($event)"></app-form>
                    <div *ngIf="userService.user.role == 'superadmin' || userService.user.role == 'owner' || userService.user.role == 'mg_admin' || userService.user.role == 'partner_admin'" class="row m-0 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 d-flex justify-content-center my-5">
                            <!-- <button type="button" class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
                                (click)="unassign()">{{this.admin_data.cancelButtonText}}</button> -->
                            <app-button [buttonData]="organisationService.cancel_button" (clickhandler)="unassign()"></app-button>
                            <!-- <button type="button" class="btn btn-primary minWidth-120 mx-2" (click)="editOrganisation()">Update
                            </button> -->
                            <app-button [buttonData]="organisationService.update_button" (clickhandler)="editOrganisation()"></app-button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
</div>