import adminLabels from "../ApplicationStrings/trans_utils";

export const API_CONNECTOR_FORMDATA = [
  {
    type: "select",
    name: "api_name",
    value: "",
    class: "col-lg-5 my-2",
    options: [
      {
        value: "zendesk",
        name: "Zendesk",
      }
    ],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.tableHeaderType,
      class: "font-weight-bold",
      inputclass: "form-control",
    },
  },
  {
    type: "select",
    name: "application_id",
    value: "",
    class: "col-lg-5 my-2",
    options: [
    ],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.taskListTableHeaderApplication,
      class: "font-weight-bold",
      inputclass: "form-control",
    },
  },
  {
    type: "text",
    name: "folder",
    value: "",
    class: "col-lg-5 my-2",
    validators: [
      {
        required: true,
        maxLength: 1000,
      },
    ],
    ui: {
      label: adminLabels.apiConnectorFolder,
      class: "font-weight-bold",
      inputclass: "form-control",
    },
    input: {
      id: "first_name",
      class: "form-control",
      type: "text",
      name: "folder_name",
      placeholder: adminLabels.apiConnectorFolder,
    },
    label: {
      title: adminLabels.apiConnectorFolder,
      class: "font-weight-bold",
      for: "folder_name",
    },
  },
]

export const api_connector_types = {
  zendesk_connector: [
    {
      type: 'text',
      key_name: 'api_endpoint',
      Label: adminLabels.apiConnectorAPIEndpoint,
      validators: [
        {
          maxLength: 80,
        },
      ]
    },
    {
      type: 'text',
      key_name: 'email_address',
      Label: adminLabels.apiConnectorEmailAddress,
      validators: [
        {
          required: true,
          maxLength: 254,
          regex: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,4}$",
        },
      ],
    },
    {
      type: 'text',
      key_name: 'token',
      Label: adminLabels.apiConnectorToken,
      validators: [
        {
          maxLength: 1024,
        },
      ],
    },

  ]
}