import { Component, OnInit, ChangeDetectorRef, Self, ViewChildren, HostListener } from '@angular/core';
import { UserService } from '../common/services/user.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { SegmentationService } from '../common/services/segmentation.service';
import { Subject } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { OrganisationService } from '../common/services/organisation.service';
import { Content, ContentService, Folder, Task_List } from '../content.service';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ADD_FOLDER_FORMDATA } from '../constants/FormData/add_folder';
import { QueryList } from '@angular/core';
import { ElementRef } from '@angular/core';
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { TasksService } from '../common/services/tasks.service';
import { ToastModalService } from '../common/services/toast-modal.service';
declare var $: any;


@Component({
    selector: 'app-manage-content',
    templateUrl: './manage-content.component.html',
    styleUrls: ['./manage-content.component.scss']
})

export class ManageContentComponent implements OnInit {
    form;
    checkForm;
    dataLoaded = false;
    visible = [];
    application_name;
    application_id;
    list_name;
    selectedOrg;
    org_id;
    task_list_id;
    add_folder_formData = JSON.parse(JSON.stringify(ADD_FOLDER_FORMDATA));
    formGroup;
    selected_folder_id;
    selected_guide_ids = [];
    task_list: Task_List;
    folder_isEdit = false;
    visible_guide_loader = false;
    search = '';
    already_searched = false;
    marUnchecked = false;
    prevCallAllGuides;
    deleteIndex = 0;
    delete_folder_id;
    refresh_checked = false;
    prev_selected_guide_ids = [];
    all_contents = [];
    is_closed = false;
    video_urls = [];
    content_ids = [];
    content_titles = [];
    breadcrumb;
    currentIndex = 0;
    showPreview = false;
    admin_data = adminLabels;
    lastSearchedValue = "";
    enforce: boolean = false;
    isChanged: boolean = false;
    task_list_title: string = '';
    changedContents = [];
    changedFolder = '';
    settings = {};
    @ViewChildren('guidepreview') previews: QueryList<ElementRef>
    modalHeader: any;
    modalContent: any;

    @HostListener('click') onClick() {
        let elem = document.getElementById("previewmodal");
        if (!elem.classList.contains("show")) {
            this.pauseVideo();
        }
    }

    constructor(public userService: UserService, private fb: FormBuilder, private cdRef: ChangeDetectorRef, private segmentationSerice: SegmentationService, private router: Router, private route: ActivatedRoute, public organisationService: OrganisationService, private errorResponseService: ErrorResponseService, private contentService: ContentService, private toastService: ToastModalService,
        private tasksService: TasksService) {
        this.form = this.fb.group({
            cont: this.fb.array([])
        });

        this.userService.getUserData();
        this.organisationService.getCDNData();
        // if(this.userService.user.role != "superadmin" && !this.userService.enableTaskList){
        //   this.router.navigate([
        //     "/organization",
        //     this.userService.organization_id
        // ]);
        // }
        this.userService.activeTab = "task list";

        this.route.queryParams.subscribe((params) => {
            if (params['list']) {
                this.list_name = decodeURIComponent(params['list']);
            }
            if (params['application']) {
                this.application_name = decodeURIComponent(params['application']);
            }



            this.route.paramMap.subscribe((params) => {
                if (params["params"]["org_id"]) {
                    this.org_id = params["params"]["org_id"];
                }

                if (params['params']['application_id']) {
                    this.application_id = parseInt(params['params']['application_id']);
                }


                this.router.events
                    .pipe(filter((event) => event instanceof NavigationEnd))
                    .subscribe(() => {
                        // this.userService.setBreadcrumbs(this.admin_data.tableTitle3, this.route.url["_value"]);
                        if (params['params']['org_id']) {
                            this.userService.setBreadcrumb([
                                {
                                    name: this.admin_data.taskList,
                                    route: ['/organization', this.org_id, 'tasks_list']
                                },
                                {
                                    name: this.admin_data.tableTitle3,
                                    route: null
                                }
                            ])
                        }
                        this.breadcrumb = this.userService.breadCrumbs;
                    });
                this.organisationService.getSignature(this.org_id).subscribe((response) => {

                    let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));
                    cdn_sign["cdn_signature"] = response["data"]["cdn_signature"];
                    cdn_sign["cdn_signature_expiry"] = response["data"]["cdn_signature_expiry"]

                    this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                    this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];
                    localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
                });
                if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin")) {
                    this.getOrganization();
                }

                if (params['params']['task_list_id']) {
                    this.task_list_id = params['params']['task_list_id'];
                }
            })
        });

        $('[data-toggle="tooltip"]').tooltip();
        let self = this;
        $(document).ready(function () {

            $('.carousel').carousel({
                interval: false
            });
            $('a[data-slide="prev"]').click(function () {
                if (!self.showPreview) {
                    return;
                }

                // $('#videoCarousel').carousel('prev');
                if (self.currentIndex > 0) {
                    self.currentIndex--;
                } else {
                    self.currentIndex = 0;
                }
                if (self.currentIndex > 0 && self.video_urls.length < self.currentIndex + 1) {
                    self.getGuide(self.content_ids[self.currentIndex]);
                } else {
                    self.showPreview = true;
                    $('#videoCarousel').click();
                }
                self.pauseVideo();
            });

            $('a[data-slide="next"]').click(function () {
                if (!self.showPreview) {
                    return;
                }
                //  $('#videoCarousel').carousel('next');

                if (self.currentIndex == (self.video_urls.length - 1) && self.video_urls.length != self.content_ids.length) {
                    self.currentIndex++
                    self.getGuide(self.content_ids[self.currentIndex]);
                } else {
                    if (self.currentIndex != self.content_ids.length - 1) {
                        self.currentIndex++;
                    } else {
                        self.currentIndex = 0;
                    }
                    self.showPreview = true;
                    $('#videoCarousel').click();
                }
                self.pauseVideo();
            });
        })
    }

    pauseVideo() {
        if (this.previews.length > 0) {
            this.previews['_results'].forEach((elem) => {
                elem.nativeElement.pause();
            })
        }
    }

    initializeForm() {
        let form = {};
        for (let i = 0; i < this.add_folder_formData.length; i++) {
            form[this.add_folder_formData[i].name] = new FormControl("");
            let validations = [];
            for (let v of this.add_folder_formData[i].validators) {
                for (let key in v) {
                    validations.push(key);
                }
            }
            let validators = [];
            for (let j = 0; j < validations.length; j++) {
                switch (validations[j]) {
                    case "required":
                        validators.push(Validators.required);
                        break;

                    case "email":
                        validators.push(Validators.email);
                        break;
                }
            }
            form[this.add_folder_formData[i].name].setValidators(validators);
        }
        this.formGroup = new FormGroup(form);
    }

    getOrganization() {
        this.organisationService.getOrganization(this.org_id).subscribe(
            (response) => {
                let organization = response["data"]["organization"];
                this.userService.organization = organization["name"];
                this.userService.organization_id = organization["organization_id"];
                this.selectedOrg = this.userService.organization_id;
                // this.userService.user.cdn_signature = organization["cdn_signature"];
                this.userService.organization = organization;
                let admin_settings = JSON.parse(
                    this.userService.organization.admin_settings
                );
                this.userService.enableSegments =
                    admin_settings["guide_segmentation"] == "1" ? true : false;
                this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
                this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
                this.userService.enableMediaThemes = admin_settings['media_files_themes'] == 0 ? false : true;

                this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
                // if(!this.userService.enableTaskList){
                //   this.router.navigate([
                //     "/organization",
                //     this.userService.organization_id
                // ]);
                // }
                this.ngOnInit();

            }
        );
    }

    getFolders() {
        this.contentService.getTasks(this.task_list_id, this.tasksService.curr_language).subscribe((response) => {
            if (response['error'] == false) {
                this.task_list = response['data']['task_list'];
                if (response['data']['task_list']['folders']) {
                    this.settings = response['data']['task_list']['setting'] === '' || response['data']['task_list']['setting'] === null ? {} : JSON.parse(response['data']['task_list']['setting']);
                    this.enforce = this.settings['force_completion'] != null && this.settings['force_completion'] == 1 ? true : false;
                    this.task_list_title = response['data']['task_list']['title'];
                    this.folders = response['data']['task_list']['folders'];
                    this.contentsWithoutFolder = [];
                    this.visible = new Array(this.folders.length);
                    this.showFolder = true;
                    setTimeout(() => {
                        this.showTooltip();
                    }, 1000)
                } else if (response['data']['task_list']['content']) {
                    this.settings = response['data']['task_list']['setting'] === '' || response['data']['task_list']['setting'] === null ? {} : JSON.parse(response['data']['task_list']['setting']);
                    this.enforce = this.settings['force_completion'] != null && this.settings['force_completion'] == 1 ? true : false;
                    this.task_list_title = response['data']['task_list']['title'];
                    this.contentsWithoutFolder = response['data']['task_list']['content'];
                    this.contentsWithoutFolder.length > 0 && this.contentsWithoutFolder.sort((a, b) => parseInt(a['content_order']) - parseInt(b['content_order']));

                    this.folders = [];
                    this.showFolder = false;
                } else {
                    this.settings = response['data']['task_list']['setting'] === '' || response['data']['task_list']['setting'] === null ? {} : JSON.parse(response['data']['task_list']['setting']);
                    this.enforce = this.settings['force_completion'] != null && this.settings['force_completion'] == 1 ? true : false;
                    this.task_list_title = response['data']['task_list']['title'];
                    this.folders = [];
                    this.contentsWithoutFolder = [];
                }
            }
        })
    }

    addFolderWithContents = false;
    addContentWithoutFolder = false;
    folder_name;
    showFolder = false;

    folder_index = 0;


    ngOnInit() {
        if (this.task_list_id && this.org_id) {
            this.initializeForm();
            this.getFolders();
        }
    }

    toggle(sel, ind, id) {
        let elem = sel + ind;
        $(elem).toggleClass("rotate");
        let el = '#loader' + ind;
        $(el).toggleClass("loaderShow");
        let collapseElem = "#course" + ind;
        this.getContents(collapseElem, ind, id);
    }

    getContents(elem, ind, id) {
        this.visible[ind] = true;
        this.contentService.getContents(id, this.tasksService.curr_language).subscribe((response) => {
            if (response['data']) {
                this.folders[ind].contents = response['data']['content_list'];
                this.folders[ind].contents.sort((a, b) => parseInt(a['content_order']) - parseInt(b['content_order']));
                this.visible[ind] = false;
            }
        })
    }

    showPreviewModal(id = -1) {
        $("#previewmodal").modal("show");
        this.removeTooltip();
        this.showPreview = false;
        this.getContentIds(id);
    }


    getContentIds(folder_id) {
        this.content_ids = [];
        this.content_titles = [];
        this.video_urls = [];
        if (folder_id != -1) {
            this.contentService.getContents(folder_id, this.tasksService.curr_language).subscribe((response) => {
                let contents = response['data']['content_list'];
                contents.forEach((content) => {
                    this.content_ids.push(content['guide_id']);
                    this.content_titles.push(content['guide_title'])
                })
                this.currentIndex = 0;
                if (this.content_ids.length > 0) {
                    this.getGuide(this.content_ids[this.currentIndex]);
                } else {
                    this.video_urls.push(this.admin_data.previewMessage);
                    this.showPreview = true;
                    $('#videoCarousel').click();
                }
            })
        } else {
            this.contentsWithoutFolder.forEach((content) => {
                this.content_ids.push(content['guide_id']);
                this.content_titles.push(content['guide_title']);
            })
            this.currentIndex = 0;
            this.getGuide(this.content_ids[this.currentIndex]);
        }
    }


    getGuide(content_id) {
        this.showPreview = false;
        if (this.currentIndex + 1 <= this.video_urls.length) {
            this.showPreview = true;
            $('#videoCarousel').click();
            return;
        }
        this.contentService.getGuide(content_id).subscribe((res) => {
            if (!res['error']) {
                if (res['data']['tour']['media_files'].length > 0) {
                    if (res['data']['tour']['media_files'][0]['video']) {
                        this.video_urls.push(res['data']['tour']['media_files'][0]['video']);
                    } else {
                        this.video_urls.push(this.admin_data.previewMessage);
                    }
                } else {
                    this.video_urls.push("Video preview does not exist");
                }
                this.showPreview = true;
                $('#videoCarousel').click();
            }
        });
    }

    // folders = [
    //   {
    //     folder_name: 'Paytm',
    //     contents: ["Content 1", "Content 2", "Content 3"]
    //   },
    //   {
    //     folder_name: 'Zomato',
    //     contents: []
    //   },
    //   {
    //     folder_name: 'Myntra',
    //     contents: ["Content 5", "Content 6", "Content 7"]
    //   }
    // ];

    folders: Folder[] = [];

    // contents = ["Content 1", "Content 2", "Content 3", "Content 4", "Content 5", "Content 6", "Content 7", "Content 8", "Content 9", "Content 10", "Content 11"];
    //contents1 = ["Content 1", "Content 2", "Content 3", "Content 4", "Content 5", "Content 6", "Content 7", "Content 8", "Content 9", "Content 10", "Content 11"];

    contents: Content[] = [];

    selectedContents = [];

    contentsWithoutFolder = [];


    editFolder(ind, id) {
        // let elem1 = "#folder" + ind;
        // let elem2 = "#folder_name" + ind;
        // $(elem1).toggleClass("hide");
        // $(elem2).removeClass("hide");
        // $(elem2).addClass("show");
        this.folder_isEdit = true;
        this.addFolderWithContents = true;
        this.selected_folder_id = id
        if (this.tasksService.curr_language.length > 0 && this.tasksService.curr_language.startsWith('en')) {
            if (this.add_folder_formData.length == 1) {
                this.add_folder_formData.push(
                    {
                        type: "checkbox",
                        name: "translation_required",
                        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
                        value: "",
                        checked: false,
                        validators: [],
                        ui: {
                            label: adminLabels.translation_required,
                            class: "mb-0",
                            inputclass: "primary",
                        },
                    },
                )
            }
        }else if(this.add_folder_formData.length > 1){
            this.add_folder_formData.pop();
        }
        this.initializeForm()
        this.formGroup.patchValue({
            'title': this.folders[ind]['title']
        });
        this.showAddModal();
    }

    saveFolderName(ind, name) {
        let elem1 = "#folder" + ind;
        let elem2 = "#folder_name" + ind;
        $(elem1).toggleClass("hide");
        $(elem2).removeClass("show");
        $(elem2).addClass("hide");
        this.folders[ind]['folder_name'] = name;
    }

    moveUpwards(ind, folders, folder?) {
        this.isChanged = true;
        if (ind > 0) {
            let elem = folders[ind - 1];
            folders.splice(ind - 1, 1, folders[ind]);
            folders[ind] = elem;
        }
        this.changedContents = folders;
        this.changedFolder = folder;
    }

    moveDownwards(ind, folders, folder?) {

        this.isChanged = true;
        if (ind < folders.length - 1) {
            let elem = folders[ind + 1];
            folders.splice(ind + 1, 1, folders[ind]);
            folders[ind] = elem;
        }

        this.changedContents = folders;
        this.changedFolder = folder;
    }

    showContentModal(ind, folder_id) {
        this.addContentWithoutFolder = false;
        this.addFolderWithContents = false;
        this.contents = [];
        this.folder_index = ind;
        this.selected_folder_id = folder_id;
        this.search = '';
        this.refresh_checked = true;
        this.getAllGuides('');
        $("#addContentModal").modal("show");

        //this.contents = JSON.parse(JSON.stringify(this.contents1));
    }


    getAllGuides(search = '') {
        console.log("called get all guides " + search);

        let self = this;
        this.visible_guide_loader = true;
        var promise = new Promise((resolve, reject) => {
            let contentService = this.contentService;
            let limit = 100;
            let offset = 0;
            let cb = contentService.getAllGuides(limit, offset, search, self.application_id);
            if (self.prevCallAllGuides) {
                clearTimeout(self.prevCallAllGuides);
            }
            let allGuides = [];
            if (self.is_closed) {
                self.selected_guide_ids = [];
            }
            if (self.refresh_checked) {
                self.prev_selected_guide_ids = Object.assign([], self.selected_guide_ids);
                self.selected_guide_ids = [];
            }
            let fetchRows = false;
            let rows;
            function guides() {
                self.prevCallAllGuides = setTimeout(() => {
                    cb.subscribe(
                        (data) => {
                            Array.prototype.push.apply(
                                allGuides,
                                data["data"]["tours"]
                            );
                            offset += 100;
                            cb = contentService.getAllGuides(limit, offset, search, self.application_id);
                            if (data["data"]["tours"].length != 0) {
                                guides();
                            } else {
                                allGuides = allGuides.filter((guide) => guide['tour_type'].includes('onboarding_tour') || guide['tour_type'].includes('walkthrough_tour'));
                                resolve(allGuides)
                            }
                        },
                        (error) => {
                        }
                    );
                }, 300)
            }

            guides();
        }).then(function (data: []) {
            self.resetFormValues(data.length);
            self.contents = data;
            if (self.refresh_checked) {
                self.all_contents = data;
            }
            if (!self.addContentWithoutFolder) {
                self.userService.show();
                self.contentService.getContents(self.selected_folder_id, self.tasksService.curr_language).subscribe((response) => {
                    if (response['error'] == false) {
                        self.folders[self.folder_index]['contents'] = response['data']['content_list'];
                        self.selectedContents = Object.assign([], self.folders[self.folder_index]['contents']);
                        let guide_ids = [];
                        if (self.refresh_checked) {
                            self.selectedContents.forEach((val) => {
                                guide_ids.push(val['guide_id']);
                            })
                            self.selected_guide_ids = guide_ids;
                        }
                        if (self.selectedContents.length > 0) {
                            self.contents.forEach((cont, ind) => {
                                if (guide_ids.indexOf(cont['tour_id']) != -1) {
                                    self.form.get("cont")['controls'][ind].patchValue({
                                        check: true
                                    })
                                }
                            })
                        }
                        self.userService.hide();
                        self.visible_guide_loader = false;
                    }
                }, error => {
                    self.userService.hide();
                    self.visible_guide_loader = false;
                })
            }
            else {
                self.userService.show();
                self.contentService.getTasks(self.task_list_id, self.tasksService.curr_language).subscribe((response) => {
                    if (response['error'] == false && response['data']['task_list']['content']) {
                        // $("#addContentModal").modal("show");
                        self.contentsWithoutFolder = response['data']['task_list']['content'];
                        self.selectedContents = Object.assign([], response['data']['task_list']['content']);
                        let guide_ids = [];
                        let initialised = false;
                        // if(!self.already_searched){
                        if (self.refresh_checked) {
                            self.selectedContents.forEach((val) => {
                                guide_ids.push(val['guide_id']);
                            })
                            self.selected_guide_ids = guide_ids;
                            // }
                        }
                        if (self.selectedContents.length > 0) {
                            self.contents.forEach((cont, ind) => {
                                if (guide_ids.indexOf(cont['tour_id']) != -1) {
                                    self.form.get("cont")['controls'][ind].patchValue({
                                        check: true
                                    })
                                }
                            })
                        }
                    } else {
                        self.visible_guide_loader = false;
                        // $("#addContentModal").modal("show");
                    }
                    self.userService.hide();
                    self.visible_guide_loader = false;
                }, error => {
                    self.userService.hide();
                    self.visible_guide_loader = false;
                })
            }
        });
    }

    closeContentModal() {
        $("#addContentModal").modal("hide");
        this.refresh_checked = true;
        this.is_closed = true;
    }

    setSelected(checked, j) {
        if (checked) {
            if (this.selected_guide_ids.indexOf(this.contents[j]['tour_id']) == -1) {
                this.selected_guide_ids.push(this.contents[j]['tour_id']);
                this.form.get("cont")['controls'][j].patchValue({
                    checked: true
                })
            }
        } else {
            if (this.selected_guide_ids.indexOf(this.contents[j]['tour_id']) != -1) {
                this.selected_guide_ids = this.selected_guide_ids.filter((guide_id) => { return guide_id != this.contents[j]['tour_id'] });
                this.prev_selected_guide_ids = Object.assign([], this.selected_guide_ids);
            }
        }
        if (this.refresh_checked) {
            let is_updated = false;
            if (this.selected_guide_ids.length > 0) {
                this.selected_guide_ids.forEach((e) => {
                    if (this.prev_selected_guide_ids.indexOf(e) == -1) {
                        is_updated = true;
                        this.prev_selected_guide_ids.push(e);
                    }
                })
            } else {
                this.prev_selected_guide_ids = [];
            }
            if (is_updated) {
                this.selected_guide_ids = Object.assign([], this.prev_selected_guide_ids);
            }
        }
    }

    addContents() {
        if (this.addFolderWithContents) {
            // this.folders.push({
            //   "folder_name": this.folder_name,
            //   "contents": this.selectedContents
            // })
            this.showFolder = true;
        }
        this.closeContentModal();
        if (!this.addFolderWithContents && !this.addContentWithoutFolder) {
            //this.folders[this.folder_index]['contents'] = this.selectedContents;
            let request = {
                "folder_id": this.selected_folder_id,
                "task_list_id": this.task_list_id,
                "guide_ids": JSON.stringify(this.selected_guide_ids)
            }
            this.contentService.updateContents(request).subscribe((response) => {
                if (response['error'] == false) {
                    this.getFolders();
                    this.closeContentModal();
                } else {
                    this.userService.errorMessage = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    $("#errorModal").modal("show");
                }
            }, error => {
                this.closeContentModal();
            })
        }

        if (this.addContentWithoutFolder) {
            let request = {
                "task_list_id": this.task_list_id,
                "guide_ids": JSON.stringify(this.selected_guide_ids)
            }
            this.contentService.updateContents(request).subscribe((response) => {
                if (response['error'] == false) {
                    this.getFolders();
                    this.closeContentModal();
                }
            })
        }
    }

    addFolder() {
        this.folder_name = "";
        this.addFolderWithContents = true;
        this.addContentWithoutFolder = true;
        this.showFolder = true;
        this.add_folder_formData = JSON.parse(JSON.stringify(ADD_FOLDER_FORMDATA));
        this.formGroup.patchValue({
            "title": ''
        })
        this.resetFormValues(this.contents.length);
        this.showAddModal();
        this.folder_isEdit = false;
        this.selectedContents = [];
        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();
        this.formGroup.get("title").valueChanges.subscribe(x => {
            if (x && !this.userService.pattern.test(x)) {
                this.formGroup.get("title").setValue(x.replace(this.userService.replacePattern, ""), { emitEvent: false });
            }
        });
    }

    saveFolder() {
        let request = {
            "task_list_id": this.task_list_id,
            "title": this.formGroup.get("title").value.trim()
        }
        this.contentService.save_button.disable = true;
        this.contentService.save_button.text = this.admin_data.savingButtonText;
        this.contentService.saveFolder(request).subscribe((response) => {
            if (response['error'] == false) {
                this.contentService.save_button.disable = false;
                this.contentService.save_button.text = this.admin_data.saveButtonText;
                this.closeContentModal();
                this.getFolders();
            } else {
                this.contentService.save_button.disable = false;
                this.contentService.save_button.text = this.admin_data.saveButtonText;
                this.closeContentModal();
                this.userService.showModal.next(false);
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            }
        }, error => {
            this.contentService.save_button.disable = false;
            this.contentService.save_button.text = this.admin_data.saveButtonText;
            this.closeContentModal();
        })
    }

    updateFolder(title) {
        if (this.tasksService.curr_language.length == 0 || this.tasksService.curr_language.startsWith('en')) {
        let request = {
            "id": this.selected_folder_id,
            title: title
        }
        if(this.tasksService.curr_language.length> 0 && this.tasksService.curr_language.startsWith('en')){
            request['translation_required'] = parseInt(this.formGroup.get("translation_required").value) === 1 ? 1 : 0;
        }
        this.contentService.update_button.disable = true;
        this.contentService.update_button.text = this.admin_data.updatingButtonText;
        this.contentService.editFolder(request).subscribe((response) => {
            if (response['error'] == false) {
                this.getFolders();
                this.contentService.update_button.disable = false;
                this.contentService.update_button.text = this.admin_data.updateButtonText;
                this.closeContentModal();
                // this.saveFolderName(ind, title);
            } else {
                this.contentService.update_button.disable = false;
                this.contentService.update_button.text = this.admin_data.updateButtonText;
                this.closeContentModal();
                this.userService.showModal.next(false);
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            }
            this.folder_isEdit = false;
        }, error => {
            this.contentService.update_button.disable = false;
            this.contentService.update_button.text = this.admin_data.updateButtonText;
            this.closeContentModal();
        })
    } else if (this.tasksService.curr_language.length != 0) {
        let langUpdateObj = {
            id: this.selected_folder_id,
            title: title,
            language: this.tasksService.curr_language
        }
        this.contentService.update_button.disable = true;
        this.contentService.update_button.text = this.admin_data.updatingButtonText;
        this.contentService.updateFolderWithLanguageUpdate(langUpdateObj).subscribe((response) => {
            if (response['error'] == false) {
                this.getFolders();
                this.contentService.update_button.disable = false;
                this.contentService.update_button.text = this.admin_data.updateButtonText;
                this.closeContentModal();
                // this.saveFolderName(ind, title);
            } else {
                this.contentService.update_button.disable = false;
                this.contentService.update_button.text = this.admin_data.updateButtonText;
                this.closeContentModal();
                this.userService.showModal.next(false);
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            }
            this.folder_isEdit = false;
        }, error => {
            this.contentService.update_button.disable = false;
            this.contentService.update_button.text = this.admin_data.updateButtonText;
            this.closeContentModal();
        })
    }
    }

    resetFormValues(length) {
        this.form = this.fb.group({
            cont: this.fb.array([])
        });
        for (let i = 0; i < length; i++) {
            let control = this.fb.group({
                check: [false]
            });
            this.form.get("cont").push(control);
            this.form.get("cont")['controls'][i].patchValue({
                check: false
            })
        }
    }
    showAddModal() {
        this.contents = [];
        // this.contents = JSON.parse(JSON.stringify(this.contents1));
        $("#addContentModal").modal("show");
        this.already_searched = false;
    }

    addContentsWithoutFolder() {
        this.addFolderWithContents = false;
        this.addContentWithoutFolder = true;
        this.refresh_checked = true;
        this.search = '';
        this.getAllGuides('');
        $("#addContentModal").modal("show");
        // this.showAddModal();
    }

    deleteFolder(id) {
        let request = {
            "id": this.delete_folder_id
        }
        this.contentService.deleteFolder(request).subscribe((response) => {
            this.organisationService.showConfirmationModal.next(false);
            if (response['error'] == false) {
                this.deleteIndex = 0;
                this.getFolders();
            } else {
                if (response['code'] == '4017')
                    $("#deleteWarningmodal").modal("show");
            }
        })
    }

    processChecked(content) {
        if (this.showFolder) {
            if (!this.addFolderWithContents && this.folders[this.folder_index]['contents'].indexOf(content) != -1) {
                return true;
            } else {
                return false;
            }
        }
        else {
            if (this.contentsWithoutFolder.indexOf(content) != -1) {
                return true;
            } else {
                return false;
            }
        }
        // !addContentWithoutFolder && folders[folder_index]['contents'].indexOf(content)!=-1
    }

    ngAfterViewInit() {
        this.cdRef.detectChanges();
    }

    showTooltip() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    removeTooltip() {
        $('.tooltip').remove();
    }

    searchGuide() {
        if (this.search.length >= 1 || this.search.length == 0) {
            this.already_searched = true;
            this.refresh_checked = false;
            this.is_closed = false;
            this.getAllGuides(this.search);
        }
    }

    selectAllGuides(checked) {
        if (checked) {
            let current_select = this.contents.map((elem) => { return elem['tour_id'] });
            if (current_select.length > 0) {
                current_select.forEach((elem) => {
                    if (this.selected_guide_ids.indexOf(elem) == -1) {
                        this.selected_guide_ids.push(elem);
                    }
                })
            }
            this.marUnchecked = false;
        } else {
            let current_guide_ids = this.contents.map((elem) => { return elem['tour_id'] });
            this.selected_guide_ids = this.selected_guide_ids.filter((elem) => {
                if (current_guide_ids.indexOf(elem) == -1) {
                    return elem
                }
            });
            this.marUnchecked = true;
        }
    }

    showModal(folder_id) {
        this.userService.showModal.next(true);
        this.folders.forEach((elem, i) => {
            if (elem.id == folder_id) {
                this.deleteIndex = i;
                this.delete_folder_id = elem.id;
            }
        });
        this.modalHeader = this.admin_data.deleteFolder;
        let tasklist = this.folders[this.deleteIndex]['title']
        let str = this.admin_data.deleteFolderContent
        str = str.split(' ')
        str.splice(str.length - 1, 0, "'" + tasklist + "'")
        this.modalContent = str.join(' ');
        this.organisationService.showConfirmationModal.next(true);
    }

    checkActive() {
        if (document.activeElement == document.getElementById("searchGuide")) {
            return true;
        } else {
            return false;
        }
    }

    getVideoURL(videoPath) {
        if (videoPath) {
            let str = videoPath;
            str += this.organisationService.cdn_signature;
            return str;
        }
    }

    validateInput(event: Event) {
        this.userService.validateInput(event);
        if ((event.target as HTMLInputElement).value != this.lastSearchedValue) {
            this.searchGuide();
            this.lastSearchedValue = (event.target as HTMLInputElement).value;
        }
    }

    toggleEnforce(checked) {
        this.enforce = !this.enforce;
        this.settings['force_completion'] = this.enforce ? 1 : 0;
        let request = {
            id: parseInt(this.task_list_id),
            "setting": JSON.stringify(this.settings),
            "title": this.task_list_title
        };

        this.tasksService.editTaskList(request).subscribe((response) => {
            if (response["code"] == 1000) {
            }
        }
        );
    }

    updateSequence() {
        let content_json = [];
        this.changedContents.forEach((content, i) => {
            content_json.push({
                "guide_id": content['guide_id'],
                "order": (i + 1).toString()
            })
        })
        let request = {
            "task_list_id": this.task_list_id,
            "content_json": JSON.stringify(content_json)
        }
        if (this.changedFolder !== '' && this.changedFolder !== undefined) {
            request['folder_id'] = this.changedFolder
        }

        this.contentService.updateOrder(request).subscribe((response) => {
            this.toastService.showToast = true;
            this.toastService.toastType = "guide_sequence_updated";
            this.toastService.showToastMessage(this.toastService.toastType);
            this.reset();
        },
            err => {
                console.error(err);
            })

    }

    reset() {
        if (this.changedFolder != '' && this.changedFolder !== undefined) {
            let ind = this.folders.findIndex((el) => el.id == this.changedFolder);
            let collapseElem = "#course" + ind
            $(collapseElem).collapse()
            this.getContents(collapseElem, ind, this.changedFolder);
        } else {
            this.getFolders();
        }
        this.isChanged = false;
        this.changedContents = [];
        this.changedFolder = "";
    }

}
