<div class="width100">
    <div [ngClass]="className" [ngStyle]="getStyle(dropdownPosition)" class="main-drop-div">
        <div>
            <button class="drop-toggle btn flat" (click)="openCloseDropdown();"
                [disabled]="isDisabled">
                <span>{{getSelectedLabel()}}</span>
                <img *ngIf="!showDropDown" src="./../../assets/svgs/icon-down-arrow.svg" />
                <img *ngIf="showDropDown" src="./../../assets/svgs/icon-up-arrow.svg" />
            </button>
        </div>
        <div class="drop-show" [ngClass]="[className2, position=='up'?'drop-show-up':'drop-show-down']" *ngIf="showDropDown">
            <div *ngIf="dataList.length>0; then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
                <div class="drop-list-row disabled" *ngIf="preSelectOption" [ngClass]="selected == '' ? 'active' : ''">
                    <div class="labelText">{{this.admin_data.pleaseSelect}}</div>
                </div>
                <ng-container *ngFor="let obj of dataList; index as i;">
                    <div class="drop-list-row" [ngClass]="{'active': selected == getValue(obj), 'disabled': getDisabledSubItem(obj)}" (click)="onSelect(getValue(obj))">
                        <div class="labelText" [ngStyle]="obj.styles">{{getLabel(obj)}}</div>
                    </div>
                </ng-container>
            </ng-template>
            <ng-template #elseBlock>
                {{this.admin_data.noDataFound}}
            </ng-template>
        </div>
    </div>
</div>