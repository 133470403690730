import { HttpClientModule} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { API_END_POINTS } from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { ApiService } from './api.service';
import { UserService } from './user.service';

export interface Task{
  type:string,
  category_id: any,
category_title: any,
creation_date: string,
description: any,
modification_date: string,
progress: string,
status: string,
task_data: string,
task_error: string,
task_id: string,
task_type: string,
task_url: string,
tour_id: any,
tour_title: any,
user_id: string,
export_guide_action:any
}

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(private userService: UserService, private http: HttpClientModule,private apiService:ApiService) { }

  guide_data_button={
    type:'guide_data',
    class:'btn btn-primary minWidth-160 mx-2',
    disable:false,
    text: adminLabels.exportDataExportGuideButtonText,
    tooltipTemplate:`<div class="tooltip tooltip-width" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>`,
    tooltip:`<div class="text-left w-100">` + adminLabels.exportmessage1 +`</div>`
  }

  user_data_button = {
    type: 'user_data',
    class: 'btn btn-primary minWidth-120 mx-2',
    disable: false,
    text: adminLabels.exportDataExportUserButtonText,
    tooltipTemplate: `<div class="tooltip tooltip-width" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>`,
    tooltip: `<div class="text-left w-100">` + adminLabels.exportmessage2  + `</div>`
  }

  appTitles = [adminLabels.exportDataTableHeaderExportType, adminLabels.exportDataTableHeaderExportedBy, adminLabels.tableHeaderProgress, adminLabels.tableHeaderStatus, adminLabels.tableHeaderCreationDate, adminLabels.tableHeaderActions];

  appDataKeys = ['','type', 'user_email','progress', 'status', 'creation_date', 'export_guide_action'];

  col_widths = ['15%','20%','20%','10%','15%','20%'];

  exportGuideList(request){
    let urlPath = environment.baseUrl +API_END_POINTS.EXPORT_GUIDE_REPORT;
    return this.apiService.post(urlPath,request);
  }

  getGuideExportTaskStatus(org_id){
    let urlPath =
      environment.baseUrl + API_END_POINTS.GET_ORGANIZATION_TASK_STATUS+
      "?limit=10"+
      "&offset=0" +
      "&organization_id=" +
      parseInt(org_id)+"&task_type=tours_list_export";
      return this.apiService.get(urlPath);
  }

  getUserExportData(org_id) {
    let urlPath =
      environment.baseUrl + API_END_POINTS.GET_ORGANIZATION_TASK_STATUS +
      "?limit=10" +
      "&offset=0" +
      "&organization_id=" +
      parseInt(org_id) + "&task_type=user_export";
    return this.apiService.get(urlPath);
  }

  exportUserList(request) {
    let urlPath = environment.baseUrl + API_END_POINTS.EXPORT_USER_DATA;
    return this.apiService.post(urlPath, request);
  }

}
