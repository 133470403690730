<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>

        <section class="component-section">
            <div class="sectionBody">
                <div class="d-flex mb-3">
					<div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
						{{this.admin_data.userAttributes}}
					</div>
					<div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right">
						<button type="button" class="btn btn-primary ml-2"
                            (click)="showAddSegmentModal()">{{this.admin_data.addUserAttributesButtonText}}</button>
                        <button type="button" class="btn btn-primary ml-2" (click)="navigateToImport()">{{this.admin_data.import}}</button>
					</div>
				</div>

                <app-table [data]="segmentations" [titles]="segmentation.appTitles"
                    [dataKeys]="segmentation.appDataKeys" [widths]="segmentation.col_widths"
                    (delete)="showDeleteModal($event)" (roles)="showSegmentList($event)"
                    (edit)="getSegmentData($event)"></app-table>
                <div class="modal fade" id="addSegmentModal" tabindex="-1" role="dialog"
                    aria-labelledby="addSegmentLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="addRoleModalLabel">
                                    <ng-container *ngIf="segmentation.isEdit">
                                        {{this.admin_data.updateUserAttributesButtonText}}</ng-container>
                                    <ng-container *ngIf="!segmentation.isEdit">
                                        {{this.admin_data.addUserAttributesButtonText}}</ng-container>
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                    (click)="closeAddSegmentModal()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <app-form [formData]="segment_formData" [formGroup]="formGroup"></app-form>
                            </div>
                            <div class="modal-footer justify-content-center">
                                <!-- <button type="button" class="btn btn-secondary minWidth-120" data-dismiss="modal"
                                    (click)="closeAddSegmentModal()">{{this.admin_data.cancelButtonText}}</button> -->
                                <app-button [buttonData]="segmentation.cancel_button"
                                    (clickhandler)="closeAddSegmentModal()"></app-button>
                                <!-- <button type="button" class="btn btn-primary"
                                    (click)="segmentation.isEdit ? editSegmentation() : addSegmentation()" [disabled]="!formGroup.valid">
                                    <ng-container *ngIf="segmentation.isEdit">Update Segment</ng-container>
                                    <ng-container *ngIf="!segmentation.isEdit">Add Segment</ng-container>
                                </button> -->
                                <app-button *ngIf="segmentation.isEdit" [disabled]="!formGroup.valid"
                                    [buttonData]="segmentation.update_button" (clickhandler)="editSegmentation($event)">
                                </app-button>
                                <app-button *ngIf="!segmentation.isEdit" [disabled]="!formGroup.valid"
                                    [buttonData]="segmentation.add_button" (clickhandler)="addSegmentation($event)">
                                </app-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <ng-container *ngIf="segmentations">
            <ng-container *ngIf="segmentations.length>0">
                <app-modal-confirmation [header_value]="modalHeader" [content_value]="modalContent"
                [modal_ok_value]="this.userService.delete_button.text" [org]=""
                [disabled]="this.userService.delete_button.disable"
                (onDelete)="deleteSegmentation($event)"></app-modal-confirmation>
            </ng-container>
        </ng-container>
    </div>
</div>