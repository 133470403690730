<nav aria-label="breadcrumb">
    <ol class="breadcrumb align-items-center breadcrumb-div">
        <!-- <svg-icon class="cursor pointer outline-0" [routerLink]="
        userService.organization_id != '000'
          ? ['/organization', userService.organization_id]
          : ''" [class.no-cursor]="hideCursor(userService.organization_id != '000'
          ? ['/organization', userService.organization_id]
          : '')" src="../../../assets/svgs/breadcrumb_home.svg"></svg-icon> -->
        <span class="breadcrumb-span">You are here: </span>
        <li class="pt-1 pb-1 breadcrumb-item" [class.active]="i == breadcrumb.length - 1"
            *ngFor="let bread of breadcrumb;let i = index">
            <a [class.no-cursor]="bread.route==null" [routerLink]="bread.route">{{ bread.name }}</a>
        </li>
        <!-- <li *ngIf="templateVar=='customization'" class="template-container">
            <app-multi-checkbox-dropdown *ngIf="appList.length>0" [appList]="appList" (updateApplist)="emitUpdateApplist($event)"></app-multi-checkbox-dropdown>
            <button class="btn btn-discard" [disabled]="buttonDisable=='true'"
                (click)="emitDiscardSettings($event)">{{this.admin_data.discardBtnTxt}}</button>
            <button class="btn btn-save" [disabled]="buttonDisable=='true'"
                (click)="emitSaveSettings($event)">{{this.admin_data.saveButtonText}}</button>
        </li> -->
        <!-- <li *ngIf="templateVar=='findReplace'" class="template-container"> -->
            <!-- <button class="btn btn-discard" [hidden]="buttonHidden==true" [disabled]="buttonDisable"
                (click)="emitCancel()">{{this.admin_data.cancelButtonText}}</button> -->
            <!-- <button class="btn btn-save" (click)="emitRefresh()" [disabled]="buttonDisable">{{this.admin_data.refresh}}</button> -->
        <!-- </li> -->
    </ol>
</nav>