import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../common/services/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from '../common/services/api.service';
import { RoleService } from '../common/services/role.service';
import { Title } from '@angular/platform-browser';
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { ManageKeywordsService } from '../common/services/manage-keywords.service';
import { CustomizationCentreService } from '../common/services/customization.service';
import { ErrorResponseService } from '../common/services/error-response.service';
import { BehaviorSubject, Observable, Subject, Subscription, forkJoin, interval } from 'rxjs';
import { OrganisationService } from '../common/services/organisation.service';
import { PaginationService } from '../pagination.service';
declare var $: any;
declare var bootstrap: any;
@Component({
    selector: 'app-manage-keywords',
    templateUrl: './manage-keywords.component.html',
    styleUrls: ['./manage-keywords.component.scss']
})
export class ManageKeywordsComponent implements OnInit {

    @ViewChild("status") statusChild;
    @ViewChild("folder") folderChild;
    @ViewChild("userFilter") userFilterChild;
    @ViewChild("app") appChild;


    breadcrumb;
    admin_data = adminLabels;
    showFilterMenu = false;
    loader = false;
    curr_org_id: any;
    limit = 10;
    offset = 0;
    tours = [];
    apps = [];
    keyword = '';
    users = [];
    folders = [];
    curr_folder = '';
    curr_app_id = "";
    curr_user = "";
    curr_status = "";
    search_text = '';
    curr_tour;
    curr_keywords = [];
    curr_tour_ids = new Set([]);
    taskSubscription: Subscription;
    destroy: Subject<boolean> = new Subject<boolean>();
    blankString = "";
    published = [this.admin_data.unpublished, this.admin_data.published]
    customSelectObj = [
        { guide_state: this.admin_data.all, guide_value: "" },
        { guide_state: this.admin_data.published, guide_value: "1" },
        { guide_state: this.admin_data.unpublished, guide_value: "0" }
    ]
    tableHeading = [this.admin_data.guideName, this.admin_data.apiConnectorFolder, this.admin_data.usersVariable8, this.admin_data.keywords, this.admin_data.tableHeaderActions];
    cdn_signature: any;
    disableUpdateTag: Boolean = false;
    private gotoPageSubject = new BehaviorSubject<number>(1);
    destroyed = new Subject();
    constructor(
        public userService: UserService,
        private router: Router,
        private manageKeywordService: ManageKeywordsService,
        private route: ActivatedRoute,
        private customizationService: CustomizationCentreService,
        private titleService: Title,
        private errorResponseService: ErrorResponseService,
        private organisationService: OrganisationService,
        public paginationService: PaginationService,) {
        this.userService.getUserData();
        this.paginationService.initializeValues();
        this.titleService.setTitle(
            this.admin_data.manageKeywords + " | Cornerstone Guide"
        );
        this.userService.activeTab = 'manage_keywords';
        //console.log("In manage Keywords");
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.userService.setBreadcrumb([
                    {
                        name: this.admin_data.manageKeywords,
                        route: null,
                    },
                ]);
                this.breadcrumb = this.userService.breadCrumbs;
            });
        this.route.paramMap.subscribe((params) => {
            if (params['params']['org_id']) {
                if (params['params']['org_id'] !== undefined && params['params']['org_id'] !== '000') {
                    this.curr_org_id = params['params']['org_id'];
                    //console.log(this.userService);
                    this.userService.organization_id = this.curr_org_id;

                    this.organisationService.getSignature(this.curr_org_id).subscribe((response) => {
                        this.cdn_signature = response["data"]["cdn_signature"];
                    });
                    this.getApps()
                    this.getUsers()
                }
            }
        });
    }

    ngAfterViewInit() {
        if (this.appChild) {
            this.appChild.value = this.curr_app_id;
        }
        if (this.statusChild) {
            this.statusChild.value = this.curr_status;
        }
        if (this.folderChild) {
            this.folderChild.value = this.curr_folder;
        }
        if (this.userFilterChild) {
            this.userFilterChild.value = this.curr_user;
        }
    }

    ngOnInit() {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        })
        this.gotoPageSubject.pipe(
            takeUntil(this.destroyed),
            debounceTime(800))
            .subscribe((page: number) => {
                if (page != this.paginationService.currentPage) {
                    let newOffset = this.paginationService.gotoPage(page, this.limit, this.offset)
                    if (newOffset >= 0) {
                        this.offset = newOffset
                        if (this.offset >= 0) this.getAllGuides(this.curr_app_id);
                    }
                }
            })

        //this.userService.visible.next(true)
    }

    ngOnDestroy() {
        //this.userService.visible.next(true)
    }

    getTaskList() {

        this.manageKeywordService.getGuideTasks(this.curr_org_id)
            .subscribe((response) => {
                let index = response['data']['tasks'].findIndex(ele => ele['status'] !== 'PENDING')
                if (!index) {
                    this.taskSubscription.unsubscribe();
                    this.getAllGuides(this.curr_app_id)
                }

            })


    }

    isTooltip = (tour) => {
        if (tour && tour.tour_type && tour.tour_type.indexOf('smartTip') !== -1) return true;
        else return false;
    };

    isHelpArticle = (tour) => {
        if (tour && tour.tour_type && tour.tour_type.indexOf('help_article') !== -1) return true;
        else return false;
    };

    isTutorial = (tour) => {
        if (tour && tour.tour_type && tour.tour_type.indexOf('onboarding_tour') !== -1) return true;
        else return false;
    };

    isInsights = (tour) => {
        if (tour && tour.tour_type.indexOf('insights') >= 0) return true;
        else return false;
    };

    getIcon = (tour) => {
        let img = '';
        if (tour.image_url && tour.image_url.indexOf('default_icon') == -1) {
            return tour.image_url + this.cdn_signature;
        } else if (this.isHelpArticle(tour)) {
            img = "help-article";
        } else if (this.isInsights(tour)) {
            img = "guide-insights";
        } else if (this.isTutorial(tour)) {
            img = "guide-tutorial";
        } else if (this.isTooltip(tour)) {
            img = "guide-tooltip";
        } else {
            img = "icon-guide";
        }
        return "./../../assets/svgs/" + (tour.is_published != '0' ? img + "-published" : img) + ".svg";
    }

    getAllGuides(app_id) {
        this.loader = false;
        this.userService.hide(); //turn off default loader for this request
        this.destroy.next(true);
        this.destroy.next(false);
        this.tours = [];
        this.manageKeywordService.getAllGuides(this.limit, this.offset, this.curr_org_id, app_id, this.curr_user, this.curr_folder, this.curr_status, this.search_text)
            .pipe(takeUntil(this.destroy))
            .subscribe((response) => {
                let count = 0;
                if (response['data']['total_count']) {
                    count = response['data']['total_count']
                }
                if (response['data']['total_rows']) {
                    count = response['data']['total_rows']
                }
                this.paginationService.calculatePageLimit(count, this.limit);
                this.curr_tour_ids.clear()
                this.tours = response['data']['tours'];
                this.tours.forEach((tour) => {
                    if (tour['keywords'] !== null && tour['keywords'] !== "") {
                        tour['keywords'] = tour['keywords'].split(',')
                    }
                })
                this.paginationService.setPaginationStatus(this.tours, this.limit);
                this.loader = true
            })
    }

    getApps(app_id?) {
        this.customizationService.getApplications(1000, 0, this.curr_org_id).subscribe((response => {
            this.apps = []
            response['data']['applications'].forEach(element => {
                let app = {
                    application_id: element['application_id'],
                    name: element['title']
                }
                this.apps.push(app);
            });
            if (this.curr_app_id.trim() == "" || this.curr_app_id == undefined) {
                this.curr_app_id = this.apps[0]['application_id'];

            }
            this.getAllGuides(this.curr_app_id)
            this.getFolders(this.curr_app_id)
        }))
    }

    getFolders(app_id) {
        this.manageKeywordService.getFolders(app_id).subscribe((response) => {
            ////console.log(response);
            this.folders = []
            response['data']['categories'].forEach(element => {
                let folder = {
                    category_id: element['category_id'],
                    name: element['category_title']
                }
                this.folders.push(folder);
            })
            this.folders.unshift({ "category_id": '', "name": this.admin_data.usersVariable3 })
        })
    }

    getUsers() {
        this.users = []
        this.manageKeywordService.getUsers(this.curr_org_id).subscribe((response) => {
            this.users.push({ user_id: '', name: this.admin_data.usersVariable3 })
            response['data'] && response['data']['users'] && response['data']['users'].forEach(element => {
                let user = {
                    user_id: element['user_id'],
                    name: element['first_name'] + " " + element['last_name']
                }
                this.users.push(user);
            })
        })
    }

    openModal(index) {
        this.curr_tour = (this.tours[index])
        this.curr_tour_ids.clear();
        this.curr_keywords = this.tours[index]['keywords']
        this.disableUpdateTag = true;
        this.blankString = ""
        $("#assignKeywords").modal("show");

    }

    openModalForBulkupdate() {
        this.disableUpdateTag = true;
        this.curr_keywords = [];
        this.curr_tour = undefined;
        this.blankString = ""
        $("#assignKeywords").modal("show");
    }

    closeModal() {
        $("#assignKeywords").modal("hide");
    }
    doNothing($event, i) {
        //console.log($event);
        //console.log(this.tours[i]);

        // this.loader=true;
    }

    onCheckboxClick(checked, i) {
        if (i == -1) {
            if (checked) {
                this.tours.forEach((element) => {
                    this.curr_tour_ids.add(element['tour_id'])
                })
            }
            else {
                (document.getElementById('allCheck') as HTMLInputElement).checked = false;
                this.curr_tour_ids.clear()
            }
        }
        else {
            if (checked) {
                this.curr_tour_ids.add(this.tours[i]['tour_id'])
            } else {
                this.curr_tour_ids.delete(this.tours[i]['tour_id'])
            }
        }
        //console.log(this.curr_tour_ids);


    }

    getCheckedStatus(i) {
        return this.curr_tour_ids.has(this.tours[i]['tour_id'])
    }

    keyPressed($event) {
        //console.log($event);
        //console.log(this.curr_keywords);


    }

    applyFilter(app_id, status, folder_id, user_id) {
        app_id = app_id ? app_id : this.curr_app_id;
        this.curr_app_id = app_id;
        this.curr_status = status;
        this.curr_folder = folder_id;
        this.curr_user = user_id;
        // this.limit = 10;
        this.offset = 0;
        this.getAllGuides(app_id);
        this.close()
    }

    search() {
        this.curr_status = "";
        this.curr_folder = "";
        this.curr_user = "";
        // this.limit = 10;
        this.offset = 0;
        this.getAllGuides(this.curr_app_id)
    }

    appChanged(app_id) {
        this.curr_app_id = app_id;
        this.curr_status = "";
        this.curr_folder = "";
        this.curr_user = "";
        this.search_text = "";
        this.paginationService.initializeValues();
        // this.limit = 10;
        this.offset = 0;
        this.getAllGuides(app_id)
        this.getFolders(app_id)
    }

    close() {
        this.showFilterMenu = false;
        // if (document.getElementById("filter-dropdown-menu") && document.getElementById("filter-dropdown-menu").classList.contains("show")) {
        //     document.getElementById("filter-dropdown-menu").classList.remove("show");
        //     document.getElementById("filter").classList.remove("show");
        // }
    }

    clear(){
        if(this.curr_status !== '' || this.curr_folder !== '' || this.userFilterChild !== '' ){
            this.statusChild.value = '';
            this.userFilterChild.value = '';
            this.folderChild.value = '';
            this.applyFilter(this.curr_app_id, '','','');       
        }
    }


    addKeywords() {
        let reqAddArray;
        let reqDeleteArray;
        let self = this;
        let tour_ids;
        let addApiCall;
        let deleteApiCall;
        if (this.curr_tour && this.curr_tour['keywords'] != null) {
            let temp = this.curr_tour['keywords']
            reqAddArray = this.curr_keywords.filter(function (element) {
                return temp.indexOf(element) === -1;
            })
            if (temp) {
                reqDeleteArray = temp.filter(function (element) {
                    return self.curr_keywords.indexOf(element) === -1;
                })
            }
            //console.log(this.curr_keywords, "currkeyw");
            //console.log(reqDeleteArray, "reqDeleteArray");
        } else {
            reqAddArray = this.curr_keywords;
            //console.log(reqAddArray, "reqAddArray");
        }

        /*Below if else block is for bulk addition of keywords */
        if (this.curr_tour != undefined) {
            tour_ids = [this.curr_tour['tour_id']];
        } else {
            tour_ids = Array.from(this.curr_tour_ids)
        }
        //console.log(tour_ids, "tour_ids");
        if (reqAddArray.length > 0) {
            let addRequest = {
                keywords: reqAddArray.toString(),
                organization_id: this.curr_org_id,
                tour_ids: JSON.stringify(tour_ids)
            }
            //console.log(addRequest);
            addApiCall = this.manageKeywordService.addKeywords(addRequest);
        }
        if (reqDeleteArray != undefined && reqDeleteArray.length > 0) {
            let deleteRequest = {
                keywords: reqDeleteArray.toString(),
                organization_id: this.curr_org_id,
                tour_id: tour_ids[0]
            }
            //console.log(deleteRequest);
            deleteApiCall = this.manageKeywordService.deleteKeywords(deleteRequest);

        }

        if (reqDeleteArray != undefined && reqDeleteArray.length > 0 && reqAddArray.length > 0) {
            forkJoin(addApiCall, deleteApiCall).subscribe((response) => {
                if (response[0]['error'] == false && response[1]['error'] == false) {
                    this.getAllGuides(this.curr_app_id)
                    this.curr_tour = [];
                    this.curr_keywords = [];
                    this.curr_tour_ids.clear()
                } else {
                    let errorResponse;
                    if (response[0]['error'] == true) {
                        errorResponse = this.errorResponseService.getErrorResponseMessage(response[0]['message'][0]);
                    }
                    else {
                        errorResponse = this.errorResponseService.getErrorResponseMessage(response[1]['message'][0]);
                    }
                    this.userService.errorMessage = errorResponse;
                    $("#errorModal").modal("show");
                }

            })

        } else if (reqDeleteArray != undefined && reqDeleteArray.length > 0) {
            deleteApiCall.subscribe((response) => {
                if (response['error'] == false) {
                    //console.log(response);
                    this.getAllGuides(this.curr_app_id)
                    this.curr_tour = [];
                    this.curr_keywords = [];
                    this.curr_tour_ids.clear()
                } else {
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    this.userService.errorMessage = errorResponse;
                    $("#errorModal").modal("show");
                }

            })
        } else if (reqAddArray.length > 0) {
            addApiCall.subscribe((response) => {
                if (response['error'] == false) {
                    //console.log(response);
                    this.getAllGuides(this.curr_app_id)
                    this.curr_tour = [];
                    this.curr_keywords = [];
                    if (this.curr_tour_ids.size > 1) {
                        this.taskSubscription && this.taskSubscription.unsubscribe()
                        this.taskSubscription = interval(30000).subscribe((x => {
                            this.getTaskList();
                        }));
                    }
                    this.curr_tour_ids.clear()
                } else {
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    this.userService.errorMessage = errorResponse;
                    $("#errorModal").modal("show");
                }

            })

        }

        this.closeModal();

    }

    deleteTag(tour_id, keyword) {
        let deleteRequest = {
            keywords: keyword,
            organization_id: this.curr_org_id,
            tour_id: tour_id
        }

        this.manageKeywordService.deleteKeywords(deleteRequest).subscribe((response) => {
            if (response['error'] == false) {
                //console.log(response);
                this.getAllGuides(this.curr_app_id)
                this.curr_tour = [];
                this.curr_keywords = [];
                this.curr_tour_ids.clear()
            } else {
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            }

        })

    }

    updateDisabledProp(prop) {
        this.disableUpdateTag = prop;
    }

    getCreatorName(creator_id) {
        return this.users.find(element => element['user_id'] == creator_id) ? this.users.find(element => element['user_id'] == creator_id)['name'] : ""
    }

    appendOffset() {
        let offset = this.paginationService.appendOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getAllGuides(this.curr_app_id);
        }
    }

    prependOffset() {
        let offset = this.paginationService.prependOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getAllGuides(this.curr_app_id);
        }

    }

    setIndex(ind) {
        let offset = this.paginationService.setIndex(ind, this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getAllGuides(this.curr_app_id);
        }
    }

    goToAction(event) {
        this.gotoPageSubject.next(Number(event.target.value));
    }

    gotoFirstPage() {
        this.setIndex(1);
    }

    gotoLastPage(event) {
        this.setIndex(event);
    }

    changeLimit(event) {
        this.limit = parseInt(event);
        this.offset = 0;
        this.getAllGuides(this.curr_app_id);
        this.paginationService.initializeValues();
    }

    gotoPage(page) {
        this.offset = this.paginationService.gotoPage(page, this.limit, this.offset)
        if (this.offset >= 0) this.getAllGuides(this.curr_app_id);
    }
}
