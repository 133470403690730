import { observable } from 'rxjs';

export interface firstPage {
  logo_id: number,
  logo_url: any,
  footer_logo_id: number,
  footer_logo_url: any
}

export interface middlePage {
  footer_logo_id: number,
  footer_logo_url: any
}

export interface lastPage {
  title: string,
  lp_subtitle_1: any,
  lp_subtitle_2: any,
  logo_id: number,
  logo_url: any,
  footer_logo_id: number,
  footer_logo_url: any
}


export class Theme {
  public theme_id: string;
  public theme_name: string;
  public theme_image: string;
  public theme_color: string;
  public domain_name: string;
  public first_page: firstPage;
  public middle_page: middlePage;
  public last_page: lastPage;

  constructor(
    theme_id: string,
    theme_name: string,
    theme_image: any,
    theme_color: string,
    domain_name: string,
    first_page: firstPage,
    middle_page: middlePage,
    last_page: lastPage
  ) {
    this.theme_id = theme_id;
    this.theme_name = theme_name;
    this.theme_image = theme_image;
    this.theme_color = theme_color;
    this.domain_name = domain_name;
    this.first_page = first_page;
    this.middle_page = middle_page;
    this.last_page = last_page;
  }
}
