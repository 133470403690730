import adminLabels from "../ApplicationStrings/trans_utils";

export const GENERATE_FORGOT_PASSWORD_DATA = [
  {
    type: "password",
    name: "password",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    layoutModal: true,
    validators: [
      {
        required: true,
      },
      {
        regex:
          "(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>\"'\\;:{\\}\\[\\]\\|\\+\\-\\=\\_\\)\\(\\)\\`\\/\\\\\\]]).{8,20}",
      },
    ],
    ui: {
      label: adminLabels.newPassword,
      class: "col-lg-12",
      inputclass: "col-lg-12",
    },
    input: {
      id: "pwd",
      class: "form-control",
      type: "password",
      name: "password",
      placeholder: adminLabels.newPassword,
      maxLength: 20
    },
    label: {
      title: adminLabels.newPassword,
      class: "font-weight-bold",
      for: "pwd",
    },
  },
  {
    type: "password",
    name: "confirm_password",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    validators: [
      {
        required: true,
      },
      {
        regex:
          "(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>\"'\\;:{\\}\\[\\]\\|\\+\\-\\=\\_\\)\\(\\)\\`\\/\\\\\\]]).{8,20}",
      },
      {
        custom: "password()",
      },
    ],
    ui: {
      label: adminLabels.confirmNewPassword,
      class: "col-lg-12",
      inputclass: "col-lg-12",
    },
    input: {
      id: "cpwd",
      class: "form-control",
      type: "password",
      name: "confirm_password",
      placeholder: adminLabels.confirmNewPassword,
      maxLength: 20
    },
    label: {
      title: adminLabels.confirmNewPassword,
      class: "font-weight-bold",
      for: "cpwd",
    },
  },
];
