import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomizationCentreService } from 'src/app/common/services/customization.service';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { ActivatedRoute } from '@angular/router';

declare var $: any;
declare var jquery: any;
@Component({
    selector: 'app-modal-library',
    templateUrl: './modal-library.component.html',
    styleUrls: ['./modal-library.component.css']
})
export class ModalLibraryComponent implements OnInit {
    admin_data = adminLabels;
    constructor(private cutomizationService: CustomizationCentreService,
        private route: ActivatedRoute,) { }
    displayStyle = "none";
    appId = '';
    images = [];
    imageLabels = [this.admin_data.all, this.admin_data.building, this.admin_data.business, this.admin_data.education, this.admin_data.fashion,this.admin_data.finance, this.admin_data.food,this.admin_data.healthcare, this.admin_data.miscellenous, this.admin_data.nature, this.admin_data.people, this.admin_data.science, this.admin_data.technology, this.admin_data.transport, this.admin_data.work]
    sections = [this.admin_data.images, this.admin_data.icons, this.admin_data.colors]
    sectiontypes= ["Images", "Icons", "Colors"]
    iconLabels = [this.admin_data.all, this.admin_data.accessibility, this.admin_data.animal,this.admin_data.audioAndVideo, this.admin_data.automotive, this.admin_data.building, this.admin_data.business, this.admin_data.charity,this.admin_data.chat, this.admin_data.communication, this.admin_data.solid]
    colourLabels = [this.admin_data.all, this.admin_data.trendy, this.admin_data.popular, this.admin_data.random]
    currSection =0;
    currLabels = this.imageLabels;
    libImageSelected = "";
    activeLabel: Number ;
    activeComponent = ""
    imageTypes = [ "Building", "Business", "Education", "Fashion", "Finance", "Food", "Healthcare", "Miscellenous", "Nature", "People", "Science", "Technology", "Transport", "Work"]
    iconTypes = [ "Accessibility", "Animals","Audio_and_video", "Automotive", "Building","Business", "Charity","Chat", "Communication", "Solid"]
    colourTypes = [ "Trendy", "Popular", "Random"]
    
    ngOnInit() {


    }

    openPopup($event) {
        $("#library").modal("show");
        this.setImages(this.currSection, 0, $event);

    }
    closePopup($event) {
        $event.stopPropagation();
        $("#library").modal("hide");
        // $event.stopPropagation()
        this.libImageSelected = "";
        this.activeLabel = undefined;
        this.activeComponent = ""
    }

    setImages(section: Number, type, $event) {
        $event.stopPropagation();
        this.activeLabel = type
        if (type === 0) {

            switch (section) {
                case 0: {
                    this.images = this.cutomizationService.getLibraryImages(this.sectiontypes[0], this.imageTypes);
                    this.currSection = 0;
                    this.currLabels = this.imageLabels;
                    break;
                }
                case 1: {
                    this.images = this.cutomizationService.getLibraryImages(this.sectiontypes[1], this.iconTypes);
                    this.currSection = 1;
                    this.currLabels = this.iconLabels;
                    break;
                }
                case 2: {
                    this.images = this.cutomizationService.getLibraryImages(this.sectiontypes[2], this.colourTypes);
                    this.currSection = 2;
                    this.currLabels = this.colourLabels;
                    break;
                }

            }

        }

        else {
            switch (section) {
                case 0: {
                    this.images = this.cutomizationService.getLibraryImages(this.sectiontypes[0], this.imageTypes[type-1].split(":"));
                    this.currSection = 0;
                    this.currLabels = this.imageLabels;
                    break;
                }
                case 1: {
                    this.images = this.cutomizationService.getLibraryImages(this.sectiontypes[1], this.iconTypes[type-1].split(":"));
                    this.currSection = 1;
                    this.currLabels = this.iconLabels;
                    break;
                }
                case 2: {
                    this.images = this.cutomizationService.getLibraryImages(this.sectiontypes[2], this.colourTypes[type-1].split(":"));
                    this.currSection = 2;
                    this.currLabels = this.colourLabels;
                    break;
                }

            }
        }
    }

    onImageClicked($event) {
        $event.stopPropagation()
        if (this.libImageSelected !== $event.target.currentSrc){
            this.libImageSelected = $event.target.currentSrc;
        }else{
            this.libImageSelected = ""
        }
    }

    selectImage($event) {
        this.libImageSelected = $event.target.currentSrc;
        this.onImageSelected($event);
    }

    onImageSelected($event) {
        $event.stopPropagation()
        let fileName = "image"
        fetch(this.libImageSelected)
            .then(async response => {
                const blob = await response.blob()
                const file = new File([blob], fileName, { type: "image/jpeg" })
                const formData = new FormData();
                this.route.paramMap.subscribe((params) => {
                    if (params['params']['org_id']) {
                   formData.append("organization_id", params['params']['org_id']) 
                formData.append("image", file);
                this.cutomizationService
                    .getImageId(formData).subscribe((response) => {
                        const appPos = this.cutomizationService.apps.map(app => app.appId).indexOf(this.appId);
                        if (this.activeComponent === "firstSlide") {
                            this.cutomizationService.apps[appPos].settings.first_slide = response["data"]["image_path"];
                            this.cutomizationService.apps[appPos].settings.first_slide_id = response["data"]["image_id"];
                        } else if (this.activeComponent === "lastSlide") {
                            this.cutomizationService.apps[appPos].settings.last_slide = response["data"]["image_path"];
                            this.cutomizationService.apps[appPos].settings.last_slide_id = response["data"]["image_id"];
                        }
                        if (this.cutomizationService.changedApps.length >= 1) {
                            const changedPos = this.cutomizationService.changedApps.findIndex((app) => {
                                return app.appId === this.appId
                            })
                            if (changedPos > -1) {
                                this.cutomizationService.changedApps.splice(changedPos, 1)
                                this.cutomizationService.changedApps.push(this.cutomizationService.apps[appPos])

                            } else {
                                this.cutomizationService.changedApps.push(this.cutomizationService.apps[appPos])
                            }
                        }
                        else {
                            this.cutomizationService.changedApps.push(this.cutomizationService.apps[appPos])
                        }
                        this.closePopup($event);
                    })
                    
                }})   
            })
            

    }
}
