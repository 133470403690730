<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">
			<div class="sectionBody">
                <div class="d-flex mb-3">
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                        {{this.admin_data.createPartner}}
                    </div>
                </div>
                <div *ngIf="formGroup" class="">
                    <app-form *ngIf="formGroup" [formData]="add_partner_formdata" [formGroup]="formGroup" ></app-form>
                    <div class="row m-0 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-4 d-flex align-items-center justify-content-center">
                    
                    <app-button [buttonData]="partnerService.cancel_button" (clickhandler)="unassign()"></app-button>
                    <app-button *ngIf="partnerService.isEdit" [disabled]="!formGroup.valid " [buttonData]="userService.update_button" (clickhandler)="editPartner()"></app-button>
                    <app-button *ngIf="!partnerService.isEdit" [disabled]="!formGroup.valid" [buttonData]="userService.add_button" (clickhandler)="addPartner()"></app-button>
                </div>
                </div>
                </div>
            </div>
        </section>
        
    </div>
    <!-- <div class="modal fade" id="actionDeclined" tabindex="-1" role="dialog" aria-labelledby="actionDeclined"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{this.admin_data.actionDeclined}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ng-container>
                    <div class="modal-body">
                       {{decline_message}}
                    </div>
                </ng-container>

            </div>
        </div>
    </div> -->
</div>