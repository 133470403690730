import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API_END_POINTS } from 'src/app/constants/api-end-points';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ManageKeywordsService {

  constructor(private apiService: ApiService) { }

  getGuideTasks(org_id){
    let urlPath =
      environment.baseUrl + API_END_POINTS.GET_ORGANIZATION_TASK_STATUS+
      "?limit=10"+
      "&offset=0" +
      "&organization_id=" +
      parseInt(org_id)+"&task_type=update_guide_keywords";
      return this.apiService.get(urlPath);
  }

    getAllGuides(limit, offset,org_id,app_id, user_id="", folder_id="", published="",search="", category_id = ""){
        let urlPath;
        
        if (search == "") {
            urlPath =
                environment.baseUrl + API_END_POINTS.GET_TOUR_LIST +
                "?limit=" + limit +
                "&offset=" + offset +
                "&organization_id=" + org_id +
                "&application_id=" + app_id;

            if (published != "") {
                urlPath = urlPath + "&published_guides=" + published
            }
        } else {
            urlPath = environment.baseUrl + API_END_POINTS.SEARCH_TOUR_LIST +
                '?limit=' + limit +
                '&offset=' + offset +
                '&organization_id=' + org_id
                + "&application_id=" + app_id +
                '&search_text=' + search;

            if (published != "") {
                urlPath = urlPath + "&is_published=" + published
            }
        }
        if(user_id!=""){
            urlPath = urlPath+ "&my_guides=" + user_id
        }
        if(folder_id!=""){
            urlPath = urlPath + "&category_id="+folder_id
        }
        if(category_id!=""){
            urlPath = urlPath + "&category_id=" + category_id;
        }
        return this.apiService.get(urlPath);

    }

    getFolders(app_id){
    
        let urlPath = 
        environment.baseUrl + API_END_POINTS.GET_CATEGORY_BY_TOUR_COUNT +
        "?application_id=" + app_id;

        return this.apiService.get(urlPath)
    }

    getUsers(org_id){
    
        let urlPath = 
        environment.baseUrl + API_END_POINTS.GET_LIST_USERS + 
        "?limit=100"+
        "&offset=0" + 
        "&organization_id="+org_id + 
        "&owner_creator_filter=1"

        return this.apiService.get(urlPath);
    
    }

    addKeywords(request){
        let urlPath =
        environment.baseUrl + API_END_POINTS.PUT_KEYWORDS 
        return this.apiService.put(urlPath, request)
    }

    deleteKeywords(request){
        let urlPath =
        environment.baseUrl + API_END_POINTS.PUT_KEYWORDS 
        return this.apiService.deleteWithBody(urlPath, request)
    
    }

    // addKeywords(request){
    //     let urlPath = 
    //     environment.baseUrl + API_END_POINTS.ADD_KEYWORD
    // }
}
