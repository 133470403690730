import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent {

  @Input() formControlName!: string;
  @Input() inputClass!: string;
  @Input() type: string = 'checkbox';
  @Input() checked: boolean = false;
  @Input() value!: number;
  @Input() disabled: boolean = false;
  @Output() toggleEvent = new EventEmitter<Event>();

  control!: FormControl;

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.control = this.controlContainer.control?.get(this.formControlName) as FormControl;
  }

  toggle(event: Event) {
    this.toggleEvent.emit(event);
  }
}
