import { Component, OnInit } from '@angular/core';
import { CustomizationCentre, CustomizationCentreService } from 'src/app/common/services/customization.service';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

declare var $: any;
declare var jquery: any;
@Component({
    selector: 'app-modal-step-theme',
    templateUrl: './modal-step-theme.component.html',
    styleUrls: ['./modal-step-theme.component.css']
})
export class ModalStepThemeComponent implements OnInit {
    admin_data = adminLabels;
    appId = '';
    mutableDefaultObj = {};
    mutableClassicObj = {};
    logoSrc= "";
    app: CustomizationCentre = {}
    
    fontSizeArray = [
        {value: "xx-small", name: "xx-small", styles: {'font-size': "xx-small"}},
        {value: "x-small", name: "x-small", styles: {'font-size': "x-small"}},
        {value: "small", name: "small", styles: {'font-size': "small"}},
        {value: "medium", name: "medium", styles: {'font-size': "medium"}},
        {value: "large", name: "large", styles: {'font-size': "large"}},
        {value: "x-large", name: "x-large", styles: {'font-size': "x-large"}},
        {value: "xx-large", name: "xx-large", styles: {'font-size': "xx-large"}},
    ]

    fontWeightArray = [{
        name: 'lighter',
        value: 'lighter',
        styles: {'font-weight': '100'}
    }, {
        name: 'normal',
        value: 'normal',
        styles: {'font-weight': '400'}
    }, {
        name: 'bold',
        value: 'bold',
        styles: {'font-weight': '600'}
    }, {
        name: 'bolder',
        value: 'bolder',
        styles: {'font-weight': '900'}
    }];
    
    fontFamiliesArray = [
        { "value": "Arial", "name": "Arial", "styles": { "font-family": "Arial" } },
        { "value": "Comic Sans MS", "name": "Comic Sans MS", "styles": { "font-family": "Comic Sans MS" } },
        { "value": "Courier New", "name": "Courier New", "styles": { "font-family": "Courier New" } },
        { "value": "Cursive", "name": "Cursive", "styles": { "font-family": "Cursive" } },
        { "value": "Fantasy", "name": "Fantasy", "styles": { "font-family": "Fantasy" } },
        { "value": "Garamond", "name": "Garamond", "styles": { "font-family": "Garamond" } },
        { "value": "Georgia", "name": "Georgia", "styles": { "font-family": "Georgia" } },
        { "value": "Helvetica", "name": "Helvetica", "styles": { "font-family": "Helvetica" } },
        { "value": "Monospace", "name": "Monospace", "styles": { "font-family": "Monospace" } },
        { "value": "Narrow", "name": "Narrow", "styles": { "font-family": "Narrow" } },
        { "value": "Proxima Nova", "name": "Proxima Nova", "styles": { "font-family": "Proxima Nova" } },
        { "value": "Sans-Serif", "name": "Sans-Serif", "styles": { "font-family": "Sans-Serif" } },
        { "value": "Serif", "name": "Serif", "styles": { "font-family": "Serif" } },
        { "value": "Tahoma", "name": "Tahoma", "styles": { "font-family": "Tahoma" } },
        { "value": "Trebuchet MS", "name": "Trebuchet MS", "styles": { "font-family": "Trebuchet MS" } },
        { "value": "Verdana", "name": "Verdana", "styles": { "font-family": "Verdana" } },
        { "value": "Wide", "name": "Wide", "styles": { "font-family": "Wide" } }
      ]
      
    constructor(private cutomizationService: CustomizationCentreService) { }


    ngOnInit() {

    }

    openPopup(app_id, logoSrc) {
        this.logoSrc = logoSrc;
        $("#stepTheme").modal("show");
        this.appId = app_id;
        this.app = JSON.parse(JSON.stringify(this.cutomizationService.apps[this.cutomizationService.apps.findIndex((app) => {
            return app.appId === app_id
        })]))
        if (this.app.settings.popupDesign == undefined) {
            this.app.settings.popupDesign = { ...this.cutomizationService.apps[0].settings.popupDesign };
        }
        let popupDesign = this.getPropValue(this.app, "settings.popupDesign");
        if (this.getPopupStyles("type") == "classic") {
            this.mutableDefaultObj = { ...popupDesign.default };
            this.mutableClassicObj = { ...popupDesign.current };
        } else {
            this.mutableDefaultObj = { ...popupDesign.current };
            this.mutableClassicObj = { ...popupDesign.classic };
        }
    }

    closePopup($event) {
        $event.stopPropagation();
        $("#stepTheme").modal("hide");
        delete this.mutableClassicObj;
        delete this.mutableDefaultObj;
        delete this.app;
    }

    getCardWidth(card_number) {
        if (!card_number) {
            // if (Number(this.getPopupStyles('stepPopupWidth')) > 800) { return "100%" }
            { return "100%" }
        } else {
            if (Number(this.getPopupStyles('stepPopupWidth')) < 800) { return "100%" }
            else { return this.getPopupStyles('stepPopupWidth') }
        }
    }

    getPopupStyles(property) {
        let type = this.getPropValue(this.app, "settings.popupDesign.type");
        if (property == "type") return type;
        let currentObj = this.getCurrentMutableObj(type);
        if (property == "stepPopupWidth") {
            let val = this.getPropValue(this.app, "settings.stepPopupWidth");
            val = val < 250 ? 250 : val;
            val = val > 1000 ? 1000 : val;
            return val;
        }
        if(property.includes("padding")){
           if(currentObj["padding"]!=undefined) {
            return currentObj.padding[property.split(".")[1]]==undefined?0:currentObj.padding[property.split(".")[1]];
           } 
           return 0;
        }
        return currentObj[property];
    }

    getCurrentMutableObj(type): any {
        if (type == null) type = this.getPropValue(this.app, "settings.popupDesign.type");
        if (type === "classic") return this.mutableClassicObj;
        return this.mutableDefaultObj;
    }

    changeTheme($event) {
        this.app.settings.popupDesign.type = $event.target.value;
    }

    changeFontSize(property, propValue) {
        let currentObj = this.getCurrentMutableObj(null);
        currentObj[property] = propValue;
    }

    changeFontFamily(property, propValue) {
        let currentObj = this.getCurrentMutableObj(null);
        currentObj[property] = propValue + "," + currentObj[property].split(",")[1];
    }

    getFontFamily(property) {
        let currentObj = this.getCurrentMutableObj(null);
        return this.capitalizeFirstLetter(currentObj[property]).split(',')[0];
    }

    changeColor(property, color) {
        let currentObj = this.getCurrentMutableObj(null);
        currentObj[property] = color;
        if (property == "closeIconColor") currentObj["audioIconColor"] = color;
    }

    changeNumber(property, $event) {
        $event.stopPropagation();
        let currentObj = this.getCurrentMutableObj(null);
        if ($event.target.value == "") $event.target.value = 0;
        this.enforceMinMax($event);
        currentObj[property] = parseInt($event.target.value);
    }

    changeStepPopupWidth = ($event) => {
        if ($event.target.value == "") this.app.settings.stepPopupWidth = 0;
        else this.app.settings.stepPopupWidth = parseInt($event.target.value);
    }

    changePadding = (prop, $event) => {
        $event.stopPropagation();
        let currentObj = this.getCurrentMutableObj(null);
        if(currentObj.padding == undefined) {
            currentObj.padding = {};
        }
        if ($event.target.value == "") $event.target.value = 0;
        this.enforceMinMax($event);
        currentObj.padding[prop] = parseInt($event.target.value);
    }

    capitalizeFirstLetter(s) {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    saveTheme($event) {
        this.app.settings.popupDesign.current = this.getCurrentMutableObj(null);
        this.cutomizationService.apps[
            this.cutomizationService.apps.findIndex(app => { return this.appId == app.appId })].settings = JSON.parse(JSON.stringify(this.app.settings))
        this.addAppToChanged(this.app)
        this.closePopup($event);
    }

    addAppToChanged(app) {
        if (this.cutomizationService.changedApps.length >= 1) {
            const pos = this.cutomizationService.changedApps.findIndex((app) => {
                return app.appId === this.appId
            })
            if (pos > -1) {
                this.cutomizationService.changedApps.splice(pos, 1)
                this.cutomizationService.changedApps.push(app)

            } else {
                this.cutomizationService.changedApps.push(app)
            }
        }
        else {
            this.cutomizationService.changedApps.push(app)
        }
    }

    getPropertyValue = (o, prop) => {
        const [head, ...rest] = prop.split(".");
        if (rest.length && o.hasOwnProperty(head)) {
            return this.getPropertyValue(o[head], rest.join("."));
        } else {
            return o.hasOwnProperty(head) ? o[head] : "";
        }
    }

    getPropValue = (o: object, prop: string) => {
        let propValue = this.getPropertyValue(o, prop);
        if (JSON.stringify(propValue) != JSON.stringify("")) {
            return propValue;
        }
        return this.getPropertyValue(this.cutomizationService.apps[0], prop);
    }

    resetPopupTheme = () => {
        this.app.settings.popupDesign.type = "default";
        this.app.settings.stepPopupWidth = this.cutomizationService.apps[0].settings.stepPopupWidth;
        this.mutableDefaultObj = { ...this.cutomizationService.apps[0].settings.popupDesign.default };
        this.mutableClassicObj = { ...this.cutomizationService.apps[0].settings.popupDesign.classic };
    }

    enforceMinMax = (event) => {
        let el = (event.target as HTMLInputElement);
        if (el.value != "") {
            if (parseInt(el.value) < parseInt(el.min)) {
                el.value = el.min;
            }
            if (parseInt(el.value) > parseInt(el.max)) {
                el.value = el.max;
            }
        }
    }

    isDisabledSave() {
        let val = this.getPropValue(this.app, "settings.stepPopupWidth");
        if (isNaN(parseInt(val))) {
            return true;
        }
        if (val > 1000 || val < 250) {
            return true;
        }
        return false;
    }

    enforceMinMaxForStepPopup(event) {
        let el = (event.target as HTMLInputElement).value;
        if (el == "" || isNaN(parseInt(el))) {
            event.target.value = this.getPropertyValue(this.cutomizationService.apps[0], "settings.stepPopupWidth");
            this.app.settings.stepPopupWidth = this.getPropertyValue(this.cutomizationService.apps[0], "settings.stepPopupWidth");
        } else {
            this.enforceMinMax(event);
            this.app.settings.stepPopupWidth = parseInt((event.target as HTMLInputElement).value);
        }
    }

    getCdnSignature = () => {
        return this.cutomizationService.cdn_signature;
    }

    getPopupVisibility = () => {
        if (this.app === undefined || this.app.appId === undefined)
            return false;
        return true;
    }

    getPadding = () => {
        let currentObj = this.getCurrentMutableObj(null);
        if(currentObj.padding == undefined) {
            return "0";
        }
        let padding = this.getPadUtil(currentObj, "top")+this.getPadUtil(currentObj, "right")+this.getPadUtil(currentObj, "bottom")+this.getPadUtil(currentObj, "left");
        return padding;
    }

    getPadUtil = (obj, prop) => {
        if(obj.padding[prop]==undefined) return "0px ";
        return obj.padding[prop]+"px ";
    }
}

