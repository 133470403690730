<div *ngIf="theme" class="page-wrapper last-page-mainDiv" [style.border-top]="header_border ? '2px solid ' + theme.theme_color : 'none'">
  <img class="header-img" alt="Header Strip" *ngIf="theme && header_img"
    src="../../../../assets/images/themes/theme-3/header-strip.png" />
  <!-- Title Section -->
  <div class="d-block h-100 word-wrap vertical-align">
 <div #title class="positionRelative h-auto minWidth-40">
    <div class="title-wrapper">
    <div class="edit-options z-index-100" *ngIf="edit_last_page_title">
      <input [(ngModel)]="theme.last_page.title" value="{{ theme.last_page.title }}" type="text" name="last_page_title" autocomplete="off"
        id="last_page_title" maxlength="50"/>
        <button [disabled]="(theme.last_page.title.trim()) ==''" class="btn btn-primary btn-sm" type="button" (click)="onSaveTitle()">
          {{this.admin_data.saveButtonText}}
        </button>
        <button class="btn btn-secondary btn-sm" type="button" (click)="onCancelTitle()">
          {{this.admin_data.cancelButtonText}}
        </button>
    </div>
    <p  class="mb-0 word-wrap" (click)="onEditTitle()" [ngClass]="getTitleStyle()" data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom"
      title="{{this.admin_data.tableTitle4}}">
      {{ theme.last_page.title }}
    </p>
  </div>
  <!-- Logo Section -->
  <div *ngIf="show_logo" class="logo-wrapper" data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom" title="{{this.admin_data.tableTitle4}}">
    <div class="save-options z-index-100" *ngIf="logo_options">
      <button class="btn btn-primary btn-sm" type="button" title="Save" (click)="onSaveLogo(org_id, 'logo')">
        {{this.admin_data.saveButtonText}}
      </button>
      <button class="btn btn-secondary btn-sm" type="button" (click)="onCancelLogo('logo')">
        {{this.admin_data.cancelButtonText}}
      </button>
    </div>


    <div *ngIf="theme.hasOwnProperty('last_page'); then load_img1; else load_img1"></div>
    <ng-template #load_img1>
        <img *ngIf="binary_image" class="logo" alt="Brand Logo" (click)="logo.click()" [src]="
                theme.last_page.logo_url
                  ? theme.last_page.logo_url
                  : '../../../../assets/images/themes/logo.png'
              " data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom"
          title="{{this.admin_data.tableTitle4}}" />
        <img *ngIf="!binary_image" class="logo" alt="Brand Logo" (click)="logo.click()" [src]="
                theme.last_page.logo_url
                  ? theme.last_page.logo_url + cdn_sign
                  : '../../../../assets/images/themes/logo.png'
              " data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom"
          title="{{this.admin_data.tableTitle4}}" />
          </ng-template>
              <ng-template ##load_img2>
                <img class="logo" alt="Brand Logo" (click)="logo.click()" [src]="'../../../../assets/images/themes/logo.png'
                              " data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom"
                  title="{{this.admin_data.tableTitle4}}" />
              </ng-template>
        </div>
    

 

  <!-- Domain Name Section -->
  <div  *ngIf="show_domain_name" class="z-index-100">
    <div class="edit-options z-index-100" *ngIf="edit_domain_name">
      <input [(ngModel)]="theme.domain_name" value="{{ theme.domain_name }}" type="text" name="domain_name_input" autocomplete="off"
        id="domain_name_input" maxlength="50" />
      <button [disabled]="(theme.domain_name.trim()) ==''" class="btn btn-primary btn-sm" type="button" (click)="onSaveDomainName()">
          {{this.admin_data.saveButtonText}}
        </button>
      <button class="btn btn-secondary btn-sm" type="button" (click)="onCancelDomainName()">
        {{this.admin_data.cancelButtonText}}
        </button>
    </div>
    <p (click)="onEditDomainName()" class="mb-0 word-wrap domain-name center z-index-100" data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom"
      title="{{this.admin_data.tableTitle4}}">
      {{ theme.domain_name }}
    </p>
  </div>

  <!-- Sub Title 1 Section -->
  <!-- *ngIf="theme.last_page.lp_subtitle_1" -->
  <div>
    <div class="edit-options z-index-100" *ngIf="edit_last_page_subtitle_1">
      <input [(ngModel)]="theme.last_page.lp_subtitle_1" value="{{ theme.last_page.lp_subtitle_1 }}" type="text" autocomplete="off"
        name="last_page_sub_title_1" id="last_page_sub_title_1" maxlength="50" />
      <button [disabled]="(theme.last_page.lp_subtitle_1.trim()) ==''" class="btn btn-primary btn-sm" type="button" title="Save" (click)="onSaveSubTitle1()">
        {{this.admin_data.saveButtonText}}
        </button>
      <button class="btn btn-secondary btn-sm" type="button" title="Cancel" (click)="onCancelSubTitle1()">
        {{this.admin_data.cancelButtonText}}
        </button>
    </div>
    <p *ngIf="theme.last_page.lp_subtitle_1" class="word-wrap" (click)="onEditSubTitle1()" class="subtitle" data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom" title="{{this.admin_data.tableTitle4}}">
      {{ theme.last_page.lp_subtitle_1 }}
    </p>
  </div>

  <!-- Sub Title 2 Section -->
  <!-- *ngIf="theme.last_page.lp_subtitle_2" -->
  <div>
    <div class="edit-options z-index-100" *ngIf="edit_last_page_subtitle_2">
      <input [(ngModel)]="theme.last_page.lp_subtitle_2" value="{{ theme.last_page.lp_subtitle_2 }}" type="text" autocomplete="off"
        name="last_page_sub_title_2" id="last_page_sub_title_2" maxlength="50" />
      <button [disabled]="(theme.last_page.lp_subtitle_2.trim()) ==''" class="btn btn-primary btn-sm" type="button" (click)="onSaveSubTitle2()">
        {{this.admin_data.saveButtonText}}
        </button>
      <button class="btn btn-secondary btn-sm" type="button" (click)="onCancelSubTitle2()">
        {{this.admin_data.cancelButtonText}}
        </button>
    </div>
    <p *ngIf="theme.last_page.lp_subtitle_2" (click)="onEditSubTitle2()" class="subtitle" data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom" title="{{this.admin_data.tableTitle4}}">
      {{ theme.last_page.lp_subtitle_2 }}
    </p>
  </div>

</div>
</div>

  <img *ngIf="theme.theme_id == 'theme-5'" src="../../../../assets/images/themes/theme-5/footer-strip.png" class="strip-img"/>

    <!-- [style.margin-top]="header_img ? '300px' : '470px'" -->
  <div class="footer-img-div">
    <span *ngIf="show_page_number" class="page-number">{{this.admin_data.pageThemeNumber}}</span>
    <span *ngIf="userService.enableDisplayPublishDate" class="page-number" [ngClass]="{'font-grey':theme.theme_id == 'theme-5'}"><ng-container *ngIf="theme && theme.theme_id == 'theme-2'"> | </ng-container>Published on {{themeService.current_date | date: 'mediumDate'}}</span>
    <div class="save-options z-index-100" *ngIf="footer_logo_options">
      <button class="btn btn-primary btn-sm" type="button" (click)="onSaveLogo(org_id, 'footer_logo')">
        {{this.admin_data.saveButtonText}}
      </button>
      <button class="btn btn-secondary btn-sm" type="button" title="Cancel" (click)="onCancelLogo('footer_logo')">
        {{this.admin_data.cancelButtonText}}
      </button>
    </div>
    <div *ngIf="theme.hasOwnProperty('last_page'); then load_img2; else default_img2"></div>
    <ng-template #load_img2>
      <img *ngIf="show_footer_logo && binary_image" class="footer-logo" alt="Footer Logo" (click)="footer_logo.click()" [src]="
              theme.last_page.footer_logo_url
                ? theme.last_page.footer_logo_url
                : '../../../../assets/images/themes/logo.png'
            " data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom"
        title="{{this.admin_data.tableTitle4}}" />
      <img *ngIf="show_footer_logo && !binary_image" class="footer-logo" alt="Footer Logo" (click)="footer_logo.click()"
        [src]="
              theme.last_page.footer_logo_url
                ? theme.last_page.footer_logo_url + cdn_sign
                : '../../../../assets/images/themes/logo.png'
            " data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom"
        title="{{this.admin_data.tableTitle4}}" />
    </ng-template>
    <ng-template #default_img2>
      <img class="footer-logo" alt="Footer Logo" (click)="footer_logo.click()" [src]="'../../../../assets/images/themes/logo.png'
                  " data-toggle="tooltip" (mouseover)="showToolTip()" (mouseout)="removeToolTip()" data-placement="bottom"
        title="{{this.admin_data.tableTitle4}}" />
    </ng-template>

    
  </div>
  <div *ngIf="addFooterStrip()" class="footer-strip-div">
    <img class="footer-strip" src="../../../../assets/images/themes/footer-strip.png" alt="Footer Strip" />
  </div>
</div>
<input class="d-none" type="file" accept=".jpg,.png" #logo [(ngModel)]="file_1" (input)="onSelectLogo($event, 'logo')" />
<input class="d-none" type="file" accept=".jpg,.png" #footer_logo
[(ngModel)]="file_2" (input)="onSelectLogo($event, 'footer_logo')" />
