import { Injectable, ɵConsole, Injector } from '@angular/core';
import { tap, catchError, retry } from "rxjs/operators";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse, HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import { of, Observable, from, Subject } from 'rxjs';
import { OrganisationService } from './organisation.service';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  allRequestOptions;
  title='';
  constructor(private userService:UserService,private http: HttpClient){

  }

  getHeader(){
 return {
    headers: new HttpHeaders({
    //   'AppKey': environment.authKey,
      'AccessToken':this.userService.user.token
  })
  }
}

  get(url){
    this.allRequestOptions=this.getHeader();
    return this.http.get(url,this.allRequestOptions)
  }

  post(url,request){
    this.allRequestOptions=this.getHeader();
    return this.http.post(url,request,this.allRequestOptions);
  }

  put(url,request){
    this.allRequestOptions = this.getHeader();
    return this.http.put(url,request,this.allRequestOptions);
  }

  delete(url,httpParams){
    this.allRequestOptions=this.getHeader();
    this.allRequestOptions['params']=httpParams;
    return this.http.delete(url,this.allRequestOptions);
  }

  deleteWithBody(url,request){
    this.allRequestOptions=this.getHeader();
    let options=this.getHeader();
    options['body']=request;
    return this.http.delete(url,options);
  }

  refreshToken(){
    const userOptions = {
                    headers: new HttpHeaders({
                    //   'AppKey': environment.authKey,
                      'RefreshToken': this.userService.user.refreshtoken
                    })
                  }
                  let urlPath = environment.baseUrl + "/user/token";
                  return this.http.get(urlPath,userOptions);
  }

  logout(){
    let urlPath=environment.baseUrl+"/user/signout";
    let Options = {
      headers: new HttpHeaders({
        // 'AppKey': environment.authKey,
        'RefreshToken':this.userService.user.refreshtoken
    })
    }
   return this.http.post(urlPath,Options);
  }


}
