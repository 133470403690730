import { ErrorHandler, Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { CustomErrorService } from './custom-error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
    constructor(private errorService: CustomErrorService) { } 
    handleError(error: any): void {
        try {
            const uuid = uuidv4();
            let errorType, errorLog, severity, filePath, urlPath;
            const { name } = error;
            if (name == "CustomError") {                
                errorType = error.errorType;
                errorLog = error.errorLog;
                severity = error.severity;
                filePath = error.filePath;
                urlPath = error.urlPath;
            } else if (error instanceof HttpErrorResponse) {
                errorType = 8;
                errorLog = JSON.stringify(error.message);
                severity = 4;
                filePath = null;
                urlPath = error.url;
            } else {
                let stack = JSON.stringify(error.stack);
                let fileName = stack.substring(stack.indexOf("main"));
                fileName = fileName.substring(0, fileName.indexOf(")"));
                errorType = 7;
                errorLog = error.stack;
                severity = 4;
                filePath = fileName;
                urlPath = null;
            }
            let persistObj = {
                "errorType": errorType,
                "errorLog": errorLog,
                "severity": severity,
                "filePath": filePath,
                "urlPath": urlPath,
                "logUUID": uuid
            }            
            this.errorService.persistErrorDetails(persistObj).subscribe(res => {});
            // console.error(error)
        } catch (error) {
            console.log("error while error logging ", error);
        }
    }
}