<div class="d-flex">
    <!-- <div id="flexNav" *ngIf="userService.organization_id != '000'">
        <app-left-panel></app-left-panel>
    </div> -->
    <div class="container-fluid content-container p-3 toggle-view expanded-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">
            <div class="sectionBody">

                <div class="d-flex mb-3">
					<div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
						{{this.admin_data.themeListVariable1}}
					</div>
				</div>

                <app-theme-list></app-theme-list>
            </div>
        </section>
    </div>
</div>