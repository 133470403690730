import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, forkJoin, from, interval } from 'rxjs';
import { UserService } from '../common/services/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ManageKeywordsService } from '../common/services/manage-keywords.service';
import { CustomizationCentreService } from '../common/services/customization.service';
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from '../common/services/error-response.service';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { SegmentationService } from '../common/services/segmentation.service';
import { ManageSegmentsService } from '../manage-segments.service';
import { OrganisationService } from '../common/services/organisation.service';
import { PaginationService } from '../pagination.service';
declare var $: any;
declare var bootstrap: any;
@Component({
    selector: 'app-manage-segments',
    templateUrl: './manage-segments.component.html',
    styleUrls: ['./manage-segments.component.scss']
})
export class ManageSegmentsComponent implements OnInit, AfterViewInit {

    @ViewChild("status") statusChild;
    @ViewChild("folder") folderChild;
    @ViewChild("userFilter") userFilterChild;
    @ViewChild("app") appChild;

    breadcrumb;
    admin_data = adminLabels;
    showFilterMenu = false;
    loader = false;
    curr_org_id: any;
    limit = 10;
    offset = 0;
    tours = [];
    apps = [];
    keyword = '';
    users = [];
    folders = [];
    curr_folder = '';
    curr_app_id = "";
    curr_user = "";
    curr_status = "";
    search_text = '';
    search_text_segment = '';
    curr_tour;
    curr_segments = [];
    curr_tour_ids = new Set([]);
    taskSubscription: Subscription;
    destroy: Subject<boolean> = new Subject<boolean>();
    published = [this.admin_data.unpublished, this.admin_data.published]
    tableHeading = [this.admin_data.guideName, this.admin_data.apiConnectorFolder, this.admin_data.usersVariable8, this.admin_data.segments, this.admin_data.tableHeaderActions];
    segments: any = [];
    segmentMap: Map<string, string> | undefined;
    values = [];
    curr_tab: String = '';
    cdn_signature: any;
    modalHeader: any;
    modalContent: any;
    group_id: any;
    segmentName: any;
    modalOkText: any;
    tableData: any[] = [];
    disableUpdateTag: Boolean = true;
    blankString = ""
    private gotoPageSubject = new BehaviorSubject<number>(1);
    destroyed = new Subject();
    customSelectObj = [
        { guide_state: this.admin_data.all, guide_value: "" },
        { guide_state: this.admin_data.published, guide_value: "1" },
        { guide_state: this.admin_data.unpublished, guide_value: "0" }
    ]
    // totalCount = -1;

    constructor(
        public userService: UserService,
        private router: Router,
        private manageKeywordService: ManageKeywordsService,
        private route: ActivatedRoute,
        private customizationService: CustomizationCentreService,
        private titleService: Title,
        private errorResponseService: ErrorResponseService,
        private manageSegmentsService: ManageSegmentsService,
        private organisationService: OrganisationService,
        public paginationService: PaginationService,) {
        this.userService.getUserData();
        this.titleService.setTitle(
            this.admin_data.manageSegments + " | Cornerstone Guide"
        );

        this.paginationService.initializeValues();
        this.userService.activeTab = 'manage_segments';
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.userService.setBreadcrumb([
                    {
                        name: this.admin_data.manageSegments,
                        route: null,
                    },
                ]);
                this.breadcrumb = this.userService.breadCrumbs;
            });
        this.route.paramMap.subscribe((params) => {
            if (params['params']['org_id']) {
                if (params['params']['org_id'] !== undefined && params['params']['org_id'] !== '000') {
                    this.curr_org_id = params['params']['org_id'];
                    this.userService.organization_id = this.curr_org_id
                    this.organisationService.getSignature(this.userService.organization_id).subscribe((response) => {
                        this.cdn_signature = response["data"]["cdn_signature"];
                    });
                    this.getApps()
                    this.getUsers()
                    this.getSegments()
                }
            }
        });
        this.curr_tab = this.manageSegmentsService.tab === '' ? 'guides' : this.manageSegmentsService.tab;
    }

    ngAfterViewInit() {
        if (this.appChild) {
            this.appChild.value = this.curr_app_id;
        }
        if (this.statusChild) {
            this.statusChild.value = this.curr_status;
        }
        if (this.folderChild) {
            this.folderChild.value = this.curr_folder;
        }
        if (this.userFilterChild) {
            this.userFilterChild.value = this.curr_user;
        }
    }

    ngOnInit() {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        })
        this.gotoPageSubject.pipe(
            takeUntil(this.destroyed),
            debounceTime(800))
            .subscribe((page: number) => {

                if (page != this.paginationService.currentPage) {
                    let newOffset = this.paginationService.gotoPage(page, this.limit, this.offset)
                    if (newOffset >= 0) {
                        this.offset = newOffset;
                        if (this.offset >= 0) this.getAllGuides(this.curr_app_id);
                    }
                }
            })
        //this.userService.visible.next(true)
    }

    ngOnDestroy() {
        //this.userService.visible.next(true)
        // this.limit = 10;
        this.offset = 0;
        this.paginationService.initializeValues();
        this.destroyed.next(true);
        this.destroyed.complete();
        this.manageSegmentsService.tab = '';
    }

    getApps(app_id?) {
        this.customizationService.getApplications(1000, 0, this.curr_org_id).subscribe((response => {
            this.apps = []
            response['data']['applications'].forEach(element => {
                let app = {
                    application_id: element['application_id'],
                    name: element['title']
                }
                this.apps.push(app);
            });
            if (this.curr_app_id.trim() == "" || this.curr_app_id == undefined) {
                this.curr_app_id = this.apps[0]['application_id'];
            }
            this.getAllGuides(this.curr_app_id)
            this.getFolders(this.curr_app_id)
            this.manageSegmentsService.group = {}
        }))
    }

    getFolders(app_id) {
        this.manageKeywordService.getFolders(app_id).subscribe((response) => {
            this.folders = []
            this.folders.push({ category_id: '', name: this.admin_data.usersVariable3 })
            response['data']['categories'].forEach(element => {
                let folder = {
                    category_id: element['category_id'],
                    name: element['category_title']
                }
                this.folders.push(folder);
            })
        })
    }

    getUsers() {
        this.users = []
        this.manageKeywordService.getUsers(this.curr_org_id).subscribe((response) => {
            this.users.push({ user_id: '', name: this.admin_data.usersVariable3 });
            response['data']['users'].forEach(element => {
                let user = {
                    user_id: element['user_id'],
                    name: element['first_name'] + " " + element['last_name']
                }
                this.users.push(user);
            })
        })
    }

    getSegments() {
        this.manageSegmentsService.getAllSegments(this.curr_org_id).subscribe((response) => {
            this.segments = []
            this.values = []
            this.segments = response['data'] && response['data']['groups'] ? response['data']['groups'] : [];
            this.segmentMap = new Map()
            this.segments.forEach(element => {
                this.segmentMap.set(element['group_id'], element['title'])
                this.values.push({ display: element['title'], value: element['group_id'] })
            });
            let self = this;
            this.tours.forEach((tour) => {
                tour['segment_groups'] = tour['segment_groups']?.filter(function (item) {
                    return self.segmentMap.get(item) !== undefined;
                })
            })
            this.tableData = this.segments
        });
    }


    isTooltip = (tour) => {
        if (tour && tour.tour_type && tour.tour_type.indexOf('smartTip') !== -1) return true;
        else return false;
    };

    isHelpArticle = (tour) => {
        if (tour && tour.tour_type && tour.tour_type.indexOf('help_article') !== -1) return true;
        else return false;
    };

    isTutorial = (tour) => {
        if (tour && tour.tour_type && tour.tour_type.indexOf('onboarding_tour') !== -1) return true;
        else return false;
    };

    isInsights = (tour) => {
        if (tour && tour.tour_type.indexOf('insights') >= 0) return true;
        else return false;
    };

    getIcon = (tour) => {
        let img = '';
        if (tour.image_url && tour.image_url.indexOf('default_icon') == -1) {
            return tour.image_url + this.cdn_signature;
        } else if (this.isHelpArticle(tour)) {
            img = "help-article";
        } else if (this.isInsights(tour)) {
            img = "guide-insights";
        } else if (this.isTutorial(tour)) {
            img = "guide-tutorial";
        } else if (this.isTooltip(tour)) {
            img = "guide-tooltip";
        } else {
            img = "icon-guide";
        }
        return "./../../assets/svgs/" + (tour.is_published != '0' ? img + "-published" : img) + ".svg";
    }
    openModal(index) {
        this.curr_tour = (this.tours[index])
        this.curr_tour_ids.clear();
        this.curr_segments = [];
        this.tours[index]['segment_groups'] ?
            this.tours[index]['segment_groups'].forEach((element) => {
                if (this.segmentMap.get(element) !== undefined)
                    this.curr_segments.push({
                        display: this.segmentMap.get(element),
                        value: element
                    })
            }) : this.curr_segments = [];
        this.blankString = ""
        $("#assignSegments").modal("show");
    }

    openModalForBulkupdate() {
        this.curr_segments = [];
        this.curr_tour = undefined;
        this.blankString = ""
        $("#assignSegments").modal("show");
    }

    closeModal() {
        $("#assignSegments").modal("hide");
    }



    onCheckboxClick(checked, i) {
        if (i == -1) {
            if (checked) {
                this.tours.forEach((element) => {
                    this.curr_tour_ids.add(element['tour_id'])
                })
            }
            else {
                (document.getElementById('allCheck') as HTMLInputElement).checked = false;
                this.curr_tour_ids.clear()
            }
        }
        else {
            if (checked) {
                this.curr_tour_ids.add(this.tours[i]['tour_id'])
            } else {
                this.curr_tour_ids.delete(this.tours[i]['tour_id'])
            }
        }
    }

    getCheckedStatus(i) {
        return this.curr_tour_ids.has(this.tours[i]['tour_id'])
    }

    applyFilter(app_id, status, folder_id, user_id) {
        app_id = app_id ? app_id : this.curr_app_id;
        this.curr_app_id = app_id;
        this.curr_status = status ? status : ''; 
        this.curr_folder = folder_id ? folder_id : ''; 
        this.curr_user = user_id ? user_id : '';
        this.paginationService.initializeValues();
        // this.limit = 10;
        this.offset = 0;
        this.getAllGuides(app_id)
        this.close()
    }

    search() {
        this.curr_status = "";
        this.curr_folder = "";
        this.curr_user = "";
        this.paginationService.initializeValues();
        // this.limit = 10;
        this.offset = 0;
        this.getAllGuides(this.curr_app_id)
    }

    appChanged(app_id) {
        this.curr_app_id = app_id;
        this.curr_status = "";
        this.curr_folder = "";
        this.curr_user = "";
        this.paginationService.initializeValues();
        // this.limit = 10;
        this.offset = 0;
        this.getAllGuides(app_id)
        this.getFolders(app_id)
    }

    close() {
        this.showFilterMenu = false;
        // if (document.getElementById("filter-dropdown-menu") && document.getElementById("filter-dropdown-menu").classList.contains("show")) {
        //     document.getElementById("filter-dropdown-menu").classList.remove("show");
        //     document.getElementById("filter").classList.remove("show");
        // }
    }

    clear(){
        if(this.curr_status !== '' || this.curr_folder !== '' || this.userFilterChild !== '' ){
            this.statusChild.value = '';
            this.userFilterChild.value = '';
            this.folderChild.value = '';
            this.applyFilter(this.curr_app_id, '','','');       
        }
    }

    getTaskList() {

        this.manageSegmentsService.getGuideTasks(this.curr_org_id)
            .subscribe((response) => {
                let index = response['data']['tasks'].findIndex(ele => ele['status'] !== 'PENDING')
                if (!index) {
                    this.taskSubscription.unsubscribe();
                    this.getAllGuides(this.curr_app_id)
                }

            });

    }


    getAllGuides(app_id) {
        this.destroy.next(true);
        this.destroy.next(false);
        this.tours = [];
        this.manageKeywordService.getAllGuides(this.limit, this.offset, this.curr_org_id, app_id, this.curr_user, this.curr_folder, this.curr_status, this.search_text)
            .pipe(takeUntil(this.destroy))
            .subscribe((response) => {
                let count = 0;
                if (response['data']['total_count']) {
                    count = response['data']['total_count']
                }
                if (response['data']['total_rows']) {
                    count = response['data']['total_rows']
                }
                this.paginationService.calculatePageLimit(count, this.limit);
                this.curr_tour_ids.clear()
                this.tours = response['data']['tours']
                this.tours.forEach((tour) => {
                    if (tour['tour_settings'] !== null && tour['tour_settings'] !== "") {
                        let segments = JSON.parse(tour['tour_settings'])['segment_groups'];
                        tour['segment_groups'] = segments ? segments : [];
                    }
                })
                let self = this;
                this.tours.forEach((tour) => {
                    tour['segment_groups'] = tour['segment_groups']?.filter(function (item) {
                        return self.segmentMap.get(item) !== undefined;
                    })
                })
                this.paginationService.setPaginationStatus(this.tours, this.limit);
                this.loader = true
            })

    }

    addSegments() {
        let reqAddArray;
        let reqDeleteArray;
        let tour_ids;
        let self = this;
        let addApiCall;
        let deleteApiCall;
        let temp = this.curr_segments.map(element => element.value);

        if (this.curr_tour && this.curr_tour['segment_groups'] != null) {
            reqAddArray = temp.filter(function (element) {
                return self.curr_tour['segment_groups'].indexOf(element) === -1;
            })
            reqDeleteArray = this.curr_tour['segment_groups'].filter(function (element) {
                return temp.indexOf(element) === -1;
            })
        }
        else {
            reqAddArray = temp
        }

        /*Below if else block is for bulk addition of keywords */
        if (this.curr_tour != undefined) {
            tour_ids = [this.curr_tour['tour_id']];
        } else {
            tour_ids = Array.from(this.curr_tour_ids)
        }
        if (reqAddArray.length > 0) {
            let addRequest = {
                tour_ids: JSON.stringify(tour_ids),
                group_ids: JSON.stringify(reqAddArray),
                organization_id: this.curr_org_id,
                operation: "update"
            }
            addApiCall = this.manageSegmentsService.addSegementToGuide(addRequest)

        }
        if (reqDeleteArray != undefined && reqDeleteArray.length > 0) {
            let deleteRequest = {
                tour_ids: JSON.stringify(tour_ids),
                group_ids: JSON.stringify(reqDeleteArray),
                organization_id: this.curr_org_id,
                operation: "delete"
            }
            deleteApiCall = this.manageSegmentsService.addSegementToGuide(deleteRequest)
        }

        if (reqDeleteArray != undefined && reqDeleteArray.length > 0 && reqAddArray.length > 0) {
            forkJoin(addApiCall, deleteApiCall).subscribe((response) => {
                if (response[0]['error'] == false && response[1]['error'] == false) {
                    this.getAllGuides(this.curr_app_id)
                    this.curr_tour = [];
                    this.curr_segments = [];
                    this.curr_tour_ids.clear()
                }
            });
        }
        else if (reqAddArray.length > 0) {
            addApiCall.subscribe((response) => {
                if (response['error'] == false) {
                    this.getAllGuides(this.curr_app_id)
                    this.curr_tour = [];
                    this.curr_segments = [];
                    if (this.curr_tour_ids.size > 1) {
                        this.taskSubscription && this.taskSubscription.unsubscribe()
                        this.taskSubscription = interval(30000).subscribe((x => {
                            this.getTaskList();
                        }));
                    }
                    this.curr_tour_ids.clear()
                } else {
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    this.userService.errorMessage = errorResponse;
                    $("#errorModal").modal("show");
                }

            });
        }
        else if (reqDeleteArray != undefined && reqDeleteArray.length > 0) {
            deleteApiCall.subscribe((response) => {
                if (response['error'] == false) {
                    this.getAllGuides(this.curr_app_id)
                    this.curr_tour = [];
                    this.curr_segments = [];
                    this.curr_tour_ids.clear()
                } else {
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    this.userService.errorMessage = errorResponse;
                    $("#errorModal").modal("show");
                }

            });
        }
        (document.getElementById('allCheck') as HTMLInputElement).checked = false;
        this.closeModal();
    }

    deleteSegment(tour_id, segment) {

        let deleteRequest = {
            tour_ids: JSON.stringify([tour_id]),
            group_ids: JSON.stringify([segment]),
            organization_id: this.curr_org_id,
            operation: "delete"
        }

        this.manageSegmentsService.addSegementToGuide(deleteRequest).subscribe((response) => {
            if (response['error'] == false) {
                this.getAllGuides(this.curr_app_id)
                this.curr_tour = [];
                this.curr_segments = [];
                this.curr_tour_ids.clear()
            } else {
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            }

        })
    }

    changeTab(Tab: String) {
        this.curr_tab = Tab;
    }

    editGroup(id, $event) {
        this.manageSegmentsService.group = this.segments.find((element) => element["group_id"] == id)
        this.router.navigate(['/segments/edit', id])
    }

    deleteGroup($event) {
        let request = {
            group_id: this.group_id
        }
        this.manageSegmentsService.deleteGroup(request).subscribe((response) => {
            if (response['error'] == false) {
                this.organisationService.showConfirmationModal.next(false);
                this.getSegments();
            } else {
                this.organisationService.showConfirmationModal.next(false);
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            }
        });
    }

    openModalForDelete(group_id) {
        this.modalHeader = this.admin_data.deleteSegment
        this.modalContent = this.admin_data.confirmDeleteSegment
        this.group_id = group_id
        this.segmentName = this.segmentMap.get(group_id)
        let str = this.admin_data.confirmDeleteSegment
        str = str.split(' ')
        str.splice(str.length - 1, 0, "'" + this.segmentName + "'")
        this.modalContent = str.join(' ');
        this.modalOkText = this.admin_data.deleteButtonText;
        this.organisationService.showConfirmationModal.next(true);
    }

    searchSegments() {
        if (this.search_text_segment == '') {
            this.tableData = this.segments;
            return;
        }
        const lowercaseSearch = this.search_text_segment.toLowerCase();
        this.tableData = this.segments.filter((element) => element['title'].toLowerCase().includes(lowercaseSearch)
        );

    }

    updateDisabledProp(prop) {
        this.disableUpdateTag = prop;
    }
    getCreatorName(creator_id) {
        return this.users.find(element => element['user_id'] == creator_id) ? this.users.find(element => element['user_id'] == creator_id)['name'] : ""
    }

    appendOffset() {
        let offset = this.paginationService.appendOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getAllGuides(this.curr_app_id);
        }
    }

    prependOffset() {
        let offset = this.paginationService.prependOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getAllGuides(this.curr_app_id);
        }

    }

    setIndex(ind) {
        let offset = this.paginationService.setIndex(ind, this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getAllGuides(this.curr_app_id);
        }
    }

    goToAction(event) {
        this.gotoPageSubject.next(Number(event.target.value));
    }

    gotoFirstPage() {
        this.setIndex(1);
    }

    gotoLastPage(event) {
        this.setIndex(event);
    }

    changeLimit(event) {
        this.limit = parseInt(event);
        this.offset = 0;
        this.getAllGuides(this.curr_app_id);
        this.paginationService.initializeValues();
    }

    gotoPage(page) {
        this.offset = this.paginationService.gotoPage(page, this.limit, this.offset)
        if (this.offset >= 0) this.getAllGuides(this.curr_app_id);
    }
}
