import adminLabels from "../ApplicationStrings/trans_utils";

export const USER_PROVISIONING_FORMDATA = [
    {
      type: "select",
      name: "provision_method",
      value: "",
      class: "col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 my-2",
      options: [
        {
            value: "provision_token",
            name: "API Token",
        },
        {
          value: "basic_token",
          name: "Basic Token",
        },
        {
            value: "jwt_token",
            name: "JWT Token",
        },
      ],
      validators: [
        {
          required: true,
        },
      ],
      ui: {
        label: adminLabels.userProvisioningMethod,
        class: "font-weight-bold",
        inputclass: "form-control",
      },
    },
    {
        type: "text",
        name: "email_id",
        value: "",
        class: "col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 my-2",
        validators: [
          {
            required: true,
          },
          {
              email:true
          }
        ],
        ui: {
          label: adminLabels.addUserEmailId,
          class: "font-weight-bold",
          inputclass: "form-control",
        },
        input: {
          id: "email_id",
          class: "form-control",
          type: "text",
          name: "email_id",
          placeholder: adminLabels.domainFormatPlaceholder,
        },
        label: {
          title: adminLabels.addUserEmailId,
          class: "font-weight-bold",
          for: "email_id",
        },
      },
]