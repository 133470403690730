import { Component, OnInit } from "@angular/core";
import { FORGOT_FORMADATA } from "../constants/FormData/forgot";
import { UserService } from "../common/services/user.service";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";

import adminLabels from "../constants/ApplicationStrings/trans_utils";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  admin_data = adminLabels;
  constructor(public userService: UserService) {}

  forgot_formData = FORGOT_FORMADATA;
  formGroup;
  emailSent = false;
  urlLinkData;
  ngOnInit() {
    let form = {};
    for (let i = 0; i < this.forgot_formData.length; i++) {
      form[this.forgot_formData[i].name] = new FormControl("");
      let validations = [];
      for (let v of this.forgot_formData[i].validators) {
        for (let key in v) {
          validations.push(key);
        }
      }
      let validators = [];
      for (let j = 0; j < validations.length; j++) {
        switch (validations[j]) {
          case "required":
            validators.push(Validators.required);
            break;

          case "email":
            validators.push(Validators.email);
            break;
        }
      }
      form[this.forgot_formData[i].name].setValidators(validators);
    }
    this.formGroup = new FormGroup(form);

    const query = new URLSearchParams(window.location.search);
		const urlData = [];
		query.forEach((v, k) => {
			urlData[k] = v;
		});

    this.urlLinkData = urlData['redirectURL'];
  }

  reset() {
    this.userService.showLoader = true;
    this.userService
      .forgotPassword(this.formGroup.get("email").value)
      .subscribe(
        (response) => {
          this.userService.showLoader = false;
          this.emailSent = true;
        },
        (error) => {
        }
      );
  }
}
