import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationService } from 'src/app//common/services/organisation.service';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { firstPage, middlePage, lastPage, Theme } from '../theme.model';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app//common/services/user.service';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
declare const $: any;

@Component({
    selector: 'app-theme-list',
    templateUrl: './theme-list.component.html',
    styleUrls: ['./theme-list.component.css']
})
export class ThemeListComponent implements OnInit {
    org_id;
    theme_settings: any;
    active_theme_id: string;
    activate_theme_id: string;
    selectedOrg;
    initialized=false;
    themes: any={
        theme_id:'theme-1'
    };

    admin_data = adminLabels ;

    constructor(
        private route: ActivatedRoute,
        private orgService: OrganisationService,
        private toastr: ToastrService,
        private userService: UserService,
        public organisationService:OrganisationService,
        private errorResponseService : ErrorResponseService,
        public router: Router
    ) {
        this.userService.activeTab = 'themes';

        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];
              this.initialized=false;  
              this.ngOnInit();
          });
    }

    ngOnInit() {
        if(this.org_id && !this.initialized){
            this.initialized=true;
        if (this.userService.user.role != "superadmin") {
            this.org_id=this.userService.organization_id;
            this.setThemes();
            this.setActiveThemeId();
        } else if (
            this.userService.user.role == "superadmin"
        ) {
            this.setThemes();
            this.setActiveThemeId();
        }
    }
        
    }

    public setActiveThemeId() {
        this.orgService.getOrganization(this.org_id).subscribe(
            (response) => {
                let data = response["data"];

                let organization = response["data"]["organization"];
                let org=organization;
    
                this.userService.organization = organization["name"];
                this.userService.organization_id = organization["organization_id"];
                this.selectedOrg = this.userService.organization_id;
                // this.userService.user.cdn_signature = organization["cdn_signature"];
                this.userService.organization = organization;
                let admin_settings = JSON.parse(
                    this.userService.organization.admin_settings
                );
                this.userService.enableSegments =
                    admin_settings["guide_segmentation"] == "1" ? true : false;
                this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
                this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
                this.userService.enableMediaThemes=admin_settings['media_files_themes'] == 0 ? false : true;

                this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
                if(!this.userService.enableMediaThemes){
                    this.router.navigate([
                      "/organization",
                      this.userService.organization_id
                  ]);
                  }
    
                let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));
    
                cdn_sign["cdn_signature"] = organization["cdn_signature"];
                cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;
    
                this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];
    
                localStorage.setItem("organization", JSON.stringify(org));
                localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));

                if(org['theme_settings']){
                this.theme_settings = JSON.parse(org["theme_settings"]);
                }else{
                    this.theme_settings='';
                }


                if (this.theme_settings
                    && this.theme_settings.activeThemeId
                    && this.theme_settings.activeThemeId != null
                    && this.theme_settings.activeThemeId != undefined) {
                    this.active_theme_id = this.theme_settings.activeThemeId;
                } else {
                    this.active_theme_id = "theme-1";
                }
            },
            (error) => {
            }
        );
    }

    public setThemes() {
        let first_page: firstPage = { "logo_id": 0, "logo_url": false, "footer_logo_id": 0, "footer_logo_url": false };
        let middle_page: middlePage = { "footer_logo_id": 0, "footer_logo_url": false };
        let last_page: lastPage = { "title": "Thank You", "lp_subtitle_1": false, "lp_subtitle_2": false, "logo_id": 0, "logo_url": false, "footer_logo_id": 0, "footer_logo_url": false };
        let theme_3_last_page: lastPage = { "title": "Brought to You by", "lp_subtitle_1": "An in-support system tool where users find", "lp_subtitle_2": "what they need to know when they need to know it", "logo_id": 0, "logo_url": false, "footer_logo_id": 0, "footer_logo_url": false };

        this.themes = [
            new Theme('theme-1', 'Turquoise', false, '#56E7CE', "myguide.org", first_page, middle_page, last_page),
            new Theme('theme-2', 'Niagara', false, '#0DAB7F', "myguide.org", first_page, middle_page, last_page),
            new Theme('theme-3', 'Cerulean', false, '#00AEEF', "myguide.org", first_page, middle_page, theme_3_last_page),
            new Theme('theme-5', 'Lochmara', false, '#007DB9', "myguide.org", first_page, middle_page, last_page)
        ];
    }

    getConfirmation(theme_id: string) {
        this.activate_theme_id = theme_id;
        $("#themeConfirmation").modal("show");
    }

    confirm(theme_id: string): void {
        $('#themeConfirmation').modal('hide');
        this.activateTheme(theme_id);
        this.active_theme_id = theme_id;
    }

    activateTheme(theme_id: string) {

        if (this.theme_settings
            && this.theme_settings["activeThemeId"] != null
            && this.theme_settings["activeThemeId"] != undefined) {
            this.theme_settings["activeThemeId"] = theme_id;
        } else {
            this.theme_settings = {
                "activeThemeId": theme_id
            };
        }



        let request: object = {};
        if (theme_id === 'theme-3' && Object.keys((this.theme_settings)).length === 1 && ('themes' in this.theme_settings === false)){
            request = {
                "theme_settings": JSON.stringify({ "activeThemeId": "theme-3", "themes": { "theme-3": { "theme_id": "theme-3", "theme_name": "Cerulean", "theme_image": false, "theme_color": "#00AEEF", "domain_name": "myguide.org", "first_page": { "logo_id": 0, "logo_url": false, "footer_logo_id": 0, "footer_logo_url": false }, "middle_page": { "footer_logo_id": 0, "footer_logo_url": false }, "last_page": { "title": "Brought to You by", "lp_subtitle_1": "An in-support system tool where users find", "lp_subtitle_2": "what they need to know when they need to know it", "logo_id": 0, "logo_url": false, "footer_logo_id": 0, "footer_logo_url": false } } } }),
                "organization_id": this.org_id
            }
        } else {
            request = {
                "theme_settings": JSON.stringify(this.theme_settings),
                "organization_id": this.org_id
            }
        }


        this.orgService.updateThemeSetting(request).subscribe(
            (response) => {
                if(!response['error']){
                this.toastr.success(this.admin_data.successfullyText, this.admin_data.successfullyText, {
                    timeOut: 2000
                });
            }else{
                this.userService.showModal.next(false);
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage=errorResponse;
			    $("#errorModal").modal("show");
            }
            },
            () => {
                this.toastr.error(this.admin_data.themeActivation, this.admin_data.failed, {
                    timeOut: 2000
                });
            }
        );
    }
}
