import { Injectable, NgModule } from '@angular/core';
import { HttpClientModule, HttpHeaders, HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import { API_END_POINTS } from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";


export interface Partner{

    id?: String,
    title?: String,
    description?: String
}
@Injectable({
    providedIn: 'root'
  })
  export class PartnerService{
    
    partners : Partner[] = [];
    currPartner: Partner = {};
    isEdit: Boolean= false;
	showmodal = new Subject<boolean>();
    isDelete = false;

    cancel_button = {
		type: 'cancel',
		class: 'btn btn-secondary minWidth-120 mx-2',
		disable: false,
		text: adminLabels.cancelButtonText
	}
    constructor(private userService: UserService, private http: HttpClient,private apiService:ApiService) { }

    getPartnerList(limit, offset){
        let urlPath = environment.baseUrl + API_END_POINTS.GET_PARTNER_LIST+"?limit=" + limit + "&offset=" + offset;
        return this.apiService.get(urlPath)
    }

    searchPartner(limit, offset, search_key:String){
        
        let urlPath = environment.baseUrl + API_END_POINTS.GET_PARTNER_LIST+"?limit=" + limit + "&offset=" + offset
        +"&search_text=" + search_key;
        
        return this.apiService.get(urlPath)
    
        
    }

    getPartnerById(id){
        let urlPath = environment.baseUrl + API_END_POINTS.GET_PARTNER_BY_ID + "?id=" +id;
        return this.apiService.get(urlPath)
    }

    deletePartner(id){
        let httpParams = new HttpParams().set('id', id);
        let urlPath = environment.baseUrl + API_END_POINTS.GET_PARTNER_BY_ID ;
        return this.apiService.delete(urlPath, httpParams)
    }
    addPartner(request){
        let urlPath = environment.baseUrl + API_END_POINTS.ADD_PARTNER;
        // let httpParams = new HttpParams().set('title', title).append('', description)
        return this.apiService.post(urlPath,request)
    }

    editPartner(request){
        let urlPath = environment.baseUrl + API_END_POINTS.ADD_PARTNER;
        return this.apiService.put(urlPath,request)
    }

    getOrgsForUsers(userid, limit = 1000, offset = 0){
        let urlPath = environment.baseUrl + API_END_POINTS.GET_ORGS_FOR_PARTNER_ADMIN + "?partner_id=" + userid + "&limit=" + limit + "&offset=" + offset;
        return this.apiService.get(urlPath)
    }
}