import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientModule, HttpHeaders, HttpClient, HttpErrorResponse ,HttpParams} from '@angular/common/http';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import {API_END_POINTS} from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";



export interface Roles {
  'role_id': string,
  'title': string,
  'description': string,
  'organization_id': string,
  'is_active': string,
  'creation_date': string,
  'modification_date': string,
  'is_default': string
}


@Injectable({
  providedIn: 'root'
})
export class RoleService {

  // appTitles = [adminLabels.tableHeaderTitle, adminLabels.tableHeaderDescription, adminLabels.tableHeaderCreationDate, adminLabels.tableHeaderModificationDate, 'Action'];
  // appDataKeys = ['','title', 'description', 'creation_date', 'modification_date', 'role_action'];

  // appRoleTitles = ['Mark All', adminLabels.tableHeaderTitle,adminLabels.tableHeaderDescription, adminLabels.tableHeaderCreationDate, adminLabels.tableHeaderModificationDate];
  // appRoleDataKeys = ['check','title', 'description', 'creation_date', 'modification_date'];

  appTitles=[];
  appDataKeys=[];

  col_widths=['25%','25%','20%','20%','10%'];

  selectedRole:Roles;
  isEdit=false;

  assignRoles=false;
  applicationRoles=[];
  assignRoleList=[];

  add_button={
    type:'add_role',
    class:'btn btn-primary minWidth-120',
    disable:false,
    text:adminLabels.rolesAddRoleButtonText
  }

  update_button={
    type:'update_role',
    class:'btn btn-primary minWidth-120',
    disable:false,
    text:adminLabels.rolesUpdateRoleButtonText
  }

  cancel_button={
    class:'',
    disable:'',
    text:adminLabels.cancelButtonText
  }




  //appDataKeys=['name','type','description','creation_date','modification_date','is_active'];

  constructor(private http: HttpClient,public userService: UserService,private apiService:ApiService) { }

  getRoles(limit, offset,search='',app_id='') {
    let urlPath;
    if (this.userService.organization_id == '000') {
      urlPath = environment.baseUrl + API_END_POINTS.GET_ROLE_LIST+"?limit=" + limit + "&offset=" + offset;
    } else {
      urlPath = environment.baseUrl + API_END_POINTS.GET_ROLE_LIST+ "?limit=" + limit + "&offset=" + offset + "&organization_id=" + this.userService.organization_id;
    }
    if(search!=''){
      urlPath+='&search_text='+search;
    }
    if(app_id!=''){
      urlPath+='&application_id='+app_id;
    }

    return this.apiService.get(urlPath);
  }

  addRoles(request,limit,offset,search=''){
    let urlPath = environment.baseUrl + API_END_POINTS.ADD_ROLE;
    return this.apiService.post(urlPath,request);
  }

  editRole(request){
    let urlPath=environment.baseUrl+API_END_POINTS.EDIT_ROLE;
    return this.apiService.put(urlPath,request);
  }

  deleteRole(role_id){
    let httpParams = new HttpParams().set('organization_role_id', role_id);
    let urlPath = environment.baseUrl + API_END_POINTS.DELETE_ROLE;
    return this.apiService.delete(urlPath,httpParams);
  }

  assignRoleToApplication(app_id,org_id){
  let request={
    "role_ids":JSON.stringify(this.assignRoleList),
    "application_id":app_id,
    "organization_id":org_id
  }
  let urlPath=environment.baseUrl+API_END_POINTS.APPLICATION_ACCESS;
  return this.apiService.put(urlPath,request);
}
}
