import adminLabels from "../ApplicationStrings/trans_utils";

export const ADD_PARTNER_FORMDATA = [
    {
        type: "text",
        name: "title",
        value: "",
        formtype: "inline",
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2 font14",
        validators: [
            {
                required: true,
                maxLength: 30,
            },
        ],
        ui: {
            label: adminLabels.tableHeaderTitle,
            class: "col-lg-2",
            inputclass: "col-lg-6",
        },
        input: {
            id: "title",
            class: "form-control",
            type: "text",
            name: "title",
            placeholder: adminLabels.tableHeaderTitle,
        },
        label: {
            title: adminLabels.tableHeaderTitle,
            class: "font-weight-bold label-asterisk",
            for: "title",
        },
    },
    {
        type: "textarea",
        name: "description",
        value: "",
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 font14",
        validators: [
            {
                required: true,
                maxLength: 3000,
            },
        ],
        ui: {
            label: adminLabels.tableHeaderDescription,
            class: "font-weight-bold font14  padding0 label-asterisk",
            inputclass: "form-control",
        },
        input: {
            id: "description",
            class: "form-control",
            type: "text",
            name: "description",
            placeholder: adminLabels.tableHeaderDescription,
        },
        label: {
            title: adminLabels.tableHeaderDescription,
            class: "font-weight-bold label-asterisk",
            for: "description",
        },
    },
    {
        type: "number",
        name: "partner_admin_max_limit",
        value: "",
        formtype: "inline",
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 font14 form-inline mt-4 mb-0",
        validators: [
            {
                required: true,
            
                regex: "^[0-9]*$",
            
                minvalue: 1
            }
        ],
        ui: {
            label: adminLabels.tableHeaderTitle,
            class: "col-lg-2",
            inputclass: "col-lg-4",
        },
        input: {
            id: "partner_admin_max_limit",
            class: "form-control ml-4",
            type: "number",
            name: "partner_admin_max_limit",
            placeholder: adminLabels.numOfPartnerAdminsAllowed,
        },
        label: {
            title: adminLabels.numOfPartnerAdminsAllowed,
            class: "font-weight-bold label-asterisk",
            for: "partner_admin_max_limit",
        },
    },]
