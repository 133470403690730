import { Component, OnInit } from "@angular/core";
import { ApplicationService } from "../../../common/services/application.service";
import { Application } from "../../../common/services/application.service";
import { UserService } from "../../../common/services/user.service";
import { OrganisationService } from "../../../common/services/organisation.service";
import { ADD_APPLICATION_FORMDATA } from "../../../constants/FormData/add_application";
import { ToastrService } from 'ngx-toastr';
import {
    FormGroup,
    FormControl,
    FormBuilder,
    Validators,
} from "@angular/forms";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { ApiService } from "../../../common/services/api.service";
import { BehaviorSubject } from "rxjs";
import { TokenInterceptorInterceptor } from "../../../interceptor/token-interceptor.interceptor";
import { RoleService } from "../../../common/services/role.service";
import { ApiandservicesService } from "../../../common/services/apiandservices.service";
import { debounceTime, filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { PaginationService } from 'src/app/pagination.service';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "src/app/common/services/toast-modal.service";
import adminLabels from "../../../constants/ApplicationStrings/trans_utils";

declare var $: any;

@Component({
    selector: "app-application",
    templateUrl: "./application.component.html",
    styleUrls: ["./application.component.scss"],
})
export class ApplicationComponent implements OnInit {
    limit = 10;
    offset = 0;
    applications: Application[];

    pages = [1, 2];
    currentPage = 1;
    prevIndex = 0;
    currentIndex = 1;
    total = 0;
    pageLimit: number = 0;
    disableNext = false;
    disablePrev = false;
    showPagination = false;
    add_app_formdata = ADD_APPLICATION_FORMDATA;
    images;
    formData;
    searchInputApp;
    deleteIndex = 0;
    delete_app_id;
    formGroup;
    org_id;
    assignApp = false;
    selected_id;
    hasPaginated = false;
    previousPageApps: Application[] = [];
    selectedOrg;
    type;
    onClose = false;
    prevGetApplicationCall;
    pageLimitSet = false;
    prevSearch;
    search = '';
    is_searching=false;
    statusIndex;
    breadcrumb=[];
    admin_data = adminLabels ;
    lastSearchedValue = "";
    private gotoPageSubject = new BehaviorSubject<number>(1);

    constructor(
        private interceptor: TokenInterceptorInterceptor,
        public application: ApplicationService,
        public userService: UserService,
        private organisationService: OrganisationService,
        private router: Router,
        private apiService: ApiService,
        private roleService: RoleService,
        private route: ActivatedRoute,
        public apiAndService: ApiandservicesService,
        private toastr: ToastrService,
        private titleService: Title,
        public paginationService: PaginationService,
		private errorResponseService : ErrorResponseService,
        private toastService:ToastModalService
    ) {
        this.titleService.setTitle( this.admin_data.applicationsTitle + "Cornerstone Guide");
        this.userService.getUserData();
        this.paginationService.initializeValues();
        this.organisationService.getCDNData()
        this.userService.activeTab = 'applications';
        this.application.simplemessage$.subscribe((value) => {
            this.userService.showLoader = false;
        });

        this.route.paramMap.subscribe((params) => {

            this.org_id = params["params"]["org_id"];
            this.userService.organization_id = this.org_id;
            if (params.get("key_id")) {
                this.assignApp = true;
                this.setTableHeaders();
                this.userService.activeTab = 'api & services';

                this.selected_id = params.get("key_id");
                // this.userService.setBreadcrumbs(
                //     "Application-assign:" + params.get('key_name'),
                //     this.route.url["_value"]
                // );
                // this.application.appTitles = [
                //     this.admin_data.applicationsTableHeaderApplicationName,
                //     this.admin_data.applicationsTableHeaderApplicationName,
                //     this.admin_data.tableHeaderType,
                //     this.admin_data.tableHeaderDescription,
                //     this.admin_data.tableHeaderCreationDate,
                //     this.admin_data.tableHeaderModificationDate,
                // ];
                // this.application.appDataKeys = [
                //     "check",
                //     "title",
                //     "type",
                //     "description",
                //     "creation_date",
                //     "modification_date"
                // ];
                this.titleService.setTitle( this.admin_data.apiAndServicesTitle + "Cornerstone Guide");
            } else {
                this.assignApp = false;
                this.selected_id=null;
                this.setTableHeaders();
                this.userService.activeTab="applications"
                // this.application.appTitles = [
                //     this.admin_data.applicationsTableHeaderThumbnail,
                //     this.admin_data.applicationsTableHeaderApplicationName,
                //     this.admin_data.tableHeaderType,
                //     this.admin_data.tableHeaderDescription,
                //     this.admin_data.tableHeaderCreationDate,
                //     this.admin_data.tableHeaderModificationDate,
                //     this.admin_data.tableHeaderActions,
                // ];
                // this.application.appDataKeys = [
                //     "image_url",
                //     "title",
                //     "type",
                //     "description",
                //     "creation_date",
                //     "modification_date",
                //     "app_action",
                // ];
                this.titleService.setTitle( this.admin_data.applicationsTitle + "Cornerstone Guide");
            }

            if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" )) {
                this.getOrganization();
            }else{
                this.ngOnInit();
            }

            this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs(
                //     "Applications",
                //     this.route.url["_value"]
                // );
                if(this.assignApp){
                    this.userService.setBreadcrumb(
                        [
                        {
                         name : this.admin_data.apiAndServicesBreadCrumbTitle,
                         route:['/organization',this.org_id,'get_secret_key']
                        },
                        {
                         name: this.admin_data.applicationsAssignTitle,
                         route:null
                        }
                         ])
                }else{
                this.userService.setBreadcrumb(
                   [{
                    name : this.admin_data.applicationsBreadCrumbTitle,
                    route:null
                    }])
                }
                this.breadcrumb=this.userService.breadCrumbs;
            });
        });
    }

    getOrganization() {
        this.organisationService.getOrganization(this.org_id).subscribe(
            (response) => {
                let organization = response["data"]["organization"];

                this.userService.organization = organization["name"];
                this.userService.organization_id = organization["organization_id"];
                this.selectedOrg = this.userService.organization_id;
                // this.userService.user.cdn_signature = organization["cdn_signature"];
                this.userService.organization = organization;
                let admin_settings = JSON.parse(
                    this.userService.organization.admin_settings
                );
                this.userService.enableSegments =
                    admin_settings["guide_segmentation"] == "1" ? true : false;
                this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
                this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
                this.userService.enableMediaThemes=admin_settings['media_files_themes'] == 0 ? false : true;

                this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
                this.userService.enableAutomation = admin_settings['myBotEnabled'] == 1 ? true:false;

                let org = organization;
                let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

                cdn_sign["cdn_signature"] = organization["cdn_signature"];
                cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

                this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                localStorage.setItem("organization", JSON.stringify(org));
                localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
                // this.ngOnInit();
            }
        );
    }

    ngOnInit() {
        this.roleService.assignRoleList = [];
        this.searchInputApp = document.getElementById("searchInputApp");
        this.is_searching=false;
        if (this.userService.user.role != "superadmin") {
            this.displayAutomationStatus();
            this.getApplications();          
        } else if (
            this.userService.user.role == "superadmin" &&
            this.selectedOrg == this.userService.organization_id
        ) {
            this.displayAutomationStatus();
            this.getApplications();         
        }

        this.gotoPageSubject.pipe(
            debounceTime(800))
            .subscribe((page: number) => {

                if (page != this.paginationService.currentPage) {
                    this.offset = this.paginationService.gotoPage(page, this.limit, this.offset);
                    if (this.offset >= 0) { this.getApplications(); }
                }
            })
    }

    displayAutomationStatus(){
        if(this.userService.enableAutomation && !this.assignApp){
            this.application.appTitles = [ this.admin_data.applicationsTableHeaderThumbnail ,
                                            this.admin_data.applicationsTableHeaderApplicationName ,
                                            this.admin_data.tableHeaderType,
                                            this.admin_data.applicationsTableHeaderMobileApplicationId,
                                            this.admin_data.tableHeaderDescription,
                                            this.admin_data.applicationsTableHeaderAutomationStatus,
                                            this.admin_data.tableHeaderCreationDate,
                                            this.admin_data.tableHeaderModificationDate,
                                            this.admin_data.tableHeaderActions
                                        ];

            this.application.appDataKeys = ['image_url',
'title',
'type',
'mobile_app_id',
'description','isAutomationEnabled',
'creation_date',
'modification_date',
'app_action'];

            this.application.col_widths = ['12%','20%','12%','15%','15%','11%','12%','12%','14%'];  
        }else if(!this.userService.enableAutomation && !this.assignApp){
            this.application.appTitles = [ this.admin_data.applicationsTableHeaderThumbnail ,
                                            this.admin_data.applicationsTableHeaderApplicationName,
                                            this.admin_data.tableHeaderType,
                                            this.admin_data.applicationsTableHeaderMobileApplicationId,
                                            this.admin_data.tableHeaderDescription,
                                            this.admin_data.tableHeaderCreationDate,
                                            this.admin_data.tableHeaderModificationDate,
                                            this.admin_data.tableHeaderActions
                                        ];

            this.application.appDataKeys = ['image_url',
                                            'title',
                                            'type',
                                            'mobile_app_id',
                                            'description',
                                            'creation_date',
                                            'modification_date',
                                            'app_action'
                                            ];
           
            this.application.col_widths = [ '8%',
                                            '12%',
                                            '10%',
                                            '12%',
                                            '15%',
                                            '15%',
                                            '15%',
                                            '13%'
                                        ];  
        }
    }

    getApplications() {
        // this.userService.showLoader = true;
        // if (this.assignApp) {
        //     this.getAllapplications();
        // }
        // else {
        //     if (this.prevGetApplicationCall) {
        //         // this.prevGetApplicationCall.unsubscribe();
        //         clearTimeout(this.prevGetApplicationCall);
        //     }
        //     // if(search!='' && this.prevSearch!=search){
        //     //     this.offset=0;
        //     //     this.currentPage=1;
        //     //     this.pages=[1,2];
        //     // }
        //     this.prevGetApplicationCall = setTimeout(()=>{this.application.getApplications(this.limit, this.offset, this.search, this.type).subscribe(
        //         (response) => {
        //             this.processResponse(response);
        //             this.application.selectedApplication = null;
        //             this.application.isEdit = false;
        //             this.previousPageApps = this.applications;
        //             this.close();
        //             if(this.toastService.showToast){
        //                 this.toastService.showToastMessage(this.toastService.toastType);
        //             }
        //         },
        //         (error) => {
        //         }
        //     )},this.is_searching?300:0);
        //     // this.prevGetApplicationCall();
        // }
    }

    setTableHeaders(){
        this.applications=null;
        if(this.assignApp){
            this.application.appTitles = [
                this.admin_data.applicationsTableHeaderApplicationName,
                this.admin_data.applicationsTableHeaderApplicationName,
                this.admin_data.tableHeaderType,
                this.admin_data.tableHeaderDescription,
                this.admin_data.applicationsTableHeaderApplicationId,
                this.admin_data.tableHeaderCreationDate,
                this.admin_data.tableHeaderModificationDate,
            ];
            this.application.appDataKeys = [
                "check",
                "title",
                "type",
                "description",
                "mobile_app_id",
                "creation_date",
                "modification_date"
            ];
        }else{
            this.application.appTitles = [
                this.admin_data.applicationsTableHeaderThumbnail,
                this.admin_data.applicationsTableHeaderApplicationName,
                this.admin_data.tableHeaderType,
                this.admin_data.applicationsTableHeaderMobileApplicationId,
                this.admin_data.tableHeaderDescription,
                this.admin_data.applicationsTableHeaderApplicationId,
                this.admin_data.applicationsTableHeaderAutomationStatus,
                this.admin_data.tableHeaderCreationDate,
                this.admin_data.tableHeaderModificationDate,
                this.admin_data.tableHeaderActions,
            ];
            this.application.appDataKeys = [
                "image_url",
                "title",
                "type",
                "mobile_app_id",
                "description",
                "mobile_app_id",
                "isAutomationEnabled",
                "creation_date",
                "modification_date",
                "app_action",
            ];
        }
    }


    getAllapplications() {
        let self = this;
        var promise = new Promise((resolve, reject) => {
            let applicationService = this.application;
            let limit = 100;
            let offset = 0;
            let cb = applicationService.getApplications(limit, offset);
            let allApplications = [];
            let fetchRows = false;
            let rows;
            function application() {
                cb.subscribe(
                    (data) => {

                        Array.prototype.push.apply(
                            allApplications,
                            data["data"]["applications"]
                        );
                        offset += 100;
                        cb = applicationService.getApplications(limit, offset);
                        if (!fetchRows) {
                            rows = data["data"]["total_rows"]
                            fetchRows = true;
                        } else {
                            rows = rows % 100;
                        }
                        if (rows / 100 > 1) {
                            application();
                        } else {
                            resolve(allApplications);
                        }
                    },
                    (error) => {
                    }
                );
            }
            application();
        }).then(function (data: []) {
            self.applications = data;
            self.apiAndService
                .getApplicationList(self.selected_id)
                .subscribe((res) => {
                    let apps = res["data"]["application_ids"];

                    self.apiAndService.keyApplications =
                        res["data"]["application_ids"];
                    apps.forEach((data) => {
                        if (self.apiAndService.assignApplications.indexOf(data) == -1) {
                            self.apiAndService.assignApplications.push(data);
                        }
                    });
                });
        });
    }

    processResponse(response) {

      //let search = this.searchInputApp.value;
        this.deleteIndex = 0;

        // if (!this.pageLimitSet) {
            this.paginationService.calculatePageLimit(response['data']['total_rows'], this.limit);
            this.pageLimitSet = true;
        // }

        if (response['data']['applications']) {
            let applications=response['data']['applications']
            applications.forEach((app)=>{
                app['isAutomationEnabled']=app['settings'] && JSON.parse(app['settings'])['isAutomationEnabled']?true:false;
                if (app['type'] == 'guide')
                {
                    app['type'] = 'Web';
                }
                else if (app['type'] == 'electron')
                {
                    app['type'] = 'Desktop';
                }
                else if (app['type'] == 'mobile_web')
                {
                    app['type'] = 'Mobile Web';
                }

                if(app["mobile_app_id"] == null || app["mobile_app_id"] == "")
                {
                    app['mobile_app_id'] = '-';
                }
            })
            this.applications = applications;
            this.paginationService.setPaginationStatus(this.applications, this.limit);
        }
    }

    appendOffsetAndGetApplications() {
        this.is_searching=false;
        let offset = this.paginationService.appendOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getApplications();
        }
    }

    prependOffsetAndGetApplications() {
        this.is_searching=false;
        let offset = this.paginationService.prependOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getApplications();
        }

    }

    setnIndex(ind, event) {
        this.is_searching=false;
        if (this.disablePrev && this.disableNext) {
            event.preventDefault();
            return;
        }
        let offset = this.paginationService.setIndex(ind, this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getApplications();
        }
    }


    searchApplicationList() {
        this.limit = 10;
        this.offset = 0;
        this.paginationService.initializeValues();
        this.pageLimitSet = false;
        this.is_searching=true;
        this.getApplications();
    }

    showModal(app_id) {
        this.userService.showModal.next(true);
        this.applications.forEach((elem, i) => {
            if (elem.application_id == app_id) {
                this.deleteIndex = i;
                this.delete_app_id = elem.application_id;
            }
        });
    }

    showAutomationStatusModal(data){
        $("#automationStatusModal").modal("show");
        this.applications.forEach((elem, i) => {
            if (elem["application_id"] == data['application_id']) {
                this.statusIndex = i;
            }
        });
    }

    closeAutomationStatusModal(){
        $("#automationStatusModal").modal("hide");
    }

    closeModal() {
        $("#deleteAppModal").modal("hide");
    }

    changeAutomationStatus(){
        let settings = JSON.parse(this.applications[this.statusIndex]['settings']);
        settings['isAutomationEnabled'] = !settings['isAutomationEnabled'];
        this.closeAutomationStatusModal();
        let request = {
				application_id: this.applications[this.statusIndex].application_id,
				title: this.applications[this.statusIndex].title,
				image_id: this.applications[this.statusIndex].image_id,
				description: this.applications[this.statusIndex].description,
				settings: JSON.stringify(settings)
			};
        this.application.editApplication(request).subscribe((response)=>{
            this.toastService.showToast=true;
            this.toastService.toastType="update_automation_status";
            this.getApplications();
        })
    }

    addApplication() {

        this.userService.showLoader = true;
        this.formData.append(
            "organization_id",
            this.userService.organization_id
        );
        this.application.getImageId(this.formData).subscribe(
            (response) => {
                let data = response["data"];
                let request = {
                    title: this.formGroup.get("name").value,
                    image_id: parseInt(data["image_id"]),
                    organization_id: parseInt(this.userService.organization_id),
                    description: this.formGroup.get("description").value,
                    type: this.formGroup.get("type").value,
                    settings: "{}",
                };
                this.application.addApplication(request).subscribe((res) => {
                    this.getApplications();
                    this.closeModal();
                });
            },
            (error) => {
            }
        );
    }

    setImages(images) {
        this.formData = new FormData();
        this.formData.append("image", images.get("app_image"));
    }

    performEdit(application) {
        this.router.navigate([
            "/organization",
            this.userService.organization_id,
            "application",
            "edit",
            application["application_id"],
        ]);
    }

    deleteApplication(app) {
        this.userService.showLoader = true;
        this.userService.delete_button.disable=true;
        this.userService.delete_button.text=this.admin_data.deletingButtonText;
        this.is_searching=false;
        this.application.deleteApplication(this.delete_app_id).subscribe(
            (response) => {
                this.userService.delete_button.disable=false;
                this.userService.delete_button.text=this.admin_data.deleteButtonText;
                if(!response['error']){
                this.onClose = true;
                this.deleteIndex = 0;
                this.userService.showModal.next(false);
                this.pageLimitSet=false;
                this.toastService.showToast=true;
                this.toastService.toastType="delete_application";
                if(this.applications.length==1 && this.paginationService.currentPage>1){ 
                    this.prependOffsetAndGetApplications()
                }else{
                    this.getApplications();
                }
            }else{
                this.userService.showModal.next(false);
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage=errorResponse;
                $("#errorModal").modal("show");
                
            }
        },
            (error) => {
                this.userService.delete_button.disable=false;
                this.userService.delete_button.text=this.admin_data.deleteButtonText;
            }
        );
    }

    showRoles(application) {
        this.roleService.assignRoles = true;
        this.router.navigate([
            "/organization",
            this.userService.organization_id,
            "roles",
            { app_id: application.application_id }
        ]);
    }

    applyFilter(type) {
        this.limit = 10;
        this.offset = 0;
        this.paginationService.initializeValues();
        this.pageLimitSet = false;
        this.type = type == 'all' ? '' : type;
        this.is_searching=false;
        this.getApplications();
    }

    close() {
        if (document.getElementById("filter-dropdown-menu")  && document.getElementById("filter-dropdown-menu").classList.contains("show")) {
            document.getElementById("filter-dropdown-menu").classList.remove("show");
            document.getElementById("filter").classList.remove("show");
        }
    }

    assignApps() {
        let request = {
            application_ids: JSON.stringify(this.apiAndService.assignApplications),
            key_id: this.selected_id,
        };
        this.apiAndService.assignAppsToKeys(request).subscribe(
            (response) => {
                this.toastService.showToast = true;
                this.toastService.toastType = "assign_app_to_keys";
                this.router.navigate([
                    "/organization",
                    this.userService.organization_id,
                    "get_secret_key",
                ]);
            },
            (error) => {

            }
        );
    }

    validateInput(event: Event) {
        this.userService.validateInput(event);
        if((event.target as HTMLInputElement).value!=this.lastSearchedValue){
            this.searchApplicationList();
            this.lastSearchedValue=(event.target as HTMLInputElement).value;
        }
    }

    goToAction(event) {
        this.gotoPageSubject.next(Number(event.target.value));
    }
}
