import { Injectable } from '@angular/core';
import { API_END_POINTS } from 'src/app/constants/api-end-points';
import { environment } from "src/environments/environment";
import { ApiService } from './common/services/api.service';

@Injectable({
    providedIn: 'root'
})
export class ManageSegmentsService {

    segment = {};
    tab = '';
    group: any;
    allSegments = [];
    constructor(private apiService: ApiService) { }

    getAllSegments(org_id) {
        let urlPath = environment.baseUrl + API_END_POINTS.GET_ALL_SEGMENTS + "?organization_id=" + org_id;;
        return this.apiService.get(urlPath);
    }

    addSegementToGuide(request) {
        let urlPath = environment.baseUrl + API_END_POINTS.ADD_SEGMENT_GUIDE;
        return this.apiService.put(urlPath, request)

    }

    getGuideTasks(org_id) {
        let urlPath =
            environment.baseUrl + API_END_POINTS.GET_ORGANIZATION_TASK_STATUS +
            "?limit=10" +
            "&offset=0" +
            "&organization_id=" +
            parseInt(org_id) + "&task_type=update_guide_segments";
        return this.apiService.get(urlPath);
    }

    addGroup(request){
        let urlPath =
        environment.baseUrl + API_END_POINTS.ADD_GROUP;
        return this.apiService.post(urlPath,request)        
    }

    updateGroup(request){
        let urlPath =
        environment.baseUrl + API_END_POINTS.ADD_GROUP;
        return this.apiService.put(urlPath,request)        
    }

    deleteGroup(request){
        /**{
            "group_id": "509"
            } */
        let urlPath =
        environment.baseUrl + API_END_POINTS.ADD_GROUP;
        return this.apiService.delete(urlPath,request)        
    }


}
