/* tslint:disable */
import { Component, OnInit } from "@angular/core";
import { ADD_APPLICATION_FORMDATA } from "../../../constants/FormData/add_application";
import { FormGroup, FormControl, FormBuilder, Validators, FormArray,
} from "@angular/forms";
import { ApplicationService } from "../../../common/services/application.service";
import { UserService } from "../../../common/services/user.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { OrganisationService } from "../../../common/services/organisation.service";
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "src/app/common/services/toast-modal.service";
import adminLabels from "../../../constants/ApplicationStrings/trans_utils";


declare var $: any;

@Component({
	selector: "app-add-application",
	templateUrl: "./add-application.component.html",
	styleUrls: ["./add-application.component.scss"],
})
export class AddApplicationComponent implements OnInit {
	org_id;
	routeSet = false;
	breadcrumb;
	admin_settings;
	languages;
	translation_languages;
	selected_languages;
	admin_data = adminLabels ;
	constructor(
		public application: ApplicationService,
		private organisationService: OrganisationService,
		public userService: UserService,
		private router: Router,
		private route: ActivatedRoute,
		private toastr: ToastrService,
		private titleService: Title,
		private errorResponseService : ErrorResponseService,
		private toastService: ToastModalService
	) {
		this.titleService.setTitle(this.admin_data.applicationsTitle + "Cornerstone Guide");
		this.userService.activeTab = 'applications';
		this.userService.getUserData();
		this.organisationService.getCDNData();
		this.route.paramMap.subscribe((params) => {
			this.org_id = params["params"]["org_id"];
			if( params["params"]["org_id"]){
				this.org_id = params['params']['org_id'];
                this.userService.organization_id = this.org_id;
			}

			if (params["params"]["app_id"]) {
				this.routeSet = false;
				this.application
					.getApplication(params["params"]["app_id"])
					.subscribe((response) => {
						this.application.isEdit = true;
						this.routeSet=true;
						this.application.selectedApplication =
							response["data"]["application"];
					if( this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" )){
						this.getOrganization();
					}else{
						this.admin_settings=JSON.parse(this.userService.organization.admin_settings);
						this.initialize();			
					}
					});
			}else{
				if( this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" )){
					this.getOrganization();
				}else{
					this.admin_settings=JSON.parse(this.userService.organization.admin_settings);
					this.initialize();
				}			
			}
		});
		
	}

	setBreadCrumb() {
		let initialBreadCrumbs = [
			{
				name: this.admin_data.applicationsBreadCrumbTitle,
				route: ['/organization', this.org_id, 'application']
			},
		]
		if (this.application.isEdit) {
			initialBreadCrumbs.push({
				name: this.admin_data.addApplicationEditBreadcrumb,
				route: null
			})
		} else {
			initialBreadCrumbs.push({
				name: this.admin_data.addApplicationAddBreadcrumb,
				route: null
			})
		}
		if(this.routeSet){
		this.userService.setBreadcrumb(initialBreadCrumbs);
		this.breadcrumb=initialBreadCrumbs;
		}
	}

	getOrganization() {
		this.organisationService
			.getOrganization(this.org_id)
			.subscribe((response) => {
				let organization = response["data"]["organization"];
				this.userService.organization = organization["name"];
				this.userService.organization_id = organization["organization_id"];
				//this.userService.user.cdn_signature = organization["cdn_signature"];
				this.userService.organization = organization;
				let admin_settings = JSON.parse(
					this.userService.organization.admin_settings
				);
				this.admin_settings=admin_settings;
				this.userService.enableSegments =
					admin_settings["guide_segmentation"] == "1" ? true : false;
				this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
				this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
				this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
				this.userService.enableMediaThemes=admin_settings['media_files_themes'] == 0 ? false : true;
                this.userService.enableAutomation = admin_settings['myBotEnabled'] == 1 ? true:false;
                    
                this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
				let org = organization;
				let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

				cdn_sign["cdn_signature"] = organization["cdn_signature"];
				cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) - 100;

				this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
				this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

				localStorage.setItem("organization", JSON.stringify(org));
				localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
				this.initialize();
			});
	}

	add_app_formdata = JSON.parse(JSON.stringify(ADD_APPLICATION_FORMDATA));

	formData = new FormData();
	hasUploaded = false;
	formGroup;
	ngOnInit() {
		
	}

	initialize(){
		let form = {};
		if(this.enableMirrorApplicationSettings() || !this.userService.enableAutomation){
			this.add_app_formdata.splice(this.add_app_formdata.length - 1, 1);
		}
		for (let i = 0; i < this.add_app_formdata.length; i++) {
			form[this.add_app_formdata[i].name] = new FormControl("");
			let validations = [];
			for (let v of this.add_app_formdata[i].validators) {
				for (let key in v) {
					validations.push(key);
				}
			}
			let validators = [];
			for (let j = 0; j < validations.length; j++) {
				switch (validations[j]) {
					case "required":
						validators.push(Validators.required);
						break;

					case "email":
						validators.push(Validators.email);
						break;
				}
			}
			form[this.add_app_formdata[i].name].setValidators(validators);
		}

		this.formGroup = new FormGroup(form);
		let inputValidateField = ["title", "description"];
		for(let ind=0; ind<inputValidateField.length; ind++){
		this.formGroup.get(inputValidateField[ind]).valueChanges.subscribe(x => {
			if (x && !this.userService.pattern.test(x)) {
			this.formGroup.get(inputValidateField[ind]).setValue(x.replace(this.userService.replacePattern, ""),{ emitEvent: false });
			}
		});
		}

		this.formGroup.patchValue({
			type: 'guide'
		});
		this.routeSet=true;
		if (this.application.isEdit) 
        {
            this.setBreadCrumb();

            if (this.formGroup.get('type'))
            {
                this.formGroup.removeControl('type');
                this.add_app_formdata.splice(3, 1);
            }


			for (let i = 0; i < this.add_app_formdata.length; i++) {
				if (
					this.application.selectedApplication[this.add_app_formdata[i].name]
				) {
					this.formGroup.patchValue({
						[this.add_app_formdata[i].name]: this.application
							.selectedApplication[this.add_app_formdata[i].name],
					});
				}
				if (this.add_app_formdata[i].type == "file") {
					this.add_app_formdata[
						i
					].source = this.application.selectedApplication.image_url + this.organisationService.cdn_signature;
					this.formGroup.patchValue({
						app_image: "",
					});
				}
			}
			if(!this.enableMirrorApplicationSettings()) {
				this.formGroup.patchValue({
					'isAutomationEnabled':JSON.parse(this.application.selectedApplication['settings'])['isAutomationEnabled']?true:false
				})
			}
		}
        else{

            this.setBreadCrumb();

            this.formGroup.get('type').valueChanges.subscribe((val) => {
                if (val == 'android') {
                    if (this.formGroup.get('package_id')) {
                        this.formGroup.removeControl('package_id');
                        this.add_app_formdata.splice(4, 1);
                    }
                    if (this.formGroup.get('bundle_id')) {
                        this.formGroup.removeControl('bundle_id');
                        this.add_app_formdata.splice(4, 1);
                    }
                    this.formGroup.addControl('package_id', new FormControl(''));
                    this.add_app_formdata.splice(4,0,{
                        type: "text",
                        name: "package_id",
                        value: "",
                        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
                        validators: [
                            {
                                required: true,
                                maxLength: 120,
                            },
                        ],
                        ui: {
                            label: this.admin_data.addApplicationPackageId,
                            class: "col-lg-4 font14",
                            inputclass: "col-lg-8 ",
                        },
                        input: {
                            id: "package_id",
                            class: "form-control font14",
                            type: "text",
                            name: "package_id",
                            placeholder: "Package ID",
                        },
                        label: {
                            title: this.admin_data.addApplicationPackageId,
                            class: "font-weight-bold font14 label-asterisk",
                            for: "package_id",
                        },
                    });
                }
                else if (val == 'ios'){
                    if (this.formGroup.get('package_id')) {
                        this.formGroup.removeControl('package_id');
                        this.add_app_formdata.splice(4, 1);
                    }
                    if (this.formGroup.get('bundle_id')) {
                        this.formGroup.removeControl('bundle_id');
                        this.add_app_formdata.splice(4, 1);
                    }
                    this.formGroup.addControl('bundle_id', new FormControl(''));
                    this.add_app_formdata.splice(4,0,{
                        type: "text",
                        name: "bundle_id",
                        value: "",
                        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
                        validators: [
                            {
                                required: true,
                                maxLength: 120,
                            },
                        ],
                        ui: {
                            label: this.admin_data.addApplicationBundleId,
                            class: "col-lg-4 font14",
                            inputclass: "col-lg-8 ",
                        },
                        input: {
                            id: "bundle_id",
                            class: "form-control font14",
                            type: "text",
                            name: "bundle_id",
                            placeholder: "Bundle ID",
                        },
                        label: {
                            title: this.admin_data.addApplicationBundleId,
                            class: "font-weight-bold font14 label-asterisk",
                            for: "bundle_id",
                        },
                    });
                }
                else
                {
                    if (this.formGroup.get('package_id')) {
                        this.formGroup.removeControl('package_id');
                        this.add_app_formdata.splice(4, 1);
                    }
                    if (this.formGroup.get('bundle_id')) {
                        this.formGroup.removeControl('bundle_id');
                        this.add_app_formdata.splice(4, 1);
                    }
                }		
            });

		}

		this.languages=this.admin_settings.language_settings;
		if (!this.enableMirrorApplicationSettings() && this.languages) {
			if (this.languages['default']['language'].includes('en') && this.languages['translations'] && this.languages['translations'].length > 0) {
				this.translation_languages = this.languages['translations'];
				this.formGroup.addControl('enable_application_language_settings', new FormControl(0));
				this.add_app_formdata.push({
					type: "regular_checkbox",
					name: "enable_application_language_settings",
					class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 d-flex align-items-center font14",
					value: "",
					checked: true,
					validators: [],
					ui: {
						label: this.admin_data.addApplicationEnableApplicationLanguageSettings,
						class: "font-weight-bold mb-0 custom-check",
					},
					visibility: (this.admin_settings['guide_translation'] === 1 || this.admin_settings['guide_translation'] === '1' ? (this.languages['translations'].length > 0 ? true : false) : false)
				 })
				 

				//   if(this.translation_languages.length>0){
				// 	  this.formGroup.addControl('app_languages',new FormArray([]));
				// 	  this.add_app_formdata.push({
				// 		type: "form_table",
				// 		name: "app_languages",
				// 		class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 d-flex align-items-center font14",
				// 		value: "",
				// 		checked: true,
				// 		validators: [],
				// 		data:this.translation_languages,
				// 		titles:['Mark All','Language','Voice'],
				// 		title_keys:['check','name','voice'],
				// 		ui: {
				// 		  label: "Application level translation languages",
				// 		  class: "font-weight-bold mb-0 custom-check",
				// 		}, 
				// 	  })
				//   }

				this.formGroup.get('enable_application_language_settings').valueChanges.subscribe((val) => {
					if (val == '1') {
						let temp_lang = JSON.parse(JSON.stringify(this.translation_languages));

						this.translation_languages = [];
						this.translation_languages = temp_lang;
						if (this.translation_languages.length > 0) {
							this.formGroup.addControl('app_languages', new FormArray([]));
							this.add_app_formdata.push({
								type: "form_table",
								name: "app_languages",
								class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 d-flex align-items-center font14",
								value: "",
								checked: true,
								validators: [],
								data: this.translation_languages,
								titles: [this.admin_data.markAll, this.admin_data.language, this.admin_data.voice],
								title_keys: ['check', 'name', 'voice'],
								ui: {
									label: this.admin_data.addApplicationApplicationLevelTranslationLanguages,
									class: "font-weight-bold mb-0 custom-check",
								},
								visibility: (this.admin_settings['guide_translation'] === 1 || this.admin_settings['guide_translation'] === '1' ? (this.languages['translations'].length > 0 ? true : false) : false)
							})
						}
					} else {
						if (this.formGroup.get('app_languages')) {
							this.formGroup.removeControl('app_languages');
							this.add_app_formdata.splice(this.add_app_formdata.length - 1, 1);
						}
					}
				})
				
				if (this.application.isEdit) {
					let settings = JSON.parse(this.application.selectedApplication['settings']);
					if (settings['enable_application_language_settings']) {
						this.formGroup.patchValue({
							enable_application_language_settings: settings['enable_application_language_settings'] ? '1' : '0'
						})
						this.selected_languages = settings['app_languages'];
					}
				}
			}
		}
		
	}

	addApplication() {
		this.formData.append(
			"organization_id",
			this.userService.organization_id
		);
		this.application.add_button.disable=true;
		this.application.add_button.text=this.admin_data.addingButtonText;
		let mobile_app_id = '';
		let settings = {};
		if(!this.enableMirrorApplicationSettings() && this.userService.enableAutomation){
		settings['isAutomationEnabled']=(this.formGroup.get("isAutomationEnabled").value=='1' || this.formGroup.get("isAutomationEnabled").value===true)?true:false;
		}

		if(this.formGroup.get("type").value=='android')
		{
			mobile_app_id = this.formGroup.get("package_id").value
		}
		else if (this.formGroup.get("type").value=='ios')
		{
			mobile_app_id = this.formGroup.get("bundle_id").value
		}

		if (!this.enableMirrorApplicationSettings() && this.languages){
			if(this.languages['translations'] && this.languages['translations'].length>0){

			settings['enable_application_language_settings']=this.formGroup.get('enable_application_language_settings').value == '1'?true:false;
			if(this.formGroup.get('enable_application_language_settings').value == '1'){
				settings['app_languages']=this.formGroup.get('app_languages').value;
			}
			}
		}
		
		
		if (this.formData.get("image")) {
			// this.userService.showLoader = true;
			this.application.getImageId(this.formData).subscribe((response) => {
				// this.userService.showLoader = false;
				let data = response["data"];
				let request = {
					title: this.formGroup.get("title").value.trim(),
					image_id: parseInt(data["image_id"]),
					organization_id: parseInt(this.userService.organization_id),
					description: this.formGroup.get("description").value.trim(),
					type: this.formGroup.get("type").value,
					settings: JSON.stringify(settings),
					mobile_app_id: mobile_app_id, 
				};
				// this.userService.showLoader = true;
				this.application.addApplication(request).subscribe(
					(res) => {

						this.application.add_button.disable=false;
						this.application.add_button.text=this.admin_data.addButtonText;
						if (res['error'] == false) {
							// this.userService.showLoader = false;
							this.router.navigate([
								"/organization",
								this.userService.organization_id,
								"application",
							]);
							for (let i = 0; i < this.add_app_formdata.length; i++) {
								this.formGroup.patchValue({
									[this.add_app_formdata[i].name]: "",
								});
								if (this.add_app_formdata[i].type == "file") {
									this.add_app_formdata[i].source = "../assets/images/image.png";
								}
							}
							this.application.selectedApplication = null;
							this.application.isEdit = false;
							// this.toastr.success('Application Created', this.admin_data.successfullyText, {
							// 	timeOut: 2000
							//   });
							this.toastService.showToast = true;
							this.toastService.toastType = "add_application";
						} else {
							if (res['code'] == 3034) {
								this.showDeclineModal();
							}
							if (res['code'] == 3064){
								this.showDeclineModal2();
							}
							if(response['code'] == 3011){
								this.userService.showModal.next(false);
								let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
								this.userService.errorMessage=errorResponse;
								$("#errorModal").modal("show");
							}
						}
					},
					(error) => {

						this.application.add_button.disable=false;
						this.application.add_button.text=this.admin_data.addButtonText;
						if (error.error.code == 1003 || error.error.code == 1007) {
							this.application.eventEmitted$.subscribe((value) => {
								if (value["data"]) {
									// this.userService.showLoader = false;
									this.router.navigate([
										"/organization",
										this.userService.organization_id,
										"application",
									]);
									for (let i = 0; i < this.add_app_formdata.length; i++) {
										this.formGroup.patchValue({
											[this.add_app_formdata[i].name]: "",
										});
										if (this.add_app_formdata[i].type == "file") {
											this.add_app_formdata[i].source =
												"../assets/images/image.png";
										}
									}
									this.application.selectedApplication = null;
									this.application.isEdit = false;
								}
							});
						}
					}
				);
			});
		} else {
			let request = {
				title: this.formGroup.get("title").value.trim(),
				image_id: "0",
				organization_id: parseInt(this.userService.organization_id),
				description: this.formGroup.get("description").value.trim(),
				type: this.formGroup.get("type").value,
				settings: JSON.stringify(settings),
				mobile_app_id: mobile_app_id, 
			};
			// this.userService.showLoader = true;
			this.application.addApplication(request).subscribe(
				(response) => {

					this.application.add_button.disable=false;
					this.application.add_button.text=this.admin_data.addButtonText;
					if (response['error'] == false) {
						// this.userService.showLoader = false;
						this.router.navigate([
							"/organization",
							this.userService.organization_id,
							"application",
						]);
						for (let i = 0; i < this.add_app_formdata.length; i++) {
							this.formGroup.patchValue({
								[this.add_app_formdata[i].name]: "",
							});
							if (this.add_app_formdata[i].type == "file") {
								this.add_app_formdata[i].source = "../assets/images/image.png";
							}
						}
						this.application.selectedApplication = null;
						this.application.isEdit = false;
						// this.toastr.success('Application Created', this.admin_data.successfullyText, {
						// 	timeOut: 2000
						//   });
						this.toastService.showToast = true;
						this.toastService.toastType = "add_application";
					} else {
						if (response['code'] == 3034) {
							this.showDeclineModal();
						}
						if (response['code'] == 3064) {
							this.showDeclineModal2();
						}
						if(response['code'] == 3011){
							this.userService.showModal.next(false);
							let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
							this.userService.errorMessage=errorResponse;
							$("#errorModal").modal("show");
						}
					}
				},error=>{
					this.application.add_button.disable=false;
					this.application.add_button.text=this.admin_data.addButtonText;
				});
		}
	}

	setImages(images) {
		this.hasUploaded = true;
		this.formData.append("image", images.get("app_image"));
	}

	editApplication() {
		this.application.update_button.disable=true;
		this.application.update_button.text=this.admin_data.updatingButtonText;
		let settings = JSON.parse(this.application.selectedApplication['settings']);
		if(!this.enableMirrorApplicationSettings() && this.userService.enableAutomation){
		settings['isAutomationEnabled']=(this.formGroup.get("isAutomationEnabled").value=='1' || this.formGroup.get("isAutomationEnabled").value===true)?true:false ;
		}



		if (!this.enableMirrorApplicationSettings() && this.languages){
			if (this.languages['translations'] && this.languages['translations'].length > 0) {
				settings['enable_application_language_settings'] = this.formGroup.get('enable_application_language_settings').value == '1' ? true : false;
				if (this.formGroup.get('enable_application_language_settings').value == '1') {
					settings['app_languages'] = this.formGroup.get('app_languages').value;
				}
		}
		
	}
		if (this.hasUploaded) {
			this.formData.append(
				"organization_id",
				this.userService.organization_id
			);
			// this.userService.showLoader = true;
			this.application.getImageId(this.formData).subscribe(
				(response) => {
					// this.userService.showLoader = false;
					let data = response["data"];
					let request = {
						application_id: this.application.selectedApplication.application_id,
						title: this.formGroup.get("title").value.trim(),
						image_id: parseInt(data["image_id"]),
						description: this.formGroup.get("description").value.trim(),
						// type: this.formGroup.get("type").value,
						settings: JSON.stringify(settings),
					};
					// this.userService.showLoader = true;
					this.application.editApplication(request).subscribe(
						(response) => {
							// this.userService.showLoader = false;
							this.application.update_button.disable=false;
							this.application.update_button.text=this.admin_data.updateButtonText;
							this.resetFormData();
							this.router.navigate([
								"/organization",
								this.userService.organization_id,
								"application",
							]);
							// this.toastr.success('Application Updated', this.admin_data.successfullyText, {
							// 	timeOut: 2000
							//   });
							this.toastService.showToast = true;
							this.toastService.toastType = "update_application";
						}
					);
				}
			);
		} else {
			let request = {
				application_id: this.application.selectedApplication.application_id,
				title: this.formGroup.get("title").value.trim(),
				image_id: this.application.selectedApplication.image_id,
				description: this.formGroup.get("description").value.trim(),
				// type: this.formGroup.get("type").value,
				settings: JSON.stringify(settings),
			};
			// this.userService.showLoader = true;
			this.application.editApplication(request).subscribe(
				(response) => {
					// this.userService.showLoader = false;
					this.application.update_button.disable=false;
					this.application.update_button.text=this.admin_data.updateButtonText;
					this.resetFormData();
					this.router.navigate([
						"/organization",
						this.userService.organization_id,
						"application",
					]);
					// this.toastr.success('Application Updated', this.admin_data.successfullyText, {
					// 	timeOut: 2000
					//   });
					this.toastService.showToast = true;
					this.toastService.toastType = "update_application";
				}
			);
		}
	}

	resetFormData() {
		for (let i = 0; i < this.add_app_formdata.length; i++) {
			this.formGroup.patchValue({
				[this.add_app_formdata[i].name]: "",
			});
			if (this.add_app_formdata[i].type == "file") {
				this.add_app_formdata[i].source = "../assets/images/image.png";
			}
		}
		this.application.selectedApplication = null;
		this.application.isEdit = false;
	}

	unassign() {
		this.application.selectedApplication = null;
		this.application.isEdit = false;
		for (let i = 0; i < this.add_app_formdata.length; i++) {
			this.formGroup.patchValue({
				[this.add_app_formdata[i].name]: "",
			});
			if (this.add_app_formdata[i].type == "file") {
				this.add_app_formdata[i].source = "../assets/images/image.png";
			}
		}
		this.router.navigate([
			"/organization",
			this.userService.organization_id,
			"application",
		]);
	}

	showDeclineModal() {
		$("#actionDeclined").modal("show");
		this.formGroup.markAsPristine();
		this.formGroup.markAsUntouched();
	}

	showDeclineModal2() {
		$("#actionDeclined2").modal("show");
		this.formGroup.markAsPristine();
		this.formGroup.markAsUntouched();
	}

	enableMirrorApplicationSettings = () => {
		if (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" 
			|| this.userService.user.role == "partner_admin") {
				if(this.userService.organization && this.userService.organization["has_mirror_apps"]=="1") {
					return true;
				}
		}
		return false;
	}
}  
