<div class="d-flex">
	<div class="container-fluid content-container p-3 toggle-view"
		[ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
		<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
		<section class="component-section">
			<div class="sectionBody">
				<div class="d-flex mb-3">
					<div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
						{{this.admin_data.apiAndServicesBreadCrumbTitle}}
					</div>
					<div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right">
						<button type="button" class="btn btn-primary ml-2" [routerLink]="[
						'/organization',userService.organization_id,'generate_secret_key'
					  ]"> {{ this.admin_data.apiAndServicesGenrateCrendentialsButton }}
						</button>
					</div>
				</div>
				
				<app-table [data]="secretKeyList" [titles]="apiAndServices.appTitles"
					[dataKeys]="apiAndServices.appDataKeys" [widths]="apiAndServices.col_widths"
					(delete)="showModal($event)" (roles)="showRoles($event)" (edit)="editApiKey($event)">
				</app-table>
				<ng-container *ngIf="secretKeyList">
					<ng-container *ngIf="secretKeyList.length>0">
                        <app-modal-confirmation [header_value]="modalHeader" [content_value]="modalContent"
                        [modal_ok_value]="this.userService.delete_button.text" [org]=""
                        [disabled]="this.userService.delete_button.disable"
                        (onDelete)="deleteSecretKey($event)"></app-modal-confirmation>
					</ng-container>
				</ng-container>
			</div>
		</section>
	</div>
</div>