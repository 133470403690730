<div class="d-flex">
	<div class="container-fluid content-container p-3 toggle-view"
		[ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
		<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>

		<section class="component-section">
			<div class="sectionBody">

				<div class="d-flex mb-3">
					<div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
						{{this.admin_data.activity}}
					</div>
				</div>

				<!-- <ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" (click)="changeTab(1)">
						<a class="nav-link custom-nav-link active" id="home-tab" data-toggle="tab" href="#admin_activities"
							role="tab" aria-controls="home" aria-selected="true">{{this.admin_data.activityAdmin}}</a>
					</li> -->
				<!-- <li class="nav-item" (click)="changeTab(2)">
						<a class="nav-link custom-nav-link" id="profile-tab" data-toggle="tab" href="#login_details" role="tab"
							aria-controls="profile" aria-selected="false">{{this.admin_data.activityLogin}}</a>
					</li> -->
				<!-- <li class="nav-item">
			<a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Contact</a>
		  </li> -->
				<!-- </ul> -->
				<div class="tab-container pb-0 clearfix">
					<div class="d-flex justify-content-end align-items-center mb-3">
						<div [class.d-none]="currentTab!=1" id="filter" class="filter-dropdown">
							<button class="btn-primary-borderless-medium" id="filter-menu"
								(click)="showFilterMenu = !showFilterMenu">
								<span class="d-flex align-items-center">
									<span class="mr-2">
										<img src="../../../assets/svgs/filter_new.svg" />
									</span>
									<span>{{admin_data.filter}}</span>
								</span>
							</button>
							<div class="dropdown-menu pb-0" role="menu" [ngClass]="{'show': showFilterMenu}"
								id="filter-dropdown-menu">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left px-4 pt-3">
									<div class="d-flex justify-content-between align-items-baseline">
										<span
											class="fontWeight-900 font16 text-color-dark">{{this.admin_data.filters}}</span>
										<div class="d-flex justify-content-end align-items-center">
											<span type="button"
												class="primaryFont-color mx-2 px-2 font13 fontWeight-700"
												(click)="clear()">
												{{this.admin_data.clear}}</span>
											<button type="button" class="btn btn-primary-small"
												(click)="applyFilter();$event.stopPropagation()"
												[disabled]="actions.length==0 || !this.action">
												{{this.admin_data.apply}}</button>
										</div>
									</div>
								</div>
								<hr>
								<div class="form-group col-md-12 text-left d-flex flex-column px-4">
									<label class="fontWeight-900 font14 text-color-dark-grey"
										for="status">{{this.admin_data.activityType}}</label>
									<!-- <select #action_type class="form-control form-control-sm" id="action_type"
											(change)="setActions($event.target.value)" >
											<option value='' disabled>{{this.admin_data.pleaseSelect}}</option>
											<option *ngFor="let type of action_types" value={{type.type}} [selected]="type.type===action_Type">
												{{type.label}}
											</option>
										</select> -->

									<!-- <option value="all">All</option>
						  <option value="0">Active</option>
						  <option value="1">Suspended</option> -->

									<app-custom-select [dataList]="action_types" componentName="activityType"
										[selected]="action_Type"
										(updateDataList)="setActions($event)" dropdownPosition="inherit"
										[ngStyle]="{ 'position': 'relative' }"></app-custom-select>
								</div>

								<div class="form-group col-md-12 text-left d-inline-block px-4 mb-4">
									<label class="fontWeight-900 font14 text-color-dark-grey"
										for="status">{{this.admin_data.activityAction}}</label>
									<!-- <select #actionTag class="form-control form-control-sm" id="status"
										[attr.disabled]="actions.length==0 ? '':null">
										<option value=''>{{this.admin_data.pleaseSelect}}</option>
										<option *ngFor="let act of actions" value={{act.value}}
											[selected]="act.value === action">
											{{act.label}}
										</option>
									</select> -->
									<!-- <option value="all">All</option>
										<option value="0">Active</option>
										<option value="1">Suspended</option> -->

									<app-custom-select [dataList]="actions" componentName="activityAction"
										[selected]="action"
										(updateDataList)="setActionTag($event)" dropdownPosition="inherit"
										[ngStyle]="{ 'position': 'relative' }"></app-custom-select>
								</div>
							</div>
						</div>
						<!-- <my-date-range-picker class="d-inline-flex" [options]="myDateRangePickerOptions"
					(dateRangeChanged)="onDateRangeChanged($event)">
				  </my-date-range-picker> -->
						<div class="input-group w-50 ml-1 d-inline-flex width-335 dates-div">
							<input class="form-control py-2 border-right-0 background-icon"
								placeholder={{this.admin_data.selectDatesPlaceholder}} type="text" name="daterange"
								autocomplete="off" />
						</div>
						<div class="input-group w-50 ml-2 d-inline-flex width-335 marginRight-15">
							<input id="searchInputApp" class="form-control py-2 search" type="search" autocomplete="off"
								placeholder={{this.admin_data.searchNameIdPlaceholder}} (search)="search()"
								(input)="onInput($event)" aria-label="Search" [(ngModel)]="search_text">
							<!-- <span class="input-group-append">
									<div class="input-group-text bg-white">
										<svg-icon src="../assets/svgs/search-icon.svg"></svg-icon>
									</div>
								</span> -->
						</div>
					</div>
					<div class="tab-content" id="myTabContent">
						<div class="tab-pane fade show active" id="admin_activities" role="tabpanel"
							aria-labelledby="home-tab">

							<app-table [data]="admin_activities" [titles]="activityService.admin_appTitles"
								[widths]="activityService.col_admin_widths"
								[dataKeys]="activityService.admin_appDataKeys" (details)="processData($event)"
								[disable]="actions_to_disable">
							</app-table>
							<div class="d-flex flex-row justify-content-end align-items-baseline">
								<!-- <p *ngIf="admin_pageLimit > 1  && admin_showPagination" class="font12 paginationInfo mb-0">
                                    {{admin_data.showing + " " + (((admin_currentPage-1)*10)+1) + "-"}}
                                    {{ admin_currentPage*10 < admin_total ? admin_currentPage*10 :
                                        admin_total}} {{ " " + admin_data.of + " " + admin_total }}</p> -->
								<ul *ngIf="
                            					admin_activities &&
                            					admin_showPagination
                            				  " class="pagination mt-4 mb-0 float-right">
									<li class="page-item mx-1" [class.disabled]="admin_disablePrev">
										<button class="page-link rounded"
											(click)="prependOffsetAndGetAdminActivities()">
											<svg xmlns="http://www.w3.org/2000/svg" width="9" height="14"
												viewBox="0 0 9 14" fill="none">
												<path
													d="M8.50781 2.0275L3.54615 7L8.50781 11.9725L6.98031 13.5L0.480312 7L6.98031 0.5L8.50781 2.0275Z"
													fill="#8E8FA6" />
											</svg>
										</button>
									</li>
									<li class="page-item mx-1" [class.disabled]="admin_disableNext">
										<button class="page-link rounded" (click)="appendOffsetAndGetAdminActivities()">
											<svg xmlns="http://www.w3.org/2000/svg" width="9" height="14"
												viewBox="0 0 9 14" fill="none">
												<path
													d="M0.492188 11.9725L5.45385 7L0.492188 2.0275L2.01969 0.5L8.51969 7L2.01969 13.5L0.492188 11.9725Z"
													fill="#8E8FA6" />
											</svg>
										</button>
									</li>
								</ul>
							</div>
							<br>
						</div>
						<!-- <div class="tab-pane fade" id="login_details" role="tabpanel" aria-labelledby="profile-tab">
							<app-table [data]="login_details" [titles]="activityService.login_appTitles"
								[widths]="activityService.col_login_widths" [dataKeys]="activityService.login_appDataKeys"
								[anotherOne]="'anotherDataTable'"></app-table>
                                <div *ngIf="login_details?.length > 0 && login_pageLimit > 1  && login_showPagination"
                                    class="d-flex flex-row justify-content-between align-items-baseline">
                                    <p *ngIf="login_pageLimit > 1  && login_showPagination" class="font12 paginationInfo mb-0">
                                        {{admin_data.showing + " " + (((login_currentPage-1)*10)+1) + "-"}}
                                        {{ login_currentPage*10 < login_total ? login_currentPage*10 : login_total}} {{ " " + admin_data.of + " " + login_total
                                            }}</p>
                                            <ul *ngIf="login_details &&
                                					login_showPagination
                                				  " class="pagination mt-4 mb-0 float-right">
                                                <li class="page-item mx-1" [class.disabled]="login_disablePrev">
                                                    <button class="page-link rounded" (click)="prependOffsetAndGetLoginActivities()">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
                                                            <path
                                                                d="M8.50781 2.0275L3.54615 7L8.50781 11.9725L6.98031 13.5L0.480312 7L6.98031 0.5L8.50781 2.0275Z"
                                                                fill="#8E8FA6" />
                                                        </svg>
                                                    </button>
                                                </li>
                                                <ng-container *ngIf="login_pageLimit>5">
                                                    <li class="page-item mx-1" *ngIf="login_showBegin">
                                                        <a class="page-link rounded"
                                                            (click)="setLoginIndex(login_pages[0],$event)">{{login_pages[0]}}</a>
                                                        ...
                                                    </li>
                                                    <ng-container *ngFor="let page of login_pagesinLimit">
                                                        <li class="page-item mx-1" [ngClass]="{ active: page == login_currentPage }">
                                                            <a class="page-link rounded" (click)="setLoginIndex(page,$event)">{{page}}</a>
                                                        </li>
                                                    </ng-container>
                                                    <li class="page-item mx-1" *ngIf="login_showEnd">
                                                        ...
                                                        <a class="page-link rounded"
                                                            (click)="setLoginIndex(login_pages[login_pages.length-1],$event)">{{login_pages[login_pages.length-1]}}</a>
                                                    </li>
                                                </ng-container>
                                                <ng-container *ngIf="login_pageLimit<=5">
                                                    <ng-container *ngFor="let page of login_pages">
                                                        <li class="page-item mx-1" [ngClass]="{ active: page == login_currentPage }">
                                                            <a class="page-link rounded" (click)="setLoginIndex(page,$event)">{{page}}</a>
                                                        </li>
                                                    </ng-container>
                                                </ng-container>
                                                <li class="page-item mx-1" [class.disabled]="login_disableNext">
                                                    <button class="page-link rounded" (click)="appendOffsetAndGetLoginActivities()">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
                                                            <path
                                                                d="M0.492188 11.9725L5.45385 7L0.492188 2.0275L2.01969 0.5L8.51969 7L2.01969 13.5L0.492188 11.9725Z"
                                                                fill="#8E8FA6" />
                                                        </svg>
                                                    </button>
                                                </li>
                                                <li class="d-flex flex-row align-items-baseline">
                                                    <label class="mx-2" for="goto">Go to</label>
                                                    <input style="max-width: 50px;" class="form-control" name="goto" type="number"
                                                        (input)="goToActionLogin($event)">
                                                </li>
                                            </ul>
                                </div>
                                <div *ngIf="login_details && login_isOutsideLimit" class="float-right font12 text-danger">
                                    {{admin_data.pageEnteredOutsideRange}}</div>
                                <br>

						</div> -->
					</div>
				</div>
			</div>
		</section>

		<div class="modal fade" id="detailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="addRoleModalLabel">{{this.admin_data.activityDetails}}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<table class="table table-striped tl-fixed break-w">
							<thead>
								<tr>
									<ng-container *ngFor="let title of modalHeads">
										<th>{{ title }}</th>
									</ng-container>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let row of modalRows">
									<td class="">{{ row["field"] }}</td>
									<td *ngIf="
                      !(
                        row['field'] == 'logo' ||
                        row['field'] == 'widget_icon_path' ||
                        row['field'] == 'first_slide' ||
                        row['field']=='image_path' ||
                        row['field'] == 'last_slide' ||
                        row['field'] == 'widget_icon'
                      ) || row['original_value'] ==''
                    ">
										{{ row["original_value"] | json }}
									</td>
									<td *ngIf="
                      (row['field'] == 'logo' || row['field'] == 'last_slide') && row['original_value'] != ''
                    ">
										<img class="img-fluid w-25" src="{{ row['original_value'] }}{{
                      organisationService.cdn_signature
                      }}" />
									</td>
									<td *ngIf="
                      (row['field'] == 'widget_icon_path' || row['field'] == 'first_slide' || row['field'] == 'widget_icon') &&
                      row['original_value'] != ''
                    ">
										<img class="img-fluid w-25"
											src="{{row['original_value']}}{{organisationService.cdn_signature}}" />
									</td>
									<td *ngIf="row['field']=='image_path' &&
                  row['original_value'] != ''">
										<img class="img-fluid w-25"
											src="{{CDN_BASE_URL}}/{{row['original_value']}}{{organisationService.cdn_signature}}" />
									</td>
									<td *ngIf="
                      !(
                        row['field'] == 'widget_icon_path' ||
                        row['field'] == 'widget_icon' ||
                        row['field'] == 'logo' ||
                        row['field'] == 'image_path' ||
                        row['field'] == 'first_slide' ||
                        row['field'] == 'last_slide'
                       ) || row['new_value'] == ''
                    ">
										{{ row["new_value"] | json }}
									</td>
									<td *ngIf="row['field'] == 'logo' && row['new_value'] != ''">
										<img class="img-fluid w-25" src="{{row['new_value']}}{{
                      organisationService.cdn_signature
                      }}" />
									</td>
									<td *ngIf="
                      (row['field'] == 'widget_icon_path' || row['field'] == 'widget_icon' || row['field'] == 'first_slide' || row['field'] == 'last_slide') &&
                      row['new_value'] != ''
                    ">
										<img class="img-fluid w-25"
											src="{{row['new_value']}}{{organisationService.cdn_signature}}" />
									</td>
									<td *ngIf="row['field']=='image_path'">
										<img class="img-fluid w-25"
											src="{{CDN_BASE_URL}}/{{row['new_value']}}{{organisationService.cdn_signature}}" />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="modal-footer justify-content-end">
						<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">
							{{this.admin_data.close}}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>