<div class="d-flex">
	<div class="container-fluid content-container p-3 toggle-view" [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
		<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>

		<section class="component-section">
           
            <div class="sectionBody">

				<div class="d-flex mb-3">
					<div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
						{{this.admin_data.labelAPIConnector}}
					</div>
					<div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right">
						<button *ngIf="!(userService.user.role=='app_admin')" type="button"
							class="btn btn-primary ml-2"
							[routerLink]="['/organization',userService.organization_id,'api_connector','add']">{{this.admin_data.addToConnector}}
						</button>
					</div>
				</div>

			
				<app-table [data]="api_connectors" [titles]="apiConnectorService.appTitles" [widths]="apiConnectorService.col_widths"
					[dataKeys]="apiConnectorService.appDataKeys" (edit)="performEdit($event)" (delete)="showModal($event)">
				</app-table>
				<div *ngIf="api_connectors" class="modal fade" id="deleteApiModal" tabindex="-1" role="dialog"
					aria-labelledby="deleteApiModalLabel" aria-hidden="true">
					<div class="modal-dialog modal-dialog-centered" role="document">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" id="deleteApiModalLabel">{{this.admin_data.doYouWantToDelete}}</h5>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div *ngIf="api_connectors.length>0" class="modal-body">
								<div class="alert alert-danger m-0" role="alert">
									<b class="text-dark">{{api_connectors[deleteIndex]['api_name']}}</b>
								</div>
							</div>
							<div class="modal-footer justify-content-center">
								<button type="button" class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
									(click)="closeModal()">{{this.admin_data.cancelButtonText}}</button>
								<button [disabled]="deleting" type="button" class="btn btn-danger minWidth-120 mx-2"
									(click)="performDelete(delete_id)">
								<ng-container *ngIf="!deleting">{{this.admin_data.deleteButtonText}}</ng-container>
								<ng-container *ngIf="deleting">{{this.admin_data.deletingButtonText}}</ng-container>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
	</div>
</div>