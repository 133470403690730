import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { API_END_POINTS } from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

    admin_appTitles = [adminLabels.activityTableHeaderActivity, adminLabels.tableHeaderDescription, adminLabels.activityTableHeaderActivityEmailId, adminLabels.tableHeaderModificationDate,
        // adminLabels.activityTableHeaderActivityIPAddress,
        adminLabels.tableHeaderActions];
    admin_appDataKeys = ['', 'activity', 'description', 'user_email', 'modification_date',
        // 'ip_address',
        'details'];
    login_appTitles = [adminLabels.activityTableHeaderActivity, adminLabels.tableHeaderDescription, adminLabels.tableHeaderModificationDate
        // , adminLabels.activityTableHeaderActivityIPAddress
    ];
    login_appDataKeys = ['', 'action', 'data', 'modification_date'
        // , 'ip_address'
    ];

    col_admin_widths = ['14%', '30%', '18%', '15%',
        // '14%',
        '14%']
    col_login_widths = ['15%', '35%', '10%',
        // '10%'
    ];


  constructor(private apiService:ApiService) { }

  getActivity(limit,offset,type,org_id,from_date='',to_date='',search='',action_type='',action=''){
    let urlPath
    if(from_date!='' && to_date!=''){
      urlPath=environment.baseUrl+API_END_POINTS.GET_ACTIVITY+'?limit='+limit+'&offset='+offset+'&type='+type+'&organization_id='+org_id+'&from_date='+from_date+'&to_date='+to_date;
    }else{
    urlPath=environment.baseUrl+API_END_POINTS.GET_ACTIVITY+'?limit='+limit+'&offset='+offset+'&type='+type+'&organization_id='+org_id;
    }
    if(search!=''){
      urlPath+='&search_text='+encodeURIComponent(search);
    }

    if(action_type!='' && action!=''){
      urlPath+='&activity_type='+action_type+'&action='+action;
    }
    return this.apiService.get(urlPath);
  }

    getIndividualActivity(key: string, org_id) {
        let urlPath = environment.baseUrl + API_END_POINTS.GET_INDIVIDUAL_ACTIVITY;
        urlPath += '?organization_id=' + org_id + '&action=' + key;
        return this.apiService.get(urlPath)
        
    }
}
