import { Component, OnInit, HostListener, Input } from "@angular/core";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { DashboardService } from "../services/dashboard.service";
import { UserService } from "../services/user.service";
import { LoaderService } from "../services/loader.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ApiService } from "../services/api.service";
import { OrganisationService } from "../services/organisation.service";
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { environment } from "../../../environments/environment";

declare var jquery: any;
declare var $: any;

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
	closeResult: string;
	userEmail: any = "harsh+stage";
	userProfile: string;
	userId: string;
	imageId: string;

	userLast: string;
	userEmailId: string;
	org_name: string;
	image_id = "1";

	SuperAdmin: String = "false";
	AccessToken: String;
	userSign: string;
	userOrg: string;
	userOrgImg: string;
	orgImgUrl: string = "";

	showFilter: boolean = true;

	userImg: string;
	req: any;
	searchQuery = "";
	_offset = 0;
	localPage = 0;
	page = 1;
	_limit = 10;
	orgList: any = [];
	orgDataFeched;
	selectedOrgId;
	public show_modal = true;
	currentYear: any;
	currentUrl: any;
	orgName: string;
	orgProfile: string;
	switchFlag = false;

	firstTimeLoad = true;
	organizations: any = [];
	totalListLength;
	startPage: any;
	endPage: any;
	ePage: any;
	totalListCount;
	countFlag = false;

	// pagination
	start_page = 1;
	end_page = 0;
	showPagination = false;
	listData;
	initials = "";
	message;
	selectedVal;

	imageOrgId;
	newPassword = "";
	confirmPassword = "";
	currentPassword = "";

	errorFlag_confirm = false;
	errorMsg_confirm = "";

	errorFlag_new = false;
	errorFlag_new_upper = false;
	errorMsg_new = "";
	errorMsg_new_space = "";

	errorMsg_confirm_space = "";

	invalidForm = true;
	errorFlag_current = false;
	errorFlag_new_space = false;
	errorFlag_confirm_space = false;
	//===========================================
	autoClickOneTimeUse = false;

	currentError_msg = "";
	commonError_msg = "";
	adminLink;
	sub;
	sourceState = adminLabels.guideRolePlayer;
	sourceTitle = adminLabels.guideRolePlayer;
	currentRoute;
	insightsUrl = environment.INSIGHTS_URL;
	url_path;
	selectedLanguage = (localStorage.getItem('language') != '') ? localStorage.getItem('language') : "english";
	languageList = [{ value: "english", name: "English" },
	{ value: "frenchCanadian", name: "French(Canada)" },
	{ value: "french", name: "French(France)" },
	{ value: "japanese", name: "Japanese(Japan)" },
	{ value: "korean", name: "Korean(Korea)" }]

	items = {
		a: "#AF9595",
		b: "#EBADAD",
		c: "#FE9469",
		d: "#FFA477",
		e: "#FFA54D",
		f: "#ECC084",
		g: "#C5FF6C",
		h: "#A1C87A",
		i: "#8DE5BA",
		j: "#4E9FFE",
		k: "#D18CFF",
		l: "#FF4DFF",
		m: "#C0FF00",
		n: "#00FF7F",
		o: "#E9967A",
		p: "#FFE5B4",
		q: "#FF66CC",
		r: "#F88379",
		s: "#FBEC5D",
		t: "#FAD6A5",
		u: "#99CCFF",
		v: "#FFFFCC",
		w: "#FFFF99",
		x: "#E0E0E0",
		y: "#A5BBCA",
		z: "#BEA5CA",
	};

	extentionFilterState = [
		{ key: "Player", value: adminLabels.guideRolePlayer },
		{ key: "Creator", value: adminLabels.guideRoleCreator },
		{ key: "Both", value: "Both" },
	];

	showNotifConfig = false;

	changePassword_form = new FormGroup({
		currentPassword: new FormControl(),
		newPassword: new FormControl(),
		confirmPassword: new FormControl(),
	});

	createForm() {
		this.changePassword_form = this.formBuilder.group({
			currentPassword: [""],
			newPassword: [""],
			confirmPassword: [""],
		});
	}

	eventSource_disabled = false;
	navigationRoute;
	param;
	searchQueryFlag = false;

	admin_data = adminLabels;

	constructor(
		private formBuilder: FormBuilder,
		private dbService: DashboardService,
		public userService: UserService,
		public loader: LoaderService,
		private router: Router,
		private route: ActivatedRoute,
		private apiService: ApiService,
		public organisationService: OrganisationService
	) {
		this.userEmail = this.userService.user.user_email;

		// this.selectedLanguage = (localStorage.getItem('language') != '') ? localStorage.getItem('language') : "english";

		// console.log(this.selectedLanguage)

		let self = this;
		// $(document).ready(function () {

		// });

		router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.url_path = window.location.href.includes('maintenance_mode');
			}
		})

	}

	toggleElem;
	sidebarELem;
	toggleViewElem;
	org_id;
	prevOrgName;
	displayPrevOrg = false;
	displayOrgName = false;

	ngOnInit() {
		let self = this;
		this.url_path = window.location.href.includes('maintenance_mode');
	}



	selectTab() { }

	switchOrganization() { }

	imageerror() { }

	logout() {
		this.apiService.logout().subscribe((reponse) => {
			this.userService.user.token = "";
			localStorage.removeItem("token");
			localStorage.removeItem("user");
			localStorage.removeItem("organization");
			localStorage.removeItem("cdn_sign");
			this.router.navigate(["/login"]);
		});
	}
	switch() {
		this.userService.organization_id = "000";
		localStorage.removeItem("organization");
	}


	checkOrgId() {
		let url = this.router.url;
		this.prevOrgName = JSON.parse(JSON.stringify(this.userService.organization.name));
		if (this.userService.user.role == 'superadmin') {
			if (url.includes(this.userService.organization_id)) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	scrollBottom() {
		setTimeout(() => {
			let elem = document.getElementsByClassName("dropdown-size")[0];
			elem.scrollTo(0, elem.scrollHeight);
		}, 0);
	}

	setDefaultPic() {
		this.image_id = "0";
	}

	preventClose() {
		event.stopPropagation();
	}

	getRole() {
		switch (this.userService.user.role) {
			case 'creator':
				return 'Creator';
			case 'owner':
				return 'Org Admin';
			case 'user':
				return 'Player';
			case 'app_admin':
				return 'App Admin';
			case 'mg_admin':
				return 'MG Admin';
			case 'superadmin':
				return 'Super Admin';
			case 'partner_admin':
				return 'Partner Admin';
			default:
				return this.userService.user.role;
		}
	}

	updateLanguage() {
		// console.log(this.selectedLanguage)
		this.loader.setVariable(true);
		this.loader.setLanguage(this.selectedLanguage)
		let request = JSON.parse(localStorage.getItem("user"));
		let settings = JSON.parse(request['settings'])
		// console.log(settings["preferredLanguage"]["UILanguagePreference"]);
		delete request['role']
		delete request['token']
		delete request['refreshtoken']
		delete request['org_role_id']
		delete request['app_access']
		// delete request['first_name']
		delete request['is_locked']
		delete request['is_paid']
		delete request['is_password_expired']
		delete request['is_player_request']
		delete request['is_suspend']
		delete request['is_verified']
		delete request['keep_login']
		// delete request['last_name']
		delete request['modification_date']
		delete request['org_ids']
		delete request['partner_id']
		delete request['profile']
		delete request['track_failed_login_attempt']
		delete request['user_key']
		delete request['accesstoken']
		delete request['creation_date']
		delete request['image_url']
		delete request['user_email']
		// delete request['user_id']
		delete request['image_id']
		//userid,imageid

		if (settings["preferredLanguage"] == undefined) {
			settings["preferredLanguage"] = {}
		}
		switch (this.selectedLanguage) {
			case 'english':
				settings["preferredLanguage"]["UILanguagePreference"] = 'en_US'
				request['settings'] = JSON.stringify(settings);
				this.userService.editUser(request).subscribe((response) => {
					localStorage.setItem("language", "english");
					location.reload()
				})
				// updateTranslation('english');
				break;
			case 'frenchCanadian':
				settings["preferredLanguage"]["UILanguagePreference"] = 'fr_CA'
				request['settings'] = JSON.stringify(settings);
				this.userService.editUser(request).subscribe((response) => {
					localStorage.setItem("language", "frenchCanadian");
					location.reload()
				})
				// updateTranslation('frenchCanadian');
				break;
			case 'french':
				settings["preferredLanguage"]["UILanguagePreference"] = 'fr_FR'
				request['settings'] = JSON.stringify(settings);
				this.userService.editUser(request).subscribe((response) => {
					localStorage.setItem("language", "french");
					location.reload()
				})
				// updateTranslation('french');
				break;
			case 'japanese':
				settings["preferredLanguage"]["UILanguagePreference"] = 'ja_JP'
				request['settings'] = JSON.stringify(settings);
				this.userService.editUser(request).subscribe((response) => {
					localStorage.setItem("language", "japanese");
					location.reload()
				})
				// updateTranslation('japanese');
				break;
			case 'korean':
				settings["preferredLanguage"]["UILanguagePreference"] = 'ko_KR'
				request['settings'] = JSON.stringify(settings);
				this.userService.editUser(request).subscribe((response) => {
					localStorage.setItem("language", "korean");
					location.reload()
				})
				// updateTranslation('korean');
				break;
			default:
				settings["preferredLanguage"]["UILanguagePreference"] = 'en_US'
				request['settings'] = JSON.stringify(settings);
				this.userService.editUser(request).subscribe((response) => {
					localStorage.setItem("language", "english");
					location.reload()
				})
				// updateTranslation('english');
				break;
		}
	}

	redirectToInsights() {
		let token = localStorage.getItem('token');
		token = JSON.parse(token);
		const accessToken = token['accesstoken'];
		const refreshToken = token['refreshtoken'];

		this.userService.generateHandoffToken(accessToken, refreshToken).subscribe(
			(response1) => {
				if (this.userService.user.role == 'creator') {
					return window.open(this.insightsUrl + '/#/survey/manage-survey/?' + 'handoff-token=' + response1["data"]["handoff-token"], "_self");
				} else {
					return window.open(this.insightsUrl + '/#/dashboard/?' + 'handoff-token=' + response1["data"]["handoff-token"], "_blank");
				}
			});
	}

	showHelpUrl() {
		let role = this.userService.user.role;
		if (role == 'owner' || role == 'app_admin' || role == 'mg_admin') {
			return true;
		}
		return false;
	}

	ensureHttps(url) {
		if (!/^https?:\/\//i.test(url)) {
			url = 'https://' + url;
		}
		return url;
	}

	redirectToHelpUrl() {
		let orgSetting = (JSON.stringify(this.userService.organization.settings));
		let tempSetting = JSON.parse(orgSetting);
		if (tempSetting['helpUrl'] != undefined) {
			if (tempSetting['helpUrl'].substring())
				return window.open(this.ensureHttps(tempSetting['helpUrl']), "_blank");
		} else {
			return window.open("https://help.csod.com/MyGuide/", "_blank");
		}
		return '';
	}
}