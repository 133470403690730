import adminLabels from "../constants/ApplicationStrings/trans_utils";
export const DOMAIN_FORMADATA = [
  {
    type: "text",
    name: "domain",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14 h-120",
    layoutModal: true,
    validators: [
      {
        required: true,
        maxLength: 250,
      },
      // {
      //   regex: /[A-Za-z\d]{1,61}\.[a-zA-Z\d]{2,}/g,
      // },
    ],
    ui: {
      label: "Domain:*",
      class: "col-lg-4",
      inputclass: "col-lg-8",
    },
    input: {
      id: "domain",
      class: "form-control",
      type: "password",
      name: "password",
      placeholder: adminLabels.domains,
      disabled:false
    },
    label: {
      title: adminLabels.domainsAddDomainButtonText,
      class: "font-weight-bold label-asterisk",
      for: "domain",
    },
  },
];
