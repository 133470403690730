import adminLabels from "../ApplicationStrings/trans_utils";

export const ADD_APPLICATION_FORMDATA = [
    {
        type: "file",
        name: "app_image",
        value: "",
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
        fileclass: "img-fluid border",
        source: "../assets/images/image.png",
        validators: [],
        ui: {
            label: adminLabels.addApplicationUploadThumbnail,
            class: "font-weight-bold font14",
            inputclass: "w-50 text-center",
        },
    },
    {
        type: "text",
        name: "title",
        value: "",
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
        validators: [
            {
                required: true,
                maxLength: 120,
            },
        ],
        ui: {
            label: adminLabels.applicationsTableHeaderApplicationName,
            class: "col-lg-4 font14",
            inputclass: "col-lg-8 ",
        },
        input: {
            id: "title",
            class: "form-control font14",
            type: "text",
            name: "title",
            placeholder: adminLabels.applicationsTableHeaderApplicationName,
        },
        label: {
            title: adminLabels.applicationsTableHeaderApplicationName,
            class: "font-weight-bold font14 label-asterisk",
            for: "title",
        },
    },
    {
        type: "textarea",
        name: "description",
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
        value: "",
        validators: [
            {
                maxLength:3000,
            },
        ],
        ui: {
            label: adminLabels.tableHeaderDescription,
            class: "font-weight-bold font14",
            inputclass: "form-control",
        },
    },
    {
        type: "select",
        name: "type",
        value: "",
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
        options: [
            {
                value: "guide",
                name: "Web",
            },
            {
                value: "electron",
                name: "Desktop",
            },
            {
                value: "android",
                name: "Android",
            },
            {
                value: "ios",
                name: "IOS",
            },
            {
                value: "mobile_web",
                name: "Mobile Web"
            }
        ],
        validators: [
            {
                required: true,
            },
        ],
        ui: {
            label: adminLabels.tableHeaderType,
            class: "font-weight-bold font14",
            inputclass: "form-control",
        },
    },
    {
        type: "checkbox",
        name: "isAutomationEnabled",
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 d-flex align-items-center font14",
        value: "",
        checked: true,
        validators: [],
        ui: {
          label: adminLabels.addApplicationGuideAutomation,
          class: "font-weight-bold mb-0",
        },
      },
];
