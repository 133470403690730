import { Component, OnInit, ElementRef, Input } from '@angular/core';
import {SVG_SOURCE} from '../svgSource';
import adminLabels from '../constants/ApplicationStrings/trans_utils';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css']
})
export class IconComponent implements OnInit {

  @Input()
  set name(iconName:string){
    this.element.nativeElement.innerHTML=SVG_SOURCE[iconName] || null
  }

  admin_data = adminLabels ;

  constructor(private element:ElementRef) { }

  ngOnInit() {
  }

}
