import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API_END_POINTS } from 'src/app/constants/api-end-points';
import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class GuidesService {

    constructor(private apiService: ApiService,
        private http: HttpClient) { }

    getCategoryList(limit, offset, app_id, parent_id) {

        let urlPath =
            environment.baseUrl + API_END_POINTS.GET_CATEGORY_LIST +
            "?application_id=" + app_id +
            "&limit=" + limit +
            "&offset=" + offset +
            "&parent_id=" + parent_id;

        return this.apiService.get(urlPath)
    }

    exportGuides(request){
        let urlPath = 
            environment.baseUrl + API_END_POINTS.BULK_EXPORT_GUIDES
        return this.apiService.post(urlPath, request);
    }

    pasteGuides(request){
        let urlPath = 
            environment.baseUrl +API_END_POINTS.BULK_PASTE_GUIDES
        return this.apiService.post(urlPath,request);
    }

    getUploadedFile(org_id) {
		let urlPath =
			environment.baseUrl + API_END_POINTS.GET_UPLOAD_FILE
			+ "?organization_id=" +
			org_id +
			"&file_type=json&mime=" +
			encodeURIComponent("text/json");
		
		
		return this.apiService.get(urlPath);
	}

    importGuides(request){
        let urlPath = 
            environment.baseUrl +API_END_POINTS.BULK_IMPORT_GUIDES
        return this.apiService.post(urlPath,request);
    }

    getImportedGuides(org_id){
        let urlPath =
            environment.baseUrl + API_END_POINTS.GET_ORGANIZATION_TASK_STATUS +
            "?limit=100" +
            "&offset=0" +
            "&organization_id=" +
            parseInt(org_id) + "&task_type=bulk_tour_import";
        return this.apiService.get(urlPath);        
    }

    getExportedGuides(org_id){
        let urlPath =
            environment.baseUrl + API_END_POINTS.GET_ORGANIZATION_TASK_STATUS +
            "?limit=100" +
            "&offset=0" +
            "&organization_id=" +
            parseInt(org_id) + "&task_type=bulk_tour_export";
        return this.apiService.get(urlPath);        
    }

    downloadfile(url){
        
        let options = this.apiService.getHeader();
        options['responseType'] = 'blob'     
        return this.http.get(url, {
            responseType: 'blob'
          })
    }

}
