import adminLabels from "../ApplicationStrings/trans_utils";

export const SEGMENT_FORMADATA = [
  {
    type: "text",
    name: "name",
    value: "",
    layoutModal: true,
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2",
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.segmentData,
      class: "col-lg-4",
      inputclass: "col-lg-8",
    },
    input: {
      id: "name",
      class: "form-control",
      type: "text",
      name: "name",
      placeholder: adminLabels.segmentData,
    },
    label: {
      title: adminLabels.segmentData,
      class: "font-weight-bold",
      for: "name",
    },
  },
  {
    type: "checkbox",
    name: 'is_default',
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 d-flex align-items-center font14",
    value: "",
    checked: false,
    validators: [],
    ui: {
      label: adminLabels.segmentsListTableHeaderIsDefault,
      class: "font-weight-bold mb-0",
      inputclass: "primary",
    },
  }
];

