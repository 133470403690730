<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">

            <div class="sectionBody">
                <div class="d-flex mb-3">
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                        {{this.admin_data.adminSettings}}
                    </div>
                </div>

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" (click)="changeTab(1)">
                        <a class="nav-link custom-nav-link active" id="home-tab" data-toggle="tab"
                            href="#feature_settings" role="tab" aria-controls="home"
                            aria-selected="true">{{this.admin_data.featureSettings}}</a>
                    </li>
                    <li *ngIf="globalSettingMapper.size > 0 ? getInsightsSettingVisibility('insights') : true"
                        class="nav-item" (click)="changeTab(2)">
                        <a class="nav-link custom-nav-link" id="home-tab" data-toggle="tab" href="#insight_settings"
                            role="tab" aria-controls="home"
                            aria-selected="true">{{this.admin_data.insightsSettings}}</a>
                    </li>
                    <li class="nav-item" (click)="changeTab(3)">
                        <a class="nav-link custom-nav-link" id="profile-tab" data-toggle="tab" href="#limit_settings"
                            role="tab" aria-controls="profile"
                            aria-selected="false">{{this.admin_data.limitSettings}}</a>
                    </li>
                    <li class="nav-item" (click)="changeTab(4)">
                        <a class="nav-link custom-nav-link" id="profile-tab" data-toggle="tab"
                            href="#language_translations" role="tab" aria-controls="profile"
                            aria-selected="false">{{this.admin_data.languageTranslationsSettings}}</a>
                    </li>
                    <li *ngIf="organizationProductTypeEnterprise && betaFeatureHolder.length > 0" class="nav-item"
                        (click)="changeTab(5)">
                        <a class="nav-link custom-nav-link" id="profile-tab" data-toggle="tab" href="#beta-settings"
                            role="tab" aria-controls="profile"
                            aria-selected="false">{{this.admin_data.betaFeatures}}</a>
                    </li>
                </ul>
                <div class="tab-container px-2 pt-4 pb-0 clearfix">
                    <form *ngIf="formGroup" [formGroup]="formGroup">
                        <div class="form-group">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="feature_settings" role="tabpanel"
                                    aria-labelledby="home-tab">
                                    <div class="row m-0">
                                        <ng-container
                                            *ngFor="let form of admin_settings_formdata.attributes.slice(0,feature_end_index);let j=index">
                                            <ng-container *ngIf="getFeatureSettingVisibility(form.name)">
                                                <div class="{{form.class}}"
                                                    *ngIf="form.type=='text' && form.name!='ftp_endpoint'">
                                                    <div>
                                                        <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                                        <svg-icon src="../../../assets/svgs/icon-info.svg"
                                                            class="ml-2 infoIcon" data-toggle="tooltip"
                                                            data-placement="bottom" title="{{form.tooltip}}"></svg-icon>
                                                        <input class="{{form.ui.inputclass}}" type="{{form.type}}"
                                                            autocomplete="off" formControlName="{{form.name}}"
                                                            [attr.disabled]="form.disable?form.disable:null">
                                                    </div>
                                                    <div *ngIf="formGroup.get(form.name).invalid"
                                                        class="height12px font12">
                                                        <div *ngIf="formGroup.get(form.name).hasError('required')"
                                                            class="error color">{{form.ui.label}}
                                                            {{this.admin_data.isRequired}}</div>
                                                        <div class="error color pb-2"
                                                            *ngIf="formGroup['controls'][form.name].hasError('pattern')">
                                                            {{this.admin_data.patternInvalid}}</div>
                                                    </div>
                                                </div>
                                                <div class="{{form.class}}" *ngIf="form.type=='number'">
                                                    <div class="numberRow">
                                                        <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                                        <br />
                                                        <!-- <span class="text-secondary font12 width-80">{{form.tooltip}}</span> -->
                                                        <input class="{{form.ui.inputclass}}" type="{{form.type}}"
                                                            autocomplete="off" formControlName="{{form.name}}"
                                                            min="3600"
                                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                            [maxLength]="form.ui.maxLength?form.ui.maxLength:5">
                                                    </div>
                                                    <!-- <div *ngIf="formGroup.get(form.name).invalid" class="height12px font12">
                                                    <div *ngIf="formGroup.get(form.name).hasError('required')" class="error color">Enter a value more than 3600 secs.</div>
                                                        <div class="error color pb-2"
                                                        *ngIf="formGroup['controls'][form.name].hasError('pattern')">
                                                        Pattern is invalid</div>
                                                </div> -->
                                                </div>
                                                <div class="{{form.class}}" *ngIf="form.type=='password'">
                                                    <div>
                                                        <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                                        <input class="{{form.ui.inputclass}}" type="{{form.type}}"
                                                            autocomplete="off" formControlName="{{form.name}}">
                                                    </div>
                                                    <div class="height12px font12">
                                                        <div *ngIf="formGroup['controls'][form.name].invalid"
                                                            class="error color">{{form.ui.label}}
                                                            {{this.admin_data.isRequired}}</div>
                                                    </div>
                                                </div>
                                                <div class="{{form.class}}" *ngIf="form.type=='select'">
                                                    <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                                    <select class="{{form.ui.inputclass}}"
                                                        formControlName="{{form.name}}">
                                                        <option *ngFor="let option of form['options']"
                                                            value="{{option.value}}">
                                                            {{option.name}}</option>
                                                    </select>
                                                    <div class="height12px font12">
                                                        <div *ngIf="formGroup['controls'][form.name].invalid"
                                                            class="error color">{{form.ui.label}}
                                                            {{this.admin_data.isRequired}}</div>
                                                    </div>
                                                </div>
                                                <div class="{{form.class}}" *ngIf="form.type=='textarea'">
                                                    <label class="{{form.ui.class}}">{{form.ui.label}}:</label>
                                                    <textarea class="{{form.ui.inputclass}} resize-none"
                                                        formControlName="{{form.name}}" rows="6" cols="50"></textarea>
                                                </div>
                                                <div class="{{form.class}}" *ngIf="form.type=='radio'">
                                                    <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                                    <ng-container *ngFor="let val of form.value">
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" class="custom-control-input"
                                                                type="{{form.type}}" id="{{val}}"
                                                                formControlName="{{form.name}}" name="{{form.name}}"
                                                                (keyup)="seeChanges()" value="{{val}}">
                                                            <label class="custom-control-label"
                                                                for="{{val}}">{{val}}</label>
                                                        </div>
                                                    </ng-container>
                                                    <div class="height12px font12">
                                                        <div *ngIf="formGroup['controls'][form.name].invalid"
                                                            class="error color fs-small">{{form.ui.label}}
                                                            {{this.admin_data.isRequired}}</div>
                                                    </div>
                                                </div>
                                                <div class="{{form.class}} label-switch-container"
                                                    [ngClass]="{'pt-2 pt-sm-2 pt-md-2 pt-lg-4 pt-xl-4 rounded-top': j == 0 || j == 1}"
                                                    *ngIf="form.type=='checkbox' && form.name!='on_premise_deployment'">
                                                    <!-- <div *ngIf="form.name == 'hash_player_pii'" class="alert alert-warning" role="alert">
                                                  <b>Warning!</b> Once <b>"{{this.admin_data.adminSettingsHashPlayersData}}"</b> is enabled it cannot be disabled.
                                                </div> -->
                                                    <div class="label-switch-content">
                                                        <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                                        <br />
                                                        <span
                                                            class="text-secondary font12 width-80">{{form.tooltip}}</span>
                                                        <ng-container *ngIf="form.history" class="">
                                                            <span class="modal-link"
                                                                (click)="openActivityModal(form.name)">{{this.admin_data.viewActivityDetails}}</span>
                                                        </ng-container>
                                                        <label class="switch mx-2 mb-0">
                                                            <input class="{{form.ui.inputclass}}" type="{{form.type}}"
                                                                formControlName="{{form.name}}"
                                                                [checked]="form.name==='media_file_notifications'?  (formGroup['controls'][form.name].value==='0'?false:true): (formGroup['controls'][form.name].value==1?true:false)"
                                                                [value]="form.checked==true?1:0"
                                                                (click)="form.name!='create_tooltip_media_file'?toggle(j,$event.target.checked):toggle(j,$event.target.checked,$event)">
                                                            <span class="slider round"></span>
                                                        </label>
                                                        <div *ngIf="form.ui.message!=undefined" class="error color">
                                                            {{form.ui.message}}
                                                        </div>
                                                    </div>

                                                    <!-- <input class="{{form.ui.inputclass}}" type="checkbox"
                                                        formControlName="{{form.name}}"
                                                        [checked]="form.name==='media_file_notifications'?  (formGroup['controls'][form.name].value==='0'?false:true): (formGroup['controls'][form.name].value==1?true:false)"
                                                        [value]="form.checked==true?1:0"
                                                        (click)="form.name!='create_tooltip_media_file'?toggle(j,$event.target.checked):toggle(j,$event.target.checked,$event)">
                                                    <span class="slider round"
                                                        (click)="$event.stopPropagation()"></span> -->
                                                </div>
                                                <div class="{{form.class}}" *ngIf="form.type=='file'">
                                                    <label
                                                        class="{{form.ui.class}} float-left">{{form.ui.label}}:</label>
                                                    <div
                                                        class="{{form.otherdivclass}} float-left border border-dark text-center">
                                                        <div class="h-100 w-30 m-auto">
                                                            <img class="{{form.fileclass}}" [src]="form.source">
                                                        </div>
                                                        <div class="{{form.ui.inputclass}}">

                                                            <input id="fileInput" class="m-auto d-none"
                                                                type="{{form.type}}" formControlName="{{form.name}}"
                                                                name="{{form.name}}" (keyup)="seeChanges()"
                                                                value="{{val}}"
                                                                (change)="showFile($event.target.files,form)">
                                                            <button type="button"
                                                                class="btn btn-secondary btn-choose-img m-auto bg-white"
                                                                (click)="clickFileInput()">{{this.admin_data.chooseImage}}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="form.type=='button'">
                                                    <div class="float-left w-50px mr-100px"
                                                        *ngIf="form.ui.label=='Save'">
                                                        <input type="{{form.type}}" class="{{form.class}}"
                                                            value="{{form.ui.label}}" [disabled]="formGroup.invalid">
                                                    </div>
                                                    <div class="float-left w-50px mr-100px"
                                                        *ngIf="form.ui.label=='Cancel'">
                                                        <input type="{{form.type}}" class="{{form.class}}"
                                                            value="{{form.ui.label}}">
                                                    </div>
                                                </div>
                                                <!-- <ng-container *ngIf="form.name=='maintenance_mode'">
                                                <div class="{{form.class}}" *ngIf="form.type=='checkbox'">
                                                    <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                                    <label class="switch mx-2 mb-0">
                                                        <input class="{{form.ui.inputclass}}" formControlName="{{form.name}}"
                                                            type="{{form.type}}"
                                                            [checked]="formGroup['controls'][form.name].value==1?true:false"
                                                            [value]="form.checked?1:0" (click)="toggle(j,$event.target.checked)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                    <small class="d-flex error color mt-2">
                                                        {{this.admin_data.adminSettingsWarning}}
                                                    </small>
                                                </div>
                                            </ng-container> -->
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="insight_settings" role="tabpanel"
                                    aria-labelledby="profile-tab">
                                    <div class="row m-0">
                                        <ng-container
                                            *ngFor="let form of admin_settings_formdata.attributes.slice(feature_end_index,insight_end_index);let K=index">
                                            <ng-container
                                                *ngIf="['insights','guide_insights','real_time_stats', 'business_intelligence_tools_integration'].includes(form.name)?getInsightsSettingVisibility(form.name):true">
                                                <ng-container *ngIf="checkRole(form.name)">
                                                    <div class="{{form.class}}"
                                                        [ngClass]="{'pt-2 pt-sm-2 pt-md-2 pt-lg-4 pt-xl-4 rounded-top': j == 0 || j == 1}"
                                                        *ngIf="form.type=='checkbox'">
                                                        <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                                        <br />
                                                        <span class="text-secondary font12 width-80">{{form.tooltip}}
                                                            <span
                                                                class="error color pt-1">{{form.ui.message}}</span></span>
                                                        <ng-container *ngIf="form.history" class="">
                                                            <span class="modal-link"
                                                                (click)="openActivityModal(form.name)">{{this.admin_data.viewActivityDetails}}</span>
                                                        </ng-container>
                                                        <label class="switch mx-2 mb-0">
                                                            <input class="{{form.ui.inputclass}}" type="{{form.type}}"
                                                                formControlName="{{form.name}}"
                                                                [checked]="formGroup['controls'][form.name].value==1?true:false"
                                                                [value]="form.checked==true?1:0"
                                                                (click)="toggle(K+feature_end_index,$event.target.checked)">
                                                            <span class="slider round"></span>
                                                        </label>
                                                        <!-- <div *ngIf="form.ui.message!=undefined" class="error color pt-1">
                                                        {{form.ui.message}}
                                                    </div> -->
                                                    </div>
                                                </ng-container>
                                                <div *ngIf="form.type=='heading'" class="{{form.class}}">
                                                    <span class="{{form.text_class}}">{{form.ui.label}}</span>
                                                    <br />
                                                    <span class="text-secondary font12">{{form.tooltip}}</span>
                                                </div>
                                                <div class="{{form.class}} selectRow" *ngIf="form.type=='select'">
                                                    <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                                    <!-- <select class="{{form.ui.inputclass}}"
                                                        formControlName="{{form.name}}">
                                                        <option *ngFor="let option of form['options']"
                                                            value="{{option.value}}">
                                                            {{option.name}}</option>
                                                    </select> -->
                                                    <div class="width-30-percent">
                                                        <app-custom-select formControlName="{{form.name}}"
                                                        [dataList]="form['options']"
                                                        componentName="adminSetting"
                                                        dropdownPosition="relative"></app-custom-select>
                                                    </div>
                                                    
                                                    <div class="height12px font12">
                                                        <div *ngIf="formGroup['controls'][form.name].invalid"
                                                            class="error color">
                                                            {{form.ui.label}}{{this.admin_data.isRequired}}</div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="limit_settings" role="tabpanel"
                                    aria-labelledby="profile-tab">
                                    <ng-container *ngIf="admin_settings_formdata.attributes[insight_end_index]">
                                        <div
                                            *ngIf="admin_settings_formdata.attributes[insight_end_index].formtype=='admin_inline'">
                                            <div class="row m-0 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50">
                                                <ng-container
                                                    *ngFor="let form of admin_settings_formdata.attributes.slice(insight_end_index ,admin_settings_formdata.attributes.length);let j=index">
                                                    <div class="{{form.class}}"
                                                        *ngIf="form.type=='inline_text'||form.type=='number'">
                                                        <div>
                                                            <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                                            <input class="{{form.ui.inputclass}}" type="{{form.type}}"
                                                                autocomplete="off" formControlName="{{form.name}}"
                                                                min='0' (keypress)='getKeyVal($event)'
                                                                [maxLength]="form.ui.maxLength?form.ui.maxLength:5">
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="tab-pane fade" id="language_translations" role="tabpanel"
                                    aria-labelledby="profile-tab">
                                    <div formGroupName="language_settings">
                                        <div class="alert alert-secondary mb-4 font14" role="alert">
                                            <b class="font14">{{this.admin_data.languageTranslationsSettings}}</b>
                                            <br>
                                            {{this.admin_data.englishDefault}}
                                            <br>
                                            {{this.admin_data.languageEdited}}
                                        </div>
                                        <div class="row m-0 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50">
                                            <ng-container formGroupName="default">
                                                <div class="col-lg-6 my-2 zIndex-3">
                                                    <label
                                                        class="font-weight-bold font14">{{this.admin_data.defaultLanguage}}</label>
                                                    <!-- <select class="form-control font14" formControlName="language"
                                                        (change)="selectVoice($event.target)">
                                                        <option value='' disabled>{{this.admin_data.pleaseSelect}}
                                                        </option>
                                                        <option
                                                            *ngFor="let language of language_settings[0];let m=index"
                                                            [value]="language['value']" [selected]="this.formGroup
                                                            .get('language_settings')
                                                            .get('default')
                                                            .get('name')
                                                            .value==language['value']">
                                                            {{language['name']}}</option>
                                                    </select> -->
                                                    <app-custom-select preSelectOption="true" formControlName="language"
                                                        [dataList]="language_settings[0]"
                                                        (updateDataList)="selectVoice($event)"
                                                        [selected]="this.formGroup.get('language_settings').get('default').get('language').value"
                                                        componentName="adminDefaultLanguageSetting"
                                                        dropdownPosition="inherit"></app-custom-select>
                                                </div>
                                                <div class="col-lg-6 my-2 zIndex-3">
                                                    <label
                                                        class="font-weight-bold font14">{{this.admin_data.defaultVoice}}</label>
                                                    <!-- <select class="form-control font14" formControlName="voice">
                                                        <option value='' disabled>{{this.admin_data.pleaseSelect}}
                                                        </option>
                                                        <option *ngFor="let voice of default_voice | orderBy:'display'"
                                                            [value]="voice.valueData"
                                                            [selected]="this.formGroup.get('language_settings').get('default').get('voice').value === voice['valueData']">
                                                            {{voice.display}}</option>
                                                    </select> -->

                                                    <app-custom-select preSelectOption="true" formControlName="voice"
                                                        [dataList]="getSortedByDisplay(default_voice)"
                                                        [selected]="this.formGroup.get('language_settings').get('default').get('voice').value"
                                                        componentName="adminVoiceSetting"
                                                        dropdownPosition="inherit"></app-custom-select>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <label
                                            *ngIf="formGroup.get('language_settings').get('default').get('name').value.includes('English')"
                                            class="font-weight-bold mb-0 mt-4 font14">{{this.admin_data.moreLanguage}}</label>
                                        <div *ngIf="formGroup.get('language_settings').get('default').get('name').value.includes('English')"
                                            class="col-lg-1 d-md-inline-block align-items-center justify-content-center"
                                            (click)="addMoreSettings(this.formGroup.get('language_settings').get('default').get('name').value);$event.stopPropagation()">
                                            <svg-icon src="../../../assets/svgs/add_lang.svg" class="cursor pointer">
                                            </svg-icon>
                                        </div>

                                        <ng-container
                                            *ngIf="formGroup.get('language_settings').get('translations')['controls'].length>0"
                                            formArrayName="translations">
                                            <div *ngFor="let sel of more_settings[0];let h=index" class="row"
                                                [formGroupName]="h">

                                                <ng-container *ngIf="flag" style="display:flex">
                                                    <div class="col-lg-6 my-2 zIndex-3">
                                                        <!-- <select class="form-control font14"
                                                            formControlName="language"
                                                            (change)="selectTranslationVoice($event.target.value,$event.target,h)">
                                                            <option value='' disabled>{{this.admin_data.pleaseSelect}}
                                                            </option>
                                                            <option [disabled]="checkDuplicates(option['value'])"
                                                                *ngFor="let option of more_settings[0][h]"
                                                                [value]="option['value']"
                                                                [selected]="formGroup.get('language_settings').get('translations')['controls'].length>0?formGroup.get('language_settings').get('translations')['controls'][h].get('language').value==option['value']:''">
                                                                {{option.name}}</option>
                                                        </select> -->

                                                       
                                                            <app-custom-select #lang{{h}} formControlName="language"
                                                            preSelectOption="true" [dataList]="more_settings[0][h]"
                                                            [selected]="formGroup.get('language_settings').get('translations')['controls'].length>0??formGroup.get('language_settings').get('translations')['controls'][h].get('language').value"
                                                            (updateDataList)="selectTranslationVoice($event, h)"
                                                            [disabledSubItems] = "existingLanguage()"
                                                            isDisabledSubItem="true"
                                                            componentName="adminLanguageSetting"
                                                            dropdownPosition="inherit"></app-custom-select>
                                                        
                                                        
                                                    </div>
                                                    <div class="col-lg-4 my-2 font14">
                                                        <!-- <select class="font14 form-control" formControlName="voice">
                                                            <option value='' disabled>{{this.admin_data.pleaseSelect}}
                                                            </option>
                                                            <option
                                                                *ngFor="let option of more_settings[1][h] | orderBy:'display'"
                                                                [value]="option['valueData']"
                                                                [selected]="formGroup.get('language_settings').get('translations')['controls'][h].get('voice').value==option['valueData']">
                                                                {{option.display}}</option>
                                                        </select> -->
                                                        <app-custom-select formControlName="voice"
                                                            preSelectOption="true"
                                                            [dataList]="getSortedByDisplay(more_settings[1][h])"
                                                            [selected]="formGroup.get('language_settings').get('translations')['controls'][h].get('voice').value"
                                                            componentName="adminVoiceSetting"
                                                            dropdownPosition="inherit"></app-custom-select>
                                                    </div>
                                                    <div class="d-none font14">
                                                        <input type="text" autocomplete="off" formControlName="name"
                                                            [value]="selectedLanguageNames[h]">
                                                    </div>
                                                    <div
                                                        class="col-lg-1 d-flex align-items-center justify-content-center">
                                                        <svg-icon src="../../../assets/svgs/emit_delete.svg"
                                                            class="cursor pointer"
                                                            (click)="deleteMoreSettings(h);$event.stopPropagation()">
                                                        </svg-icon>
                                                    </div>
                                                </ng-container>

                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="beta-settings" role="tabpanel">
                                    <div class="bg-checkbox">
                                        <ng-container *ngFor="let feature of betaFeatureHolder">
                                            <div
                                                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2  my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14">
                                                <label class="{{feature.ui.class}}">{{feature.ui.label}}</label>
                                                <br />
                                                <span class="text-secondary font12 width-80">{{feature.tooltip}}</span>
                                                <label class="switch mx-2 mb-0">
                                                    <input class="{{feature.ui.inputclass}}" type="{{feature.type}}"
                                                        formControlName="{{feature.name}}"
                                                        [checked]="formGroup['controls'][feature.name].value==='0'?false:true"
                                                        [value]="feature.checked==true?1:0"
                                                        (click)="toggleFeature(feature.name, $event.target.checked)">
                                                    <span class="slider round"
                                                        (click)="$event.stopPropagation()"></span>
                                                </label>
                                                <div *ngIf="feature.ui.message!=undefined" class="error color">
                                                    {{feature.ui.message}}
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div
                                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center py-4">
                                <!-- <button type="button" class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
                                    (click)="unassign()">{{this.admin_data.cancelButtonText}}</button>
                                <button type="button"  [disabled]="this.formGroup.get('language_settings')?!this.formGroup.get('language_settings').valid:''" class="btn btn-primary minWidth-120 mx-2" (click)="submitSettings()">Save
                                    Settings</button> -->
                                <button type="button" class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
                                    (click)="unassign()">{{this.admin_data.cancelButtonText}}
                                </button>
                                <app-button
                                    [disabled]="(this.formGroup.get('language_settings')?this.formGroup.get('language_settings').get('default').get('name').value?(this.formGroup.get('language_settings').get('default').get('name').value && !this.formGroup.get('language_settings').get('default').get('voice').value) || !this.formGroup.get('language_settings').valid:'':'')"
                                    [buttonData]="adminSetting.save_button"
                                    (clickhandler)="submitSettings()"></app-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>

    </div>

    <div class="modal fade" id="warningmodal" tabindex="-1" role="dialog" aria-labelledby="warningmodal"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ng-container>
                    <div class="modal-body">
                        {{this.admin_data.adminSettingsWarning}}
                    </div>
                </ng-container>

            </div>
        </div>
    </div>
    <!-- Start of Confirmation Modal for PII  -->
    <div class="modal" id="confirm_modal" tabindex="-1" role="dialog" aria-labelledby="confirm_modal"
        data-backdrop="static" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 *ngIf="hash_modal" class="modal-title">{{this.admin_data.adminSettingsHashPlayersData}}</h5>
                    <h5 *ngIf="automation_settings" class="modal-title">{{this.admin_data.guideOrWorkflowAutomation}}
                    </h5>
                    <button (click)="resetAndClose()" type="button" class="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body font14">
                    <p *ngIf="hash_modal" class='font14'>{{this.admin_data.adminSettingsEnableSure}}
                        <b>{{this.admin_data.adminSettingsHashPlayersData}}?</b>
                        <br /> {{this.admin_data.cannotBeDisabled}}
                        <br /> {{this.admin_data.okToApply}}
                    </p>
                    <p *ngIf="automation_settings" class='font14'> {{this.admin_data.adminSettingsYouCanEither}}
                        <b>{{this.admin_data.adminSettingsGuideAutomation}}</b> or
                        <b>{{this.admin_data.adminSettingsWorkflowAutomation}}.</b><br />
                        {{this.admin_data.adminSettingsUnselectProceed}}
                    </p>
                </div>
                <div class="modal-footer">
                    <button *ngIf="hash_modal" type="button" class="btn btn-secondary"
                        (click)="resetAndClose()">{{this.admin_data.cancelButtonText}}</button>
                    <button *ngIf="hash_modal" type="button" class="btn btn-primary"
                        data-dismiss="modal">{{this.admin_data.okButtonText}}</button>
                    <button *ngIf="automation_settings" type="button" class="btn btn-secondary"
                        (click)="resetAndClose()">{{this.admin_data.okButtonText}}</button>
                </div>
            </div>
        </div>
    </div>
    <!-- End of Confirmation Modal for PII -->
    <!-- Start of Modal for activity -->
    <div class="modal fade" id="activityModal" tabindex="-1" role="dialog" aria-labelledby="activityModal"
        aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addRoleModalLabel">{{this.admin_data.activityDetails}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div *ngIf="individualActivity else loader">
                        <table *ngIf="individualActivity.name != ''" class="table table-striped">
                            <th class="font14" *ngFor="let name of activityModalheadings">
                                {{name}}
                            </th>
                            <tr>
                                <td>{{individualActivity.name}}</td>
                                <td>{{individualActivity.modificationDate * 1000 | date: "EEE, MMM d, y" }}</td>
                                <td>{{individualActivity.oldValue == 0 ? this.admin_data.disabled :
                                    this.admin_data.enabled}}</td>
                                <td>{{individualActivity.newValue == 0 ? this.admin_data.disabled :
                                    this.admin_data.enabled}}</td>
                            </tr>
                        </table>
                        <div *ngIf="individualActivity.name == ''">
                            {{this.admin_data.noDataFound}}
                        </div>
                    </div>
                    <ng-template #loader>
                        <img class="spinner-img img-fluid" src="../../../assets/images/g_new_loader.gif" />
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <!-- End of Modal for Activity -->
</div>