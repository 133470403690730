import { Component, OnInit, Input, Output } from '@angular/core';
import { UserService } from '../services/user.service';
import { EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import adminLabels from 'src/app/constants/ApplicationStrings/trans_utils';
declare var $: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  // imageSize_error_flag = false;
  imageSize_error_flag_organisation = false;
  imageSize_error_flag_first = false;
  imageSize_error_flag_last = false;
  imageSize_error_flag_user_image = false;
  

  @Input('formData') formData;
  @Input('formGroup') formGroup;
  @Input('languages') set languages(value:[]){
    this.translations=value;
    this.initialiseChecks();
  };

  @Input('selected_languages') set selected_languages(value:[]){
    this.selected_translations=value;
    if(this.selected_translations){
    this.selected_translations.forEach((sel,i)=>{
     this.translations.forEach((tr,j)=>{
       if(sel['language']==tr['language'] && sel['voice']==tr['voice']){
         let ind = j;
         this.mark(true,ind);
       }
     })
    })
  }
  }

  @Output() images: EventEmitter<any> = new EventEmitter();
  @Output() api_name:EventEmitter<any> = new EventEmitter();
  @Output() provision_method:EventEmitter<any> = new EventEmitter();
  @Output() disable_org_role:EventEmitter<any> = new EventEmitter();
  @Output() generic_button_clicked_event:EventEmitter<any> = new EventEmitter();
  @Output() svg_button_clicked_event:EventEmitter<any> = new EventEmitter();

  imageFiles={};
  translations=[];
  selected_translations=[];

  // items=[
  //   {display: 'Pizza', value:'Pizza'},
  //   {display: 'Pasta', value: 'Pasta'},
  //   {display: 'Parmesan', value: 'Parmesan'},
  //   {display: 'Papaya', value: 'Papaya'}
  // ]

  checks=[];
  current_date= new Date(Date.now());
  translationVisibility = true;

  admin_data = adminLabels;
  constructor(private userService:UserService) {

   }

  ngOnInit() {
    for (let data of this.formData){
      if (data.type === 'form_table' || data.type === 'regular_checkbox') {
        if ('visibility' in data) {
          this.translationVisibility = data.visibility;
        }
      }
    }
  }

  toggle(j, checked) {
    this.formGroup.patchValue({
      [this.formData[j].name]:
        checked == true ? 1 : 0,
    });
  }  

  initialiseChecks() {
    if(this.translations && this.translations.length>0){
    this.checks = new Array(this.translations.length);
    for (let i = 0; i < this.checks.length; i++) {
        this.checks[i] = false;
    }
  }
}

  showFile(files,form){
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      form['error'] = "Only images are supported.";
      return;
    }


    var reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (_event) => {
      // to check dimensions
      let img:any = new Image();
      img.onload = () => {
        if (img.width <= 500 && img.height <= 500) {
          form.source = reader.result;
          let formData = new FormData();
          formData.append(form.name, files[0], files[0].name);
          this.imageFiles[form.name] = formData.get(form.name);
          this.images.emit(formData);

          switch (form.name) {
            case 'first_slide':
                this.imageSize_error_flag_first = false;
            case 'last_slide':
                this.imageSize_error_flag_last = false;
            case 'uploadorg':
                this.imageSize_error_flag_organisation = false;
                case 'app_image':
                    this.imageSize_error_flag_user_image = false;
          }


          // this.imageSize_error_flag = false;
        } else {
          switch (form.name) {
            case 'first_slide':
                this.imageSize_error_flag_first = true;
            case 'last_slide':
                this.imageSize_error_flag_last = true;
            case 'uploadorg':
                this.imageSize_error_flag_organisation = true;
            case 'app_image':
                this.imageSize_error_flag_user_image = true;
          }
          // this.imageSize_error_flag = true;

          form.source = '../../../assets/images/user.png';
          let formData = new FormData();
          formData.append(form.name, '../../../assets/images/user.png');
          form['error'] = "Recommended size (500px X 500px).";
          return;
        }
      };
      img.src = reader.result; // This is the data URL 
    };
  }

  clickFileInput(j){
    document.getElementById("fileInput"+j).click();
  }

  emitSelected(value,formName){
   let obj={
     value:value,
     formName:formName
   }
    if(formName=='api_name'){
      this.api_name.emit(obj);
    }
    if(formName=='application_id'){
      this.api_name.emit(obj);
    }

    if(formName=='provision_method'){
      this.provision_method.emit(obj);
    }

    if(formName='role'){
      this.disable_org_role.emit(value);
    }
  }

  imageError(name) {
    if (name == "app_image") {
      $("img.img-fluid").attr("src", "/assets/images/image.png");
    } if ( name == "uploadorg") {
      $("img.img-fluid").attr("src", "/assets/images/organization_42x42.png");
    }
  }

  showToolTip(){
    $('[data-toggle="tooltip"]').tooltip();
  }

  ngAfterViewInit(){
    $('[data-toggle="tooltip"]').tooltip();
  }

  ngAfterViewChecked(){
    $('[data-toggle="tooltip"]').tooltip();
  }
  
  removeToolTip(){
    $('.tooltip').remove();
  }

  mark(checked,ind){
    if(this.formGroup.get('app_languages')){
      if(checked){
        this.checks[ind]=true;
        this.formGroup.get('app_languages').push(new FormGroup({
          name:new FormControl(this.translations[ind]['name']),
          language:new FormControl(this.translations[ind]['language']),
          voice:new FormControl(this.translations[ind]['voice'])
        }))
      }else{
        this.checks[ind]=false;
        let index;
        this.formGroup.get('app_languages')['controls'].forEach((lang,i)=>{
         if(this.translations[ind]['name']==lang.get('name').value && this.translations[ind]['voice']==lang.get('voice').value){
          index=i;
         }
        })
        this.formGroup.get('app_languages').removeAt(index);
      }
    }
  }

  isCheckedAll(){
    let all = true;
    for(let i=0;i<this.checks.length;i++){
      if(!this.checks[i]){
        all = false;
        break;
      }
    }
    return all;
  }

  markAll(checked){
    if(this.formGroup.get('app_languages')){
      if(checked){
        while(this.formGroup.get('app_languages')['controls'].length>0){
          this.formGroup.get('app_languages').removeAt(0);
        }
        
        for(let i=0;i<this.translations.length;i++){
          this.checks[i]=true;        
          this.formGroup.get('app_languages').push(new FormGroup({
            name:new FormControl(this.translations[i]['name']),
            language:new FormControl(this.translations[i]['language']),
            voice:new FormControl(this.translations[i]['voice'])
          }))
        }
      }else{
        for(let i=0;i<this.translations.length;i++){
          this.checks[i]=false;
           this.formGroup.get('app_languages').removeAt(0);
        }
      }
    }
  }

  emitClicked(formName){
    this.generic_button_clicked_event.emit(formName);
    }

    emitSvgButtonClicked(formName){
        this.svg_button_clicked_event.emit(formName);
    }

  
}
