<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">

            <div class="sectionBody">
                <div class="d-flex mb-3">
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader mb-2">
                        {{this.admin_data.globalFeatureFlags + " :"}}
                    </div>
                </div>
                <div class="tab-container px-4 pt-4 pb-0 clearfix bg-checkbox">
                    <!-- <form [formGroup]="formGroup"> -->
                    <div class="d-flex ">
                        <div
                            class="col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 py-2  my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 font14 font-weight-bold  pb-4">
                            <span class="heading_text">{{admin_data.features}}</span>
                            <svg-icon *ngIf="" src="../../../assets/svgs/icon-info.svg" class="ml-2 infoIcon"
                                data-toggle="tooltip" data-placement="bottom" title=""></svg-icon>
                        </div>
                        <div
                            class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 py-2  my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 font14 font-weight-bold">
                            <span class="heading_text pl-2">{{admin_data.beta}}</span>
                            <svg-icon *ngIf="" src="../../../assets/svgs/icon-info.svg" class="ml-2 infoIcon"
                                data-toggle="tooltip" data-placement="bottom" title=""></svg-icon>
                        </div>
                        <div
                            class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 py-2  my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 font14 font-weight-bold">
                            <span class="heading_text pl-2">{{admin_data.legacy}}</span>
                            <svg-icon *ngIf="" src="../../../assets/svgs/icon-info.svg" class="ml-2 infoIcon"
                                data-toggle="tooltip" data-placement="bottom" title=""></svg-icon>
                        </div>
                        <div
                            class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 py-2  my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 font14 font-weight-bold">
                            <span class="heading_text pl-2">{{admin_data.enterprise}}</span>
                            <svg-icon *ngIf="" src="../../../assets/svgs/icon-info.svg" class="ml-2 infoIcon"
                                data-toggle="tooltip" data-placement="bottom" title="}"></svg-icon>
                        </div>
                    </div>
                    <div>
                        <div class="d-flex" *ngFor="let feature of featureList ; index as j">
                            <ng-container *ngIf="feature.show">
                                <div
                                    class="col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 py-2  my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-0 font14 ">
                                    <label class="mb-0 float-none">{{feature.title}}</label>
                                    <svg-icon *ngIf="feature.tooltip !== ''" src="../../../assets/svgs/icon-info.svg"
                                        class="ml-2 infoIcon" data-toggle="tooltip" data-placement="bottom"
                                        title={{feature.tooltip}}></svg-icon>
                                </div>
                                <div
                                    class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 py-2  my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-0 font14">
                                    <label class="switch mx-2 mb-0 float-none"
                                    [ngClass]="feature.beta==0 && (feature.legacy == 1 || feature.enterprise == 1) ? ' switch-disabled' : ''">
                                        <input class="primary" type="checkbox" [checked]="feature.beta==1?true:false"
                                            [value]="feature.beta" (click)="toggle(j,$event.target.checked, 'beta')"
                                            [disabled]="feature.beta==0 && (feature.legacy == 1 || feature.enterprise == 1)">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <div
                                    class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 py-2  my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-0 font14">
                                    <label class="switch mx-2 mb-0 float-none"
                                        [ngClass]="feature.beta==1 ? ' switch-disabled' : ''">
                                        <input class="primary" type="checkbox"
                                            [checked]="(!(feature.beta == 1) && feature.legacy ) ==1?true:false"
                                            [value]="feature.legacy" (click)="toggle(j,$event.target.checked, 'legacy')"
                                            [disabled]="feature.beta==1">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <div
                                    class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 py-2  my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-0 font14">
                                    <label class="switch mx-2 mb-0 float-none"
                                        [ngClass]="feature.beta==1 ? ' switch-disabled' : ''">
                                        <input class="primary" type="checkbox"
                                            [checked]="(feature.beta == 1 || feature.enterprise==1) ? true : false"
                                            [value]="feature.enterprise"
                                            (click)="toggle(j, $event.target.checked, 'enterprise')"
                                            [disabled]="feature.beta==1">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div>
                    <!-- <div class="{{form.class}}">
                        <span class="{{form.text_class}}">{{form.ui.label}}</span>
                        <svg-icon *ngIf="form.tooltip" src="../../../assets/svgs/icon-info.svg" class="ml-2 infoIcon" data-toggle="tooltip"
                            data-placement="bottom" title="{{form.tooltip}}"></svg-icon>
                    </div> -->
                    <!-- <div class="{{form.class}}" [ngClass]="{'pt-2 pt-sm-2 pt-md-2 pt-lg-4 pt-xl-4 rounded-top': j == 0 || j == 1}"
                        *ngIf="form.name!='maintenance_mode' && form.type=='checkbox'">
                        <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                        <svg-icon *ngIf="form.tooltip !== ''" src="../../../assets/svgs/icon-info.svg" class="ml-2 infoIcon"
                            data-toggle="tooltip" data-placement="bottom" title="{{form.tooltip}}"></svg-icon>
                        <label class="switch mx-2 mb-0 float-none">
                            <input class="{{form.ui.inputclass}}" type="{{form.type}}" formControlName="{{form.name}}"
                                [checked]="formGroup['controls'][form.name].value==1?true:false" [value]="feature.checked==true?1:0"
                                (click)="toggle(feature.key,$event.target.checked)">
                            <span class="slider round"></span>
                        </label>
                        <div *ngIf="form.ui.message!=undefined" class="error color pt-1">
                            {{form.ui.message}}
                        </div>
                    </div> -->
                </div>
            </div>
        </section>
        <app-modal-confirmation [header_value]="admin_data.confirmChanges"
            [content_value]="admin_data.featureFlagConfirmationMessage" [modal_ok_value]="admin_data.okButtonText" [org]=""
            (onDelete)="confirmAction()" (onClose)="closeModal()"></app-modal-confirmation>
    </div>
</div>