import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PaginationService {

    pages = [1, 2, 3, 4, 5];
    currentPage: number = 1;
    prevIndex = 0;
    currentIndex = 1;
    total = 0;
    pageLimit: number = 0;
    deleteIndex = 0;
    delete_user_id = 0;
    searchInputApp;
    allRoles = [];
    org_id;
    selectedOrg;
    search_text = "";
    is_suspend_filter;
    role_filter;
    org_role_filter;

    disableNext = false;
    disablePrev = false;
    showPagination = false;
    begin = 0;
    end : number = 0;
    lastbegin = 0;
    pagesinLimit = [];
    showBegin = false;
    showEnd = false;
    isOutsideLimit = false;
    constructor() { }

    initializeValues() {
        this.pages = [1, 2, 3];
        this.currentPage = 1;
        this.prevIndex = 0;
        this.currentIndex = 1;
        this.total = 0;
        this.pageLimit = 1;
        this.deleteIndex = 0;
        this.delete_user_id = 0;
        this.disableNext = false;
        this.disablePrev = false;
        this.showPagination = false;

    }

    calculatePageLimit(total_rows, limit) {
        this.total = total_rows;
        this.pageLimit = Math.ceil(parseInt(total_rows) / limit);
        if (this.pageLimit == 2) {
            this.pages = [1, 2];
        } else {
            this.pages = Array.from(Array(this.pageLimit), (_, index) => index + 1);
        }
        this.setPagesinView();
    }

    setPaginationStatus(data, limit) {
        if (data.length < limit) {
            this.disableNext = true;
            this.showPagination = false;
        } else {
            this.disableNext = false;
            this.showPagination = true;
        }

        if (this.currentIndex == this.pageLimit) {
            this.disableNext = true;
        }

        if (this.currentPage == 1) {
            this.disablePrev = true;
        } else {
            this.disablePrev = false;
            this.showPagination = true;
        }
    }

    pageSetOnDelete() {
        this.currentIndex--;
        this.currentPage = this.currentIndex;
    }

    appendOffset(limit, offset) {
        this.disableNext = true;
        this.currentPage++;
        this.currentIndex = this.currentPage;
        if (this.currentPage > 0 && this.currentPage <= this.pageLimit) {
            if (this.currentPage > this.pages[this.pages.length - 1]) {
                this.pages.shift();
                this.pages.push(this.currentPage);
            }
            this.setPagesinView();
            offset += limit;
            this.isOutsideLimit = false;
            return offset;
        }
        return -1;
    }

    prependOffset(limit, offset) {
        this.disablePrev = true;
        this.currentPage--;
        this.currentIndex = this.currentPage;
        if (this.currentPage > 0 && this.currentPage <= this.pageLimit) {
            if (this.currentPage < this.pages[0]) {
                this.pages.pop();
                this.pages.unshift(this.currentPage);
            }
            this.setPagesinView();
            offset -= limit;
            this.isOutsideLimit = false;
            return offset;
        }

        return -1;

    }

    setIndex(ind, limit, offset) {
        if (ind != this.currentIndex) {
            this.prevIndex = this.currentIndex;
            this.currentIndex = ind;
            this.currentPage = this.currentIndex;
            offset = this.calculateOffset(this.prevIndex, this.currentIndex, limit, offset);
            this.setPagesinView();
            this.isOutsideLimit = false;
            return offset;
        }
        return -1;
    }

    calculateOffset(prev, current, limit, offset) {
        if (offset < 0) offset = 0;
        if (current > prev) {
            offset = (current - prev) * limit + offset;
        } else {
            offset = offset - (prev - current) * limit;
        }
        this.isOutsideLimit = false;
        return offset;
    }

    setPagesinView() {
        this.begin = this.currentPage - 3;
        this.end = this.currentPage + 2;        
        if (this.begin < 0) {
            this.begin = 0;
            this.end = this.begin + 5;
        }
        if (this.end == this.pageLimit) {
            this.lastbegin = this.begin;
            this.end = this.currentPage + 2;
        }
        if (this.end > this.pageLimit) {
            this.end = this.pageLimit;
            this.pagesinLimit = this.pages.slice(this.end - 5, this.end);
        }
        else {
            this.pagesinLimit = this.pages.slice(this.begin, this.end);
        }

        if (Math.ceil(this.pageLimit) > 5) {
            if (this.currentPage > Math.floor(this.pageLimit / 2)) {
                this.showBegin = true;
                this.showEnd = false;
            } else {
                this.showEnd = true;
                this.showBegin = false;
            }
        } else {
            this.showBegin = false;
            this.showEnd = false;
        }
    }

    gotoPage(page: number, limit, offset) {
        if (page > this.pageLimit || page <= 0) {
            this.isOutsideLimit = true;
            return -1;
        }
        
        let off = 0;        
        if (page == this.pageLimit) {
            this.disableNext = false;
        } else if (page == 1) {
            off = 0;
        } else {
            this.disableNext = true;
            this.disablePrev = true;
        }
        this.currentIndex = page;
        off = this.calculateOffset(this.currentPage, page, limit, offset);
        this.currentPage = page;
        this.setPagesinView();
        return off;
        
    }
}
