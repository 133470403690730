<div class="row org-card-wrapper">
    <div class="col-sm-12 col-md-12">
        <div role="alert">
            <!-- <strong class="heading">{{this.admin_data.themeListVariable1}}</strong>  -->
            <span class="label alert alert-secondary py-2 px-3 p ml-2"><strong>{{this.admin_data.themeListVariable2}}</strong>{{this.admin_data.themeListVariable4}}</span>
        </div>
    </div>
</div>
<div class="row org-card-wrapper">
    <div class="col-sm-6 col-md-6 col-lg-3 p-3 theme-box" *ngFor="let theme of themes;">
        <div class="card org-card border-top-0">
            <div class="card-header org-card-header m-0 p-0">
                <img class="img-fluid" src="../../../assets/images/themes/{{theme.theme_id}}/theme.png">
                <span *ngIf="active_theme_id == theme.theme_id" class="org-tick">
                <svg-icon src="../../../assets/svgs/org_tick.svg">
                    
                </svg-icon>
            </span>
            </div>
            <div class="card-body org-card-body">
                <div class="list-group-item border-0 d-flex align-items-center pb-2 p-0">
                    <h5 class="card-title m-0 text-truncate">{{theme.theme_name}}</h5>
                </div>
                <ul class="list-group list-group-flush flex-row justify-content-between themes-div">
                    <li class="list-group-item border-0 d-flex align-items-center p-0">
                        <button [routerLink]="['/organization', org_id,'themes',theme.theme_id]" type="button" class="btn btn-sm btn-secondary ">
                           <b>{{this.admin_data.view}}</b>
                        </button>
                    </li>
                    <li class="list-group-item border-0 d-flex align-items-center p-0">
                        <button [class.faded]="active_theme_id == theme.theme_id"
                            type="button" class="btn btn-sm btn-primary" (click)="getConfirmation(theme.theme_id)"
                            [disabled]="active_theme_id == theme.theme_id">
                            <span *ngIf="active_theme_id == theme.theme_id">{{this.admin_data.themeListVariable4}}</span>
                            <span *ngIf="active_theme_id != theme.theme_id">{{this.admin_data.themeListVariable5}}</span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="modal" tabindex="-1" role="dialog" id="themeConfirmation">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{this.admin_data.themeListVariable6}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>{{this.admin_data.themeListVariable7}}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{this.admin_data.themeListVariable8}}</button>
                    <button type="button" class="btn btn-primary" (click)="confirm(activate_theme_id)">{{this.admin_data.themeListVariable9}}</button>
                </div>
            </div>
        </div>
    </div>
