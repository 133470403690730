import adminLabels from "../ApplicationStrings/trans_utils";

export const ADD_FOLDER_FORMDATA=[
    {
    type: "text",
    name: "title",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    layoutModal: true,
    validators: [
      {
        required: true,
        maxLength: 80
      },
    ],
    ui: {
      label: adminLabels.addFolderName,
      class: "col-lg-4 font14 label-asterisk",
      inputclass: "col-lg-8",
    },
    input: {
      id: "title",
      class: "form-control font14",
      type: "text",
      name: "title",
      placeholder: adminLabels.addFolderName,
    },
    label: {
      title: adminLabels.addFolderName,
      class: "font-weight-bold font14",
      for: "title",
    },
  }
]