import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomSelectComponent),
    multi: true
  }]
})
export class CustomSelectComponent implements ControlValueAccessor, OnChanges {
  admin_data = adminLabels;
  showDropDown = false;
  attributeMapper = new Map();

  @Input() dataList: any[];
  @Input() componentName: string = "guides";
  @Input() className: string = "";
  @Input() className2: string = "";
  @Input() placeholder: string = "";
  @Input() isDisabled: boolean = false; //This disable is for main toggle button
  @Input() isDisabledSubItem: boolean = false; //This will check if any sub item need to be disabled
  @Input() disabledSubItems: any = [];
  @Input() position: string = '';
  @Input() selected: any = '';
  @Input() preSelectOption: boolean = false;
  @Input() isTest = false;
  @Input() dropdownPosition:string;

  @Output() updateDataList = new EventEmitter<any>();

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {
    this.attributeMapper.set("default", ["value", "name"]);
    this.attributeMapper.set("guides", ['guide_value', 'guide_state']);
    this.attributeMapper.set("apps", ['application_id', 'name']);
    this.attributeMapper.set("users", ['user_id', 'name']);
    this.attributeMapper.set("activityType", ["type", "label"]);
    this.attributeMapper.set("activityAction", ["value", "label"]);
    this.attributeMapper.set("adminSetting", ["value", "name"]);
    this.attributeMapper.set("adminDefaultLanguageSetting", ["value", "name"]);
    this.attributeMapper.set("adminLanguageSetting", ["value", "name"]);
    this.attributeMapper.set("adminVoiceSetting", ["valueData", "display"]);
    this.attributeMapper.set("generateCredentials", ["value", "name"]);
    this.attributeMapper.set("applicationList", ["value", "name"]);
    this.attributeMapper.set("language", ["language", "name"]);
    this.attributeMapper.set("findReplaceApplication", ["application_id", "title"]);
    this.attributeMapper.set("folder", ["category_id", "name"]);
    this.attributeMapper.set("userAttributeSegment", ["id", "key_name"]);
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataList']) {
      this.showDropDown = false;
      this.log(this.dataList);
      if (!changes['dataList'].firstChange) {
        this.cdr.detectChanges();
      }
    }

    if (changes['selected']) {
      this.cdr.detectChanges();
    }
  }

  openCloseDropdown = () => {
    this.showDropDown = !this.showDropDown;
  }

  onSelect = (data_id: any) => {
    this.selected = data_id;
    switch (this.componentName) {
      case "adminDefaultLanguageSetting":
      case "adminLanguageSetting":
      case "language": {
        const obj = this.dataList.find(data => this.getValue(data) === data_id);
        this.updateDataList.emit(obj);
        break;
      }

      default: {
        this.updateDataList.emit(data_id);
      }
    }
    this.onChange(this.selected);
    this.showDropDown = false;
  };

  getSelectedLabel = () => this.getLabel((this.dataList && this.dataList.find(obj => this.selected == this.getValue(obj))) ?? {}) ?? (this.preSelectOption ? this.admin_data.pleaseSelect : '');
  getValue = (obj) => obj[this.attributeMapper.get(this.componentName)[0]];
  getLabel = (obj) => obj[this.attributeMapper.get(this.componentName)[1]];

  getDisabledSubItem(obj) {
    if (this.isDisabledSubItem) {
      switch (this.componentName) {
        case "adminLanguageSetting": {
          return this.disabledSubItems.some(subItem => subItem.language === this.getValue(obj))
        }
        default: {
          return false;
        }
      }
    }
    return false
  }

  writeValue(value: any): void {
    this.selected = value;
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (this.showDropDown && !this.elementRef.nativeElement.contains(targetElement)) {
      this.showDropDown = false;
    }
  }

  @HostListener('document:scroll', ['$event'])
  onScrollOutside(event: Event) {
    if (this.showDropDown) {
      this.showDropDown = false;
    }
  }

  log(message: any, ...optionalParams: any[]) {
    if (this.isTest) {
      console.log(message, ...optionalParams);
    }
  }

  getStyle(position: string) {
    switch (position) {
      case 'inherit':
        return { position: 'inherit'};
      case 'relative':
        return { position: 'relative'};
      default:
        return { position: 'relative'}; 
    }
  }

}
