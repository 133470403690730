import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserService } from '../common/services/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { FormControl, FormGroup } from '@angular/forms';
import { OrganisationService } from '../common/services/organisation.service';
import { ToastModalService } from '../common/services/toast-modal.service';
import { ErrorResponseService } from '../common/services/error-response.service';
declare var $: any;
declare var bootstrap: any;

type feature = {
    key: string;
    title: string;
    beta: number;
    legacy: number;
    enterprise: number;
    show: boolean;
    tooltip: string;
};
@Component({
    selector: 'app-feature-flags',
    templateUrl: './feature-flags.component.html',
    styleUrls: ['./feature-flags.component.scss']
})
export class FeatureFlagsComponent {



    breadcrumb;
    formGroup;
    admin_data = adminLabels;
    featureList: feature[] = [
        {
            key: "guide_translation", title: this.admin_data.labelGuideTranslation,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipGuideTranslation
        },
        {
            key: "guide_segmentation", title: this.admin_data.labelGuideSegmentation,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipGuideSegmentation
        },
        {
            key: "sso", title: this.admin_data.labelSSO,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipSSO
        },
        {
            key: "media_file_notifications", title: this.admin_data.enableMediaNotification,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipEnableInsights
        },
        {
            key: "myBotEnabled", title: this.admin_data.labelGuideAutomation,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipGuideAutomation
        },
        {
            key: "display_accept_cookie_popup", title: this.admin_data.labelDisplayAcceptCookiePopup,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipDisplayAcceptCookiePopup
        },
        {
            key: "current_page_beacon", title: this.admin_data.labelShowBeaconGuidesOnCurrentPage,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipShowBeaconGuidesOnCurrentPage
        },
        {
            key: "isPITrackingEnabled", title: this.admin_data.labelUserInformationTracking,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipUserInformationTracking
        },
        {
            key: "mediafile_creation", title: this.admin_data.labelMediaFileCreation,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipMediaFileCreation
        },
        {
            key: "api_connector", title: this.admin_data.labelAPIConnector,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipAPIConnector
        },
        {
            key: "display_publish_date", title: this.admin_data.labelDisplayGuidePublishingDate,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipDisplayGuidePublishingDate
        },
        {
            key: "enable_guideme_as_secondary_option", title: this.admin_data.labelEnableGuidemeAsSecondaryOption,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipEnableGuidemeAsSecondaryOption
        },
        {
            key: "self_hosted_player", title: this.admin_data.selfHostedPlayer,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: ""
        },
        {
            key: "keyboard_shortcuts", title: this.admin_data.keyboardShortcuts,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.keyboardShortcutsTooltip
        },
        {
            key: "autobot", title: this.admin_data.autobot,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: ""
        },
        {
            key: "enable_insights", title: this.admin_data.labelEnableInsights,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipEnableInsights
        },
        {
            key: "guide_insight", title: this.admin_data.labelGuideInsights,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipGuideInsights
        },
        {
            key: "realtime_insights", title: this.admin_data.labelRealTimeInsights,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipRealTimeInsights
        },
        {
            key: "show_tooltips_during_workflow_guide", title: this.admin_data.showtooltipsWithWorkflowTutorial,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.showtooltipsWithWorkflowTutorialTooltip
        },
        {
            key: "efficient_rule_mode", title: this.admin_data.labelLoadContextChangeOnPageClick,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipLoadContextChangeOnPageClick
        },
        {
            key: "create_tooltip_media_file", title: this.admin_data.mediaFileCreationForTooltipGuides,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipMediaFileCreationTooltipGuides
        },
        {
            key: "auto_generate_keywords", title: this.admin_data.auto_generate_keywords,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.tooltipMediaFileCreationTooltipGuides
        },
        {
            key: "business_intelligence_tools_integration", title: this.admin_data.biConnector,
            beta: 0,
            legacy: 0,
            enterprise: 0,
            show: false,
            tooltip: this.admin_data.biConnectorTooltip
        }
    ]
    request: { feature_flag_key: string; operation_type: string; value: number; };
    constructor(
        public userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private organisationService: OrganisationService,
        private toastService: ToastModalService,
        private errorResponseService: ErrorResponseService,
        private ref: ChangeDetectorRef
    ) {
        this.route.paramMap.subscribe((params) => { })

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.userService.setBreadcrumb([
                    {
                        name: this.admin_data.dashboard,
                        route: '/'
                    },
                    {
                        name: this.admin_data.globalFeatureFlags,
                        route: null
                    }
                ])
                this.breadcrumb = this.userService.breadCrumbs;
            });
        this.userService.getUserData();
    }


    ngOnInit() {
        this.getFeatureFlags();
    }

    ngAfterViewChecked() {
        $('[data-toggle="tooltip"]').tooltip();
    }
    getFeatureFlags() {
        this.organisationService.getGlobalFeature().subscribe((response) => {
            if (response['data']) {
                response['data']['global_setting']?.forEach(setting => {
                    let feature = this.featureList.find(feature => feature.key === setting['feature_flag_key']);
                    if (feature) {
                        feature.show = true;
                        feature.beta = Number(setting['beta']);
                        feature.legacy = Number(setting['legacy']);
                        feature.enterprise = Number(setting['enterprise']);
                    }
                });
                this.ref.detectChanges()
                if (this.toastService.showToast) {
                    this.toastService.showToastMessage(this.toastService.toastType);
                }
                $('[data-toggle="tooltip"]').tooltip()
            }
        })
    }

    toggle(i: number, checked: boolean, type: string) {
        this.featureList[i][type] = checked ? 1 : 0;
        this.request = {
            feature_flag_key: this.featureList[i].key,
            operation_type: type,
            value: this.featureList[i][type]
        }
        this.organisationService.showConfirmationModal.next(true);
    }

    confirmAction() {
        this.organisationService.postGlobalFeature(this.request).subscribe((response) => {
            if (response['error'] == false) {
                this.toastService.showToast = true;
                this.toastService.toastType = "global_feature_changed";
                this.getFeatureFlags();

            } else {
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            }
            this.organisationService.showConfirmationModal.next(false);
        });
    }

    closeModal() {
        let object = this.featureList.find(feature => feature.key === this.request.feature_flag_key
        );
        object[this.request.operation_type] = this.request.value === 1 ? 0 : 1;
        this.ref.detectChanges();
        this.organisationService.showConfirmationModal.next(false);
    }

}
