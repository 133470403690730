// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl :"https://stage-api.guideme.io/v3",
  authKey:"TESTAUTHKEY",
  SSO_API_BASE_URL:"https://stagesso.guideme.io",
  CDN_BASE_URL:"https://stagecdn.guideme.io",
  SITE_KEY: '6LeQ_2AUAAAAAFCBDED6w6QalGXeRqo_7AGwSXtP',
  include_default_segment:true,
  external_user_id:true,
  isAzure: true,
  INSIGHTS_HOST: "https://analytics-stage.guideme.io",
  INSIGHTS_URL: "https://stageanalytics.guideme.io",
  MYGUIDE_ORGKEY: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJvcmdfa2V5IjoiYjYyN2I4MjdiOTNjZTBiZTI2MmNlM2ZmYmE0ZmI0YjUifQ.7CucrqHkWnMd3xk5N3J1EtA_WWA9a-sCRhEDe6bc8rY"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
 