import adminLabels from "../ApplicationStrings/trans_utils";

export const ADD_INTERNAL_USER_FORMDATA = [
    {
        type: "text",
        name: "first_name",
        value: "",
        formtype: "inline",
        class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
        validators: [
            {
                required: true,
                maxLength: 30,
            },
        ],
        ui: {
            label: adminLabels.addUserFirstName,
            class: "col-lg-2",
            inputclass: "col-lg-6",
        },
        input: {
            id: "first_name",
            class: "form-control",
            type: "text",
            name: "first_name",
            placeholder: adminLabels.addUserFirstName,
        },
        label: {
            title: adminLabels.addUserFirstName,
            class: "font-weight-bold label-asterisk",
            for: "first_name",
        },
    },
    {
        type: "text",
        name: "last_name",
        value: "",
        class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
        validators: [
            {
                required: true,
                maxLength: 30,
            },
        ],
        ui: {
            label: adminLabels.addUserLastName,
            class: "col-lg-2",
            inputclass: "col-lg-6",
        },
        input: {
            id: "last_name",
            class: "form-control",
            type: "text",
            name: "last_name",
            placeholder: adminLabels.addUserLastName,
        },
        label: {
            title: adminLabels.addUserLastName,
            class: "font-weight-bold label-asterisk",
            for: "last_name",
        },
    },
    {
        type: "text",
        name: "user_email",
        value: "",
        class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
        validators: [
            {
                required: true,
            },
            {
                regex:
                "^[a-zA-Z0-9._%+'-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,4}$",      
            }
        ],
        ui: {
            label: adminLabels.addUserEmailId,
            class: "col-lg-2",
            inputclass: "col-lg-6",
        },
        input: {
            id: "email_id",
            class: "form-control",
            type: "text",
            name: "email_id",
            placeholder: adminLabels.addUserEmailId,
            disabled:false,
        },
        label: {
            title: adminLabels.addUserEmailId,
            class: "font-weight-bold label-asterisk",
            for: "email_id",
        },
    },
    {
        type: "select",
        name: "role",
        value: "",
        class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
        options: [
            // {
            //     value: "superadmin",
            //     name: "Superadmin",
            //     disable:true
            // },
            // {
            //     value: "owner",
            //     name: adminLabels.guideRoleAdministrator,
            // },
            // {
            //     value: "creator",
            //     name: adminLabels.guideRoleCreator,
            // },
            // {
            //     value: "user",
            //     name: adminLabels.guideRolePlayer,
            // },
            // {
            //     value: "app_admin",
            //     name: adminLabels.guideRoleAppAdmin,
            // },
            {
                value: "mg_admin",
                name: adminLabels.usersVariable11,
            },
            {
                value: "partner_admin",
                name: adminLabels.partnerAdmin,
            }
            // {
            //     value: "mg_admin",
            //     name: "MG Admin",
            // }
        ],
        validators: [
            {
                required: true,
            }
        ],
        ui: {
            label: adminLabels.addUserRole,
            class: "font-weight-bold label-asterisk",
            inputclass: "form-control",
        },
        disable:false
    },
    {
        type: "select",
        name: "partners",
        value: "",
        class: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2 font14",
        options: [],
        validators: [],
        ui: {
            label: adminLabels.partner,
            class: "font-weight-bold",
            inputclass: "form-control",
        },
        disable:false
    }]