<ng-container *ngIf="theme">
	<div class="d-flex">
		<div class="container-fluid content-container p-3 toggle-view"
			[ngClass]="{'expanded-view':(org_id && userService.sidebarOpen),'collapsed-view':(org_id && !userService.sidebarOpen)}">
			<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
			<section class="component-section">
				
				<div class="sectionBody">
					<div class="d-flex mb-3">
						<div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
							{{this.admin_data.tableTitle2}} {{this.admin_data.themeListVariable1}}
						</div>
					</div>

					<ul class="nav nav-tabs" id="myTab" role="tablist">
						<li class="nav-item" (click)="changeTab(1)">
							<a class="nav-link custom-nav-link active" id="page_1" data-toggle="tab" role="tab"
								aria-controls="home" href="#first_page"
								aria-selected="true">{{this.admin_data.firstPage}}</a>
						</li>
						<li class="nav-item" (click)="changeTab(2)">
							<a class="nav-link custom-nav-link" id="page_2" data-toggle="tab" role="tab"
								aria-controls="home" href="#middle_page"
								aria-selected="true">{{this.admin_data.middlePage}}</a>
						</li>
						<li class="nav-item" (click)="changeTab(3)">
							<a class="nav-link custom-nav-link" id="page_3" data-toggle="tab" role="tab"
								aria-controls="home" href="#last_page"
								aria-selected="true">{{this.admin_data.lastPage}}</a>
						</li>
					</ul>
					<div class="tab-container px-4 pt-4 pb-0 clearfix">
						<div class="tab-content" id="myTabContent">
							<div class="tab-pane fade show active" id="first_page" role="tabpanel"
								aria-labelledby="first_page">
								<div class="row m-0 w-85">
									<div class="page positionRelative">
										<app-theme-first-page [theme]="theme" [cdn_sign]="cdn_sign" [org_id]="org_id"
											(updateTheme)="updateTheme()"></app-theme-first-page>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="middle_page" role="tabpanel" aria-labelledby="middle_page">
								<div class="row m-0 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50">
									<div class="page positionRelative">
										<app-theme-middle-page [theme]="theme" [cdn_sign]="cdn_sign" [org_id]="org_id"
											(updateTheme)="updateTheme()"></app-theme-middle-page>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="last_page" role="tabpanel" aria-labelledby="last_page">
								<div class="page positionRelative">
									<app-theme-last-page [theme]="theme" [cdn_sign]="cdn_sign" [org_id]="org_id"
										(updateTheme)="updateTheme()">
									</app-theme-last-page>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

		</div>
	</div>
</ng-container>