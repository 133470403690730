import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Theme } from '../../theme.model';
import { ActivatedRoute } from '@angular/router';
import { ThemeService } from '../../../common/services/theme.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/common/services/user.service';
import adminLabels from "../../../constants/ApplicationStrings/trans_utils";

declare const $: any;

@Component({
    selector: 'app-theme-last-page',
    templateUrl: './theme-last-page.component.html',
    styleUrls: ['./theme-last-page.component.css']
})

export class ThemeLastPageComponent implements OnInit {
    @Input() theme: Theme;
    @Input() cdn_sign: string;
    @Input() org_id: number;
    @Output("updateTheme") updateTheme: EventEmitter<any> = new EventEmitter();
    theme_id: string;
    save_options: boolean;
    previous_logo_url: string;
    previous_footer_logo_url: string;
    footer_logo_options: boolean;
    selected_logo: File;
    edit_domain_name: boolean;
    previous_domain_name: string;
    edit_last_page_title: boolean;
    edit_last_page_subtitle_1: boolean;
    edit_last_page_subtitle_2: boolean;
    header_border: boolean;
    header_img: boolean;
    show_page_number: boolean;
    show_footer_logo: boolean;
    show_logo: boolean;
    show_domain_name: boolean;
    previous_title: string;
    previous_sub_title_1: string;
    previous_sub_title_2: string;
    binary_image: boolean;
    logo_options: boolean;
    file_1;
    file_2;
    @ViewChild("title", {static : false}) title_elem:ElementRef;
    @ViewChild("maindiv", {static : false}) maindiv:ElementRef;

    admin_data = adminLabels ;

    constructor(
        private route: ActivatedRoute,
        public themeService: ThemeService,
        public userService:UserService,
        private toastr: ToastrService
    ) {
     }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.theme_id = params["params"]["theme_id"];
            this.header_border = (this.theme_id == 'theme-2') ? true : false;
            this.header_img = (this.theme_id == 'theme-3') ? true : false;
            this.show_domain_name = (this.theme_id != 'theme-3') ? true : false;
            this.show_logo = (this.theme_id == 'theme-3' || this.theme_id == 'theme-5') ? true : false;
            this.show_page_number = (this.theme_id == 'theme-2') ? true : false;
            this.show_footer_logo = (this.theme_id == 'theme-1' || this.theme_id == 'theme-2') ? true : false;
        });

        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    }

    onSelectLogo(event, type: string) {
        if (event.target.files && event.target.files[0]) {
            this.selected_logo = event.target.files[0];

            // File Name and Extension.
            let file_name: string = this.selected_logo.name;
            let file_extension: string = file_name.replace(/^.*\./, '');
            let file_size: number = this.selected_logo.size;

            // Check if it is an image file.
            if ((file_extension == "png"
                || file_extension == "jpg"
                || file_extension == "jpeg")
                && file_size <= 2097152) {

                var reader = new FileReader();

                // read file as data url
                reader.readAsDataURL(event.target.files[0]);

                reader.onload = () => {
                    // called once readAsDataURL is completed
                    this.binary_image = true;
                    if (type === "footer_logo") {
                        this.previous_footer_logo_url = this.theme.last_page.footer_logo_url;
                        this.theme.last_page.footer_logo_url = reader.result;
                        this.footer_logo_options = true;
                    } else {
                        this.previous_logo_url = this.theme.last_page.logo_url;
                        this.theme.last_page.logo_url = reader.result;
                        this.logo_options = true;
                    }
                };
            } else {
                this.onCancelLogo(type);
                this.toastr.error(this.admin_data.invalidExtension, this.admin_data.failed, {
                    timeOut: 3000
                });
            }
        }
    }

    onEditDomainName() {
        this.previous_domain_name = this.theme.domain_name;
        this.edit_domain_name = true;
    }

    onCancelDomainName() {
        this.theme.domain_name = this.previous_domain_name;
        this.edit_domain_name = false;
    }

    onSaveDomainName() {
        this.edit_domain_name = false;
        this.updateTheme.emit();
    }

    onEditTitle() {
        this.previous_title = this.theme.last_page.title;
        this.edit_last_page_title = true;
    }

    onCancelTitle() {
        this.theme.last_page.title = this.previous_title;
        this.edit_last_page_title = false;
    }

    onSaveTitle() {
        this.edit_last_page_title = false;
        this.updateTheme.emit();
    }

    onEditSubTitle1() {
        this.previous_sub_title_1 = this.theme.last_page.lp_subtitle_1;
        this.edit_last_page_subtitle_1 = true;
    }

    onCancelSubTitle1() {
        this.theme.last_page.lp_subtitle_1 = this.previous_sub_title_1;
        this.edit_last_page_subtitle_1 = false;
    }

    onSaveSubTitle1() {
        this.edit_last_page_subtitle_1 = false;
        this.updateTheme.emit();
    }

    onEditSubTitle2() {
        this.previous_sub_title_2 = this.theme.last_page.lp_subtitle_2;
        this.edit_last_page_subtitle_2 = true;
    }

    onCancelSubTitle2() {
        this.theme.last_page.lp_subtitle_2 = this.previous_sub_title_2;
        this.edit_last_page_subtitle_2 = false;
    }

    onSaveSubTitle2() {
        this.edit_last_page_subtitle_2 = false;
        this.updateTheme.emit();
    }

    onCancelLogo(type: string) {
        if (type == 'footer_logo') {

            if(this.previous_footer_logo_url != '' && this.previous_footer_logo_url !=undefined) {
                this.theme.last_page.footer_logo_url = this.previous_footer_logo_url + this.cdn_sign;
            } else {
                this.theme.last_page.footer_logo_url = '';
            }

            this.footer_logo_options = false;
        } else {

            if (this.previous_logo_url != '' && this.previous_logo_url != undefined) {
                this.theme.last_page.logo_url = this.previous_logo_url + this.cdn_sign;
            } else {
                this.theme.last_page.logo_url = '';
            }
            this.logo_options = false;
        }
        this.file_1=null;
        this.file_2=null;
    }

    onSaveLogo(org_id: number, type: string) {
        let formData = new FormData();
        formData.append(
            "image",
            this.selected_logo
        );

        formData.append(
            "organization_id",
            org_id.toString()
        );

        this.themeService.getImageId(formData).subscribe(
            (response) => {
                let data = response["data"];
                if (type == 'footer_logo') {
                    this.footer_logo_options = false;
                    this.theme.last_page.footer_logo_id = parseInt(data["image_id"]);
                    this.theme.last_page.footer_logo_url = data["image_path"];
                } else {
                    this.logo_options = false;
                    this.theme.last_page.logo_id = parseInt(data["image_id"]);
                    this.theme.last_page.logo_url = data["image_path"];
                }

                this.updateTheme.emit();
                this.binary_image = false;
            },
            (error) => {
                this.toastr.error(this.admin_data.unableUpdateLogo, this.admin_data.failed, {
                    timeOut: 2000
                });
            }
        );
    }

    addFooterStrip() {
        return (this.theme_id == 'theme-2') ? true : false;
    }

    getTitleStyle() {
        let css_class: string = "title";
        if (this.theme_id == 'theme-3') {
            css_class = "title-3";
        }
        if (this.theme_id == 'theme-5') {
            css_class = "title-5";
        }
        return css_class;
    }

    showToolTip(){
        $('[data-toggle="tooltip"]').tooltip();
      }
    
      removeToolTip(){
        $('.tooltip').remove();
      }

    //   ngAfterViewChecked(){
    //     if(this.title_elem && this.maindiv){
    //         if(this.title_elem.nativeElement.offsetHeight>0){
    //           this.title_elem.nativeElement.offsetTop =(this.maindiv.nativeElement.clientHeight/2) - (this.title_elem.nativeElement.clientHeight/2);
    //           this.title_elem.nativeElement.offsetTop=this.title_elem.nativeElement.offsetTop+'px';
    //       }
        
    //     }
    //   }

    //   getHeight(){
    //     if(this.title_elem){
    //         return this.title_elem.nativeElement.clientHeight/2;
    //     }

    //     getDomainHeight(){
    //         if(this.title_elem && this.maindiv)
    //     }
    // }
}
