<form [formGroup]="formGroup">
  <div class="form-group">
    <div class="row m-0" [ngClass]="{'w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50': !formData[0].layoutModal}">
      <ng-container *ngFor="let form of formData; let j = index">
        <div class="{{ form.class }}" *ngIf="form.type == 'text'">
          <label class="{{ form.label.class }}" for="{{ form.label.for }}">
            {{ form.label.title }}
          </label>
          <input id="{{ form.input.id }}" class="{{ form.input.class }}" type="{{ form.type }}" autocomplete="off"
            pattern="^(?!\s*$).+" formControlName="{{ form.name }}" placeholder="{{ form.input.placeholder }}"
            required="{{ form.validators[0].required }}" maxlength="{{ form.validators[0].maxLength }}"
            [attr.disabled]="form.input.disabled?form.input.disabled:null" />
          <div *ngIf="formGroup['controls'][form.name].invalid" class="height12px font12 h-100">
            <ng-container *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
              <div class="error color" *ngIf="formGroup.get(form.name).hasError('required')">
                {{this.admin_data.loginFieldMandatory}}
              </div>
              <div class="error color"
                *ngIf="(formGroup.get(form.name).hasError('pattern') || formGroup.get(form.name).hasError('email')) && form.name!='domain'">
                {{this.admin_data.patternInvalid}}
              </div>
              <div class="error color pb-2" *ngIf="formGroup.get(form.name).hasError('pattern') && form.name=='domain'">
                {{this.admin_data.formMessage1}}
                <br>{{this.admin_data.formMessage2}}
                <br>{{this.admin_data.formMessage3}}
                <br>{{this.admin_data.formMessage4}}
              </div>
            </ng-container>
          </div>

        </div>
        <div class="{{ form.class }}" *ngIf="form.type == 'tag-input'">
          <div>
            <label class="{{ form.ui.class }}" data-toggle="tooltip" data-placement="bottom" (mouseover)="showToolTip()"
              (mouseout)="removeToolTip()" title="{{ form.ui.label }}">{{ form.ui.label }}</label>
            <tag-input class="tag-input" formControlName="{{ form.name }}" [ngModel]="form['selectedItems']"
              [placeholder]="this.admin_data.enterNewTag" [secondaryPlaceholder]="this.admin_data.enterNewTag"
              [onlyFromAutocomplete]="true">
              <tag-input-dropdown [autocompleteItems]="form['items']" [showDropdownIfEmpty]="true"
                [dynamicUpdate]="false">
              </tag-input-dropdown>
            </tag-input>
          </div>
          <!-- <div class="height12px font12">
            <ng-container *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
              <div *ngIf="formGroup['controls'][form.name].invalid" class="error color">
                {{ form.ui.label }} is required</div>
            </ng-container>
          </div> -->
        </div>
        <div class="{{ form.class }}" *ngIf="form.type == 'password'">
          <label class="{{ form.label.class }}" for="{{ form.label.for }}">
            {{ form.label.title }}
          </label>
          <input id="{{ form.input.id }}" class="{{ form.input.class }}" type="{{ form.type }}" autocomplete="off"
            formControlName="{{ form.name }}" placeholder="{{ form.input.placeholder }}"
            required="{{ form.validators[0].required }}" [maxLength]="form.input.maxLength?form.input.maxLength:100" />
          <div *ngIf="formGroup['controls'][form.name].valid" class="valid-feedback">
            {{this.admin_data.valid}}
          </div>
          <div class="height12px font12">
            <ng-container *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
              <div *ngIf="!formGroup['controls'][form.name].valid"
                [class.invalid-feedback]="!formGroup['controls'][form.name].valid">
                {{this.admin_data.loginFieldMandatory}}
              </div>
              <div class="error color" *ngIf="formGroup.get(form.name).hasError('required')">
                {{this.admin_data.loginFieldMandatory}}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="{{ form.class }}" *ngIf="form.type == 'select'">
          <label class="{{ form.ui.class }}">{{ form.ui.label }}</label>
          <!-- <select class="{{ form.ui.inputclass }}" formControlName="{{ form.name }}"
            (change)="emitSelected($event.target.value,form.name)" [attr.disabled]="form.disable?true:null">
            <option value="">{{this.admin_data.select}}</option>
            <option *ngFor="let option of form['options']" value="{{ option.value }}">
              {{ option.name }}
            </option>
          </select> -->

          <app-custom-select formControlName="{{form.name}}" [dataList]="form['options']" preSelectOption="true"
            (updateDataList)="emitSelected($event,form.name)" componentName="default" [isDisabled]="form.disable">
          </app-custom-select>
          <div class="height12px font12">
            <ng-container *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
              <div *ngIf="formGroup['controls'][form.name].invalid" class="error color">
                {{ form.ui.label }} {{this.admin_data.isRequired}}</div>
            </ng-container>
          </div>
        </div>
        <div class="{{ form.class }}" *ngIf="form.type == 'textarea'">
          <label class="{{ form.ui.class }}">{{ form.ui.label }}</label>
          <textarea class="{{ form.ui.inputclass }} resize-none" formControlName="{{ form.name }}" rows="6"
            maxlength="{{ form.validators[0].maxLength }}" cols="50"></textarea>
          <ng-container *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
            <div class="error color font14 ml-2" *ngIf="formGroup.get(form.name).hasError('required')">
              {{this.admin_data.loginFieldMandatory}}
            </div>
          </ng-container>
        </div>
        <div class="{{ form.class }} " *ngIf="form.type == 'radio'" class="d-inline-flex">
          <label class="{{ form.ui.class }}">{{ form.ui.label }}:</label>
          <svg-icon *ngIf="form.tooltip" src="../../../assets/svgs/icon-info.svg" class="ml-2 infoIcon"
            data-toggle="tooltip" data-placement="bottom" title="{{form.tooltip}}"></svg-icon>
          <ng-container *ngFor="let val of form.value; let m = index">
            <div class="custom-control-inline mx-4">
              <input type="radio" class="input-radio-custom" id="{{ val }}" formControlName="{{ form.name }}"
                name="{{ form.name }}" (keyup)="seeChanges()" value="{{ val }}" />
              <label class="ml-2" for="{{ val }}">{{
                form.value_labels[m]
                }}</label>
            </div>
          </ng-container>
          <div *ngIf="form.ui.message!=undefined" class="error color">
            {{form.ui.message}}
          </div>
          <div class="height12px font12">
            <ng-container *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
              <div *ngIf="formGroup['controls'][form.name].invalid" class="error color fs-small">
                {{ form.ui.label }} {{this.admin_data.isRequired}}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="{{ form.class }}" *ngIf="form.type == 'checkbox'">
          <label class="{{ form.ui.class }}">{{ form.ui.label }}:</label>
          <label class="switch mx-2 mb-0" (click)="toggle(j,$event.target.checked)">
            <input class="{{ form.ui.inputclass }} primary" formControlName="{{ form.name }}" type="{{ form.type }}"
              [value]="form.checked == true ? 1 : 0" [checked]="formGroup.get(form.name).value == '1' ? true : false"
              placeholder="{{ form.placeholder }}" />
            <span class="slider round" (click)="$event.stopPropagation()"></span>
          </label>
        </div>
        <div class="{{ form.class }}" *ngIf="form.type == 'file'">
          <label class="{{ form.ui.class }} my-1">{{ form.ui.label }}</label>
          <small class="d-flex reco-size mb-2">{{this.admin_data.recommendedSize}}</small>
          <div class="d-flex align-items-center justify-content-center flex-column p-4 border bw-1 dashed">
            <div class="{{ form.ui.inputclass }}">
              <img class="{{ form.fileclass }}" [src]="form.source" (error)="imageError(form.name)" />
              <input id="fileInput{{ j }}" class="m-auto d-none" type="{{ form.type }}" accept=".jpg,.png"
                formControlName="{{ form.name }}" name="{{ form.name }}" (keyup)="seeChanges()" value="{{ val }}"
                (change)="showFile($event.target.files, form)" />
            </div>
            <button class="btn btn-sm btn-secondary my-3" type="button" (click)="clickFileInput(j)">
              {{this.admin_data.chooseImage}}
            </button>
          </div>

          <div class="height12px font12">
            <ng-container *ngIf="form.name == 'first_slide'">
              <div class="error color" *ngIf="imageSize_error_flag_first">
                {{this.admin_data.recommendedSize2}}
              </div>
            </ng-container>

            <ng-container *ngIf="form.name == 'last_slide'">
              <div class="error color" *ngIf="imageSize_error_flag_last">
                {{this.admin_data.recommendedSize2}}
              </div>
            </ng-container>

            <ng-container *ngIf="form.name == 'uploadorg'">
              <div class="error color" *ngIf="imageSize_error_flag_organisation">
                {{this.admin_data.recommendedSize2}}
              </div>
            </ng-container>

            <ng-container *ngIf="form.name == 'app_image'">
              <div class="error color" *ngIf="imageSize_error_flag_user_image">
                {{this.admin_data.recommendedSize2}}
              </div>
            </ng-container>
          </div>


        </div>
        <div *ngIf="form.type == 'button'">
          <div class="float-left w-50px mr-100px" *ngIf="form.ui.label == 'Save'">
            <input type="{{ form.type }}" class="{{ form.class }}" value="{{ form.ui.label }}"
              [disabled]="formGroup.invalid" />
          </div>
          <div class="float-left w-50px mr-100px" *ngIf="form.ui.label == 'Cancel'">
            <input type="{{ form.type }}" class="{{ form.class }}" value="{{ form.ui.label }}" />
          </div>
          <div class="float-left w-50px mr-100px">
            <button class="{{ form.class }}" (click)="emitClicked(form.name)">{{ form.ui.label }}
            </button>
          </div>
        </div>
        <div *ngIf="form.type == 'svg_button'" class="float-left w-50px">
          <div (click)="emitSvgButtonClicked(form.name)" class="{{ form.class }}">
            <svg-icon role="button" data-toggle="tooltip" data-placement="bottom" title="{{form.tooltip}}"
              src="{{form.svg.src}}">
            </svg-icon>
          </div>
        </div>
        <div class="col-lg-12 p-0" *ngIf="form.type == 'heading'">
          <div class="{{form.class}}">
            <span class="{{form.text_class}}">{{form.name}}</span>
            <svg-icon *ngIf="form.tooltip" src="../../../assets/svgs/icon-info.svg" class="ml-2 infoIcon"
              data-toggle="tooltip" data-placement="bottom" title="{{form.tooltip}}"></svg-icon>
          </div>
        </div>
        <div *ngIf="form.type == 'regular_checkbox' && translationVisibility" class="{{form.class}}">
          <label class="{{form.ui.class}}">
            <input type="checkbox" (click)="toggle(j,$event.target.checked)" [value]="form.checked == true ? 1 : 0"
              [checked]="formGroup.get(form.name).value == '1' ? true : false" />
            <span class="checkmark mr-3"></span>
            <span class="mx-3 checkbox-label">{{form.ui.label}}</span>
          </label>
        </div>
        <div *ngIf="(form.type == 'form_table' && translations) && translationVisibility" class="{{form.class}}">
          <label class="{{form.ui.class}}"></label>
          <!-- <app-table [data]="form.data" [form_checks]="form.data" [titles]="form.titles" [dataKeys]="form.title_keys"></app-table> -->
          <table class="w-100">
            <thead>
              <tr>
                <th *ngFor="let title of form.titles">
                  <ng-container *ngIf="title == 'Mark All'">
                    <label class="custom-check">
                      <input type="checkbox" (click)="markAll($event.target.checked)" [checked]="isCheckedAll()" />
                      &nbsp; &nbsp; {{this.admin_data.selectAll}}
                      <span class="checkmark top-2"></span>
                    </label>
                  </ng-container>
                  <ng-container *ngIf="title !== 'Mark All'">{{title}}</ng-container>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let d of form.data;let h=index">
                <td *ngFor="let key of form.title_keys">
                  <ng-container *ngIf="key=='check'">
                    <label class="custom-check top--10">
                      <input type="checkbox" (click)=mark($event.target.checked,h) [checked]="checks[h]" />
                      <span class="checkmark"></span>
                    </label>
                  </ng-container>
                  <ng-container *ngIf="key !=='check' ">{{d[key]}}</ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="form.type == 'position_radio' || form.type == 'empty_position' || form.type == 'position_heading' "
          [ngClass]="{'m-20':form.type == 'position_heading'}" class="{{form.class}} pl-0 pb-3 container h-100 bg-grey">
          <label *ngIf="form.type == 'position_heading'" class="m-20 bg-grey col-lg-12 {{ form.ui.class }}">{{
            form.ui.label }}:</label>
          <div *ngIf="form.type == 'position_radio'" class="mb-20 button-wrap">
            <ng-container *ngFor="let val of form.value;let m=index">
              <input class="hidden radio-label" type="radio" id="{{form.name}}{{ val }}"
                formControlName="{{ form.name }}" name="{{ form.name }}" value="{{ val }}"
                [attr.disabled]="val=='bottom-right'?true:null">
              <label class="button-label" for="{{form.name}}{{ val }}"
                [ngClass]="{'top-left':m==0,'top-right':m==1,'bottom-left':m==2,'bottom-right':m==3}">
                <!-- <h1>{{form.value_labels[m]}}</h1> -->
              </label>
            </ng-container>
            <div class="position-info-box">
              <ng-container
                *ngIf="formGroup.get(form.name).value=='top-left'">{{this.admin_data.topLeft}}</ng-container>
              <ng-container
                *ngIf="formGroup.get(form.name).value=='top-right'">{{this.admin_data.topRight}}</ng-container>
              <ng-container
                *ngIf="formGroup.get(form.name).value=='bottom-left'">{{this.admin_data.bottomLeft}}</ng-container>
              <ng-container
                *ngIf="formGroup.get(form.name).value=='bottom-right'">{{this.admin_data.bottomRight}}</ng-container>
            </div>
            <div class="publish-text">Published Date : {{current_date | date:'dd/MM/yyyy'}}</div>
          </div>
          <!-- <input class="hidden radio-label" type="radio" name="accept-offers" id="no-button">
          <label class="button-label" for="no-button">
            <h1>No</h1>
          </label>
          <input class="hidden radio-label" type="radio" name="accept-offers" id="maybe-button">
          <label class="button-label" for="maybe-button">
            <h1>Maybe</h1>
          </label> -->
          <div *ngIf="form.type == 'empty_position'" class=" mb-20 empty-box">
            <div>{{form.name}}</div>
          </div>
        </div>
        <div class={{form.class}} *ngIf="form.type == 'number'">
          <label class={{form.label.class}} for={{form.label.for}}>{{form.label.title}}</label>
          <input formControlName={{form.name}} id="{{ form.input.id }}" class="{{ form.input.class }}"
            type="{{ form.type }}" autocomplete="off" pattern="^[0-9]*$" />
          <ng-container *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">

            <div class="error color mt-lg-2 mt-xl-2 ml-md-2 ml-xs-2 ml-sm-2"
              *ngIf="formGroup.get(form.name).hasError('min')">
              {{this.admin_data.valueShouldBeGreaterThan + " " + form.validators[0].minvalue}}
            </div>
            <div class="error color ml-2"
              *ngIf="formGroup.get(form.name).hasError('pattern') || formGroup.get(form.name).hasError('email')">
              {{this.admin_data.patternInvalid}}
            </div>
            <div class="error color font14 ml-2" *ngIf="formGroup.get(form.name).hasError('required')">
              {{this.admin_data.loginFieldMandatory}}
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="formData[19]">
      <div *ngIf="formData[19].formtype == 'admin_inline'" class="bg-white-smoke">
        <h4>{{this.admin_data.limitSettings}}</h4>
        <hr />
        <div class="row">
          <ng-container *ngFor="
              let form of formData.slice(19, formData.length);
              let j = index
            ">
            <div class="{{ form.class }}" *ngIf="form.type == 'inline_text'">
              <div>
                <label class="{{ form.ui.class }} pr-0">{{ form.ui.label }}:</label>
                <input class="{{ form.ui.inputclass }} p-none" type="{{ form.type }}" autocomplete="off"
                  formControlName="{{ form.name }}" />
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</form>