import adminLabels from "../ApplicationStrings/trans_utils";

export const FORGOT_FORMADATA = [
  {
    type: "text",
    name: "email",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2",
    layoutModal: true,
    validators: [
      {
        required: true,
      },
      {
        email: false,
      },
    ],
    ui: {
      label: adminLabels.addUserEmailId,
      class: "col-lg-12",
      inputclass: "col-lg-12",
    },
    input: {
      id: "email",
      class: "form-control",
      type: "text",
      name: "email",
      value: "",
      placeholder: adminLabels.domainFormatPlaceholder,
    },
    label: {
      title: adminLabels.addUserEmailId,
      class: "font-weight-bold",
      for: "email",
    },
  },
];
