import { Component, OnInit, Output } from "@angular/core";
import { OrganisationService } from "../../common/services/organisation.service";
import { UserService, User } from "../../common/services/user.service";
declare var jquery: any;
declare var $: any;
import { Organisation } from "../../common/services/organisation.service";
import { Router } from "@angular/router";
import { EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "src/app/common/services/toast-modal.service";
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

@Component({
  selector: "app-organisation",
  templateUrl: "./organisation.component.html",
  styleUrls: ["./organisation.component.scss"],
})
export class OrganisationComponent implements OnInit {


  @Output() dashboard = new EventEmitter();

  constructor(
    public userService: UserService,
    private organisationService: OrganisationService,
    private router: Router,
    private toastr: ToastrService,
    private errorResponseService : ErrorResponseService,
    private toastService:ToastModalService
  ) { 
    this.initializeShowIcons();
  }

  ngOnInit() {
    //console.log(this.userService)
    // this.userService.getUserData();
this.organisationService.getCDNData()
    this.getOrganisationList();
    // this.userService.organization.name=" ";
    // this.userService.organization_id="000";
    this.searchInputOrg = document.getElementById("searchInputOrg");
  }

  admin_data = adminLabels ;
  organisations: Organisation[];
  selected = [false, false, false, false];
  tickindex;
  verifyChecked = [false, false, false, false, false];
  activeChecked = [false, false, false, false, false];
  deleteIndex = 0;
  limit = 20;
  offset = 0;
  est_limit=100;
  estimated=false;

  searchInputOrg;
  searchText = '';
  isSearch = false;

  pages = [1, 2];
  currentPage = 1;
  prevIndex = 0;
  currentIndex = 1;
  total = 0;
  pageLimit: number = 0;
  disableNext = false;
  disablePrev = false;
  showPagination = false;
  showIcons=[];
  navigated=false;
  activeTab = "active";
  modalHeader;
  modalContent;
  modalOkText;
  orgName;
    assignedAdmins= [];
  getOrganisationList(search='') {
    this.organisations = []
    if(!this.estimated){
      this.limit=this.est_limit;
    }
    if(this.activeTab == "active"){
    this.organisationService
      .getOrganisations(this.est_limit, this.offset,search)
      .subscribe(
        (response) => {
          if(this.toastService.showToast){
            this.toastService.showToastMessage(this.toastService.toastType);
          }
          this.processResponse(response);
          //this.organisations = response["data"]["organizations"];
          // this.organisations.forEach((elem)=>{
          //   if(elem.organization_id==this.userService.organization_id){
          //     this.tickindex=this.organisations.indexOf(elem);
          //   }
          // })

          // this.userService.organization_id = "000";
          // this.userService.organization.name = "";


          // let appData = JSON.parse(localStorage.getItem("appData"));
          // appData["organization"] = this.userService.organization.name;
          // appData["organization_id"] = this.userService.organization_id;
          // localStorage.setItem("appData", JSON.stringify(appData));

          // let org = this.userService.organization;
          // localStorage.setItem("organization", JSON.stringify(org));
        },
        (error) => {
        }
      );
    }else{ 
        if(this.toastService.showToast){
        this.toastService.showToastMessage(this.toastService.toastType);
      }
        this.organisationService.getSuspendedDeletedOrgs(this.est_limit, this.offset, search).subscribe((response)=>{
            this.processResponse(response);
            
        })
    }
  }

  processResponse(response) {
    this.initializeShowIcons();
    let search = this.searchText;
    this.deleteIndex=0;

    let begin=1;
    let end = begin;
    let det = 2;
    if(this.activeTab === 'active' && (this.userService.user.role === 'superadmin' || this.userService.user.role === 'partner_admin')){
        response['data']['organizations'] = response['data']['organizations'].filter((org)=>{
            if(org['is_suspend'] === "0")
                return true;
        })
    }
    
      let orgs = response['data']['organizations'];
      if(orgs.length==this.est_limit){
        begin=this.currentPage-2;
        end = this.currentPage+2;
        if(begin>0){
        this.pages=[this.currentPage-2,this.currentPage-1,this.currentPage,this.currentPage+1,this.currentPage+2]
        }else{
          this.pages=[1,2,3,4,5];
          this.navigated=false;
        }
        this.estimated=false;

      }else{
        if(orgs.length>this.est_limit-20){
          begin=this.currentPage-3;
          end = this.currentPage+2;
          if(begin>0){
          this.pages=[this.currentPage-3,this.currentPage-2,this.currentPage-1,this.currentPage,this.currentPage+1];
          this.navigated=true;
          }else{
            if(!this.navigated){
            this.pages=[1,2,3,4];
            this.navigated=false;
            }
          }
          this.estimated=true;
        }else{
          begin=this.currentPage-4;
          end = this.currentPage+2;
          if(begin>0){
            this.pages=[this.currentPage-4,this.currentPage-3,this.currentPage-2,this.currentPage-1,this.currentPage];
            this.navigated=true;
          }else{
            if(!this.navigated){
            this.pages=[1,2];
            this.navigated=false;
            }
          }
          this.estimated=true;
        }
      }

    this.organisations = response["data"]["organizations"].slice(0,20);
    if (this.currentPage > 1 && this.organisations.length < 1) {
      this.prependOffsetAndGetOrganisations();
    }
    if (this.organisations.length < 20) {
      this.disableNext = true;
    } else {
      this.disableNext = false;
      this.showPagination = true;
    }

    if(orgs.length==this.organisations.length && this.offset==0){
      this.showPagination=false;
    }else{
      this.showPagination=true;
    }

    if (this.currentPage == 1) {
      this.disablePrev = true;
    } else {
      this.disablePrev = false;
      this.showPagination = true;
    }
    // if (this.organisations.length == this.limit) {
    //   if (res["data"]["applications"].length > this.applications.length) {
    //     this.showPagination = true;
    //   } else {
    //     this.showPagination = false;
    //   }
    // this.organisationService.cdn_signature = this.userService.user.cdn_signature;
    // this.organisationService.cdn_signature_expiry = this.userService.user.cdn_signature_expiry;
}

initializeShowIcons(){
  this.showIcons = new Array(20);
  for(let i=0;i<this.showIcons.length;i++){
    this.showIcons[i]=false;
  }
}

  // getOrganisationList(search) {
  //   this.organisationService
  //     .getOrganisations(this.limit, this.offset, search)
  //     .subscribe(
  //       (response) => {
  //         this.organisations = response["data"]["organizations"];
  //       },
  //       (error) => {
  //       }
  //     );
  // }

  appendOffsetAndGetOrganisations() {
    this.disableNext=true;
    this.currentPage++;
    this.currentIndex = this.currentPage;
    if (this.currentPage > 0) {
      if (this.currentPage > this.pages[this.pages.length - 1]) {
        this.pages.shift();
        this.pages.push(this.currentPage);
        this.navigated=true;
      }
      this.offset += 20;
      if (!this.isSearch) {
        this.getOrganisationList();
      } else {
        this.getOrganisationList(this.searchInputOrg.value);
      }
    }
  }

  prependOffsetAndGetOrganisations() {
    this.disablePrev=true;
    this.currentPage--;
    this.currentIndex = this.currentPage;
    if (this.currentPage > 0 && this.currentPage < this.pages[0]) {
      this.pages.pop();
      this.pages.unshift(this.currentPage);
      this.navigated=true;
    }
    this.offset -= 20;
    if (!this.isSearch) {
      this.getOrganisationList();
    } else {
      this.getOrganisationList(this.searchInputOrg.value);
    }
  }

  setndex(ind) {
    if (ind != this.currentIndex) {
      this.prevIndex = this.currentIndex;
      this.currentIndex = ind;
      this.currentPage = this.currentIndex;
      this.calculateOffsetAndGetOrganisations(
        this.prevIndex,
        this.currentIndex
      );
    }
  }

  calculateOffsetAndGetOrganisations(prev, current) {
    if (current > prev) {
      this.offset = (current - prev) * 20 + this.offset;
    } else {
      this.offset = this.offset - (prev - current) * 20;
    }
    if (!this.isSearch) {
      this.getOrganisationList();
    } else {
      this.getOrganisationList(this.searchInputOrg.value);
    }
  }

  select(ind) {
    console.log("ABC 3");
    
    this.tickindex = ind;
    // this.userService.organization.name = this.organisations[ind].name;
    this.userService.organization_id = this.organisations[
      ind
    ].organization_id;    
    this.userService.dashboardLoading = true;
    // this.organisationService
    //   .getSignature(this.userService.organization_id)
    //   .subscribe((response) => {
    //     let data = response["data"];
    //     this.organisationService.cdn_signature = data["cdn_signature"];
    //     this.organisationService.cdn_signature_expiry =
    //       data["cdn_signature_expiry"];
        // this.userService.user.cdn_signature = data["cdn_signature"];
        // this.userService.user.cdn_signature_expiry =
        //   data["cdn_signature_expiry"];
        // let appData = JSON.parse(localStorage.getItem("appData"));
        // appData["organization"] = this.userService.organization.name;
        // appData["organization_id"] = this.userService.organization_id;
        // appData["cdn_signature"] = this.organisationService.cdn_signature;
        // appData[
        //   "cdn_signature_expiry"
        // ] = this.organisationService.cdn_signature_expiry;

        // let cdn_sign = {};
        // cdn_sign["cdn_signature"] = this.organisationService.cdn_signature;
        // cdn_sign[
        //   "cdn_signature_expiry"
        // ] = this.organisationService.cdn_signature_expiry;
        // this.userService.user.cdn_signature = this.organisationService.cdn_signature;
        // this.userService.user.cdn_signature_expiry = this.organisationService.cdn_signature_expiry;
        // this.organisationService
        //   .getOrganization(this.userService.organization_id)
        //   .subscribe((res) => {
        //     // appData["org"] = res["data"]["organization"];

        //     let org = res["data"]["organization"];
        //     // localStorage.setItem("appData", JSON.stringify(appData));

        //     localStorage.setItem("organization", JSON.stringify(org));
        //     localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
        //     console.log('this.userService.organization_id: ' +this.userService.organization_id)
        //     localStorage.setItem("active_org_id", this.userService.organization_id);  

        //     this.searchText = '';
        //     // this.searchOrganiations(13);
        //     console.log(this.userService.organization_id)
        //    


        //     // this.dashboard.emit(org);
        //   });
        
        this.router.navigate([
                "organization",
                this.userService.organization_id,
            ]);
    //   });
  }

  edit(ind) {
    this.tickindex = ind;
    this.userService.organization_id = this.organisations[
      ind
    ].organization_id;
    this.organisationService
      .getSignature(this.userService.organization_id)
      .subscribe((response) => {
        let data = response["data"];
        this.organisationService.cdn_signature = data["cdn_signature"];
        this.organisationService.cdn_signature_expiry =
          data["cdn_signature_expiry"];
  

        let cdn_sign = {};
        cdn_sign["cdn_signature"] = this.organisationService.cdn_signature;
        cdn_sign[
          "cdn_signature_expiry"
        ] = this.organisationService.cdn_signature_expiry;

        this.organisationService
          .getOrganization(this.userService.organization_id)
          .subscribe((res) => {

            let org = res["data"]["organization"];

            localStorage.setItem("organization", JSON.stringify(org));
            localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
            this.searchText = '';
            this.searchOrganiations(13);
            this.router.navigate([
              "edit_organization",
              this.userService.organization_id,
            ]);
          });
      });
  }

  toggleCheck(checked) {
    checked = !checked;
  }

  showModal(i) {
    this.deleteIndex = i;
    this.userService.showModal.next(true);
  }

  closeModal() {
    $("#exampleModal").modal("hide");
  }

  deleteOrganisation(id) {
    this.userService.delete_button.disable=true;
    this.userService.delete_button.text=this.admin_data.deletingButtonText;
    if (this.deleteIndex == this.tickindex) {
      this.tickindex = -1;
    }
    this.organisationService.deletOrganisation(this.organisations[this.deleteIndex]['organization_id']).subscribe((response) => {
      this.userService.delete_button.disable=false;
      this.userService.delete_button.text=this.admin_data.deleteButtonText;
      this.getOrganisationList();
      this.userService.showModal.next(false);
      this.deleteIndex = 0;
      this.toastService.showToast=true;
      this.toastService.toastType="delete_org";
    },error=>{
      this.userService.delete_button.disable=false;
      this.userService.delete_button.text=this.admin_data.deleteButtonText;
    });
  }

  searchOrganiations(value) {
    if (value == 13) {
      this.isSearch = true;
      this.limit = 20;
      this.offset = 0;

      this.pages = [1, 2];
      this.currentPage = 1;
      this.prevIndex = 0;
      this.currentIndex = 1;
      this.total = 0;
      this.pageLimit = 0;
      this.getOrganisationList(this.searchText);
    }
  }

  changeVerify(organisation, checked) {
    let request = {
      organization_id: organisation.organization_id,
      approval_status: checked == true ? "1" : "0",
    };
    this.organisationService.changeVerify(request).subscribe((response) => {
      this.toastService.showToast=true;
      this.toastService.toastType="change_verify_status";
      this.getOrganisationList();
    });
  }

  changeActive(organisation, checked) {
    let request = {
      organization_id: organisation.organization_id,
    };
    this.organisationService.changeSuspend(request).subscribe((response) => {
      this.toastService.showToast=true;
      this.toastService.toastType="change_active_status";
      this.getOrganisationList();
    });
  }

  editOrg(org) {
    console.log(org.organization_id)
  }

  validateInput(event: Event) {
   this.userService.validateInput(event);
  }

  changeTab(type){
    this.activeTab= type
    this.searchText = "";
    this.limit = 20;
    this.offset = 0;
    this.currentPage = 1;
    this.prevIndex = 0;
    this.currentIndex = 1;
    this.total = 0;
    this.pageLimit = 0;
    this.getOrganisationList();
}

    decommissionOrg(deleteIndex){
        this.modalHeader = this.admin_data.decommissionBtn + " "
        this.modalContent = this.admin_data.decommissionContent
        this.deleteIndex = deleteIndex
        this.orgName = this.organisations[deleteIndex]['name']
        this.modalOkText = this.admin_data.decommissionBtn;
        this.organisationService.showConfirmationModal.next(true);
    }

    deleteBackup(deleteIndex){
        this.modalHeader = this.admin_data.deleteBackupOf
        this.modalContent = this.admin_data.deleteBackupContent
        this.deleteIndex = deleteIndex
        this.orgName = this.organisations[deleteIndex]['name']
        this.modalOkText = this.admin_data.deleteBackupBtn;
        this.organisationService.showConfirmationModal.next(true);        
    }


    decommissionOrDeleteBackup($event){
        if(this.modalOkText === this.admin_data.decommissionBtn){
            console.log(this.organisations[this.deleteIndex]);
            
        this.organisationService.deletOrganisation(this.organisations[this.deleteIndex]['organization_id']).subscribe((response) => {
            this.organisationService.showConfirmationModal.next(false);
            this.getOrganisationList();
          },error=>{
          });
        }else if(this.modalOkText === this.admin_data.deleteBackupBtn){
            this.organisationService.deleteBackup(this.organisations[this.deleteIndex]['organization_id']).subscribe((response) => {
                this.organisationService.showConfirmationModal.next(false);
                this.getOrganisationList();
              },error=>{
              });
        }
    
    }

    checkThirtyDaysPassed(date){
        return (Math.floor(Date.now()/1000)-date >= 30*24*60*60)
    }

    openAssignedAdminsModal(ind){
        
        let org_id = this.organisations[
            ind
        ].organization_id;
        this.organisationService.getAssignedAdminsForOrg(org_id).subscribe((response) => {
            this.assignedAdmins = []
            if(!response['error']){
                response['data'][0].forEach(user => {
                    this.assignedAdmins.push(user['first_name'] + ' ' + user['last_name'])
                });
            }
            $("#mgAdminModal").modal("show");
            
        })
    }

    closeAssignedAdminsModal(){
        $("#mgAdminModal").modal("hide");
    }
}
