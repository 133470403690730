import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/common/services/user.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Theme } from '../theme.model';
import { OrganisationService } from 'src/app/common/services/organisation.service';
import { ThemeService } from '../../common/services/theme.service';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-theme-view',
  templateUrl: './theme-view.component.html',
  styleUrls: ['./theme-view.component.css']
})

export class ThemeViewComponent implements OnInit {
  org_id;
  theme_id: string;
  theme: Theme;
  cdn_sign: string;
  theme_settings: object;
  current_tab = 1;
  selectedOrg;
  initialized = false;
  breadcrumb;

  admin_data = adminLabels ;

  constructor(
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private orgService: OrganisationService,
    private themeService: ThemeService,
    private toastr: ToastrService,
    private titleService: Title,
    public organisationService: OrganisationService
  ) {
    this.titleService.setTitle(this.admin_data.themeSettings + " | " + "Cornerstone Guide");
    this.userService.activeTab = 'themes';
    this.userService.getUserData();
    this.route.paramMap.subscribe((params) => {
      this.theme_id = params["params"]["theme_id"];
      this.org_id = params["params"]["org_id"];
      this.initialized = false;



      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          // this.userService.setBreadcrumbs("Edit", this.route.url['_value']);
          if (params['params']['org_id']) {
            this.userService.setBreadcrumb([
              {
                name: this.admin_data.themes,
                route: ['/organization', this.org_id, 'themes']
              },
              {
                name: this.admin_data.tableTitle2,
                route: null
              }
            ])
          }
          this.breadcrumb=this.userService.breadCrumbs;
        });
      this.ngOnInit();
    });


  }

  ngOnInit() {
    if (this.org_id && !this.initialized) {
      this.initialized = true;
      if (this.userService.user.role != "superadmin") {
        this.org_id = this.userService.organization_id;
        this.setTheme();
        this.setCdnSign();
      } else if (
        this.userService.user.role == "superadmin"
      ) {
        this.setTheme();
        this.setCdnSign();
      }
    }

  }

  changeTab(val) {
    this.current_tab = val;
  }
  setTheme() {
    this.orgService.getOrganization(this.org_id).subscribe(
      (response) => {
        let data = response["data"];


        let organization = response["data"]["organization"];
        let org = organization;

        this.userService.organization = organization["name"];
        this.userService.organization_id = organization["organization_id"];
        this.selectedOrg = this.userService.organization_id;
        // this.userService.user.cdn_signature = organization["cdn_signature"];
        this.userService.organization = organization;
        let admin_settings = JSON.parse(
          this.userService.organization.admin_settings
        );
        this.userService.enableSegments =
          admin_settings["guide_segmentation"] == "1" ? true : false;
        this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
        this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
        this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
        this.userService.enableDisplayPublishDate = admin_settings['display_publish_date'] ==0 ? false:true; 

        this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
        this.userService.enableMediaThemes=admin_settings['media_files_themes'] == 0 ? false : true;

        if(!this.userService.enableMediaThemes){
          this.router.navigate([
            "/organization",
            this.userService.organization_id
        ]);
        }


        let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

        cdn_sign["cdn_signature"] = organization["cdn_signature"];
        cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

        this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
        this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

        localStorage.setItem("organization", JSON.stringify(org));
        localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));

        this.theme_settings = org['theme_settings'] ? JSON.parse(org["theme_settings"]) : '';


        let themes = (this.theme_settings && this.theme_settings["themes"]) ? this.theme_settings["themes"] : false;

        if (themes
          && themes[this.theme_id]
          && themes[this.theme_id] != null
          && themes[this.theme_id] != undefined) {
          this.theme = themes[this.theme_id];
        }
        else {
          this.theme = this.themeService.getDefaultTheme(this.theme_id);
        }
      }
    );
  }

  setCdnSign() {
    // this.orgService.getSignature(this.org_id).subscribe(
    //   (response) => {
    //     let data = response["data"];
    //     this.cdn_sign = data["cdn_signature"];
    //   });
    let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));
    this.cdn_sign = cdn_sign['cdn_signature'];
  }

  updateTheme() {
    if (this.theme_settings
      && this.theme_settings["themes"] != null
      && this.theme_settings["themes"] != undefined
    ) {
      this.theme_settings["themes"][this.theme_id] = this.theme;
    } else {
      let theme_1 = this.themeService.getDefaultTheme("theme-1");
      this.theme_settings = {
        "activeThemeId": "theme-1",
        "themes": { "theme-1": theme_1 }
      };

      if (this.theme_id != "theme-1") {
        this.theme_settings["themes"][this.theme_id] = this.theme;
      }
    }

    let request: object = {
      "theme_settings": JSON.stringify(this.theme_settings),
      "organization_id": this.org_id
    }

    this.orgService.updateThemeSetting(request).subscribe(
      () => {
        this.toastr.success(this.admin_data.successfullyText, this.admin_data.successfullyText, {
          timeOut: 2000
        });
      },
      () => {
        this.toastr.error(this.admin_data.successfullyText, this.admin_data.failed, {
          timeOut: 2000
        });
      }
    );
  }
  
}
