import { Component, OnInit } from "@angular/core";
import { SegmentationService, Segmentation } from "../../common/services/segmentation.service";
import { ToastrService } from 'ngx-toastr';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { UserService } from "../../common/services/user.service";
import { SEGMENT_LIST_FORMADATA } from "../../constants/FormData/segment-list";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { OrganisationService } from "../../common/services/organisation.service";
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "../../common/services/toast-modal.service";
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
declare var $: any;

@Component({
  selector: "app-segment",
  templateUrl: "./segment.component.html",
  styleUrls: ["./segment.component.css"],
})
export class SegmentComponent implements OnInit {
  org_id;
  selectedOrg;
  admin_data = adminLabels ;
    modalHeader: any;
    modalContent: any;

  constructor(
    public segmentation: SegmentationService,
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private organizationService: OrganisationService,
    private toastr: ToastrService,
    private titleService: Title,
    private errorResponseService : ErrorResponseService,
    private toastService: ToastModalService,
    public organisationService: OrganisationService
  ) {
      this.titleService.setTitle(this.admin_data.userAttributes + " | " + "Cornerstone Guide");
    this.userService.getUserData();
    if (this.userService.user.role != "superadmin" && !this.userService.enableSegments) {
      this.router.navigate([
        "/organization",
        this.userService.organization_id
      ]);
    }
    this.organizationService.getCDNData()
    this.userService.activeTab = 'segments';
    this.route.paramMap.subscribe((params) => {
      this.org_id = params["params"]["org_id"];
      if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" )) {
        this.organizationService
          .getOrganization(this.org_id)
          .subscribe((response) => {
            let organization = response["data"]["organization"];

            this.userService.organization = organization["name"];
            this.userService.organization_id =
              organization["organization_id"];
            // this.userService.user.cdn_signature = organization["cdn_signature"];
            this.userService.organization = organization;
            let admin_settings = JSON.parse(
              this.userService.organization.admin_settings
            );
            this.userService.enableSegments =
              admin_settings["guide_segmentation"] == "1" ? true : false;
            this.userService.enableSSO =
              admin_settings["sso"] == 1 ? true : false;
            this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
            this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
            this.userService.enableMediaThemes=admin_settings['media_files_themes'] == 0 ? false : true;
            this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
            if (!this.userService.enableSegments) {
              this.router.navigate([
                "/organization",
                this.userService.organization_id
              ]);
            }

            let org = organization;
            let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

            cdn_sign["cdn_signature"] = organization["cdn_signature"];
            cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

            this.organizationService.cdn_signature = cdn_sign["cdn_signature"];
            this.organizationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

            localStorage.setItem("organization", JSON.stringify(org));
            localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
            this.selectedOrg = this.userService.organization_id;
            // this.ngOnInit();
          });
      }
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // this.userService.setBreadcrumbs(
        //     this.admin_data.segments,
        //     this.route.url["_value"]
        // );
        this.userService.setBreadcrumb([
          {
            name: this.admin_data.userAttributes,
            route: null
          }
        ])
        this.breadcrumb=this.userService.breadCrumbs;
      });
  }

  limit = 10;
  offset = 0;
  segmentations: Segmentation[];
  segment_formData = SEGMENT_LIST_FORMADATA;

  pages = [1, 2];
  currentPage = 1;
  prevIndex = 0;
  currentIndex = 1;
  total = 0;
  pageLimit: number = 0;
  disableNext = false;
  disablePrev = false;
  showPagination = false;
  images;
  formData;
  searchInputApp;
  deleteIndex = 0;
  delete_segment_id;
  formGroup;
  breadcrumb;

  ngOnInit() {
    if (
      this.userService.user.role != "superadmin" ||
      (this.userService.user.role == "superadmin" &&
        this.selectedOrg == this.userService.organization_id)
    ) {
      this.getSegmentations();
    }
    let form = {};
    for (let i = 0; i < this.segment_formData.length; i++) {
      form[this.segment_formData[i].name] = new FormControl("");
      let validations = [];
      for (let v of this.segment_formData[i].validators) {
        for (let key in v) {
          validations.push(key);
        }
      }
      let validators = [];
      for (let j = 0; j < validations.length; j++) {
        switch (validations[j]) {
          case "required":
            validators.push(Validators.required);
            break;

          case "email":
            validators.push(Validators.email);
            break;
        }
      }
      form[this.segment_formData[i].name].setValidators(validators);
    }
    this.formGroup = new FormGroup(form);
    this.formGroup.get("key_name").valueChanges.subscribe(x => {
      if (x && !this.userService.pattern.test(x)) {
        this.formGroup.get("key_name").setValue(x.replace(this.userService.replacePattern, ""),{ emitEvent: false });
      }
     });
    
    if (this.segmentation.isEdit) {
      for (let i = 0; i < this.segment_formData.length; i++) {
        if (this.segmentation.selectedSegment && this.segmentation.selectedSegment[this.segment_formData[i].name]) {
          this.formGroup.patchValue({
            [this.segment_formData[i].name]: this.segmentation.selectedSegment[
              this.segment_formData[i].name
            ],
          });
        }
      }
    }
  }

  getSegmentations() {
    this.segmentation.getSegmentations(this.org_id).subscribe((response) => {
      if (!response['error']) {
        this.segmentations = response["data"]["segments"];
        if (this.toastService.showToast) {
          this.toastService.showToastMessage(this.toastService.toastType);
        }
      } else {
        this.userService.showModal.next(false);
        let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
        this.userService.errorMessage=errorResponse;
        $("#errorModal").modal("show");
      }
    });
  }

  showAddSegmentModal() {
    $("#addSegmentModal").modal("show");
    this.resetFormData();
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
    this.segmentation.isEdit = false;
  }

  closeAddSegmentModal() {
    this.resetFormData();
    $("#addSegmentModal").modal("hide");
  }

  resetFormData() {
    for (let i = 0; i < this.segment_formData.length; i++) {
      this.formGroup.patchValue({
        [this.segment_formData[i].name]: "",
      });
    }
    this.segmentation.selectedSegment = null;
  }

  addSegmentation(value) {
    this.segmentation.add_button.disable = true;
      this.segmentation.add_button.text = adminLabels.addingUserAttributeButtonText;

    let keyName = this.formGroup.get("key_name").value.trim();
    keyName = keyName[0].toUpperCase() + keyName.slice(1);

    let request = {
      key_name: keyName,
      is_required: this.formGroup.get("is_required").value,
      organization_id: this.userService.organization_id,
    };

    this.segmentation.addSegment(request).subscribe((response) => {
      this.segmentation.add_button.disable = false;
        this.segmentation.add_button.text = adminLabels.addUserAttributesButtonText;
      if (!response['error']) {
        if (response["code"] == 1000) {

          this.toastService.showToast = true;
          this.toastService.toastType = "add_segment";
          this.getSegmentations();
          this.closeAddSegmentModal();
        }
      } else {
        this.closeAddSegmentModal();
        this.userService.showModal.next(false);
        let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
        this.userService.errorMessage=errorResponse;
        $("#errorModal").modal("show");
      }
    }, (error) => {
      this.segmentation.add_button.disable = false;
        this.segmentation.add_button.text = adminLabels.addUserAttributesButtonText;
    });
  }

  editSegmentation(value) {
    this.segmentation.update_button.disable = true;
      this.segmentation.update_button.text = adminLabels.updatingUserAttributeButtonText;

    let keyName = this.formGroup.get("key_name").value.trim();
    keyName = keyName[0].toUpperCase() + keyName.slice(1);

    let request = {
      id: parseInt(this.segmentation.selectedSegment.id),
      key_name: keyName,
      is_required: this.formGroup.get("is_required").value,
    };

    this.segmentation.editSegment(request).subscribe((response) => {
      this.segmentation.update_button.disable = false;
        this.segmentation.update_button.text = adminLabels.updateUserAttributesButtonText
      if (response["code"] == 1000) {
        this.resetFormData();
        this.toastService.showToast = true;
        this.toastService.toastType = "update_segment";
        this.getSegmentations();
        this.closeAddSegmentModal();
      }
    }, (error) => {
      this.segmentation.update_button.disable = false;
        this.segmentation.update_button.text = adminLabels.updateUserAttributesButtonText
    }
    );
  }

  getSegmentData(segment) {
    this.showAddSegmentModal();
    this.segmentation.isEdit = true;
    this.segmentation.selectedSegment = segment;
    for (let i = 0; i < this.segment_formData.length; i++) {
      if (this.segmentation.selectedSegment[this.segment_formData[i].name]) {
        this.formGroup.patchValue({
          [this.segment_formData[i].name]: this.segmentation.selectedSegment[
            this.segment_formData[i].name
          ],
        });
      }
    }
  }


  showDeleteModal(id) {
    this.segmentations.forEach((segment, i) => {
      if (segment.id == id) {
        this.deleteIndex = i;
        this.delete_segment_id = id;
      }
    });
    this.modalHeader = this.admin_data.deleteUserAttribute;
    let userAttribute = this.segmentations[this.deleteIndex]['key_name']
    let str = this.admin_data.deleteUserAttributeContent
    str = str.split(' ')
    str.splice(str.length-2, 0,"'" + userAttribute + "'")
    this.modalContent = str.join(' ');
    this.organisationService.showConfirmationModal.next(true);
  }

  closeDeleteModal() {
    $("#deleteSegModal").modal("hide");
  }

  deleteSegmentation(segment) {
    this.userService.delete_button.disable = true;
    this.userService.delete_button.text=this.admin_data.deletingButtonText;
    this.segmentation.deleteSegmentation(this.delete_segment_id).subscribe(
      (response) => {
        this.organisationService.showConfirmationModal.next(false);
        this.deleteIndex = 0;
        this.userService.delete_button.disable = false;
        this.userService.delete_button.text=this.admin_data.deleteButtonText;
        this.toastService.showToast = true;
        this.toastService.toastType = "delete_segment";
        this.getSegmentations();
        this.closeDeleteModal();

      },
      (error) => {
        this.organisationService.showConfirmationModal.next(false);
        this.userService.delete_button.disable = false;
        this.userService.delete_button.text=this.admin_data.deleteButtonText;
      }
    );
  }

  showSegmentList(segment) {
    this.router.navigate([
      "/segment-list",
      this.userService.organization_id,
      segment["id"]
    ]);
  }

    navigateToImport() {
        this.router.navigate(['/segment', this.org_id, 'import'])
    }

}
