import { Component, OnInit, OnDestroy } from "@angular/core";
import { ORG_FORMADATA } from "../../../constants/FormData/organisation-form";
import { ToastrService } from 'ngx-toastr';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { User, UserService } from "../../../common/services/user.service";
import { OrganisationService, Organisation } from "../../../common/services/organisation.service";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { ApplicationService } from "../../../common/services/application.service";
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { Subscription, forkJoin } from 'rxjs';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "src/app/common/services/toast-modal.service";
import adminLabels from "../../../constants/ApplicationStrings/trans_utils";
import { AdminSettingsService } from "src/app/common/services/admin-settings.service";



@Component({
  selector: "app-create-organisation",
  templateUrl: "./create-organisation.component.html",
  styleUrls: ["./create-organisation.component.scss"],
})
export class CreateOrganisationComponent implements OnInit, OnDestroy {
  org_id;
  count = 0;
  admin_data = adminLabels ;
  constructor(
   public userService: UserService,
    private organisationService: OrganisationService,
    private route: ActivatedRoute,
    private application: ApplicationService,
    private router: Router,
    private toastr:ToastrService,
    private titleService:Title,
    private errorResponseService : ErrorResponseService,
      private toastService: ToastModalService,
    private adminSettingsService: AdminSettingsService
  ) {
    this.titleService.setTitle( this.admin_data.organisationEditText +" | "+"Cornerstone Guide");
   this.userService.getUserData();
this.organisationService.getCDNData()
    this.route.paramMap.subscribe((params) => {
      this.org_id = params["params"]["org_id"];
      // this.userService.organization_id='000';
      this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // this.userService.setBreadcrumbs("Edit-Organization",this.route.url['_value']);
        if(params['params']['org_id']){
          this.userService.setBreadcrumb([
            {
              name:this.admin_data.organisationEditText,
              route:null
            }
          ])
        }
        this.breadcrumb=this.userService.breadCrumbs;
      });
    });

    
  }

  org_formData = JSON.parse(JSON.stringify(ORG_FORMADATA));
  formGroup;
  organization: any;
  orgformData = new FormData();
  firstSlideformData = new FormData();
  lastSlideformData = new FormData();
  imageData = [];
  imageIds = [];
  imageUploads = [];
  breadcrumb;
  numOfDomains = 0;
  firstSlideIndex = 16;
  admin_settings = {};
  sub1: Subscription;

  ngOnDestroy() {
    if (this.sub1 != undefined){
      this.sub1.unsubscribe();
    }
  }

  ngOnInit() {
    let form = {};
    for (let i = 0; i < this.org_formData.length; i++) {
      form[this.org_formData[i].name] = new FormControl("");
      let validations = [];
      for (let v of this.org_formData[i].validators) {
        for (let key in v) {
          validations.push(key);
        }
      }
      let validators = [];
      for (let j = 0; j < validations.length; j++) {
        switch (validations[j]) {
          case "required":
            validators.push(Validators.required);
            break;

          case "email":
            validators.push(Validators.email);
            break;
        }
      }
      form[this.org_formData[i].name].setValidators(validators);
    }
    form["upload_org_id"] = new FormControl("");
    form["first_slide_id"] = new FormControl("");
    form["last_slide_id"] = new FormControl("");
    form["upload_org_path"] = new FormControl("");
    form["first_slide_path"] = new FormControl("");
    form["last_slide_path"] = new FormControl("");
    this.formGroup = new FormGroup(form);

    this.org_formData.splice(5,0,{
      type: 'heading',
      name: this.admin_data.organisationHeading1,
      class:'my-4',
      text_class:'heading_text',
      tooltip: this.admin_data.organisationTooltip1
    },);

    // this.formGroup.patchValue({
    //   first_slide_display_position:'top_left'
    // })

    console.log(this.userService.organization_id)


    if(this.userService.user.role !='superadmin' && this.userService.user.role !='partner_admin'){
        this.org_id = this.userService.organization_id;
        let productTypeIndex = this.org_formData.findIndex(el => el.name === 'product_type')
        this.org_formData.splice(productTypeIndex, 1);
        this.firstSlideIndex--;
    }
      
    //   if (this.userService.user.role != 'superadmin') {
    //       this.org_id = this.userService.organization_id;
    //       let productTypeIndex = this.org_formData.findIndex(el => el.name === 'insights_customer_type')
    //       this.org_formData.splice(productTypeIndex, 1);
    //   }

    this.organisationService
      .getOrganization(this.org_id, true)
      .subscribe((response) => {

        let organization = response["data"]["organization"];

        if((this.userService.user.role == 'superadmin'  || this.userService.user.role == "partner_admin")&& this.userService.organization_id!='000'){
        this.userService.organization = organization["name"];
        this.userService.organization_id = organization["organization_id"];

            this.userService.organization = organization;
        // let admin_settings = JSON.parse(
        //     this.userService.organization.admin_settings
        //     );

            this.admin_settings = JSON.parse(
                this.userService.organization.admin_settings
            );
        this.userService.enableSegments = this.admin_settings["guide_segmentation"] == "1" ? true : false;
            
        this.userService.enableSSO = this.admin_settings["sso"] == 1 ? true : false;
        this.userService.enableConnector = this.admin_settings['api_connector'] == 1 ? true : false;
        this.userService.enableTaskList = this.admin_settings['task_list'] == 1 ? true : false;
        this.userService.enableMediaThemes = this.admin_settings['media_files_themes'] == 0 ? false : true;
        this.userService.enableDisplayPublishDate = this.admin_settings['display_publish_date'] ==0 ? false:true; 
            this.userService.enablePlayerPackages = this.admin_settings['self_hosted_player'] == "1" ? true : false;
            this.formGroup
        let org = organization;
        let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

        cdn_sign["cdn_signature"] = organization["cdn_signature"];
        cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

        this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
        this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

        localStorage.setItem("organization", JSON.stringify(org));
        localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
        }

        this.organization = response["data"]["organization"];
        let admin_settings = JSON.parse(
            this.userService.organization.admin_settings
          );          
        this.userService.enableDisplayPublishDate = JSON.parse(this.organization.admin_settings)['display_publish_date'] == 0 ? false:true; 
        let settings = JSON.parse(response["data"]["organization"]["settings"]);
        this.organization.settings = settings;
        for (let i = 0; i < this.org_formData.length; i++) {
          if (this.org_formData[i].type == "file") {
            switch (this.org_formData[i].name) {
              case "uploadorg":
                this.org_formData[i].source =
                  this.organization.image_url + this.organization.cdn_signature;
                break;
              case "first_slide":
                this.org_formData[i].source =
                  this.organization.settings.first_slide +
                  this.organization.cdn_signature;
                break;
              case "last_slide":
                this.org_formData[i].source =
                  this.organization.settings.last_slide +
                  this.organization.cdn_signature;
                break;
              default:
                break;
            }
          }
        }
        this.formGroup.patchValue({
            name: this.organization.name,
            about: this.organization.about,
            organization_type: this.organization.organization_type,
            product_type: this.organization.product_type,
            dual_auth: this.organization.settings.dual_auth,
            uploadorg: "",
            first_slide: "",
            first_slide_text: this.organization.settings.first_slide_text,
            last_slide: "",
            last_slide_text: this.organization.settings.last_slide_text,
            copyright_text: this.organization.settings.copyright_text,
        });
        //   if (this.userService.user.role === 'superadmin') {
        //       this.formGroup.patchValue({
        //         insights_customer_type: this.admin_settings['insights_customer_type'] ? this.admin_settings['insights_customer_type'] : 'myguide_customers',
        //       })
        //   }
      if(this.userService.enableDisplayPublishDate){


        this.org_formData.splice(11,0,{
          type: 'heading',
          name: this.admin_data.guidePublishingDate,
          class:'my-4',
          text_class:'heading_text',
          // tooltip:'The following settings are used to customize the structure of video files generated for the organization after the guides are published'
        })

        this.formGroup.addControl('first_slide_publish_date',new FormControl(0));
        this.formGroup.addControl('last_slide_publish_date',new FormControl(0));
        
      this.org_formData.push({
        type: "regular_checkbox",
        name: "first_slide_publish_date",
        class: "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0 my-2 d-flex align-items-center font14",
        value: "",
        checked: true,
        validators: [],
        ui: {
          label: this.admin_data.firstSlide,
          class: "font-weight-bold mb-0 custom-check",
        },
      },
      {
        type: "regular_checkbox",
        name: "last_slide_publish_date",
        class: "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0 my-2 d-flex align-items-center font14",
        value: "",
        checked: true,
        validators: [],
        ui: {
          label: this.admin_data.lastSlide,
          class: "font-weight-bold mb-0 custom-check",
        },
      });

      this.formGroup.patchValue({
        first_slide_publish_date:this.organization.settings['first_slide_publish_date_position']?1:0,
        last_slide_publish_date:this.organization.settings['last_slide_publish_date_position']?1:0
      })


      this.org_formData.push({
        type:'position_heading',
        name:'display_position',
        ui: {
          label: this.admin_data.setDisplay,
          class: "font-weight-bold m-0",
        }
      })

      this.org_formData.push({type:'empty_position','name':"First Slide",ui: {
        label: this.admin_data.setFirstSlide,
        class: "font-weight-bold m-0",
      },class: "col-lg-6 p-0 font14"});
      this.org_formData.push({type:'empty_position','name':"Last Slide",ui: {
        label: this.admin_data.setLastSlide,
        class: "font-weight-bold m-0",
      },class: "col-lg-6 p-0 font14"});

      if(this.formGroup.get('first_slide_publish_date').value==1){
        this.addDisplayPositionControl('first_slide');
        this.formGroup.patchValue({
          first_slide_display_position:this.organization.settings['first_slide_publish_date_position']
        })
      }

      if(this.formGroup.get('last_slide_publish_date').value==1){
        this.addDisplayPositionControl('last_slide');
        this.formGroup.patchValue({
          last_slide_display_position:this.organization.settings['last_slide_publish_date_position']
        })
      }

      this.formGroup.get('first_slide_publish_date').valueChanges.subscribe((val)=>{
         if(val=='1'){
           this.addDisplayPositionControl('first_slide');
         }else{
          this.removeDisplayPositionControl('first_slide');
         }
      })

      this.formGroup.get('last_slide_publish_date').valueChanges.subscribe((val)=>{
        if(val=='1'){
          this.addDisplayPositionControl('last_slide');
        }else{
         this.removeDisplayPositionControl('last_slide');
        }
     })
     let inputValidateField = ["name", "about", "first_slide_text", "last_slide_text", "copyright_text"];
     for(let ind=0; ind<inputValidateField.length; ind++){
      this.formGroup.get(inputValidateField[ind]).valueChanges.subscribe(x => {
        if (x && !this.userService.pattern.test(x)) {
          this.formGroup.get(inputValidateField[ind]).setValue(x.replace(this.userService.replacePattern, ""),{ emitEvent: false });
        }
      });
     }

         

     if(this.organization.settings['display_publish_date']==undefined){
      this.formGroup.patchValue({
        first_slide_publish_date:1,
        last_slide_publish_date:1
      })
    }
    }

    
    this.org_formData.splice(this.org_formData.length,0,{
        type: 'heading',
        name: this.admin_data.excludeDomains,
        class:'mb-0 mt-4 pl-0 font14',
        text_class:'font14 font-weight-bold',
        tooltip: adminLabels.excludeDomainsTooltip
      },);
   
    
    if(this.organization.settings['excludeDomains'] != undefined &&  this.organization.settings['excludeDomains'].length>0){
        for(let i =0; i<this.organization.settings['excludeDomains'].length; i++){
            this.addInputDomain();
            let prop = "excludeDomain"+(i*2);
            
            this.formGroup.patchValue({
                [prop] : this.organization.settings['excludeDomains'][i]
            });
            }
            
        }
    let index = this.org_formData.findIndex((element) => {
        return element.name == this.admin_data.excludeDomains})
    this.org_formData.splice(index+this.numOfDomains+1, 0, {
        type: "button",
        name: "add_domain",
        value: "",
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 btn btn-outline-primary mt-2 mb-4",
        validators: [
            {},
        ],
        ui: {
            label: adminLabels.domainsAddDomainButtonText,
            class: "col-lg-2",
            inputclass: "col-lg-6",
        }
    })
    // }
    this.formGroup.valueChanges.subscribe(x => {
        this.checkFormValid()  
    }); 
          if (admin_settings['self_hosted_player'] && admin_settings['self_hosted_player'] === "1" && (this.userService.user.role === "partner_admin" || this.userService.user.role === "mg_admin" || this.userService.user.role === "owner")) {

              this.organisationService.getSelfHostedConfig(this.org_id).subscribe((response) => {
                  let endpoint = response['data'] ? response['data']['endpoint'] : ""
                  this.formGroup.patchValue({
                      self_hosted_player: endpoint
                  })

              })// get self hosted player call ends here
              this.org_formData.push({
                  type: "text",
                  name: "self_hosted_player",
                  value: "",
                  class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
                  validators: [
                      {},
                  ],
                  ui: {
                      label: adminLabels.selfHostedPlayerURL,
                      class: "col-lg-2 label-asterisk",
                      inputclass: "col-lg-6",
                  },
                  input: {
                      id: "self_hosted_player",
                      class: "form-control",
                      type: "text",
                      name: "self_hosted_player",
                      placeholder: adminLabels.selfHostedPlayerURL,
                  },
                  label: {
                      title: adminLabels.selfHostedPlayerURL,
                      class: "font-weight-bold label-asterisk",
                      for: "self_hosted_player",
                  },
              })

              this.formGroup.addControl('self_hosted_player', new FormControl(""));
              this.formGroup.get("self_hosted_player").setValidators([
                  Validators.required,
                  Validators.pattern(/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)[a-zA-Z0-9]{1,}(\.[a-zA-Z0-9]{1,}){1,}(:[a-zA-Z0-9]*)?/)]);


          }
      });
  }

  addDisplayPositionControl(type){
    if(type == 'first_slide'){
      this.org_formData[this.firstSlideIndex] = {
        type: "position_radio",
        name: "first_slide_display_position",
        class: "col-lg-6 p-0 font5",
        value: ["top-left", "top-right", "bottom-left", "bottom-right"],
        value_labels: ["Top Left", "Top Right", "Bottom Left", "Bottom Right"],
        validators: [
          {
            required: true,
          },
        ],
        ui: {
          label: this.admin_data.setFirstSlide,
          class: "font-weight-bold m-0",
        },
      }
      this.formGroup.addControl('first_slide_display_position',new FormControl('bottom-left',[Validators.required]));
    }

    if(type == 'last_slide'){
      this.org_formData[this.firstSlideIndex+1] = {
        type: "position_radio",
        name: "last_slide_display_position",
        class: "col-lg-6 p-0 font14",
        value: ["top-left", "top-right", "bottom-left", "bottom-right"],
        value_labels: ["Top Left", "Top Right", "Bottom Left", "Bottom Right"],
        validators: [
          {
            required: true,
          },
        ],
        ui: {
          label: this.admin_data.setLastSlide,
          class: "font-weight-bold m-0",
        },
      }
    }

    this.formGroup.addControl('last_slide_display_position',new FormControl('bottom-left',[Validators.required]));
  }

  addInputDomain(){    
    this.formGroup.addControl("excludeDomain" + this.numOfDomains,new FormControl(""));
    let index = this.org_formData.findIndex((element) => {
    return element.name == this.admin_data.excludeDomains})
    this.org_formData.splice(index+this.numOfDomains+1, 0 , {
        type: "text",
        name: "excludeDomain" + this.numOfDomains,
        value: "",
        class: "col-10 col-lg-10 col-xl-10 col-md-10 col-sm-10 p-0 font14 lh-1",
        validators: [
            {required: false },
        ],
        ui: {
            label: "",
            class: "col-lg-2",
            inputclass: "col-lg-6",
        },
        input: {
            id: "excludeDomain" + this.numOfDomains,
            class: "form-control",
            type: "text",
            name: "excludeDomain" + this.numOfDomains,
            placeholder: adminLabels.domainsAddDomainButtonText,
        },
        label: {
            title: "",
            class: "m-0 p-0",
            for: "excludeDomain" + this.numOfDomains,
        },
    })

    this.formGroup.get("excludeDomain" + this.numOfDomains).setValidators([
        Validators.pattern(/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/|www\.)[a-zA-Z0-9]{1,}(\.[a-zA-Z0-9]{1,}){1,}(:[a-zA-Z0-9]*)?/)]);

    this.org_formData.splice(index+this.numOfDomains+2, 0, {
        type: "svg_button",
        name:  "excludeDomainButton" + this.numOfDomains,
        value: "",
        class: "col-2 col-lg-2 col-xl-2 col-md-2 col-sm-2 mt-4 lh-1",
        validators: [
            {},
        ],
        ui: {
            label: adminLabels.deleteButtonText,
            class: "col-2",
        },
        svg: {
            src: "../../../assets/svgs/emit_delete.svg"
        }
    })
    this.numOfDomains+=2;
}

  removeDisplayPositionControl(type){
    if(type=='first_slide'){
      this.org_formData[this.firstSlideIndex]={type:'empty_position','name':"First Slide",ui: {
        label: this.admin_data.setFirstSlide,
        class: "font-weight-bold m-0",
      },class: "col-lg-6 p-0 font14"};
      this.formGroup.removeControl('first_slide_display_position');
    }
    if(type=='last_slide'){
      this.org_formData[this.firstSlideIndex+1]={type:'empty_position','name':"Last Slide",ui: {
        label: this.admin_data.setLastSlide,
        class: "font-weight-bold m-0",
      },class: "col-lg-6 p-0 font14"};
      this.formGroup.removeControl('last_slide_display_position');
    }
    
  }

  editOrganisation() {
    this.organisationService.update_button.disable=true;
    this.organisationService.update_button.text=this.admin_data.updatingButtonText;
    this.count = 0;
    this.imageData = [];
    
    let len;
    len = this.checkImage(this.orgformData, "upload_org_id", "upload_org_path");
    len = this.checkImage(
      this.firstSlideformData,
      "first_slide_id",
      "first_slide_path"
    );
    len = this.checkImage(
      this.lastSlideformData,
      "last_slide_id",
      "last_slide_path"
    );
    this.imageData.forEach((fdata, ind) => {
      this.uploadImage(fdata, this.imageIds[ind], this.imageUploads[ind]);
    });
    if (this.imageUploads.length == 0) {
      this.updateOrganization(2);
    } else {
      this.sub1 = this.organisationService.countImages.subscribe((count) => {
        if (count == this.imageData.length) {
          this.updateOrganization(1);
        }
      });
    }
  }

  updateOrganization(option) {
      this.formGroup.updateValueAndValidity();
      let admin_settings = {};
    let settings = this.organization['settings'];
      settings['first_slide_text']= this.formGroup.get("first_slide_text").value;
      settings['first_slide']= this.formGroup.get("first_slide_path").value
        ? this.formGroup.get("first_slide_path").value
        : this.organization.settings.first_slide;
      settings['first_slide_id']= this.formGroup.get("first_slide_id").value
        ? parseInt(this.formGroup.get("first_slide_id").value)
        : "";
      settings['last_slide_text']= this.formGroup.get("last_slide_text").value;
      settings['copyright_text']= this.formGroup.get("copyright_text").value;
      settings['last_slide']= this.formGroup.get("last_slide_path").value
        ? this.formGroup.get("last_slide_path").value
        : this.organization.settings.last_slide;
      settings['last_slide_id']= this.formGroup.get("last_slide_id").value
        ? parseInt(this.formGroup.get("last_slide_id").value)
        : "";

      settings['dual_auth']= this.formGroup.get("dual_auth").value;

      if(this.numOfDomains>0){
            settings['excludeDomains'] = [];
            let max : number = 0;
            while(max < this.numOfDomains){
                
                if(this.formGroup.get("excludeDomain" + max).value.trim().length>0) {
                    settings['excludeDomains'].push(this.formGroup.get("excludeDomain" + max).value.trim())
                }
                max+=2;
            }
        }else{
            settings['excludeDomains']=[];
        }
        
      if(this.userService.enableDisplayPublishDate){
        settings['display_publish_date'] = (this.formGroup.get('first_slide_publish_date').value == 1 || this.formGroup.get('last_slide_publish_date').value == 1) ? true:false;
        settings['first_slide_publish_date_position'] = this.formGroup.get('first_slide_publish_date').value == 1 ? this.formGroup.get('first_slide_display_position').value : false;
        settings['last_slide_publish_date_position'] = this.formGroup.get('last_slide_publish_date').value == 1 ? this.formGroup.get('last_slide_display_position').value : false;

      }

    let stringifiedSettings = JSON.stringify(settings);
    
      let updateRequest = {
          name: this.formGroup.get("name").value.trim(),
          about: this.formGroup.get("about").value.trim(),
          product_type: this.formGroup.get("product_type").value.trim(),
          image_id: this.formGroup.get("upload_org_id").value
              ? parseInt(this.formGroup.get("upload_org_id").value)
              : this.organization.image_id,
          organization_id: this.org_id,
          organization_type: this.formGroup.get("organization_type").value,
          settings: stringifiedSettings,
      };
      let apiCalls = []
      apiCalls.push(this.organisationService
          .updateOrganization(updateRequest));
    //   if (this.userService.user.role === 'superadmin') {
    //       this.admin_settings['insights_customer_type'] = this.formGroup.get('insights_customer_type').value;
    //       let adminSettingsRequest = {
    //           admin_settings: JSON.stringify(this.admin_settings),
    //           organization_id: this.userService.organization_id
    //       };
    //       apiCalls.push(this.adminSettingsService.updateAdminSettings(adminSettingsRequest));
    //   }
      forkJoin(apiCalls)
          .subscribe((response) => {
              this.organisationService.update_button.disable = false;
              this.organisationService.update_button.text = this.admin_data.updateButtonText;
              this.toastService.showToast = true;
              this.toastService.toastType = "update_org";
              if (this.userService.organization_id == '000') {
                  this.router.navigate(['']);
              } else {
                  this.router.navigate([
                      "organization",
                      this.userService.organization_id,
                  ]);
              }
        
      });
      if(this.formGroup.contains("self_hosted_player")){       
        let endpoint = Array.isArray(this.formGroup.get("self_hosted_player").value) ? 
                            this.formGroup.get("self_hosted_player").value.flat(Infinity)[0] : 
                            this.formGroup.get("self_hosted_player").value;
        let selfHostedRequest = {
            organization_id : this.org_id,
            endpoint: endpoint
        }
        this.organisationService.setSelfHostedConfig(selfHostedRequest, this.org_id).subscribe((response)=>{            
            
        })
    }
  }

  checkImage(formData, id, upload) {
    if (formData.get("image")) {
      this.imageData.push(formData);
      this.imageIds.push(id);
      this.imageUploads.push(upload);
      return this.imageData.length;
    }
  }

  setImages(images) {
    
    if (images.get("uploadorg")) {
      this.orgformData.append("image", images.get("uploadorg"));
      this.orgformData.append("organization_id", this.org_id);
    }
    if (images.get("first_slide")) {
      this.firstSlideformData.append("image", images.get("first_slide"));
      this.firstSlideformData.append("organization_id", this.org_id);
    }
    if (images.get("last_slide")) {
      this.lastSlideformData.append("image", images.get("last_slide"));
      this.lastSlideformData.append("organization_id", this.org_id);
    }
  }

  uploadImage(formData, id, upload) {
    formData;
    this.application.getImageId(formData).subscribe((response) => {
      let imgid = response["data"]["image_id"];
      let imgpath = response["data"]["image_path"];
      this.formGroup.patchValue({
        [id]: imgid,
        [upload]: imgpath,
      });
      this.count++;
      this.organisationService.countImages.next(this.count);
    });
  }

  unassign() {
    for (let i = 0; i < this.org_formData.length; i++) {
      this.formGroup.patchValue({
        [this.org_formData[i].name]: "",
      });
      if (this.org_formData[i].type == "file") {
        this.org_formData[i].source = "../assets/images/image.png";
      }
    }
    if(this.userService.organization_id == '000'){
      this.router.navigate(['']);
      }else{
        this.router.navigate([
          "organization",
          this.userService.organization_id,
        ]);
      }
  }

  checkFormValid(){
    if((this.formGroup.get('self_hosted_player') && !this.formGroup.get('self_hosted_player').valid) || 
    (this.formGroup.get('name') && !this.formGroup.get('name').valid) || 
    (this.formGroup.get('about') && !this.formGroup.get('about').valid) || 
    (this.formGroup.get('organization_type') && !this.formGroup.get('organization_type').valid)){    
        this.organisationService.update_button.disable=true;
        
    }else{
        this.organisationService.update_button.disable=false;
    }
}

    formButtonClicked(formName){
        
        if(formName == "add_domain"){
            this.addInputDomain();
        }else{
            while(this.org_formData.findIndex((element)=> { return element.name === formName})> -1){
                let index = this.org_formData.findIndex((element)=> { return element.name === formName});
                this.org_formData.splice(index-1,2);
            }
            this.numOfDomains-=2
        }
    }
}
