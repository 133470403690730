import { Component, OnInit, Inject } from "@angular/core";
import { OrganisationService } from "../../../common/services/organisation.service";
import { UserService } from "../../../common/services/user.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SegmentationService } from "../../../common/services/segmentation.service";
import { ToastrService } from 'ngx-toastr';
import adminLabels from "../../../constants/ApplicationStrings/trans_utils";

import {
    BLOB_STORAGE_TOKEN,
    IBlobService,
    IBlobStorage,
    ISasToken,
    ISpeedSummary,
} from "./azureStorage";
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "src/app/common/services/toast-modal.service";
import { environment } from "src/environments/environment";
import { GuidesService } from "src/app/common/services/guides.service";
declare var $: any;
declare var AzureStorage: any;

@Component({
    selector: "app-import-user",
    templateUrl: "./import-user.component.html",
    styleUrls: ["./import-user.component.css"],
})
export class ImportUserComponent implements OnInit {
    org_id;
    selectedOrg;
    sample_download_url;
    sample_file_name;
    showFileError = false;
    send_bulk_mail = "0";
    fields = ['"FIRST NAME"', '"LAST NAME"', '"EMAIL ID"', '"ORGANIZATION ROLE"'];
    csv = '"FIRST NAME","LAST NAME","EMAIL ID","ORGANIZATION ROLE"';
    file;
    tasks;
    inputFile;

    limit = 10;
    offset = 0;

    pages = [1, 2];
    currentPage = 1;
    prevIndex = 0;
    currentIndex = 1;
    total = 0;
    pageLimit: number = 0;
    disableNext = false;
    disablePrev = false;
    showPagination = false;
    file_name;
    selected_file;
    breadcrumb;
    admin_data = adminLabels ;

    constructor(
        private organisationService: OrganisationService,
        public userService: UserService,
        private route: ActivatedRoute,
        private segmentation: SegmentationService,
        private router: Router,
        private toastr: ToastrService,
        private titleService: Title,
		private errorResponseService : ErrorResponseService,
        private toastService:ToastModalService,        
        private guidesService: GuidesService,
    ) {
        
        const roleVal = JSON.parse(localStorage.getItem('organization'));

        this.titleService.setTitle(this.admin_data.usersBreadCrumbTitle + " | " + "Cornerstone Guide");
        this.userService.getUserData();
        this.organisationService.getCDNData()
        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];
            if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" )) {
                this.getOrganization();
            }

            if(this.userService.user.role == "superadmin" || roleVal['role'] == "owner" || roleVal['role'] == "mg_admin") {
                this.getSegments();
            }

            this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs("Import User", this.route.url["_value"]);
                if(params['params']['org_id'])
                this.userService.setBreadcrumb([
                    {
                        name:adminLabels.usersBreadCrumbTitle,
                        route:['/organization',this.org_id,'users']
                    },
                    {
                        name:adminLabels.usersVariable28,
                        route:null
                    }
                ])
                this.breadcrumb=this.userService.breadCrumbs;
            });
        });
        
    }

    ngOnInit() {
        if (
            this.userService.user.role != "superadmin" ||
            (this.userService.user.role == "superadmin" &&
                this.selectedOrg == this.userService.organization_id)
        ) {
            this.getTaskList();
        }
    }


    getTaskList() {
        this.userService
            .getTaskStatus(this.limit, this.offset, this.org_id)
            .subscribe((response) => {
                this.processResponse(response);
                if(this.toastService.showToast){
                    this.toastService.showToastMessage(this.toastService.toastType);
                }
            });
    }

    getSegments() {
        this.segmentation.getSegmentations(this.org_id).subscribe((response) => {
            let data = response["data"]["segments"];
            data.forEach((d) => {
                this.fields.push(d["key_name"].toUpperCase());
                this.csv += ",";
                this.csv += '"' + d["key_name"].toUpperCase().replace(" ", "") + '"';
            });
        });
    }

    processResponse(response) {

        this.tasks = response["data"]["tasks"];
        for (let i=0; i<this.tasks.length;i++)
        {
            if(this.tasks[i].status == "DONE")
            {
                this.tasks[i].status = this.admin_data.done
            }
            else if(this.tasks[i].status == "STARTED")
            {
                this.tasks[i].status = this.admin_data.started
            }
            else if(this.tasks[i].status == "PENDING")
            {
                this.tasks[i].status = this.admin_data.pending
            }
            else if(this.tasks[i].status == "FAILED")
            {
                this.tasks[i].status = this.admin_data.failed
            }
        }
        // console.log(this.tasks)
        if (this.currentPage > 1 && this.tasks.length < 1) {
            this.prependOffsetAndGetUsers();
        }
        this.userService
            .getTaskStatus(this.limit + 1, this.offset, this.org_id)
            .subscribe((res) => {
                this.userService.showLoader = false;
                if (this.tasks.length < this.limit) {
                    this.disableNext = true;
                } else {
                    this.disableNext = false;
                    this.showPagination = true;
                }

                if (this.currentPage == 1) {
                    this.disablePrev = true;
                } else {
                    this.disablePrev = false;
                    this.showPagination = true;
                }
                if (this.tasks.length == this.limit) {
                    if (res["data"]["tasks"].length > this.tasks.length) {
                        this.showPagination = true;
                    } else {
                        if (this.currentPage == 1) {
                            this.showPagination = false;
                        } else {
                            this.disableNext = true;
                            this.showPagination = true;
                        }
                    }
                }
            });

    }

    appendOffsetAndGetUsers() {
        this.disableNext = true;
        this.currentPage++;
        this.currentIndex = this.currentPage;
        if (this.currentPage > 0) {
            if (this.currentPage > this.pages[this.pages.length - 1]) {
                this.pages.shift();
                this.pages.push(this.currentPage);
            }
            this.offset += 10;
            this.getTaskList();
        }
    }

    prependOffsetAndGetUsers() {
        this.disablePrev = true;
        this.currentPage--;
        this.currentIndex = this.currentPage;
        if (this.currentPage > 0) {
            if (this.currentPage < this.pages[0]) {
                this.pages.pop();
                this.pages.unshift(this.currentPage);
            }
            this.offset -= 10;
            this.getTaskList();
        }

    }

    setndex(ind, event) {
        if (this.disablePrev && this.disableNext) {
            event.preventDefault();
            return;
        }
        if (ind != this.currentIndex) {
            this.prevIndex = this.currentIndex;
            this.currentIndex = ind;
            this.currentPage = this.currentIndex;
            this.calculateOffsetAndGetUsers(this.prevIndex, this.currentIndex);
        }
    }

    calculateOffsetAndGetUsers(prev, current) {
        if (current > prev) {
            this.offset = (current - prev) * this.limit + this.offset;
        } else {
            this.offset = this.offset - (prev - current) * this.limit;
        }
        this.getTaskList();
    }

    download() {
        let org_bucket = JSON.parse(localStorage.getItem('organization'))['bucket'];
        console.log(JSON.parse(localStorage.getItem('organization')));
        let cdn_sign = JSON.parse(localStorage.getItem('cdn_sign'))['cdn_signature'];
        let filename = 'sample_' + this.org_id + '.csv';
        let url = environment.CDN_BASE_URL + '/' + org_bucket + '/csv/' + filename + cdn_sign ;
        this.guidesService.downloadfile(url).subscribe((blob) => {
            let task_filename = filename.split('/')
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(blob)
            a.href = objectUrl
            a.download = task_filename[task_filename.length - 1];
            a.click();
            URL.revokeObjectURL(objectUrl);
        })
    }

    getOrganization() {
        this.organisationService
            .getOrganization(this.org_id)
            .subscribe((response) => {
                let organization = response["data"]["organization"];

                this.userService.organization = organization["name"];
                this.userService.organization_id = organization["organization_id"];
                // this.userService.user.cdn_signature = organization["cdn_signature"];
                this.userService.organization = organization;
                let admin_settings = JSON.parse(
                    this.userService.organization.admin_settings
                );
                // this.userService.enableSegments =
                //     admin_settings["guide_segmentation"] == "1" ? true : false;
                // if (this.userService.enableSegments) {
                //     this.getSegments();
                // }
                this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
                this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
                this.userService.enableMediaThemes=admin_settings['media_files_themes'] == 0 ? false : true;

                let org = organization;
                let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));


                cdn_sign["cdn_signature"] = organization["cdn_signature"];
                cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

                this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                this.selectedOrg = this.userService.organization_id;
                localStorage.setItem("organization", JSON.stringify(org));
                localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
                this.ngOnInit();
            });
    }

    setSendMail(value) {
        this.send_bulk_mail = value;
    }

    upload() {
        let file_elem = document.getElementById("validatedCustomFile");
        this.userService.import_button.disable=true;
        this.userService.import_button.text=this.admin_data.uploadingButtonText;
        this.userService.getUploadedFile().subscribe((response) => {
            let uploadData = response["data"];
            let self = this;

            // if (environment.isAzure) {
                // FOR AZURE
                // let ind = uploadData["upload_url"]["signature"].lastIndexOf("&");

                const blobService = AzureStorage.Blob.createBlobServiceWithSas(
                    uploadData["upload_url"]["blob_uri"],
                    uploadData["upload_url"]["signature"]
                );
                let customBlockSize = 0;
                
                if (this.file) {
                    customBlockSize =
                        this.file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
                    blobService.singleBlobPutThresholdInBytes = customBlockSize;
                }
                blobService.createBlockBlobFromBrowserFile(
                    "csv",
                    uploadData["upload_url"]["file_name"].split("/")[1],
                    this.file,
                    {
                        blockSize: customBlockSize,
                    },
                    function (error, result, response) {
                        if (error) {
                            alert("Your CSV is not uploaded, Please try again");
                        } else {
                            let request = {
                                file_id: uploadData["upload_url"]["file_id"],
                                send_email: self.send_bulk_mail,
                            };
                            self.userService.importUser(request).subscribe((response) => {
                                self.userService.import_button.disable = false;
                                self.userService.import_button.text = self.admin_data.uploadButtonText;
                                self.toastService.showToast = true;
                                self.toastService.toastType = "import_user";
                                self.file = "";
                                file_elem['value'] = null;
                                self.selected_file = null;
                                self.getTaskList();
                            });
                        }
                    }
                );
            // } else {
            //     // FOR FTP
            //     let request = {
            //         file_id: uploadData["upload_url"]["file_id"],
            //         content: this.file
            //     };
            //     self.userService.uploadFileInFTP(request).subscribe((response) => {
            //         if (!response['error']){
            //             let request = {
            //                 file_id: uploadData["upload_url"]["file_id"],
            //                 send_email: self.send_bulk_mail,
            //             };
            //             self.userService.importUser(request).subscribe((result) => {
            //                 self.userService.import_button.disable = false;
            //                 self.userService.import_button.text = "Upload";
            //                 self.toastService.showToast = true;
            //                 self.toastService.toastType = "import_user";
            //                 self.file = "";
            //                 file_elem['value'] = null;
            //                 self.selected_file = null;
            //                 self.getTaskList();
            //             });
            //         } else {
            //             alert("Your CSV is not uploaded, Please try again");
            //         }
            //     });
            // }
        });
    }

    clickFileInput() {
        document.getElementById("validatedCustomFile").click();
    }

    saveFile(file) {
        if (environment.isAzure) {
            this.selected_file = file;
            let names = file[0].name.split(".");
            if (file[0].name.split(".")[names.length - 1] != 'csv') {
                this.showFileError = true;
                return;
            } else {
                this.showFileError = false;
            }
            this.file = file[0];
        } else {
            if (file && file.length > 0) {
                let file_save: File = file.item(0);
                let fileReader: FileReader = new FileReader();
                fileReader.readAsText(file_save);
                fileReader.onload = (ev) => {
                    this.file = fileReader.result.toString();
                };
            }
        }
    }
}
