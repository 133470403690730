<div *ngIf="totalRecords && totalRecords > 0">
    <br>
    <div class="d-flex flex-row-reverse mb-2">
        <span class="d-flex flex-row form-inline align-items-center">
            <label for="numOfRows" class="flex-fill mr-2 font14">Rows per page</label>
            <!-- <select name="numOfRows" id="numOfRows" class="form-control mr-3 font-large"
                (change)="emitLimitChanged($event)">
                <option *ngFor="let n of noOfRows" value={{n}} [selected]="limit==n">{{n}}</option>
            </select> -->

            <app-custom-select [dataList]="noOfRows" (updateDataList)="emitLimitChanged($event)" position="up"
                      [selected]="limit" componentName="default"></app-custom-select>
            <p class="form-text text-muted ml-2 my-0 font14">{{(offset+1) + " - "}}
                {{ (offset+1+(limit)) < totalRecords ? (offset+limit) : totalRecords}} {{ "  " + admin_data.of + "  " +
                    totalRecords }}</p>
                    <ul class="pagination ml-2 my-auto">
                        <li class="page-item" [class.disabled]="currentPage<2">
                            <button class="page-link rounded" (click)="gotoFirstPage.emit()"><svg-icon
                                    src="../../../assets/svgs/caret-left-bar.svg"></svg-icon></button>
                        </li>
                        <li class="page-item" [class.disabled]="currentPage<2">
                            <button class="page-link rounded" (click)="gotoPreviousPage.emit()">
                                <svg-icon src="../../../assets/svgs/caret-left.svg"></svg-icon>
                            </button>
                        </li>
                        <li class="page-item" [class.disabled]="currentPage>=totalPage">
                            <button class="page-link rounded" (click)="gotoNextPage.emit()">
                                <svg-icon src="../../../assets/svgs/caret-right.svg"></svg-icon>
                            </button>
                        </li>
                        <li class="page-item" [class.disabled]="currentPage>=totalPage">
                            <button class="page-link rounded" (click)="gotoLastPage.emit(totalPage)">
                                <svg-icon src="../../../assets/svgs/caret-right-bar.svg"></svg-icon>
                            </button>
                        </li>
                        <li class="d-flex flex-row align-items-baseline">
                            <label class="mx-2" for="goto">Go to</label>
                            <input style="max-width: 50px;" class="form-control" name="goto" type="number"
                                (input)="gotoSubjectCall($event)">
                        </li>
                    </ul>
        </span>
    </div>
    <div *ngIf="invalidGotoPage" class="float-right font12 text-danger">
        {{admin_data.pageEnteredOutsideRange}}</div>
</div>