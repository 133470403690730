import { Component, OnInit } from "@angular/core";
import { GENERATE_CREDENTIALS_FORMADATA } from "../../constants/FormData/generate-credentials";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { UserService } from "../../common/services/user.service";
import { OrganisationService } from "../../common/services/organisation.service";
import { ApiandservicesService } from "../../common/services/apiandservices.service";
import { ApplicationService } from "../../common/services/application.service";
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "src/app/common/services/toast-modal.service";
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

declare var $:any;

@Component({
    selector: "app-generate-credentials",
    templateUrl: "./generate-credentials.component.html",
    styleUrls: ["./generate-credentials.component.css"],
})
export class GenerateCredentialsComponent implements OnInit {
    org_id;
    dropdownList = [];
    selectedItems = [];
    allApplications = [];
    breadcrumb;
    admin_data = adminLabels ;
    provisioning = '';
    initalSelectedApps = [];
    dropdownSettings;
    keyId = "";
    editedSecret = false;
    
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public userService: UserService,
        private organisationService: OrganisationService,
        public apiAndServices: ApiandservicesService,
        private applicationService: ApplicationService,
        private toastr: ToastrService,
        private titleService: Title,
		private errorResponseService : ErrorResponseService,
        private toastService:ToastModalService
    ) {
        this.titleService.setTitle("API & Services | " + "Cornerstone Guide");
        this.userService.getUserData();
        this.organisationService.getCDNData()
        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];
            this.keyId = params["params"]["key_id"] ? params["params"]["key_id"] : ""
            if (window.location.href.includes('edit_secret_key') && !this.apiAndServices.secretKeyToEdit) {
                this.router.navigate([
                    '/organization', userService.organization_id, 'generate_secret_key'
                ])
            }
            if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" )) {
                this.getOrganization();
            } else {
               if(this.keyId === "") this.getApplications();
            }
        });
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs("Generate Credentials", this.route.url['_value']);
                if (this.keyId != "") {
                    this.userService.setBreadcrumb([
                        {
                            name: "API & Services",
                            route: ['/organization', this.org_id, 'get_secret_key']
                        },
                        {
                            name: this.admin_data.editCredentials,
                            route: null
                        }
                    ])
                } else {
                    this.userService.setBreadcrumb([
                        {
                            name: "API & Services",
                            route: ['/organization', this.org_id, 'get_secret_key']
                        },
                        {
                            name: this.admin_data.apiAndServicesGenrateCrendentialsButton,
                            route: null
                        }
                    ])
                }
                this.breadcrumb=this.userService.breadCrumbs;
            });
    }

    getOrganization() {
        this.organisationService.getOrganization(this.org_id).subscribe(
            (response) => {
                let organization = response["data"]["organization"];
                this.userService.organization = organization["name"];
                this.userService.organization_id = organization["organization_id"];
                // this.userService.user.cdn_signature = organization["cdn_signature"];
                this.userService.organization = organization;
                let admin_settings = JSON.parse(
                    this.userService.organization.admin_settings
                );
                this.userService.enableSegments =
                    admin_settings["guide_segmentation"] == "1" ? true : false;
                this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
                this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;

                this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
                this.userService.enableMediaThemes=admin_settings['media_files_themes'] == 0 ? false : true;

                let org = organization;
                let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

                cdn_sign["cdn_signature"] = organization["cdn_signature"];
                cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

                this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                localStorage.setItem("organization", JSON.stringify(org));
                localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
                if(this.keyId === "") this.getApplications();
            },
            (error) => {

            }
        );
    }

    formGroup : FormGroup;
    add_credential_formdata = JSON.parse(
        JSON.stringify(GENERATE_CREDENTIALS_FORMADATA)
    );
    4;
    formData = this.add_credential_formdata;
    ngOnInit() {
        let form = {};
        for (let i = 0; i < this.add_credential_formdata.length; i++) {
            form[this.add_credential_formdata[i].name] = new FormControl("");
            let validations = [];
            for (let v of this.add_credential_formdata[i].validators) {
                for (let key in v) {
                    validations.push(key);
                }
            }
            let validators = [];
            for (let j = 0; j < validations.length; j++) {
                switch (validations[j]) {
                    case "required":
                        validators.push(Validators.required);
                        break;

                    case "email":
                        validators.push(Validators.email);
                        break;
                }
            }
            form[this.add_credential_formdata[i].name].setValidators(validators);
        }
        form["applications"] = new FormControl("");
        this.formGroup = new FormGroup(form);
        if (this.keyId !== "") {
            this.provisioning = this.apiAndServices.secretKeyToEdit.anonymous_access == '1' ? 'anonymous' : 'jit'
            this.formGroup.patchValue({
                "provisioning": this.apiAndServices.secretKeyToEdit.anonymous_access == '1' ? 'anonymous' : 'jit',
                "key_name": this.apiAndServices.secretKeyToEdit.key_name,
            })
            this.formGroup.updateValueAndValidity();
        }
        this.formGroup.get("key_name").valueChanges.subscribe(x => {
            this.editedSecret = true;
            if (x && !this.userService.pattern.test(x)) {
              this.formGroup.get("key_name").setValue(x.replace(this.userService.replacePattern, ""),{ emitEvent: false });
            }
         });
        this.dropdownSettings = {
            singleSelection: false,
            idField: "item_id",
            textField: "item_text",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            itemsShowLimit: 3,
            allowSearchFilter: false,
        };
    }

    createDropdown(dropdownList) {
        dropdownList();
    }

    getApplications() {
        var dropdownList = this.dropdownList;
        let self = this;
        var promise = new Promise((resolve, reject) => {
            let applicationService = this.applicationService;
            let limit = 100;
            let offset = 0;
            let cb = applicationService.getApplications(limit, offset);
            this.allApplications = [];
            let allApplications = this.allApplications;
            let self = this;
            function application() {
                cb.subscribe(
                    (data) => {
                        if (data["data"]["applications"].length > 0) {
                            Array.prototype.push.apply(
                                allApplications,
                                data["data"]["applications"]
                            );
                            offset += 100;
                            cb = applicationService.getApplications(limit, offset);
                            application();
                        } else {
                            resolve(allApplications);
                        }
                    },
                    (error) => {
                    }
                );
            }
            application();
        }).then(function (data: []) {
            dropdownList = data.map((d) => {
                return {
                    item_id: d["application_id"],
                    item_text: d["title"],
                };
            });
            self.dropdownList = dropdownList;
            self.selectedItems = self.dropdownList;
            self.initalSelectedApps = self.selectedItems.map(app => app['item_id']);
        });
    }

    unassign() {
        this.apiAndServices.secretKeyToEdit = undefined;
        this.router.navigate([
            "/organization",
            this.userService.organization_id,
            "get_secret_key",
        ]);
    }

    generateSecretKey() {
        let ids = [];
        this.apiAndServices.add_button.disable=true;
        this.apiAndServices.add_button.text=this.admin_data.generatingButtonText;
        this.selectedItems.forEach((data) => {
            ids.push(data["item_id"]);
        });
        let request = {
            organization_id: this.userService.organization_id,
            key_name: this.formGroup.get("key_name").value,
            guest_access:
                this.provisioning === "anonymous" ? 1 : 0,
            jit_provisioning:
                this.provisioning === "jit" ? 1 : 0,
            app_ids: JSON.stringify(ids),
        };
        this.apiAndServices.generateSecretKey(request).subscribe(
            (response) => {
                this.apiAndServices.add_button.disable=false;
                this.apiAndServices.add_button.text=this.admin_data.generateButtonText;
                if(!response['error']){
                this.toastService.showToast=true;
                this.toastService.toastType="generate_secret_key";
                this.router.navigate([
                    "/organization",
                    this.userService.organization_id,
                    "get_secret_key",
                ]);
            }else{
                if(response['code'] == 3011){
                    this.userService.showModal.next(false);
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    this.userService.errorMessage=errorResponse;
                    $("#errorModal").modal("show");
                }
            }
            },
            (error) => {
                this.apiAndServices.add_button.disable=false;
                this.apiAndServices.add_button.text=this.admin_data.generateButtonText;
            }
        );
    }

    getValue(type) {
        this.editedSecret = true;
        this.provisioning = type;
    }

    updateAppList = (event) => {
        switch(event.type) {
            case "select": this.onItemSelect(event); break;
            case "deselect": this.onDeSelect(event); break;
            case "selectAll": this.onSelectAll(); break;
            case "deselectAll": this.onDeSelectAll(); break;
            default: return;
        }
    }

    onItemSelect(item: any) {
        if (!this.selectedItems.some(selectedItem => selectedItem.item_id === item.item_id)) {
            this.selectedItems.push({ item_id: item.item_id, item_text: item.item_text });
        }
    }
    onSelectAll = () => this.selectedItems = this.dropdownList.map(item => ({ item_id: item.item_id, item_text: item.item_text }));

    onDeSelect = (items: any) => this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.item_id !== items.item_id);
    
    onDeSelectAll = () => this.selectedItems = [];

    editSecretKey() {
        this.apiAndServices.edit_button.disable = true;
        this.apiAndServices.edit_button.text = this.admin_data.generatingButtonText;
        
        let request = {
            key_id: this.keyId,
            organization_id: this.userService.organization_id,
            key_name: this.formGroup.get("key_name").value,
            anonymous_access:
                this.provisioning === "anonymous" ? 1 : 0,
            jit_provisioning:
                this.provisioning === "jit" ? 1 : 0,
        };
        this.apiAndServices.editSecretKey(request).subscribe(
            (response) => {
                this.apiAndServices.edit_button.disable = false;
                this.apiAndServices.edit_button.text = this.admin_data.editCredentials;
                if (!response['error']) {
                    this.toastService.showToast = true;
                    this.toastService.toastType = "generate_secret_key";
                    this.router.navigate([
                        "/organization",
                        this.userService.organization_id,
                        "get_secret_key",
                    ]);
                } else {
                    let errorResponse = this.errorResponseService.getErrorResponse(response);
                    this.userService.errorMessage = errorResponse;     
                    $("#errorModal").modal("show");
                    this.apiAndServices.edit_button.disable = false;
                    this.apiAndServices.edit_button.text = this.admin_data.editCredentials;
                }
            });
    }
}
