import { Injectable } from '@angular/core';
import { ApiService } from '../common/services/api.service';
import { environment } from "src/environments/environment";
import { API_END_POINTS } from '../constants/api-end-points';

@Injectable({
    providedIn: 'root'
})
export class PlayerPackageService {

    constructor(private apiService: ApiService) { }

    getPlayerPackages(org_id) {
        let urlPath =
            environment.baseUrl + API_END_POINTS.GET_PLAYER_PACKAGES +
            "?limit=100" +
            "&offset=0" +
            "&organization_id=" + parseInt(org_id);
        return this.apiService.get(urlPath)
    }

    generatePlayerPackage(org_id){
        let request = {
            organization_id : parseInt(org_id)
        }
        let urlPath =
        environment.baseUrl + API_END_POINTS.GENERATE_PLAYER_PACKAGES ;
        return this.apiService.post(urlPath,request)
    }
}
