import { Component, OnInit } from "@angular/core";
import {
	FormGroup,
	FormControl,
	FormBuilder,
	Validators,
} from "@angular/forms";
import { UserService } from "../common/services/user.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';
import { OrganisationService } from '../common/services/organisation.service';
import { ToastrService } from "ngx-toastr";
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "../common/services/toast-modal.service";
import { environment } from "src/environments/environment";
import adminLabels from "../constants/ApplicationStrings/trans_utils";
declare var $: any;

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
	email: string;
	password: string;
	handoff_token: string;
	expired = false;
	invalid_credentials = false;
	insights_player_login = false;
	loaderFlag = false;
	admin_data = adminLabels;
	urlLinkData;
    loginForm;
	constructor(
		private fb: FormBuilder,
		public userService: UserService,
		private router: Router,
		private route: ActivatedRoute,
		private organisationService: OrganisationService,
		private errorResponseService : ErrorResponseService,
		private toastService: ToastModalService
	) {
		// if (this.userService.isLoggedIn()) {
		// 	this.router.navigate([""]);
		// }
		this.userService.getUserData();
		this.userService.user.token = '';
        this.loginForm = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required]],
            remember: "",
            recaptcha: [null, [Validators.required]],
        });

	}

	ngOnInit() {
		if (this.toastService.showToast) {
			this.toastService.showToastMessage(this.toastService.toastType);
		}

		const query = new URLSearchParams(window.location.search);
		const urlData = [];
		query.forEach((v, k) => {
			urlData[k] = v;
		});

		if(urlData && urlData['redirectURL']){
			this.urlLinkData = urlData['redirectURL'];
		}

		// this.checkMaintenance()
	}

	checkMaintenance() {
		let req = {
			email_id: '',
			password: '',
			keep_login: 0,
			token: "string",
			captcha: '',
		};

		this.userService.getUserLogin(req).subscribe(
			(response) => {
				let organization = {};
				let token = {};
				let user = {};
				if (response["error"]) {
					console.log(response)
				} 
			},
			(error) => {
				console.log(error)
			}
		);
	}

	login(loginForm, val) {
		let formValues = this.loginForm.getRawValue();
		localStorage.setItem("active_org_id", "");  


		let req = {
			email_id: formValues.email,
			password: formValues.password,
			keep_login: formValues.remember ? 1 : 0,
			token: "string",
			captcha: formValues.recaptcha,
            
		};
        let email = req.email_id;
		let login = localStorage.getItem("users")
			? JSON.parse(localStorage.getItem("users"))
			: {};
		// if (!login[email]) {
		// 	this.expired = true;
		// }
		let expiry;
		if (req["keep_login"] == 1) {
			this.userService.token_expiry = Date.now() + 30 * 24 * 3600000;
		} else {
			this.userService.token_expiry = Date.now() + 24 * 3600000;
		}
		// if (
		// 	!login[req["email_id"]] ||
		// 	login[req["email_id"]]["expiry"] < Date.now()
		// ) {
		// 	login["expiry"] = this.userService.token_expiry;
		// 	this.expired = true;
		// }

		req["token"] = ""
        // req && 
		// 	login[req.email_id] && !this.expired
		// 		? login[req["email_id"]]["token"]
				// : "";
		let appData = {};

		const query = new URLSearchParams(window.location.search);
		const urlData = [];
		query.forEach((v, k) => {
			urlData[k] = v;
		});

		let urlString = '';
		for (let urlParam in urlData) {
			if (urlString.length === 0) {
				urlString += '?';
			} else {
				urlString += '%';
			}
			urlString += (urlParam + '=' + urlData[urlParam]);
		}

		if (urlString.includes('redirectURL')) {
			this.loaderFlag = true;
			// this.router.navigate(['/loader']);
		}
		
		this.userService.getUserLogin(req).subscribe(
			(response) => {
				let organization = {};
				let token = {};
				let user = {};
				if (response["error"]) {
					this.invalid_credentials = true;
					this.loaderFlag = false;
					// grecaptcha.reset();
					this.loginForm.reset();
				} else if (response["data"]["user"]) {
			
					let appData = {};
					this.invalid_credentials = false;
					this.userService.user = response["data"]["user"];
					this.userService.user.token = response["data"]["user"]["accesstoken"];
					this.userService.user.role = response["data"]["user"]["role"];

					token["accesstoken"] = response["data"]["user"]["accesstoken"];
					token["refreshtoken"] = response["data"]["user"]["refreshtoken"];
					user = response["data"]["user"];

					localStorage.setItem("token", JSON.stringify(token));
					localStorage.setItem("user", JSON.stringify(user));

					if ((this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin"  )) {
						this.userService.organization.name = " ";
						this.userService.organization_id = "000";
						if(this.userService.user.role == "partner_admin")
						{
							this.userService.partner_id = response["data"]["user"]["partner_id"]
						} else {
							this.userService.partner_id = "0"
						}
					} 
					else {
							this.userService.organization_id = response["data"]["user"]["org_ids"][0];							
							this.organisationService.getOrganization(response["data"]["user"]["org_ids"][0])
							.subscribe((responseOrg) => {
								organization = responseOrg["data"]["organization"];
								this.userService.organization.name = responseOrg["data"]["organization"]["name"];
								localStorage.setItem("organization", JSON.stringify(organization));
							});
					}



					// let cdn_sign = {};
					// organization = response["data"]["user"]["organization"];
					// cdn_sign["cdn_signature"] = this.organisationService.cdn_signature;
					// cdn_sign["cdn_signature_expiry"] = this.organisationService.cdn_signature_expiry;
					// if ((this.userService.user.role != "superadmin" && this.userService.user.role != "mg_admin" && this.userService.user.role != "partner_admin")) {
					// 	localStorage.setItem("organization", JSON.stringify(organization));
					// }
					// localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));

					if (urlString.includes('redirectURL')) {
						if(response["data"]["user"]["role"] == 'player' || response["data"]["user"]["role"] == 'user') {

							this.loaderFlag = false;
							const query = new URLSearchParams(window.location.search);
							const urlData = [];
							query.forEach((v, k) => {
								urlData[k] = v;
							});
							this.router.navigate(['/login'], { queryParams: urlData });

							this.urlLinkData = urlData;
							setTimeout(() => {
								this.insights_player_login = true;
							},0)
						} else {
							this.insights_player_login = false;
							const accessToken = response["data"]["user"]["accesstoken"];
							const refreshToken = response["data"]["user"]["refreshtoken"];
	
							this.userService.generateHandoffToken(accessToken, refreshToken).subscribe(
								(response1) => {
									if (response1["error"]) {
									} else if (response1["data"]["handoff-token"]) {
										let urlLink = atob(urlData['redirectURL']);
										this.urlLinkData = urlLink;
										if(response["data"]["user"]["role"] !== 'creator'){
											if(urlLink.includes('logout')){
												urlLink = '/dashboard/';
											}
											const appendSign = urlLink.includes('dashboard') ? '?' : '&';
											window.open(environment.INSIGHTS_URL + '/#' + urlLink + appendSign + 'handoff-token=' + response1["data"]["handoff-token"], "_self");
										} else {
											window.open(environment.INSIGHTS_URL + '/#/survey/manage-survey/?handoff-token=' + response1["data"]["handoff-token"], "_self");
										}
									} else {
									}
								},
								(error) => {
									this.router.navigate(["/login"], { queryParams: urlData });
								}
							);
						}
					} 
					else {
						this.router.navigate(["/dashboard"]);
					}
					// uncomment else block if it causes issues
					// this.router.navigate(["/dashboard"])
				} else {
					let appData = {};

					this.invalid_credentials = false;
					// appData['token']=response['data']['token'];
					this.userService.token = response["data"]["token"];
					this.userService.token = response["data"]["token"];
					// localStorage.setItem('appData',JSON.stringify(appData));
					let token = {};
					token["token"] = this.userService.token;
					token["email"] = req["email_id"];
					localStorage.setItem("token", JSON.stringify(token));
					const query = new URLSearchParams(window.location.search);
					const urlData = [];
					query.forEach((v, k) => {
						urlData[k] = v;
					});
					this.router.navigate(["/otp"], { queryParams: urlData });
				}
			},
			(error) => {
                this.loaderFlag = false
				this.userService.token = "";
				this.router.navigate(["/login"], { queryParams: urlData });
			}
		);
		
		$("#errorModal").modal("hide");
	}

	onScriptLoad() {
	}

	onScriptError() {
	}
	captureValue(response) {
	}
}
