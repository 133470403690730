import { Component, OnInit } from '@angular/core';
import { ADD_ORG_FORMADATA } from '../../../constants/FormData/add_organisation';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApplicationService } from '../../../common/services/application.service';
import { UserService } from '../../../common/services/user.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { OrganisationService } from '../../../common/services/organisation.service';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from 'src/app/common/services/toast-modal.service';
import adminLabels from "../../../constants/ApplicationStrings/trans_utils";


@Component({
  selector: 'app-add-organisation',
  templateUrl: './add-organisation.component.html',
  styleUrls: ['./add-organisation.component.css']
})
export class AddOrganisationComponent implements OnInit {

  admin_data = adminLabels;

  constructor(public userService: UserService, public organisation: OrganisationService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private titleService: Title, private errorResponseService: ErrorResponseService, private toastService: ToastModalService) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // this.userService.setBreadcrumbs("Add", this.route.url['_value']);
        let breadCrumb = [
          {
            name: this.admin_data.organisationAddText,
            route: null
          }
        ];
        if (this.userService.organization_id === "000") {
          let tempDashboardBreadCrumb = this.userService.getDashboardBreadCrumb();
          breadCrumb.unshift(tempDashboardBreadCrumb);
        }
        this.userService.setBreadcrumb(breadCrumb)
        this.breadcrumb = this.userService.breadCrumbs;
      });
  }

  add_org_formdata = JSON.parse(JSON.stringify(ADD_ORG_FORMADATA));

  formData = new FormData();
  hasUploaded = false;
  breadcrumb;
  formGroup;
  ngOnInit() {
    this.titleService.setTitle(this.admin_data.organisationAddText + " | " + "Cornerstone Guide");

    this.userService.getUserData();
    this.organisation.getCDNData();
      this.userService.organization_id = '000';
      if (this.userService.user.role === 'partner_admin') {
          this.add_org_formdata.pop(); //remove mirror applications toggle
      }
    let form = {};
    for (let i = 0; i < this.add_org_formdata.length; i++) {
      form[this.add_org_formdata[i].name] = new FormControl('');
      let validations = [];
      for (let v of this.add_org_formdata[i].validators) {
        for (let key in v) {
          validations.push(key);
        }
      }
      let validators = [];
      for (let j = 0; j < validations.length; j++) {
        switch (validations[j]) {
          case ('required'):
            validators.push(Validators.required);
            break;

          case ('email'):
            validators.push(Validators.email);
            break;
        }
      }
      form[this.add_org_formdata[i].name].setValidators(validators);
    }
    this.formGroup = new FormGroup(form);
    let inputValidateField = ["name", "about"];
    for (let ind = 0; ind < inputValidateField.length; ind++) {
      this.formGroup.get(inputValidateField[ind]).valueChanges.subscribe(x => {
        if (x && !this.userService.pattern.test(x)) {
          this.formGroup.get(inputValidateField[ind]).setValue(x.replace(this.userService.replacePattern, ""), { emitEvent: false });
        }
      });
    }
  }

  addOrganization() {
    this.organisation.add_button.disable = true;
    this.organisation.add_button.text = this.admin_data.addingButtonText;
    let request = {
      name: this.formGroup.get("name").value.trim(),
      about: this.formGroup.get("about").value.trim(),
      organization_type: this.formGroup.get("organization_type").value,
      product_type: this.formGroup.get("product_type").value,
      };
      if (this.userService.user.role === 'superadmin') {
          request['has_mirror_apps'] = this.formGroup.get("has_mirror_apps").value;
      }
    request['settings'] = "{}";
    this.organisation.addOrganisation(request).subscribe((response) => {
      this.organisation.add_button.disable = false;
      this.organisation.add_button.text = this.admin_data.addButtonText;
      this.toastService.showToast = true;
      this.toastService.toastType = "add_org";
      this.organisation.add_button.text = this.admin_data.addButtonText;
      this.router.navigate(['']);
    }, error => {
      this.organisation.add_button.disable = false;
      this.organisation.add_button.text = this.admin_data.addButtonText;
    })
  }

  unassign() {
    for (let i = 0; i < this.add_org_formdata.length; i++) {
      this.formGroup.patchValue({
        [this.add_org_formdata[i].name]: ''
      })
    }
    this.router.navigate(['']);
  }
}
