import { Component, OnInit } from '@angular/core';
import { UserService } from '../common/services/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ErrorResponseService } from '../common/services/error-response.service';
import { Title } from '@angular/platform-browser';
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { filter } from 'rxjs/operators';
import {PlayerPackageService} from "./player-package.service"
import { OrganisationService } from '../common/services/organisation.service';
declare var $: any;

@Component({
    selector: 'app-player-packages',
    templateUrl: './player-packages.component.html',
    styleUrls: ['./player-packages.component.scss']
})
export class PlayerPackagesComponent implements OnInit {

    admin_data = adminLabels;
    breadcrumb;
    curr_org_id;
    cdn_signature = '';
    packageList = [];
    tableHeading = [this.admin_data.srNo, this.admin_data.version, this.admin_data.usersVariable2, this.admin_data.tableHeaderCreationDate, this.admin_data.tableHeaderActions]
    disabled = false;
    constructor(
        public userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private errorResponseService: ErrorResponseService,
        private playerPackageService : PlayerPackageService,
        private organisationService: OrganisationService,) {
        this.titleService.setTitle(
            this.admin_data.playerPackages + " | Cornerstone Guide"
        );
        this.userService.activeTab = 'player_packages';
        this.userService.getUserData();
        this.organisationService.getCDNData()
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.userService.setBreadcrumb([
                    {
                        name: this.admin_data.playerPackages,
                        route: null,
                    },
                ]);
                this.breadcrumb = this.userService.breadCrumbs;
            });
            this.route.paramMap.subscribe((params) => {
                if (params['params']['org_id']) {
                    this.curr_org_id = params['params']['org_id'];
                    this.userService.organization_id = this.curr_org_id;
                    this.getOrganization()
                    this.organisationService.getSignature(this.userService.organization_id).subscribe((response) => {
                        this.cdn_signature = response["data"]["cdn_signature"];
                    });
                }
            });
    }

    ngOnInit() {
        this.getPlayerPackages()   
    }

    getOrganization() {
        this.organisationService
            .getOrganization(this.curr_org_id)
            .subscribe((response) => {
                let organization = response["data"]["organization"];
    
                this.userService.organization = organization["name"];
                this.userService.organization_id = organization["organization_id"];
                // this.userService.user.cdn_signature = organization["cdn_signature"];
                this.userService.organization = organization;
                let admin_settings = JSON.parse(
                    this.userService.organization.admin_settings
                );
                this.userService.enableSegments =
                    admin_settings["guide_segmentation"] == "1" ? true : false;
                this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
                this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
                this.userService.enableMediaThemes= admin_settings['media_files_themes'] == 0 ? false : true;
                this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
                
                if (!this.userService.enablePlayerPackages) {
                    this.router.navigate([
                        "/organization",
                        this.userService.organization_id
                    ]);
                }
                let org = organization;
                let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));
                cdn_sign["cdn_signature"] = organization["cdn_signature"];
                cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;
    
                this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];
    
                localStorage.setItem("organization", JSON.stringify(org));
                localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
    
            });
    }
    getPlayerPackages() {
        this.playerPackageService.getPlayerPackages(this.userService.organization_id).subscribe((response)=> {
            if (response['error'] == false){
                this.packageList = response['data']['packages']
                this.packageList.forEach((pkg) => {                    
                    if(pkg['status'] !== "DONE" && pkg['status'] !== "FAILED"){
                        this.disabled = true;
                    }
                })
            }else{
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            }
            
        })
    }

    downloadFile(url, filename): void {
        let task_filename = filename.split('/')
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = task_filename[task_filename.length - 1];
        anchor.target = '_blank';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    generatePlayerPackage(){
        console.log("generatingPlayerPackages");
        this.playerPackageService.generatePlayerPackage(this.userService.organization_id).subscribe((response)=> {
            if (response['error'] == false){
                this.getPlayerPackages()
            }else{
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            }
            
        })
        
    }

}
