import adminLabels from "../ApplicationStrings/trans_utils";

export const ADD_ORG_FORMADATA = [
  {
    type: "text",
    name: "name",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    validators: [
      {
        required: true,
        maxLength: 120,
      },
    ],
    ui: {
      label: adminLabels.addOrganisationName,
      class: "col-lg-2 label-asterisk",
      inputclass: "col-lg-6",
    },
    input: {
      id: "name",
      class: "form-control",
      type: "text",
      name: "name",
      placeholder: adminLabels.addOrganisationName,
    },
    label: {
      title: adminLabels.addOrganisationName,
      class: "font-weight-bold label-asterisk",
      for: "name",
    },
  },
  {
    type: "textarea",
    name: "about",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    value: "",
    validators: [
      {
        required: true,
        maxLength: 3000,
      },
    ],
    ui: {
      label: adminLabels.tableHeaderDescription,
      class: "font-weight-bold label-asterisk",
      inputclass: "form-control",
    },
  },
  {
    type: "radio",
    name: "organization_type",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    value: ["paid", "trial"],
    value_labels: [adminLabels.organisationPaid, adminLabels.organisationTrial],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.addOrganisationType,
      class: "font-weight-bold label-asterisk",
    },
  },
  {
    type: "radio",
    name: "product_type",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    value: ["legacy", "enterprise"],
      value_labels: [adminLabels.legacy, adminLabels.enterprise],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.productType,
      class: "font-weight-bold label-asterisk",
    },
  },
  {
    type: "checkbox",
    name: "has_mirror_apps",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 d-flex align-items-center font14",
    value: "0",
    checked: false,
    validators: [],
    ui: {
      label: adminLabels.enableMirrorApplications,
      class: "font-weight-bold mb-0",
    },
  }
];
