import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { API_END_POINTS } from 'src/app/constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { UserService, httpOptions } from './user.service';
import { ApiService } from './api.service';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { observable } from 'rxjs';


export interface Tasks{
  "id": string,
  "title": string,
  "application_title": string,
  "organization_id": string,
  "application_id": string,
  "is_publish": string,
  "creation_date": string,
  "modification_date": string,
  "completion_count": number,
  "setting": string;
}

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  isEdit: boolean = false;
  openLeaderBoard = false;
  selectedTask: Tasks;
    curr_language = "";
  constructor(private userService:UserService,private apiService:ApiService, private http:HttpClient) { }

  titles=[adminLabels.tableHeaderTitle,adminLabels.taskListTableHeaderApplication,adminLabels.taskListTableHeaderLeaderboard,adminLabels.tableHeaderStatus,adminLabels.tableHeaderActions];
  DataKeys=['','title','application_title','completion_count','is_publish','task_action'];

  col_widths = ['20%','20%','20%','20%','20%'];

  add_button={
    type:'add_task',
    class:'btn btn-primary minWidth-120',
    disable:false,
    text:adminLabels.addButtonText
  }
  
  update_button={
    type:'update_task',
    class:'btn btn-primary minWidth-120',
    disable:false,
    text:adminLabels.updateButtonText
  }
  
  cancel_button={
    type:'cancel',
    class:'btn btn-secondary minWidth-120 mx-2',
    disable:false,
    text:adminLabels.cancelButtonText
  }

  getTasksList(limit,offset, language, search=''){
    let url = environment.baseUrl+API_END_POINTS.GET_TASKS_LIST;
    url+="?limit="+limit+"&offset="+offset+"&organization_id="+this.userService.organization_id;
    if(search!=''){
      url+='&search='+search;
    }
    if(language != ''){
        url+= '&language=' + language;
    }
    return this.apiService.get(url)
  }

  getHeader(){
    return {
       headers: new HttpHeaders({
        //  'AppKey': environment.authKey,
         'AccessToken':this.userService.user.token
     })
     }
    }

  deleteTask(req){
    let url = environment.baseUrl+API_END_POINTS.DELETE_TASKS_LIST;
    let options=this.getHeader();
    options['body']=req;
    return this.http.delete(url,options);
  }

  addTaskList(request) {
    let urlPath = environment.baseUrl + API_END_POINTS.ADD_TASK_LIST;
    return this.apiService.post(urlPath, request);
  }

  editTaskList(request) {
    let urlPath = environment.baseUrl + API_END_POINTS.EDIT_TASK_LIST;
    return this.apiService.put(urlPath, request);
  }

  languageUpdateForTaskList(request) {
    let urlPath = environment.baseUrl + API_END_POINTS.PUT_TASKLIST_LANGUAGE_UPDATE;
    return this.apiService.put(urlPath, request)
  }

  updatePublishStatus(request){
    let url = environment.baseUrl+API_END_POINTS.UPDATE_TASK_LIST_PUBLISH;
    return this.apiService.put(url,request);
  }

  updateSetting(request){
    let url = environment.baseUrl+API_END_POINTS.UPDATE_TASK_LIST_SETTING;
    return this.apiService.put(url,request);
  }
}
