import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { API_END_POINTS } from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";


export interface Segmentation{
  id: string,
  organization_id:string,
  key_name: string,
  data: string,
  is_active: string,
  creation_date: string,
  modification_date: string,
  key_type: any
}

export interface Segment_List{
  value:[];
}


@Injectable({
  providedIn: 'root'
})
export class SegmentationService {

appTitles = [ adminLabels.segmentsTableHeaderKeyName, adminLabels.segmentsTableHeaderIsRequired, adminLabels.tableHeaderModificationDate, adminLabels.tableHeaderActions];
appDataKeys = ['', 'key_name', 'is_required', 'modification_date', 'segment_action'];

col_widths= ['25%','10%','15%','10%'];

list_appTitles = [adminLabels.segmentsListTableHeaderValue,adminLabels.segmentsListTableHeaderIsDefault,adminLabels.tableHeaderActions];
list_appDataKeys = ['','value','default_value', 'segment_list_action'];

col_list_widths = ['80%','30%'];


add_button={
  type:'add_segment',
  class:'btn btn-primary',
  disable:false,
    text: adminLabels.addUserAttributesButtonText
}

update_button={
  type:'update_segment',
  class:'btn btn-primary',
  disable:false,
    text: adminLabels.updateUserAttributesButtonText
}

cancel_button={
  type:'cancel',
  class:'btn btn-secondary minWidth-120',
  disable:false,
  text:adminLabels.cancelButtonText
}

add_value_button={
  type:'add_segment_value',
  class:'btn btn-primary',
  disable:false,
  text:adminLabels.segmentsAddValueButtonText
}

cancel__value_button={
  type:'cancel',
  class:'btn btn-secondary minWidth-120',
  disable:false,
  text:adminLabels.cancelButtonText
}


isEdit=false;
selectedSegment:Segmentation;

  constructor(private userService:UserService,private apiService:ApiService) { }

  getSegmentations(org_id){
    let urlPath=environment.baseUrl+API_END_POINTS.GET_SEGMENTS+"?organization_id="+org_id;
    return this.apiService.get(urlPath);
  }

  addSegment(request){
    let urlPath=environment.baseUrl+API_END_POINTS.ADD_SEGMENT;
    return this.apiService.post(urlPath,request);
  }

  editSegment(request) {
    let urlPath = environment.baseUrl + API_END_POINTS.EDIT_SEGMENT;
    return this.apiService.put(urlPath,request);
  }

  deleteSegmentation(id){
    let httpParams = new HttpParams().set('id', id);
    let urlPath=environment.baseUrl+API_END_POINTS.DELETE_SEGMENT;
    return this.apiService.delete(urlPath,httpParams);
  }

  getSegmentationList(id){
    let urlPath=environment.baseUrl+API_END_POINTS.GET_SEGMENT_VALUES+"?id="+id;
    return this.apiService.get(urlPath);
  }

  addSegmentList(request){
    let urlPath=environment.baseUrl+API_END_POINTS.ADD_SEGMENT_VALUES;
    return this.apiService.put(urlPath,request);
  }

    getTaskStatus(limit, offset, org_id, task_type) {
        let urlPath =
            environment.baseUrl + API_END_POINTS.GET_TASK_STATUS +
            "?limit=" +
            limit +
            "&offset=" +
            offset +
            "&organization_id=" +
            parseInt(org_id) + "&task_type=" + task_type;
        return this.apiService.get(urlPath);
    }

    importSegmentsValues(request,){
        let urlPath = environment.baseUrl + API_END_POINTS.IMPORT_SEGMENT_VALUES;		
		return this.apiService.post(urlPath, request);
    }

    importSegment(request,) {
        let urlPath = environment.baseUrl + API_END_POINTS.IMPORT_SEGMENT;
        return this.apiService.post(urlPath, request);
    }


}
