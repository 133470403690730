<button id="button-elem" type="button" [class]="button.class" (click)="handleClick()"
  [disabled]="button.disable || disabled" 
  data-toggle="tooltip"
  data-placement="right"
  data-html="true"
  title="{{button.tooltip ? button.tooltip : null}}"
  >
 {{button.text}}
</button>



