import { Injectable, Injector } from "@angular/core";
import { User } from "../common/services/user.service";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";

import { tap, catchError, retry, switchMap } from "rxjs/operators";

import { OrganisationService } from "../common/services/organisation.service";
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { UserService } from "../common/services/user.service";
import { environment } from "src/environments/environment";
import { ApplicationService } from "../common/services/application.service";
import { Router } from "@angular/router";
import { ApiService } from "../common/services/api.service";
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { CustomError } from "../common/error-handler/custom-error.model";
declare var $: any;

@Injectable()
export class TokenInterceptorInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
    private router: Router,
    private errorResponseService: ErrorResponseService,
    private http: HttpClient,
    private userService: UserService,
    private application: ApplicationService,
    private organisationService: OrganisationService,
    private authService: ApiService,
  ) { }
  public isReloadedSub: Subject<HttpResponse<any>> = new Subject<
    HttpResponse<any>
  >();
  public simplemessageSub: Subject<string> = new Subject<string>();

  req;
  isReloaded = false;
  private totalRequests = 0;
  eventEmitted$ = this.isReloadedSub.asObservable();
  simplemessage$ = this.simplemessageSub.asObservable();
  //   userService = this.injector.get(UserService);
  //   application = this.injector.get(ApplicationService);
  //   organisationService = this.injector.get(OrganisationService);
  eventToEmit: HttpResponse<any>;
  cdn_initiated = false;
  cdn_user_initiated = false;

  //   intercept(
  //     request: HttpRequest<any>,
  //     next: HttpHandler
  //   ) {
  //     let userService = this.injector.get(UserService);
  //     let application = this.injector.get(ApplicationService);
  //     if (this.req) {
  //     }
  //     this.totalRequests++;
  //     userService.show();
  //     return next.handle(request).pipe(
  //       tap(
  //         event => {
  //           if (event instanceof HttpResponse) {
  //             userService.hide();
  //             this.eventToEmit=event;
  //             if(this.isReloaded){
  //               application.returnEvent(this.eventToEmit.body);
  //               this.isReloaded=false;
  //             }
  //             // if (this.req && request.url == 'https://api-v3-stage.guideme.io/v3/user/token') {
  //             //   return next.handle(this.req).pipe(tap(ev => {
  //             //   }))
  //             // }
  //           }
  //           this.totalRequests--;
  //           this.isReloaded=false;
  //         },
  //         error => {
  //           if (error instanceof HttpErrorResponse) {

  //             let urlPath;
  //             this.req=request.clone();
  //             const userOptions = {
  //               headers: new HttpHeaders({
  //                 'AppKey': environment.authKey,
  //                 'RefreshToken': userService.user.refreshtoken
  //               })
  //             }
  //             urlPath = environment.baseUrl + "/user/token";
  //             return this.http.get(urlPath, userOptions).subscribe((res) => {
  //               let data = res['data'];
  //               userService.user.token = data['accesstoken'];
  //               let appData = JSON.parse(localStorage.getItem('appData'));
  //               appData['user'] = userService.user;
  //               appData.token = userService.user.token;
  //               localStorage.setItem('appData', JSON.stringify(appData));
  //               // this.req.headers=new HttpHeaders({
  //               //   'AppKey': environment.authKey,
  //               //   'RefreshToken': userService.user.token
  //               // })
  //               // let allRequestOptions = {
  //               //   headers: new HttpHeaders({
  //               //     'AppKey': environment.authKey,
  //               //     'AccessToken': userService.user.token
  //               //   })
  //               // }
  //               this.req.headers.set('AccessToken',userService.user.token);
  //               this.req=this.req.clone({setHeaders:{['AccessToken']:userService.user.token}});
  //               return this.http.request(this.req).subscribe((data)=>{
  //                 this.isReloaded=true;
  //               })
  //             });
  //           }
  //         }
  //       )
  //     )
  //   }

  // //   emitIsReloaded(input) {
  // //     this.isReloadedSub.next(input);
  // //  }

  // getsubject():Observable<any>{
  //   return this.isReloadedSub.asObservable();
  // }
  //   authService = this.injector.get(ApiService);
  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject<void>();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  admin_data = adminLabels;



  addAuthHeader(request) {
    let authHeader = this.userService.user.token;
    if (request.url.includes("signout")) {
      return request.clone({
        setHeaders: {
        //   AppKey: environment.authKey,
          RefreshToken: this.userService.user.refreshtoken,
        },
      });
    }
    if (request.url.includes("youtube_settings")) {
      return request;
    }
    if (request.url.includes("otp") || request.url.includes("user/verify")) {
      return request;
    }
    if (authHeader) {
      return request.clone({
        setHeaders: {
        //   AppKey: environment.authKey,
          AccessToken: authHeader,
        },
      });
    }
    return request;
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable((observer) => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.authService.refreshToken().pipe(
        tap(
          (res) => {
            let data = res["data"];
            if (res["error"] == true) {
              //   localStorage.removeItem("appData");
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              localStorage.removeItem("organization");
              localStorage.removeItem("cdn_sign");
              let errorResponse = this.errorResponseService.getErrorResponseMessage(res['message'][0]);
              this.userService.errorMessage = errorResponse;
              $("#errorModal").modal("show");
              this.router.navigate(["/login"]);
            }
            if (data) {
              this.userService.user.token = data["accesstoken"];
              this.userService.user.accesstoken = data["accesstoken"];
            }
            let user = this.userService.user;
            let token = JSON.parse(localStorage.getItem("token"));

            if (token) {
              token["accesstoken"] = this.userService.user.token;
            }
            localStorage.setItem("token", JSON.stringify(token));
            localStorage.setItem("user", JSON.stringify(user));
            this.refreshTokenInProgress = false;
            this.tokenRefreshedSource.next();
          },
          (error) => {
            this.refreshTokenInProgress = false;
            this.logout();
          }
        )
      );
    }
  }

  logout() {
    this.userService.user.token = "";
    $("#errorModal").modal("hide");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("organization");
    localStorage.removeItem("cdn_sign");
    this.userService.user.token = '';
    // this.router.navigate(["/login"]);

    const query = new URLSearchParams(window.location.search);
    const urlData = [];
    query.forEach((v, k) => {
      urlData[k] = v;
    });
    this.userService.hide();
    //this.router.navigate(['/'], { queryParams: urlData });
    location.reload();


  }

  getErrorMessage(error) {
    let msg = '';
    if (typeof error['message'] === 'string' || error['message'] instanceof String) {
      let errorResponse = this.errorResponseService.getErrorResponseMessage(error['message']);
      msg += errorResponse + '.';
    }
    else {
      for (let i = 0; i < error['message'].length; i++) {
        let errorResponse = this.errorResponseService.getErrorResponseMessage(error['message'][i]);
        msg += errorResponse + '.';
      }
    }
    return msg;
  }

  handleResponseError(error, request?, next?) {

      if (error instanceof HttpErrorResponse && error.url.includes("/log/error/frontend/")) {
          console.error("Error while sending error logs", error.status)
          return;
      }
    try {

      if (error.status !== 401) {//as 401 in invalid token, we can not call api with invalid token
        throw new CustomError({ errorType: 8, errorLog: error.message, severity: 4, urlPath: request });
      }
    } catch (err) {
      //suppressing error
    }
    // Business error
    if (error.status === 400) {
      this.userService.errorMessage = this.getErrorMessage(error['error']);
      $("#errorModal").modal("show");
      this.userService.hide();
    }

    // Invalid token error
    else if (error.status === 401) {
        console.log(error, "401 error");
        if (error?.error['code'] == 1014) {
          console.log(error, "maintenance error");
          
        this.userService.hide();
        this.redirectToMaintenance();
      }
      else if (error instanceof HttpErrorResponse && error.url.includes("/user/token")) {
        this.userService.errorMessage = this.admin_data.errorMessage1;
        $("#errorModal").modal("show");
        this.logout();
      } else {
        return this.refreshToken().pipe(
          switchMap((res) => {
            // if (res['data'].accesstoken) {
            request = this.addAuthHeader(request);
            return next.handle(request);
            // }
            // return this.logout();
          }),
          catchError((e) => {
            if (e.status !== 401) {
              console.log("!401")
              return this.handleResponseError(e);
            } else {
              this.logout();
            }
          })
        );
      }
    }

    // Access denied error
    else if (error.status === 403) {
      // Show message
      // Logout
      this.logout();
    }

    // Server error
    else if (error.status === 500) {
      this.userService.errorMessage = this.admin_data.errorMessage2;
      $("#errorModal").modal("show");
      this.userService.hide();
    }

    // Maintenance error
    else if (error.status === 503) {
      this.redirectToMaintenance();
      this.userService.hide();
      // Show message
      // Redirect to the maintenance page
    }

    else {
      this.userService.errorMessage = this.getErrorMessage(error);
      $("#errorModal").modal("show");
      this.userService.hide();
    }

    return throwError(error);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!(request.url.includes("/task_list/content") && request.method == 'GET')) {
      this.userService.show();
    }
    // this.authService = this.injector.get(ApiService);
    // this.organisationService = this.injector.get(OrganisationService);

    let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));
    let user = JSON.parse(localStorage.getItem("user"));
    if (cdn_sign) {
      this.organisationService.cdn_signature_expiry =
        cdn_sign["cdn_signature_expiry"];
      if (
        (!this.organisationService.cdn_signature_expiry ||
          this.organisationService.cdn_signature_expiry <
          Math.floor(Date.now() / 1000)) &&
        !this.cdn_initiated && this.userService.organization_id && this.userService.organization_id != '000'
      ) {
        this.cdn_initiated = true;
        this.getCdnSignature();
      }
    }
    else if (user && !cdn_sign && !this.cdn_initiated && this.userService.organization_id && this.userService.organization_id != '000') {
      this.cdn_initiated = true;
      this.getCdnSignature();
    }

    if (user) {
      let user_org_cdn_signature_expiry = user['cdn_signature_expiry'];
      if (this.userService.user.cdn_signature != this.organisationService.cdn_signature && user_org_cdn_signature_expiry < Math.floor(Date.now() / 1000) && !this.cdn_user_initiated) {
        this.cdn_user_initiated = true;
        this.getUserOrgCDNSignature(user);
      }
    }

    // Handle request
    request = this.addAuthHeader(request);
    // Handle response
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.userService.hide();
        }
      }),
      catchError((error) => {
        console.log("catcherror")
        return this.handleResponseError(error, request, next);
      })
    );
  }

  getUserOrgCDNSignature(user) {
    console.log("calling user signture from token interceptor", user['organization']['organization_id'])
    this.organisationService.getSignature(user['organization']['organization_id']).subscribe((response) => {
      let data = response["data"];
      this.userService.user.cdn_signature = data["cdn_signature"];
      this.userService.user.cdn_signature_expiry = data['cdn_signature_expiry'];

      user['cdn_signature'] = this.userService.user.cdn_signature;
      user['cdn_signature_expiry'] = this.userService.user.cdn_signature_expiry;

      localStorage.setItem("user", JSON.stringify(user));

      this.cdn_user_initiated = false;
    });
  }

  getCdnSignature() {
    this.organisationService
      .getSignature(this.userService.organization_id)
      .subscribe(
        (response) => {
          let data = response["data"];
          this.organisationService.cdn_signature = data["cdn_signature"];
          this.organisationService.cdn_signature_expiry =
            data["cdn_signature_expiry"];

          if (this.userService.user.role != 'superadmin') {
            this.userService.user.cdn_signature = data["cdn_signature"];
            this.userService.user.cdn_signature_expiry =
              data["cdn_signature_expiry"];
          }

          let cdn_sign = {};
          cdn_sign["cdn_signature"] = this.organisationService.cdn_signature;
          cdn_sign[
            "cdn_signature_expiry"
          ] = this.organisationService.cdn_signature_expiry;


          localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
          this.cdn_initiated = false;
        },
        (error) => {
        }
      );
  }

  redirectToMaintenance() {
    if (window.location.pathname.indexOf('maintenance_mode') == -1) {
      this.router.navigate(['/maintenance_mode'], { queryParams: { redirectfrommaintenance: btoa(window.location.href) } })
    }
  }
}

