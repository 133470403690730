<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">

            <div class="sectionBody">
                <div class="d-flex">
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                        {{this.admin_data.exportDataExportUserButtonText}}
                    </div>                                  
                    <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right">
                        <button type="button" class="btn btn-secondary ml-2"
                            (click)="getUserExportTaskStatus()">{{this.admin_data.refresh}}</button>
                    </div>
                </div>

                <div class="tab-container clearfix">
                        <div class="row m-0 w-90">
                            <div class="page positionRelative">
                                <div class="d-flex my-3">
                                    <div class="col-sm-9 col-md-9 col-lg-9 col-xl-9 p-0 text-left ">
                                        <app-button [buttonData]="exportService.user_data_button"
                                            (clickhandler)="exportUserList()">
                                        </app-button>
                                    </div>
                                </div>
                                <app-table [data]="export_reports" [titles]="exportService.appTitles"
                                    [dataKeys]="exportService.appDataKeys" [widths]="exportService.col_widths"></app-table>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
       
    </div>
</div>