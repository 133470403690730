<div *ngIf="appLoader == true; else loggedOut">
    <app-spinner *ngIf="!maintenanceFlag"></app-spinner>
</div>

<ng-template #loggedOut>
    <app-header *ngIf="(userService.user) && (userService.user.token != '')"></app-header>
    <app-spinner *ngIf="(isLoading | async) && !maintenanceFlag"></app-spinner>

    <div [ngClass]="this.url_path ? 'bgHeight' : 'bg-color'">
        <div class="d-flex" [ngClass]="{'h-100':!bottomMargin}">
            <div id="flexNav"
                *ngIf="(userService.organization_id && userService.organization_id != '000') && (userService.user && userService.user.token != '')">
                <app-left-panel></app-left-panel>
            </div>
            <router-outlet>
            </router-outlet>
        </div>
        <ng-container *ngIf="bottomMargin">
            <br><br>
        </ng-container>
    </div>
    <div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="errorModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="errorModal">{{this.admin_data.error}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ng-container>
                    <div class="modal-body break-w">
                        <div class="alert alert-danger m-0" role="alert">
                            <b class="text-dark">{{userService.errorMessage}}</b>
                        </div>
                    </div>
                </ng-container>
                <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-secondary w-25"
                        data-dismiss="modal">{{this.admin_data.okButtonText}}</button>
                </div>
            </div>
        </div>
    </div>
    <footer class="footer toggle-view-fixedBar"
        [ngClass]="{'expanded-view': (userService.organization_id != '000' && userService.sidebarOpen && !userService.dashboardLoading), 'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen && !maintenanceFlag)}"
        *ngIf="(userService.user) &&( userService.user.token != '')">
        <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2 fs-small" style="color: #454560;">
            <span class="float-md-left d-block d-md-inline-block">
                {{"© " + "Cornerstone " + userService.cuurentYear + " "}}
                &#8226;
                {{this.admin_data.version}}
                {{this.version}}
            </span>
            <span class="float-md-right d-block d-md-inline-blockd-none d-lg-block">
                {{this.admin_data.loginContactEdcast}}
                <a class="text-bold-800 grey darken-2 supportLink"
                    href="mailto:support@edcast.com">{{this.admin_data.support}}</a>
            </span>
        </p>
    </footer>
</ng-template>