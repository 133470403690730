//@ts-nocheck
import { Component, OnInit, HostListener } from "@angular/core";
import { UserService, User } from "../../common/services/user.service";
import { DBUser, DashboardService } from "../../common/services/dashboard.service";
import { OrganisationService } from "../../common/services/organisation.service";
import { ApplicationService } from "../../common/services/application.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { RoleService } from "../../common/services/role.service";
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { PaginationService } from '../../pagination.service';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "../../common/services/toast-modal.service";
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

declare var $: any;

@Component({
    selector: "app-internal-users",
    templateUrl: "./internalUsers.component.html",
    styleUrls: ["./internalUsers.component.scss"],
})
export class InternalUsersComponent implements OnInit {
    limit = 10;
    offset = 0;
    users: DBUser[];

    pages = [1, 2, 3];
    currentPage = 1;
    prevIndex = 0;
    currentIndex = 1;
    total = 0;
    pageLimit: number = 0;
    deleteIndex = 0;
    unlockIndex = 0;
    delete_user_id = 0;
    unlock_user_id = {};
    searchInputApp;
    allRoles = [];
    org_id;
    selectedOrg;
    search_text = "";
    is_suspend_filter;
    role_filter;
    org_role_filter;
    is_global = false;
    table_titles: string[] = [];
    table_title_keys: string[] = [];
    col_widths: string[] = [];
    is_searching = false;

    disableNext = false;
    disablePrev = false;
    showPagination = false;
    prevGetUserListCall;
    pageLimitSet = false;
    lockModal = false;
    activeModal = false;
    breadcrumb;
    selected_user_id;
    csv = '"FIRST NAME","LAST NAME","EMAIL ID","USER ROLE","APP ACCESS ROLE"';

    admin_data = adminLabels;

    currentTab = 1;

    constructor(
        public userService: UserService,
        public dbService: DashboardService,
        private organisationService: OrganisationService,
        private application: ApplicationService,
        private router: Router,
        private roleService: RoleService,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private titleService: Title,
        public paginationService: PaginationService,
        private errorResponseService: ErrorResponseService,
        private toastService: ToastModalService
    ) {
        this.titleService.setTitle(this.admin_data.usersBreadCrumbTitle + " | " + "Cornerstone Guide");
        // this.userService.getUserData();
        // this.paginationService.initializeValues();
        // this.organisationService.getCDNData()
        // this.userService.activeTab = 'users';
        // this.table_title_keys = this.dbService.userKeys;
        // this.route.paramMap.subscribe((params) => {
        //     this.org_id = params["params"]["org_id"];
        //     this.col_widths = this.dbService.col_widths;
        //     this.is_global = false;
        //     if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" )) {

        //         this.dbService.DBtableHead = [
        //             adminLabels.addUserAvtar,
        //             adminLabels.addUserFirstName,
        //             adminLabels.addUserLastName,
        //             adminLabels.addUserEmailId,
        //             adminLabels.tableHeaderStatus,
        //             adminLabels.addUserLockedStatus,
        //             adminLabels.addUserRole,
        //             adminLabels.addUserOrganisationRole,
        //             adminLabels.dashboardUserAction,
        //         ];

        //         this.table_titles = this.dbService.DBtableHead;

        //         this.organisationService.getOrganization(this.org_id).subscribe(
        //             (response) => {
        //                 let organization = response["data"]["organization"];

        //                 this.userService.organization = organization["name"];
        //                 this.userService.organization_id =
        //                     organization["organization_id"];
        //                 // this.userService.user.cdn_signature = organization["cdn_signature"];
        //                 this.userService.organization = organization;
        //                 let admin_settings = JSON.parse(
        //                     this.userService.organization.admin_settings
        //                 );
        //                 this.userService.enableSegments =
        //                     admin_settings["guide_segmentation"] == "1" ? true : false;
        //                 this.userService.enableSSO =
        //                     admin_settings["sso"] == 1 ? true : false;

        //                 this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
        //                 this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;

        //                 this.userService.enableMediaThemes=admin_settings['media_files_themes'] == 0 ? false : true;

        //                 let org = organization;
        //                 let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));
        //                 cdn_sign["cdn_signature"] = organization["cdn_signature"];
        //                 cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

        //                 this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
        //                 this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];
        //                 localStorage.setItem("organization", JSON.stringify(org));
        //                 localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
        //                 this.selectedOrg = this.userService.organization_id;
        //                 this.ngOnInit();
        //             },
        //             (error) => {
        //             }
        //         );
        //     } else if (!this.org_id && (this.userService.user.role == 'superadmin' || this.userService.user.role == 'owner' || this.userService.user.role == 'mg_admin')) {
        //         this.is_global = true;
        //         this.table_titles = this.dbService.DBtableHead_global;
        //         this.table_title_keys = this.dbService.userKeys_global;
        //         this.col_widths = ['10%', '10%', '14%', '10%', '10%', '10%', '14%', '10%', '12%'];
        //     }
        //     else {
        //         this.dbService.DBtableHead = [
        //             adminLabels.addUserAvtar,
        //             adminLabels.addUserFirstName,
        //             adminLabels.addUserLastName,
        //             adminLabels.addUserEmailId,
        //             adminLabels.tableHeaderStatus,
        //             adminLabels.addUserLockedStatus,
        //             adminLabels.addUserRole,
        //             adminLabels.addUserOrganisationRole,
        //             adminLabels.dashboardUserAction,
        //         ];
        //         this.table_titles = this.dbService.DBtableHead;
        //     }


        // });
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs("Users", this.route.url["_value"]);
                let breadCrumb;
                if (this.userService.organization_id == "000" || this.userService.organization_id == undefined) {
                    breadCrumb = [
                        {
                            name: adminLabels.dashboardBreadCrumb,
                            route: ['']
                        },
                        {
                            name: adminLabels.usersBreadCrumbTitle,
                            route: null
                        }
                    ];
                } else {
                    breadCrumb = [
                        {
                            name: adminLabels.usersBreadCrumbTitle,
                            route: null
                        }
                    ];
                }
                this.userService.setBreadcrumb(breadCrumb);
                this.breadcrumb = this.userService.breadCrumbs;
            });


    }

    ngOnInit() {

        // this.searchInputApp = document.getElementById("searchInputApp");
        // this.userService.isEdit = false;
        // this.pageLimitSet = false;

        // if (
        //     this.userService.user.role != "superadmin" ||
        //     (this.userService.user.role == "superadmin" &&
        //         this.selectedOrg == this.userService.organization_id) && !this.is_global
        // ) {
        //     let userList = this.getUserList.bind(this);
        //     var promise = new Promise((resolve, reject) => {
        //         let roleService = this.roleService;
        //         let limit = 100;
        //         let offset = 0;
        //         let cb = roleService.getRoles(limit, offset);
        //         this.allRoles = [];
        //         let allRoles = this.allRoles;
        //         let fetchRows = false;
        //         let rows;
        //         function roles() {
        //             cb.subscribe(
        //                 (data) => {
        //                     Array.prototype.push.apply(
        //                         allRoles,
        //                         data["data"]["organizationRoleList"]
        //                     );
        //                     offset += 100;
        //                     cb = roleService.getRoles(limit, offset);
        //                     if (!fetchRows) {
        //                         rows = data["data"]["total_rows"]
        //                         fetchRows = true;
        //                     } else {
        //                         rows = rows % 100;
        //                     }
        //                     if (rows / 100 > 1) {
        //                         roles();
        //                     } else {
        //                         allRoles.push({
        //                             role_id: '0',
        //                             title: ''
        //                         })
        //                         resolve(allRoles);
        //                     }
        //                 },
        //                 (error) => {
        //                 }
        //             );
        //         }
        //         roles();
        //     }).then(function (data) {
        //         userList();
        //     });
        // }
        // if (this.is_global) {
        //     this.getUserList();
        // }
    }

    changeTab(val) {
        this.currentTab = val;
    }


}