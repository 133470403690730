import { Injectable } from '@angular/core';
import { HttpClientModule, HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import { API_END_POINTS } from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
// import { url } from 'inspector';

@Injectable({
  providedIn: 'root'
})
export class AdminSettingsService {


  constructor(private http: HttpClient,private userService: UserService,private apiService:ApiService) { }

  save_button={
    type:'save_button',
    class:'btn btn-primary minWidth-120 mx-2',
    disable:false,
    text:adminLabels.saveSettingsButtonText
  }

  cancel_button={
    type:'cancel',
    class:'btn btn-secondary minWidth-120 mx-2',
    disable:false,
    text:adminLabels.cancelButtonText
  }

  getAdminSettings(){
    let urlPath=environment.baseUrl+API_END_POINTS.GET_ADMIN_SETTINGS+'?'+"organization_id="+this.userService.organization_id;
    return this.apiService.get(urlPath);
  }

  getLanguageSettings(){
    let urlPath=environment.CDN_BASE_URL +API_END_POINTS.LANGUAGE_SETTING;
    return this.apiService.get(urlPath);
  }

  updateAdminSettings(request){
    let urlPath = environment.baseUrl+API_END_POINTS.GET_ADMIN_SETTINGS;
    return this.apiService.put(urlPath,request);
  }
}
