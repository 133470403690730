import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UserService } from '../common/services/user.service';
import adminLabels from "../constants/ApplicationStrings/trans_utils";
declare var $:any;

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent implements OnInit {

  admin_data = adminLabels ;
  constructor(public userService:UserService) { }

  ngOnInit() {
    this.userService.showModal.subscribe((value)=>{
      if(value){
        $("#deleteModal").modal("show");
      }else{
        $("#deleteModal").modal("hide");
      }
    })
  }

  elem;
  showModal=false;

  @Input('delete_elem') set delete_elem(value){
    this.elem=value;
  };

  @Input('show_email') show_email;

  @Input('show') set show(value){
    this.showModal=value;
    if(this.showModal){
      $("#deleteModal").modal("show");
    }else{
      $("#deleteModal").modal("hide");
    }
  }

  @Output() onDelete = new EventEmitter();
  @Output() onClose = new EventEmitter();

  
  closeModal() {
    this.userService.showModal.next(false);
}

delete(){
this.onDelete.emit(this.delete_elem);
}

}
