import adminLabels from "../ApplicationStrings/trans_utils";

import { environment } from 'src/environments/environment';
export const ORG_FORMADATA = [
  {
    type: "text",
    name: "name",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    validators: [
      {
        required: true,
        maxLength: 120,
      },
    ],
    ui: {
      label: adminLabels.addOrganisationName,
      class: "col-lg-2 label-asterisk",
      inputclass: "col-lg-6",
    },
    input: {
      id: "name",
      class: "form-control",
      type: "text",
      name: "name",
      placeholder: adminLabels.addOrganisationName,
    },
    label: {
      title: adminLabels.addOrganisationName,
      class: "font-weight-bold label-asterisk",
      for: "name",
    },
  },
  {
    type: "textarea",
    name: "about",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    value: "",
    validators: [
      {
        required: true,
        maxLength: 3000,
      },
    ],
    ui: {
      label: adminLabels.tableHeaderDescription,
      class: "font-weight-bold label-asterisk",
      inputclass: "form-control",
    },
  },
  {
    type: "radio",
    name: "organization_type",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    value: ["paid", "trial"],
    value_labels: [adminLabels.organisationPaid, adminLabels.organisationTrial],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.addOrganisationType,
      class: "font-weight-bold label-asterisk",
    },
  },
  {
    type: "radio",
    name: "product_type",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    value: ["legacy", "enterprise"],
      value_labels: [adminLabels.legacy, adminLabels.enterprise],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.productType,
      class: "font-weight-bold label-asterisk",
    },
  },
//   {
//       type: "radio",
//       name: "insights_customer_type",
//       class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
//       value: ["myguide_customers", "csod-internal"],
//       value_labels: [adminLabels.myguideCustomers, adminLabels.csodInternal],
//       validators: [
//           {
//               required: true,
//           },
//       ],
//       ui: {
//           message: adminLabels.insightsCustomerTypeMessage,
//           label: adminLabels.insightsCustomerType,
//           class: "font-weight-bold",
//       },
//       tooltip: adminLabels.insightsCustomerTypeTooltip
//   },
  {
    type: "checkbox",
    name: "dual_auth",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 d-flex align-items-center font14",
    value: "",
    checked: true,
    validators: [],
    ui: {
      label: adminLabels.organisationFormDualAuthentication,
      class: "font-weight-bold mb-0",
    },
  },
  // {
  //     type:'checkbox',
  //     name:'guiderole',
  //     class:'elem',
  //     value:'',
  //     checked:false,
  //     validators:[],
  //     ui:{
  //         label:'User Role',
  //         class:'col-lg-2',
  //         inputclass:'col-lg-6'
  //     }
  // },
  {
    type: "file",
    name: "uploadorg",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    fileclass: "img-fluid border",
    value: "",
    source: "",
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.organisationFormUploadOrganisationThumbnail,
      class: "font-weight-bold",
      inputclass: "w-50 text-center",
    },
  },
  {
    type: "file",
    name: "first_slide",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    fileclass: "img-fluid border",
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.organisationFormUploadFirstSlide,
      class: "font-weight-bold",
      inputclass: "w-50 text-center",
    },
    default_path: environment.CDN_BASE_URL + "/guideme-assests/default_first_slide.png"
  },
  {
    type: "text",
    name: "first_slide_text",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    validators: [
      {
        required: false,
      },
    ],
    ui: {
      label: adminLabels.organisationFormUploadFirstSlideText,
      class: "col-lg-2",
      inputclass: "col-lg-6",
    },
    input: {
      id: "name",
      class: "form-control",
      type: "text",
      name: "first_slide_text",
      placeholder: adminLabels.organisationFormUploadFirstSlideText,
    },
    label: {
      title: adminLabels.organisationFormUploadFirstSlideText,
      class: "font-weight-bold",
      for: "first_slide_text",
    },
  },
  {
    type: "file",
    name: "last_slide",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    fileclass: "img-fluid border",
    source: "",
    validators: [
      {
        required: false,
      },
    ],
    ui: {
      label: adminLabels.organisationFormUploadLastSlide,
      class: "font-weight-bold",
      inputclass: "w-50 text-center",
    },
    default_path: environment.CDN_BASE_URL + "/guideme-assests/default_last_slide.png"
  },
  {
    type: "text",
    name: "last_slide_text",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    validators: [
      {
        required: false,
      }
    ],
    ui: {
      label: adminLabels.organisationFormUploadLastSlideText,
      class: "col-lg-2",
      inputclass: "col-lg-6",
    },
    input: {
      id: "last_slide_text",
      class: "form-control",
      type: "text",
      name: "last_slide_text",
      placeholder: adminLabels.organisationFormUploadLastSlideText,
    },
    label: {
      title: adminLabels.organisationFormUploadLastSlideText,
      class: "font-weight-bold",
      for: "last_slide_text",
    },
  },
  {
    type: "text",
    name: "copyright_text",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    validators: [
      {
        required: false,
      }
    ],
    ui: {
      label: adminLabels.organisationFormCopyrightText,
      class: "font-weight-bold",
      inputclass: "col-lg-6",
    },
    input: {
      id: "copyright_text",
      class: "form-control",
      type: "password",
      name: "copyright_text",
      placeholder: adminLabels.organisationFormCopyrightText,
    },
    label: {
      title: adminLabels.organisationFormCopyrightText,
      class: "font-weight-bold",
      for: "copyright_text",
    },
  },
  // {
  //     type:'button',
  //     name:'',
  //     value:'',
  //     class:'btn',
  //     validators:[],
  //     ui:{
  //         label:adminLabels.cancelButtonText
  //     }
  // },
  // {
  //     type:'button',
  //     name:'',
  //     value:'',
  //     class:'btn btn-primary',
  //     validators:[],
  //     ui:{
  //         label:'Save'
  //     }
  // }
];
