import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DashboardService } from "../common/services/dashboard.service";
import { UserService } from "../common/services/user.service";
import adminLabels from "../constants/ApplicationStrings/trans_utils";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.css"],
})
export class BreadcrumbComponent implements OnInit {

  admin_data = adminLabels;

  constructor(
    public dbService: DashboardService,
    public userService: UserService,
    private route: ActivatedRoute
  ) { }

  @Input() breadcrumb;
  @Input() templateVar;
  @Input() findReplace; //for find & replace
  @Input() appList;
  @Input() emitCancel;
  @Input() buttonHidden;
  @Output() saveSettings = new EventEmitter<any>();
  @Output() discardSettings = new EventEmitter<any>();
  @Output() updateApplist = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  ngOnInit() {

  }

  hideCursor(route) {
    let current_route = "/";
    if (this.route.url['value'] != "") {
      current_route = "/" + this.route.url['value'].reduce((red, val) => { return red + "/" + val });
    }
    let bread_route = route != '' ? route.join("/") : '/';
    if (current_route == bread_route) {
      return true;
    } else {
      return false;
    }
  }

  /** Customization center changes start */
  emitSaveSettings = (event: Event) => {
    if (this.templateVar == "customization")
      this.saveSettings.emit(event);
  }

  emitDiscardSettings = (event: Event) => {
    if (this.templateVar == "customization")
      this.discardSettings.emit(event);
  }

  emitUpdateApplist = (event: Event) => {
    if (this.templateVar == "customization")
      this.updateApplist.emit(event)
  }
  /** Customization center changes end */

  /** Find & Replace changes start */
  // emitCancel = () => {
  //   if(this.templateVar == "findReplace")
  //     this.cancel.emit();
  // }

  emitRefresh = () => {
    if(this.templateVar == "findReplace")
      this.refresh.emit();
  }
  /** Find & Replace changes end */
}
