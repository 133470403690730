import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import {API_END_POINTS} from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

export interface api_connector{
  id: string,
  organization_id: string,
  api_name: string,
  setting:string,
  is_active:string,
  creation_date: string,
  modification_date: string,
  zendesk_sync_date:string,
  is_sync_complete: string
}

@Injectable({
  providedIn: 'root'
})
export class ApiConnectorService {

  constructor(private userService:UserService,private apiService:ApiService) { }

  appTitles=[adminLabels.apiConnectorTableHeaderAPIName,adminLabels.apiConnectorTableHeaderSyncCompleted, adminLabels.tableHeaderModificationDate,adminLabels.tableHeaderActions];
  appDataKeys=['','api_name','is_sync_complete','modification_date','api_connector_action'];
  col_widths=['35%','15%','20%','10%'];
  selectedConnector:api_connector;
  isEdit=false;

  add_button={
    type:'save_connector',
    class:'btn btn-primary minWidth-120 mx-2',
    disable:false,
    text:adminLabels.saveButtonText
  }

  cancel_button={
    type:'cancel',
    class:'btn btn-secondary minWidth-120 mx-2',
    disable:false,
    text:adminLabels.cancelButtonText
  }

  getApiConnectors(api_name=''){
    let urlPath=environment.baseUrl+API_END_POINTS.GET_API_CONNECTOR+"?organization_id="+this.userService.organization_id;
    if(api_name!=''){
      urlPath+="&api_name="+api_name;
    }
   return this.apiService.get(urlPath);
  }

  createConnector(request){
    let urlPath=environment.baseUrl+API_END_POINTS.CREATE_API_CONNECTOR;
    return this.apiService.post(urlPath,request);
  }

  getCategory(id){
    let urlPath=environment.baseUrl+API_END_POINTS.GET_CATEGORY+"?category_id="+id;
    return this.apiService.get(urlPath);
  }

  deleteConnector(id){
    let httpParams = new HttpParams().set('id', id);
    let urlPath=environment.baseUrl+API_END_POINTS.DELETE_CONNECTOR;
    return this.apiService.delete(urlPath,httpParams);
  }

}
