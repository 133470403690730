if (GmXt === undefined) var GmXt = {};
GmXt.playerLbls = {};
GmXt.playerLbls.fr_CA = {
    "myGuideTitle": "MyGuide",
    "addButtonText": "Ajouter",
    "addingButtonText": "Ajouter…",
    "cancelButtonText": "Annuler",
    "okButtonText": "OK",
    "updateButtonText": "Mettre à jour",
    "updatingButtonText": "Mise à jour…",
    "deleteButtonText": "Supprimer",
    "deletingButtonText": "Suppression…",
    "uploadButtonText": "Télécharger",
    "uploadingButtonText": "Télécharger",
    "saveButtonText": "Enregistrer",
    "savingButtonText": "Enregistrement…",
    "saveSettingsButtonText": "Enregistrer les paramètres",
    "noDataFoundText": "Aucune donnée trouvée!",
    "generateButtonText": "Générer",
    "generatingButtonText": "Génération…",
    "actionDeclined": "Action refusée!",
    "searchPlaceholder": "Rechercher",
    "searchNameIdPlaceholder": "Rechercher le prénom, le nom, l’identifiant de courriel",
    "selectDatesPlaceholder": "Sélectionner les dates",
    "enterIDPAttributeNamePlaceholder": "Saisir le nom de l’attribut IDP",
    "domainFormatPlaceholder": "name@domain.com",
    "error": "Erreur",
    "yes": "Oui",
    "no": "Non",
    "all": "Tout",
    "web": "Web",
    "desktop": "Ordinateur de bureau",
    "android": "Android",
    "ios": "IOS",
    "mobileWeb": "Mobile Web",
    "user": "Utilisateur",
    "currentAdminPassword": "Mot de passe actuel de l’Admin",
    "note": "Note :",
    "domainMessage": "L’IDP de connexion unique est attaché à une organisation par défaut. Ainsi, l’URL de connexion unique (SSO) par défaut sera déclenchée si l’utilisateur visite un domaine Web sans que la session ne soit trouvée. Dans cette section, vous pouvez attacher l’URL de la connexion unique à un domaine particulier. Ainsi, l’URL de la connexion unique ne se déclenchera que sur le domaine associé. Ainsi, une organisation peut disposer de plusieurs IDP de connexion unique sur plusieurs domaines.",
    "refresh": "Rafraîchir",
    "iconWorks": "l’icône fonctionne!",
    "pageNotWorks": "Page-non-trouvée fonctionne!",
    "valid": "Valide.",
    "selectAll": "Sélectionner tout",
    "guideTitle": "Titre du Guide",
    "copy": "Copier",
    "copied": "Copié!",
    "on": "ACTIVÉ",
    "off": "DÉSACTIVÉ",
    "publish": "Publier",
    "unpublished": "Non publié",
    "locked": "Verrouillé",
    "unlocked": "Déverrouillé",
    "superAdmin": "Super Admin",
    "download": "Télécharger",
    "noDataFound": "Aucune donnée n’a été trouvée.",
    "and": "et",
    "guideRoleAdministrator": "Organisation Admin",
    "guideRoleCreator": "Créateur",
    "guideRolePlayer": "Joueur",
    "guideRoleAppAdmin": "Admin de l’application",
    "tableHeaderStatus": "État",
    "tableHeaderProgress": "Avancement",
    "tableHeaderTitle": "Titre",
    "tableHeaderType": "Type",
    "tableHeaderCreationDate": "Date de création",
    "tableHeaderActions": "Actions",
    "tableHeaderMarkAll": "Cocher tout",
    "tableHeaderDescription": "Description",
    "pleaseSelect": "Veuillez sélectionner",
    "close": "Fermer",
    "clear": "Effacer",
    "apply": "Appliquer",
    "patternInvalid": "Le modèle n’est pas valide",
    "isRequired": "est obligatoire",
    "confirmNewPassword": "Confirmer le nouveau mot de passe",
    "newPassword": "Nouveau mot de passe",
    "currentPassword": "Mot de passe actuel",
    "addApplicationAddBreadcrumb": "Ajouter une application",
    "addApplicationEditBreadcrumb": "Éditer une application",
    "addApplicationUploadThumbnail": "Télécharger l’image miniature de l’application",
    "addApplicationGuideAutomation": "Automatisation du Guide",
    "addApplicationApplicationLevelTranslationLanguages": "Langues de traduction au niveau de l’application",
    "addApplicationEnableApplicationLanguageSettings": "Activer les paramètres linguistiques au niveau de l’application.",
    "addApplicationPackageId": "Code forfait",
    "addApplicationBundleId": "Code lot",
    "addApplicationMaxApplicationLimit": "La nouvelle application ne peut pas être ajoutée, car l’organisation a dépassé la limite maximale d’applications.",
    "addApplicationIdExists": "La nouvelle application ne peut pas être ajoutée, car le code forfait ou le code lot existe déjà.",
    "addFolderName": "Nom du dossier",
    "addOrganisationName": "Nom de l’organisation",
    "addOrganisationType": "Type d’organisation",
    "addUserAvtar": "Avatar",
    "addUserFirstName": "Prénom",
    "addUserLastName": "Nom",
    "addUserEmailId": "Code courriel",
    "addUserOrganisationRole": "Rôle d’accès à l’application",
    "addUserLockedStatus": "Statut verrouillé",
    "addUserRole": "Rôle de l’utilisateur",
    "addUserUUName": "UUName",
    "labelDoItForMe": "DoItForMe",
    "labelMyChat": "MyChat",
    "labelTeachMe": "TeachMe",
    "labelTutorialGuides": "Tutorial Guides",
    "labelGuideTranslation": "Traduction du Guide",
    "labelShowBeaconGuidesOnCurrentPage": "Afficher BeaconGuides sur la Page actuelle",
    "labelGuideSegmentation": "Segmentation du Guide",
    "labelShowCurrentPageGuidesOnly": "Afficher la Page actuelle des Guides uniquement",
    "labelTooltipEnhancements": "Améliorations de la Tooltip",
    "labelUserInformationTracking": "Suivi des informations sur les utilisateurs",
    "labelSSO": "Connexion unique",
    "labelMediaFileCreation": "Création de fichiers multimédia",
    "labelYoutubeSettings": "Paramètres YouTube",
    "labelEnableOrgAndGuidesJSONCaching": "Activer la mise en cache JSON de l’organisation et des Guides",
    "labelGuideAutomation": "Automatisation du Guide",
    "labelAPIConnector": "Connecteur d’API",
    "labelLoadContextChangeOnPageClick": "Charger le changement de contexte lors du clic sur la page",
    "labelTaskList": "Liste des tâches",
    "labelMediaFileTheme": "Thèmes des fichiers multimédia",
    "labelDisplayGuidePublishingDate": "Afficher la date de publication du Guide",
    "labelMaintenanceMode": "Mode de maintenance",
    "labelEnableGuidemeAsSecondaryOption": "Activer la fonction Guideme en tant qu’option secondaire",
    "labelHashPlayerUsersData": "Hacher les données de l’utilisateur du lecteur",
    "labelAllowOnPremiseDeployment": "Autoriser le déploiement sur place",
    "labelFTPEndpointToSaveContent": "Point d’arrivée FTP permettant de sauvegarder le contenu",
    "labelEnableInsights": "Activer Insights",
    "labelDisplayAcceptCookiePopup": "Afficher la fenêtre contextuelle « Accepter les témoins »",
    "labelSetRefreshTokensExpiryTime": "Définir le délai d’expiration de l’actualisation du jeton (en secondes)",
    "labelGuideInsights": "Guide Insights",
    "labelRealTimeInsights": "Insights en temps réel",
    "labelInsightsPriority": "Priorité des Insights",
    "labelMaxGuideLimit": "Limite maximale du Guide",
    "labelMaxApplicationLimit": "Limite maximale d’applications",
    "labelMaxStepLimit": "Limite maximale d’étapes",
    "labelMaxAdminUsers": "Nombre maximal d’utilisateurs Admin",
    "labelMaxCreatorUsers": "Nombre maximal d’utilisateur créateurs",
    "labelMaxPlayerUsers": "Nombre maximal d’utilisateurs lecteurs",
    "tooltipDoItForMe": "Permet de lire les guides en mode « Do it for me » (mode automatisé)",
    "tooltipMyChat": "Active l’assistant de clavardage virtuel",
    "tooltipTeachMe": "Permet la lecture du guide dans la simulation de l’application hôte",
    "tooltipTutorialGuides": "Active la fonction Tutorial du guide",
    "tooltipGuideTranslation": "Permet de traduire les guides dans une ou plusieurs langues prises en charge par MyGuide",
    "tooltipShowBeaconGuidesOnCurrentPage": "Permet aux guides ayant une balise configurée de s’afficher sur la page en cours. Par défaut, les balises sont affichées sur la page et les guides correspondants ne sont pas visibles sur la page en cours.",
    "tooltipGuideSegmentation": "Supervise les guides auxquels un utilisateur peut ou ne peut pas accéder en fonction des données d’attribut de l’utilisateur",
    "tooltipShowCurrentPageGuidesOnly": "Masque l’onglet Tous les Guides et la hiérarchie des dossiers qui comprend les guides non disponibles sur la page actuelle.",
    "tooltipTooltipEnhancements": "Améliorations de la Tooltip",
    "tooltipUserInformationTracking": "Permet un suivi plus précis des Insights grâce au suivi des données uniques de l’utilisateur",
    "tooltipSSO": "Permet l’intégration de la connexion unique avec l’IDP du client",
    "tooltipMediaFileCreation": "Permet l’exportation automatique du contenu de MyGuide vers différents formats, notamment .pdf, et .docx.",
    "tooltipYoutubeSettings": "Permet au connecteur YouTube de publier des guides sur YouTube.",
    "tooltipEnableOrgAndGuidesJSONCaching": "Permet au JSON de l’organisation d’inclure tous les guides et toutes les étapes. Recommandé uniquement pour les organisations ayant publié moins de 100 guides. Ce connecteur doit être désactivé si la taille totale des JSON de l’organisation est supérieure à 4 Mo.",
    "tooltipGuideAutomation": "Active la fonctionnalité de robot automatisé",
    "tooltipAPIConnector": "Permet aux connecteurs API tiers d’importer des données. Les connecteurs pris en charge sont notamment Zendesk.",
    "tooltipLoadContextChangeOnPageClick": "Permet à l’utilisateur de restreindre la recherche de règles au clic de la page",
    "tooltipTaskList": "Permet la création de listes d’objectifs basés sur des guides à affecter à l’utilisateur sous forme de tâches.",
    "tooltipMediaFileTheme": "Permet la sélection et la personnalisation des thèmes des fichiers multimédia",
    "tooltipDisplayGuidePublishingDate": "Permet l’affichage de la date de publication d’un guide sur les formats téléchargeables comme les fichiers vidéo, les fichiers pdf, et autres.",
    "tooltipMaintenanceMode": "Mode de maintenance",
    "tooltipEnableGuidemeAsSecondaryOption": "Activer le mode guideme comme mode secondaire pour les guides qui ont été créés avec showme par défaut.",
    "tooltipHashPlayerUsersData": "Hacher les données de l’utilisateur du lecteur",
    "tooltipAllowOnPremiseDeployment": "Permet de déployer et d’exploiter MyGuide en tant que configuration locale ou Intranet.",
    "tooltipFTPEndpointToSaveContent": "Veuillez saisir une adresse FTP valide commençant par « ftp » : //. par exemple : ftp : //1.2.3.4 : 21 ou ftp : //abc.exemple.com : 21",
    "tooltipEnableInsights": "Activé par défaut. Si cette option est désactivée, elle empêche l’organisation d’obtenir de nombreuses informations",
    "tooltipDisplayAcceptCookiePopup": "Active l’affichage de la fenêtre contextuelle d’acceptation des témoins.",
    "tooltipSetRefreshTokensExpiryTime": "Définir le délai d’expiration du jeton d’actualisation",
    "tooltipGuideInsights": "Permet de visualiser l’interaction de l’utilisateur avec les guides MyGuide, les info-bulles, les balises et autres. Cette fonction est activée par défaut.",
    "tooltipRealTimeInsights": "L’activation de cette fonction permet de refléter les données en temps réel pour certaines sections du portail d’informations",
    "tooltipAppInsights": "Permet à l’application de collecter des informations sur le suivi des clics, le suivi des pages, le suivi des flux de travail et d’autres informations. Cette fonction travaille en arrière-plan sans que les utilisateurs n’interagissent avec les guides, les balises, les infobulles et autres.",
    "tooltipInsightsPriority": "Définir la priorité Insights sur MyGuide",
    "languageTranslationsSettings": "Règles de traduction",
    "englishDefault": "1. La traduction n’est disponible que lorsque l’anglais (toute variante) est sélectionné comme langue par défaut.",
    "languageEdited": "2. Si vous avez modifié la langue par défaut, vous devez sélectionner à nouveau les langues précédemment ajoutées à partir de l’option « Choisir la langue ».",
    "defaultLanguage": "Langue par défaut",
    "defaultVoice": "Voix par défaut",
    "moreLanguage": "Afficher d’autres langues",
    "guideOrWorkflowAutomation": "Automatisation du Guide ou du Workflow",
    "cannotBeDisabled": "Une fois activée, cette option ne peut plus être désactivée.",
    "okToApply": "Pour appliquer ce paramètre, cliquez sur le bouton OK.",
    "addToConnector": "Ajouter un connecteur",
    "doYouWantToDelete": "Voulez-vous supprimer?",
    "segmentData": "Données :",
    "segmentKeyName": "Nom de la clé :",
    "ssoDescription": "Description de la connexion unique",
    "ssoIssuerURL": "Émetteur URL",
    "ssoCertificate": "Certificat x.509",
    "ssoAPIKey": "Clé API",
    "ssoAPISecret": "API Privée",
    "ssoNameIdEncrypted": "Voulez-vous que NameID soit chiffré?",
    "ssoMetaDataSigned": "Voulez-vous que les métadonnées soient signées?",
    "ssoResponseSigned": "Voulez-vous que la réponse soit signée?",
    "ssoAssertionSigned": "Voulez-vous que l’assertion soit signée?",
    "ssoSignatureAlgorithm": "Algorithme de signature",
    "ssoDigestAlgorithm": "Algorithme de résumé",
    "ssoMessageSigned": "Voulez-vous que le message soit signé?",
    "ssoWish": "Souhaitez-vous utiliser le même certificat pour le chiffrement du NameID?",
    "ssoEncryptCertificate": "Certificat de chiffrement x509",
    "ssoAllowAplicationSingleLayout": "Permettre à l’application de procéder à une déconnexion unique?",
    "ssoAuthRequestSigned": "Souhaitez-vous que la demande d’authentification soit signée?",
    "ssoConfirmationMessage": "Message de confirmation de la connexion unique",
    "ssoButtonMessage": "Message du bouton de connexion unique",
    "ssoSingleLogoutURL": "URL de déconnexion unique",
    "ssoListName": "Nom :*",
    "userProvisioningMethod": "Méthode de provisionnement des utilisateurs",
    "apiConnectorToken": "Jeton",
    "apiConnectorEmailAddress": "Adresse courriel",
    "apiConnectorAPIEndpoint": "Point de terminaison de l’API",
    "apiConnectorFolder": "Dossier",
    "organisationFormDualAuthentication": "Authentification à deux facteurs",
    "organisationFormUploadOrganisationThumbnail": "Télécharger l’image miniature de l’organisation",
    "organisationFormUploadFirstSlide": "Télécharger l’image de la première diapositive",
    "organisationFormUploadFirstSlideText": "Texte de la première diapositive",
    "organisationFormUploadLastSlide": "Télécharger l’image de la dernière diapositive",
    "organisationFormUploadLastSlideText": "Texte de la dernière diapositive",
    "organisationFormCopyrightText": "Texte relatif au droit d’auteur",
    "applicationCreatedText": "Application créée",
    "applicationUpdatedText": "Application mise à jour",
    "applicationDeletedText": "Application supprimée",
    "applicationAssignedText": "Applications affectées",
    "automationStatusUpdatedText": "Statut de l’automatisation mis à jour",
    "roleCreatedText": "Rôle créé",
    "roleUpdatedText": "Rôle mis à jour",
    "roleDeletedText": "Rôle supprimé",
    "roleAssignedText": "Rôle affecté",
    "roleAssignedToApplicationText": "Rôle affecté à l’application",
    "organisationRoleAssignedText": "Rôle d’organisation affecté",
    "credentialsCreatedText": "Informations d’identification créées",
    "credentialsDeletedText": "Informations d’identification supprimées",
    "segmentCreatedText": "Segment créé",
    "segmentUpdatedText": "Segment mis à jour",
    "segmentDeletedText": "Segment supprimé",
    "segmentValueCreatedText": "Valeur de segment créée",
    "segmentValueDeletedText": "Valeur de segment supprimée",
    "userCreatedText": "Utilisateur créé",
    "userUpdatedText": "Utilisateur mis à jour",
    "userDeletedText": "Utilisateur supprimé",
    "domainCreatedText": "Domaine créé",
    "domainDeletedText": "Domaine supprimé",
    "updatedDomainSsoMapping": "Mise à jour du mappage de la connexion unique du domaine",
    "apiConnectorAddedText": "Ajout d’un connecteur d’API",
    "apiConnectorDeletedText": "Connecteur d’API supprimé",
    "taskListCreatedText": "Liste des tâches créée",
    "taskListUpdatedText": "Liste des tâches mise à jour",
    "taskListDeletedText": "Tâche supprimée",
    "visibilitySettingsUpdatedText": "Les paramètres de visibilité ont été mis à jour",
    "adminSettingsUpdatedText": "Paramètres Admin mis à jour",
    "importedText": "Importé",
    "userUnlockedText": "Utilisateur déverrouillé",
    "userStatusChangedText": "Statut de l’utilisateur modifié",
    "sessionClearedforUserText": "Session effacée pour l’utilisateur",
    "passwordChangedText": "Mot de passe modifié",
    "organisationUpdatedText": "Organisation mise à jour",
    "organisationActiveStatusChangedText": "Statut Actif de l’organisation modifié",
    "organisationVerifiedStatusChangedText": "Statut Vérifié de l’organisation modifié",
    "organisationCreatedText": "Organisation ajoutée",
    "organisationDeletedText": "Organisation supprimée",
    "ssoCreatedText": "SSO ajoutée",
    "ssoDeletedText": "SSO supprimée",
    "changesReflectedText": "Les changements seront répercutés sur le portail des joueurs dans quelques minutes",
    "sessionClearedForOrganisationText": "Session autorisée avec succès pour l’organisation!",
    "ssoConfigurationUpdatedText": "Configuration SSO mise à jour",
    "instantUpdateText": "Mise à jour instantanée réussie",
    "sessionClearedText": "Session autorisée",
    "successfullyText": "Avec succès!",
    "successText": "Réussite!",
    "login": "Connexion",
    "loginInvalidCredentials": "Identifiants de connexion non valides. Réessayez",
    "loginAccessForbidden": "Accès formellement interdit",
    "loginPassword": "Mot de passe",
    "loginFillField": "Veuillez renseigner ce champ.",
    "loginFieldMandatory": "Ce champ est obligatoire.",
    "loginContactEdcast": "Si vous avez besoin d’aide, veuillez nous écrire à",
    "loginCopyright": "Copyright ©",
    "support": "support@csod.com",
    "forgotPassword": "Mot de passe oublié",
    "resetPassword": "Réinitialiser le mot de passe",
    "forgotPasswordBackToLogin": "Revenir à la connexion",
    "forgotPasswordResendLink": "Renvoyer le lien",
    "forgotPasswordInstructions": "- S’il s’agit d’un ID MyGuide valide, vous devriez recevoir un e-mail contenant des instructions sur la façon de créer un nouveau mot de passe.",
    "forgotPasswordLinkSent": "Le lien de réinitialisation du mot de passe a été envoyé",
    "forgotPasswordDidntRecieveMail": "Vous n’avez pas reçu l’e-mail?",
    "forgotPasswordCheckSpam": "Vérifiez dans les dossiers de courrier indésirable la présence d’un message provenant de",
    "generatePasswordSuccesfullyReset": "Le mot de passe a été réinitialisé avec succès",
    "generatePasswordLinkExpired": "Générer un message lien de mot de passe expiré ou mot de passe à usage unique invalide",
    "generatePasswordMustContain": "Le nouveau mot de passe doit contenir :",
    "generatePasswordCharacterLimit": "Entre 8 et 20 caractères",
    "generatePasswordOneNumber": "Au moins un chiffre",
    "generatePasswordOneUpperCase": "Au moins une lettre majuscule",
    "generatePasswordOneSpecialCharacter": "Au moins un caractère spécial (par ex. @,*,!)",
    "generatePasswordShouldMatch": "Les champs Nouveau mot de passe et Confirmer le nouveau mot de passe doivent correspondre",
    "organisationSelectText": "Sélectionner une organisation",
    "organisationAddText": "Ajouter une organisation",
    "organisationEditText": "Modifier l’organisation",
    "organisationActiveText": "Actif",
    "organisationVerifiedText": "Vérifié",
    "organisationPaid": "Payé",
    "organisationTrial": "Essai",
    "organisationHeading1": "Paramètres vidéo & GIF",
    "organisationTooltip1": "Les paramètres suivants sont utilisés pour personnaliser la structure des formats vidéo et GIF des guides générés pour l’organisation",
    "dashboardTitle": "Dashboard | MyGuide",
    "dashboardTriggerInstantUpdateButton": "Déclencher une mise à jour instantanée",
    "dashboardClearSessionButton": "Autoriser la session",
    "dashboardTriggerInstantUpdateButtonTooltip": "Les portails des joueurs sont mis à jour toutes les heures. Cliquez ici pour déclencher une mise à jour instantanée. Les changements seront répercutés dans 2 à 10 minutes.",
    "dashboardClearSessionButtonTooltip": "Autoriser la session pour l’organisation.",
    "dashboardUserAction": "Action de l’utilisateur",
    "dashboardUserKey": "Clé utilisateur",
    "dashboardRegisteredDate": "Date d’enregistrement",
    "apiAndServicesTitle": "API & services |",
    "apiAndServicesBreadCrumbTitle": "API & services",
    "apiAndServicesGenrateCrendentialsButton": "Générer des identifiants",
    "apiAndServicesTableHeaderKeyName": "Nom clé",
    "apiAndServicesTableHeaderKey": "Clé",
    "apiAndServicesTableHeaderSecret": "Secret",
    "apiAndServicesTableHeaderAnonymousAccess": "Accès anonyme",
    "apiAndServicesTableHeaderJITProvisioning": "Provisionnement juste à temps",
    "applicationsTitle": "Applications |",
    "applicationsAssignTitle": "Attribuer des applications",
    "applicationsBreadCrumbTitle": "Applications",
    "applicationsTableHeaderThumbnail": "Miniature du",
    "applicationsTableHeaderApplicationName": "Nom de l’application",
    "applicationsTableHeaderApplicationId": "ID d’application",
    "applicationsTableHeaderMobileApplicationId": "ID de l’application mobile",
    "applicationsTableHeaderAutomationStatus": "État de l’automatisation",
    "applicationsUpdateAutomationStatus": "Mettre à jour l’état de l’automatisation",
    "changeAutomationStatus": "Voulez-vous modifier l’état d’automatisation de cette application?",
    "rolesAddRoleButtonText": "Ajouter un rôle",
    "rolesAddingRoleButtonText": "Ajout d’un rôle en cours...",
    "rolesUpdateRoleButtonText": "Mettre à jour le rôle",
    "rolesUpdatingRoleButtonText": "Mise à jour du rôle en cours...",
    "usersBreadCrumbTitle": "Utilisateurs",
    "segmentsTableHeaderKeyName": "Nom clé",
    "segmentsTableHeaderIsRequired": "Est obligatoire?",
    "segmentsListTableHeaderValue": "Valeur",
    "segmentsListTableHeaderIsDefault": "Valeur par défaut?",
    "segmentsAddSegmentButtonText": "Ajouter un segment",
    "segmentsUpdateSegmentButtonText": "Mettre à jour le segment",
    "segmentsAddValueButtonText": "Ajouter une valeur",
    "segmentsAddingValueButtonText": "Ajout d’une valeur en cours...",
    "segmentsAddingSegmentButtonText": "Ajout d’un segment en cours...",
    "segmentsUpdatingSegmentButtonText": "Mise à jour du segment en cours...",
    "addSegmentsValue": "Ajouter des valeurs de segment",
    "domainsAddDomainDomainName": "Nom du domaine",
    "domainsAddDomainSSOIDP": "IDP SSO",
    "domainsAddDomainButtonText": "Ajouter un domaine",
    "domainsAddingDomainButtonText": "Ajout du domaine en cours...",
    "apiConnectorTableHeaderAPIName": "Nom d’API",
    "apiConnectorTableHeaderSyncCompleted": "Synchronisation terminée",
    "ssoListTableHeaderSSOName": "Nom de SSO",
    "ssoListDownloadMetadata": "Télécharger les métadonnées",
    "ssoListIdentityProviders": "Fournisseurs d’identité",
    "ssoListDomainMappings": "Mappages de domaine",
    "addSSOIDP": "Ajouter l’IDP de SSO",
    "addSSO": "Ajouter une SSO",
    "updateSSO": "Mettre à jour la SSO",
    "featureSettings": "Paramètres de fonctionnalité",
    "insightsSettings": "Paramètres de Insights",
    "limitSettings": "Paramètres de limite",
    "languageTranslations": "Traduction linguistique",
    "chooseImage": "Choisir l’image",
    "adminSettingsWarning": "Avertissement! L’activation du mode Maintenance désactivera cette organisation",
    "adminSettingsHashPlayersData": "Hacher les données de l’utilisateur du lecteur",
    "adminSettingsEnableSure": "Voulez-vous vraiment activer",
    "adminSettingsYouCanEither": "Vous pouvez activer soit",
    "adminSettingsGuideAutomation": "Automatisation du Guide",
    "adminSettingsWorkflowAutomation": "Automatisation du Workflow",
    "adminSettingsUnselectProceed": "Désélectionnez l’un d’eux pour continuer",
    "activityTableHeaderActivity": "Activité",
    "activityTableHeaderActivityEmailId": "ID d’e-mail d’activité",
    "activityTableHeaderActivityIPAddress": "Adresse IP",
    "activityAdmin": "Activité d’Admin",
    "activityLogin": "Activité de connexion",
    "activityType": "Type d’activité",
    "activityAction": "Action",
    "activityDetails": "Détails d’activité",
    "activityTableHeaderActivityIPAddresss": "Adresse IP",
    "userProvisioningTitle": "Provisionnement de l’utilisateur |",
    "userProvisioningBreadCrumbTitle": "Provisionnement de l’utilisateur",
    "userProvisioningProvideKey": "Indiquer le nom de la clé",
    "exportDataExportGuideButtonText": "Exporter les données du Guide",
    "exportDataExportUserButtonText": "Exporter les données utilisateur",
    "exportDataTableHeaderExportType": "Type d’exportation",
    "exportDataTableHeaderExportedBy": "Exporté par",
    "guideList": "Liste de Guide",
    "userList": "Liste d’utilisateur",
    "selectApplications": "Sélectionner des applications",
    "selectApplicationsMessage": "Veuillez sélectionner l’application pour exporter les données du guide",
    "exportDetails": "Exporter les détails",
    "taskListAddTaskButtonText": "Ajouter une tâche",
    "taskListAddingTaskButtonText": "Ajout d’une tâche en cours...",
    "taskListUpdateTaskButtonText": "Mettre à jour la tâche",
    "taskListUpdatingTaskButtonText": "Mise à jour de la tâche en cours...",
    "taskListTableHeaderApplication": "Application",
    "taskListTableHeaderLeaderboard": "Tableau de classement",
    "addGroup": "Ajouter un groupe",
    "removeGroup": "Supprimer un groupe",
    "updateTaskList": "Mettre à jour la liste de tâches",
    "addTaskList": "Ajouter une liste de tâches",
    "formMessage1": "1. Le nom de domaine doit contenir au moins deux parties séparées par un point ’.’",
    "formMessage2": "2. La partie nom de domaine ne doit pas commencer ou se terminer par un trait d’union ’-’.",
    "formMessage3": "3. Les noms de domaine peuvent contenir les lettres a à z, les chiffres 0 à 9 et le trait d’union.",
    "formMessage4": "4. Les parties suivantes du nom de domaine doivent comporter au moins deux caractères.",
    "recommendedSize": "(Taille recommandée 500 x 500 px) Fichier pris en charge : JPG, PNG",
    "recommendedSize2": "Taille recommandée (500 px x 500 px).",
    "topLeft": "En haut à gauche",
    "topRight": "En haut à droite",
    "bottomLeft": "En bas à gauche",
    "bottomRight": "En bas à droite",
    "importUserVariable1": "Téléverser le fichier CSV pour importer des utilisateurs",
    "importUserVariable2": "Glisser-déposer le fichier CSV ici",
    "importUserVariable4": "Parcourir",
    "importUserVariable5": "Type de fichier non valide",
    "importUserVariable6": "Envoyer un e-mail en masse :",
    "importUserVariable7": "Oui",
    "importUserVariable8": "Non",
    "importUserVariable9": "Télécharger",
    "importUserVariable10": "Historique du fichier d’importation des utilisateurs",
    "importUserVariable11": "Actualiser",
    "importUserVariable12": "Télécharger un exemple de fichier CSV",
    "importUserVariable13": "OU",
    "generateCredentialsVariable1": "Informations sur la création de données d’identification",
    "generateCredentialsVariable2": "Anonyme",
    "generateCredentialsVariable3": "- Si les utilisateurs ne veulent pas utiliser MyGuide pour enregistrer leurs informations, sélectionnez Anonyme.",
    "generateCredentialsVariable4": "Juste à temps",
    "generateCredentialsVariable5": "Si vous sélectionnez Juste à temps, nous vérifions si cet e-mail est déjà enregistré avec MyGuide. Dans le cas contraire, nous ajoutons la personne en tant qu’utilisateur MyGuide.",
    "generateCredentialsVariable6": "Nom clé",
    "generateCredentialsVariable7": "- Dans ce champ, entrez le but de la création de cette clé. Par exemple, SSO, Test, Salesforce, .js, etc.",
    "generateCredentialsVariable8": "Choisir l’image",
    "generateCredentialsVariable15": "Sélectionner des applications",
    "generateCredentialsVariable16": "Une application est requise",
    "headerVariable1": "ORGANISATION :",
    "headerVariable2": "RÔLE :",
    "headerVariable3": "Changer le mot de passe",
    "headerVariable4": "Déconnexion",
    "headerVariable5": "Insights",
    "manageContentVariable1": "Liste de tâches :",
    "manageContentVariable5": "Application :",
    "manageContentVariable6": "Prévisualisation",
    "manageContentVariable10": "Pas de Guides",
    "manageContentVariable12": "Gérer Guides",
    "manageContentVariable13": "Ajouter un dossier",
    "manageContentVariable17": "Sélectionner tout",
    "manageContentVariable18": "Guides sélectionné(s)",
    "manageContentVariable21": "Aucun Guides publié n’est disponible sous cette application",
    "manageContentVariable27": "Précédent",
    "manageContentVariable29": "Suivant",
    "manageContentVariable30": "Ce dossier ne peut pas être supprimé, car il contient des guides. Retirez les Guides et réessayez!",
    "manageRoleVariable5": "Ajouter un rôle",
    "manageRoleVariable1": "Attribuer un rôle",
    "otpVariable1": "Revenir à la connexion",
    "otpVariable2": "Vérifier le compte",
    "otpVariable3": "Déverrouiller l’utilisateur",
    "otpVariable4": "Le mot de passe à usage unique a été envoyé à",
    "otpVariable6": "Renvoi d’un mot de passe à usage unique",
    "otpVariable7": "Revenir à la connexion",
    "otpVariable8": "Compte utilisateur déverrouillé avec succès.",
    "otpVariable9": ". Veuillez entrer le mot de passe à usage unique pour vérifier votre compte",
    "otpVariable10": "Veuillez entrer le mot de passe à usage unique pour déverrouiller votre compte",
    "otpVariable11": "Mot de passe à usage unique",
    "otpVariable12": "Mot de passe à usage unique non valide",
    "otpVariable13": "Veuillez essayer avec le bon mot de passe à usage unique",
    "otpVariable14": "N’a pas reçu de mot de passe à usage unique",
    "otpVariable15": "Renvoi d’un mot de passe à usage unique",
    "attributeMapping": "Mappage d’attributs",
    "stepTitle": "Titre de l’étape",
    "stepDescription": "Description de l’étape",
    "stepTooltips": "Info-bulles des étapes",
    "pageThemeNumber": "Page ##",
    "firstPage": "Première Page",
    "middlePage": "Deuxième Page",
    "lastPage": "Dernière Page",
    "themeListVariable1": "Paramètre de thème au format PPT, Doc & PDF",
    "themeListVariable2": "Info :",
    "themeListVariable3": "Le thème actif sera utilisé pour créer des fichiers au format PPT (.pptx), PDF (.pdf) et document (.docx)",
    "themeListVariable4": "Actif",
    "themeListVariable5": "Activer",
    "themeListVariable6": "Activation du thème",
    "themeListVariable7": "Voulez-vous changer le thème par défaut?",
    "themeListVariable8": "Non",
    "themeListVariable9": "Oui",
    "usersVariable2": "État",
    "usersVariable3": "Tout",
    "usersVariable4": "Actif",
    "usersVariable5": "Suspendu",
    "usersVariable6": "Tout",
    "usersVariable7": "Organisation Admin",
    "usersVariable8": "Créateur",
    "usersVariable9": "Joueur",
    "usersVariable10": "Admin de l’application",
    "usersVariable11": "Admin MG",
    "usersVariable12": "Rôle d’accès à l’application",
    "usersVariable13": "Tout",
    "usersVariable14": "Exporter les données",
    "usersVariable19": "Mettre à jour l’état verrouillé",
    "usersVariable20": "Mettre à jour l’état",
    "usersVariable22": "Voulez-vous déverrouiller cet utilisateur?",
    "usersVariable23": "Voulez-vous mettre à jour l’état de cet utilisateur?",
    "usersVariable25": "Déverrouiller",
    "usersVariable26": "Oui",
    "usersVariable27": "Ajouter un utilisateur",
    "usersVariable28": "Importer un utilisateur",
    "usersVariable29": "Mettre à jour l’état verrouillé",
    "usersVariable30": "Mettre à jour l’état",
    "usersVariable31": "Rôle de l’utilisateur",
    "usersProvisioningVariable30": "1. La création d’un nouveau jeton de provisionnement supprimera/remplacera le jeton de provisionnement existant. Cela empêchera l’accès aux API de provisionnement de l’utilisateur à l’aide d’un ancien jeton.",
    "usersProvisioningVariable31": "2. Les informations d’identification générées dépendent de la méthode de provisionnement sélectionnée ci-dessous.",
    "usersProvisioningVariable1": "Veuillez copier les informations d’identification ci-dessus avant de fermer cette fenêtre contextuelle. Elles ne peuvent plus être visualisées après la fermeture de cette fenêtre contextuelle.",
    "usersProvisioningVariable2": "Jeton d’API",
    "usersProvisioningVariable3": "Voulez-vous vraiment continuer?",
    "tableUnmapped": "Non mappé",
    "tableTitle1": "Attribuer des valeurs de segment",
    "tableTitle2": "Éditer",
    "tableTitle3": "Gérer le contenu",
    "tableTitle4": "Cliquer pour éditer",
    "tableTitle5": "Se déconnecter de toutes les sessions",
    "visibility": "Visibilité",
    "taskVariable1": "Le chemin commencera toujours par une barre oblique, seuls les caractères alphanumériques et les caractères spéciaux (~,=,<,>,/,* uniquement) sont autorisés, les espaces ne sont pas autorisés dans la chaîne de chemin.",
    "taskVariable2": "Ce champ est obligatoire.",
    "taskVariable3": "La double barre oblique n’est pas autorisée.",
    "taskVariable4": "Il est impossible d’avoir <> et ~= ensemble après une barre oblique.",
    "taskVariable5": "Erreur: _ et - ne sont autorisés qu’entre crochets (<>).",
    "taskVariable6": "Il est impossible d’avoir ~ ou = seul dans le chemin, ~ sera toujours suivi de =.",
    "taskVariable7": "Paramètre invalide : il commencera toujours par un? et ne peut pas avoir de? suivi de = dans le paramètre",
    "taskVariable8": "Paramètre invalide : & suivi de caractères alphanumériques et de caractères spéciaux (-, _) est autorisé.",
    "taskVariable9": "Paramètre invalide : seuls les caractères alphanumériques et les caractères spéciaux (-, _, =, &) sont autorisés.",
    "taskVariable10": "Hash non valide : il commencera toujours par un #.",
    "applications": "Applications",
    "users": "Utilisateurs",
    "manageRoles": "Gérer les rôles",
    "themes": "Thèmes",
    "activity": "Activité",
    "exportData": "Exporter les données",
    "taskList": "Liste de tâches",
    "segments": "Segments",
    "domains": "Domaines",
    "adminSettings": "Paramètres d’Admin",
    "themeSettings": "Paramètres de thème",
    "guidePublishingDate": "Afficher la date de publication du Guide",
    "firstSlide": "Première diapositive",
    "lastSlide": "Dernière diapositive",
    "setDisplay": "Définir la position d’affichage",
    "setFirstSlide": "Définir la position d’affichage de la première diapositive",
    "setLastSlide": "Définir la position d’affichage de la dernière diapositive",
    "markAll": "Marquer tout",
    "language": "Langue",
    "voice": "Voix",
    "uploadProfileImage": "Téléverser l’image du profil",
    "downloadSampleCSV": "Télécharger un exemple de fichier CSV",
    "done": "Fait",
    "started": "Démarré",
    "pending": "En attente",
    "failed": "Échoué",
    "changeUserPassword": "Modifier le mot de passe de l’utilisateur",
    "segmentValues": "Valeurs de segment",
    "tokenGenerated": "Jeton généré",
    "availableSoon": "Votre fichier sera bientôt disponible",
    "exportInProgress": "Exportation en cours!",
    "themeActivated": "Thème activé",
    "themeUpdated": "Thème activé",
    "themeGenerated": "Thème généré",
    "themeActivation": "Activation du thème",
    "invalidExtension": "Extension non valide ou le format est supérieur à 2 Mo.",
    "unableUpdateLogo": "Impossible de mettre à jour le logo",
    "create": "Créer",
    "delete": "Supprimer",
    "update": "Mettre à jour",
    "field": "Champ",
    "originalValue": "Valeur initiale",
    "newValue": "Nouvelle valeur",
    "message1": "Avertissement! Une fois que les données de l’utilisateur de Hash player sont activées, elles ne peuvent pas être désactivées",
    "message2": "Pour activer Insights, au moins une des options suivantes doit être activée",
    "high": "Élevé",
    "medium": "Intermédiaire",
    "low": "Bas",
    "view": "Afficher",
    "details1": "Détails",
    "assign": "Affecter",
    "label1": "Mettre à jour le mot de passe",
    "label2": "Mettre à jour le profil",
    "label3": "Générer des clés",
    "label4": "Mettre à jour les clés",
    "label5": "Supprimer les clés",
    "label6": "Attribuer à l’application",
    "label7": "Rôle de l’utilisateur",
    "label8": "Provisionnement",
    "label9": "Clés d’API",
    "label10": "Organisation",
    "label11": "Application",
    "label12": "Ordre des catégories",
    "label13": "Catégorie",
    "label14": "Ordre de présentation",
    "label15": "Mettre à jour les paramètres d’Admin",
    "label16": "Créer des paramètres SSO",
    "label17": "Mettre à jour les paramètres SSO",
    "label18": "Supprimer les paramètres SSO",
    "label22": "Mettre à jour l’ordre des catégories",
    "label23": "Créer une sous-catégorie",
    "label24": "Mettre à jour la sous-catégorie",
    "label25": "Supprimer une sous-catégorie",
    "label26": "Mettre à jour l’ordre de présentation",
    "label27": "Créer une liste de tâches",
    "label28": "Supprimer la liste de tâches",
    "label29": "Publier la liste de tâches",
    "label30": "Mettre à jour la liste de tâches",
    "label31": "Mettre à jour le paramètre de liste de tâches",
    "label32": "Créer le dossier de liste de tâches",
    "label33": "Mettre à jour le dossier de liste de tâches",
    "label34": "Supprimer le dossier de liste de tâches",
    "label35": "Mettre à jour le contenu de la liste de tâches",
    "label36": "Créer la liste de tâches Achèvement",
    "exportmessage1": "Exporter le fichier avec les métadonnées du guide - nom, application, statut de publication, etc.",
    "exportmessage2": "Exporter le fichier avec les détails de l’utilisateur comme : nom et prénom, e-mail, rôle, etc.",
    "previewMessage": "L’aperçu vidéo n’existe pas",
    "preview": "Prévisualisation",
    "detailButtonText": "Détails",
    "taskListNote1": "Étapes de publication d’une liste de tâches :",
    "taskListNote2": "1. Créer une liste de tâches",
    "taskListNote3": "2. Ajouter du contenu",
    "taskListNote4": "3. Ajouter des paramètres de visibilité",
    "taskListNote5": "4. Modifier le statut de publication",
    "serverMessage1": "En-tête non valide/vide [AccessToken]",
    "serverMessage2": "[AccessToken] a expiré",
    "serverMessage3": "Nous sommes vraiment désolés, il semble qu’il y ait un problème avec votre demande ou avec nos serveurs. Nous avons été informés de l’erreur et nous la corrigerons dès que possible.",
    "serverMessage4": "L’e-mail de l’utilisateur n’est pas vérifié",
    "serverMessage5": "Maintenance en cours. Communiquez avec nous à l’adresse support@csod.com.",
    "serverMessage6": "Paramètre invalide/vide [token]",
    "serverMessage7": "Vous avez atteint la limite maximale pour créer des étapes",
    "serverMessage8": "Paramètre non valide/vide [jeton de déverrouillage de compte]",
    "serverMessage9": "Le support pour cette API d’Admin est indisponible. Nous l’avons rendu obsolète. Nous le supprimerons dans les prochaines versions.",
    "serverMessage10": "L’en-tête [Autorisation] a expiré",
    "serverMessage11": "Il manque un ou plusieurs paramètre(s) obligatoire(s).",
    "serverMessage12": "Identifiants de connexion non valides. Votre compte sera verrouillé après plusieurs tentatives infructueuses.",
    "serverMessage13": "Mot de passe à usage unique invalide ou expiré",
    "serverMessage14": "Le fichier téléversé n’est pas une image valide. (Seuls les fichiers JPG et PNG sont autorisés)",
    "serverMessage15": "Session expirée",
    "serverMessage16": "ID utilisateur non valide. Réessayez",
    "serverMessage17": "ID e-mail incorrect. Réessayez",
    "serverMessage18": "Rôle utilisateur invalide. Réessayez",
    "serverMessage19": "Paramètre non valide [task_type]",
    "serverMessage20": "Paramètre non valide/vide [application_id]",
    "serverMessage21": "JSON non valide",
    "serverMessage22": "Paramètre non valide/vide [organization_id]",
    "serverMessage23": "Paramètre non valide/vide [parent_id]",
    "serverMessage24": "Paramètre non valide/vide [category_id]",
    "serverMessage25": "Paramètres non valides/vides [from_category_id/to_category_id]",
    "serverMessage26": "Paramètre requis pour super Admin [organization_id]",
    "serverMessage27": "Le contenu des données d’image doit être codé en base64",
    "serverMessage28": "ID de domaine non valide",
    "serverMessage29": "Type de fichier non pris en charge",
    "serverMessage30": "Paramètre non valide [file_id]",
    "serverMessage31": "Paramètre non valide/vide [tour_id]",
    "serverMessage32": "Paramètre non valide/vide [step_id]",
    "serverMessage33": "En-tête non valide/vide [GoogleToken]",
    "serverMessage34": "Équipe non valide URL",
    "serverMessage35": "En-tête non valide/vide [EdcastToken]",
    "serverMessage36": "La taille du fichier ne doit pas dépasser 5 Mo.",
    "serverMessage37": "URL non valide",
    "serverMessage38": "Votre mot de passe doit comporter entre 8 et 15 caractères, dont au moins une lettre majuscule, une lettre minuscule, une valeur numérique et un caractère spécial.",
    "serverMessage39": "Mot de passe non valide",
    "serverMessage40": "Paramètre non valide/vide [country_id]",
    "serverMessage41": "Paramètre non valide/vide [city_id]",
    "serverMessage42": "Paramètre non valide [langue]",
    "serverMessage43": "L’ID d’authentification est non valide",
    "serverMessage44": "Paramètre non valide [file_id]",
    "serverMessage45": "ID d’URL non valide",
    "serverMessage46": "En-tête invalide/vide/expiré [Authorization]",
    "serverMessage47": "En-tête non valide/vide [AppKey]",
    "serverMessage48": "En-tête non valide/vide [RefreshToken]",
    "serverMessage49": "ID de rôle non valide",
    "serverMessage50": "ID de segment non valide",
    "serverMessage51": "Nom de domaine non valide",
    "serverMessage52": "Données de segment en double",
    "serverMessage53": "ID d’abonnement non valide",
    "serverMessage54": "ID de rôle d’organisation non valide",
    "serverMessage55": "ID de liste de tâches non valide",
    "serverMessage56": "La liste de tâches n’a pas été publiée",
    "serverMessage57": "La liste de tâches a été publiée",
    "serverMessage58": "2044",
    "serverMessage59": "ID de dossier non valide",
    "serverMessage60": "2045",
    "serverMessage61": "ID de contenu non valide",
    "serverMessage62": "Paramètre non valide [external_user_id]",
    "serverMessage63": "Données de langue non valides pour l’étape. Veuillez vérifier le titre et la description de l’étape pour tout caractère invalide ou indésirable",
    "serverMessage64": "ID d’emploi non valide",
    "serverMessage65": "Action non autorisée sur un compte utilisateur verrouillé",
    "serverMessage66": "Paramètre non valide/vide [segments]",
    "serverMessage67": "Paramètre non valide/vide [group_id]",
    "serverMessage68": "Paramètre non valide/vide [sso_id]",
    "serverMessage69": "URL non valide fournie",
    "serverMessage70": "Paramètre non valide/vide [group_id]",
    "serverMessage71": "Les champs step_audio ou step_audio_text sont obligatoires",
    "serverMessage72": "Échec de l’intégrité des données",
    "serverMessage73": "Un ancien et un nouveau mot de passe ne peuvent pas être identiques.",
    "serverMessage74": "Des champs d’action sont obligatoires",
    "serverMessage75": "Paramètre non valide/vide [id]",
    "serverMessage76": "La sous-catégorie ne peut pas être créée dans la catégorie si un Guide est déjà créé.",
    "serverMessage77": "Si un utilisateur est déjà un super Admin, vous ne pouvez pas modifier le rôle d’utilisateur.",
    "serverMessage78": "Cette action n’est pas autorisée sur un guide publié",
    "serverMessage79": "Le guide verrouillé ne peut pas être publié.",
    "serverMessage80": "Cette action n’est pas autorisée sur une catégorie publiée. Veuillez annuler sa publication.",
    "serverMessage81": "L’application doit appartenir à l’organisation donnée",
    "serverMessage82": "Vous n’êtes pas autorisé(e) à effectuer cette action",
    "serverMessage83": "L’action n’est pas autorisée pour cette sous-catégorie.",
    "serverMessage84": "Le Guide ne peut pas être créé dans la catégorie si une sous-catégorie a déjà été créée",
    "serverMessage85": "Pour créer une ou plusieurs étape(s), vous devez déverrouiller ce guide",
    "serverMessage86": "Pour éditer une ou plusieurs étape(s), vous devez déverrouiller ce guide",
    "serverMessage87": "Pour supprimer une ou plusieurs étape(s), vous devez déverrouiller ce guide",
    "serverMessage88": "Pour copier et coller une ou plusieurs étape(s), vous devez déverrouiller ce guide",
    "serverMessage89": "L’action n’est pas autorisée pour cette liste de tâches.",
    "serverMessage90": "L’action n’est pas autorisée sur une liste de tâches publiée.",
    "serverMessage91": "L’action n’est pas autorisée pour ce dossier.",
    "serverMessage92": "Ceci est déjà un brouillon de guide, nous ne pouvons pas en créer un nouveau",
    "serverMessage93": "Le brouillon de guide ne peut pas être créé si le guide n’est pas publié sur l’environnement le plus élevé.",
    "serverMessage94": "Ce guide a déjà un brouillon, nous ne pouvons pas en créer un nouveau.",
    "serverMessage95": "Valeurs invalides du paramètre [envs]",
    "serverMessage96": "Vous avez atteint la limite autorisée pour les identifiants du Guide",
    "serverMessage97": "Valeurs invalides du paramètre [Keywords]",
    "serverMessage98": "Action non autorisée pour l’étape relative aux balises.",
    "serverMessage99": "Les Guides ne peuvent pas être collés dans la même catégorie",
    "serverMessage100": "Les étapes ne peuvent pas être collées dans la même présentation",
    "serverMessage101": "Le Guide sera bientôt disponible à l’exportation. Pour vérifier l’état, accédez à [État de la tâche] dans le menu du compte.",
    "serverMessage102": "Le téléversement a commencé. Veuillez ne pas actualiser ou fermer le navigateur tant que le téléversement n’est pas terminé. Vous pouvez vérifier l’état de l’importation une fois le téléversement terminé",
    "serverMessage103": "L’utilisateur existe déjà",
    "serverMessage104": "L’identifiant de messagerie est déjà vérifié",
    "serverMessage105": "Cette URL est en conflit avec une autre configuration d’application, veuillez essayer d’ajouter une autre URL. Pour obtenir de l’aide, veuillez utiliser l’adresse support@csod.com",
    "serverMessage106": "La catégorie doit appartenir à la même application",
    "serverMessage107": "Le domaine existe déjà",
    "serverMessage108": "Vous avez atteint la limite maximale pour créer des guides",
    "serverMessage109": "L’ID d’étape doit appartenir au même guide",
    "serverMessage110": "Cette organisation est suspendue. Veuillez contacter votre administrateur.",
    "serverMessage111": "Guide Le ou les ID doi(ven)t appartenir à la même catégorie",
    "serverMessage112": "Le Guide existe déjà avec la paire donnée de nom d’objet et de champ",
    "serverMessage113": "Utilisateur suspendu",
    "serverMessage114": "Utilisateur déjà vérifié",
    "serverMessage115": "Cette fonction est désactivée sur HPE",
    "serverMessage116": "Le mot de passe à usage unique a été envoyé avec succès à votre adresse e-mail",
    "serverMessage117": "Le rôle existe déjà",
    "serverMessage118": "Le pays existe déjà",
    "serverMessage119": "La ville existe déjà",
    "serverMessage120": "Le ou les guide(s) donné(s) a/ont déjà été ajouté(s) dans la file d’attente en vue d’une exportation",
    "serverMessage121": "Catégorie non publiée",
    "serverMessage122": "Catégorie publiée",
    "serverMessage123": "Votre organisation n’est pas vérifiée. Pour obtenir de l’aide, veuillez utiliser l’adresse support@csod.com",
    "serverMessage124": "Le fichier JSON n’est pas disponible pour cette organisation.",
    "serverMessage125": "Les informations d’identification API sont erronées ou n’existent pas.",
    "serverMessage126": "Le fichier JSON n’est pas disponible pour ce guide.",
    "serverMessage127": "Aucune étape disponible. Créez au moins une étape pour publier le guide.",
    "serverMessage128": "Veuillez entrer une adresse e-mail de travail valide.",
    "serverMessage129": "Application par défaut.",
    "serverMessage130": "Rôle par défaut.",
    "serverMessage131": "La clé d’API est utilisée pour la configuration SSO.",
    "serverMessage132": "Impossible de traiter cette demande. Veuillez nous contacter à l’adresse contact support@csod.com pour activer cette demande.",
    "serverMessage133": "Vous avez atteint la limite maximale pour créer des applications",
    "serverMessage134": "Cette fonction est désactivée",
    "serverMessage135": "L’horodatage fourni n’est pas valide. Veuillez indiquer un horodatage valide.",
    "serverMessage136": "Vous avez atteint la limite maximale pour créer des utilisateurs.",
    "serverMessage137": "Abonnement invalide/expiré.",
    "serverMessage138": "L’ID SCIM fourni n’est pas valide.",
    "serverMessage139": "Le filtre fourni n’est pas pris en charge.",
    "serverMessage140": "La ressource spécifiée n’est pas disponible.",
    "serverMessage141": "Schéma SCIM non valide.",
    "serverMessage142": "Paramètre non valide.",
    "serverMessage143": "Le(s) segment(s) requis sont manquant(s).",
    "serverMessage144": "Cette tâche est déjà terminée.",
    "serverMessage145": "Ce compte utilisateur est déjà déverrouillé.",
    "serverMessage146": "Votre compte a été temporairement verrouillé en raison d’échecs de connexion excessifs. Nous vous avons envoyé un e-mail avec les étapes pour le déverrouiller. Pour plus d’assistance, veuillez nous contacter à l’adresse support@csod.com.",
    "serverMessage147": "Données non valides fournies JSON",
    "serverMessage148": "SSO n’est pas activé pour cette organisation. Pour plus d’informations, veuillez nous contacter à l’adresse support@csod.com.",
    "serverMessage149": "L’audio n’est pas créé",
    "serverMessage150": "Le fichier Excel de la liste des guides sera bientôt disponible.",
    "serverMessage151": "Les présentations n’existent pas dans l’organisation donnée",
    "serverMessage152": "Votre action précédente est en cours, une fois qu’elle est terminée, vous pouvez effectuer l’action suivante.",
    "serverMessage153": "Les présentations existent déjà dans la catégorie donnée",
    "serverMessage154": "Le ou les fichier(s) n’a/n’ont pas pu être importé(s) dans l’application/la catégorie spécifiée.",
    "serverMessage155": "Les thèmes de fichiers multimédias ne sont pas activés pour cette organisation. Pour plus d’informations, veuillez nous contacter à l’adresse support@csod.com.",
    "serverMessage156": "Destination invalide pour l’importation de Guide",
    "serverMessage157": "Les utilisateurs n’existent pas dans l’organisation donnée",
    "serverMessage158": "Le fichier Excel de la liste des utilisateurs sera bientôt disponible.",
    "serverMessage159": "Le cache Redis est désactivé",
    "serverMessage160": "Vous pouvez épingler jusqu’à 5 guides",
    "serverMessage161": "Le Guide est déjà épinglé",
    "serverMessage162": "Paramètre non valide/vide [mobile_app_id]",
    "serverMessage163": "Le fichier JSON n’est pas disponible pour cette application.",
    "serverMessage164": "L’ID de bundle/package existe déjà.",
    "serverMessage165": "Un segment de même nom existe déjà.",
    "serverMessageDefault": "Désolé pour le dérangement. Pour plus d’informations, veuillez nous contacter à l’adresse support@csod.com.",
    "errorMessage1": "Session expirée!",
    "errorMessage2": "Service non disponible!",
    "usersVariable32": "Ajouter un utilisateur interne",
    "usersVariable33": "Attribuer un organisme",
    "customizationCentre": "Centre de personnalisation",
    "customizationCentreBreadcrumb": "Centre de personnalisation",
    "customizationCentreTitle": "Centre de personnalisation |",
    "settings": "Paramètres",
    "brandLogo": "Logo de la marque",
    "brandingWatermark": "Filigrane de marque",
    "widgetIcon": "Icône de widget",
    "widgetIconPosition": "Position de l’icône du widget",
    "widgetIconBadge": "Badge de l’icône du widget",
    "widgetIconZIndex": "z-index du widget",
    "hideWidgetIconOnGuidesPage": "Cacher le widget s’il n’y a pas de guides sur la page",
    "widgetIconVisibility": "Visibilité de l’icône du widget",
    "false": "Faux",
    "firstSlideImage": "Image de la première diapositive",
    "lastSlideImage": "Image de la dernière diapositive",
    "stepBalloonTheme": "Thème du ballon de l’étape",
    "stepTitleText": "Texte du titre de l’étape",
    "dummyText": "Lorem Ipsum est un texte factice",
    "prev": "Précédent",
    "tooltipBalloonTheme": "thème du ballon de l’Tooltip",
    "tooltipBallonInfoTip": "La première ligne du texte de l’Tooltip sera traitée comme le « 'Titre de l’étape' » et la partie suivante comme la « 'Description de l’étape' »",
    "applyStepBallonTheme": "Appliquer le thème du ballon de l’étape",
    "stepSelectorBorderWidth": "Largeur de la bordure du sélecteur d’étape",
    "stepSelectorBorderColor": "Couleur de la bordure du sélecteur d’étape",
    "enableStepAudio": "Activer l’étape audio",
    "preferredColorsForTitleDescription": "Couleurs préférées pour le titre et la description de l’étape",
    "reset": "Réinitialiser",
    "tooltipSelectorBGColor": "Couleur d’arrière-plan du sélecteur de l’Tooltip",
    "tooltipSelectorBorderColor": "couleur de la bordure du sélecteur de l’Tooltip",
    "notificationHeaderColor": "Couleur de l’en-tête de la notification",
    "pushNotificationDelay": "Délai de la notification Push",
    "pushNotificationSnoozeTime": "Délai de répétition de la notification Push",
    "chatbotIcon": "Icône de robot conversationnel",
    "chatbotPosition": "Position du robot conversationnel",
    "branding": "Personnalisation",
    "widget": "Widget",
    "videogif": "Vidéo & GIF",
    "step": "Étape",
    "notification": "Notification",
    "chatbot": "Robot conversationnel",
    "features": "Fonctions",
    "labels": "Libellés",
    "top": "Top",
    "bottom": "Inférieur",
    "left": "Gauche",
    "right": "Droit",
    "chatbotGreetingMsg": "Message d’accueil du robot conversationnel",
    "defaultChatbotGreeting": "Hé! Bienvenue dans MyGuide. Comment puis-je vous aider? N’oubliez pas que je suis un robot.",
    "chatbotGreetingEndMsg": "Message de fin de conversation du robot conversationnel",
    "defaultChatbotEnd": "Nous avons terminé, merci beaucoup!",
    "chatbotErrorMsg": "Message d’erreur du robot conversationnel",
    "defaultChatbotError": "Je suis désolé, je n'ai pas compris. Pourriez-vous être plus précis?",
    "miniPlayerHeader": "En-tête du mini-lecteur",
    "showAllGuidesTab": "Afficher l’onglet « Tous les Guides »",
    "defaultGuideActionInPlayer": "Action de guidage par défaut dans le lecteur",
    "defaultWebPlayerMode": "Mode par défaut du lecteur Web",
    "default": "Valeur par défaut",
    "miniPlayer": "Mini Player",
    "playerPanelDefaultMode": "Mode par défaut du panneau du lecteur",
    "open": "Ouvrir",
    "closed": "Fermé",
    "configureFeedbackOptn": "Configurez l’option de retour d’information",
    "imageLibrary": "Bibliothèque d’images",
    "submit": "Soumettre",
    "classic": "Classique",
    "defaultPagePlayer": "Page par défaut dans le lecteur",
    "allGuides": "Tous Guides",
    "currentPage": "Page actuels",
    "bgColor": "Couleur de l’arrière-plan",
    "borderColor": "Couleur de la bordure",
    "stepTitleColor": "Couleur du titre de l’étape",
    "stepDescColor": "Couleur de la description de l’étape",
    "borderRadius": "Rayon de la bordure",
    "prevBTNBG": "Arrière-plan du bouton Précédent",
    "prevBTNText": "Texte du bouton Précédent",
    "borderWidth": "Largeur de la bordure",
    "nextBTNBG": "Arrière-plan du bouton Suivant",
    "nextBTNText": "Texte du bouton Suivant",
    "popupWidth": "Largeur de la fenêtre contextuelle",
    "colorPicker": "Sélecteur de couleurs",
    "brandLogoTooltip": "Dimensions de l’image : 100 x 40 pixels",
    "brandingWatermarkTooltip": "Un élément de marque à superposer au bas de vos vidéos et GIF. Pour de meilleurs résultats, utilisez la transparence et les dimensions recommandées\nDimensions de l’image : 150 x 150 pixels",
    "widgetIconBadgeTooltip": "Ce badge est visible si des guides sont présents sur la page en cours.",
    "firstSlideText": "Texte de la première diapositive",
    "slideTextTooltip": "Le texte de la diapositive s’applique uniquement à une vidéo et non à un GIF",
    "lastSlideText": "Texte de la dernière diapositive",
    "pushNotifDelayTooltip": "Afficher la fenêtre contextuelle de notification après (plage autorisée de 0 à 5 secondes)",
    "pushNotifSnoozeTime": "Ne pas afficher les notifications fermées pendant [select] heures",
    "playerPanelOpenLabelTooltip": "Ouvre le panneau du lecteur à chaque fois que la page est chargée",
    "widgetPosi1": "En haut à droite",
    "widgetPosi2": "En haut à gauche",
    "widgetPosi3": "En bas à droite",
    "widgetPosi4": "En bas à gauche",
    "URL": "URL",
    "urlHostname": "Nom d’hôte de l’URL",
    "urlPath": "Chemin de l’URL",
    "urlParams": "Paramètres de l’URL",
    "urlHash": "Hash d’URL",
    "pageTitle": "Titre du Page",
    "equals": "Est égal à",
    "notEquals": "Non égal à",
    "contains": "Contient",
    "doesNotContain": "Ne contient pas",
    "startsWith": "Commence par",
    "endsWith": "Se termine par",
    "discardBtnTxt": "Rejeter",
    "addRule": "Ajouter une règle",
    "createRules": "Créer des règles",
    "stepRules": "Règles d’étape",
    "building": "Construction",
    "business": "Commerce",
    "education": "Éducation",
    "fashion": "Mode",
    "finance": "Finances",
    "food": "Agro-alimentaire",
    "healthcare": "Santé",
    "miscellenous": "Divers",
    "nature": "Nature",
    "people": "Personnes",
    "science": "Sciences",
    "technology": "Technologie",
    "transport": "Transport",
    "work": "Travail",
    "images": "Images",
    "icons": "Icônes",
    "colors": "Couleurs",
    "accessibility": "Accessibilité",
    "animal": "Animal",
    "audioAndVideo": "Audio & video",
    "automotive": "Secteur automobile",
    "charity": "Activités caritatives",
    "chat": "Forum",
    "communication": "Communication",
    "solid": "Sérieux",
    "trendy": "Branché",
    "popular": "Populaire",
    "random": "Aléatoire",
    "resumeGuide": "Reprendre avec le Guide :",
    "oopsMessage": "Message Oups :",
    "maintaninence": "Maintenance :",
    "serviceError": "Erreur d’entretien :",
    "internalUsers": "Utilisateurs internes :",
    "externalUsers": "Utilisateurs externes",
    "partnerId": "ID partenaire",
    "addPartner": "Ajouter un partenaire",
    "partner": "Partenaire",
    "partnerAdmin": "Partenaire Admin",
    "partners": "Partenaire",
    "editPartner": "Éditer le partenaire",
    "addInternalUser": "Ajouter un utilisateur interne",
    "editInternalUser": "Modifier l’utilisateur interne",
    "unassign": "Annuler l’attribution",
    "orgAssign": "Attribuer une organisation",
    "tableHeaderFolder": "Dossier",
    "labelAppInsights": "Insights sur l’application :",
    "version": "Version",
    "decommissionBtn": "Mettre hors service",
    "deleteBackupBtn": "Supprimer la sauvegarde",
    "reactivateBtn": "Réactiver",
    "activeOrgs": "Organisations actives",
    "suspendedDeletedOrgs": "Organisations suspendues / mises hors service",
    "suspendedByUser": "Suspendues par utilisateur :",
    "suspendedByEmail": "Suspendues par courriel :",
    "suspendedOn": "Suspendues le :",
    "deletedByUser": "Supprimées par utilisateur :",
    "deletedByEmail": "Supprimées par courriel :",
    "deletedOn": "Supprimées le :",
    "decommissionContent": "Voulez-vous vraiment mettre hors service cette organisation? Cette action ne peut pas être annulée.",
    "deleteBackupOf": "Supprimer la sauvegarde de",
    "deleteBackupContent": "Voulez-vous vraiment supprimer cette sauvegarde d’organisation? Cette action ne peut pas être annulée.",
    "enableMediaNotification": "Activer la notification de création de fichier multimédia",
    "guideName": "Nom du Guide",
    "keywords": "Mots clés",
    "addKeywords": "Ajouter un ou plusieurs mot(s) clé(s)",
    "manageKeywords": "Gérer les mots clés",
    "published": "Publié",
    "keywordLoading": "Mise à jour des mots-clés. Cela prendra quelques minutes. Vous pouvez naviguer en toute sécurité",
    "assignKeywords": "Attribuer des mots-clés",
    "keywordTooltip": "Appuyez sur Entrée ou sur la virgule pour ajouter un mot-clé",
    "manageSegments": "Gérer les segments",
    "guides": "Guides",
    "segmentTitle": "Titre du segment",
    "addSegmentTitle": "Ajouter un titre de segment",
    "assignSegments": "Affecter des segments",
    "segmentLoading": "Mise à jour des segments. Cela prendra quelques minutes. Vous pouvez naviguer en toute sécurité",
    "addSegment": "Ajouter un ou plusieurs segments",
    "segmentTooltip": "Sélectionnez un ou plusieurs segments dans la liste",
    "addASegment": "Ajouter un segment",
    "deleteSegment": "Supprimer un segment",
    "findReplace": "Trouver & remplacer",
    "apps": "Applications",
    "content": "Contenu",
    "text": "Texte",
    "element": "Élément",
    "find": "Rechercher",
    "replace": "Remplacer",
    "selectElement": "Sélectionner l’élément",
    "elementPreview": "Aperçu de l’élément",
    "action": "Action",
    "findReplaceSearchPlaceholder": "Que recherchez-vous?",
    "noGuideFound": "Aucun Guide ne correspond à votre recherche.",
    "modifySearchCriteria": "Essayez de modifier vos critères de recherche",
    "maxGuideSelected": "Vous pouvez sélectionner un maximum de 50 Guide(s) à la fois.",
    "import": "Importer",
    "paste": "Coller",
    "importedGuides": "Guides Importé",
    "exportedGuides": "Guides Exporté",
    "transferGuides": "Transférer les Guides",
    "export": "Exporter au format",
    "pageTracking": "Suivi du Page",
    "featureTracking": "Suivi des fonctionnalités",
    "enableMirrorApplications": "Activer les applications miroir",
    "selfHostedPlayer": "Lecteur autohébergé",
    "playerPackages": "Forfaits lecteur",
    "createPlayerPackage": "Créer un forfait lecteur",
    "playerPackageError": "Cette action n’est pas autorisée, car le travail est déjà en cours",
    "selfHostedPlayerURL": "URL du lecteur autohébergé",
    "selectApps": "Select Apps",
    "tooltipPageTracking": "This enables tracking of various URL (of domains have been added into applications). Along with various metrics like page load time, time spend on page, etc.",
    "tooltipFeatureTracking": "This enables click tracking based on feature guides created in application. This tracking is silent and transparent to the user",
    "commonLoginMsg": "This is common login for Admin portal & MyGuide Insights",
    "serverMessage166": "A new password can not be the same as the old 5 password.",
    "serverMessage167": "The Title field may only contain alpha characters and spaces",
    "tutorialSelectorBGColor": "Tutorial selector background color",
    "circular": "Circular",
    "rectangular": "Rectangular",
    "width": "Width",
    "height": "Height",
    "stepPopupBtnNext": "Bouton contextuel de l’étape : « Suivant »",
    "stepPopupBtnPrev": "Bouton contextuel de l’étape : « Précédent »",
    "enableChat": "Activate Chat",
    "resumeGuideDefaulText": "Resume Guide",
    "oopsMessageDefaulText": "It looks like you're performing steps that are not part of this Guide. If you no longer wish to follow the Guide, click on Exit Guide",
    "maintenanceDefaulText": "Maintenance in progress. Please contact us via support@csod.com",
    "serviceErrorDefaulText": "Oops! An error occurred. Please try again after some time. You can also email us at support@csod.com for any assistance",
    "invalidImageText": "Selected files should be in png or jpeg format",
    "tutorialSteps": "Tutorial Steps",
    "tutorialModal": "Tutorial Thème des ballons",
    "tutorial": "Tutorial",
    "titleBgColor": "Title Background Color",
    "prevBtnLabel": "Previous Button",
    "nextBtnLabel": "Next Button",
    "color": "Color",
    "background": "Background",
    "fontSize": "Font Size",
    "outerBox": "Outer Box",
    "outerBoxShadow": "Outer Box Shadow",
    "horizontal": "Horizontal",
    "vertical": "Vertical",
    "blur": "Blur",
    "spreadRadius": "Spread Radius",
    "inset": "Inset",
    "noApplicationAssigned": "No Application assigned",
    "pasteErrorMessage": "Guides cannot be pasted in same category",
    "pasted": "Pasted Successfully !!",
    "exportMessage": "Guide will be available for export soon. To check the status, go to 'Exported Guides' under the Guides section",
    "importMessage": "Import of guides started. To check the status, go to 'Imported Guides' tab under the Guides section",
    "srNo": "Sr No.",
    "jsonProcessingIncomplete": "This action is not allowed because json processing is not completed",
    "contentCreationNotAllowed": "Content creation is not allowed for Mirror Applications.",
    "ssoUrl": "SSO URL",
    "sendFeedbackOptn": "Activer l'envoi de rétroaction",
    "selectSegment": "Sélectionnez un Segment}",
    "chatbotVisibilitySettings": "Paramètres de visibilité du robot conversationnel",
    "padding": "Remplissage :",
    "excludeDomains": "Exclure des domaines",
    "excludeDomainsTooltip": "Définissez une liste de domaines où MyGuide ne doit pas être disponible.",
    "firstNameError": "Le prénom ne doit pas contenir de caractères spéciaux",
    "lastNameError": "Le nom de famille ne doit pas contenir de caractères spéciaux",
    "enterNewTag": "Saisissez une nouvelle étiquette",
    "noteForCustomizationCentre": "La disponibilité des fonctionnalités ci-dessous dépend de leur disponibilité dans la catégorie de produit cible (par exemple : application mobile, application de bureau, etc.) et peut changer au fil du temps.",
    "enforceSequence": "Appliquer la séquence",
    "guideSequenceUpdated": "Séquence Guide mise à jour",
    "updateSequence": "Mise à jour de la séquence",
    "shiftUp": "Passage au niveau supérieur",
    "shiftDown": "Passage au niveau inférieur",
    "mediaFileCreationForTooltipGuides": "Création de fichiers multimédias pour les guides Tooltip",
    "tooltipMediaFileCreationTooltipGuides": "Permet d’exporter la création de fichiers multimédias pour les guides Tooltip.",
    "mediaFileCreationForTooltipGuidesToastInfo": "L’activation des guides Tooltip pour la création de fichiers multimédias n’est possible que lorsque le paramètre 'Création de fichiers multimédias' est activé.",
    "importSegmentValues": "Importer les valeurs de Segment",
    "importSegmentValuesHistory": "Importer historique des valeurs de Segment",
    "configureHelpLocation": "Configurer l’emplacement de l’aide",
    "creataOrganization": "Créer une organisation",
    "createPartner": "Créer un partenaire",
    "createSegment": "Créer Segment",
    "oneOrMoreGuidesPresent": "Un ou plusieurs guides sélectionnés existent déjà dans la liste des tâches",
    "betaFeatures": "Caractéristiques de la version bêta",
    "keyboardShortcuts": "Raccourcis clavier",
    "keyboardShortcutsTooltip": "Les raccourcis clavier vous permettent de créer des raccourcis pour lire les guides de workflow. Pour voir la liste complète des raccourcis clavier créés, saisissez « ? » lorsque vous visualisez l’application Web du client dans votre navigateur.",
    "dashboardBreadCrumb": "Dashboard",
    "tableHeaderModificationDate": "Date de modification",
    "headerVariable6": "Aide",
    "productType": "Type de produit",
    "guideInventory": "Inventaire Guide",
    "createdBy": "Créé par",
    "modifiedBy": "Modifié par",
    "guideId": "ID Guide",
    "botGuide": "Robot Guide",
    "tooltipGuide": "Tooltip Guide",
    "workflowGuide": "Workflow Guide",
    "featureTags": "Fonction Tags",
    "tutorialGuide": "Tutorial Guide",
    "guideType": "Type de Guide",
    "unpublish": "Annuler la publication",
    "environments": "Environnements",
    "publishGuide": "Publier Guide",
    "importSegment": "Importer Segments",
    "importSegmentHistory": "Importer l’historique Segments",
    "guidePublishInfoMessage": "Le Guide sera publié dans",
    "guidePublishedSuccessfully": "Guide publié avec succès",
    "guideUnpublishedSuccessfully": "Publication de Guide annulée avec succès",
    "enableDAP": "Activer les notifications DAP de Insights",
    "enableDAPTooltip": "Lorsque ce paramètre est activé, les valeurs par défaut définies dans Insights Portal sont utilisées, sous Control Center, pour Préférences de notification par courriel > Statistiques d’utilisation hebdomadaires et Rapport quotidien sur les étapes en échec. Si ce paramètre est désactivé, les paramètres par défaut seront remplacés et cela désactivera l’envoi des notifications pour Statistiques d’utilisation hebdomadaires et Rapport quotidien sur les étapes en échec dans le Centre de contrôle Insights",
    "showtooltipsWithWorkflowTutorial": "Afficher Tooltips avec Workflow et Tutorial Guides",
    "showtooltipsWithWorkflowTutorialTooltip": "Permet l’affichage des guides Tooltip sur une page qui affiche également les guides Workflow ou Tutorial. Lorsque ce paramètre est désactivé, les guides Tooltip ne sont pas affichés en parallèle avec les guides Workflow ou Tutorial.",
    "exportInventoryToastMessage": "Les données de Guide ont été mises en file d’attente pour l’exportation. Pour vérifier l’état, accédez à Exporter les données dans la section Gérer Guides.",
    "autobot": "AutoBot",
    "globalFeatureFlags": "Indicateurs de fonctionnalités globales",
    "beta": "Bêta",
    "legacy": "Ancien système",
    "enterprise": "Entreprise",
    "featureStatusChanged": "Indicateur de fonctionnalité globale mis à jour avec succès",
    "confirmChanges": "Confirmer les modifications",
    "featureFlagConfirmationMessage": "Cela se propagera à toutes les organisations. Voulez-vous continuer?",
    "generatePasswordOneLowerCase": "Au moins une lettre minuscule",
    "pageEnteredOutsideRange": "Le numéro de Page saisi est en dehors de la plage.",
    "showing": "Affichage",
    "of": "de",
    "segmentation": "Segmentation",
    "customization": "Personnalisation",
    "administration": "Administration",
    "userAttributes": "Attributs utilisateur",
    "addUserAttributesButtonText": "Ajouter un attribut utilisateur",
    "updateUserAttributesButtonText": "Mettre à jour l’attribut de l’utilisateur",
    "assignUserAttributes": "Assigner des valeurs d’attribut utilisateur",
    "userAttributeValues": "Valeurs d’attribut utilisateur",
    "addUserAttributeValues": "Ajouter des valeurs d’attribut utilisateur",
    "importUserAttributeValues": "Importer les valeurs d’attribut utilisateur",
    "importUserAttributeValuesHistory": "Importer l’historique des valeurs d’attributs utilisateur",
    "importUserAttributes": "Importer les attributs utilisateur",
    "importUserAttributesHistory": "Importer l’historique des attributs utilisateur",
    "addingUserAttributeButtonText": "Ajout d’un attribut utilisateur…",
    "updatingUserAttributeButtonText": "Mise à jour de l’attribut utilisateur…",
    "userAttributeCreatedText": "Attribut utilisateur créé",
    "userAttributeUpdatedText": "Attribut utilisateur mis à jour",
    "userAttributeDeletedText": "Attribut utilisateur supprimé",
    "userAttributeValueCreatedText": "Valeur d’attribut utilisateur créée",
    "userAttributeValueDeletedText": "Valeur d’attribut utilisateur supprimée",
    "widgetIconZIndextooltip": "La fonction Z-Index permet de contrôler la façon dont MyGuide} apparaît au-dessus les éléments à l’écran. Dans certains cas, MyGuide les composants deviennent cachés derrière des éléments à l’écran. La définition d’un Z-Index plus ou moins élevé peut accroître les chances d’afficher les composants MyGuide} devant les éléments à l’écran (valeur comprise entre -2147483646 et 2147483646).",
    "mirrorApplications": "Applications en miroir",
    "enabled": "Activé",
    "disabled": "Désactivé",
    "appIntelligence": "Intelligence d’application",
    "greaterThan": "Supérieur à",
    "lessThan": "Inférieur à",
    "userCreationDate": "Date de création de l’utilisateur",
    "rule_dayOfWeek": "<ul><li>Utilisez les 3 premières lettres de l’orthographe anglaise pour indiquer un jour.</li><br><li>Considérez le dimanche comme le début de la semaine</li><br><li>Utilisez le <i>trait d’union</i> « - » pour spécifier l’intervalle.</li><br><li>OU ne mentionnez qu’un jour spécifique</li><br><li><i>Le séparateur droit</i> « | » peut être utilisé en cas d’options multiples.</li><br><li>Exemple de valeurs acceptées : <br>« Lun - Ven », <br>« Mar »<br>, « Lun - Mer | Ven ».</li></ul>",
    "rule_fixTime": "<ul><li>Saisissez l’heure au format 24 heures</li><br><li>Utilisez le <i>trait d’union</i> « - » pour spécifier l’intervalle.</li><br><li>OU ne mentionnez qu’une heure de début</li><br><br><li>L’heure de fin sera considérée comme la fin de la journée (23:59) à moins qu’une valeur valide ne soit spécifiée.</li><br><li><i>Le séparateur droit</i> « | » peut être utilisé en cas d’options multiples.</li><br><li>Exemple de valeurs acceptées : <br>« 09:00 - 18:00 », <br>« 11:00 », <br>« 09:00-12:00 | 16:00 ».</li></ul>",
    "rule_dateRange": "Les champs <span><b>« Du »</b> et <b>« Au »</b> sont obligatoires.</span>",
    "insightsCustomerType": "Insights Type de client",
    "myguideCustomers": "Clients Cornerstone Guide",
    "csodInternal": "CSOD interne",
    "insightsCustomerTypeMessage": "Le basculement de ce paramètre peut entraîner la purge d’anciennes données enregistrées dans Cornerstone Guide Insights",
    "insightsCustomerTypeTooltip": "Ce paramètre modifiera les bases de données de manière à répartir les charges entre les clients payants de Cornerstone Guide et les clients de CSOD.",
    "date": "Date",
    "time": "temps",
    "dayOfWeek": "Jour de la semaine",
    "fixedTime": "Heure fixe dans la journée",
    "dateRange": "Période",
    "displayFrequency": "Fréquence d’affichage",
    "platFormUsageReport": "Rapport d’utilisation de la plate-forme",
    "platformUsageReportInfo": "Un rapport qui présente les données globales de Cornerstone Guide en tant que plateforme, en fournissant des informations sur la croissance des utilisateurs et leurs consommations dans toutes les organisations.",
    "totalUsers": "Nombre total d’utilisateurs",
    "newUsers": "Nouveaux utilisateurs",
    "activeUserSessions": "Sessions d’utilisateurs actifs",
    "guidesCreated": "Guides créés",
    "guidesPublished": "Guides Publié",
    "guidesPlays": "Lectures de Guides",
    "tooltipsCreated": "Tooltips Créé",
    "tooltipsPublished": "Tooltips Publié",
    "tooltipsPlays": "Lectures de Tooltips",
    "autoProceedStep": "Passez automatiquement à l’étape suivante",
    "automationStepDelay": "Délai de passage automatique à l’étape suivante",
    "userProvisioning": "Provisionnement de l’utilisateur",
    "hideBrandLogo": "Cacher le logo de la marque",
    "enableUserSessionTracking": "Activer le suivi des sessions utilisateur",
    "viewActivityDetails": "Afficher les détails de l’activité",
    "editingSegment": "Modification d’un segment",
    "selectSegmentUserAttribute": "Sélectionnez un attribut utilisateur",
    "selectUserAttributeAndValues": "Sélectionner les attributs et les valeurs de l’utilisateur",
    "editingSegmentInfoMessage": "Les Segments vous permettent de contrôler l’accès aux guides pour des groupes d’utilisateurs ciblés. Les attributs et les valeurs des utilisateurs reposent sur les profils des utilisateurs, qui sont introduits par intermédiaire d’intégrations d’utilisateurs, notamment l’authentification unique, ou gérés manuellement à partir des attributs des utilisateurs dans la segmentation. Vous pouvez également utiliser des règles pour contrôler l’emplacement des guides pour les utilisateurs segmentés, par exemple sur des pages ou des périodes spécifiques. Les attributs utilisateur et les règles sont utilisés ensemble pour créer le segment.",
    "numOfPartnerAdminsAllowed": "Nombre d’administrateurs partenaires autorisés",
    "numOfPartnerAdminExceeded": "Le nombre d’administrateurs partenaires pour le partenaire actuel a été dépassé. Veuillez nous contacter via l’adresse support@csod.com",
    "valueShouldBeGreaterThan": "La valeur doit être supérieure ou égale à",
    "setVisibilitySetting": "Définir la visibilité du Guide après la publication",
    "visibilitySettingsUpdated": "Mise à jour des paramètres de visibilité",
    "auto_generate_keywords": "Guide Assistance pour les créateurs",
    "editCredentials": "Modifier les informations d’identification",
    "createSegmentOptionOne": "Afficher les Guides dans le lecteur si un utilisateur fait partie du Segment.",
    "createSegmentOptionTwo": "Cacher les Guides dans le lecteur si un utilisateur fait partie du Segment.",
    "createSegmentOptionThree": "Afficher les Guides dans le lecteur si un utilisateur fait partie du Segment.",
    "select": "Sélectionner",
    "filters": "Filtres",
    "myGuideLogin": "Connectez-vous au portail Cornerstone Guide Admin",
    "loginRemember": "N’oubliez pas",
    "dashboardNoOfOrganisationsTitle": "Organisations(s)",
    "dashboardNoOfApplicationsTitle": "Application(s)",
    "dashboardNoOfUsersTitle": "Utilisateur(s)",
    "dashboardNoOfPartnersTitle": "Partenaire(s)",
    "label19": "Créer une application",
    "label20": "Mettre à jour la demande",
    "label21": "Supprimer l’application",
    "email": "Courriel",
    "iconColor": "Couleur de l’icône",
    "confirmDeleteSegment": "Êtes-vous sûr de vouloir supprimer segment?",
    "advanceSettings": "Paramètres avancés",
    "changeIcon": "Changer d’icône",
    "selectFromLibrary": "Sélectionnez dans la bibliothèque",
    "uploadFromComputer": "Télécharger depuis l’ordinateur",
    "textIs": "Le texte est",
    "testIsNot": "Le texte n’est pas",
    "textContains": "Le texte contient",
    "textMatches": "Le texte correspond",
    "variables": "Variables",
    "deleteApplicationContent": "Êtes-vous sûr de vouloir supprimer votre application?",
    "deleteUser": "Supprimer l’utilisateur",
    "deleteUserContent": "Êtes-vous sûr de vouloir supprimer l’utilisateur?",
    "deleteTasklist": "Supprimer la liste des tâches",
    "deleteTasklistContent": "Êtes-vous sûr de vouloir supprimer la liste des tâches?",
    "deleteFolder": "Supprimer le dossier",
    "deleteFolderContent": "Êtes-vous sûr de vouloir supprimer le dossier?",
    "deleteKey": "Supprimer la clé",
    "deleteKeyContent": "Êtes-vous sûr de vouloir supprimer la clé?",
    "deleteDomain": "Supprimer un domaine",
    "deleteDomainContent": "Êtes-vous sûr de vouloir supprimer le domaine?",
    "deleteRole": "Supprimer le rôle",
    "deleteRoleContent": "Êtes-vous sûr de vouloir supprimer le rôle?",
    "deletePartner": "Supprimer le partenaire",
    "deletePartnerContent": "Êtes-vous sûr de vouloir supprimer le partenaire?",
    "deleteUserAttribute": "Supprimer l’attribut utilisateur",
    "deleteUserAttributeContent": "Êtes-vous sûr de vouloir supprimer l’attribut utilisateur?",
    "deleteUserAttributeValue": "Supprimer la valeur de l’attribut utilisateur",
    "deleteUserAttributeValueContent": "Êtes-vous sûr de vouloir supprimer la valeur de l’attribut utilisateur?",
    "deleteSSO": "Supprimer le SSO",
    "deleteSSOContent": "Êtes-vous sûr de vouloir supprimer SSO?",
    "filter": "Filtre",
    "notifThemeProperties": "Propriétés du thème",
    "notifThemePropertiesInfo": "Définir les options de style des notifications push",
    "notifHeaderPlaceholder": "Voici votre nom d'alerte !",
    "notifTitle": "Titre de la notification",
    "notifDescription": "Donnez une brève description à votre notification",
    "primaryAction": "Action principale",
    "secondaryAction": "Action secondaire",
    "generalSettings": "Paramètres généraux",
    "buttons": "Boutons",
    "paddings": "Remplissages",
    "alert": "Alerte",
    "alertFont": "Police d’alerte",
    "fontType": "Type de police",
    "alertTextColor": "Couleur du texte d’alerte",
    "alertBackground": "Arrière-plan de l’alerte",
    "heading": "En-tête",
    "headingFont": "Police d’en-tête",
    "headingTextColor": "Couleur du texte d’en-tête",
    "descriptionFont": "Police de description",
    "descriptionTextColor": "Couleur du texte de description",
    "buttonFont": "Police de bouton",
    "buttonTextColor": "Couleur du texte du bouton",
    "buttonBackground": "Arrière-plan du bouton",
    "buttonBorderColor": "Couleur de la bordure du bouton",
    "actionButton": "Bouton d’action",
    "primaryButton": "Bouton principal",
    "secondaryButton": "Bouton secondaire",
    "paddingsSetings": "Paramètres de rembourrage",
    "notificationPreview": "Il s’agit de l’aperçu de votre fenêtre de notification",
    "notificationWidth": "Largeur de notification",
    "notificationHeight": "Hauteur de la notification",
    "accessibilityWarning": "La personnalisation des couleurs peut affecter l’accessibilité. Si vous changez les options de couleur par défaut, vérifiez la conformité aux normes d’accessibilité.",
    "readyToStartYourJourney": "Prêt à commencer votre voyage avec nous?",
    "viewOrganizations": "Afficher les organisations",
    "associatedOrganizaitons": "Organisations associées",
    "enterEmail": "Saisir le courriel",
    "enterPassword": "Entrez le mot de passe",
    "passwordShort": "Le mot de passe doit contenir au moins 8 caractères",
    "passwordLong": "Le mot de passe doit contenir 20 caractères ou moins",
    "generateDashboard": "Générer un Dashboard}",
    "assigned": "Affecté",
    "unassigned": "Non affecté",
    "biConnector": "Intégration des outils de Business Intelligence",
    "biConnectorTooltip": "L’activation de cette fonction vous permettra de vous connecter aux données Insights par divers outils de veille stratégique comme Power BI, etc. (qui sont actuellement pris en charge par Cornerstone Guide."
};
module.exports = GmXt.playerLbls.fr_CA;