<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id !== undefined && userService.organization_id !== '000' && userService.sidebarOpen), 'collapsed-view':( userService.organization_id !== undefined && userService.organization_id !== '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>

        <section class="component-section">
            <div class="sectionBody">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 d-flex">

                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader" >
                        {{this.admin_data.usersBreadCrumbTitle}}
                    </div> 
                </div>
                <ul class="nav nav-tabs" id="userTabs" role="userTabsList"  style="margin-top:30px">
                    <li class="nav-item" (click)="changeTab(1)">
                        <a class="nav-link custom-nav-link active" id="internal-user-tab" data-toggle="tab"
                            href="#internal_users" role="tab" aria-controls="internalUsers"
                            aria-selected="true">{{this.admin_data.internalUsers}}</a>
                    </li>
                    <li class="nav-item" (click)="changeTab(2)">
                        <a class="nav-link custom-nav-link" id="external-user-tab" data-toggle="tab"
                            href="#external_users" role="tab" aria-controls="externalUsers"
                            aria-selected="true">{{this.admin_data.externalUsers}}</a>
                    </li>
                </ul>
                <div class="tab-container">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="internal_users" role="tabpanel"
                            aria-labelledby="internal-user-tab" *ngIf="this.currentTab==1">
                            <ng-container>
                                <app-users [source]="'topLevelInternalUsers'"></app-users>
                            </ng-container>
                        </div>
                        <div class="tab-pane fade show active" id="external_users" role="tabpanel"
                            aria-labelledby="external-user-tab" *ngIf="this.currentTab==2">
                            <ng-container>
                                <app-users [source]="'topLevelExternalUsers'"></app-users>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</div>