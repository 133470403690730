import { Component, OnInit ,AfterViewChecked,AfterViewInit} from "@angular/core";
import { GENERATE_FORGOT_PASSWORD_DATA } from "../constants/FormData/generate_password";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../common/services/user.service";
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import adminLabels from "../constants/ApplicationStrings/trans_utils";
declare var $: any;

@Component({
  selector: "app-generate-password",
  templateUrl: "./generate-password.component.html",
  styleUrls: ["./generate-password.component.scss"],
})
export class GeneratePasswordComponent implements OnInit {
  admin_data = adminLabels;
  constructor(
    private route: ActivatedRoute,
    public userService: UserService,
    private errorResponseService : ErrorResponseService,
  ) {

    this.route.queryParamMap.subscribe((params) => {
      this.otp = params.get("otp");
      this.userService.show();
      this.userService.verifyOTP(this.otp).subscribe((response)=>{
        this.userService.hide();
        if(!response['error']){
          this.hasLoaded=true;
          this.otpError=false;
          this.passwordChanged=false;
        }else{
          this.hasLoaded=true;
          this.otpError=true;
          this.passwordChanged=false;
        }
        this.ngOnInit();
      })
    });
  }

  formGroup;
  passwordChanged = false;
  setPasswordValidator = false;
  generate_password_formData = GENERATE_FORGOT_PASSWORD_DATA;
  otp;
  length_check=false;
  digit_check=false;
  capital_check = false;
  small_letter_check = false;  
  specialchar_check=false;
  equal_check=false;
  otpError=true;
  hasLoaded=false;
  checksApplied=false;

  ngOnInit() {
    let form = {};
    for (let i = 0; i < this.generate_password_formData.length; i++) {
      form[this.generate_password_formData[i].name] = new FormControl("");
      let validations = [];
      for (let v of this.generate_password_formData[i].validators) {
        for (let key in v) {
          validations.push(key);
        }
      }
      let validators = [];
      for (let j = 0; j < validations.length; j++) {
        switch (validations[j]) {
          case "required":
            validators.push(Validators.required);
            break;

          case "email":
            validators.push(Validators.email);
            break;
          case "regex":
            validators.push(
              Validators.pattern(
                this.generate_password_formData[i].validators[j]["regex"]
              )
            );
            
            break;
          case "custom":
            
            if (
              this.generate_password_formData[i].validators[j]["custom"] ==
              "password()"
            ) {
              this.setPasswordValidator = true;
              
              validators.push(
                this.password.bind(
                  this,
                  form["password"],
                  form["confirm_password"]
                )
              );
            }
            break;
        }
      }
      form[this.generate_password_formData[i].name].setValidators(validators);
    }
    this.formGroup = new FormGroup(form);
    this.formGroup.setValidators(
      this.password.bind(this, form["password"], form["confirm_password"])
    );
    this.addPasswordChecks();
  }

  password(password, confirmPassword) {
    return password.value === confirmPassword.value
      ? null
      : { passwordDoNotMatch: true };
  }

  reset() {
    let body = {
      password: this.formGroup.get("password").value,
      code: this.otp,
      type: "reset_password",
    };
    this.userService.resetPassword(body).subscribe(
      (response) => {
        if(!response['error']){
        this.passwordChanged = true;
        this.hasLoaded=true;
          this.otpError=false;
        }else{
          if(response['error'] && response['code']==2054){
            this.userService.showModal.next(false);
            let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
            this.userService.errorMessage=errorResponse;
								$("#errorModal").modal("show");
          }
          else{
          this.hasLoaded=true;
          this.otpError=true;
          this.passwordChanged=false;
          }
        }
      },
      (error) => {
      }
    );
  }

  ngAfterViewInit(){
    this.addPasswordChecks(); 
  }

  ngAfterViewChecked(){
    if(!this.checksApplied){
      this.addPasswordChecks();
    }
  }

  addPasswordChecks(){
    let form = this.formGroup;
    let self=this;
    document.getElementById("pwd")?document.getElementById("pwd").addEventListener("keyup",function() {
      let pattern1,pattern2,pattern3,pattern4;
      try{
        pattern1 = new RegExp('[A-Z]');
        pattern2 = new RegExp('[0-9]');
          pattern3 = new RegExp("[$@$!#^~%*?&,.<>\"'\\;:{\\}\\[\\]\\|\\+\\-\\=\\_\\)\\(\\)\\`\\/\\\\\\]]");
          pattern4 = new RegExp('[a-z]');
      }
      catch(e){

      }
      if(form.get('password').value.length>=8 && form.get('password').value.length<=20){
        self.length_check=true;
      }else{
        self.length_check=false;
      }
      if(form.get('password').value.match(pattern1)){
        self.capital_check=true;
      }else{
        self.capital_check=false;
      }
      if(form.get('password').value.match(pattern2)){
        self.digit_check=true;
      }else{
        self.digit_check=false;
      }
      if(form.get('password').value.match(pattern3)){
        self.specialchar_check=true;
      }else{
        self.specialchar_check=false;
      }
      if (form.get('password').value.match(pattern4)) {
        self.small_letter_check = true;
      } else {
        self.small_letter_check = false;
        }
      if (form.get('password').value == form.get('confirm_password').value) {
        self.equal_check = true;
      } else {
           self.equal_check = false;
      }
      self.checksApplied=true;
    }):this.checksApplied=false;
    document.getElementById("cpwd")?document.getElementById("cpwd").addEventListener("keyup",function(){
      if(form.get('password').value==form.get('confirm_password').value){
        self.equal_check=true;
      }else{
        self.equal_check=false;
      }
      self.checksApplied=true;
    }):this.checksApplied=false;
  }

}
