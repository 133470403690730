//@ts-nocheck
import { Component, OnInit } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { UserService } from "../../../common/services/user.service";
import { ADD_USER_FORMDATA } from "../../../constants/FormData/add_user";
import { UUID } from "../../../constants/FormData/add_user";
import { SegmentationService } from "../../../common/services/segmentation.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { ApplicationService } from "../../../common/services/application.service";
import { OrganisationService } from "../../../common/services/organisation.service";
import { filter } from 'rxjs/operators';
import { RoleService } from '../../../common/services/role.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "../../../common/services/toast-modal.service";
import adminLabels from "../../../constants/ApplicationStrings/trans_utils";

import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
// import { runInThisContext } from "vm";
declare var $: any;

@Component({
    selector: "app-create-user",
    templateUrl: "./create-user.component.html",
    styleUrls: ["./create-user.component.css"],
})
export class CreateUserComponent implements OnInit {
    org_id;
    allRoles = [];
    fetch_roles = false;
    fetch_segments = false;
    fetch_user = false;
    decline_message = '';
    formGroup;
    org_admin_settings;
    breadcrumb;
    isLXPUser = false;
    add_user_formdata = JSON.parse(JSON.stringify(ADD_USER_FORMDATA));
    uuid = JSON.parse(JSON.stringify(UUID));
    admin_data = adminLabels ;

    constructor(
        public userService: UserService,
        private segmentation: SegmentationService,
        private router: Router,
        private application: ApplicationService,
        private route: ActivatedRoute,
        private organizationService: OrganisationService,
        private roleService: RoleService,
        private toastr: ToastrService,
        private titleService: Title,
		private errorResponseService : ErrorResponseService,
        private toastService: ToastModalService
    ) {
        this.titleService.setTitle(this.admin_data.usersBreadCrumbTitle + " | " + "Cornerstone Guide");
        this.userService.activeTab = 'users';
        this.route.paramMap.subscribe((params) => {
            if (params['params']['org_id']) {
                this.org_id = params['params']['org_id'];
                this.userService.organization_id = this.org_id;
            }
            this.router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe(() => {
                    if (!params['params']['user_id']) {
                        this.userService.setBreadcrumb([
                            {
                                name: this.admin_data.usersBreadCrumbTitle,
                                route: ['/organization', this.org_id, 'users']
                            },
                            {
                                name: this.admin_data.addButtonText,
                                route: ['/organization', this.org_id, 'users', 'add']
                            }
                        ])
                    } else {
                        this.userService.setBreadcrumb([
                            {
                                name: adminLabels.usersBreadCrumbTitle,
                                route: ['/organization', this.org_id, 'users']
                            },
                            {
                                name: adminLabels.tableTitle2,
                                route: ['/organization', this.org_id, 'users', 'edit']
                            }
                        ])
                    }
                    this.breadcrumb = this.userService.breadCrumbs;
                });
            })
    }

    setBreadCrumb() {
        // this.userService.setBreadcrumbs(
        //     "User-edit",
        //     this.route.url["_value"]
        // );
        // this.userService.setBreadcrumb([
        //     {
        //         name:"Dashboard",
        //         route:['/organization',this.org_id]
        //     },
        //     {
        //         name:"Users",
        //         route:['/organization',this.org_id,'users']
        //     },
        //     {
        //         name:"Edit",
        //         route:null
        //     }
        // ])
    }

    segments = [];
    formData = new FormData();
    hasUploaded = false;

    ngOnInit() {
        this.segments = [];
        this.userService.getUserData();
        this.organizationService.getCDNData()
        this.route.paramMap.subscribe((params) => {
            if (params['params']['org_id']) {
                this.org_id = params['params']['org_id'];
                this.userService.organization_id = this.org_id;
            }
            let user_org_id;
            if (params["params"]["user_id"]) {
                this.userService.isEdit = true;
                this.setBreadCrumb();
                this.userService
                    .getUser(params["params"]["user_id"])
                    .subscribe((response) => {
                        let user_data = response["data"]["user"];
                        user_org_id = user_data["org_ids"][0];
                        // this.checkPiiData(user_data);
                        this.userService.selectedUser = user_data;
                        this.fetch_user = true;

                        if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" )) {
                            this.organizationService
                                .getOrganization(this.org_id)
                                .subscribe((response) => {
                                    let organization = response["data"]["organization"];
                                    this.org_admin_settings = JSON.parse(organization['admin_settings']);
                                    if (this.org_id == user_org_id) {
                                        this.userService.organization = organization["name"];
                                        this.userService.organization_id =
                                            organization["organization_id"];
                                        // this.userService.user.cdn_signature = organization["cdn_signature"];
                                        this.userService.organization = organization;
                                        let admin_settings = JSON.parse(
                                            this.userService.organization.admin_settings
                                        );
                                        this.userService.enableSegments =
                                            admin_settings["guide_segmentation"] == "1" ? true : false;
                                        this.userService.enableSSO =
                                            admin_settings["sso"] == 1 ? true : false;
                                        this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                                        this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
                                        this.userService.enableMediaThemes = admin_settings['media_files_themes'] == 0 ? false : true;

                                        this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
                                        let org = organization;
                                        let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));
                                        cdn_sign["cdn_signature"] = organization["cdn_signature"];
                                        cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) - 100;

                                        this.organizationService.cdn_signature = cdn_sign["cdn_signature"];
                                        this.organizationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                                        localStorage.setItem("organization", JSON.stringify(org));
                                        localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
                                    } else {
                                        if (this.userService.isEdit) {
                                            this.router.navigate([
                                                "/organization",
                                                this.org_id,
                                                "users",
                                            ]);
                                            
                                        }
                                    }
                                    this.initialize();
                                });
                        } else {
                            let org = this.userService.organization;
                            this.org_admin_settings = JSON.parse(org["admin_settings"]);
                            this.initialize();
                        }
                    });
            } else {
                this.userService.isEdit = false;

                if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" )) {
                    this.organizationService
                        .getOrganization(this.org_id)
                        .subscribe((response) => {
                            let organization = response["data"]["organization"];
                            this.org_admin_settings = JSON.parse(organization['admin_settings']);
                            if (this.org_id == user_org_id) {
                                this.userService.organization = organization["name"];
                                this.userService.organization_id =
                                    organization["organization_id"];
                                // this.userService.user.cdn_signature = organization["cdn_signature"];
                                this.userService.organization = organization;
                                let admin_settings = JSON.parse(
                                    this.userService.organization.admin_settings
                                );
                                this.userService.enableSegments =
                                    admin_settings["guide_segmentation"] == "1" ? true : false;
                                this.userService.enableSSO =
                                    admin_settings["sso"] == 1 ? true : false;
                                this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                                this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;

                                this.userService.enableMediaThemes = JSON.parse(response["data"]["admin_settings"])['media_files_themes'] == 0 ? false : true;



                                let org = organization;
                                let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));
                                cdn_sign["cdn_signature"] = organization["cdn_signature"];
                                cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) - 100;

                                this.organizationService.cdn_signature = cdn_sign["cdn_signature"];
                                this.organizationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                                localStorage.setItem("organization", JSON.stringify(org));
                                localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
                            } else {
                                if (this.userService.isEdit) {
                                    this.router.navigate([
                                        "/organization",
                                        this.org_id,
                                        "users",
                                    ]);
                                    
                                }
                            }
                            this.initialize();
                        });
                } else {
                    let org = this.userService.organization;
                    this.org_admin_settings = JSON.parse(org["admin_settings"]);
                    this.initialize();
                }
                // let org = this.userService.user.organization;
                // this.org_admin_settings=JSON.parse(org["admin_settings"]);
                // this.initialize();
            }

        });
    }


    initialize() {
        let form = {};
        this.userService.add_button.disable = false;
        this.userService.add_button.text=this.admin_data.addButtonText;
        if (this.userService.show_external_user_id && this.userService.isEdit) {
            this.add_user_formdata.unshift(this.uuid);
        }
        for (let i = 0; i < this.add_user_formdata.length; i++) {

            if((this.userService.user.role !== 'superadmin') && (this.userService.user.role !== 'mg_admin')){
        
                if(this.add_user_formdata[i]['type'] === 'select' && this.add_user_formdata[i]['name'] === 'role'){
                    if(this.userService.user.role == 'app_admin') {
                        this.add_user_formdata[i]['options'] = [
                            {
                                value: "creator",
                                name: "Creator",
                            },
                            {
                                value: "user",
                                name: "Player",
                            },
                            {
                                value: "app_admin",
                                name: "App Admin",
                            }
                        ]
                    }else {
                        this.add_user_formdata[i]['options'] = [
                            {
                                value: "owner",
                                name: "Org Admin",
                            },
                            {
                                value: "creator",
                                name: "Creator",
                            },
                            {
                                value: "user",
                                name: "Player",
                            },
                            {
                                value: "app_admin",
                                name: "App Admin",
                            }
                        ]
                    }
                    
                }
            }
            
            form[this.add_user_formdata[i].name] = new FormControl("");
            let validations = [];
            for (let v of this.add_user_formdata[i].validators) {
                for (let key in v) {
                    validations.push(key);
                }
            }
            let validators = [];
            for (let j = 0; j < validations.length; j++) {
                switch (validations[j]) {
                    case "required":
                        if (validations[j]['required']) {
                            validators.push(Validators.required);
                        }
                        break;

                    case "email":
                        validators.push(Validators.email);
                        break;

                    case "regex":
                        validators.push(
                            Validators.pattern(
                              this.add_user_formdata[i].validators[j]["regex"]
                            )
                          );
                }
            }
            form[this.add_user_formdata[i].name].setValidators(validators);
        }

        // this.formGroup = new FormGroup(form);
        // if (this.userService.isEdit && !form['app_image']) {
        this.addImageField(form);
        // }
        if (this.org_admin_settings["guide_segmentation"] == 1 && !this.fetch_segments) {
            this.fetch_segments = true;
            this.segmentation.getSegmentations(this.org_id).subscribe((response) => {
                // this.add_user_formdata = Object.assign([], formData);
                if (!response['error']) {
                    if (response['data']['segments']) {
                        this.segments = response["data"]["segments"]
                    };
                    if (this.segments.length > 0) {
                        this.add_user_formdata.push(
                            {
                                type: 'heading',
                                name: 'Segments',
                                class: 'my-4',
                                text_class: 'heading_text'
                            }
                        )
                    }
                    this.segments.forEach((data) => {
                        let field = {
                            type: "tag-input",
                            name: data["key_name"],
                            value: "",
                            selectedItems: [],
                            class: "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 my-2 font14",
                            validators: [
                                {
                                    required: true,
                                },
                            ],
                            ui: {
                                label: data["key_name"],
                                class: "font-weight-bold user-segment-label",
                                inputclass: "col-lg-6",
                            },
                        };
                        field["items"] = [];
                        if (JSON.parse(data["data"]) != null) {
                            JSON.parse(data["data"]).forEach((value) => {
                                field["items"].push({
                                    display: value,
                                    value: value,
                                });
                            });
                            if (this.userService.isEdit) {
                                if (this.userService.selectedUser['profile'] != "" && this.userService.selectedUser['profile'] != null) {
                                    let profile = JSON.parse(
                                        this.userService.selectedUser["profile"]
                                    );
                                    if (profile) {
                                        if (profile.length > 0) {
                                            profile.forEach((segment) => {
                                                if (segment["id"] == data["id"]) {
                                                    let dataValues = segment["data"];
                                                    if (typeof (dataValues) == 'string') {
                                                        dataValues = JSON.parse(dataValues);
                                                    }
                                                    dataValues.forEach((val) => {
                                                        field.selectedItems.push({
                                                            display: val,
                                                            value: val,
                                                        });
                                                    });
                                                }
                                            });
                                        } else {
                                            this.isLXPUser = true;
                                            for (let i in profile) {
                                                if (i == data['key_name']) {
                                                    let dataValue = profile[i];
                                                    // if (typeof (dataValues) == 'string') {
                                                    //     dataValues = JSON.parse(dataValues);
                                                    // }
                                                    // dataValues.forEach((val) => {
                                                    field.selectedItems.push({
                                                        display: dataValue,
                                                        value: dataValue,
                                                    });
                                                    // });
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                            form[data["key_name"]] = new FormControl("");
                            this.formGroup = new FormGroup(form);
                            this.add_user_formdata.push(field);
                            this.assignValues();
                        }
                    });
                } else {
                    this.userService.showModal.next(false);
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    this.userService.errorMessage=errorResponse;
                    $("#errorModal").modal("show");
                }
            });
        }


        if (!this.fetch_roles) {
            this.getRoles();
        }
        this.formGroup = new FormGroup(form);

        let inputValidateField = ["first_name", "last_name"];
        for(let ind=0; ind<inputValidateField.length; ind++){
        this.formGroup.get(inputValidateField[ind]).valueChanges.subscribe(x => {
            if (x && !this.userService.pattern.test(x)) {
            this.formGroup.get(inputValidateField[ind]).setValue(x.replace(this.userService.replacePattern, ""),{ emitEvent: false });
            }
        });
        }
    }

    addImageField(form) {
        if (this.userService.isEdit) {
            if (!this.userService.selectedUser["image_url"] || this.userService.selectedUser["image_url"] == null) {

                this.userService.selectedUser["image_url"] =
                    "../../assets/images/user.png";
            }
        } else {
            this.userService.selectedUser = null;
        }

        form["app_image"] = new FormControl("");
        let input = {
            type: "file",
            name: "app_image",
            value: "",
            class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2",
            otherdivclass: "col-lg-8",
            fileclass: "img-fluid border",
            source: this.userService.isEdit ? this.userService.selectedUser["image_url"] : "../../assets/images/user.png",
            validators: [],
            ui: {
                label: this.admin_data.uploadProfileImage,
                class: "font-weight-bold",
                inputclass: "w-25 text-center",
            },
        };
        this.add_user_formdata.push(input);
    }

    assignValues() {
        if (this.userService.isEdit) {
            for (let i = 0; i < this.add_user_formdata.length; i++) {
                if (this.userService.selectedUser[this.add_user_formdata[i].name] && this.add_user_formdata[i].type != 'heading') {
                    this.formGroup.patchValue({
                        [this.add_user_formdata[i].name]: this.userService.selectedUser[
                            this.add_user_formdata[i].name
                        ],
                    });
                }
                if (this.add_user_formdata[i].type == "file") {
                    if (this.userService.selectedUser.image_url == null) {
                        this.userService.selectedUser["image_url"] =
                            "../../assets/images/user.png";
                    }
                    this.add_user_formdata[i]["source"] =
                        this.userService.selectedUser.image_url +
                        this.organizationService.cdn_signature;
                    this.formGroup.patchValue({
                        app_image: "",
                        email_id: this.userService.selectedUser["user_email"],
                    });
                }
                if (this.add_user_formdata[i].name == "user_email") {
                    this.add_user_formdata[i].input.disabled = true;
                }

                // if (this.formGroup.get('role').value == 'owner') {
                //     this.add_user_formdata[(this.userService.show_external_user_id && this.userService.isEdit)?5:4].disable = true;
                // }

                // if (this.formGroup.get('role').value == 'superadmin' && this.add_user_formdata[i].name=="role") {
                //     this.add_user_formdata[i].disable=true;
                // }

            }
        }
    }

    getRoles() {
        let self = this;
        var promise = new Promise((resolve, reject) => {
            let roleService = this.roleService;
            let limit = 100;
            let offset = 0;
            let cb = roleService.getRoles(limit, offset);
            this.allRoles = [];
            let allRoles = this.allRoles;
            self.fetch_roles = true;
            function roles() {
                cb.subscribe(
                    (data) => {
                        if (data["data"]["organizationRoleList"].length > 0) {
                            Array.prototype.push.apply(
                                allRoles,
                                data["data"]["organizationRoleList"]
                            );
                            offset += 100;
                            cb = roleService.getRoles(limit, offset);
                            roles();
                        } else {
                            // allRoles.push({
                            //     role_id: '0',
                            //     title: 'Unassigned'
                            // })
                            resolve(allRoles);
                        }
                    },
                    (error) => {
                    }
                );
            }
            roles();
        }).then(function (data) {
            let options = [];
            self.allRoles.forEach((role) => {
                let option = {
                    value: role['role_id'],
                    name: role['title']
                }
                options.push(option);
            })
            self.add_user_formdata[(self.userService.show_external_user_id && self.userService.isEdit) ? 5 : 4].options = options;
            if (self.userService.isEdit) {
                self.formGroup.patchValue({
                    'org_role_id': self.userService.selectedUser.org_role_id
                });
                self.assignValues();
            }
        });
    }


    addUser() {
        this.userService.add_button.disable = true;
        this.userService.add_button.text=this.admin_data.addingButtonText;
        let self = this;
        let profile = [];
        this.segments.forEach((data) => {
            let obj = {};
            if (this.formGroup.get(data["key_name"])) {
                obj["id"] = data["id"];
                obj["data"] = [];
                
                if (this.formGroup.get(data["key_name"]).value[0]){
                    obj["data"].push(this.formGroup.get(data["key_name"]).value[0]['value']);
                }
      
                // if (this.formGroup.get(data["key_name"]).value !== '' || this.formGroup.get(data["key_name"]).value !== null){
                //     // this.formGroup.get(data["key_name"]).value.forEach((val) => {
                //     //     obj["data"].push(val["value"]);
                //     // });
                // }
              
                // obj["data"] = JSON.stringify(obj["data"]);
                profile.push(obj);
            }
        });

        if (this.hasUploaded) {
            this.formData.append(
                "organization_id",
                this.userService.organization_id
            );
            this.application.getImageId(this.formData).subscribe((response) => {
                let data = response["data"];
                let request = {
                    organization_id: this.userService.organization_id,
                    first_name: this.formGroup.get("first_name").value.trim(),
                    last_name: this.formGroup.get("last_name").value.trim(),
                    email_id: this.formGroup.get("user_email").value,
                    role: this.formGroup.get('role').value,
                    org_role_id: this.formGroup.get('org_role_id').value,
                    image_id: parseInt(data["image_id"]),
                    profile: JSON.stringify(profile),
                };
                this.userService.addUser(request).subscribe(
                    (response) => {
                        processResponse(response);
                    },
                    (err)=>{
                       processResponse(err);                
                    })
            })
        } else {
            let request = {
                organization_id: this.userService.organization_id,
                first_name: this.formGroup.get("first_name").value.trim(),
                last_name: this.formGroup.get("last_name").value.trim(),
                email_id: this.formGroup.get("user_email").value,
                role: this.formGroup.get('role').value,
                org_role_id: this.formGroup.get('org_role_id').value,
                image_id: 0,
                profile: JSON.stringify(profile),
            };
            this.userService.addUser(request).subscribe((response) => {
                processResponse(response);
            },
            (err)=>{
               processResponse(err);                
            }
            );
        }

        function processResponse(response) {
            self.userService.add_button.disable = false;
            self.userService.add_button.text=self.admin_data.addButtonText;
            if (response['error'] == false) {
                self.toastService.showToast = true;
                self.toastService.toastType = "add_user";
                self.router.navigate([
                    "/organization",
                    self.userService.organization_id,
                    "users",
                ]);
            } else {
                if (response['code'] == 3037) {
                    self.decline_message = "You have reached the maximum limit to create users";
                    self.showDeclineModal();
                }
                if (response['code'] == 3003) {
                    self.decline_message = "User Already Exists";
                    self.showDeclineModal();
                }
                if (response['code'] == 3011) {
                    // self.decline_message = response['message'][0];
                    // self.showDeclineModal();                    
                    self.userService.showModal.next(false);
                    let errorResponse = self.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    self.userService.errorMessage=errorResponse;
                    $("#errorModal").modal("show");
                }
                this.userService.add_button.disable = false;
                this.userService.add_button.text=this.admin_data.addButtonText;
                
            }
        }
    }

    createProfile() {
        let profile = [];
        this.segments.forEach((data) => {
            let obj = {};
            if (this.formGroup.get(data["key_name"])) {
                obj["id"] = data["id"];
                obj["data"] = [];
         
                this.formGroup.get(data["key_name"]).value.forEach((val) => {
                    obj["data"].push(val["value"]);
                });
                // obj["data"] = JSON.stringify(obj["data"]);
                profile.push(obj);
            }
        });

        return profile;
    }

    createLXPProfile() {
        let profile = {};
        this.segments.forEach((data) => {
            // let obj = {};
            if (this.formGroup.get(data["key_name"])) {
                profile[data['key_name']] = [];
                this.formGroup.get(data["key_name"]).value.forEach((val) => {
                    profile[data['key_name']].push(val["value"]);
                });
                // obj["data"] = JSON.stringify(obj["data"]);
                // profile.push(obj);
            }
        })
        return profile;
    }

    setImages(images) {
        this.hasUploaded = true;
        this.formData.append("image", images.get("app_image"));
    }

    editApplication() {
        if (this.formData.get("image")) {
            this.formData.append(
                "organization_id",
                this.userService.organization_id
            );
        }
    }

    editUser() {
        this.userService.update_button.disable = true;
        this.userService.update_button.text=this.admin_data.updatingButtonText;

        if (this.hasUploaded) {
            this.formData.append(
                "organization_id",
                this.userService.organization_id
            );
            this.application.getImageId(this.formData).subscribe((response) => {
                let data = response["data"];
                let profile = this.createProfile();
                let request = {
                    user_id: this.userService.selectedUser.user_id,
                    first_name: this.formGroup.get("first_name").value.trim(),
                    last_name: this.formGroup.get("last_name").value.trim(),
                    role: this.formGroup.get('role').value,
                    org_role_id: this.formGroup.get('org_role_id').value,
                    image_id: parseInt(data["image_id"]),
                    settings: "",
                    profile: JSON.stringify(profile),
                };

                this.userService.editUser(request).subscribe(
                    (response) => {
                        this.userService.update_button.disable = false;
                        this.userService.update_button.text=this.admin_data.updateButtonText;
                        if (!response['error']) {
                            this.toastService.showToast = true;
                            this.toastService.toastType = "update_user";
                            this.router.navigate([
                                "/organization",
                                this.userService.organization_id,
                                "users",
                            ]);
                        } else {
                            if (response['code'] == 3011) {
                                this.userService.showModal.next(false);
                                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                                this.userService.errorMessage=errorResponse;

                                $("#errorModal").modal("show");
                            }
                        }
                    },
                    (error) => {
                    }
                );
            });
        } else {
            let profile = this.createProfile();
            let request = {
                user_id: this.userService.selectedUser.user_id,
                first_name: this.formGroup.get("first_name").value.trim(),
                last_name: this.formGroup.get("last_name").value.trim(),
                role: this.formGroup.get('role').value,
                org_role_id: this.formGroup.get('org_role_id').value,
                image_id: parseInt(this.userService.selectedUser.image_id),
                image_url: this.userService.selectedUser.image_url,
                settings: "",
                profile: JSON.stringify(profile),
            };
            this.userService.editUser(request).subscribe(
                (response) => {
                    this.userService.update_button.disable = false;
                    this.userService.update_button.text=this.admin_data.updateButtonText;
                    if (!response['error']) {
                        this.userService.showLoader = false;
                        this.toastService.showToast = true;
                        this.toastService.toastType = "update_user";
                        this.router.navigate([
                            "/organization",
                            this.userService.organization_id,
                            "users",
                        ]);
                    } else {
                        if (response['code'] == 3011) {
                            this.userService.showModal.next(false);
                            let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                            this.userService.errorMessage=errorResponse;
                            $("#errorModal").modal("show");
                        }
                    }
                },
                (error) => {
                    this.userService.update_button.disable = false;
                    this.userService.update_button.text=this.admin_data.updateButtonText;
                }
            );
        }
    }

    unassign() {
        this.userService.selectedUser = null;
        this.userService.isEdit = false;
        for (let i = 0; i < this.add_user_formdata.length; i++) {
            this.formGroup.patchValue({
                [this.add_user_formdata[i].name]: "",
            });
            if (this.add_user_formdata[i].type == "file") {
                this.add_user_formdata[i].source = "../assets/images/image.png";
            }
        }
        this.router.navigate([
            "/organization",
            this.userService.organization_id,
            "users",
        ]);
    }

    // checkPiiData(user_data: any) {
    //     let settings = (user_data['settings']) ? user_data['settings'] : null;
    //     let is_pii_data_hashed = false;
    //     if (settings) {
    //         let data = JSON.parse(settings);
    //         is_pii_data_hashed = data.is_pii_data_hashed;
    //     }
    //     if (is_pii_data_hashed) {
    //         this.router.navigate([
    //             'organization/' +
    //             this.userService.organization_id +
    //             '/users'
    //         ]);
    //     }

    //     return true;
    // }

    showDeclineModal() {
        $("#actionDeclined").modal("show");
    }

    // disable_org_role(role) {
    //     if (role == 'owner' && this.userService.isEdit) {
    //         this.add_user_formdata[(this.userService.show_external_user_id && this.userService.isEdit)?5:4].disable = true;
    //         this.formGroup.patchValue({
    //             'org_role_id': '0'
    //         })
    //     } else {
    //         this.add_user_formdata[(this.userService.show_external_user_id && this.userService.isEdit)?5:4].disable = false;
    //     }
    // }

}
