import { BrowserModule, Title } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { DatePipe } from "@angular/common";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { UserService } from "./common/services/user.service";
import { AuthGuard } from "./auth-guard/auth.guard";
import { OtpComponent } from "./otp/otp.component";
import { HeaderComponent } from "./common/header/header.component";
import { AngularSvgIconModule } from "angular-svg-icon";
import { LeftPanelComponent } from "./common/left-panel/left-panel.component";
import { DashboardService } from "./common/services/dashboard.service";
import { OrganisationComponent } from "./Organizations/organisation/organisation.component";
import { TableComponent } from "./common/table/table.component";
import { ApplicationComponent } from "./Applications/list/application/application.component";
import { ApplicationService } from "./common/services/application.service";
import { FormComponent } from "./common/form/form.component";
import { CreateOrganisationComponent } from "./Organizations/edit/create-organisation/create-organisation.component";
import { UsersComponent } from "./Users/list/users/users.component";
import { InternalUsersComponent } from './InternalUsers/list/internalUsers.component';
import { ManageRolesComponent } from "./manage-roles/manage-roles.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { GeneratePasswordComponent } from "./generate-password/generate-password.component";
import { AddApplicationComponent } from "./Applications/add_edit/add-application/add-application.component";
import { ApiService } from "./common/services/api.service";
import { TokenInterceptorInterceptor } from "./interceptor//token-interceptor.interceptor";
import { AdminSettingsComponent } from "./admin-settings/admin-settings.component";
import { SpinnerComponent } from "./common/spinner/spinner.component";
import { AddOrganisationComponent } from "./Organizations/add/add-organisation/add-organisation.component";
import { SegmentComponent } from "./Segments/segment/segment.component";
import { SegmentListComponent } from "./Segments/segment-list/segment-list.component";
import { CreateUserComponent } from "./Users/add_edit/create-user/create-user.component";
import { TagInputModule } from "ngx-chips";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ApiandservicesComponent } from "./APIAndServices/apiandservices/apiandservices.component";
import { GenerateCredentialsComponent } from "./APIAndServices/generate-credentials/generate-credentials.component";
// import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ActivityComponent } from "./activity/activity.component";
// import { MyDateRangePickerModule } from "mydaterangepicker";
import { DomainComponent } from "./domain/domain.component";
import { SsoComponent } from './sso/sso/sso.component';
import { ImportUserComponent } from "./Users/import/import-user/import-user.component";
import { NgOtpInputModule } from "ng-otp-input";
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { IconComponent } from './icon/icon.component';
import { ApiConnectorComponent } from './API Connector/api-connector/api-connector.component';
import { CreateApiConnectorComponent } from './API Connector/create-api-connector/create-api-connector.component';
import { ClipboardModule } from 'ngx-clipboard';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { ThemesComponent } from './themes/themes.component';
import { ThemeListComponent } from './themes/theme-list/theme-list.component';
import { ThemeViewComponent } from './themes/theme-view/theme-view.component';
import { ToastrModule } from 'ngx-toastr';
import { ThemeFirstPageComponent } from './themes/theme-view/theme-first-page/theme-first-page.component';
import { ThemeMiddlePageComponent } from './themes/theme-view/theme-middle-page/theme-middle-page.component';
import { ThemeLastPageComponent } from './themes/theme-view/theme-last-page/theme-last-page.component';
import { ThemeService } from './common/services/theme.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UserProvisioningComponent } from './user-provisioning/user-provisioning.component';
import { TasksComponent } from './Tasks/tasks/tasks.component';
import { ManageContentComponent } from './manage-content/manage-content.component';

import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SsoListComponent } from './sso/sso-list/sso-list.component';
import { ButtonComponent } from './common/button/button.component';
import { LoaderComponent } from './loader/loader.component';
import { CustomzationCentreComponent } from './customzation-centre/customzation-centre.component';
import { CustomizationCentreService } from "./common/services/customization.service";
import { ModalLibraryComponent } from './customzation-centre/modal-library/modal-library.component';
import { ModalStepThemeComponent } from './customzation-centre/modal-step-theme/modal-step-theme.component';
import { ModalRuleComponent } from './customzation-centre/modal-rule/modal-rule.component';
import { PartnersComponent } from './partners/partners.component';
import { CreatePartnerComponent } from './partners/create-partner/create-partner.component';
import { CreateInternalUserComponent } from './InternalUsers/create-internal-user/create-internal-user.component';
import { UsersListMappingComponent } from './InternalUsers/listWithMapping/users-list-mapping/users-list-mapping.component';
import { ModalConfirmationComponent } from './Organizations/modal-confirmation/modal-confirmation.component';
import { ModalTutorialComponent } from './customzation-centre/modal-tutorial/modal-tutorial.component';
import { MultiCheckboxDropdownComponent } from './common/multi-checkbox-dropdown/multi-checkbox-dropdown.component';
import { ManageKeywordsComponent } from "./manage-keywords/manage-keywords.component";
import { ManageKeywordsService } from "./common/services/manage-keywords.service";
import { ManageSegmentsComponent } from "./manage-segments/manage-segments.component";
import { CreateSegmentComponent } from './manage-segments/create-segment/create-segment.component';
import { FindReplaceComponent } from './find-replace/find-replace.component';
import { ManageSegmentsService } from "./manage-segments.service";
import { GuidesComponent } from './guides/guides.component';
import { CustomErrorHandler } from "./common/error-handler/custom-error-handler";
import { PlayerPackagesComponent } from './player-packages/player-packages.component';
import { ImportSegmentValueComponent } from './Segments/segment-list/import-segment-value/import-segment-value.component';
import { ExportGuidesComponent } from './export/export-guides/export-guides.component';
import { ExportUsersComponent } from './export/export-users/export-users.component';
import { GuideInventoryComponent } from './guide-inventory/guide-inventory.component';
import { FeatureFlagsComponent } from './feature-flags/feature-flags.component';
import { MaintenanceModeComponent } from './maintenance-mode/maintenance-mode.component';
import { OrderByPipe } from './order-by.pipe';
import { ProductUsageComponent } from "./product-usage/product-usage.component";
import { ApplicationListComponent } from './Applications/list/application-list/application-list.component';
import { PaginationComponent } from "./common/pagination/pagination.component";
import { ToggleSwitchComponent } from './common/toggle-switch/toggle-switch.component';
import { CustomSelectComponent } from './common/custom-select/custom-select.component';
import { ModalNotificationThemeComponent } from './customzation-centre/modal-notification-theme/modal-notification-theme.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        OtpComponent,
        HeaderComponent,
        LeftPanelComponent,
        OrganisationComponent,
        TableComponent,
        ApplicationComponent,
        FormComponent,
        CreateOrganisationComponent,
        UsersComponent,
        InternalUsersComponent,
        ManageRolesComponent,
        ForgotPasswordComponent,
        GeneratePasswordComponent,
        AddApplicationComponent,
        AdminSettingsComponent,
        SpinnerComponent,
        AddOrganisationComponent,
        SegmentComponent,
        SegmentListComponent,
        CreateUserComponent,
        ApiandservicesComponent,
        GenerateCredentialsComponent,
        ActivityComponent,
        DomainComponent,
        SsoComponent,
        ImportUserComponent,
        BreadcrumbComponent,
        IconComponent,
        ApiConnectorComponent,
        CreateApiConnectorComponent,
        DeleteModalComponent,
        ThemesComponent,
        ThemeListComponent,
        ThemeViewComponent,
        ThemeFirstPageComponent,
        ThemeMiddlePageComponent,
        ThemeLastPageComponent,
        PageNotFoundComponent,
        UserProvisioningComponent,
        TasksComponent,
        ManageContentComponent,
        ChangePasswordComponent,
        SsoListComponent,
        ButtonComponent,
        LoaderComponent,
        CustomzationCentreComponent,
        ModalLibraryComponent,
        ModalStepThemeComponent,
        ModalRuleComponent,
        PartnersComponent,
        CreatePartnerComponent,
        CreateInternalUserComponent,
        UsersListMappingComponent,
        ModalConfirmationComponent,
        ModalTutorialComponent,
        ManageKeywordsComponent,
        MultiCheckboxDropdownComponent,
        ManageKeywordsComponent,
        ManageSegmentsComponent,
        CreateSegmentComponent,
        FindReplaceComponent,
        GuidesComponent,
        PlayerPackagesComponent,
        ImportSegmentValueComponent,
        ExportGuidesComponent,
        ExportUsersComponent,
        GuideInventoryComponent,
        FeatureFlagsComponent,
        MaintenanceModeComponent,
        OrderByPipe,
        ProductUsageComponent,
        PaginationComponent,
        ToggleSwitchComponent,
        ApplicationListComponent,
        CustomSelectComponent,
        ModalNotificationThemeComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TagInputModule,
        // NgMultiSelectDropDownModule,
        BrowserAnimationsModule,
        AngularSvgIconModule.forRoot(),
        // MyDateRangePickerModule,
        NgOtpInputModule,
        ClipboardModule,
        CommonModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-right',
        }),
        ColorPickerModule,
    ],
    providers: [
        UserService,
        AuthGuard,
        DashboardService,
        ApplicationService,
        CustomizationCentreService,
        ManageKeywordsService,
        ManageSegmentsService,
        TokenInterceptorInterceptor,
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorInterceptor,
            multi: true,
        },
        ThemeService,
        Title,
        { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.SITE_KEY } },
        { provide: ErrorHandler, useClass: CustomErrorHandler }

    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
