<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">
            <div class="sectionBody">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 d-flex mb-3">
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                        {{this.importLabel}}
                    </div>
                    <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right">
                        <button type="button" class="btn btn-outline-primary w-20"
                            (click)="downloadSampleCSV()">{{this.admin_data.downloadSampleCSV}}</button>
                    </div>
                </div>

                <div class="">
                    <div class="col-lg-5">
                        <form>
                            <div class="form-group">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2">
                                    <div
                                        class="d-flex align-items-center justify-content-center flex-column p-4 border bw-1 dashed position-relative">
                                        <div class="w-50 text-center">
                                            <input type="file" name="inputFile" class="custom-file-input cursor pointer"
                                                id="validatedCustomFile" required accept=".csv"
                                                (change)="saveFile($event.target.files)">
                                            <div *ngIf="!this.file">
                                                <svg-icon src="../../../assets/svgs/upload_user.svg"></svg-icon>
                                                <h6 class="my-3">{{this.admin_data.importUserVariable12}}</h6>
                                                <h6>
                                                    <small>{{this.admin_data.importUserVariable13}}</small>
                                                </h6>
                                            </div>
                                            <div *ngIf="this.file">{{this.file['name']}}</div>
                                            <button class="btn btn-outline-primary my-3 w-50" type="button"
                                                (click)="clickFileInput()">{{this.admin_data.importUserVariable4}}</button>
                                        </div>
                                    </div>
                                    <div [class.d-block]="showFileError" class="invalid-feedback">
                                        {{this.admin_data.importUserVariable5}}</div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center my-3">
                                    <app-button [disabled]="!this.file" [buttonData]="userService.import_button"
                                        (clickhandler)="upload()"></app-button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <hr class="my-5">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 d-flex mb-3">

                        <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                            {{this.importHistoryLabel}}
                        </div>
                        <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right marginTop--10">
                            <button type="button" class="btn btn-secondary ml-2"
                                (click)="getTaskList()">{{this.admin_data.importUserVariable11}}</button>
                        </div>
                    </div>
                    <app-table [data]="tasks" [titles]="userService.task_appTitles"
                        [dataKeys]="userService.task_appKeys" [widths]="userService.col_widths">
                    </app-table>
                    <div class="clearfix">
                        <ul *ngIf="tasks && !(disableNext && disablePrev) && showPagination"
                            class="pagination my-4 float-right">
                            <li class="page-item mx-1" [class.disabled]="disablePrev">
                                <button class="page-link rounded" (click)="prependOffsetAndGetTasks()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14"
                                        fill="none">
                                        <path
                                            d="M8.50781 2.0275L3.54615 7L8.50781 11.9725L6.98031 13.5L0.480312 7L6.98031 0.5L8.50781 2.0275Z"
                                            fill="#8E8FA6" />
                                    </svg>
                                </button>
                            </li>
                            <ng-container *ngFor="let page of pages">
                                <li class="page-item mx-1" [ngClass]="{'active':page==currentPage}">
                                    <a class="page-link rounded" (click)="setIndex(page,$event)">{{page}}</a>
                                </li>
                            </ng-container>
                            <li class="page-item mx-1" [class.disabled]="disableNext">
                                <button class="page-link rounded" (click)="appendOffsetAndGetTasks()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14"
                                        fill="none">
                                        <path
                                            d="M0.492188 11.9725L5.45385 7L0.492188 2.0275L2.01969 0.5L8.51969 7L2.01969 13.5L0.492188 11.9725Z"
                                            fill="#8E8FA6" />
                                    </svg>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

    </div>
</div>