<div class="modal fade" id="notifTheme">
    <div class="modal-dialog">
        <div class="modal-content" *ngIf="getPopupVisibility()">
            <div class="modal-header">
                <span>{{this.admin_data.notifThemeProperties}}</span>
                <svg-icon role="button" (click)="closePopup($event)"
                    src="../../../assets/svgs/close-icon-large.svg"></svg-icon>
            </div>
            <div class="modal-body d-flex flex-column overflow-hidden">
                <div class="w-100 overflow-auto" style="min-height: 250px !important;">
                    <div class="d-flex flex-column notifTheme mx-auto overflow-hidden" [ngStyle]="{
                    'width': getStyleForNotif('width') + 'px',
                    'height': getStyleForNotif( 'height') + 'px'
                    }">
                        <div name="header" class="notif-header" [ngStyle]="{
                            'background-color' : getStyleForNotif('header.background'),
                            'color' : getStyleForNotif('header.color'),
                            'font-family' : getStyleForNotif('header.font'),
                            'fill': getStyleForNotif('header.color'),}" style="border-bottom: 1px solid #E6E6E6">
                            <span class="d-flex justify-content-between align-items-end p-4">
                                <span class="font14 fontWeight-900">{{this.admin_data.notifHeaderPlaceholder}}</span>
                                <svg-icon src="../../assets/svgs/close-icon-large.svg"></svg-icon>
                            </span>
                        </div>
                        <div name="body" class="d-flex flex-column justify-content-center px-auto notif-body" [ngStyle]="{
                            'padding-top': getStyleForNotif( 'body.padding_top') + 'px',
                            'padding-bottom': getStyleForNotif( 'body.padding_bottom') + 'px',
                            'padding-left': getStyleForNotif( 'body.padding_left') + 'px',
                            'padding-right': getStyleForNotif( 'body.padding_right') + 'px',
                            'background-color': getStyleForNotif('body.background_color')
                            }">
                            <span class="ml-auto mr-auto mb-2" [ngStyle]="{'fill':getStyleForNotif('body.fill')}">
                                <svg-icon src="../../assets/svgs/notification-icon.svg"></svg-icon>
                            </span>
                            <span class="text-center fontWeight-900 mb-2" [ngStyle]="{
                                    'font-family': getStyleForNotif( 'body.title_font_family'),
                                    'color': getStyleForNotif( 'body.title_color'),
                                    'font-size' : getStyleForNotif( 'body.title_font_size'),
                                }">
                                {{this.admin_data.notifTitle}}
                            </span>
                            <span class="text-center" [ngStyle]="{
                                        'font-family': getStyleForNotif( 'body.desc_font_family'),
                                        'color': getStyleForNotif( 'body.desc_color'),
                                        'font-size' : getStyleForNotif( 'body.desc_font_size'),
                                    }">
                                {{this.admin_data.notifDescription}}
                            </span>
                        </div>
                        <div name="footer"
                            class="d-flex flex-column justify-content-center notif-footer  align-items-center mt-auto py-3">
                            <div>
                                <button class="btn-customizable mr-4" [ngStyle]="{
                            'color': getStyleForNotif( 'footer.primaryBtnTextColor'),
                            'font-size': getStyleForNotif( 'footer.primaryBtnTextSize'),
                            'font-family': getStyleForNotif( 'footer.primaryBtnFont'),
                            'background-color': getStyleForNotif( 'footer.primaryBtnBackground'),
                            'border-color': getStyleForNotif('footer.primaryBtnBorderColor')}">
                                    {{this.admin_data.primaryAction}}</button>
                                <button class="btn-customizable" [ngStyle]="{
                            'color': getStyleForNotif( 'footer.secondaryBtnTextColor'),
                            'font-size': getStyleForNotif( 'footer.secondaryBtnTextSize'),
                            'font-family': getStyleForNotif( 'footer.secondaryBtnFont'),
                            'background-color': getStyleForNotif( 'footer.secondaryBtnBackground'),
                            'border-color': getStyleForNotif('footer.secondaryBtnBorderColor')

                        }">{{this.admin_data.secondaryAction}}</button>
                            </div>
                        </div>
                    </div>
                    <span class="font12 mt-1 mb-2"
                        style="margin-left: 40%;">{{this.admin_data.notificationPreview}}</span>
                </div>
                <ul class="nav nav-tabs ml-2" id="notifTabs">
                    <li class="nav-item" (click)="changeTab('generalSettings')">
                        <a class="nav-link custom-nav-link active" id="general-settings" data-toggle="tab"
                            role="tab">{{this.admin_data.generalSettings}}</a>
                    </li>
                    <li class="nav-item" (click)="changeTab('buttons')">
                        <a class="nav-link  custom-nav-link" id="buttons" data-toggle="tab"
                            role="tab">{{this.admin_data.buttons}}</a>
                    </li>
                    <li class="nav-item" (click)="changeTab('paddings')">
                        <a class="nav-link  custom-nav-link" id="paddings" data-toggle="tab"
                            role="tab">{{this.admin_data.paddings}}</a>
                    </li>
                </ul>
                <div *ngIf="currentTab === 'generalSettings'" class="d-flex flex-column overflow-auto mt-4 ml-2">
                    <span *ngIf="showAccesibility" class="accessibility-warning">
                        <svg-icon src="../../../assets/svgs/info-icon-large.svg"></svg-icon>
                        <span class="ml-2 my-4">{{this.admin_data.accessibilityWarning}}</span>
                        <svg-icon role="button" (click)="hideAccessibility()"
                            src="../../../assets/svgs/close-icon-large.svg" class="ml-auto"></svg-icon>
                    </span>
                    <span class="d-flex flex-row align-items-center justify-content-between col-3 p-0 my-2">
                        <label for="background">{{this.admin_data.background}}</label>
                        <input style="max-height: 25px; margin-left: 2%;" type="color" class="colorPicker"
                            (input)="changeProp('body.background_color', $event.target.value)"
                            [value]="getStyleForNotif('body.background_color')" id="">
                    </span>
                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="icon_color">{{this.admin_data.iconColor}}</label>
                        <input style="max-height: 25px; margin-left: 2%;" type="color" class="colorPicker"
                            (input)="changeProp('body.fill', $event.target.value)"
                            [value]="getStyleForNotif('body.fill')" id="">
                    </span>
                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="width">{{this.admin_data.notificationWidth}}</label>
                        <span class="d-flex align-items-baseline">
                            <input name="width" type="number" min="250" max="1000" class="form-control"
                                style="width: max-content;" (change)="changeProp('width', $event.target.value)"
                                [value]="getStyleForNotif('width')" (focusout)="enforceMinMax($event)">
                            <span> &nbsp;px</span></span>
                    </span>
                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="height">{{this.admin_data.notificationHeight}}</label>
                        <span class="d-flex align-items-baseline">
                            <input name="height" type="number" min="250" max="1000" class="form-control"
                                (change)="changeProp('height', $event.target.value)"
                                [value]="getStyleForNotif('height')" (focusout)="enforceMinMax($event)">
                            <span>&nbsp;px</span></span>
                    </span>
                    <span class="fontWeight-900 font14 text-color-dark-grey my-2">{{this.admin_data.alert}}</span>
                    <span class="d-flex flex-row col-6 p-0 align-items-center justify-content-between my-2">
                        <label for="header-font">{{this.admin_data.alertFont}}</label>
                        <span class="d-flex flex-coulumn" style="flex-direction: column !important;">
                            <label for="header-font" class="secondary-label">{{this.admin_data.fontType}}</label>
                            <app-custom-select [dataList]="fontFamiliesArray"
                                (updateDataList)="changeProp('header.font',$event)"
                                [selected]="getStyleForNotif('header.font')" componentName="default" class="filters"
                                dropdown_position="inherit" style="min-width: 288px !important;"></app-custom-select>
                        </span>
                    </span>
                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="background">{{this.admin_data.alertTextColor}}</label>
                        <input style="max-height: 25px; margin-left: 2%;" type="color" class="colorPicker"
                            (input)="changeProp('header.color', $event.target.value)"
                            [value]="getStyleForNotif('header.color')" id="">
                    </span>
                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="background">{{this.admin_data.alertBackground}}</label>
                        <input style="max-height: 25px; margin-left: 2%;" type="color" class="colorPicker"
                            (input)="changeProp('header.background', $event.target.value)"
                            [value]="getStyleForNotif('header.background')" id="">
                    </span>
                    <!-- Heading Div-->
                    <span class="fontWeight-900 font14 text-color-dark-grey my-2">{{this.admin_data.heading}}</span>
                    <span class="d-flex flex-row col-11 p-0 align-items-center justify-content-between my-2">
                        <label for="title-font">{{this.admin_data.headingFont}}</label>
                        <span class="d-flex flex-coulumn" style="flex-direction: column !important;">
                            <label for="title-font" class="secondary-label">{{this.admin_data.fontType}}</label>
                            <app-custom-select [dataList]="fontFamiliesArray"
                                (updateDataList)="changeProp('body.title_font_family',$event)"
                                [selected]="getStyleForNotif('body.title_font_family')" componentName="default"
                                class="filters" dropdown_position="inherit"
                                style="min-width: 288px !important;"></app-custom-select>
                        </span>
                        <span class="d-flex flex-coulumn" style="flex-direction: column !important;">
                            <label for="title-font-size" class="secondary-label">{{this.admin_data.fontSize}}</label>
                            <app-custom-select [dataList]="fontSizeArray"
                                (updateDataList)="changeProp('body.title_font_size',$event)"
                                [selected]="getStyleForNotif('body.title_font_size')" componentName="default"
                                class="filters" dropdown_position="inherit"
                                style="min-width: 288px !important;"></app-custom-select>
                        </span>
                    </span>
                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="background">{{this.admin_data.headingTextColor}}</label>
                        <input style="max-height: 25px; margin-left: 2%;" type="color" class="colorPicker"
                            (input)="changeProp('body.title_color', $event.target.value)"
                            [value]="getStyleForNotif('body.title_color')" id="">
                    </span>
                    <!-- Description Div -->
                    <span
                        class="fontWeight-900 font14 text-color-dark-grey my-2">{{this.admin_data.tableHeaderDescription}}</span>
                    <span class="d-flex flex-row col-11 p-0 align-items-center justify-content-between my-2">
                        <label for="title-font">{{this.admin_data.descriptionFont}}</label>
                        <span class="d-flex flex-coulumn" style="flex-direction: column !important;">
                            <label for="title-font" class="secondary-label">{{this.admin_data.fontType}}</label>
                            <app-custom-select [dataList]="fontFamiliesArray"
                                (updateDataList)="changeProp('body.desc_font_family',$event)"
                                [selected]="getStyleForNotif('body.desc_font_family')" componentName="default"
                                class="filters" dropdown_position="inherit" position="up"
                                style="min-width: 288px !important;"></app-custom-select>
                        </span>
                        <span class="d-flex flex-coulumn" style="flex-direction: column !important;">
                            <label for="title-font-size" class="secondary-label">{{this.admin_data.fontSize}}</label>
                            <app-custom-select [dataList]="fontSizeArray"
                                (updateDataList)="changeProp('body.desc_font_size',$event)"
                                [selected]="getStyleForNotif('body.desc_font_size')" componentName="default"
                                class="filters" position="up" style="min-width: 288px !important;"></app-custom-select>
                        </span>
                    </span>
                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="background">{{this.admin_data.descriptionTextColor}}</label>
                        <input style="max-height: 25px; margin-left: 2%;" type="color" class="colorPicker"
                            (input)="changeProp('body.desc_color', $event.target.value)"
                            [value]="getStyleForNotif('body.desc_color')" id="">
                    </span>
                </div>
                <div *ngIf="currentTab === 'buttons'" class="d-flex flex-column overflow-auto mt-4 ml-2">
                    <!-- Action Button -->
                    <!-- <span class="fontWeight-900 font14 text-color-dark-grey my-2">{{this.admin_data.actionButton}}</span>
                    <span class="d-flex flex-row col-6 p-0 align-items-center justify-content-between my-2">
                        <label for="action-button-font">{{this.admin_data.buttonFont}}</label>
                        <span class="d-flex flex-coulumn" style="flex-direction: column !important;">
                            <label for="action-button-font" class="secondary-label">{{this.admin_data.fontType}}</label>
                            <app-custom-select [dataList]="fontFamiliesArray"
                                (updateDataList)="changeProp('body.action_button_font',$event)"
                                [selected]="getStyleForNotif('body.action_button_font')" componentName="default"
                                class="filters" dropdown_position="inherit" style="min-width: 288px !important;"></app-custom-select>
                        </span>
                    </span>
                    <span class="d-flex flex-row col-6 p-0 align-items-center justify-content-between my-2">
                        <label for="action-button-url">{{this.admin_data.URL}}</label>
                        <span class="d-flex flex-coulumn" style="flex-direction: column !important;">
                            <label for="action-button-font" class="secondary-label">{{this.admin_data.URL}}</label>
                            <input class="form-control" type="text" (input)="changeProp('body.action_button_url', $event.target.value)" [value]="getStyleForNotif('body.action_button_url')"
                                placeholder="http://" style="min-width: 288px !important;">
                        </span>
                    </span>
                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="background">{{this.admin_data.buttonTextColor}}</label>
                        <input style="max-height: 25px; margin-left: 2%;" type="color" class="colorPicker"
                            (input)="changeProp('body.action_button_text_color', $event.target.value)"
                            [value]="getStyleForNotif('body.action_button_text_color')" id="">
                    </span>
                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="background">{{this.admin_data.buttonBackground}}</label>
                        <input style="max-height: 25px; margin-left: 2%;" type="color" class="colorPicker"
                            (input)="changeProp('body.action_button_background', $event.target.value)"
                            [value]="getStyleForNotif('body.action_button_background')" id="">
                    </span>
                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="background">{{this.admin_data.buttonBorderColor}}</label>
                        <input style="max-height: 25px; margin-left: 2%;" type="color" class="colorPicker"
                            (input)="changeProp('body.action_button_border_color', $event.target.value)"
                            [value]="getStyleForNotif('body.action_button_border_color')" id="">
                    </span> -->
                    <!-- Primary Button -->
                    <span
                        class="fontWeight-900 font14 text-color-dark-grey my-2">{{this.admin_data.primaryButton}}</span>
                    <span class="d-flex flex-row col-11 p-0 align-items-center justify-content-between my-2">
                        <label for="title-font">{{this.admin_data.buttonFont}}</label>
                        <span class="d-flex flex-coulumn" style="flex-direction: column !important;">
                            <label for="title-font" class="secondary-label">{{this.admin_data.fontType}}</label>
                            <app-custom-select [dataList]="fontFamiliesArray"
                                (updateDataList)="changeProp('footer.primaryBtnFont',$event)"
                                [selected]="getStyleForNotif('footer.primaryBtnFont')" componentName="default"
                                class="filters" dropdown_position="inherit"
                                style="min-width: 288px !important;"></app-custom-select>
                        </span>
                        <span class="d-flex flex-coulumn" style="flex-direction: column !important;">
                            <label for="title-font-size" class="secondary-label">{{this.admin_data.fontSize}}</label>
                            <app-custom-select [dataList]="fontSizeArray"
                                (updateDataList)="changeProp('footer.primaryBtnTextSize',$event)"
                                [selected]="getStyleForNotif('footer.primaryBtnTextSize')" componentName="default"
                                class="filters" dropdown_position="inherit"
                                style="min-width: 288px !important;"></app-custom-select>
                        </span>
                    </span>
                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="background">{{this.admin_data.buttonTextColor}}</label>
                        <input style="max-height: 25px; margin-left: 2%;" type="color" class="colorPicker"
                            (input)="changeProp('footer.primaryBtnTextColor', $event.target.value)"
                            [value]="getStyleForNotif('footer.primaryBtnTextColor')" id="">
                    </span>
                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="background">{{this.admin_data.buttonBackground}}</label>
                        <input style="max-height: 25px; margin-left: 2%;" type="color" class="colorPicker"
                            (input)="changeProp('footer.primaryBtnBackground', $event.target.value)"
                            [value]="getStyleForNotif('footer.primaryBtnBackground')" id="">
                    </span>
                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="background">{{this.admin_data.buttonBorderColor}}</label>
                        <input style="max-height: 25px; margin-left: 2%;" type="color" class="colorPicker"
                            (input)="changeProp('footer.primaryBtnBorderColor', $event.target.value)"
                            [value]="getStyleForNotif('footer.primaryBtnBorderColor')" id="">
                    </span>
                    <!-- Secondary Button -->
                    <span
                        class="fontWeight-900 font14 text-color-dark-grey my-2">{{this.admin_data.secondaryButton}}</span>
                    <span class="d-flex flex-row col-11 p-0 align-items-center justify-content-between my-2">
                        <label for="title-font">{{this.admin_data.buttonFont}}</label>
                        <span class="d-flex flex-coulumn" style="flex-direction: column !important;">
                            <label for="title-font" class="secondary-label">{{this.admin_data.fontType}}</label>
                            <app-custom-select [dataList]="fontFamiliesArray"
                                (updateDataList)="changeProp('footer.secondaryBtnFont',$event)"
                                [selected]="getStyleForNotif('footer.secondaryBtnFont')" componentName="default"
                                class="filters" dropdown_position="inherit" position="up"
                                style="min-width: 288px !important;"></app-custom-select>
                        </span>
                        <span class="d-flex flex-coulumn" style="flex-direction: column !important;">
                            <label for="title-font-size" class="secondary-label">{{this.admin_data.fontSize}}</label>
                            <app-custom-select [dataList]="fontSizeArray"
                                (updateDataList)="changeProp('footer.secondaryBtnTextSize',$event)"
                                [selected]="getStyleForNotif('footer.secondaryBtnTextSize')" componentName="default"
                                class="filters" dropdown_position="inherit" position="up"
                                style="min-width: 288px !important;"></app-custom-select>
                        </span>
                    </span>
                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="background">{{this.admin_data.buttonTextColor}}</label>
                        <input style="max-height: 25px; margin-left: 2%;" type="color" class="colorPicker"
                            (input)="changeProp('footer.secondaryBtnTextColor', $event.target.value)"
                            [value]="getStyleForNotif('footer.secondaryBtnTextColor')" id="">
                    </span>
                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="background">{{this.admin_data.buttonBackground}}</label>
                        <input style="max-height: 25px; margin-left: 2%;" type="color" class="colorPicker"
                            (input)="changeProp('footer.secondaryBtnBackground', $event.target.value)"
                            [value]="getStyleForNotif('footer.secondaryBtnBackground')" id="">
                    </span>

                    <span class="d-flex flex-row col-3 p-0 align-items-center justify-content-between my-2">
                        <label for="background">{{this.admin_data.buttonBorderColor}}</label>
                        <input style="max-height: 25px; margin-left: 2%;" type="color" class="colorPicker"
                            (input)="changeProp('footer.secondaryBtnBorderColor', $event.target.value)"
                            [value]="getStyleForNotif('footer.secondaryBtnBorderColor')" id="">
                    </span>
                </div>
                <div *ngIf="currentTab === 'paddings'" class="mt-4 ml-2 overflow-auto">
                    <span class="d-flex col-4 p-0 justify-content-between align-items-baseline my-2">
                        <input type="number" (input)="changeProp('body.padding_left', $event.target.value)"
                            [value]="getStyleForNotif('body.padding_left')" class="form-control padding-left mr-2">
                        <input type="number" (input)="changeProp('body.padding_right', $event.target.value)"
                            [value]="getStyleForNotif('body.padding_right')" class="form-control padding-right">
                    </span>

                    <span class="d-flex col-4 p-0 justify-content-between align-items-baseline my-2">
                        <input type="number" (input)="changeProp('body.padding_top', $event.target.value)"
                            [value]="getStyleForNotif('body.padding_top')" class="form-control padding-top mr-2">
                        <input type="number" (input)="changeProp('body.padding_bottom', $event.target.value)"
                            [value]="getStyleForNotif('body.padding_bottom')" class="form-control padding-bottom">
                    </span>
                </div>
            </div>
            <div class="modal-footer" style="display: block;">
                <span class="d-flex ml-2 justify-content-between">
                    <span role="button" (click)="reset()" class="font-light-color">{{this.admin_data.reset}}</span>
                    <span class="d-flex  ml-auto">
                        <button type="button" class="btn btn-discard btn-secondary mr-2" (click)="closePopup($event)">
                            {{this.admin_data.discardBtnTxt}}
                        </button>
                        <button (click)="save($event)"
                            class="btn btn-primary">{{this.admin_data.saveButtonText}}</button>
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>