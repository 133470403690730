import adminLabels from "../ApplicationStrings/trans_utils";

export const ADD_SSO_LIST_FORMDATA = [
    {
      type: "text",
      name: "name",
      value: "",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
      layoutModal: true,
      validators: [
        {
          required: true,
          maxLength: 250,
        },
      ],
      ui: {
        label: adminLabels.ssoListName,
        class: "col-lg-4",
        inputclass: "col-lg-8",
      },
      input: {
        id: "name",
        class: "form-control",
        type: "text",
        name: "name",
          placeholder: adminLabels.ssoListTableHeaderSSOName,
      },
      label: {
        title: adminLabels.ssoListName,
        class: "font-weight-bold label-asterisk",
        for: "title",
      },
    },
    {
      type: "textarea",
      name: "description",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
      value: "",
      validators: [
        {
          required:true,
          maxLength: 3000,
        },
      ],
      ui: {
        label: adminLabels.tableHeaderDescription,
        class: "font-weight-bold label-asterisk",
        inputclass: "form-control",
      },
    },
  ];