import { Component, OnInit } from "@angular/core";
import { UserService } from "../services/user.service";
import {environment} from '../../../environments/environment';

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent implements OnInit {
  constructor(private user: UserService) {}

  CDN_BASE_URL=environment.CDN_BASE_URL;

  ngOnInit() {
  }
}
