import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import {API_END_POINTS} from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

export interface SecretKeyObject{
    key_id: string,
    organization_id: string,
    key_type: any,
    api_key: string,
    api_secret: string,
    anonymous_access:string,
    jit_provisioning: string,
    is_active: string,
    creation_date: string,
    modification_date: string,
    key_name: string
}

@Injectable({
  providedIn: 'root'
})
export class ApiandservicesService {

  appTitles = [adminLabels.segmentsTableHeaderKeyName, adminLabels.apiAndServicesTableHeaderKey, adminLabels.apiAndServicesTableHeaderSecret, adminLabels.apiAndServicesTableHeaderAnonymousAccess, adminLabels.apiAndServicesTableHeaderJITProvisioning, adminLabels.tableHeaderCreationDate, adminLabels.tableHeaderActions];

  appDataKeys = ['sno', 'key_name', 'api_key', 'api_secret', 'anonymous_access', 'jit_provisioning', 'creation_date','secret_action'];

  col_widths=['8%','15%','15%','8%','8%','12%','19%'];
    secretKeyToEdit: SecretKeyObject;
  add_button={
    type:'generate_credentials',
    class:'btn btn-primary minWidth-120 mx-2',
    disable:false,
    text: adminLabels.generateButtonText
    }
    
    edit_button = {
        type: 'generate_credentials',
        class: 'btn btn-primary minWidth-120 mx-2',
        disable: false,
        text: adminLabels.editCredentials
    }

  cancel_button={
    type:'cancel',
    class:'btn btn-secondary minWidth-120 mx-2',
    disable:false,
    text: adminLabels.cancelButtonText
  }



  keyApplications=[];
  assignApplications=[];
  constructor(private apiService:ApiService) { }

  getKeyList(org_id){
    let urlPath=environment.baseUrl+API_END_POINTS.GET_KEY_LIST+"?organization_id="+org_id;
    return this.apiService.get(urlPath);
  }

  getApplications(){
    let urlPath=environment.baseUrl+"/"
  }

  generateSecretKey(request){
    let urlPath=environment.baseUrl+API_END_POINTS.GENERATE_SECRET_KEY;
    return this.apiService.post(urlPath,request);
  }

  deleteSecretKey(key_id){
    let httpParams = new HttpParams().set('key_id', key_id);
    let urlPath=environment.baseUrl+API_END_POINTS.DELETE_SECRET_KEY;
    return this.apiService.delete(urlPath,httpParams);
  }

  getApplicationList(key_id){
    let urlPath=environment.baseUrl+API_END_POINTS.GET_APPLICATION_LIST+"?key_id="+key_id;
    return this.apiService.get(urlPath);
  }

  assignAppsToKeys(request){
    let urlPath=environment.baseUrl+API_END_POINTS.ASSIGN_APPS_TO_KEYS;
    return this.apiService.post(urlPath,request);
    }
    
    editSecretKey(request) {
        let urlPath = environment.baseUrl + API_END_POINTS.GENERATE_SECRET_KEY;
        return this.apiService.put(urlPath, request);
    }

}
